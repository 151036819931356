import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import DivLine from "components/DivLine/DivLine";
import Header from "components/Header/Header";
import Listbox from "react-widgets/Listbox";
import DropdownList from "react-widgets/DropdownList";
import axios from "axios";
import { useFormik } from "formik";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { Link } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { isString } from "utils/isString";
import {
  getNumberOfOffers,
  getOffersList,
  getOffersListErrorMessage,
  getOffersListPending,
  getOfferThunk,
  nextOffersPaginationThunk,
  previousOffersPaginationThunk,
  resetAllOffersKeyState,
  selectCurrentPaginationOfOffers,
  selectMaxPaginationOfOffers,
} from "app/slices/offre/checkAllOffers.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { nanoid } from "@reduxjs/toolkit";

import { isEmpty } from "utils/isEmpty";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import arrow from "./assets/icon-arrow-right.svg";
import arrowPagination from "./assets/icon-arrow-pagination.svg";
import bgConsulOffreImg from "../CheckYourCV/assets/bg-follow-offre.png";
import styles from "./CheckVacanciesOffers.module.scss";

const contractTypes = {
  key: "contractTypes",
  max: 6,
};

const educationLevels = {
  key: "educationLevels",
  max: 7,
};
const sector = {
  key: "sector",
};
const subSector = {
  key: "subSector",
};

const startDate = {
  key: "startDate",
};

const contractDuration = {
  key: "contractDuration",
};

const country = {
  key: "country",
};

const region = {
  key: "region",
};

const normalizeFilter = {
  contractTypes: "contractTypes",
  educationLevels: "educationLevels",
  sector: "sector",
  subSector: "subSector",
  contractDuration: "contractDuration",
  startDate: "startDate",
  country: "country",
  region: "region",
};

const emptySelect = "-Aucun-";

function normalizeData(values) {
  return Object.keys(values).reduce((acc, key) => {
    let value = values[key];
    if (key === "startDate") {
      value = formatDate(value);
    }
    const filterKey = normalizeFilter[key] || key;

    if (!value) {
      return acc;
    }
    if (Array.isArray(values[key])) {
      acc[filterKey] = value.map((v) => v.key);
    } else if (isString(value)) {
      acc[filterKey] = value;
    } else {
      if (value?.key === emptySelect) {
        return acc;
      }
      acc[filterKey] = value.key;
    }

    return acc;
  }, {});
}

const generateParams = (values) => {
  const normalizedData = normalizeData(values);
  const params = Object.keys(normalizedData).reduce((acc, key) => {
    const value = normalizedData[key];

    if (Array.isArray(value) && value.length === 0) {
      return acc;
    }
    if (Array.isArray(value)) {
      return [...acc, `${key}[]=${value.join(`&${key}[]=`)}`];
    }

    // if (acc.includes("language=Autre")) {
    // return [`otherLanguage=${value}`];
    // }
    return [...acc, `${key}=${value}`];
  }, []);
  return params.join("&");
};

/* eslint-disable */
export default function ConsultTheOffers() {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");

  const offersList = useSelector(getOffersList);

  const pending = useSelector(getOffersListPending);
  const isError = useSelector(getOffersListErrorMessage);
  const referencialData = useSelector(getReferentialsData);
  const userToken = useSelector(getUserToken);
  const NumberOfOffers = useSelector(getNumberOfOffers);
  console.log("NumberOfOffers", NumberOfOffers);

  const currentPagination = useSelector(selectCurrentPaginationOfOffers);
  const maxPagination = useSelector(selectMaxPaginationOfOffers);

  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (!pending && userToken && !firstLoad) {
      (async () => {
        try {
          await dispatch(
            getOfferThunk({
              query: `${generateParams(filter)}`,
              axiosCancelToken,
            }),
          ).unwrap();
          setFirstLoad(true);
        } catch (error) {
          console.log("useEffect fail !", isError);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  useEffect(() => {
    if (!pending && userToken && firstLoad) {
      (async () => {
        try {
          dispatch(resetAllOffersKeyState());
          await dispatch(
            getOfferThunk({
              query: `${generateParams(filter)}`,
              axiosCancelToken,
            }),
          ).unwrap();
        } catch (error) {
          console.log("useEffect fail !", isError);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetAllOffersKeyState());
      console.log("RESET STATE");
    },
    [],
  );

  // useFormik from Formik

  const { values, setFieldValue } = useFormik({
    initialValues: {
      [country?.key]: {
        key: "FR",
        label: `${t("app.allOffers.selectOption")}`,
      },
      [contractTypes?.key]: [],
      [educationLevels?.key]: [],
      [sector?.key]: {
        key: emptySelect,
        label: `${t("app.allOffers.selectOption")}`,
      },
      [subSector?.key]: {
        key: emptySelect,
        label: `${t("app.allOffers.selectOption")}`,
      },
      [startDate?.key]: null,
      [contractDuration?.key]: {
        key: emptySelect,
        label: `${t("app.allOffers.selectOption")}`,
      },
      [region?.key]: {
        key: emptySelect,
        label: `${t("app.allOffers.selectOption")} `,
      },
    },
  });

  const selectedCountryKey = values[country.key].key;

  const franceRegionsOptions = referencialData?.REGION_FR;

  const switzerlandRegionsOptions = referencialData?.REGION_CH;

  const whiteListCountry = ["FR", "CH"];

  const regionsInputProps = whiteListCountry.includes(selectedCountryKey)
    ? {
        data: selectedCountryKey === "FR" ? franceRegionsOptions : switzerlandRegionsOptions,
      }
    : { data: [], disabled: true };

  if (isEmpty(referencialData)) {
    return <p>Data loading ...</p>;
  }

  // call the function we made. more readable

  return (
    <div>
      <Header
        h1={t("app.allOffers.headerTitle")}
        textElement={<p>{t("app.allOffers.headerDescription")}</p>}
        bgImg={bgConsulOffreImg}
      />
      <div className={styles.container}>
        <div className={styles.divline_h2_container}>
          <DivLine />

          <h2>{t("app.allOffers.title")}</h2>
        </div>
        <div className={styles.filter_wrapper}>
          <div className={styles.subForm}>
            <div className={styles.formGroup}>
              <label htmlFor={contractTypes?.key}>{t("app.allOffers.contractType")}</label>
              <Listbox
                multiple
                data={referencialData?.CONTRACT_TYPE}
                dataKey="key"
                textField="label"
                id={contractTypes?.key}
                value={values[contractTypes?.key]}
                onChange={(newValue) => {
                  if (newValue.length > contractTypes.max) {
                    return null;
                  }
                  setFieldValue(contractTypes?.key, newValue);
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor={educationLevels?.key}>{t("app.allOffers.levelEducation")}</label>
              <Listbox
                multiple
                data={referencialData?.STUDENT_EDUCATION_LEVEL}
                dataKey="key"
                textField="label"
                id={educationLevels?.key}
                value={values[educationLevels?.key]}
                onChange={(newValue) => {
                  if (newValue.length > educationLevels.max) {
                    return null;
                  }
                  setFieldValue(educationLevels?.key, newValue);
                }}
              />
            </div>
            <div className={styles.col}>
              <div className={styles.formGroup}>
                <label htmlFor={sector?.key}>{t("app.allOffers.sector")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.COMPANY_JOB_OFFER_SECTOR}
                  dataKey="key"
                  textField="label"
                  id={sector?.key}
                  value={values[sector?.key]}
                  onChange={(newValue) => setFieldValue(sector?.key, newValue)}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={subSector?.key}>{t("app.allOffers.profession")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.COMPANY_JOB_OFFER_SUBSECTOR}
                  dataKey="key"
                  textField="label"
                  id={subSector?.key}
                  value={values[subSector?.key]}
                  onChange={(newValue) => setFieldValue(subSector?.key, newValue)}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={startDate?.key}>{t("app.allOffers.startDate")}</label>
                <DatePickerCustom
                  id={startDate?.key}
                  value={values[startDate?.key]}
                  onChange={(newValue) => setFieldValue(startDate?.key, newValue)}
                />
              </div>
            </div>
          </div>
          <div className={styles.subForm}>
            <div className={styles.formGroup}>
              <label htmlFor={contractDuration?.key}>{t("app.allOffers.estimated")}</label>
              <DropdownList
                defaultValue={[]}
                data={referencialData?.COMPANY_JOB_CONTRACT_DURATION}
                dataKey="key"
                textField="label"
                id={contractDuration?.key}
                value={values[contractDuration?.key]}
                onChange={(newValue) => setFieldValue(contractDuration?.key, newValue)}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor={country?.key}>{t("app.allOffers.country")}</label>
              <DropdownList
                defaultValue={[]}
                data={referencialData?.COUNTRY}
                dataKey="key"
                textField="label"
                id={country?.key}
                value={values[country?.key]}
                onChange={(newValue) => {
                  if (!whiteListCountry.includes(newValue?.key)) {
                    setFieldValue(region?.key, null);
                  }
                  setFieldValue(country?.key, newValue);
                }}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor={region?.key}>{t("app.allOffers.region")}</label>
              <DropdownList
                defaultValue={[]}
                {...regionsInputProps}
                dataKey="key"
                textField="label"
                id={region?.key}
                onChange={(newValue) => setFieldValue(region?.key, newValue)}
              />
            </div>
          </div>
        </div>
        <div className={styles.btnFilter}>
          <SubmitFormBtn
            text={t("app.allOffers.btnSubmitFilter")}
            type="buttom"
            onClick={() => {
              setFilter(values);
            }}
          />
        </div>

        <div className={styles.wrapper}>
          <div className={styles.divline_h2_container}>
            <DivLine />
            <h2>{t("app.allOffers.titleSection")}</h2>
          </div>
          <div className={styles.containerTable}>
            <div className={styles.offreList}>
              <div className={styles.offreList__head}>
                <div className={styles.offreList__head__name}>{t("app.allOffers.offerName")}</div>
                <div className={styles.offreList__head__campany}>{t("app.allOffers.companyName")}</div>
                <div className={styles.offreList__head__entitled}>{t("app.allOffers.jobName")}</div>

                <div className={styles.offreList__head__contract}>{t("app.allOffers.contractType")}</div>

                <div className={styles.offreList__head__level}>{t("app.allOffers.levelEducation")}</div>
                <div className={styles.offreList__head__date}>{t("app.allOffers.startDate")}</div>

                <div className={styles.offreList__head__duration}>{t("app.allOffers.estimatedDuration")}</div>

                <div className={styles.offreList__head__link} />
              </div>

              <div className={styles.offreList__body}>
                {offersList &&
                  offersList?.map((offer) => (
                    <>
                      <div className={styles.offreList__row} key={nanoid()}>
                        <div className={styles.offreList__body__name}>{offer?.name}</div>
                        <div className={styles.offreList__body__campany}>{offer?.company?.name}</div>
                        <div className={styles.offreList__body__entitled}>{offer?.position?.title}</div>

                        <div className={styles.offreList__body__contract}>
                          {offer?.contractTypes && offer?.contractTypes.map((cv) => <span>{cv.label}</span>)}
                        </div>

                        <div className={styles.offreList__body__level}>
                          {offer?.requirements?.educationLevels && (
                            <span>{offer?.requirements?.educationLevels[0]?.label}</span>
                          )}
                        </div>

                        <div className={styles.offreList__body__date}>{formatDate(offer?.availability?.startDate)}</div>
                        <div className={styles.offreList__body__duration}>
                          {offer?.availability?.contractDuration?.label}
                        </div>
                        <div className={styles.offreList__body__link}>
                          <Link to={`/offres/${offer?.id}`} className={styles.seeMore}>
                            {t("app.allOffers.btnDetails")}
                            <img src={arrow} alt={t("app.allOffers.btnDetails")} />
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.arrowPagination}>
            <button
              disabled={currentPagination <= 1}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    previousOffersPaginationThunk({
                      query: `${generateParams(filter)}`,
                      axiosCancelToken,
                    }),
                  );
                }
              }}
              className={styles.arrowLeft}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
            <span className={styles.totalPage}>PAGE</span>
            {currentPagination}/{maxPagination}
            <button
              disabled={currentPagination >= maxPagination}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    nextOffersPaginationThunk({
                      query: `${generateParams(filter)}`,
                      axiosCancelToken,
                    }),
                  );
                }
              }}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
