import { t } from "i18next";

import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  putThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  getLearningBookletsErrorMessage,
} from "app/slices/learningBooklets/learningBooklets.slice";

import Header from "components/Header/Header";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../SelectedBookletETU.module.scss";

const BilanInteETU = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);
  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const referentials = useSelector(getReferentialsData);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("BOOKLET DATA", idData);

  // Yup validation schema
  const formSchema = Yup.object().shape({
    // commentSection: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  // onSubmit form function
  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Signé par l'étudiant",
      comment: values.studentComment ? values.studentComment : null,
      signature: values.signature,
    };

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/livret-dapprentissage/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      teamIntegration: idData?.teamIntegration?.label ? idData?.teamIntegration?.label : "",
      communication: idData?.communication?.label ? idData?.communication?.label : "",
      workInTeam: idData?.workInTeam?.label ? idData?.workInTeam?.label : "",
      informationSharing: idData?.informationSharing?.label ? idData?.informationSharing?.label : "",
      understanding: idData?.understanding?.label ? idData?.understanding?.label : "",
      teamworkAndCooperation: idData?.teamworkAndCooperation?.label ? idData?.teamworkAndCooperation?.label : "",
      performance: idData?.performance?.label ? idData?.performance?.label : "",
      reliability: idData?.reliability?.label ? idData?.reliability?.label : "",
      commitment: idData?.commitment?.label ? idData?.commitment?.label : "",
      methodical: idData?.methodical?.label ? idData?.methodical?.label : "",
      toolsMastery: idData?.toolsMastery?.label ? idData?.toolsMastery?.label : "",
      punctuality: idData?.punctuality?.label ? idData?.punctuality?.label : "",
      attendance: idData?.attendance?.label ? idData?.attendance?.label : "",
      deadlinesRespect: idData?.deadlinesRespect?.label ? idData?.deadlinesRespect?.label : "",
      autonomy: idData?.autonomy?.label ? idData?.autonomy?.label : "",
      workQuality: idData?.workQuality?.label ? idData?.workQuality?.label : "",
      companyTutorComment: idData?.companyTutorComment ? idData?.companyTutorComment : "",
      studentComment: idData?.studentComment ? idData?.studentComment : "",
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  /* eslint-disable */
  return (
    <>
      {referentials && (
        <>
          <Header
            h1={t("app.bilanInteETU.SuivezAlternant")}
            textElement={
              <div className={styles.headerContent}>
                <p>
                  {t("app.bilanInteETU.BilanIntro")}
                  <br></br>
                  <br></br>
                  {t("app.bilanInteETU.BilanIntro1")}
                  <br></br>
                  <br></br>
                  {t("app.bilanInteETU.BilanIntro2")}
                  <br></br>
                  <br></br>
                  {t("app.bilanInteETU.BilanIntro3")}
                  <br></br>
                  <br></br>
                  {t("app.bilanInteETU.BilanIntro4")}
                </p>
              </div>
            }
            bgImg={bgConsulOffreImg}
          />
          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <form className={styles.the_form} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Adaptation")}</label>
                      <p className={styles.p_text_value}>{values?.teamIntegration}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Communication")}</label>
                      <p className={styles.p_text_value}>{values?.communication}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Capacite")}</label>
                      <p className={styles.p_text_value}>{values.workInTeam}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Partages")}</label>
                      <p className={styles.p_text_value}>{values?.informationSharing}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.comprehension")}</label>
                      <p className={styles.p_text_value}>{values?.understanding}</p>
                    </div>
                    <div className={styles.div_line} />
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Collectif")}</label>
                      <p className={styles.p_text_value}>{values?.teamworkAndCooperation}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Performance")}</label>
                      <p className={styles.p_text_value}>{values?.performance}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Fiabilite")}</label>
                      <p className={styles.p_text_value}>{values?.reliability}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.InvestissementMission")}</label>

                      <p className={styles.p_text_value}>{values?.commitment}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Methode")}</label>
                      <p className={styles.p_text_value}>{values?.methodical}</p>
                    </div>
                    <div className={styles.div_line} />
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.MaitriseOutils")}</label>
                      <p className={styles.p_text_value}>{values?.toolsMastery}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Ponctualite")}</label>
                      <p className={styles.p_text_value}>{values?.punctuality}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Assiduite")}</label>
                      <p className={styles.p_text_value}>{values?.attendance}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Delais")}</label>
                      <p className={styles.p_text_value}>{values?.deadlinesRespect}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.Autonomie")}</label>
                      <p className={styles.p_text_value}>{values?.autonomy}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.bilanInteETU.QualiteTravail")}</label>
                      <p className={styles.p_text_value}>{values?.workQuality}</p>
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="companyTutorComment" className={styles.label_text}>
                        {t("app.bilanInteETU.Commentaires")}
                      </label>
                      <textarea
                        disabled
                        style={
                          errors.commentSection &&
                          touched.commentSection && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="companyTutorComment"
                        rows="5"
                        maxLength={32768}
                        value={values.companyTutorComment}
                        onChange={(e) => setFieldValue("companyTutorComment", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.companyTutorComment} touched={touched.companyTutorComment} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="studentComment">{t("app.bilanInteETU.CommentairesPreconisations")}</label>
                      <textarea
                        style={
                          errors.studentComment &&
                          touched.studentComment && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="studentComment"
                        rows="5"
                        maxLength={32768}
                        value={values.studentComment}
                        onChange={(e) => setFieldValue("studentComment", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.studentComment} touched={touched.studentComment} />
                    </div>
                    <div className={styles.checkbox_container}>
                      <label htmlFor="signature" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.SignatureStudient")}
                      </label>
                      <input
                        style={
                          errors.signature &&
                          errors.signature && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="signature"
                        type="checkbox"
                        checked={values.signature}
                        onChange={(e) => setFieldValue("signature", e.target.checked)}
                      />
                      <InputErrorMessage message={errors.signature} touched={touched.signature} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.formulairesLivretApprentissage.BoutonValider")} />
                  {/* <BackButton
										text={t("app.formulairesLivretApprentissage.BoutonRetour")}
									/> */}
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BilanInteETU;
