import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { impersonateQueryName, pca } from "../../index";
import { store } from "../store";
import { handleUserToken, setAcquireTokenRedirectInProgress } from "../slices/userConnexion/userConnexion.slice";

// Axios instance for Common middleware
export const axiosCommon = (contentType = "application/json") => {
  const account = pca.getActiveAccount();
  console.log("account in axiosCommon", account);
  const language = store.getState().languages.data;
  const { userToken } = store.getState().userConnexion;
  console.log("language in axiosCommon", language);
  const scopes = ["openid"];
  const headers = {
    "Content-Type": contentType,
    "Accept-Language": language,
    Authorization: `Bearer ${userToken}`,
  };

  if (sessionStorage.getItem(impersonateQueryName)) {
    headers.impersonate = sessionStorage.getItem(impersonateQueryName);
  }
  console.log("HEADERS", headers);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_MIDDLEWARE_COMMON,
    headers,
  });

  if (account) {
    instance.interceptors.request.use(async (config) => {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          scopes,
          account,
        });
        store.dispatch(handleUserToken({ userToken: tokenResponse.idToken }));
        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
        return config;
      } catch (error) {
        if (
          error instanceof InteractionRequiredAuthError &&
          !store.getState().userConnexion.acquireTokenRedirectInProgress
        ) {
          store.dispatch(setAcquireTokenRedirectInProgress(true));
          await pca.acquireTokenRedirect({ scopes, account });
          store.dispatch(setAcquireTokenRedirectInProgress(false));
        }
        console.log(error);
        return Promise.reject(error);
      }
    });
  }

  return instance;
};

// Axios instance for Student middleware
export const axiosStudent = (contentType = "application/json") => {
  const account = pca.getActiveAccount();
  console.log("account in axiosStudent", account);
  const language = store.getState().languages.data;
  const { userToken } = store.getState().userConnexion;
  console.log("language in axiosStudent", language);
  const scopes = ["openid"];
  const headers = {
    "Content-Type": contentType,
    "Accept-Language": language,
    Authorization: `Bearer ${userToken}`,
  };

  if (sessionStorage.getItem(impersonateQueryName)) {
    headers.impersonate = sessionStorage.getItem(impersonateQueryName);
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_MIDDLEWARE_STUDENT,
    headers,
  });

  if (account) {
    instance.interceptors.request.use(async (config) => {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          scopes,
          account,
        });
        store.dispatch(handleUserToken({ userToken: tokenResponse.idToken }));
        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
        return config;
      } catch (error) {
        if (
          error instanceof InteractionRequiredAuthError &&
          !store.getState().userConnexion.acquireTokenRedirectInProgress
        ) {
          store.dispatch(setAcquireTokenRedirectInProgress(true));
          await pca.acquireTokenRedirect({ scopes, account });
          store.dispatch(setAcquireTokenRedirectInProgress(false));
        }
        console.log(error);
        return Promise.reject(error);
      }
    });
  }

  return instance;
};

// Axios instance for Company middleware
export const axiosCompany = (contentType = "application/json") => {
  const account = pca.getActiveAccount();
  console.log("account in axiosCompany", account);
  const language = store.getState().languages.data;
  const { userToken } = store.getState().userConnexion;
  console.log("language in axiosCompany", language);
  const scopes = ["openid"];
  const headers = {
    "Content-Type": contentType,
    "Accept-Language": language,
    Authorization: `Bearer ${userToken}`,
  };

  if (sessionStorage.getItem(impersonateQueryName)) {
    headers.impersonate = sessionStorage.getItem(impersonateQueryName);
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_MIDDLEWARE_COMPANY,
    headers,
  });

  if (account) {
    instance.interceptors.request.use(async (config) => {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          scopes,
          account,
        });
        store.dispatch(handleUserToken({ userToken: tokenResponse.idToken }));
        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
        return config;
      } catch (error) {
        if (
          error instanceof InteractionRequiredAuthError &&
          !store.getState().userConnexion.acquireTokenRedirectInProgress
        ) {
          store.dispatch(setAcquireTokenRedirectInProgress(true));
          await pca.acquireTokenRedirect({ scopes, account });
          store.dispatch(setAcquireTokenRedirectInProgress(false));
        }
        console.log(error);
        return Promise.reject(error);
      }
    });
  }

  return instance;
};

// Axios instance for School middleware
export const axiosSchool = (contentType = "application/json") => {
  const account = pca.getActiveAccount();
  console.log("account in axiosSchool", account);
  const language = store.getState().languages.data;
  const { userToken } = store.getState().userConnexion;
  console.log("language in axiosSchool", language);
  const scopes = ["openid"];
  const headers = {
    "Content-Type": contentType,
    "Accept-Language": language,
    Authorization: `Bearer ${userToken}`,
  };

  if (sessionStorage.getItem(impersonateQueryName)) {
    headers.impersonate = sessionStorage.getItem(impersonateQueryName);
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_MIDDLEWARE_SCHOOL,
    headers,
  });

  if (account) {
    instance.interceptors.request.use(async (config) => {
      try {
        const tokenResponse = await pca.acquireTokenSilent({
          scopes,
          account,
        });
        store.dispatch(handleUserToken({ userToken: tokenResponse.idToken }));
        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
        return config;
      } catch (error) {
        if (
          error instanceof InteractionRequiredAuthError &&
          !store.getState().userConnexion.acquireTokenRedirectInProgress
        ) {
          store.dispatch(setAcquireTokenRedirectInProgress(true));
          await pca.acquireTokenRedirect({ scopes, account });
          store.dispatch(setAcquireTokenRedirectInProgress(false));
        }
        console.log(error);
        return Promise.reject(error);
      }
    });
  }

  return instance;
};
