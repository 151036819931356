import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  offerDetailsElem: undefined,
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchCompanyOfferThunk = createAsyncThunk(
  "offerDetails/patchCompanyOfferDetailsLink",
  async ({ id, dataToSend, axiosCancelToken }) => {
    console.log("SLICE ID", id);
    const response = await axiosCompany().patch(`/v2/companies/job-offers/${id}`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

// CREATESLICE SECTION

const offerDetailsSlice = createSlice({
  name: "offerDetails",
  initialState,
  extraReducers: {
    [patchCompanyOfferThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchCompanyOfferThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [patchCompanyOfferThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getCompanyOfferDetails = (state) => state.offerDetails.offerDetailsElem;
export const getCompanyOfferDetailsIsPending = (state) => state.offerDetails.pending;
export const getCompanyOfferDetailsErrorMessage = (state) => state.offerDetails.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default offerDetailsSlice.reducer;
