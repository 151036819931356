import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  company: null,
  pending: false,
  pendingPostCompany: false,
  pendingPostEmployee: false,
  errorMessage: null,
  id: null,
  data: null,
  newCompanyId: null,
  newEmployeeId: null,
};

// THUNKS SECTION

export const getSiretThunk = createAsyncThunk(
  "allCompanies/getSiretThunk",
  async ({ siret, axiosCancelToken, isSiret }) => {
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?siret=${siret}`);
    const response = await axiosCompany().get(`/v1/companies?siret=${siret}&searchInSiretBase=${isSiret}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const getDunsThunk = createAsyncThunk("allCompanies/getDunsThunk", async ({ duns, axiosCancelToken }) => {
  console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?duns=${duns}`);
  const response = await axiosCompany().get(`/v1/companies?duns=${duns}`, {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

export const postCompanyThunk = createAsyncThunk(
  "allCompanies/postCompanyThunk",
  async ({ dataToSend, axiosCancelToken }) => {
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies`);
    const response = await axiosCompany().post("/v1/companies", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postEmployeeThunk = createAsyncThunk(
  "allCompanies/postEmployeeThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/${id}/employees`);
    const response = await axiosCompany().post(`/v1/companies/${id}/employees`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

const getAllCompaniesSlice = createSlice({
  name: "allCompanies",
  initialState,
  extraReducers: {
    [getSiretThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getSiretThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getSiretThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getDunsThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getDunsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getDunsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [postCompanyThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostCompany = true;
    },
    [postCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newCompanyId = action.payload.id;
      state.pendingPostCompany = false;
    },
    [postCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostCompany = false;
      state.errorMessage = action.error.message;
    },
    [postEmployeeThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostEmployee = true;
    },
    [postEmployeeThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newEmployeeId = action.payload.id;
      state.pendingPostEmployee = false;
    },
    [postEmployeeThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostEmployee = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getAllCompanies = (state) => state.allCompanies.company;
export const getPostCompanyId = (state) => state.allCompanies.newCompanyId;
export const getPostEmployeeId = (state) => state.allCompanies.newEmployeeId;
export const getAllCompaniesIsPending = (state) => state.allCompanies.pending;
export const getPostCompanyIsPending = (state) => state.allCompanies.pendingPostCompany;
export const getPostEmployeeIsPending = (state) => state.allCompanies.pendingPostEmployee;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getAllCompaniesSlice.reducer;
