import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosSchool, axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  sessionsList: null,
  sessionsRegisterList: null,
  pending: false,
  errorMessage: null,
  pendingRegister: false,
  errorMessageRegister: null,

  errorMessagePost: null,
  pendingPost: null,
};

// THUNKS SECTION

export const getUserCoachingSessionsListThunk = createAsyncThunk(
  "checkYourCoaching/getUserCoachingSessionsListThunk",
  async () => {
    const response = await axiosSchool().get("/v2/schools/coaching-sessions");
    console.log("SLICE MY COACHING", response.data);
    return response.data;
  },
);

export const getUserCoachingRegisterSessionsListThunk = createAsyncThunk(
  "checkYourCoaching/getUserCoachingRegisterSessionsListThunk",
  async (id) => {
    const response = await axiosStudent().get(`/v2/students/${id}/schools/coaching-sessions`);
    return response.data;
  },
);

export const postRegisterToCoaching = createAsyncThunk("checkYourCoaching/postRegisterToCoaching", async (id) => {
  const response = await axiosSchool().post(`/v1/schools/coaching-sessions/${id}/subscribe`, {});
  console.log("SLICE MY COACHING REGISTER", response.data);
  return response.data;
});

// CREATESLICE SECTION

const checkYourCoachingSlice = createSlice({
  name: "checkYourCoaching",
  initialState,
  extraReducers: {
    [getUserCoachingSessionsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getUserCoachingSessionsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.sessionsList = action.payload;
    },
    [getUserCoachingSessionsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getUserCoachingRegisterSessionsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pendingRegister = true;
    },
    [getUserCoachingRegisterSessionsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pendingRegister = false;
      state.sessionsRegisterList = action.payload;
    },
    [getUserCoachingRegisterSessionsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingRegister = false;
      state.errorMessageRegister = action.error.message;
    },

    [postRegisterToCoaching.pending]: (state) => {
      console.log("pending !");
      state.errorMessagepost = "";
      state.pendingPost = true;
    },
    [postRegisterToCoaching.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      // state.newRegister = action.payload;
      state.pendingPost = false;
    },
    [postRegisterToCoaching.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPost = false;
      state.errorMessagePost = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcheckYourCoachingUser = (state) => state.checkYourCoaching.sessionsList;
export const getcheckYourCoachingPending = (state) => state.checkYourCoaching.pending;
export const getcheckYourCoachingErrorMessage = (state) => state.checkYourCoaching.errorMessage;

export const getcheckYourCoachingRegister = (state) => state.checkYourCoaching.sessionsRegisterList;
export const getcheckYourCoachingRegisterPending = (state) => state.checkYourCoaching.pendingRegister;
export const getcheckYourCoachingRegisterErrorMessage = (state) => state.checkYourCoaching.errorMessageRegister;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourCoachingSlice.reducer;
