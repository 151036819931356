import { t } from "i18next";

import { nanoid } from "@reduxjs/toolkit";

import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";
import { createObjAucun, createBooleanReferential } from "utils/localReferentials";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";

import { DropdownList } from "react-widgets";

import { axiosCompany } from "app/axiosInstances/axiosInstances";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { changeAucunToNull, changeEmptyStringToNull, changeOuiOrNonToBoolean } from "utils/tools";

import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import UploadOrganism from "../../../components/UploadOrganism/UploadOrganism";

import { ReactComponent as Pencil } from "../assets/search.svg";
import codeNafSvg from "../assets/codeNaf.svg";

import styles from "../Step7.module.scss";

/* eslint-disable */
const ApprenticeshipForm = ({ studentAge, axiosCancelToken }) => {
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);
  const objAucun = createObjAucun();
  const booleanReferential = createBooleanReferential();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [apiResponse, setApiResponse] = useState([]);
  const userToken = useSelector(getUserToken);
  const [selectedItem, setSelectedItem] = useState(null);

  const referencialsData = useSelector(getReferentialsData);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.length >= 3) {
      const response = await axiosCompany().get(`/v1/companies/apprentice-training-centers?label=*${value}*&type=CFA`);
      setApiResponse(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse([]);
    }
    setSelectedItem(null);
  };
  const handleOptionSelect = (option) => {
    setFieldValue("ListeDesCFAs", option);
    setInputValue(option.label);
    console.log(values.ListeDesCFAs);
    setApiResponse([]);
  };
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);

  // setDibaled value when the component is mounted, if a file is required
  useEffect(() => {
    if (values.situationLastYear.key === "4 Contrat d’apprentissage") setDisabled(false);
    else setDisabled(true);
  }, []);

  // useEffect onChange for UploadOrganism
  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          await dispatch(
            postFileThunk({
              file,
              endpoint: `/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/APPRENTICESHIP_TRAINING_REGISTRATION`,
              axiosCancelToken: axiosCancelToken,
            }),
          ).unwrap();
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          toastSuccess(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericSuccess")} secondMessage={file.name} />,
          );
          console.log("upload success TestUploadFile !");
        } catch (error) {
          console.log("upload fail TestUploadFile !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericError")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const StepSevenSchema = Yup.object().shape({
    situationLastYear: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    IntitulePrecisDuDernierDiplomeOuTitrePrepare: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .max(255, t("app.errorMessages.Max255")),
    DernierDiplomeOuTitrePrepare: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    lastYearSchool: Yup.string().required(t("app.errorMessages.RemplirChamp")).max(255, t("app.errorMessages.Max255")),
    lastDiplomaObtained: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    lastCourse: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    sportsman: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    particularSituation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    situationBeforeApprenticeship: Yup.object().when(["situationLastYear"], {
      is: (obj) => obj.key === "4 Contrat d’apprentissage",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    NumeroDeDepot: Yup.string().when(["situationLastYear"], {
      is: (obj) => obj.key === "4 Contrat d’apprentissage",
      then: (schema) =>
        schema
          .required(t("app.errorMessages.RemplirChamp"))
          .matches(/^[0][0-9]{14}/, `Ce n° doit commencer par 0 et contient 15 chiffres`),
    }),
    ListeDesCFAs: Yup.object().when(["situationLastYear"], {
      is: (obj) => obj.key === "4 Contrat d’apprentissage",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });
  // onSubmit form function
  const onSubmit = (values, actions) => {
    if (!disabled && fileUploaded === 0) {
      setSpanErrorMessage(true);
      return;
    } else {
      setSpanErrorMessage(false);
    }

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    if (valuesCopy.particularSituation.key === false) {
      valuesCopy.particularSituation = { key: "Non", label: "Non" };
    }

    // Data to send object initialization
    const dataToSend = {
      stage: "Etape8",
      student: {
        currentAcademicProfile: {
          situationLastYear: valuesCopy.situationLastYear.key,
          situationBeforeApprenticeship: valuesCopy.situationBeforeApprenticeship.key,
          lastYearSchool: valuesCopy.lastYearSchool,
          lastDiplomaPrepared: {
            name: valuesCopy.IntitulePrecisDuDernierDiplomeOuTitrePrepare,
            type: valuesCopy.DernierDiplomeOuTitrePrepare.key,
          },
          lastDiplomaObtained: {
            type: valuesCopy.lastDiplomaObtained.key,
          },
          lastCourse: valuesCopy.lastCourse.key,
          sportsman: valuesCopy.sportsman.key,
          particularSituation: valuesCopy.particularSituation.key,
          apprenticeshipTrainingCenterRegistration: {
            centerKey: valuesCopy.ListeDesCFAs.key,
            number: valuesCopy.NumeroDeDepot,
          },
        },
      },
    };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken: axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };
  // useFormik from Formik
  const thisPreContraData = preContractualizationInformations?.student?.currentAcademicProfile;

  const { values, setFieldValue, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      situationLastYear: thisPreContraData?.situationLastYear ? thisPreContraData?.situationLastYear : { ...objAucun },
      IntitulePrecisDuDernierDiplomeOuTitrePrepare: thisPreContraData?.lastDiplomaPrepared?.name
        ? thisPreContraData?.lastDiplomaPrepared?.name
        : "",
      DernierDiplomeOuTitrePrepare: thisPreContraData?.lastDiplomaPrepared?.type
        ? thisPreContraData?.lastDiplomaPrepared?.type
        : { ...objAucun },
      lastYearSchool: thisPreContraData?.lastYearSchool ? thisPreContraData?.lastYearSchool : "",
      lastDiplomaObtained: thisPreContraData?.lastDiplomaObtained?.type
        ? thisPreContraData?.lastDiplomaObtained?.type
        : { ...objAucun },
      lastCourse: thisPreContraData?.lastCourse ? thisPreContraData?.lastCourse : { ...objAucun },
      sportsman:
        thisPreContraData?.sportsman === true
          ? { key: "Oui" }
          : thisPreContraData?.sportsman === false
            ? { key: "Non" }
            : { ...objAucun },
      particularSituation: thisPreContraData?.particularSituation
        ? thisPreContraData?.particularSituation
        : { ...objAucun },
      NumeroDeDepot: thisPreContraData?.apprenticeshipTrainingCenterRegistration?.number
        ? thisPreContraData?.apprenticeshipTrainingCenterRegistration?.number
        : "",
      ListeDesCFAs: { ...objAucun },
      situationBeforeApprenticeship: thisPreContraData?.situationBeforeApprenticeship
        ? thisPreContraData?.situationBeforeApprenticeship
        : { ...objAucun },
    },
    validationSchema: StepSevenSchema,
    onSubmit,
  });

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      {/* {disabled && <BlueBanner />} */}
      {referencialsData && (
        <>
          {" "}
          <form onSubmit={handleSubmit}>
            <div className={styles.inputs_container}>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="situationLastYear">{t("app.StepSeven.SituationAvantContrat")}</label>
                <DropdownList
                  value={values.situationLastYear}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_SITUATION]}
                  dataKey="key"
                  textField="label"
                  id="situationLastYear"
                  onChange={(value) => {
                    setFieldValue("situationLastYear", value);
                    if (value.key === "4 Contrat d’apprentissage") {
                      setDisabled(false);
                    } else {
                      setDisabled(true);
                    }
                  }}
                />
                <InputErrorMessage message={errors.situationLastYear} touched={touched.situationLastYear} />
              </div>
              <div className={`${styles.label_plus_input_container} input-like-rw`}>
                <label htmlFor="IntitulePrecisDuDernierDiplomeOuTitrePrepare">
                  {t("app.StepSeven.IntitulePrecisDuDernierDiplomeOuTitrePrepare")}
                </label>
                <input
                  type="text"
                  name="IntitulePrecisDuDernierDiplomeOuTitrePrepare"
                  id="IntitulePrecisDuDernierDiplomeOuTitrePrepare"
                  value={values.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                  onChange={(e) => {
                    setFieldValue("IntitulePrecisDuDernierDiplomeOuTitrePrepare", e.target.value);
                  }}
                />
                <InputErrorMessage
                  message={errors.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                  touched={touched.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="DernierDiplomeOuTitrePrepare">{t("app.StepSeven.DernierDiplomeOuTitrePrepare")}</label>
                <DropdownList
                  value={values.DernierDiplomeOuTitrePrepare}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_DIPLOMA]}
                  dataKey="key"
                  textField="label"
                  id="DernierDiplomeOuTitrePrepare"
                  onChange={(value) => {
                    setFieldValue("DernierDiplomeOuTitrePrepare", value);
                  }}
                />
                <InputErrorMessage
                  message={errors.DernierDiplomeOuTitrePrepare}
                  touched={touched.DernierDiplomeOuTitrePrepare}
                />
              </div>
              <div className={`${styles.label_plus_input_container} input-like-rw`}>
                <label htmlFor="lastYearSchool">{t("app.StepSeven.EtablissementAnneeDerniere")}</label>
                <input
                  type="text"
                  name="lastYearSchool"
                  id="lastYearSchool"
                  value={values.lastYearSchool}
                  onChange={(e) => {
                    setFieldValue("lastYearSchool", e.target.value);
                  }}
                />
                <InputErrorMessage message={errors.lastYearSchool} touched={touched.lastYearSchool} />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="lastDiplomaObtained">{t("app.StepSeven.DernierDiplomeObtenu")}</label>
                <DropdownList
                  value={values.lastDiplomaObtained}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_DIPLOMA]}
                  dataKey="key"
                  textField="label"
                  id="lastDiplomaObtained"
                  onChange={(value) => {
                    setFieldValue("lastDiplomaObtained", value);
                  }}
                />
                <InputErrorMessage message={errors.lastDiplomaObtained} touched={touched.lastDiplomaObtained} />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="lastCourse">{t("app.StepSeven.DerniereAnneeOuClasseSuivieParLapprenti")}</label>
                <DropdownList
                  value={values.lastCourse}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_COURSE]}
                  dataKey="key"
                  textField="label"
                  id="lastCourse"
                  onChange={(value) => {
                    setFieldValue("lastCourse", value);
                  }}
                />
                <InputErrorMessage message={errors.lastCourse} touched={touched.lastCourse} />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="sportsman">{t("app.StepSeven.InscritSurListeSportifs")}</label>
                <DropdownList
                  value={values.sportsman}
                  data={[...booleanReferential]}
                  dataKey="key"
                  textField="label"
                  id="sportsman"
                  onChange={(value) => {
                    setFieldValue("sportsman", value);
                  }}
                />
                <InputErrorMessage message={errors.sportsman} touched={touched.sportsman} />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="particularSituation">{t("app.StepSeven.SituationParticuliere")}</label>
                <DropdownList
                  value={values.particularSituation}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_PARTICULAR_SITUATION]}
                  dataKey="key"
                  textField="label"
                  id="particularSituation"
                  onChange={(value) => {
                    setFieldValue("particularSituation", value);
                  }}
                />
                <InputErrorMessage message={errors.particularSituation} touched={touched.particularSituation} />
              </div>
              {values.situationLastYear.key === "4 Contrat d’apprentissage" ? (
                <>
                  <div className={styles.Dropdown_label_plus_input_container}>
                    <label htmlFor="situationBeforeApprenticeship">
                      {t("app.StepSeven.SituationAvantApprentissage")}
                    </label>

                    <DropdownList
                      value={values.situationBeforeApprenticeship}
                      data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_SITUATION]}
                      dataKey="key"
                      textField="label"
                      id="situationBeforeApprenticeship"
                      onChange={(value) => {
                        setFieldValue("situationBeforeApprenticeship", value);
                      }}
                    />
                    <InputErrorMessage
                      message={errors.situationBeforeApprenticeship}
                      touched={touched.situationBeforeApprenticeship}
                    />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="NumeroDeDepot">
                      {t("app.StepSeven.NumeroDeDepot")}
                      <br />
                      <span className={styles.special_label}>
                        {t("app.declarezUnRecrutement.NumeroDeDepotHelpText")}
                      </span>
                    </label>

                    <input
                      type="text"
                      name="NumeroDeDepot"
                      id="NumeroDeDepot"
                      value={values.NumeroDeDepot}
                      onChange={(e) => {
                        setFieldValue("NumeroDeDepot", e.target.value);
                      }}
                    />
                    <InputErrorMessage message={errors.NumeroDeDepot} touched={touched.NumeroDeDepot} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="ListeDesCFAs">{t("app.StepSeven.ListeDesCFAs")}</label>
                    <div className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}>
                      <input type="text" value={inputValue} onChange={handleInputChange} />
                      <Pencil className={styles.search_icon} />
                    </div>
                    {/* <div>test</div> */}
                    <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
                      <ul>
                        {apiResponse.map((result) => (
                          <div key={nanoid()}>
                            <Pencil className={styles.search_icon} />

                            <div className={styles.output_plus_image_Container}>
                              <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                              <div className={styles.outputKeyLabel} onClick={() => handleOptionSelect(result)}>
                                <li> {result.key}</li>
                                <li>{result.label}</li>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    {/* </div> */}
                    <InputErrorMessage message={errors.ListeDesCFAs} touched={touched.ListeDesCFAs} />
                  </div>
                  <UploadOrganism
                    labelText={t("app.StepSeven.DeposezVotreCerfaPrecedent")}
                    disabled={disabled}
                    setFile={setFile}
                    setDisplayModal={setDisplayModal}
                    spanErrorMessage={spanErrorMessage}
                  />
                </>
              ) : null}
            </div>
          </form>
          <div className={styles.btns_container}>
            <SubmitContractBtn
              text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")}
              handleNextStep={handleSubmit}
            />
            <PreviousFormBtn
              text={t("app.declarezUnRecrutement.EtapePrecedente")}
              handlePreviousStep={() => dispatch(handlePreviousStep("Etape6"))}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ApprenticeshipForm;
