import { t } from "i18next";
import { nanoid } from "@reduxjs/toolkit";
import NextStepButton from "components/nextStepBtn/nextStepBtn";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
  patchStepThunk,
} from "app/slices/declareARecruitment/declareARecruitment.slice";

import HeaderContract from "../../components/HeaderContract/HeaderContract";

import styles from "./Step5.module.scss";

/* eslint-disable */
const List = ({ label, value }) => {
  return (
    <div className={styles.listItem}>
      <h6>{label}</h6>
      <input type="text" disabled value={value} />
    </div>
  );
};

const Step5 = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  console.log("Pré contra data : ", preContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  const handleSubmit = () => {
    // Data to send object initialization
    const dataToSend = { stage: "Etape6" };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken: axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nationality = preContractualizationInformations?.student?.nationalities;
  const arrayNationality = nationality && nationality?.map((item) => item?.label);

  const informationDetails = [
    {
      label: `${t("app.declarezUnRecrutement.EcoleDeFormation")}`,
      value: preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.trainingSchool
        ? `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.trainingSchool}`
        : "",
    },

    {
      label: `${t("app.declarezUnRecrutement.AnneeUniversitaire")}`,
      value: `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.schoolYear}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.AdresseRue")}`,
      value: `${preContractualizationInformations?.student?.address?.street}`,
    },
    {
      label: "Code postal",
      value: `${preContractualizationInformations?.student?.address?.zipCode}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.Ville")}`,
      value: `${preContractualizationInformations?.student?.address?.city}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.Telephone")}`,
      value: `${
        preContractualizationInformations?.student?.phones?.homePhone
          ? preContractualizationInformations?.student?.phones?.homePhone
          : ""
      }`,
    },
    {
      label: `${t("app.declarezUnRecrutement.Promotion")}`,
      value: `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.promotion}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.SousPromotion")}`,
      value: `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.subPromotion}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.SousPromotionEnvisagee")}`,
      value: `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.wantedSubPromotion}`,
    },
    {
      label: `${t("app.declarezUnRecrutement.Specialisation")}`,
      value: preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.trainingSchool
        ? `${preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment?.trainingSchool}`
        : "",
    },
    {
      label: `${t("app.declarezUnRecrutement.Nationalite")}`,
      value: arrayNationality,
    },
    {
      label: `${t("app.StepThree.AdresseMailDuContact")}`,
      value: `${preContractualizationInformations?.student?.email}`,
    },
  ];
  return (
    <div className={styles.container}>
      <HeaderContract />

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.headerTitle}>{t("app.declarezUnRecrutement.Scolarite")}</h2>
          <div className={styles.stepLevel}>{t("app.StepThree.Etape1Sur4")}</div>
        </div>
        <div className={styles.boxInfo}>
          <svg
            className="slds-icon"
            focusable="false"
            data-key="info"
            aria-hidden="true"
            viewBox="0 0 52 52"
            part="icon"
          >
            <g>
              <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
            </g>
          </svg>
          <p>{t("app.declarezUnRecrutement.SiVousReperezIncoherence")}</p>
        </div>
        <div className={styles.boxDisableInfo}>
          {informationDetails?.map((item) => (
            <List key={nanoid()} label={item?.label} value={item?.value} />
          ))}
        </div>
        <div className={styles.next}>
          <NextStepButton
            text={t("app.declarezUnRecrutement.ValidezEtPassezAEtapeSuivante")}
            handleNextStep={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Step5;
