import styles from "../Step2.module.scss";

/* eslint-disable */
const CheckboxOrganism = ({ disabled, checkboxId, label, text, infoBubbleContent, values, setFieldValue }) => (
  <div className={styles.label_plus_input_container}>
    <label>{label}</label>
    <div className={styles.checkbox_svg_text_container}>
      <div className={styles.checkbox_and_svg_container}>
        <div className={styles.checkbox_container}>
          <input
            disabled={disabled}
            id={checkboxId}
            type="checkbox"
            checked={values[checkboxId]}
            onChange={(e) => setFieldValue(checkboxId, e.target.checked)}
          />
        </div>
        <div className={styles.info_bubble} info-bubble-content={infoBubbleContent}>
          <svg
            className="slds-button__icon"
            focusable="false"
            data-key="info"
            aria-hidden="true"
            viewBox="0 0 52 52"
            part="icon"
            fill="grey"
          >
            <g>
              <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
            </g>
          </svg>
        </div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);

export default CheckboxOrganism;
