import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  actualStep: 1,
  numberOfSteps: 4,
  pending: false,
  errorMessage: "",
  id: null,
  stepsData: {
    yourNeed: null,
    dateAndPlace: null,
    positionToBeFilled: null,
  },
};

// THUNKS SECTION

export const postStep1Thunk = createAsyncThunk(
  "publishAnOffer/postStep1Thunk",
  async ({ dataToSend, axiosCancelToken }) => {
    const response = await axiosCompany().post("/v2/companies/job-offers", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const patchStepThunk = createAsyncThunk(
  "publishAnOffer/patchStepThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(`/v2/companies/job-offers/${id}`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

// CREATESLICE SECTION

const publishAnOfferSlice = createSlice({
  name: "publishAnOffer",
  initialState,
  reducers: {
    handlePreviousStep: (state) => {
      if (state.actualStep - 1 <= 0) {
        console.log("L'étape précédente n'existe pas !");
        return;
      }
      state.actualStep -= 1;
    },
    handleResetToStep1: (state) => {
      console.log("reset");
      state.actualStep = 1;
      state.pending = false;
      state.errorMessage = "";
      state.id = null;
      state.stepsData = {
        yourNeed: null,
        dateAndPlace: null,
        positionToBeFilled: null,
      };
    },
    handleStepsData: (state, action) => {
      console.log(action);
      state.stepsData[action.payload.formName] = action.payload.formData;
    },
  },
  extraReducers: {
    [postStep1Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [postStep1Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.id = action.payload.id;
      state.actualStep += 1;
    },
    [postStep1Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchStepThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchStepThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.actualStep += 1;
    },
    [patchStepThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getPublishAnOfferActualStep = (state) => state.publishAnOffer.actualStep;
export const getPublishAnOfferPending = (state) => state.publishAnOffer.pending;
export const getPublishAnOfferErrorMessage = (state) => state.publishAnOffer.errorMessage;
export const getPublishAnOfferId = (state) => state.publishAnOffer.id;
export const getPublishAnOfferYourNeedData = (state) => state.publishAnOffer.stepsData.yourNeed;
export const getPublishAnOfferDateAndPlace = (state) => state.publishAnOffer.stepsData.dateAndPlace;
export const getPublishAnOfferPositionToBeFilled = (state) => state.publishAnOffer.stepsData.positionToBeFilled;

// ACTIONS CREATOR EXPORTS SECTION

export const { handlePreviousStep, handleResetToStep1, handleStepsData } = publishAnOfferSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default publishAnOfferSlice.reducer;
