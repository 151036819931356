import { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { Listbox, DropdownList } from "react-widgets/cjs";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import { createBooleanReferential } from "utils/localReferentials";

import { removeEverythingExceptNumbers } from "utils/tools";

import {
  postFileStudentThunk,
  getUploadFilePending,
  getUploadFIleErrorMessage,
  downloadFileStudentThunk,
  resetFileData,
  setFileData,
  selectFileData,
} from "app/slices/uploadFile/uploadFile.slice";
import UploadDeleteOrganism from "components/UploadDeleteOrganism/UploadDeleteOrganism";

import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";

import {
  getOneCV,
  getYourCVStatus,
  getYourCVById,
  getYourCVByIdPending,
  getYourCardProps,
  patchCVByIdThunk,
  patchCVStatusToDeactivate,
} from "../../../app/slices/modifyYourCV/modifyYourCV.slice";

import {
  getAcademicExperiences,
  getAcademicExperiencesThunk,
} from "../../../app/slices/modifyYourCV/academicExperiences.slice";

import { getAcademicProjectsThunk, getProjects } from "../../../app/slices/modifyYourCV/academicProjects.slice";

import {
  getAssociativeExperiencesThunk,
  getAssociativeExperiences,
} from "../../../app/slices/modifyYourCV/associativeExperiences.slice";

import {
  getProfesionalExperiencesThunk,
  getProfesionalExperiences,
} from "../../../app/slices/modifyYourCV/professionalExperiences.slice";

import { getLanguageSkillsThunk, getLanguageSkills } from "../../../app/slices/modifyYourCV/languageSkills.slice";

import { getComputerSkillsThunk, getComputerSkills } from "../../../app/slices/modifyYourCV/computerSkills.slice";

import Header from "../../../components/Header/Header";
import DivLine from "../../../components/DivLine/DivLine";
import SubmitBtnModifyYourCV from "../../../components/SubmitBtnModifyYourCV/SubmitBtnModifyYourCV";

import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";

import ModalAcademicExperiences from "./academicExperiences/ModalAcademicExperiences";
import ModifyModalAcademicExperiences from "./academicExperiences/ModifyModalAcademicExperiences";
import PostModalAcademicExperiences from "./academicExperiences/PostModalAcademicExperiences";

import ModalAcademicProjects from "./academicProjects/ModalAcademicProjects";
import ModifyModalAcademicProjects from "./academicProjects/ModifyModalAcademicProjects";
import PostModalAcademicProjects from "./academicProjects/PostModalAcademicProjects";

import ModalAssociativeExperiences from "./associativeExperiences/ModalAssociativeExperiences";
import ModifyModalAssociativeExperiences from "./associativeExperiences/ModifyModalAssociativeExperiences";
import PostModalAssociativeExperiences from "./associativeExperiences/PostModalAssociativeExperiences";

import ModalProfesionalExperiences from "./profesionalExperiences/ModalProfesionalExperiences";
import ModifyModalProfesionalExperiences from "./profesionalExperiences/ModifyModalProfesionalExperiences";
import PostModalProfesionalExperiences from "./profesionalExperiences/PostModalProfesionalExperiences";

import ModifyModalLanguageSkills from "./languageSkills/ModifyModalLanguageSkills";
import PostModalLanguageSkills from "./languageSkills/PostModalLanguageSkills";

import ModifyModalComputerSkills from "./computerSkills/ModifyModalComputerSkills";
import PostModalComputerSkills from "./computerSkills/PostModalComputerSkills";

import ValidateFormModal from "./validateForm/ValidateFormModal";

import eyeIcon from "./assets/eye-svg.svg";
import pencilIcon from "./assets/pencil-svg.svg";
import styles from "./ModifyYourCV.module.scss";
import DisableBtnTrue from "../../../components/DisableBtnTrue/DisableBtnTrue";

import DocumentsToDownload from "./components/DocumentsToDownload/DocumentsToDownload";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

const ModifyYourCV = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const [modifyingOrNot, setModifyingOrNot] = useState(false);
  const cardProps = useSelector(getYourCardProps);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [deactivateOrNot, setDeactivateOrNot] = useState(false);

  const [showValidateForm, setShowValidateForm] = useState(false);

  const [firstCall, setFirstCall] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(false);
  const [typeOfExperience, setTypeOfExperience] = useState([]);
  const [speciality, setSpeciality] = useState([]);

  const [show, setShow] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [activeObject, setActiveObject] = useState();
  const [idForm, setIdForm] = useState(null);

  const [supportingDocument, setSupportingDocument] = useState(null);

  const fileData = useSelector(selectFileData);

  const [showAcademicProjects, setShowAcademicProjects] = useState(false);
  const [showModifyAcademicProjects, setShowModifyAcademicProjects] = useState(false);
  const [showPostAcademicProjects, setShowPostAcademicProjects] = useState(false);
  const [activeObjectAcademicProjects, setActiveObjectAcademicProjects] = useState([null]);
  const [idFormAcademicProjects, setIdFormAcademicProjects] = useState(null);

  const [showAssociativeExperiences, setShowAssociativeExperiences] = useState(false);
  const [showModifyAssociativeExperiences, setShowModifyAssociativeExperiences] = useState(false);
  const [showPostAssociativeExperiences, setShowPostAssociativeExperiences] = useState(false);
  const [activeObjectAssociativeExperiences, setActiveObjectAssociativeExperiences] = useState([null]);
  const [idFormAssociativeExperiences, setIdFormAssociativeExperiences] = useState(null);

  const [showProfesionalExperiences, setShowProfesionalExperiences] = useState(false);
  const [showModifyProfesionalExperiences, setShowModifyProfesionalExperiences] = useState(false);
  const [showPostProfesionalExperiences, setShowPostProfesionalExperiences] = useState(false);
  const [activeObjectProfesionalExperiences, setActiveObjectProfesionalExperiences] = useState([null]);
  const [idFormProfesionalExperiences, setIdFormProfesionalExperiences] = useState(null);

  const [activeObjectLanguageSkills, setActiveObjectLanguageSkills] = useState([null]);
  const [idFormLanguageSkills, setIdFormLanguageSkills] = useState(null);
  const [showModifyLanguageSkills, setShowModifyLanguageSkills] = useState(false);
  const [showPostLanguageSkills, setShowPostLanguageSkills] = useState(false);

  const [idFormComputerSkills, setIdFormComputerSkills] = useState(null);
  const [showModifyComputerSkills, setShowModifyComputerSkills] = useState(false);
  const [showPostComputerSkills, setShowPostComputerSkills] = useState(false);
  const [activeObjectComputerSkills, setActiveObjectComputerSkills] = useState([null]);

  const [file] = useState(null);
  // const [uploadAgree, setUploadAgree] = useState(false);
  const [href, setHref] = useState(null);
  const [fileType, setFileType] = useState(null);
  // const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);

  const status = useSelector(getYourCVStatus);
  const CVData = useSelector(getYourCVById);
  const pending = useSelector(getYourCVByIdPending);
  const CVAcademicExperiences = useSelector(getAcademicExperiences);
  const CVProjects = useSelector(getProjects);
  const CVAssociativeExperiences = useSelector(getAssociativeExperiences);
  const CVProfesionalExperiences = useSelector(getProfesionalExperiences);
  const CVLanguageSkills = useSelector(getLanguageSkills);
  const CVComputerSkills = useSelector(getComputerSkills);
  const referentialData = useSelector(getReferentialsData);
  const userToken = useSelector(getUserToken);

  const YesNo = createBooleanReferential();
  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const handleOpen = () => {
    setShow(true);
  };

  const handleOpenAcademicProjects = () => {
    setShowAcademicProjects(true);
  };

  const handleOpenAssociativeExperiences = () => {
    setShowAssociativeExperiences(true);
  };

  const handleOpenProfesionalExperiences = () => {
    setShowProfesionalExperiences(true);
  };

  useEffect(() => {
    if (!cardProps) {
      navigate("/mes-cvs");
    } else {
      setSupportingDocument(cardProps?.supportingDocument);
    }
  }, [cardProps]);

  useEffect(() => {
    if (!pending) {
      if (!firstCall && userToken && CVData !== undefined) {
        (async () => {
          try {
            setFirstCall(true);
            await dispatch(getOneCV(id)).unwrap();
            await dispatch(getAcademicExperiencesThunk(id)).unwrap();
            await dispatch(getAcademicProjectsThunk(id)).unwrap();
            await dispatch(getAssociativeExperiencesThunk(id)).unwrap();
            await dispatch(getProfesionalExperiencesThunk(id)).unwrap();
            await dispatch(getLanguageSkillsThunk(id)).unwrap();
            await dispatch(getComputerSkillsThunk(id)).unwrap();
            setFirstCall(false);
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModify && userToken) {
        (async () => {
          try {
            await dispatch(getAcademicExperiencesThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModifyAcademicProjects && userToken) {
        (async () => {
          try {
            await dispatch(getAcademicProjectsThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModifyAssociativeExperiences && userToken) {
        (async () => {
          try {
            await dispatch(getAssociativeExperiencesThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModifyProfesionalExperiences && userToken) {
        (async () => {
          try {
            await dispatch(getProfesionalExperiencesThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModifyLanguageSkills && userToken) {
        (async () => {
          try {
            await dispatch(getLanguageSkillsThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
      if (!showModifyComputerSkills && userToken) {
        (async () => {
          try {
            await dispatch(getComputerSkillsThunk(id)).unwrap();
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showValidateForm,
    userToken,
    showPost,
    showModify,
    show,
    showPostAcademicProjects,
    showAcademicProjects,
    showModifyAcademicProjects,
    showPostAssociativeExperiences,
    showAssociativeExperiences,
    showModifyAssociativeExperiences,
    showPostProfesionalExperiences,
    showProfesionalExperiences,
    showModifyProfesionalExperiences,
    showModifyLanguageSkills,
    showPostLanguageSkills,
    showModifyComputerSkills,
    showPostComputerSkills,
  ]);

  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          console.log("FILE CV UPLOAD", file);
          await dispatch(
            postFileStudentThunk({
              file,
              endpoint: `/v1/students/resumes/${id}/supporting-documents`,
              axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersAjoutes")}
              secondMessage={`${t("app.toastMessages.FichiersAjoutesMessage")} 1`}
            />,
          );
          // setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          console.log("upload success TestUploadFile !");
        } catch (error) {
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.ImpossibleLoad")} secondMessage={file.name} />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  /* eslint-disable */
  const handleType = (type) => {
    switch (type) {
      case "ai" || "eps":
        return "application/postscript";
      case "txt":
        return "text/plain";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "html":
        return "text/html";
      case "csv":
        return "text/csv";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "xls":
        return "application/vnd.ms-excel";
      case "ods":
        return "application/vnd.oasis.opendocument.spreadsheet";
      case "exe":
        return "application/octet-stream";
      case "mp4":
        return "video/mp4";
      case "pgs":
        return "text/plain";
      case "psd":
        return "image/vnd.adobe.photoshop";
      case "rtf":
        return "application/rtf";
      case "xml":
        return "application/xml";
      case "xmd":
        return "application/octet-stream";
      case "zip":
        return "application/zip";
      default:
        return "application/zip";
    }
  };

  useEffect(() => {
    if (cardProps?.supportingDocument?.downloadUrl !== undefined) {
      (async () => {
        try {
          setFileType(handleType(cardProps?.supportingDocument?.fileExtension));
          await dispatch(
            downloadFileStudentThunk({
              id: cardProps?.id,
              axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: fileType }));
              setHref(href);
              window.URL.revokeObjectURL(url);
            });
        } catch (error) {
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.ImpossibleLoad")} secondMessage={file.name} />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
  }, [cardProps]);

  useLayoutEffect(() => {
    if (cardProps?.supportingDocument) {
      dispatch(setFileData(cardProps.supportingDocument));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(
    () => () => {
      // axiosCancelToken.cancel();
      dispatch(resetFileData());
      console.log("ModifyYourCV unmount");
    },
    [],
  );

  const handleUpload = () => {
    if (!filePending) {
      (async () => {
        try {
          await dispatch(
            downloadFileStudentThunk({
              id: cardProps?.id,
              axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: "application/zip" }));
              setHref(href);
            });
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersTelecharges")}
              secondMessage={`${t("app.toastMessages.FichiersTelechargesMessage")} 1`}
            />,
          );
        } catch (error) {
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.ImpossibleLoad")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onSubmit = (values) => {
    const dataToSend = {
      status:
        // METHOD TO KEEP EN COURS DE MODIFICATION AND EN COURS DE SAISIE
        // WHEN ACTIVATE CV IS FIRED EN COURS DE SAISIE STATUS IS PATCHED
        // status?.key !== "Désactivé" && !deactivateOrNot
        // 	? status?.key !== "Complété" || status?.key === "En cours de saisie"
        // 		? "En cours de modification"
        // 		: "En cours de modification"
        // 	: "En cours de modification",
        // METHOD TO KEEP ONLY EN COURS DE SAISIE
        status?.key !== "Désactivé" && !deactivateOrNot
          ? status?.key !== "Complété"
            ? "En cours de saisie"
            : "En cours de saisie"
          : "En cours de saisie",
      lang: values.language.key,
      title: values.title,
      speciality: values.specialty.key,
      subSpeciality: values.subSpeciality.key === "-Aucun-" ? "" : values.subSpeciality.key,
      contractTypes:
        values.typeOfExperience.filter((e) => e.key)?.length > 0
          ? values.typeOfExperience.map((e) => e.key)
          : values.typeOfExperience,
      durationInternship: values.durationInternship.key === "-Aucun-" ? "" : values.durationInternship.key,
      durationContract: values.durationContract.key === "-Aucun-" ? "" : values.durationContract.key,
      workStudyProgram:
        values.workStudyProgram !== null && values.workStudyProgram !== undefined ? values.workStudyProgram : "",
      availableFrom: formatDate(values.startDate),
      recognizedDisabilityStatus: values.rqth === "Oui" ? true : false,
      educationLevel: values.targetLevelOfStudy.key === "-Aucun-" ? "" : values.targetLevelOfStudy.key,
      introduceYourself: values.introduceYourself,
      frenchLevel: values.frenchLevel.key === "-Aucun-" ? "" : values.frenchLevel.key,
      mobility: values.mobilityValue.key,
      mobilityComment: values.mobilityComment,
      carLicence:
        values.carLicence !== undefined && values.carLicence !== null && values.carLicence === "Oui" ? true : false,
      motorCycleLicence:
        values.motorCycleLicence !== undefined &&
        values.motorCycleLicence !== null &&
        values.motorCycleLicence === "Oui"
          ? true
          : false,
      hasVehicle:
        values.hasVehicle !== undefined && values.hasVehicle !== null && values.hasVehicle === "Oui" ? true : false,
      extraActivities: values.extraActivities,
      phone: values.phone,
      email: values.email,
      instagramUrl:
        values.instagramUrl === null || values.instagramUrl === undefined || values.instagramUrl === ""
          ? null
          : values.instagramUrl,
      youtubeUrl:
        values.youtubeUrl === null || values.youtubeUrl === undefined || values.youtubeUrl === ""
          ? null
          : values.youtubeUrl,
      linkedinUrl:
        values.linkedinUrl === null || values.linkedinUrl === undefined || values.linkedinUrl === ""
          ? null
          : values.linkedinUrl,
      blogUrl: values.blogUrl === null || values.blogUrl === undefined || values.blogUrl === "" ? null : values.blogUrl,
      videoUrl:
        values.videoUrl === null || values.videoUrl === undefined || values.videoUrl === "" ? null : values.videoUrl,
      // main: true,
      public: checked,
      skills: {
        softSkills:
          values.softSkills.filter((e) => e.key)?.length > 0 ? values.softSkills.map((e) => e.key) : values.softSkills,
      },
    };
    if (!pending) {
      (async () => {
        try {
          setModifyingOrNot(true);
          await dispatch(
            patchCVByIdThunk({
              dataToSend,
              id,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.successCvModifyTitle")}
              secondMessage={t("app.toastMessages.successCvModifyMessage")}
            />,
          );
          setShowValidateForm(true);
        } catch (error) {
          console.log(error);
          setShowValidateForm(false);
        }
      })();
    }
  };

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

  const ModifyYourCVSchema = Yup.object().shape({
    typeOfExperience: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    title: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    language: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    durationInternship: Yup.object().when([], {
      is: () =>
        typeOfExperience.map((e) => e.key).includes("Stage") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Stage"),
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    durationContract: Yup.object().when([], {
      is: () =>
        typeOfExperience.map((e) => e.key).includes("Emploi CDI") ||
        typeOfExperience.map((e) => e.key).includes("Emploi CDD") ||
        typeOfExperience.map((e) => e.key).includes("Alternance") ||
        typeOfExperience.map((e) => e.key).includes("VIE") ||
        typeOfExperience.map((e) => e.key).includes("Service Civique") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Emploi CDI") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Emploi CDD") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Alternance") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("VIE") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Sercive civique"),
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    workStudyProgram: Yup.string().when([], {
      is: () =>
        typeOfExperience.map((e) => e.key).includes("Alternance") ||
        cardProps.search.contractTypes.map((e) => e.key).includes("Alternance"),
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),
    subSpeciality: Yup.object().when([], {
      is: () =>
        values.specialty.key === "Finance" ||
        values.specialty.key === "Ingéniérie" ||
        values.specialty.key === "Management" ||
        values.specialty.key === "Sciences Politiques et Relations Internationales",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    specialty: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    rqth: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== "-Aucun-"),
    carLicence: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== "-Aucun-"),
    mobilityValue: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    motorCycleLicence: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== "-Aucun-"),
    hasVehicle: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== "-Aucun-"),
    introduceYourself: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    mobilityComment: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    softSkills: Yup.array()
      .min(1, t("app.errorMessages.SelectionnerOption"))
      .max(5, t("app.errorMessages.fiveChoicesMaximum")),
    phone: Yup.string()
      .matches(phoneRegExp, t("app.errorMessages.PhoneFormat"))
      .required(t("app.errorMessages.PhoneFormat")),
    extraActivities: Yup.string().required(t("app.errorMessages.RemplirChamp")).nullable(),
    email: Yup.string().email().required(t("app.errorMessages.MailFormat")),
    videoUrl: Yup.string().url(t("app.errorMessages.urlFormat")).nullable(),
  });

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      status: cardProps?.status?.key ? cardProps?.status?.key : "",
      startDate: cardProps?.search?.availableFrom ? new Date(cardProps?.search?.availableFrom) : null,
      title: cardProps?.title,
      workStudyProgram: cardProps?.search?.workStudyProgram,
      language: cardProps?.lang
        ? {
            key: cardProps?.lang?.key,
            label: cardProps?.lang?.key,
          }
        : { ...objAucun },
      typeOfExperience: cardProps?.search?.contractTypes ? cardProps?.search?.contractTypes?.map((e) => e.key) : [],
      durationInternship: cardProps?.search?.durationInternship
        ? {
            key: cardProps?.search?.durationInternship?.key,
            label: cardProps?.search?.durationInternship?.label,
          }
        : { ...objAucun },
      durationContract: cardProps?.search?.durationContract
        ? {
            key: cardProps?.search?.durationContract?.key,
            label: cardProps?.search?.durationContract?.key,
          }
        : { ...objAucun },
      availableFrom: cardProps?.search?.availableFrom,
      rqth: cardProps?.search?.recognizedDisabilityStatus
        ? "Oui"
        : cardProps?.search?.recognizedDisabilityStatus === "-Aucun-"
          ? "-Aucun-"
          : "Non",
      specialty: cardProps?.search?.speciality
        ? {
            key: cardProps?.search?.speciality?.key,
            label: cardProps?.search?.speciality?.label,
          }
        : { ...objAucun },
      subSpeciality: cardProps?.search?.subSpeciality
        ? {
            key: cardProps?.search?.subSpeciality?.key,
            label: cardProps?.search?.subSpeciality?.label,
          }
        : { ...objAucun },
      targetLevelOfStudy: cardProps?.profile?.educationLevel
        ? {
            key: cardProps?.profile?.educationLevel?.key,
            label: cardProps?.profile?.educationLevel?.label,
          }
        : { ...objAucun },
      introduceYourself: cardProps?.profile?.introduceYourself ? cardProps?.profile?.introduceYourself : "",
      frenchLevel: cardProps?.profile?.frenchLevel
        ? {
            key: cardProps?.profile?.frenchLevel?.key,
            label: cardProps?.profile?.frenchLevel?.label,
          }
        : { ...objAucun },
      mobilityValue: cardProps?.mobility?.mobility
        ? {
            key: cardProps?.mobility?.mobility?.key,
            label: cardProps?.mobility?.mobility?.label,
          }
        : { ...objAucun },
      mobilityComment: cardProps?.mobility?.mobilityComment ? cardProps?.mobility?.mobilityComment : "",
      carLicence: cardProps?.mobility?.carLicence
        ? "Oui"
        : cardProps?.mobility?.carLicence === "-Aucun-"
          ? "-Aucun-"
          : "Non",
      motorCycleLicence: cardProps?.mobility?.motorcycleLicence
        ? "Oui"
        : cardProps?.mobility?.motorcycleLicence === "-Aucun-"
          ? "-Aucun-"
          : "Non",
      hasVehicle: cardProps?.mobility?.hasVehicle
        ? "Oui"
        : cardProps?.mobility?.hasVehicle === "-Aucun-"
          ? "-Aucun-"
          : "Non",
      softSkills: cardProps?.skills?.softSkills ? cardProps?.skills?.softSkills?.map((e) => e.key) : [],
      phone: cardProps?.contact?.phone ? removeEverythingExceptNumbers(cardProps?.contact?.phone) : "",
      email: cardProps?.contact?.email ? cardProps?.contact?.email : "",
      instagramUrl: cardProps?.contact?.instagramUrl ? cardProps?.contact?.instagramUrl : null,
      youtubeUrl: cardProps?.contact?.youtubeUrl ? cardProps?.contact?.youtubeUrl : null,
      linkedinUrl: cardProps?.contact?.linkedinUrl ? cardProps?.contact?.linkedinUrl : null,
      blogUrl: cardProps?.contact?.blogUrl ? cardProps?.contact?.blogUrl : null,
      videoUrl: cardProps?.contact?.videoUrl ? cardProps?.contact?.videoUrl : null,
      extraActivities: cardProps?.extraActivities ? cardProps?.extraActivities : null,
    },
    validationSchema: ModifyYourCVSchema,
    onSubmit,
  });

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      {referentialData && CVData && (
        <>
          <Header h1={t("app.visualisationCv.ConsultezVosCV")} textElement={<p>{t("app.visualisationCv.Intro1")}</p>} />
          <section className={styles.cv_list_container}>
            <div className={styles.divline_h2_container}>
              <DivLine />
              <h2 className={styles.margin_h2}>{t("app.visualisationCv.InformationCV")}</h2>
            </div>
            <div className={styles.button_container}>
              <DisableBtnTrue
                disabled={status?.key === "Désactivé" ? false : true}
                onClick={() => {
                  setChecked(false);
                  status?.key === "Désactivé" ? setDeactivateOrNot(true) : setDeactivateOrNot(false);
                  (async () => {
                    if (!pending) {
                      try {
                        setLoading(true);
                        await dispatch(
                          patchCVStatusToDeactivate({
                            dataToSend: {
                              status: status?.key === "Désactivé" ? "En cours de saisie" : "Désactivé",
                            },
                            id: id,
                          }),
                        ).unwrap();
                        if (status?.key === "Désactivé")
                          toastSuccess(
                            <ToastMessageStructure
                              firstMessage={t("app.toastMessages.ActivationCV")}
                              secondMessage={t("app.toastMessages.ActivationCVSuccess")}
                            />,
                          );
                        else if (status?.key === "En cours de saisie" || status?.key === "Complété")
                          toastSuccess(
                            <ToastMessageStructure
                              firstMessage={t("app.toastMessages.DesactivationCV")}
                              secondMessage={t("app.toastMessages.DesactivationCVSuccess")}
                            />,
                          );
                        await dispatch(getOneCV(id)).unwrap();
                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        console.log("status updated failed !", error.message);
                      }
                    }
                  })();
                }}
              />
              {/* <CVExportBtn
								text={t("app.visualisationCv.ExporterCV")}
								img={downloadIcon}
							/> */}
            </div>
            {loading ? (
              <h1>Changement du statut en cours...</h1>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className={styles.form_container}>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.VotreRecherche")}</div>
                  <div className={styles.cv_list_first_pane}>
                    <div className={styles.col}>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.TitreCV")}
                        </label>
                        <input
                          style={
                            errors.title &&
                            touched.title && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          value={values.title}
                          type="text"
                          name="title"
                          id="title"
                          onChange={(e) => setFieldValue("title", e.target.value.trimStart())}
                        />
                        <InputErrorMessage message={errors.title} touched={touched.title} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.LangueCV")}
                        </label>
                        <DropdownList
                          style={
                            errors.language &&
                            touched.language && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="language"
                          dataKey="key"
                          textField="label"
                          data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_LANG]}
                          value={values.language.key}
                          onChange={(newValue) => setFieldValue("language", newValue)}
                        />
                        <InputErrorMessage message={errors.language} touched={errors.language} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.DureeStage")}
                        </label>
                        {status?.key === "Désactivé" ? (
                          <DropdownList
                            disabled
                            className={styles.dropDownList}
                            id="durationInternship"
                            dataKey="key"
                            textField="label"
                            data={[{ ...objAucun }, ...referentialData.INTERNSHIP_DURATION]}
                            value={
                              values.typeOfExperience
                                .map((e) => {
                                  return e;
                                })
                                .includes("Stage")
                                ? values.durationInternship.key
                                : ""
                            }
                            onChange={(newValue) => setFieldValue("durationInternship", newValue)}
                          />
                        ) : (
                          <>
                            <DropdownList
                              style={
                                errors.durationInternship &&
                                touched.durationInternship && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              disabled={
                                values.typeOfExperience.map((e) => e).includes("Stage")
                                  ? false
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Stage")
                                    ? false
                                    : true
                              }
                              className={styles.dropDownList}
                              id="durationInternship"
                              dataKey="key"
                              textField="label"
                              data={[{ ...objAucun }, ...referentialData.INTERNSHIP_DURATION]}
                              value={
                                values.typeOfExperience.map((e) => e).includes("Stage")
                                  ? values.durationInternship.key
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Stage")
                                    ? values.durationInternship.key
                                    : { ...objAucun }
                              }
                              onChange={(newValue) => setFieldValue("durationInternship", newValue)}
                            />
                            <InputErrorMessage
                              message={errors.durationInternship}
                              touched={errors.durationInternship}
                            />
                          </>
                        )}
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.DureeContrat")}
                        </label>
                        {status?.key === "Désactivé" ? (
                          <DropdownList
                            disabled
                            className={styles.dropDownList}
                            id="durationContract"
                            dataKey="key"
                            textField="label"
                            data={[{ ...objAucun }, ...referentialData.CONTRACT_DURATION]}
                            value={values.durationContract.key}
                            onChange={(newValue) => setFieldValue("durationContract", newValue)}
                          />
                        ) : (
                          <>
                            <DropdownList
                              style={
                                errors.durationContract &&
                                touched.durationContract && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              disabled={
                                values.typeOfExperience.map((e) => e).includes("Emploi CDI")
                                  ? false
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Emploi CDI")
                                    ? false
                                    : values.typeOfExperience.map((e) => e).includes("Emploi CDD")
                                      ? false
                                      : typeOfExperience?.length > 0 &&
                                          typeOfExperience?.map((e) => e.key).includes("Emploi CDD")
                                        ? false
                                        : values.typeOfExperience.map((e) => e).includes("Alternance")
                                          ? false
                                          : typeOfExperience?.length > 0 &&
                                              typeOfExperience?.map((e) => e.key).includes("Alternance")
                                            ? false
                                            : values.typeOfExperience.map((e) => e).includes("VIE")
                                              ? false
                                              : typeOfExperience?.length > 0 &&
                                                  typeOfExperience?.map((e) => e.key).includes("VIE")
                                                ? false
                                                : values.typeOfExperience.map((e) => e).includes("Service civique")
                                                  ? false
                                                  : typeOfExperience?.length > 0 &&
                                                      typeOfExperience?.map((e) => e.key).includes("Service civique")
                                                    ? false
                                                    : true
                              }
                              className={styles.dropDownList}
                              id="durationContract"
                              dataKey="key"
                              textField="label"
                              data={[{ ...objAucun }, ...referentialData.CONTRACT_DURATION]}
                              value={
                                values.typeOfExperience.map((e) => e).includes("Emploi CDI")
                                  ? values.durationContract.key
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Emploi CDI")
                                    ? values.durationContract.key
                                    : values.typeOfExperience.map((e) => e).includes("Emploi CDD")
                                      ? values.durationContract.key
                                      : typeOfExperience?.length > 0 &&
                                          typeOfExperience?.map((e) => e.key).includes("Emploi CDD")
                                        ? values.durationContract.key
                                        : values.typeOfExperience.map((e) => e).includes("Alternance")
                                          ? values.durationContract.key
                                          : typeOfExperience?.length > 0 &&
                                              typeOfExperience?.map((e) => e.key).includes("Alternance")
                                            ? values.durationContract.key
                                            : values.typeOfExperience.map((e) => e).includes("VIE")
                                              ? values.durationContract.key
                                              : typeOfExperience?.length > 0 &&
                                                  typeOfExperience?.map((e) => e.key).includes("VIE")
                                                ? values.durationContract.key
                                                : values.typeOfExperience.map((e) => e).includes("Service civique")
                                                  ? values.durationContract.key
                                                  : typeOfExperience?.length > 0 &&
                                                      typeOfExperience?.map((e) => e.key).includes("Service civique")
                                                    ? values.durationContract.key
                                                    : { ...objAucun }
                              }
                              onChange={(newValue) => {
                                setFieldValue("durationContract", newValue);
                              }}
                            />
                            <InputErrorMessage message={errors.durationContract} touched={errors.durationContract} />
                          </>
                        )}
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.RythmeAlternance")}
                        </label>
                        {status?.key === "Désactivé" ? (
                          <>
                            <input
                              disabled
                              value={values.workStudyProgram}
                              type="text"
                              name="workStudyProgram"
                              id="workStudyProgram"
                              onChange={(e) => setFieldValue("workStudyProgram", e.target.value.trimStart())}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              style={
                                errors.workStudyProgram &&
                                touched.workStudyProgram && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              disabled={
                                values.typeOfExperience.map((e) => e).includes("Alternance")
                                  ? false
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Alternance")
                                    ? false
                                    : true
                              }
                              value={
                                values.typeOfExperience.map((e) => e).includes("Alternance")
                                  ? values.workStudyProgram
                                  : typeOfExperience?.length > 0 &&
                                      typeOfExperience?.map((e) => e.key).includes("Alternance")
                                    ? values.workStudyProgram
                                    : ""
                              }
                              type="text"
                              name="workStudyProgram"
                              id="workStudyProgram"
                              onChange={(e) => setFieldValue("workStudyProgram", e.target.value.trimStart())}
                            />
                            <InputErrorMessage message={errors.workStudyProgram} touched={touched.workStudyProgram} />
                          </>
                        )}
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.Dispo")}
                        </label>
                        {/* <DatePicker
													style={
														errors.startDate &&
														touched.startDate && {
															border: "2px solid #EA001E",
															borderRadius: "5px",
														}
													}
													disabled={status?.key === "Désactivé" ? true : false}
													value={values.startDate}
													onChange={(value) =>
														setFieldValue("startDate", value)
													}
												/> */}
                        <DatePickerCustom
                          error={errors.startDate}
                          disabled={status?.key === "Désactivé" ? true : false}
                          touched={touched.startDate}
                          value={values.startDate}
                          onChange={(value) => setFieldValue("startDate", value)}
                        />
                        <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.Specialite")}
                        </label>
                        <DropdownList
                          style={
                            errors.specialty &&
                            touched.specialty && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="specialty"
                          dataKey="key"
                          textField="label"
                          data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_SPECIALITY]}
                          value={values.specialty.key}
                          onChange={(newValue) => {
                            setSpeciality(newValue);
                            setFieldValue("specialty", newValue);
                            setFieldValue("subSpeciality", { ...objAucun });
                          }}
                        />
                        <InputErrorMessage message={errors.specialty} touched={touched.specialty} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.SousSpecialite")}
                        </label>
                        {status?.key === "Désactivé" ? (
                          <>
                            <DropdownList
                              disabled
                              className={styles.dropDownList}
                              id="subSpeciality"
                              dataKey="key"
                              textField="label"
                              value={{ ...objAucun }}
                              data={
                                values.specialty.key === "Management"
                                  ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Management]
                                  : values.specialty.key === "Finance"
                                    ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Finance]
                                    : values.specialty.key === "Ingéniérie"
                                      ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Ingéniérie]
                                      : values.specialty.key === "Sciences Politiques et Relations Internationales"
                                        ? [
                                            { ...objAucun },

                                            ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Sciences_Politiques_et_Relations_Internationales,
                                          ]
                                        : [{ ...objAucun }]
                              }
                              onChange={(newValue) => setFieldValue("subSpeciality", newValue)}
                            />
                            <InputErrorMessage message={errors.subSpeciality} touched={touched.subSpeciality} />
                          </>
                        ) : (
                          <>
                            <DropdownList
                              style={
                                errors.subSpeciality &&
                                touched.subSpeciality && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              disabled={
                                (values.specialty?.key === "Finance"
                                  ? false
                                  : speciality?.length > 0 && speciality?.map((e) => e.key).includes("Finance")
                                    ? false
                                    : true) &&
                                (values.specialty?.key === "Management"
                                  ? false
                                  : speciality?.length > 0 && speciality?.map((e) => e.key).includes("Management")
                                    ? false
                                    : true) &&
                                (values.specialty?.key === "Ingéniérie"
                                  ? false
                                  : speciality?.length > 0 && speciality?.map((e) => e.key).includes("Ingéniérie")
                                    ? false
                                    : true) &&
                                (values.specialty?.key === "Sciences Politiques et Relations Internationales"
                                  ? false
                                  : speciality?.length > 0 &&
                                      speciality
                                        ?.map((e) => e.key)
                                        .includes("Sciences Politiques et Relations Internationales")
                                    ? false
                                    : true)
                              }
                              className={styles.dropDownList}
                              id="subSpeciality"
                              dataKey="key"
                              textField="label"
                              value={values.subSpeciality}
                              data={
                                values.specialty.key === "Management"
                                  ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Management]
                                  : values.specialty.key === "Finance"
                                    ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Finance]
                                    : values.specialty.key === "Ingéniérie"
                                      ? [{ ...objAucun }, ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Ingéniérie]
                                      : values.specialty.key === "Sciences Politiques et Relations Internationales"
                                        ? [
                                            { ...objAucun },
                                            ...referentialData.STUDENT_RESUME_SUBSPECIALITY_Sciences_Politiques_et_Relations_Internationales,
                                          ]
                                        : [{ ...objAucun }]
                              }
                              onChange={(newValue) => setFieldValue("subSpeciality", newValue)}
                            />
                            <InputErrorMessage message={errors.subSpeciality} touched={touched.subSpeciality} />
                          </>
                        )}
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.RQTH")}
                        </label>
                        <DropdownList
                          style={
                            errors.rqth &&
                            touched.rqth && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="rqth"
                          dataKey="key"
                          textField="label"
                          data={[...YesNo]}
                          value={values.rqth}
                          onChange={(newValue) => {
                            setFieldValue("rqth", newValue.key);
                          }}
                        />
                        <InputErrorMessage message={errors.rqth} touched={touched.rqth} />
                      </div>
                    </div>
                    <div className={`${styles.col} ${styles.allWidthCol}`}>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="typeOfExperience">
                          {t("app.visualisationCv.TypeDeContrat")}
                        </label>
                        <Listbox
                          style={
                            errors.typeOfExperience &&
                            touched.typeOfExperience && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          id="typeOfExperience"
                          multiple
                          dataKey="key"
                          textField="label"
                          data={[...referentialData.CONTRACT_TYPE]}
                          defaultValue={values.typeOfExperience}
                          onChange={(newValue) => {
                            setTypeOfExperience(newValue);
                            setFieldValue("typeOfExperience", newValue);
                          }}
                        />
                        <InputErrorMessage message={errors.typeOfExperience} touched={touched.typeOfExperience} />
                      </div>
                      <div className={styles.label_plus_input_container}></div>
                    </div>
                  </div>
                  <div className={styles.cv_separator}></div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.VotreProfil")}</div>
                  <div className={styles.cv_list_first_pane}>
                    <div className={styles.col}>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.NiveauEtudesActuel")}
                        </label>
                        <DropdownList
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="targetLevelOfStudy"
                          dataKey="key"
                          textField="label"
                          data={[{ ...objAucun }, ...referentialData.STUDENT_EDUCATION_LEVEL]}
                          value={values.targetLevelOfStudy}
                          onChange={(newValue) => {
                            setFieldValue("targetLevelOfStudy", newValue);
                          }}
                        />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.PermisAuto")}
                        </label>
                        <DropdownList
                          style={
                            errors.carLicence &&
                            touched.carLicence && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="carLicence"
                          dataKey="key"
                          textField="label"
                          data={[...YesNo]}
                          value={values.carLicence}
                          onChange={(newValue) => {
                            setFieldValue("carLicence", newValue.key);
                          }}
                        />
                        <InputErrorMessage message={errors.carLicence} touched={touched.carLicence} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.Mobilite")}
                        </label>
                        <DropdownList
                          style={
                            errors.mobilityValue &&
                            touched.mobilityValue && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="mobilityValue"
                          dataKey="key"
                          textField="label"
                          data={[{ ...objAucun }, ...referentialData.MOBILITY_LEVEL]}
                          value={values.mobilityValue}
                          onChange={(newValue) => {
                            setFieldValue("mobilityValue", newValue);
                          }}
                        />
                        <InputErrorMessage message={errors.mobilityValue} touched={touched.mobilityValue} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.PermisMoto")}
                        </label>
                        <DropdownList
                          style={
                            errors.motorCycleLicence &&
                            touched.motorCycleLicence && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="motorCycleLicence"
                          dataKey="key"
                          textField="label"
                          data={[...YesNo]}
                          value={values.motorCycleLicence}
                          onChange={(newValue) => {
                            setFieldValue("motorCycleLicence", newValue.key);
                          }}
                        />
                        <InputErrorMessage message={errors.motorCycleLicence} touched={touched.motorCycleLicence} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label className={styles.label_padding} htmlFor="title">
                          {t("app.visualisationCv.Vehicule")}
                        </label>
                        <DropdownList
                          style={
                            errors.hasVehicle &&
                            touched.hasVehicle && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          className={styles.dropDownList}
                          id="hasVehicle"
                          dataKey="key"
                          textField="label"
                          data={[...YesNo]}
                          value={values.hasVehicle}
                          onChange={(newValue) => {
                            setFieldValue("hasVehicle", newValue.key);
                          }}
                        />
                        <InputErrorMessage message={errors.hasVehicle} touched={touched.hasVehicle} />
                      </div>
                    </div>

                    <div className={`${styles.col} ${styles.Rightcol}`}>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.label_padding} htmlFor="introduceYourself">
                          {t("app.visualisationCv.PresentezVous")}
                        </label>
                        <textarea
                          style={
                            errors.introduceYourself &&
                            touched.introduceYourself && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          value={values.introduceYourself}
                          type="introduceYourself"
                          name="introduceYourself"
                          id="introduceYourself"
                          onChange={(e) => setFieldValue("introduceYourself", e.target.value.trimStart())}
                          maxLength="500"
                        ></textarea>
                        <InputErrorMessage message={errors.introduceYourself} touched={touched.introduceYourself} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.label_padding} htmlFor="mobilityComment">
                          {t("app.depotCv.PrecisezMobilite")}
                        </label>
                        <textarea
                          style={
                            errors.mobilityComment &&
                            touched.mobilityComment && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          disabled={status?.key === "Désactivé" ? true : false}
                          value={values.mobilityComment}
                          type="mobilityComment"
                          name="mobilityComment"
                          id="mobilityComment"
                          onChange={(e) => setFieldValue("mobilityComment", e.target.value.trimStart())}
                          maxLength="255"
                        ></textarea>
                        <InputErrorMessage message={errors.mobilityComment} touched={touched.mobilityComment} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.cv_separator}></div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.Experiences")}</div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.ExperienceAcademique")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVAcademicExperiences?.length > 0 &&
                      CVAcademicExperiences.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>{e.course}</div>
                            <div className={styles.subtitle_experience_card}>{e.institution}</div>
                            <div className={styles.date_experience_card}>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Du")} {e.startDate}
                              </div>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Au")} {e.endDate}
                              </div>
                            </div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              id={e.id}
                              onClick={() => {
                                setActiveObject(e) || handleOpen() || setIdForm(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={eyeIcon} alt="eye"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Consulter")}</p>
                            </button>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObject(e) || setShowModify(true) || setIdForm(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.add_experience_container}>
                      <button
                        disabled={status?.key === "Désactivé" || CVAcademicExperiences?.length >= 5}
                        type="button"
                        onClick={() => setShowPost(true)}
                        className={styles.add_experiences}
                      >
                        {t("app.visualisationCv.AjouterExpAcademique")}
                      </button>
                    </div>
                  </div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.ProjetAcademiques")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVProjects?.length > 0 &&
                      CVProjects.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>{e.title}</div>
                            <div className={styles.date_experience_card}>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Du")} {e.startDate}
                              </div>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Au")} {e.endDate}
                              </div>
                            </div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              id={e.id}
                              onClick={() => {
                                setActiveObjectAcademicProjects(e) ||
                                  handleOpenAcademicProjects() ||
                                  setIdFormAcademicProjects(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={eyeIcon} alt="eye"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Consulter")}</p>
                            </button>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObjectAcademicProjects(e) ||
                                  setShowModifyAcademicProjects(true) ||
                                  setIdFormAcademicProjects(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className={styles.add_experience_container}>
                    <button
                      disabled={status?.key === "Désactivé" || CVProjects?.length >= 5}
                      type="button"
                      onClick={() => setShowPostAcademicProjects(true)}
                      className={styles.add_experiences}
                    >
                      {t("app.visualisationCv.AjouterProjetAcademique")}
                    </button>
                  </div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.ExperiencesAssociatives")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVAssociativeExperiences?.length > 0 &&
                      CVAssociativeExperiences.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>{e.organizationName}</div>
                            <div className={styles.date_experience_card}>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Du")} {e.startDate}
                              </div>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Au")} {e.endDate}
                              </div>
                            </div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              id={e.id}
                              onClick={() => {
                                setActiveObjectAssociativeExperiences(e) ||
                                  handleOpenAssociativeExperiences() ||
                                  setIdFormAssociativeExperiences(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={eyeIcon} alt="eye"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Consulter")}</p>
                            </button>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObjectAssociativeExperiences(e) ||
                                  setShowModifyAssociativeExperiences(true) ||
                                  setIdFormAssociativeExperiences(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className={styles.add_experience_container}>
                    <button
                      disabled={status?.key === "Désactivé" || CVAssociativeExperiences?.length >= 5}
                      type="button"
                      onClick={() => setShowPostAssociativeExperiences(true)}
                      className={styles.add_experiences}
                    >
                      {t("app.visualisationCv.AjouterExpAssociative")}
                    </button>
                  </div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.ExperienceProfessionnelle")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVProfesionalExperiences?.length > 0 &&
                      CVProfesionalExperiences.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>{e.organizationName}</div>
                            <div className={styles.date_experience_card}>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Du")} {e.startDate}
                              </div>
                              <div>
                                {" "}
                                {t("app.visualisationCv.Au")} {e.endDate}
                              </div>
                            </div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              id={e.id}
                              onClick={() => {
                                setActiveObjectProfesionalExperiences(e) ||
                                  handleOpenProfesionalExperiences() ||
                                  setIdFormProfesionalExperiences(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={eyeIcon} alt="eye"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Consulter")}</p>
                            </button>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObjectProfesionalExperiences(e) ||
                                  setShowModifyProfesionalExperiences(true) ||
                                  setIdFormProfesionalExperiences(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className={styles.add_experience_container}>
                    <button
                      disabled={status?.key === "Désactivé" || CVProfesionalExperiences?.length >= 5}
                      type="button"
                      onClick={() => setShowPostProfesionalExperiences(true)}
                      className={styles.add_experiences}
                    >
                      {t("app.visualisationCv.AjouterExpPro")}
                    </button>
                  </div>
                  <div className={styles.cv_separator}></div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.MaitriseDesLangues")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVLanguageSkills?.length > 0 &&
                      CVLanguageSkills.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>
                              {e.lang.key === "Autre" || e.lang.key === "Other" ? e.other : e.lang.label}
                            </div>
                            <div className={styles.subtitle_experience_card}>{e.level.label}</div>
                            <div className={styles.title_experience_card}>{e.certification}</div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObjectLanguageSkills(e) ||
                                  setShowModifyLanguageSkills(true) ||
                                  setIdFormLanguageSkills(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className={styles.add_experience_container}>
                    <button
                      disabled={status?.key === "Désactivé" || CVLanguageSkills?.length >= 5}
                      type="button"
                      onClick={() => {
                        setShowPostLanguageSkills(true);
                      }}
                      className={styles.add_experiences}
                    >
                      {t("app.visualisationCv.AjoutLangue")}
                    </button>
                  </div>
                  <div className={styles.cv_thirdsublist_first_pane}>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_padding} htmlFor="frenchLevel">
                        {t("app.visualisationCv.NiveauFrancais")}
                      </label>
                      <DropdownList
                        disabled={status?.key === "Désactivé" ? true : false}
                        className={styles.dropDownList}
                        id="frenchLevel"
                        dataKey="key"
                        textField="label"
                        data={[{ ...objAucun }, ...referentialData.STUDENT_LANG_LEVEL]}
                        value={values.frenchLevel}
                        onChange={(newValue) => {
                          setFieldValue("frenchLevel", newValue);
                        }}
                      />
                    </div>
                    <div className={styles.label_plus_input_container}></div>
                  </div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.CompetencesInformatiques")}</div>
                  <div className={styles.experiences_card_container}>
                    {CVComputerSkills?.length > 0 &&
                      CVComputerSkills.map((e) => (
                        <>
                          <div key={nanoid()} id={e.id} className={styles.experiences_card}>
                            <div className={styles.title_experience_card}>{e.domain.label}</div>
                            <div className={styles.subtitle_experience_card}>{e.software}</div>
                            <button
                              disabled={status?.key === "Désactivé" ? true : false}
                              type="button"
                              className={styles.button_experience_container}
                              onClick={() => {
                                setActiveObjectComputerSkills(e) ||
                                  setShowModifyComputerSkills(true) ||
                                  setIdFormComputerSkills(e.id);
                              }}
                            >
                              <img className={styles.eye_color} src={pencilIcon} alt="pencil"></img>
                              <p className={styles.button_experience_text}>{t("app.visualisationCv.Modifier")}</p>
                            </button>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className={styles.add_experience_container}>
                    <button
                      disabled={status?.key === "Désactivé" || CVComputerSkills?.length >= 5}
                      type="button"
                      onClick={() => {
                        setShowPostComputerSkills(true);
                      }}
                      className={styles.add_experiences}
                    >
                      {t("app.visualisationCv.AjoutCompetence")}
                    </button>
                  </div>
                  <div className={styles.subtitle_cv}>{t("app.visualisationCv.CompetencesComplementaires")}</div>
                  <div className={styles.savoir_etre_container}>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_padding} htmlFor="softSkills">
                        {t("app.visualisationCv.SavoirEtre")}
                      </label>
                      <Listbox
                        style={
                          errors.softSkills &&
                          touched.softSkills && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        id="softSkills"
                        multiple
                        dataKey="key"
                        textField="label"
                        data={[...referentialData.STUDENT_RESUME_SOFT_SKILL]}
                        value={values.softSkills}
                        onChange={(newValue) => {
                          setTypeOfExperience(newValue);
                          setFieldValue("softSkills", newValue);
                        }}
                      />
                      <InputErrorMessage message={errors.softSkills} touched={touched.softSkills} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="extraActivities">
                        {t("app.visualisationCv.ActiviteExtraScolaires")}
                      </label>
                      <textarea
                        style={
                          errors.extraActivities &&
                          touched.extraActivities && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        className={styles.textArea_extraActivities}
                        value={values.extraActivities}
                        type="extraActivities"
                        name="extraActivities"
                        id="extraActivities"
                        onChange={(e) => setFieldValue("extraActivities", e.target.value.trimStart())}
                        maxLength="500"
                      ></textarea>
                      <InputErrorMessage message={errors.extraActivities} touched={touched.extraActivities} />
                    </div>
                  </div>
                  <div className={styles.cv_separator} />
                  <div className={styles.cv_list_info}>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="phone">
                        {t("app.visualisationCv.Telephone")}
                      </label>
                      <input
                        style={
                          errors.phone &&
                          touched.phone && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.phone}
                        type="text"
                        name="phone"
                        id="phone"
                        onChange={(e) => setFieldValue("phone", removeEverythingExceptNumbers(e.target.value))}
                      />
                      <InputErrorMessage message={errors.phone} touched={touched.phone} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="email">
                        {t("app.visualisationCv.Email")}
                      </label>
                      <input
                        style={
                          errors.email &&
                          touched.email && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.email}
                        type="text"
                        name="email"
                        id="email"
                        onChange={(e) => setFieldValue("email", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.email} touched={touched.email} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="instagramUrl">
                        {t("app.visualisationCv.Instagram")}
                      </label>
                      <input
                        maxLength={255}
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.instagramUrl}
                        type="text"
                        name="instagramUrl"
                        id="instagramUrl"
                        onChange={(e) => setFieldValue("instagramUrl", e.target.value.trimStart())}
                      />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="youtubeUrl">
                        {t("app.visualisationCv.Youtube")}
                      </label>
                      <input
                        style={
                          errors.youtubeUrl &&
                          touched.youtubeUrl && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.youtubeUrl}
                        type="text"
                        name="youtubeUrl"
                        id="youtubeUrl"
                        onChange={(e) => setFieldValue("youtubeUrl", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.youtubeUrl} touched={touched.youtubeUrl} />
                    </div>
                  </div>
                  <div className={`${styles.cv_list_info} ${styles.threeCols}`}>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="linkedinUrl">
                        {t("app.visualisationCv.LinkedIn")}
                      </label>
                      <input
                        style={
                          errors.linkedinUrl &&
                          touched.linkedinUrl && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.linkedinUrl}
                        type="text"
                        name="linkedinUrl"
                        id="linkedinUrl"
                        onChange={(e) => setFieldValue("linkedinUrl", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.linkedinUrl} touched={touched.linkedinUrl} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="blogUrl">
                        {t("app.visualisationCv.BlogBook")}
                      </label>
                      <input
                        style={
                          errors.blogUrl &&
                          touched.blogUrl && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.blogUrl}
                        type="text"
                        name="blogUrl"
                        id="blogUrl"
                        onChange={(e) => setFieldValue("blogUrl", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.blogUrl} touched={touched.blogUrl} />
                    </div>
                  </div>
                  <div className={styles.cv_list_info}>
                    <UploadDeleteOrganism
                      id={id}
                      middleware="student"
                      endpoint={`/v1/students/resumes/${id}/supporting-documents`}
                      labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
                      uploadMessages={{
                        success: {
                          firstMessage: t("app.toastMessages.FichiersAjoutes"),
                          secondMessage: `${t("app.toastMessages.FichiersAjoutesMessage")}`,
                        },
                        error: {
                          firstMessage: t("app.toastMessages.genericError"),
                        },
                      }}
                      deleteMessages={{
                        success: {
                          firstMessage: t("app.toastMessages.genericDeleteSuccess"),
                        },
                        error: {
                          firstMessage: t("app.toastMessages.genericDeleteError"),
                        },
                      }}
                    />
                    {fileData && (
                      <div className={styles.saveDocument_master_container}>
                        <label className={styles.label_padding} htmlFor="chargementDeDocument">
                          {t("app.visualisationCv.DocumentToDownload")}
                        </label>
                        <DocumentsToDownload
                          fileType={supportingDocument?.fileExtension}
                          href={href}
                          onClick={() => handleUpload()}
                          download={supportingDocument?.title}
                          fileTitle={supportingDocument?.title}
                          spanErrorMessage={spanErrorMessage}
                          supportingDocument={supportingDocument?.downloadUrl}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.cv_list_info}>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label className={styles.label_padding} htmlFor="videoUrl">
                        {t("app.visualisationCv.URLCV")}
                      </label>
                      <input
                        style={
                          errors.videoUrl &&
                          touched.videoUrl && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        disabled={status?.key === "Désactivé" ? true : false}
                        value={values.videoUrl}
                        type="text"
                        name="videoUrl"
                        id="videoUrl"
                        onChange={(e) => setFieldValue("videoUrl", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.videoUrl} touched={touched.videoUrl} />
                    </div>
                    <div className={styles.label_plus_input_container} />
                  </div>
                  <div className={styles.cv_sublist_first_pane}>
                    <div className={styles.cv_secondsublist_first_pane}>
                      <div className={styles.label_plus_input_container} />
                      <div className={styles.label_plus_input_container} />
                    </div>
                  </div>
                  <div className={styles.consentement_title_container}>
                    <label className={styles.label_padding} htmlFor="public">
                      {t("app.visualisationCv.Consentement")}
                    </label>
                  </div>
                  <div>
                    <label className={styles.star_for_consent}>*</label>
                    <input
                      disabled={status?.key === "Désactivé" ? true : false}
                      type="checkbox"
                      name="checked"
                      style={
                        errors.checked &&
                        errors.touched && {
                          outline: "2px solid red",
                          borderRadius: "50%",
                          outlineOffset: "-1.9px",
                        }
                      }
                      id="checked"
                      onChange={handleChange}
                      onClick={(e) => (e.target.checked ? setChecked(true) : setChecked(false))}
                    ></input>
                    <p className={styles.consentement_text}>{t("app.visualisationCv.ConsentementText")}</p>
                  </div>
                </div>
                <div className={styles.submitBtn_container}>
                  <SubmitBtnModifyYourCV
                    disabled={checked ? true : false}
                    style={{ color: "white" }}
                    text={t("app.visualisationCv.ValidezLesModifications")}
                  />
                </div>
                <ValidateFormModal
                  modifyingOrNot={modifyingOrNot}
                  onClose={() => setShowValidateForm(false)}
                  show={showValidateForm}
                />
              </form>
            )}

            <ModifyModalAcademicExperiences
              onClose={() => setShowModify(false)}
              showModify={showModify}
              id={idForm}
              object={activeObject}
            />
            <ModalAcademicExperiences
              onClose={() => setShow(false)}
              show={show}
              data={[activeObject]}
              id={idForm}
              openIt={() => setShow(false) || setShowModify(true)}
            />
            <PostModalAcademicExperiences onClose={() => setShowPost(false)} showPost={showPost} />
            <ModifyModalAcademicProjects
              onClose={() => setShowModifyAcademicProjects(false)}
              showModify={showModifyAcademicProjects}
              id={idFormAcademicProjects}
              object={activeObjectAcademicProjects}
            />
            <ModalAcademicProjects
              onClose={() => setShowAcademicProjects(false)}
              show={showAcademicProjects}
              data={[activeObjectAcademicProjects]}
              id={idFormAcademicProjects}
              openIt={() => setShowAcademicProjects(false) || setShowModifyAcademicProjects(true)}
            />
            <PostModalAcademicProjects
              onClose={() => setShowPostAcademicProjects(false)}
              showPost={showPostAcademicProjects}
            />
            <ModifyModalAssociativeExperiences
              onClose={() => setShowModifyAssociativeExperiences(false)}
              showModify={showModifyAssociativeExperiences}
              id={idFormAssociativeExperiences}
              object={activeObjectAssociativeExperiences}
            />
            <ModalAssociativeExperiences
              onClose={() => setShowAssociativeExperiences(false)}
              show={showAssociativeExperiences}
              data={[activeObjectAssociativeExperiences]}
              id={idFormAssociativeExperiences}
              openIt={() => setShowAssociativeExperiences(false) || setShowModifyAssociativeExperiences(true)}
            />
            <PostModalAssociativeExperiences
              onClose={() => setShowPostAssociativeExperiences(false)}
              showPost={showPostAssociativeExperiences}
            />
            <ModifyModalProfesionalExperiences
              onClose={() => setShowModifyProfesionalExperiences(false)}
              showModify={showModifyProfesionalExperiences}
              id={idFormProfesionalExperiences}
              object={activeObjectProfesionalExperiences}
            />
            <ModalProfesionalExperiences
              onClose={() => setShowProfesionalExperiences(false)}
              show={showProfesionalExperiences}
              data={[activeObjectProfesionalExperiences]}
              id={idFormProfesionalExperiences}
              openIt={() => setShowProfesionalExperiences(false) || setShowModifyProfesionalExperiences(true)}
            />
            <PostModalProfesionalExperiences
              onClose={() => setShowPostProfesionalExperiences(false)}
              showPost={showPostProfesionalExperiences}
            />
            <ModifyModalLanguageSkills
              onClose={() => setShowModifyLanguageSkills(false)}
              showModify={showModifyLanguageSkills}
              id={idFormLanguageSkills}
              object={activeObjectLanguageSkills}
            />
            <PostModalLanguageSkills
              onClose={() => setShowPostLanguageSkills(false)}
              showPost={showPostLanguageSkills}
            />
            <ModifyModalComputerSkills
              onClose={() => setShowModifyComputerSkills(false)}
              showModify={showModifyComputerSkills}
              id={idFormComputerSkills}
              object={activeObjectComputerSkills}
            />
            <PostModalComputerSkills
              onClose={() => setShowPostComputerSkills(false)}
              showPost={showPostComputerSkills}
            />
          </section>
        </>
      )}
    </>
  );
};

export default ModifyYourCV;
