import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DropdownList } from "react-widgets";

import {
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
  patchStepThunk,
  handlePreviousStep,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { getUploadFIleErrorMessage, getUploadFilePending, postFileThunk } from "app/slices/uploadFile/uploadFile.slice";

import { createBooleanReferential } from "utils/localReferentials";

import { toastSuccess, toastWarning, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import Header from "../../components/HeaderContract/HeaderContract";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import UploadOrganism from "../../components/UploadOrganism/UploadOrganism";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

import styles from "./Step8.module.scss";

const YesNo = createBooleanReferential();
const objAucun = { key: "-Aucun-", label: "- Aucun -" };

const Step8 = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();
  const [acompte, setAcompte] = useState("-Aucun-");
  const [step8Token] = useState(axios.CancelToken.source());

  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);

  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  console.log(preContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  // useEffect onChange for UploadOrganism
  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          await dispatch(
            postFileThunk({
              file,
              endpoint: `/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/RIB`,
              axiosCancelToken,
            }),
          ).unwrap();
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersAjoutes")}
              secondMessage={`${t("app.toastMessages.FichiersAjoutesMessage")} 1`}
            />,
          );
          console.log("upload success TestUploadFile !");
        } catch (error) {
          console.log("upload fail TestUploadFile !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericError")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // useEffect to trigger setDisabled
  useEffect(() => {
    if (preContractualizationInformations?.student?.bankInfo?.depositPaid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable */
  const onSubmit = (values) => {
    if (!disabled && fileUploaded === 0 && values.acompte?.key === "Oui") {
      console.log("disabled reject");
      setSpanErrorMessage(true);
      return;
    } else {
      setSpanErrorMessage(false);
    }

    const dataToSend =
      values.acompte.key === "Oui"
        ? {
            stage: "Etape9",
            status: "Informations étudiant complétées",
            student: {
              bankInfo: {
                depositPaid: true,
                accountHolder: {
                  name: values.lastname,
                  firstname: values.firstname,
                },
                iban: values.iban,
                bic: values.bic,
              },
            },
          }
        : {
            stage: "Etape9",
            status: "Informations étudiant complétées",
            student: {
              bankInfo: {
                depositPaid: false,
              },
            },
          };
    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken: step8Token,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  const step8Schema = Yup.object().shape({
    acompte: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    lastname: Yup.string().when([], {
      is: () => acompte === "Oui" || values.acompte?.key === "Oui",
      then: (schema) => schema.max(80).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    firstname: Yup.string().when([], {
      is: () => acompte === "Oui" || values.acompte?.key === "Oui",
      then: (schema) => schema.max(80).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    iban: Yup.string().when([], {
      is: () => acompte === "Oui" || values.acompte?.key === "Oui",
      then: (schema) =>
        schema
          .matches(
            /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|IS\d{24}|BE\d{14}|(?:FR|MR|MC)[a-zA-Z0-9]{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
            `${t("app.errorMessages.ibanFormatNumber")}`,
          )
          .required(`${t("app.errorMessages.RemplirChamp")}`)
          .max(34, `${t("app.errorMessages.ibanFormatMaximum")}`),
    }),
    bic: Yup.string().when([], {
      is: () => acompte === "Oui" || values.acompte?.key === "Oui",
      then: (schema) =>
        schema
          .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, `${t("app.errorMessages.bicFormatNumber")}`)
          .min(8, `${t("app.errorMessages.bicFormatMinimum")}`)
          .max(11, `${t("app.errorMessages.bicFormatMaximum")}`)
          .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
  });

  const { values, errors, touched, setFieldValue, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      acompte:
        preContractualizationInformations?.student?.bankInfo?.depositPaid === true
          ? { key: "Oui", label: "Oui" }
          : { ...objAucun },
      firstname: preContractualizationInformations?.student?.bankInfo?.accountHolder?.firstname
        ? preContractualizationInformations?.student?.bankInfo?.accountHolder?.firstname
        : "",
      lastname: preContractualizationInformations?.student?.bankInfo?.accountHolder?.name
        ? preContractualizationInformations?.student?.bankInfo?.accountHolder?.name
        : "",
      iban: preContractualizationInformations?.student?.bankInfo?.iban
        ? preContractualizationInformations?.student?.bankInfo?.iban
        : "",
      bic: preContractualizationInformations?.student?.bankInfo?.bic
        ? preContractualizationInformations?.student?.bankInfo?.bic
        : "",
    },
    validationSchema: step8Schema,
    onSubmit,
  });

  const validAcompte = touched.acompte && errors.acompte;
  const validFirstName = touched.firstname && errors.firstname;
  const validLastName = touched.lastname && errors.lastname;
  const validBic = touched.bic && errors.bic;
  const validIban = touched.iban && errors.iban;

  console.log("values.acompte", values.acompte);
  console.log("ERRORS", errors);

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />

      <div className={styles.container}>
        <Header />
        <div className={styles.wrapper}>
          <div className={styles.wrapper__colLeft}>
            <div className={styles.header}>
              <h2 className={styles.headerTitle}>{t("app.declarezUnRecrutement.InformationsBancaires")}</h2>
              <div className={styles.stepLevel}>{t("app.StepThree.Etape4Sur4")}</div>
            </div>
            {values.acompte.key === "-Aucun-" || values.acompte.key === "Non" ? (
              <form onSubmit={handleSubmit}>
                <div className={styles.boxWrapper}>
                  <div className={styles.formGroup}>
                    <label htmlFor="acompte">{t("app.declarezUnRecrutement.VerseAcompte")}</label>
                    <DropdownList
                      value={values.acompte.key}
                      data={[...YesNo]}
                      dataKey="key"
                      textField="label"
                      id="acompte"
                      className={`${validAcompte && `${styles.errorDropDown}`}`}
                      onChange={(value) => {
                        setAcompte(value.key);
                        setFieldValue("acompte", value);
                        if (value.key === "Oui") setDisabled(false);
                        else setDisabled(true);
                      }}
                    />
                    {validAcompte && <span className={styles.error}>{errors.acompte}</span>}
                  </div>
                </div>
                <div className={styles.action}>
                  <SubmitFormBtn text={t("app.StepThree.ValidezEtSoumettezVotreEntreprise")} />
                  <PreviousFormBtn
                    text={t("app.StepThree.EtapePrecedente")}
                    handlePreviousStep={() => dispatch(handlePreviousStep("Etape7"))}
                  />
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className={styles.boxWrapper}>
                  <div className={styles.formGroup}>
                    <label htmlFor="acompte">{t("app.declarezUnRecrutement.VerseAcompte")}</label>
                    <DropdownList
                      value={values.acompte.key}
                      data={[...YesNo]}
                      dataKey="key"
                      textField="label"
                      id="acompte"
                      className={`${validAcompte && `${styles.errorDropDown}`}`}
                      onChange={(value) => {
                        setAcompte(value.key);
                        setFieldValue("acompte", value);
                      }}
                    />
                    {validAcompte && <span className={styles.error}>{errors.acompte}</span>}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="firstname">{t("app.declarezUnRecrutement.PrenomDuTitulaireDuCompte")}</label>
                    <input
                      id="firstname"
                      maxLength={255}
                      name="firstname"
                      type="text"
                      value={values.firstname}
                      onBlur={handleBlur}
                      className={`${validFirstName && `${styles.errorInput}`}`}
                      onChange={(e) => setFieldValue("firstname", e.target.value.trimStart())}
                    />
                    {validFirstName && <span className={styles.error}>{errors.firstname}</span>}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="lastname">{t("app.declarezUnRecrutement.NomDuTitulaireDuCompte")}</label>

                    <input
                      id="lastname"
                      maxLength={255}
                      name="lastname"
                      type="text"
                      value={values.lastname}
                      onBlur={handleBlur}
                      className={`${validLastName && `${styles.errorInput}`}`}
                      onChange={(e) => setFieldValue("lastname", e.target.value.trimStart())}
                    />
                    {validLastName && <span className={styles.error}>{errors.lastname}</span>}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="iban"> {t("app.declarezUnRecrutement.IBAN")}</label>

                    <input
                      id="iban"
                      maxLength={34}
                      name="iban"
                      type="text"
                      value={values.iban}
                      onBlur={handleBlur}
                      className={`${validIban && `${styles.errorInput}`}`}
                      onChange={(e) => setFieldValue("iban", e.target.value.trimStart())}
                    />
                    {validIban && <span className={styles.error}>{errors.iban}</span>}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="email">{t("app.declarezUnRecrutement.BIC")}</label>

                    <input
                      id="bic"
                      maxLength={11}
                      name="bic"
                      type="text"
                      value={values.bic}
                      onBlur={handleBlur}
                      className={`${validBic && `${styles.errorInput}`}`}
                      onChange={(e) => setFieldValue("bic", e.target.value.trimStart())}
                    />
                    {validBic && <span className={styles.error}>{errors.bic}</span>}
                  </div>
                  <div className={styles.formGroup}>
                    <UploadOrganism
                      labelText={t("app.declarezUnRecrutement.DeposezVotreRIB")}
                      disabled={disabled}
                      setFile={setFile}
                      setDisplayModal={setDisplayModal}
                      spanErrorMessage={spanErrorMessage}
                    />
                  </div>
                </div>
                <div className={styles.action}>
                  <SubmitFormBtn
                    text={t("app.StepThree.ValidezEtSoumettezVotreEntreprise")}
                    clickFunc={
                      !values.iban && errors.iban
                        ? () =>
                            toastWarning(
                              <ToastMessageStructure
                                firstMessage={t("app.toastMessages.Attention")}
                                secondMessage={t("app.toastMessages.MerciDeTelechargerVotreRib")}
                              />,
                            )
                        : values.iban && errors.iban
                          ? () =>
                              toastError(
                                <ToastMessageStructure
                                  firstMessage={t("app.toastMessages.IbanValidation")}
                                  secondMessage={t("app.toastMessages.IbanInvalide")}
                                />,
                              )
                          : null
                    }
                  />
                  <PreviousFormBtn
                    text={t("app.StepThree.EtapePrecedente")}
                    handlePreviousStep={() => dispatch(handlePreviousStep("Etape7"))}
                  />
                </div>
              </form>
            )}
          </div>
          <SummaryCard
            data={[
              {
                title: t("app.declarezUnRecrutement.DateInscriptionPoleEmploi"),
                text: preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate,
              },
              {
                title: t("app.declarezUnRecrutement.SituationAvantContrat"),
                text: preContractualizationInformations.student?.currentAcademicProfile?.situationLastYear?.label,
              },
              {
                title: t("app.declarezUnRecrutement.IntitulePrecisDuDernierDiplomeOuTitrePrepare"),
                text: preContractualizationInformations.student?.currentAcademicProfile?.lastDiplomaPrepared?.name,
              },
              {
                title: t("app.declarezUnRecrutement.DernierDiplomeOuTitrePrepare"),
                text: preContractualizationInformations.student?.currentAcademicProfile?.lastDiplomaPrepared?.type
                  ?.label,
              },
              {
                title: t("app.declarezUnRecrutement.DerniereAnneeOuClasseSuivieParLapprenti"),
                text: preContractualizationInformations.student?.currentAcademicProfile?.lastCourse?.label,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
export default Step8;
