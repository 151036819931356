import upArrow from "../../../assets/icon-arrowDropDownUp.svg";
import styles from "./Sections.module.scss";

/* eslint-disable */
const Sections = (props) => (
  <div className={styles.main_container}>
    <div className={styles.top_section}>
      <div className={styles.section_title} onClick={() => props.setFormOpen(!props.formOpen)}>
        <h1 className={styles.title}>{props.tittle}</h1>
        <p style={{ fontSize: "12px" }}>{props?.optionnal}</p>
        <div>
          {props.pending && <div className={styles.pin_status_pending} />}

          {(props.error || !props.areaFufiled) && <div className={styles.pin_status_error} />}
          {!props.pending && !props.error && props.areaFufiled && <div className={styles.pin_status_success} />}
        </div>
        <div className={styles.dropdown_icon}>
          {!props.formOpen && <img src={upArrow} />}
          {props.formOpen && <img src={upArrow} className={styles.arrowUp} />}
        </div>
      </div>

      {props.isSubTittle === true && <label className={styles.subTittle}>{props.subTittle}</label>}
    </div>
    {props.Form}
  </div>
);

export default Sections;
