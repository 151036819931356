import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosSchool } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  listData: [],
  idData: null,
  pending: false,
  errorMessage: "",
};

// THUNKS SECTION

export const putThunk = createAsyncThunk("learningBooklets/putThunk", async ({ dataToSend, id, axiosCancelToken }) => {
  const response = await axiosSchool().put(`/v1/schools/apprenticeships/booklets/${id}`, dataToSend, {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

export const getAllThunk = createAsyncThunk("learningBooklets/getAllThunk", async ({ axiosCancelToken }) => {
  const response = await axiosSchool().get("/v1/schools/apprenticeships/booklets", {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

export const getOneThunk = createAsyncThunk("learningBooklets/getOneThunk", async ({ id, axiosCancelToken }) => {
  const response = await axiosSchool().get(`/v1/schools/apprenticeships/booklets/${id}`, {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

// CREATESLICE SECTION

const learningBookletsSlice = createSlice({
  name: "learningBooklets",
  initialState,
  reducers: {
    handleReset: (state) => {
      console.log("reset");
      state.listData = [];
      state.idData = null;
      state.pending = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [putThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [putThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [putThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getAllThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getAllThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.listData = action.payload;
    },
    [getAllThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getOneThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getOneThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.idData = action.payload;
    },
    [getOneThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getLearningBookletsListData = (state) => state.learningBooklets.listData;
export const getLearningBookletsIdData = (state) => state.learningBooklets.idData;
export const getLearningBookletsPending = (state) => state.learningBooklets.pending;
export const getLearningBookletsErrorMessage = (state) => state.learningBooklets.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

export const { handleReset } = learningBookletsSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default learningBookletsSlice.reducer;
