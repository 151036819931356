import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { DropdownList } from "react-widgets/cjs";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useSelector, useDispatch } from "react-redux";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { postProfesionalExperiencesThunk } from "../../../../app/slices/modifyYourCV/professionalExperiences.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

/* eslint-disable */
const PostModalProfesionalExperiences = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const referentialData = useSelector(getReferentialsData);

  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const onSubmit = (values) => {
    const dataToSend = {
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      organizationName: values.organizationName,
      position: values.position,
      performedTasks: values.performedTasks,
      acquiredSkills: values.acquiredSkills,
      contractType: values.contractType ? values.contractType.key : "-Aucun-",
    };
    (async () => {
      try {
        await dispatch(
          postProfesionalExperiencesThunk({
            dataToSend,
            id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationAjoutExpProTitle")}
            secondMessage={t("app.toastMessages.ConfirmationAjoutExpProMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const yourSearchSchema = Yup.object().shape({
    organizationName: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    contractType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    position: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    performedTasks: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    acquiredSkills: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      organizationName: "",
      startDate: null,
      endDate: null,
      contractType: { ...objAucun },
      position: "",
      performedTasks: "",
      acquiredSkills: "",
    },
    validationSchema: yourSearchSchema,
    onSubmit,
  });

  if (props.showPost)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.CreationExpPro")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="organizationName">
                    {t("app.visualisationCv.NomDeEntreprise")}
                  </label>
                  <input
                    style={
                      errors.organizationName &&
                      touched.organizationName && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.organizationName}
                    maxLength={80}
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    onChange={(e) => setFieldValue("organizationName", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  {/* <DatePicker
										style={
											errors.startDate &&
											touched.startDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.startDate}
										onChange={(value) => setFieldValue("startDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  {/* <DatePicker
										style={
											errors.endDate &&
											touched.endDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.endDate}
										onChange={(value) => setFieldValue("endDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.endDate}
                    touched={touched.endDate}
                    value={values.endDate}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                  <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="typeOfContract">
                    {t("app.visualisationCv.TypeDeContrat")}
                  </label>
                  <DropdownList
                    style={
                      errors.contractType &&
                      touched.contractType && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="contractType"
                    multiple
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_EXP_PRO_CONTRACT_TYPE]}
                    value={values.contractType.key}
                    onChange={(newValue) => {
                      setFieldValue("contractType", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.contractType} touched={touched.contractType} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="position">
                    {t("app.visualisationCv.Fonction")}
                  </label>
                  <textarea
                    style={
                      errors.position &&
                      touched.position && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.position}
                    maxLength={80}
                    type="position"
                    name="position"
                    id="position"
                    onChange={(e) => setFieldValue("position", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.position} touched={touched.position} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="performedTasks">
                    {t("app.visualisationCv.MissionsRealisees")}
                  </label>
                  <textarea
                    style={
                      errors.performedTasks &&
                      touched.performedTasks && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.performedTasks}
                    type="performedTasks"
                    name="performedTasks"
                    id="performedTasks"
                    onChange={(e) => setFieldValue("performedTasks", e.target.value.trimStart())}
                    maxLength={255}
                  ></textarea>
                  <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="acquiredSkills">
                    {t("app.visualisationCv.3Competences")}
                  </label>
                  <textarea
                    style={
                      errors.acquiredSkills &&
                      touched.acquiredSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.acquiredSkills}
                    type="acquiredSkills"
                    name="acquiredSkills"
                    id="acquiredSkills"
                    onChange={(e) => setFieldValue("acquiredSkills", e.target.value.trimStart())}
                    maxLength={500}
                  ></textarea>
                  <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezExperience")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showPost) return null;
};

export default PostModalProfesionalExperiences;
