import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION

export const PostExperienceThunk = createAsyncThunk(
  "createExperience/PostExperienceThunk",
  async ({ dataToSend, cvId }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/experiences/academic`, dataToSend);
    return response.data;
  },
);

export const PutExperienceThunk = createAsyncThunk(
  "createYourExperience/patchExperienceThunk",
  async ({ dataToSend, id, cvId }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${cvId}/experiences/academic/${id}`, dataToSend);
    return response.data;
  },
);

// CREATESLICE SECTION
const createYourExperienceSlice = createSlice({
  name: "createYourExperience",
  initialState,
  reducers: {},
  extraReducers: {
    [PostExperienceThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostExperienceThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostExperienceThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [PutExperienceThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PutExperienceThunk.fulfilled]: (state, action) => {
      console.log("put fulfilled !", action.payload);
      state.pending = false;
    },
    [PutExperienceThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourExperiencePending = (state) => state.createYourExperience.pending;
export const getcreateYourExperienceErrorMessage = (state) => state.createYourExperience.errorMessage;
export const getcreateYourExperienceId = (state) => state.createYourExperience.id;
export const getcreateYourExperienceData = (state) => state.createYourExperience.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourExperienceSlice.reducer;
