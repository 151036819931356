import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { axiosCompany } from "app/axiosInstances/axiosInstances";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DropdownList } from "react-widgets";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { createObjAucun, createObjCodeAucun } from "utils/localReferentials";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { getSiretThunk, getAllCompanies, postCompanyThunk } from "app/slices/checkDetailsCompany/getAllCompanies.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { ReactComponent as Pencil } from "./assets/search.svg";
import codeNafSvg from "../../../../../assets/codeNaf.svg";
import handGlass from "../../../../../assets/hand_glass.svg";
import legalForms from "../../../../../assets/legal_forms.svg";

import styles from "./Form1.module.scss";

/* eslint-disable */
const Form1 = (props) => {
  const dispatch = useDispatch();
  // const { id } = useParams();
  const objAucun = createObjAucun();
  const objCodeAucun = createObjCodeAucun();

  const referencialData = useSelector(getReferentialsData);
  // const pending = useSelector(getUserRecruitmentsPendingForm1);
  const [form1AxiosCancelToken] = useState(axios.CancelToken.source());
  const [siretResponse, setSiretResponse] = useState([]);
  const [checkBoxLocalState, setCheckBoxLocalState] = useState(false);
  const [inputValue2, setInputValue2] = useState("");
  const [apiResponse2, setApiResponse2] = useState([]);
  // const [selectedItem2, setSelectedItem2] = useState(null);
  const [firstCall, setFirstCall] = useState(false);
  const [form1Token] = useState(axios.CancelToken.source());
  const [companyActive, setCompanyActive] = useState(true);
  const [initialCodeNaf, setInitialCodeNaf] = useState(null);
  const [initialLegalStatus, setInitialLegalStatus] = useState(null);
  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);
  const allSiretInfo = useSelector(getAllCompanies);
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  const [clicked, seIsClicked] = useState(false);

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    (async () => {
      let isSiret = true;
      try {
        await dispatch(
          getSiretThunk({
            isSiret: isSiret,
            siret: recruitmentPropsGeneral.company.siret,
            axiosCancelToken: props.axiosCancelToken,
          }),
        )
          .unwrap()
          .then((res) => {
            res?.map((e) => {
              setInitialCodeNaf({
                code: e?.activities?.map((e) => e?.code).join(),
                label: e?.activities?.map((e) => e?.label).join(),
              });
              setInitialLegalStatus({
                code: e?.legalForm?.code,
                label: e?.legalForm?.label,
              });
              // setFieldValue("codeNaf", e?.collectiveAgreement);
              // setFieldValue("legalStatus", e?.legalForm);
            });
            setSiretResponse(res);
            // handleMountPingStatus();
          });
        setFirstCall(true);
        if (allSiretInfo?.length === 0) {
          await dispatch(
            getSiretThunk({
              isSiret: !isSiret,
              siret: recruitmentPropsGeneral.company.siret,
              axiosCancelToken: props.axiosCancelToken,
            }),
          )
            .unwrap()
            .then((res) => {
              setSiretResponse(res);
            });
          setFirstCall(true);
        }
      } catch (error) {
        console.log("get fail MOUNT SIRET !", error.message);
      }
    })();
    console.log("ALL SIRET INFO", allSiretInfo);
  }, []);

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus = () => {
    console.log("FORM1 JUSTMOUNTED 1", props.areaFufiled, errors);
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
    console.log("FORM1 JUSTMOUNTED 2", props.areaFufiled, errors);
  };

  useEffect(() => {
    if (siretResponse?.length > 0) {
      setFieldValue("companyName", siretResponse?.map((e) => e?.name).join());
      // if (
      // 	!firstCall &&
      // 	siretResponse.map((e) => e?.siret).join() !==
      // 		recruitmentPropsGeneral?.company?.siret &&
      // 	!errors.siret &&
      // 	values.siret !== ""
      // ) {
      // 	setFieldValue(
      // 		"codeIdcc",
      // 		siretResponse.map((e) => e?.collectiveAgreement?.code).join() !== ""
      // 			? {
      // 					code: siretResponse
      // 						.map((e) => e?.collectiveAgreement?.code)
      // 						.join(),
      // 					label: siretResponse
      // 						.map((e) => e?.collectiveAgreement?.label)
      // 						.join(),
      // 			  }
      // 			: {
      // 					code: recruitmentPropsGeneral?.company?.collectiveAgreement
      // 						?.code,
      // 					label:
      // 						recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
      // 			  }
      // 	);
      // }
      if (
        !firstCall &&
        siretResponse.map((e) => e?.siret).join() !== recruitmentPropsGeneral?.company?.siret &&
        !errors.siret &&
        values.siret !== ""
      ) {
        setFieldValue("codeIdcc", {
          code: siretResponse.map((e) => e?.collectiveAgreement?.code).join(),
          label: siretResponse.map((e) => e?.collectiveAgreement?.label).join(),
        });
      } else if (siretResponse.map((e) => e?.siret).join() === recruitmentPropsGeneral?.company?.siret && !firstCall) {
        setFieldValue(
          "codeIdcc",
          recruitmentPropsGeneral?.company?.collectiveAgreement?.code !== undefined
            ? {
                code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
                label: recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
              }
            : {
                code: "",
                label: "",
              },
        );
      }
      // setFieldValue(
      // 	"codeIdcc",
      // 	recruitmentPropsGeneral?.company?.collectiveAgreement !== undefined
      // 		? {
      // 				code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
      // 				label:
      // 					recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
      // 		  }
      // 		: { ...objCodeAucun }
      // );
      // setFieldValue(
      // 	"codeIdcc",
      // 	siretResponse.map((e) => e?.collectiveAgreement?.code).join() !== ""
      // 		? {
      // 				code: siretResponse
      // 					.map((e) => e?.collectiveAgreement?.code)
      // 					.join(),
      // 				label: siretResponse
      // 					.map((e) => e?.collectiveAgreement?.label)
      // 					.join(),
      // 		  }
      // 		: {
      // 				code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
      // 				label:
      // 					recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
      // 		  }
      // );
      setFieldValue(
        "codeNaf",
        siretResponse.map((e) => e?.activities).join() !== undefined
          ? {
              code: siretResponse.map((e) => e?.activities?.map((e) => e?.code)).join(),
              label: siretResponse.map((e) => e?.activities?.map((e) => e?.label)).join(),
            }
          : {
              code: "",
              label: "",
            },
      );
      setFieldValue("legalStatus", {
        code: siretResponse.map((e) => e?.legalForm?.code).join(),
        label: siretResponse.map((e) => e?.legalForm?.label).join(),
      });
      setFieldValue("descriptionNaf", siretResponse.map((e) => e?.activities?.map((e) => e?.label)).join());
      setFieldValue("address", siretResponse?.map((e) => e?.addresses?.headquarters?.street).join());
      setFieldValue("city", siretResponse?.map((e) => e?.addresses?.headquarters?.city).join());
      setFieldValue("zipCode", siretResponse?.map((e) => e?.addresses?.headquarters?.zipCode).join());
      setFieldValue("additionalAddress", siretResponse?.map((e) => e?.addresses?.headquarters?.complement).join());
    }
  }, [siretResponse, firstCall]);

  const handleSiretCalls = (siret) => {
    if (errors.siret) {
      return;
    } else {
      (async () => {
        let isSiret = true;
        try {
          await dispatch(
            getSiretThunk({
              isSiret: isSiret,
              siret: siret,
              axiosCancelToken: form1AxiosCancelToken,
            }),
          )
            .unwrap()
            .then((res) => {
              setCompanyActive(JSON.parse(res?.map((e) => e.active).join()));
              if (res?.map((e) => e?.siret).join() !== recruitmentPropsGeneral?.company?.siret && !firstCall) {
                setFieldValue("codeIdcc", {
                  code: res?.map((e) => e?.collectiveAgreement?.code).join(),
                  label: res?.map((e) => e?.collectiveAgreement?.label).join(),
                });
              } else if (res?.map((e) => e?.siret).join() === recruitmentPropsGeneral?.company?.siret && !firstCall) {
                setFieldValue(
                  "codeIdcc",
                  recruitmentPropsGeneral?.company?.collectiveAgreement?.code !== undefined
                    ? {
                        code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
                        label: recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
                      }
                    : {
                        code: "",
                        label: "",
                      },
                );
              }
              setSiretResponse(res);
            });
          setFirstCall(false);
          console.log("get success SIRET !");
        } catch (error) {
          console.log("get fail SIRET !", error.message);
        }
      })();
    }
  };

  const Form1Schema = Yup.object().shape({
    companyName: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    siret: Yup.string().when([], {
      is: () => values.checkboxContract === true,
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, `${t("app.errorMessages.siretFormatNumber")}`)
          .min(14, `${t("app.errorMessages.siretFormatMinimum")}`)
          .max(14, `${t("app.errorMessages.siretFormatMaximum")}`)
          .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    codeNaf: Yup.object({
      code: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
      label: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code !== "-Aucun-"),
    codeIdcc: Yup.object({
      code: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
      label: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    })
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code !== "-Aucun-")
      .nullable(),
    legalStatus: Yup.object({
      code: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
      label: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code !== "-Aucun-"),
    employerType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    VAT: Yup.string()
      .matches(/^(?:((FR)?[0-9A-Z]{2}[0-9]{9}))$/, `${t("app.errorMessages.TVAFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    pensionFund: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    workForce: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    socialRegime: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    address: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .min(5, t("app.errorMessages.NombreItemRequis"))
      .max(5, t("app.errorMessages.NombreItemRequis"))
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    city: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
  });

  const onSubmit = (values, actions) => {
    console.log("JUST MOUNT FORM 1", formikErrorJustMount);
    if (!companyActive) return;
    if (!formikErrorJustMount && allSiretInfo?.map((e) => e.id).join() !== "") {
      const valuesCopy = structuredClone(values);
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);
      const dataToSend = {
        status: "En cours",
        company: {
          id: parseInt(allSiretInfo?.map((e) => e.id).join()),
          employerType: values.employerType?.key,
          vatNumber: values.VAT !== "" ? values.VAT : null,
          pensionFund: values.pensionFund,
          workforce: parseInt(values.workForce),
          socialSecuritySystem: values?.socialRegime?.key,
          activities: allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join()
            ? [allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join()]
            : [""],
          legalForm: allSiretInfo?.map((e) => e?.legalForm?.code).join()
            ? allSiretInfo?.map((e) => e?.legalForm?.code).join()
            : "",
          collectiveAgreement: values?.codeIdcc?.code,
          providentFund: values.providentOrganization !== "" ? values.providentOrganization : null,
          addresses: {
            headquarters: {
              street: values.address,
              complement: values?.additionalAddress ? values?.additionalAddress : null,
              zipCode: values.zipCode,
              city: values.city,
            },
          },
        },
      };
      props.submitSection(dataToSend, 1);
    } else if (!formikErrorJustMount && allSiretInfo?.map((e) => e.id).join() === "") {
      (async () => {
        let newIdToPatch = null;
        try {
          await dispatch(
            postCompanyThunk({
              dataToSend: {
                siret: allSiretInfo?.map((e) => e?.siret).join(),
                name: allSiretInfo?.map((e) => e?.name).join(),
                addresses: {
                  headquarters: {
                    street: allSiretInfo?.map((e) => e?.addresses?.headquarters?.street).join(),
                    additionalAddress: null,
                    zipCode: allSiretInfo?.map((e) => e?.addresses?.headquarters?.zipCode).join(),
                    city: allSiretInfo?.map((e) => e?.addresses?.headquarters?.city).join(),
                  },
                },
                activities:
                  allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join() !== ""
                    ? [allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join()]
                    : null,
                legalForm: allSiretInfo?.map((e) => e?.legalForm?.code).join(),
                active:
                  allSiretInfo?.map((e) => e?.active).join("") !== ""
                    ? allSiretInfo?.map((e) => e?.active).join() === "true"
                      ? false
                      : true
                    : false,
              },
              axiosCancelToken: form1Token,
            }),
          )
            .unwrap()
            .then((res) => {
              newIdToPatch = res.id;
            });
          const dataToSend = {
            status: "En cours",
            company: {
              id: newIdToPatch,
              employerType: values.employerType?.key,
              vatNumber: values.VAT !== "" ? values.VAT : null,
              pensionFund: values.pensionFund,
              workforce: parseInt(values.workForce),
              socialSecuritySystem: values?.socialRegime?.key,
              activities: allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join()
                ? [allSiretInfo?.map((e) => e?.activities?.map((e) => e?.code)).join()]
                : [""],
              legalForm: allSiretInfo?.map((e) => e?.legalForm?.code).join()
                ? allSiretInfo?.map((e) => e?.legalForm?.code).join()
                : "",
              collectiveAgreement: values?.codeIdcc?.code,
              providentFund: values.providentOrganization !== "" ? values.providentOrganization : null,
              addresses: {
                headquarters: {
                  street: values.address,
                  complement: values?.additionalAddress ? values?.additionalAddress : null,
                  zipCode: values.zipCode,
                  city: values.city,
                },
              },
            },
          };
          props.submitSection(dataToSend, 1);
        } catch (err) {
          console.log(err.messsage);
        }
      })();
    } else {
      handleMountPingStatus();
    }
  };

  const deleteFieldValue = () => {
    if (!checkBoxLocalState) {
      setFieldValue("companyName", "");
      setFieldValue("siret", "");
      setFieldValue("codeNaf", { code: "-Aucun-", label: "- Sélectionner -" });
      setFieldValue("descriptionNaf", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("employerType", { ...objAucun });
      setFieldValue("legalStatus", {
        code: "-Aucun-",
        label: "- Sélectionner -",
      });
      setFieldValue("VAT", "");
      setFieldValue("pensionFund", "");
      setFieldValue("workForce", "");
      setFieldValue("socialRegime", {
        key: "-Aucun-",
        label: "- Sélectionner -",
      });
      setFieldValue("providentOrganization", "");
      setFieldValue("address", "");
      setFieldValue("additionalAddress", "");
      setFieldValue("city", "");
      setFieldValue("zipCode", "");
      setCheckBoxLocalState(true);
    }
  };

  const setInitialFieldValue = () => {
    setFieldValue(
      "companyName",
      recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.name
          ? recruitmentPropsGeneral?.company?.name
          : ""
        : "",
    );
    setFieldValue("siret", recruitmentPropsGeneral?.company?.siret ? recruitmentPropsGeneral?.company?.siret : "");
    // setFieldValue(
    //  "codeNaf",
    //  recruitmentPropsGeneral?.company?.siret
    //      ? allSiretInfo?.map((e) => e?.collectiveAgreement?.code).join()
    //          ? {
    //                  code: allSiretInfo
    //                      ?.map((e) => e?.collectiveAgreement?.code)
    //                      .join(),
    //                  label: allSiretInfo
    //                      ?.map((e) => e?.collectiveAgreement?.label)
    //                      .join(),
    //            }
    //          : { code: "-Aucun-", label: "- Sélectionner -" }
    //      : { code: "-Aucun-", label: "- Sélectionner -" }
    // );
    setFieldValue(
      "codeNaf",
      recruitmentPropsGeneral?.company?.siret ? initialCodeNaf : { code: "-Aucun-", label: "- Sélectionner -" },
    );
    setFieldValue(
      "legalStatus",
      recruitmentPropsGeneral?.company?.siret ? initialLegalStatus : { code: "-Aucun-", label: "- Sélectionner -" },
    );
    // initialCodeNaf;
    // initialLegalStatus;
    // setFieldValue(
    //  "legalStatus",
    //  recruitmentPropsGeneral?.company?.siret
    //      ? allSiretInfo?.map((e) => e?.legalForm?.inseeCode).join()
    //          ? {
    //                  code: allSiretInfo?.map((e) => e?.legalForm?.code).join(),
    //                  label: allSiretInfo?.map((e) => e?.legalForm?.label).join(),
    //            }
    //          : { code: "-Aucun-", label: "- Sélectionner -" }
    //      : { code: "-Aucun-", label: "- Sélectionner -" }
    // );
    setFieldValue(
      "descriptionNaf",
      recruitmentPropsGeneral?.company?.siret
        ? allSiretInfo?.map((e) => e?.collectiveAgreement?.label).join()
          ? allSiretInfo?.map((e) => e?.collectiveAgreement?.label).join()
          : ""
        : "",
    );
    setFieldValue(
      "codeIdcc",
      recruitmentPropsGeneral?.company?.collectiveAgreement !== undefined
        ? {
            code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
            label: recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
          }
        : { ...objCodeAucun },
    );
    // setFieldValue(
    // 	"codeIdcc",
    // 	Number.isInteger(
    // 		siretResponse.map((e) => e?.collectiveAgreement?.code).join()
    // 	)
    // 		? {
    // 				code: siretResponse.map((e) => e?.collectiveAgreement?.code).join(),
    // 				label: siretResponse
    // 					.map((e) => e?.collectiveAgreement?.label)
    // 					.join(),
    // 		  }
    // 		: { ...objCodeAucun }
    // );
    setFieldValue(
      "employerType",
      recruitmentPropsGeneral?.company?.employerType ? recruitmentPropsGeneral?.company?.employerType : { ...objAucun },
    );
    setFieldValue(
      "VAT",
      recruitmentPropsGeneral?.company?.vatNumber ? recruitmentPropsGeneral?.company?.vatNumber : "",
    );
    setFieldValue(
      "pensionFund",
      recruitmentPropsGeneral?.company?.pensionFund ? recruitmentPropsGeneral?.company?.pensionFund : "",
    );
    setFieldValue(
      "workForce",
      recruitmentPropsGeneral?.company?.workforce ? recruitmentPropsGeneral?.company?.workforce : "",
    );
    setFieldValue(
      "socialRegime",
      recruitmentPropsGeneral?.company?.socialSecuritySystem
        ? recruitmentPropsGeneral?.company?.socialSecuritySystem
        : { ...objAucun },
    );
    setFieldValue(
      "providentOrganization",
      recruitmentPropsGeneral?.company?.providentFund ? recruitmentPropsGeneral?.company?.providentFund : "",
    );
    setFieldValue(
      "address",
      recruitmentPropsGeneral?.company?.addresses?.headquarters?.street
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.street
        : "",
    );
    setFieldValue(
      "additionalAddress",
      recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.complement
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.complement
          : ""
        : "",
    );
    setFieldValue(
      "zipCode",
      recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.zipCode
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.zipCode
          : ""
        : "",
    );
    setFieldValue(
      "city",
      recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.city
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.city
          : ""
        : "",
    );
    setCheckBoxLocalState(false);
  };

  const { values, errors, touched, setFieldValue, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      checkboxContract: false,
      companyName: recruitmentPropsGeneral?.company?.name ? recruitmentPropsGeneral?.company?.name : "",
      siret: recruitmentPropsGeneral?.company?.siret ? recruitmentPropsGeneral?.company?.siret : "",
      codeNaf:
        recruitmentPropsGeneral?.company?.activities !== undefined ||
        recruitmentPropsGeneral?.company?.activities !== null
          ? {
              code: recruitmentPropsGeneral?.company?.activities?.map((e) => e?.code).join(),
              label: recruitmentPropsGeneral?.company?.activities?.map((e) => e?.code).join(),
            }
          : { ...objCodeAucun },
      legalStatus:
        recruitmentPropsGeneral?.company?.legalForm !== undefined ||
        recruitmentPropsGeneral?.company?.legalForm !== null
          ? {
              code: recruitmentPropsGeneral?.company?.legalForm?.code,
              label: recruitmentPropsGeneral?.company?.legalForm?.label,
            }
          : { ...objCodeAucun },
      descriptionNaf: recruitmentPropsGeneral?.company?.siret
        ? allSiretInfo?.map((e) => e?.collectiveAgreement?.label).join()
          ? allSiretInfo?.map((e) => e?.collectiveAgreement?.label).join()
          : ""
        : "",
      codeIdcc:
        recruitmentPropsGeneral?.company?.collectiveAgreement !== undefined
          ? {
              code: recruitmentPropsGeneral?.company?.collectiveAgreement?.code,
              label: recruitmentPropsGeneral?.company?.collectiveAgreement?.label,
            }
          : "",
      employerType: recruitmentPropsGeneral?.company?.employerType
        ? recruitmentPropsGeneral?.company?.employerType
        : { ...objAucun },
      VAT: recruitmentPropsGeneral?.company?.vatNumber ? recruitmentPropsGeneral?.company?.vatNumber : "",
      pensionFund: recruitmentPropsGeneral?.company?.pensionFund ? recruitmentPropsGeneral?.company?.pensionFund : "",
      workForce: recruitmentPropsGeneral?.company?.workforce ? recruitmentPropsGeneral?.company?.workforce : "",
      socialRegime: recruitmentPropsGeneral?.company?.socialSecuritySystem
        ? recruitmentPropsGeneral?.company?.socialSecuritySystem
        : { ...objAucun },
      providentOrganization: recruitmentPropsGeneral?.company?.providentFund
        ? recruitmentPropsGeneral?.company?.providentFund
        : "",
      address: recruitmentPropsGeneral?.company?.addresses?.headquarters?.street
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.street
        : "",
      city: recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.city
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.city
          : ""
        : "",
      additionalAddress: recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.complement
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.complement
          : ""
        : "",
      zipCode: recruitmentPropsGeneral?.company?.siret
        ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.zipCode
          ? recruitmentPropsGeneral?.company?.addresses?.headquarters?.zipCode
          : ""
        : "",
    },
    validationSchema: Form1Schema,
    onSubmit,
  });

  const validcodeIdcc = touched.codeIdcc && errors.codeIdcc;

  useEffect(() => {
    console.log("ERRORS FORM1 SIRET", allSiretInfo);
    if (formikErrorJustMount) {
      onSubmit();
    }
    console.log("ERRORS FORM1 USE EFFECT", errors);
  }, [errors, allSiretInfo]);

  const HandleInput2Change = async (event) => {
    const value = event.target.value;
    setInputValue2(value);
    if (inputValue2 !== values.codeIdcc?.code) {
      setFieldValue("codeIdcc", objCodeAucun);
    }
    if (value.length > 1) {
      const response = await axiosCompany().get(`/v1/companies/collective-agreements?code=*${value}*`);
      setApiResponse2(response.data);
    } else {
      setApiResponse2([]);
    }
    // setSelectedItem2(null);
  };

  const handleOption2Select = (option) => {
    console.log("option", option);
    setFieldValue("codeIdcc", option);
    setInputValue2(option.code);
    setApiResponse2([]);
  };
  useEffect(() => {
    console.log("Clicked");
    if (formikErrorJustMount) {
      handleSubmit();
    }
  }, [errors]);

  if (props.formOpen) {
    return (
      referencialData &&
      recruitmentPropsGeneral && (
        <div className={styles.container}>
          <form onSubmit={handleSubmit}>
            <div className={styles.BoxValided}>
              <input
                type="checkbox"
                id="checkboxContract"
                name="checkboxContract"
                value={values.checkboxContract}
                onChange={(e) => {
                  if (e.target.checked) {
                    deleteFieldValue();
                  } else {
                    setInitialFieldValue();
                    setCompanyActive(true);
                  }
                  setFieldValue("checkboxContract", !values.checkboxContract);
                  setFirstCall(false);
                }}
              />
              <label htmlFor="siretCheckbox" className={styles.checkbox}>
                {t("app.suivezVosContratsPartner.isNewSiret")}
              </label>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.formGroup}>
                <label htmlFor="companyName">{t("app.suivezVosContratsPartner.NomDeLentreprise")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <>
                    <input
                      disabled
                      style={
                        !formikErrorJustMount && clicked
                          ? errors.companyName &&
                            touched.companyName && {
                              border: "2px solid #EA001E",
                            }
                          : null
                      }
                      maxLength={255}
                      value={values.companyName}
                      id="companyName"
                      name="companyName"
                      type="text"
                      onClick={(e) => {
                        setFieldValue("companyName", e.target.value.trimStart());
                      }}
                    />
                    {!formikErrorJustMount && clicked && (
                      <InputErrorMessage message={errors.companyName} touched={touched.companyName} />
                    )}
                  </>
                ) : (
                  <>
                    <input
                      disabled
                      style={
                        !formikErrorJustMount && clicked
                          ? errors.companyName &&
                            touched.companyName && {
                              border: "2px solid #EA001E",
                            }
                          : null
                      }
                      maxLength={255}
                      value={values.companyName}
                      id="companyName"
                      name="companyName"
                      type="text"
                      onChange={(e) => {
                        setFieldValue("companyName", e.target.value.trimStart());
                      }}
                    />
                    {!formikErrorJustMount && clicked && (
                      <InputErrorMessage message={errors.companyName} touched={touched.companyName} />
                    )}
                  </>
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="siret">{t("app.suivezVosContratsPartner.siretLabel")}</label>
                {recruitmentPropsGeneral && !values.checkboxContract ? (
                  <input
                    disabled
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.siret &&
                          touched.siret && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.siret}
                    id="siret"
                    name="siret"
                    type="text"
                    onChange={(e) => {
                      setFieldValue("siret", e.target.value.trimStart());
                    }}
                  />
                ) : (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.siret &&
                          touched.siret && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    id="siret"
                    name="siret"
                    type="text"
                    onBlur={(e) => {
                      handleSiretCalls(e.target.value);
                      setFieldValue("siret", e.target.value);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      setFieldValue("siret", e.target.value.trimStart());
                    }}
                    value={values.siret}
                  />
                )}
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.siret} touched={touched.siret} />
                )}
                {!companyActive && <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="codeNaf">{t("app.suivezVosContratsPartner.CodeNaf")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <>
                    <DropdownList
                      disabled
                      selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
                      style={
                        !formikErrorJustMount && clicked
                          ? errors.codeNaf &&
                            touched.codeNaf && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          : null
                      }
                      id="codeNaf"
                      dataKey="code"
                      textField="label"
                      value={values.codeNaf}
                      renderListItem={({ item }) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div>{item?.code}</div>
                        </div>
                      )}
                      renderValue={({ item }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div
                            style={{
                              paddingLeft: "2rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {values.codeNaf?.code}
                          </div>
                        </div>
                      )}
                      maxLength={255}
                      onChange={(value) => {
                        setFieldValue("codeNaf", value);
                      }}
                    />
                    {!formikErrorJustMount && clicked && (
                      <InputErrorMessage message={errors.codeNaf?.label} touched={touched.codeNaf} />
                    )}
                  </>
                ) : (
                  <>
                    <DropdownList
                      disabled
                      selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
                      style={
                        !formikErrorJustMount && clicked
                          ? errors.codeNaf &&
                            touched.codeNaf && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          : null
                      }
                      id="codeNaf"
                      dataKey="code"
                      textField="label"
                      value={values.codeNaf}
                      renderListItem={({ item }) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div>{item?.code}</div>
                        </div>
                      )}
                      renderValue={({ item }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div
                            style={{
                              paddingLeft: "2rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {values.codeNaf?.code}
                          </div>
                        </div>
                      )}
                      maxLength={255}
                      onChange={(value) => {
                        setFieldValue("codeNaf", value);
                      }}
                    />
                    {!formikErrorJustMount && clicked && (
                      <InputErrorMessage message={errors.codeNaf?.label} touched={touched.codeNaf} />
                    )}
                  </>
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="naf">{t("app.suivezVosContratsPartner.CodeNafDescription")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <input
                    disabled
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.descriptionNaf &&
                          touched.descriptionNaf && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="descriptionNaf"
                    name="descriptionNaf"
                    type="text"
                    maxLength={255}
                    value={values.descriptionNaf}
                    onChange={(e) => {
                      setFieldValue("descriptionNaf", e.target.value.trimStart());
                    }}
                  />
                ) : (
                  <input
                    disabled
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.descriptionNaf &&
                          touched.descriptionNaf && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="descriptionNaf"
                    name="descriptionNaf"
                    type="text"
                    maxLength={255}
                    value={values.descriptionNaf}
                    onChange={(e) => {
                      setFieldValue("descriptionNaf", e.target.value.trimStart());
                    }}
                  />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="idcc">{t("app.suivezVosContratsPartner.CodeIdcc")}</label>
                {errors.codeIdcc || values.codeIdcc?.code === "-Aucun-" || values.codeIdcc === "" ? (
                  <>
                    <div className={`${styles.searchCodeIdcc} input-like-rw`}>
                      <div className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}>
                        <input
                          style={
                            errors.codeIdcc && touched.codeIdcc && clicked
                              ? {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              : null
                          }
                          id="codeIdcc"
                          name="codeIdcc"
                          className={
                            !formikErrorJustMount && clicked ? `${validcodeIdcc && `${styles.errorInput}`}` : null
                          }
                          type="text"
                          value={inputValue2}
                          onChange={HandleInput2Change}
                        />
                        <Pencil className={styles.search_icon} />
                      </div>
                      <div className={apiResponse2.length > 0 ? styles.output_container : undefined}>
                        <ul>
                          {apiResponse2.map((result) => (
                            <div>
                              <div key={result.code} className={styles.output_plus_image_Container}>
                                <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                <div className={styles.outputKeyLabel} onClick={() => handleOption2Select(result)}>
                                  <li>{result.code}</li>
                                  <li> {result.label}</li>
                                </div>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                      {validcodeIdcc && !formikErrorJustMount && clicked && (
                        <span className={styles.error}>{errors?.codeIdcc?.label}</span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <DropdownList
                      disabled
                      selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
                      style={
                        errors.codeIdcc && touched.codeIdcc && clicked
                          ? {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          : null
                      }
                      maxLength={80}
                      id="codeIdcc"
                      name="codeIdcc"
                      dataKey="code"
                      textField="label"
                      value={values?.codeIdcc}
                      renderListItem={({ item }) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div>{item?.code}</div>
                        </div>
                      )}
                      renderValue={(item) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0",
                          }}
                        >
                          <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                          <div
                            style={{
                              paddingLeft: "2rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {values?.codeIdcc?.code}
                          </div>
                        </div>
                      )}
                      onChange={(value) => {
                        setFieldValue("codeIdcc", value);
                      }}
                    />
                    {!formikErrorJustMount && clicked && (
                      <InputErrorMessage message={errors.codeIdcc?.label} touched={touched.codeIdcc} />
                    )}
                  </>
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="employerType">{t("app.suivezVosContratsPartner.TypeEmployeur")}</label>
                <DropdownList
                  style={
                    !formikErrorJustMount && clicked
                      ? errors.employerType &&
                        touched.employerType && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  value={values.employerType}
                  id="employerType"
                  dataKey="key"
                  textField="label"
                  data={[{ ...objAucun }, ...referencialData?.COMPANY_EMPLOYER_TYPE]}
                  onChange={(newValue) => setFieldValue("employerType", newValue)}
                />
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.employerType} touched={touched.employerType} />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="legalStatus">{t("app.suivezVosContratsPartner.FormeJuridique")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <DropdownList
                    disabled
                    selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.legalStatus &&
                          touched.legalStatus && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    id="legalStatus"
                    dataKey="code"
                    textField="label"
                    value={values.legalStatus}
                    renderValue={({ item }) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0",
                        }}
                      >
                        <img src={legalForms} style={{ width: "1.2rem" }} alt="test"></img>
                        <div
                          style={{
                            paddingLeft: "2rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {values.legalStatus?.code}
                        </div>
                      </div>
                    )}
                    maxLength={255}
                    onChange={(value) => {
                      setFieldValue("legalStatus", value);
                    }}
                  />
                ) : (
                  <DropdownList
                    disabled
                    selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.legalStatus &&
                          touched.legalStatus && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    id="legalStatus"
                    dataKey="code"
                    textField="label"
                    renderListItem={({ item }) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "0",
                        }}
                      >
                        <img src={legalForms} style={{ width: "1.2rem" }} alt="test"></img>
                        <div>{item.legalForm?.code}</div>
                      </div>
                    )}
                    renderValue={({ item }) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0",
                        }}
                      >
                        <img src={legalForms} style={{ width: "1.2rem" }} alt="test"></img>
                        <div
                          style={{
                            paddingLeft: "2rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {values.legalStatus?.code}
                        </div>
                      </div>
                    )}
                    maxLength={255}
                    onChange={(value) => {
                      setFieldValue("legalStatus", value);
                    }}
                    value={values.legalStatus}
                  />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="tva">{t("app.suivezVosContratsPartner.Tva")}</label>
                <input
                  style={
                    errors.VAT && touched.VAT && clicked
                      ? {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  id="VAT"
                  name="VAT"
                  type="text"
                  value={values.VAT}
                  onChange={(e) => setFieldValue("VAT", e.target.value.trimStart())}
                />
                {!formikErrorJustMount && clicked && <InputErrorMessage message={errors.VAT} touched={touched.VAT} />}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="pensionFund">{t("app.suivezVosContratsPartner.CaisseDeRetraite")}</label>

                <input
                  style={
                    !formikErrorJustMount && clicked
                      ? errors.pensionFund &&
                        touched.pensionFund && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  maxLength={255}
                  id="pensionFund"
                  name="pensionFund"
                  type="text"
                  value={values.pensionFund}
                  onChange={(e) => setFieldValue("pensionFund", e.target.value.trimStart())}
                />
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.pensionFund} touched={touched.pensionFund} />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="workForce">{t("app.suivezVosContratsPartner.Effectif")}</label>
                <input
                  style={
                    !formikErrorJustMount && clicked
                      ? errors.workForce &&
                        touched.workForce && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  maxLength={255}
                  id="workForce"
                  name="workForce"
                  type="text"
                  value={values.workForce}
                  onChange={(e) => setFieldValue("workForce", e.target.value.trimStart())}
                />
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.workForce} touched={touched.workForce} />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="socialRegime">{t("app.suivezVosContratsPartner.RegimeSocial")}</label>
                <DropdownList
                  style={
                    !formikErrorJustMount && clicked
                      ? errors.socialRegime &&
                        touched.socialRegime && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  value={values.socialRegime}
                  id="socialRegime"
                  dataKey="key"
                  textField="label"
                  data={[{ ...objAucun }, ...referencialData?.SOCIAL_SECURITY_SYSTEM]}
                  onChange={(newValue) => setFieldValue("socialRegime", newValue)}
                />
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.socialRegime} touched={touched.socialRegime} />
                )}
              </div>
              <div className={styles.formGroup}>
                <div className={styles.infoBulle}>
                  <svg
                    className="slds-button__icon"
                    focusable="false"
                    data-key="info"
                    aria-hidden="true"
                    viewBox="0 0 52 52"
                    part="icon"
                  >
                    <g>
                      <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                    </g>
                  </svg>
                  <div className={styles.infoBulleInfo}>
                    {t("app.suivezVosContratsPartner.OrganismePrevoyanceHelpText")}
                  </div>
                </div>
                <label htmlFor="providentOrganization">{t("app.suivezVosContratsPartner.OrganismePrevoyance")}</label>

                <input
                  style={
                    !formikErrorJustMount && clicked
                      ? errors.providentOrganization &&
                        touched.providentOrganization && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  maxLength={255}
                  id="providentOrganization"
                  name="providentOrganization"
                  type="text"
                  value={values.providentOrganization}
                  onChange={(e) => setFieldValue("providentOrganization", e.target.value.trimStart())}
                />
                {!formikErrorJustMount && clicked && (
                  <InputErrorMessage message={errors.providentOrganization} touched={touched.providentOrganization} />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="address">{t("app.suivezVosContratsPartner.Adresse")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <input
                    style={
                      !formikErrorJustMount && errors.address && touched.address && clicked
                        ? {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.address}
                    id="address"
                    name="address"
                    type="text"
                    onChange={(e) => setFieldValue("address", e.target.value.trimStart())}
                  />
                ) : (
                  <input
                    style={
                      !formikErrorJustMount && errors.address && touched.address && clicked
                        ? {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.address}
                    id="address"
                    name="address"
                    type="text"
                    onChange={(e) => setFieldValue("address", e.target.value.trimStart())}
                  />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="additionalAddress">{t("app.suivezVosContratsPartner.ComplementDadresse")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <input
                    style={
                      !formikErrorJustMount && errors.additionalAddress && touched.additionalAddress && clicked
                        ? {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.additionalAddress}
                    id="additionalAddress"
                    name="additionalAddress"
                    type="text"
                    onChange={(e) => setFieldValue("additionalAddress", e.target.value.trimStart())}
                  />
                ) : (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.additionalAddress &&
                          touched.additionalAddress && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.additionalAddress}
                    id="additionalAddress"
                    name="additionalAddress"
                    type="text"
                    onChange={(e) => setFieldValue("additionalAddress", e.target.value.trimStart())}
                  />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="zipCode">{t("app.suivezVosContratsPartner.CodePostal2")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.zipCode &&
                          touched.zipCode && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.zipCode}
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    onChange={(e) => setFieldValue("zipCode", e.target.value.trimStart())}
                  />
                ) : (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.zipCode &&
                          touched.zipCode && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.zipCode}
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    onChange={(e) => setFieldValue("zipCode", e.target.value.trimStart())}
                  />
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="city">{t("app.suivezVosContratsPartner.Ville")}</label>
                {recruitmentPropsGeneral && values.checkboxContract ? (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.city &&
                          touched.city && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.city}
                    id="city"
                    name="city"
                    type="text"
                    onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
                  />
                ) : (
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.city &&
                          touched.city && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    maxLength={255}
                    value={values.city}
                    id="city"
                    name="city"
                    type="text"
                    onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
                  />
                )}
              </div>
            </div>
            <div className={styles.submit_btn_to_right}>
              <span>{t("app.suivezVosContratsPartner.alertSiret")}</span>
              <SubmitFormBtn
                onClick={() => {
                  handlePingStatus();
                  seIsClicked(true);
                }}
                text={t("app.visualisationOffre.ValidezLesModifications")}
              />
            </div>
          </form>
        </div>
      )
    );
  }
};

export default Form1;
