import { t } from "i18next";

import { Link } from "react-router-dom";

import {
  DISCOVER_OMNES_EDUCATION_URL,
  DISCOVER_OUR_SCHOOLS_URL,
  DISCOVER_LEARNING_TAXE_URL,
} from "utils/globalConstantsAndURL";

import HomepageHeader from "./HomepageHeader/HomepageHeader";
import DivLine from "../../../components/DivLine/DivLine";
import ItemStep from "./ItemStep/ItemStep";
import Card from "./Card/Card";

import bgHomeImg from "./assets/bg-home.png";
import discoverImg from "./assets/discover.png";
import discoverMobileImg from "./assets/discoverMobile.png";
import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

import styles from "./Homepage.module.scss";

const Homepage = () => (
  <>
    <HomepageHeader
      h1={t("app.homePageEntreprise.Bienvenue")}
      textElement={(
        <p>
          {t("app.homePageEntreprise.IntroLogIn1")}
          <br /> {t("app.homePageEntreprise.IntroLogIn2")}
        </p>
      )}
      bgImg={bgHomeImg}
    />

    <Link to="/relation-entreprise">
      <section className={styles.discover_container}>
        <img className={styles.discover_trapeze_img} src={discoverImg} alt="Mains sur un clavier d'ordinateur" />
        <img className={styles.discover_mobile_img} src={discoverMobileImg} alt="Mains sur un clavier d'ordinateur" />
        <div className={styles.discover_content_container}>
          <DivLine />
          <h2 className={styles.discover_content_title}>{t("app.homePageEntreprise.DecouvrezOffre")}</h2>
          <div className={styles.discover_content_desc}>
            <p>{t("app.homePageEntreprise.RelationEntr")}</p>
          </div>
        </div>
      </section>
    </Link>

    <section className={styles.second_container}>
      <div className={styles.title_second_container}>
        <DivLine />
        <h2>{t("app.homePageEntreprise.EtapesASuivre")}</h2>
      </div>
      <div className={styles.steps_container}>
        <ItemStep
          number="1"
          h3={t("app.homePageEntreprise.Etape1Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape1")}</p>}
        />
        <ItemStep
          number="4"
          h3={t("app.homePageEntreprise.Etape4Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape4")}</p>}
        />
        <ItemStep
          number="2"
          h3={t("app.homePageEntreprise.Etape2Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape2")}</p>}
        />
        <ItemStep
          number="5"
          h3={t("app.homePageEntreprise.Etape5Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape5")}</p>}
        />
        <ItemStep
          number="3"
          h3={t("app.homePageEntreprise.Etape3Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape3")}</p>}
        />
        <ItemStep
          number="6"
          h3={t("app.homePageEntreprise.Etape6Titre")}
          textElement={<p>{t("app.homePageEntreprise.Etape6")}</p>}
        />
      </div>
    </section>

    <section className={styles.third_container}>
      <div className={styles.title_third_container}>
        <DivLine />
        <h2>{t("app.homePageEntreprise.MieuxNousConnaitres")}</h2>
      </div>
      <div className={styles.cards_container}>
        <Card
          path={DISCOVER_OMNES_EDUCATION_URL}
          img={cardKnow3Img}
          h3={t("app.homePageEntreprise.OMNES")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
        <Card
          path={DISCOVER_OUR_SCHOOLS_URL}
          img={cardKnow2Img}
          h3={t("app.homePageEntreprise.NosEcoles")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
        <Card
          path={DISCOVER_LEARNING_TAXE_URL}
          img={cardKnow3Img}
          h3={t("app.homePageEntreprise.TaxeApprentissage")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
      </div>
    </section>
  </>
);

export default Homepage;
