import { t } from "i18next";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

/* eslint-disable */
const ModalAcademicExperiences = (props) => {
  if (props.show)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ConsultationExperience")}</h2>
            {props.data !== null &&
              props.data !== undefined &&
              props.data.map((e) => {
                return (
                  <div className={styles.modal_contentBox}>
                    <p className={styles.etablissement}>
                      {t("app.visualisationCv.Etablissement")}: {e.institution}
                    </p>
                    <p className={styles.formation}>
                      {t("app.visualisationCv.FormationSuivie")}: {e.course}
                    </p>
                    <p className={styles.formation}>
                      {t("app.visualisationCv.DiplomeObtenu")}: {e.graduationSuccessIndicator.label}
                    </p>
                    <p className={styles.date_modal}>
                      {t("app.visualisationCv.Du")} {e.startDate} {t("app.visualisationCv.Au")} {e.endDate}
                    </p>
                    <p className={styles.country_modal}>
                      {e.city} - {e.country.label}
                    </p>
                    <p className={styles.formation}>{t("app.visualisationCv.Description")}</p>
                    <p className={styles.description_modal}>{e.description}</p>
                  </div>
                );
              })}
            <div className={styles.button_modify_modal_container}>
              <button type="button" onClick={props.openIt} className={styles.showModify_btn}>
                {t("app.visualisationCv.ModifiezExperience")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  if (!props.show) return null;
};

export default ModalAcademicExperiences;
