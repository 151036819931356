import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getOneThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  handleReset,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import Loader from "components/Loader/Loader";
import BilanInte from "./forms/Bilan_integration";
import StaticBilanInte from "./staticForms/Static_Bilan_inte";

import IntermediateEvaluationM1Valid from "./staticForms/IntermediateEvaluationM1Valid";
import IntermediateEvaluationM1 from "./forms/IntermediateEvaluationM1/IntermediateEvaluationM1";
import EndTermEvalSecondYear from "./forms/EndTermEvalSecondYear/EndTermEvalSecondYear";
import StaticEndTermEvalSecondYear from "./staticForms/StaticEndTermEvalSecondYear";
import StaticEndTermEvalFirstYear from "./staticForms/StaticEndTermEvalFirstYear";
import IntermediateEvaluationM2 from "./forms/IntermediateEvaluationM2/IntermediateEvaluationM2";
import IntermediateEvaluationM2Valid from "./staticForms/IntermediateEvaluationM2Valid";
import Mission from "./forms/Missions/Mission";
import EndTermEvalFirstYear from "./forms/EndTermEvalFirstYear/EndTermEvalFirstYear";

const SelectedBooklet = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();

  const dispatch = useDispatch();

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);

  const referentials = useSelector(getReferentialsData);

  const userToken = useSelector(getUserToken);

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getOneThunk({ id, axiosCancelToken })).unwrap();
          console.log("useLayoutEffect success ! SelectedBooklet");
        } catch (error) {
          console.log("useLayoutEffect fail ! SelectedBooklet", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      dispatch(handleReset());
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("idData : ", idData);

  return (
    <>
      {(pending || !referentials) && <Loader />}
      {idData?.status?.key === "A compléter" && idData?.type === "INTEGRATION" && referentials && <BilanInte />}

      {idData?.status?.key !== "A compléter" && idData?.type === "INTEGRATION" && referentials && <StaticBilanInte />}

      {idData?.type === "MISSION" && referentials && <Mission />}

      {idData?.status?.key === "A compléter" && idData?.type === "MID_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <IntermediateEvaluationM1 />
      )}

      {idData?.status?.key !== "A compléter" && idData?.type === "MID_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <IntermediateEvaluationM1Valid />
      )}

      {idData?.status?.key === "A compléter" && idData?.type === "MID_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <IntermediateEvaluationM2 />
      )}

      {idData?.status?.key !== "A compléter" && idData?.type === "MID_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <IntermediateEvaluationM2Valid />
      )}

      {idData?.status?.key === "A compléter" && idData?.type === "END_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <EndTermEvalSecondYear />
      )}

      {idData?.status?.key !== "A compléter" && idData?.type === "END_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <StaticEndTermEvalSecondYear />
      )}
      {idData?.type === "END_TERM_EVALUATION_FIRST_YEAR" && idData?.status?.key === "A compléter" && referentials && (
        <EndTermEvalFirstYear />
      )}
      {idData?.type === "END_TERM_EVALUATION_FIRST_YEAR" && idData?.status?.key !== "A compléter" && referentials && (
        <StaticEndTermEvalFirstYear />
      )}
    </>
  );
};

export default SelectedBooklet;
