import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Initial State declaration
const initialState = {
  data: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getCitiesThunk = createAsyncThunk("getCities/getCitiesThunk", async ({ postalCode, axiosCancelToken }) => {
  console.log(`https://geo.api.gouv.fr/communes?codePostal=${postalCode}`);
  const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${postalCode}`, {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

// CREATESLICE SECTION

const getCitiesSlice = createSlice({
  name: "getCities",
  initialState,
  extraReducers: {
    [getCitiesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getCitiesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [getCitiesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getCitiesData = (state) => state.getCities.data;
export const getCitiesPending = (state) => state.getCities.pending;
export const getCitiesErrorMessage = (state) => state.getCities.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getCitiesSlice.reducer;
