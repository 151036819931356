import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm1Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm1/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm1Slice = createSlice({
  name: "checkYourRecruitmentsForm1",
  initialState,
  reducers: {
    handleRecruitmentPropsForm1Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm1Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm1Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm1Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm1Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm1Data, handleRecruitmentSectionsForm1Data } =
  checkYourRecruitmentsForm1Slice.actions;

export const getYourRecruitmentPropsForm1Data = (state) => state.checkYourRecruitmentsForm1.recruitmentPropsForm1Data;
export const getAllUserRecruitmentsForm1 = (state) => state.checkYourRecruitmentsForm1.recruitmentList;
export const getUserRecruitmentsPendingForm1 = (state) => state.checkYourRecruitmentsForm1.pending;
export const getUserRecruitmentsErrorMessageForm1 = (state) => state.checkYourRecruitmentsForm1.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm1Slice.reducer;
