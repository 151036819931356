import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import {
  getAllAssociativeExperiences,
  getAssociativeExperiencesCardsIsLoading,
  getAssociativeExperiencesThunk,
} from "app/slices/createYourCV/getYourAsso.slice";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";

import Header from "../../../../../../components/Header/Header";
import styles from "../../../CreateYourCV.module.scss";
import {
  getcreateYourAssoData,
  getcreateYourAssoErrorMessage,
  getcreateYourAssoId,
  getcreateYourAssoPending,
  PostAssoThunk,
} from "../../../../../../app/slices/createYourCV/createYourAsso.slice";
import AssoCard from "../components/assoCard";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import Modal from "../components/AssoModal";
import style from "./AddExperienceBtn.module.scss";

/* eslint-disable */
const AssociativeExperiences = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourAssoPending);
  const errorMessage = useSelector(getcreateYourAssoErrorMessage);
  const id = useSelector(getcreateYourAssoId);
  const AcademicExperiencesData = useSelector(getcreateYourAssoData);
  const experiencesList = useSelector(getAllAssociativeExperiences);
  const getExperienceLoading = useSelector(getAssociativeExperiencesCardsIsLoading);
  const [isFinishedAssoExp, setIsFinishedAssoExp] = useState(true);
  const cvId = useSelector(getcreateYourCVId);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };
  useEffect(() => {
    if (!getExperienceLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getAssociativeExperiencesThunk({
                  cvId,
                }),
              ).unwrap();
              console.log("this is the project Data:", experiencesList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedAssoExp, isModal]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const AssoSchema = Yup.object().shape({
    organizationName: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    position: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
    performedTasks: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    acquiredSkills: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
  });
  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(values);
    changeOuiOrNonToBoolean(values);
    changeEmptyStringToNull(values);

    dispatch(
      handleStepsData({
        formName: "assoExp",
        formData: {
          organizationName: values.organizationName,
          startDate: formatDate(values.startDate),
          endDate: formatDate(values.endDate),
          position: values.position,
          performedTasks: values.performedTasks,
          acquiredSkills: values.acquiredSkills,
        },
      }),
    );

    const dataToSend = {
      organizationName: valuesCopy.organizationName,
      startDate: formatDate(valuesCopy.startDate),
      endDate: formatDate(valuesCopy.endDate),
      position: valuesCopy.position,
      performedTasks: valuesCopy.performedTasks,
      acquiredSkills: valuesCopy.acquiredSkills,
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedAssoExp(true);
    if (!pending) {
      (async () => {
        if (experiencesList.length < 5) {
          try {
            await dispatch(
              PostAssoThunk({
                cvId,
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutExpAssoTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutExpAssoMessage")}
              />,
            );
            console.log("YourProfile post success !");
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        }
      })();
    }
    resetForm({ values: "" });
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: AcademicExperiencesData
      ? {
          ...AcademicExperiencesData,
          startDate: new Date(JSON.parse(AcademicExperiencesData.startDate)),
          endDate: new Date(JSON.parse(AcademicExperiencesData.endDate)),
        }
      : {
          organizationName: "",
          startDate: null,
          endDate: null,
          position: "",
          performedTasks: "",
          acquiredSkills: "",
        },
    validationSchema: AssoSchema,
    onSubmit,
  });
  const HandlePushExperienceButton = ({ text }) => {
    return (
      <button
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
      >
        {text}
      </button>
    );
  };

  const AddProjectBtn = ({ text, disabled }) => {
    return (
      <button
        type="submit"
        className={style.add_proj_btn}
        disabled={disabled}
        style={{
          color: disabled ? "grey" : null,
          fontSize: 13,
          fontFamily: "Inseec",
          textSizeAdjust: "100%",
        }}
        onClick={() => setIsFinishedAssoExp(false)}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span>
                  <p className={styles.test1}>{t("app.depotCv.ExperiencesAssociatives")}</p>
                </span>
              </div>
              <>
                {experiencesList.length > 0 &&
                  experiencesList.map((obj, index) => (
                    <AssoCard
                      modifyForm={goToModify}
                      isModify={isModify}
                      handleModalData={handleModalData}
                      {...obj}
                      key={index}
                    />
                  ))}
                {isFinishedAssoExp ? (
                  <>
                    <div className={style.add_proj_btn_container} style={{ width: "auto", alignSelf: "center" }}>
                      <AddProjectBtn
                        disabled={experiencesList.length >= 5 ? true : false}
                        text={t("app.depotCv.ADD")}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="organizationName">{t("app.depotCv.NomAssociation")}</label>
                      <input
                        id="organizationName"
                        style={
                          errors.organizationName &&
                          touched.organizationName && {
                            border: "2px solid #EA001E",
                          }
                        }
                        type="text"
                        value={values.organizationName}
                        onChange={(e) => setFieldValue("organizationName", e.target.value)}
                      />
                      <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="StartDate">{t("app.depotCv.DateDebut")}</label>
                      {/* <DatePicker
												style={
													errors.startDate &&
													touched.startDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.startDate}
												onChange={(value) => setFieldValue("startDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="endDate">{t("app.depotCv.DateFin")}</label>
                      {/* <DatePicker
												style={
													errors.endDate &&
													touched.endDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.endDate}
												onChange={(value) => setFieldValue("endDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.endDate}
                        touched={touched.endDate}
                        value={values.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="position">{t("app.depotCv.Fonction")}</label>
                      <textarea
                        style={
                          errors.position &&
                          touched.position && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="position"
                        value={values.position}
                        onChange={(e) => setFieldValue("position", e.target.value)}
                      />
                      <InputErrorMessage message={errors.position} touched={touched.position} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="performedTasks">{t("app.depotCv.MissionsRealisees")}</label>
                      <textarea
                        style={
                          errors.performedTasks &&
                          touched.performedTasks && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="performedTasks"
                        value={values.performedTasks}
                        onChange={(e) => setFieldValue("performedTasks", e.target.value)}
                      />
                      <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="acquiredSkills">{t("app.depotCv.3Competences")}</label>
                      <textarea
                        style={
                          errors.acquiredSkills &&
                          touched.acquiredSkills && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="acquiredSkills"
                        value={values.acquiredSkills}
                        onChange={(e) => setFieldValue("acquiredSkills", e.target.value)}
                      />
                      <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                    </div>
                    <div className={style.validate_exp_btn_container}>
                      <HandlePushExperienceButton text={t("app.depotCv.ValiderExp")} />
                    </div>
                  </div>
                )}
              </>
            </form>
          </section>
        </>
      )}
    </>

    // End Academic experiences
  );
};

export default AssociativeExperiences;
