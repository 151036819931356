import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { getCVById, getCVByIdPending, getCVByIdThunk } from "app/slices/ListOfCV/checkAllCV.slice";

import Header from "components/Header/Header";
import DivLine from "components/DivLine/DivLine";
import { isEmpty } from "utils/isEmpty";
import { nanoid } from "@reduxjs/toolkit";
import {
  downloadFileStudentThunk,
  getUploadFilePending,
  resetFileData,
  selectFileData,
  setFileData,
} from "app/slices/uploadFile/uploadFile.slice";
import axios from "axios";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DocumentsToDownload from "pages/ETU/ModifyYourCV/components/DocumentsToDownload/DocumentsToDownload";

import bgConsulOffreImg from "../ListOfCV/assets/bg-consul-offre.png";

import styles from "./CvById.module.scss";

/* eslint-disable */
const ListDetail = ({ label, value, index }) => {
  return (
    !isEmpty(value) &&
    !(value === "false") && (
      <div className={styles.offreListDetails__option} key={nanoid()}>
        <h6>{label}</h6>
        <p>{value.replace(true, `${t("app.referentials.Oui")}`)}</p>
      </div>
    )
  );
};

export default function CvsById() {
  const navigate = useNavigate();
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const handleClick = () => {
    navigate("/liste-des-cvs", { replace: true });
  };
  const dispatch = useDispatch();

  const CvById = useSelector(getCVById);
  const pending = useSelector(getCVByIdPending);
  const filePending = useSelector(getUploadFilePending);
  const fileData = useSelector(selectFileData);
  let { id } = useParams();
  const [href, setHref] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    if (!pending) {
      (async () => {
        try {
          await dispatch(getCVByIdThunk(id)).unwrap();
        } catch (error) {
          console.log("useEffect fail !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const profileDetails = [
    {
      key: "durationInternship",
      label: `${t("app.detailsCv.durationInternship")}`,

      values: `${CvById?.search?.durationInternship?.label}`,
    },
    {
      key: "durationInternship",
      label: `${t("app.visualisationCv.DureeContrat")}`,

      values: `${CvById?.search?.durationContract?.label}`,
    },
    {
      key: "workStudyProgram",
      label: `${t("app.detailsCv.workStudyProgram")}`,

      values: `${CvById?.search?.workStudyProgram}`,
    },
    {
      key: "educationLevel",
      label: `${t("app.detailsCv.educationLevel")}`,

      values: `${CvById?.profile?.educationLevel?.label}`,
    },
    {
      key: "availableFrom",
      label: `${t("app.detailsCv.availableFrom")}`,

      values: `${CvById?.search?.availableFrom}`,
    },
    {
      key: "sector",
      label: `${t("app.detailsCv.sector")}`,

      values: `${CvById?.search?.speciality?.label}`,
    },
    {
      key: "subSector",
      label: `${t("app.detailsCv.subSector")}`,

      values: `${CvById?.search?.subSpeciality?.label}`,
    },
    {
      key: "mobility",
      label: `${t("app.detailsCv.mobility")}`,

      values: `${CvById?.mobility?.mobility?.label}`,
    },
    {
      key: "mobilityComment",
      label: `${t("app.detailsCv.mobilityComment")}`,

      values: `${CvById?.mobility?.mobilityComment}`,
    },
    {
      key: "carLicence",
      label: `${t("app.detailsCv.carLicence")}`,

      values: `${CvById?.mobility?.carLicence}`,
    },
    {
      key: "motorcycleLicence",
      label: `${t("app.detailsCv.motorcycleLicence")}`,

      values: `${CvById?.mobility?.motorcycleLicence}`,
    },
    {
      key: "hasVehicle",
      label: `${t("app.detailsCv.hasVehicle")}`,

      values: `${CvById?.mobility?.hasVehicle}`,
    },
    {
      key: "recognizedDisabilityStatus",
      label: `${t("app.detailsCv.recognizedDisabilityStatus")}`,
      values: `${CvById?.search?.recognizedDisabilityStatus}`,
    },

    {
      key: "nbMonthExperence",
      label: `${t("app.detailsCv.nbExperence")}`,
      values: `${CvById?.professionalExperienceMonthNumber}`,
    },

    {
      key: "introduceYourself",
      label: `${t("app.detailsCv.introduceYourself")}`,
      values: `${CvById?.profile?.introduceYourself}`,
    },
  ];

  const contactDetails = [
    {
      key: "phone",
      label: `${t("app.detailsCv.phone")}`,
      values: `${CvById?.contact?.phone}`,
    },
    {
      key: "email",
      label: `${t("app.detailsCv.email")}`,
      values: `${CvById?.contact?.email}`,
    },
    {
      key: "instagramUrl",
      label: `${t("app.detailsCv.instagram")}`,
      values: `${CvById?.contact?.instagramUrl}`,
    },
    {
      key: "youtubeUrl",
      label: `${t("app.detailsCv.youtube")}`,
      values: `${CvById?.contact?.youtubeUrl}`,
    },
    {
      key: "linkedinUrl",
      label: `${t("app.detailsCv.linkedIn")}`,
      values: `${CvById?.contact?.linkedinUrl}`,
    },
    {
      key: "blogUrl",
      label: `${t("app.detailsCv.blog")}`,
      values: `${CvById?.contact?.blogUrl}`,
    },
    {
      key: "videoUrl",
      label: `${t("app.detailsCv.videoCv")}`,
      values: `${CvById?.contact?.videoUrl}`,
    },
  ];
  const contractType = CvById?.search?.contractTypes;
  const acdemicExper = CvById?.experiences?.academicExperiences;
  const projetExperience = CvById?.projects;
  const associativeExper = CvById?.experiences?.associativeExperiences;

  const languageSkills = CvById?.skills?.languageSkills;
  const computerSkills = CvById?.skills?.computerSkills;
  const softSkill = CvById?.skills?.softSkills;
  const proExper = CvById?.experiences?.professionalExperiences;
  console.log("🚀 ~ file: CvById.js:194 ~ CvsById ~ proExper:", proExper);

  const handleType = (type) => {
    switch (type) {
      case "ai" || "eps":
        return "application/postscript";
      case "txt":
        return "text/plain";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "html":
        return "text/html";
      case "csv":
        return "text/csv";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "xls":
        return "application/vnd.ms-excel";
      case "ods":
        return "application/vnd.oasis.opendocument.spreadsheet";
      case "exe":
        return "application/octet-stream";
      case "mp4":
        return "video/mp4";
      case "pgs":
        return "text/plain";
      case "psd":
        return "image/vnd.adobe.photoshop";
      case "rtf":
        return "application/rtf";
      case "xml":
        return "application/xml";
      case "xmd":
        return "application/octet-stream";
      case "zip":
        return "application/zip";
      default:
        return "application/zip";
    }
  };

  useEffect(() => {
    if (CvById?.supportingDocument?.downloadUrl !== undefined) {
      (async () => {
        try {
          setFileType(handleType(CvById?.supportingDocument?.fileExtension));
          await dispatch(
            downloadFileStudentThunk({
              id: CvById?.id,
              axiosCancelToken: axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: fileType }));
              setHref(href);
              window.URL.revokeObjectURL(url);
            });
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={CvById?.supportingDocument?.name}
            />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
  }, [CvById]);

  useLayoutEffect(() => {
    if (CvById?.supportingDocument) {
      dispatch(setFileData(CvById?.supportingDocument));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CvById]);

  useLayoutEffect(() => {
    return () => {
      // axiosCancelToken.cancel();
      dispatch(resetFileData());
      console.log("ModifyYourCV unmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = () => {
    if (!filePending) {
      (async () => {
        try {
          await dispatch(
            downloadFileStudentThunk({
              id: CvById?.id,
              axiosCancelToken: axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: "application/zip" }));
              setHref(href);
            });
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersTelecharges")}
              secondMessage={`${t("app.toastMessages.FichiersTelechargesMessage")} 1`}
            />,
          );
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={CvById?.supportingDocument?.name}
            />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Header
        h1={t("app.librairyCV.title")}
        textElement={<p>{t("app.librairyCV.description")}</p>}
        bgImg={bgConsulOffreImg}
      />

      <div className={styles.offerWrapper}>
        <div className={styles.divline_h2_container}>
          <DivLine />

          <h2>
            <span>{t("app.detailsCv.titleInfoCv")} </span>
            <span className={styles.refOffer}> {CvById?.name}</span>
          </h2>
        </div>
        <div className={styles.actionWrapper}>
          <button type="button" className={styles.lightBtn} onClick={handleClick}>
            {t("app.detailsCv.btnBack")}
          </button>

          <a className={styles.btnContactEtudiant} href={`mailto:${CvById?.contact?.email}`}>
            {t("app.detailsCv.btnContactStudent")}
          </a>
        </div>
        <div className={styles.container__Offer}>
          <h1 className={styles.container__Offer__titleOffre}>
            {CvById?.author?.firstname} {CvById?.author?.name}
          </h1>
          <h1 className={styles.container__Offer__titleOffre__subTitle}>{CvById?.title}</h1>
          <div className={styles.offreList}>
            <h4>{t("app.detailsCv.subTitleSectionProfile")}</h4>

            <div className={styles.offreListDetails}>
              <div className={styles.offreListDetails__option}>
                <h6> {t("app.detailsCv.experienceRecherchee")}</h6>
                {contractType && contractType?.map((contrat) => <p className={styles.inline}>{contrat.label}</p>)}
              </div>
              {profileDetails.map((elem) => (
                <ListDetail key={nanoid()} label={elem.label} value={elem.values} />
              ))}
            </div>
          </div>

          <div className={styles.experience}>
            {acdemicExper && (
              <>
                <hr />
                <h4> {t("app.detailsCv.experienceAcademique")}</h4>
              </>
            )}
            {acdemicExper &&
              acdemicExper?.map(
                (acdemic, index) =>
                  index < 5 && (
                    <div className={styles.offreListDetails} key={index}>
                      <h6 className={styles.subtitle}>{acdemic?.institution}</h6>
                      <div className={styles.cols}>
                        {!isEmpty(acdemic) && (
                          <>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.courseUndertaken")}</h6>
                              <p>{acdemic.course}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.graduationIndicatorSucess")}</h6>
                              <p>{acdemic.graduationSuccessIndicator.label}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.descriptionExperience")}</h6>
                              <p>{acdemic.description}</p>
                            </div>

                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.endDate")}</h6>
                              <p>{acdemic.endDate}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.startDate")}</h6>
                              <p>{acdemic.startDate}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <p>{acdemic.country.label}</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ),
              )}
          </div>
          <div className={styles.experience}>
            {proExper && (
              <>
                <hr />
                <h4>{t("app.detailsCv.experiencePro")} </h4>
              </>
            )}
            {proExper &&
              proExper?.map(
                (proExper, index) =>
                  index < 5 && (
                    <div className={styles.offreListDetails} key={index}>
                      <h6 className={styles.subtitle}>{proExper?.organizationName}</h6>
                      {!isEmpty(proExper) && (
                        <div className={styles.cols}>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.startDate")}</h6>
                            <p>{proExper.startDate}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.endDate")}</h6>
                            <p>{proExper.endDate}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.organizationName")}</h6>
                            <p>{proExper.organizationName}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.position")}</h6>
                            <p>{proExper.position}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.performedTasks")}</h6>
                            <p>{proExper.performedTasks}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.acquiredSkills")}</h6>
                            <p>{proExper.acquiredSkills}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.librairyCV.contractType")}</h6>
                            <p>{proExper.contractType.label}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ),
              )}
          </div>
          <div className={styles.experience}>
            {associativeExper && (
              <>
                <hr />
                <h4>{t("app.detailsCv.experienceAsso")} </h4>
              </>
            )}
            {associativeExper &&
              associativeExper?.map(
                (associa, index) =>
                  index < 5 && (
                    <div className={styles.offreListDetails} key={index}>
                      <h6 className={styles.subtitle}>{associa?.organizationName}</h6>
                      <div className={styles.cols}>
                        <div>
                          <div className={styles.cols}>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.performedTasks")}</h6>
                              <p>{associa.performedTasks}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.startDate")}</h6>
                              <p>{associa.startDate}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.endDate")}</h6>
                              <p>{associa.endDate}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.position")}</h6>
                              <p>{associa.position}</p>
                            </div>
                            <div className={styles.offreListDetails__option}>
                              <h6>{t("app.detailsCv.acquiredSkills")}</h6>
                              <p>{associa.acquiredSkills}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
              )}
          </div>

          <div className={styles.experience}>
            {projetExperience && (
              <>
                <hr />
                <h4> {t("app.detailsCv.projectAcadimic")}</h4>
              </>
            )}
            {projetExperience &&
              projetExperience?.map(
                (projectExper, index) =>
                  index < 5 && (
                    <div className={styles.offreListDetails} key={index}>
                      <h6 className={styles.subtitle}>{projectExper.organizationName}</h6>
                      {!isEmpty(projectExper) && (
                        <div className={styles.cols}>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.organizationName")}</h6>
                            <p>{projectExper.organizationName}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.descriptionExperience")}</h6>
                            <p>{projectExper.description}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.startDate")}</h6>
                            <p>{projectExper.startDate}</p>
                          </div>
                          <div className={styles.offreListDetails__option}>
                            <h6>{t("app.detailsCv.endDate")}</h6>
                            <p>{projectExper.endDate}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ),
              )}
          </div>
          <hr />

          <div className={styles.offreList}>
            <h4>{t("app.detailsCv.skills")}</h4>

            <div className={`${styles.offreListDetails}  ${styles.skills}`}>
              <h5 className={styles.subtitle}>{t("app.depotCv.MaitriseDesLangues")}</h5>

              <div className={styles.containerSkills}>
                {languageSkills &&
                  languageSkills?.map((lang) => (
                    <div className={styles.multiCol} key={nanoid()}>
                      <span>{lang.lang.label}</span>
                      <span>{lang.other}</span>
                      <span className={styles.indigoColor}>{lang.level.label}</span>
                      <span> {lang.certification}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.offreList}>
              <div className={styles.offreListDetails__option}>
                <h6>{t("app.detailsCv.frenshLevel")}</h6>
                <p> {CvById?.profile?.frenchLevel?.key}</p>
              </div>
            </div>
          </div>

          <div className={styles.offreList}>
            <div className={`${styles.offreListDetails}  ${styles.skills}`}>
              <h5 className={styles.subtitle}>{t("app.visualisationCv.CompetencesInformatiques")}</h5>
              <div className={styles.containerSkills}>
                {computerSkills &&
                  computerSkills?.map((skillIt) => (
                    <div className={styles.multiCol} key={nanoid()}>
                      <span>{skillIt?.domain?.label}</span>
                      <span className={styles.indigoColor}>{skillIt?.software}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.offreList}>
            <div className={`${styles.offreListDetails} ${styles.column}`}>
              <h5 className={styles.subtitle}>{t("app.detailsCv.additionalSkills")}</h5>
              <h6>{t("app.detailsCv.softSkill")}</h6>

              {softSkill &&
                softSkill?.map((softSkills, index) => (
                  <div className={styles.offreListDetails__option} key={index}>
                    <p>{softSkills.label}</p>
                  </div>
                ))}
              <div className={styles.offreListDetails__option}>
                <h6>{t("app.detailsCv.curricualarActivity")}</h6>

                <p>{CvById?.extraActivities}</p>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.offreList}>
            <div className={styles.offreListDetails}>
              {contactDetails?.map((contact) => (
                <ListDetail key={nanoid()} label={contact.label} value={contact.values} />
              ))}
            </div>
          </div>
        </div>
        {fileData && CvById && (
          <div className={styles.saveDocument_master_container}>
            <label className={styles.label_padding} htmlFor="chargementDeDocument">
              {t("app.visualisationCv.DocumentToDownload")}
            </label>
            <DocumentsToDownload
              fileType={CvById?.supportingDocument?.fileExtension}
              href={href}
              onClick={() => handleUpload()}
              download={CvById?.supportingDocument?.title}
              fileTitle={CvById?.supportingDocument?.title}
              spanErrorMessage={false}
              supportingDocument={CvById?.supportingDocument?.downloadUrl}
            />
          </div>
        )}
        <div className={styles.actionWrapper}>
          <button type="button" className={styles.lightBtn} onClick={handleClick}>
            {t("app.detailsCv.btnBack")}
          </button>

          <a className={styles.btnContactEtudiant} href={`mailto:${CvById?.contact?.email}`}>
            {t("app.detailsCv.btnContactStudent")}
          </a>
        </div>
      </div>
    </>
  );
}
