import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getAcademicProjectsThunk = createAsyncThunk("academicProjects/getAcademicProjectsThunk", async (id) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${id}/projects`);
  return response.data;
});

export const getAcademicProjectsByIdThunk = createAsyncThunk(
  "academicProjects/getAcademicProjectsByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/projects/${ID}`);
    return response.data;
  },
);

export const postProjectsThunk = createAsyncThunk("academicProjects/postProjectsThunk", async ({ dataToSend, id }) => {
  const response = await axiosStudent().post(`/v1/students/resumes/${id}/projects`, dataToSend);
  return response.data;
});

export const patchProjectsThunk = createAsyncThunk(
  "academicProjects/patchProjectsThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/projects/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const academicProjectsSlice = createSlice({
  name: "academicProjects",
  initialState,
  extraReducers: {
    [getAcademicProjectsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getAcademicProjectsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAcademicProjectsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postProjectsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postProjectsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postProjectsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchProjectsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchProjectsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchProjectsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getAcademicProjectsByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
      return state;
    },
    [getAcademicProjectsByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAcademicProjectsByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
  },
});

export const getProjects = (state) => state.academicProjects.data;
export const getProjectsPending = (state) => state.academicProjects.pending;

export default academicProjectsSlice.reducer;
