import { t } from "i18next";
import DivLine from "components/DivLine/DivLine";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { toastBrowser } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import {
  DISCOVER_OMNES_EDUCATION_URL,
  DISCOVER_OUR_SCHOOLS_URL,
  DISCOVER_LEARNING_TAXE_URL,
} from "utils/globalConstantsAndURL";
import Card from "../Card/Card";

import discoverImg from "../assets/discover.png";
import discoverMobileImg from "../assets/discoverMobile.png";
import cardKnow2Img from "../assets/cardKnow2.png";
import cardKnow3Img from "../assets/cardKnow3.png";

import styles from "./HomepageNotAuthenticated.module.scss";

const HomepageNotAuthenticated = () => {
  useEffect(() => {
    toastBrowser(
      <ToastMessageStructure
        firstMessage={t("app.toastMessages.browserNotificationTitle")}
        secondMessage={t("app.toastMessages.browserNotificationText")}
      />,
    );
  }, []);

  return (
    <>
      <div className={styles.header}>
        <h1>{t("app.homePageEntreprise.Bienvenue")}</h1>
        <p>{t("app.homePageEntreprise.IntroLogOut")}</p>

        <div className={styles.actionBtn}>
          <Link to="/inscription-entreprises">{t("app.homePageEntreprise.InscrivezVousLabel")}</Link>
          <a
            href="https://www.omneseducation.com/le-groupe/a-propos/"
            className={styles.lightBtn}
            target="_blank"
            rel="noreferrer"
          >
            {t("app.homePageEntreprise.DecouvrezOMNES")}
          </a>
        </div>
      </div>
      <section className={styles.discover_container}>
        <img className={styles.discover_trapeze_img} src={discoverImg} alt="Mains sur un clavier d'ordinateur" />
        <img className={styles.discover_mobile_img} src={discoverMobileImg} alt="Mains sur un clavier d'ordinateur" />
        <div className={styles.discover_content_container}>
          <DivLine />
          <h2 className={styles.discover_content_title}>{t("app.homePageEntreprise.InscrivezVousTitre")}</h2>
          <div className={styles.discover_content_desc}>
            <p>{t("app.homePageEntreprise.InscrivezVous")}</p>
          </div>
        </div>
      </section>
      <section className={styles.cards}>
        <div className={styles.cardsItem}>
          <h3>{t("app.homePageEntreprise.Advantage1Titre")}</h3>
          <p>{t("app.homePageEntreprise.Advantage1")}</p>
        </div>
        <div className={styles.cardsItem}>
          <h3>{t("app.homePageEntreprise.Advantage2Titre")}</h3>
          <p>{t("app.homePageEntreprise.Advantage2")}</p>
        </div>
        <div className={styles.cardsItem}>
          <h3> {t("app.homePageEntreprise.Advantage3Titre")}</h3>
          <p>{t("app.homePageEntreprise.Advantage3")}</p>
        </div>
      </section>
      <section className={styles.third_container}>
        <div className={styles.title_third_container}>
          <DivLine />
          <h2>{t("app.homePageEntreprise.MieuxNousConnaitres")}</h2>
        </div>
        <div className={styles.cards_container}>
          <Card
            path={DISCOVER_OMNES_EDUCATION_URL}
            img={cardKnow3Img}
            h3={t("app.homePageEntreprise.OMNES")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
          <Card
            path={DISCOVER_OUR_SCHOOLS_URL}
            img={cardKnow2Img}
            h3={t("app.homePageEntreprise.NosEcoles")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
          <Card
            path={DISCOVER_LEARNING_TAXE_URL}
            img={cardKnow3Img}
            h3={t("app.homePageEntreprise.TaxeApprentissage")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
        </div>
      </section>
    </>
  );
};

export default HomepageNotAuthenticated;
