import { Routes, Route } from "react-router-dom";

import { AuthenticatedTemplate } from "@azure/msal-react";

import { useSelector } from "react-redux";
import { getMeData, getMeDataV2, getUserConnexionErrorMessage } from "app/slices/userConnexion/userConnexion.slice";

import { LOCAL_STUDENT_BYPASS } from "utils/globalConstantsAndURL";

import Loader from "components/Loader/Loader";

import ContactUsETU from "pages/ETU/ContactUs/ContactUsETU";
// import CoachingSession from "pages/ETU/CoachingSessions/CoachingSessions";
import DeclareARecruitment from "pages/ETU/DeclareARecruitment/DeclareARecruitment";
import Faq from "pages/ETU/Faq/Faq";
import LearningBookletsETU from "pages/ETU/LearningBookletsETU/LearningBookletsETU";
import SelectedBookletETU from "pages/ETU/LearningBookletsETU/SelectedBookletETU/SelectedBookletETU";
import ConfirmationBookletETU from "pages/ETU/LearningBookletsETU/ConfirmationBookletETU/ConfirmationBookletETU";
import CreateCV from "../../pages/ETU/CreateYourCV/CreateCV";
import CheckVacanciesOffers from "../../pages/ETU/CheckVacanciesOffers/CheckVacanciesOffers";
import OffreById from "../../pages/ETU/OffreById/OffreById";
import ModifyYourCV from "../../pages/ETU/ModifyYourCV/ModifyYourCV";
import Page404 from "../../pages/global/Page404/Page404";
import CheckYourCV from "../../pages/ETU/CheckYourCV/CheckYourCV";
import Homepage from "../../pages/ETU/Homepage/Homepage";

const RoutesETU = () => {
  const getMe = useSelector(getMeData);
  const getMeV2 = useSelector(getMeDataV2);
  const userConnexionErrorMessage = useSelector(getUserConnexionErrorMessage);

  const RoutesAuthenticatedStructure = (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/deposez-un-cv" element={<CreateCV />} />
      <Route path="/mes-cvs" element={<CheckYourCV />} />
      <Route path="/consultez-les-offres" element={<CheckVacanciesOffers />} />
      <Route path="/offres/:id" element={<OffreById />} />
      {/* <Route path="/consultez-vos-postulations" element={<h1>consultez-vos-postulations</h1>} /> */}
      <Route path="/mes-cvs/:id" element={<ModifyYourCV />} />
      {/* <Route path="/seance-coaching" element={<CoachingSession />} /> */}
      <Route path="/declarezUnRecrutement" element={<DeclareARecruitment />} />
      <Route path="/conventionDeStage" element={<h1>conventionDeStage</h1>} />
      <Route path="/livret-dapprentissage" element={<LearningBookletsETU />} />
      <Route path="/livret-dapprentissage/:id" element={<SelectedBookletETU />} />
      <Route path="/livret-dapprentissage/confirmation" element={<ConfirmationBookletETU />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contactez-nous" element={<ContactUsETU />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );

  if (LOCAL_STUDENT_BYPASS) {
    return (
      <>
        {(!getMe || !getMeV2) && !userConnexionErrorMessage && <Loader />}
        {getMe && getMeV2 && RoutesAuthenticatedStructure}
      </>
    );
  }

  return (
    <>
      {(!getMe || !getMeV2) && !userConnexionErrorMessage && <Loader />}
      <AuthenticatedTemplate>{getMe && getMeV2 && RoutesAuthenticatedStructure}</AuthenticatedTemplate>
    </>
  );
};

export default RoutesETU;
