/* eslint-disable */
// fonction qui vérifer (array object num string ) est vide ou non

export function isEmpty(value) {
  if (value === null || value === undefined || value === "undefined" || value === "null") {
    return true;
  }

  if (value.prop && value.prop.constructor === Array) {
    return value.length === 0;
  } else if (typeof value === "object") {
    return Object.entries(value).length === 0 && value.constructor === Object;
  } else if (typeof value === "string") {
    return value.length === 0;
  } else if (typeof value === "number") {
    return value === 0;
  } else if (!value) {
    return true;
  }
  return false;
}
