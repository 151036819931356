import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getYourCVByIdPending, patchCVByIdThunk } from "app/slices/modifyYourCV/modifyYourCV.slice";

import ContinueModifyBtn from "components/ContinueModifyBtn/ContinueModifyBtn";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import closeIcon from "../assets/close-svg.svg";
import styles from "../modalWrapper.module.scss";

/* eslint-disable */
const ValidateFormModal = (props) => {
  const pending = useSelector(getYourCVByIdPending);
  const dispatch = useDispatch();
  const { id } = useParams();

  const finalSubmit = () => {
    if (!pending) {
      const dataToSend = {
        // status: props.modifyingOrNot ? "Complété" : "En cours de modification",
        status: "Complété",
      };
      (async () => {
        try {
          await dispatch(
            patchCVByIdThunk({
              dataToSend,
              id,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.successCvModifyTitle")}
              secondMessage={t("app.toastMessages.successCvModifyMessage")}
            />,
          );
        } catch (error) {
          console.log(error);
        }
      })();
    }
  };

  if (props.show)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModificationDunCV")}</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4rem",
              }}
            >
              <div className={styles.button_modify_modal_container}>
                <ContinueModifyBtn onClick={props.onClose} text={t("app.visualisationCv.ContinuerModification")} />
              </div>
              <div className={styles.button_modify_modal_container}>
                <button
                  type="submit"
                  onClick={() => {
                    props.onClose();
                    finalSubmit();
                  }}
                  className={styles.showModify_btn}
                >
                  {t("app.visualisationCv.SoumettreVotreCV")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  if (!props.show) return null;
};

export default ValidateFormModal;
