import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { COMPANY_SUBDOMAIN_NAME } from "utils/globalConstantsAndURL";

import { useSelector, useDispatch } from "react-redux";
import { getMeData } from "app/slices/userConnexion/userConnexion.slice";
import { getDropdownDisplayStatus, handleDropdownDisplay } from "../../app/slices/dropdown/dropdown.slice";
import { getSubdomain } from "../../app/slices/subdomain/subdomain.slice";

import Dropdown from "./Dropdown/Dropdown";
import SignInBtn from "./SignInBtn/SignInBtn";
import SignOutBtn from "./SignOutBtn/SignOutBtn";
import DropDownLanguage from "../DropDownLanguage/DropDownLanguage";

import omnesLogo from "./assets/Logo-blanc-violet.png";
import iconBurger from "./assets/icon-burger.svg";
import iconBurgerClose from "./assets/icon-burger-close.svg";

import styles from "./Navbar.module.scss";

const Navbar = () => {
  const [name, setName] = useState("");

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const currentAccount = instance.getActiveAccount();

  const dispatch = useDispatch();
  const dropdownDisplayed = useSelector(getDropdownDisplayStatus);
  const subdomain = useSelector(getSubdomain);
  const getMe = useSelector(getMeData);

  useEffect(() => {
    // console.log("currentAccount : ", currentAccount);
    if (currentAccount && getMe) {
      console.log("useEffect currentAccount true", currentAccount);
      setName(`${getMe.firstname} ${getMe.name}`);
    }
  }, [currentAccount, getMe]);

  // This function is triggered on the click of the burger icon.
  // The logic is used to control the display of the correct icon.
  const handleDropdown = () => {
    dispatch(handleDropdownDisplay());
  };

  /* eslint-disable */
  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.right_container}>
          <div className={styles.separator}></div>
          {!isAuthenticated && subdomain === COMPANY_SUBDOMAIN_NAME && <SignInBtn displayProfil />}
          {isAuthenticated && <SignOutBtn displayProfil name={name} />}
          <div className={styles.separator}></div>
          {(isAuthenticated || subdomain === COMPANY_SUBDOMAIN_NAME) && <DropDownLanguage />}
        </div>
        <Link to="/">
          <div className={styles.logo_container}>
            <img src={omnesLogo} alt="Logo Omnes éducation" />
          </div>
        </Link>
        <div className={styles.burger_icon} onClick={handleDropdown}>
          <img src={!dropdownDisplayed ? iconBurger : iconBurgerClose} alt="" />
        </div>
      </nav>
      <Dropdown name={name} />
    </>
  );
};

export default Navbar;
