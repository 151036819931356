// Change the values to true if you want to develop locally based on the portal and specify the token.
export const LOCAL_STUDENT_BYPASS = false; // boolean
export const LOCAL_COMPANY_BYPASS = false; // boolean
export const LOCAL_USERTOKEN = ""; // Empty string or userToken string if locally

// Constants
export const STUDENT_SUBDOMAIN_NAME = "etudiants";
export const COMPANY_SUBDOMAIN_NAME = "entreprises";
export const TUTOR_SUBDOMAIN_NAME = "referentsalternance";
export const DISCOVER_OMNES_EDUCATION_URL = "https://www.omneseducation.com/le-groupe/a-propos/";
export const DISCOVER_OUR_SCHOOLS_URL = "https://www.omneseducation.com/nos-etablissements/nos-ecoles/";
export const DISCOVER_LEARNING_TAXE_URL = "https://www.omneseducation.com/entreprises/taxe-apprentissage/";
