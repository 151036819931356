import { t } from "i18next";

import styles from "../PartTimeDetails.module.scss";

/* eslint-disable */
const StudientSection = (props) => {
  const formatDate = (input) => {
    var datePart = input?.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  };

  if (props.recruitment) {
    return (
      <>
        <p className={styles.user_name}>
          {props?.recruitment?.student?.firstname} {props?.recruitment?.student?.name}
        </p>

        <div className={styles.user_info_container}>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.DateDeNaissance")}</label>
            <p className={styles.info_container}>
              {formatDate(props?.recruitment?.student?.birthdate) === undefined
                ? "//////////"
                : formatDate(props?.recruitment?.student?.birthdate)}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.EcoleDeFormation")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.trainingSchool === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.trainingSchool}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.Uai")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber ===
              undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber}
            </p>
          </div>
        </div>

        <div className={styles.user_info_container}>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.AnneeUniversitaire")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.schoolYear === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.schoolYear}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.Session")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.session?.label === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.session?.label}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.DateDeDebutDeFormation")}</label>
            <p className={styles.info_container}>
              {formatDate(props?.recruitment?.student?.schoolProgramEnrollment?.startDate) === undefined
                ? "//////////"
                : formatDate(props?.recruitment?.student?.schoolProgramEnrollment?.startDate)}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.Annee")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.year === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.year}
            </p>
          </div>
        </div>

        <div className={styles.user_info_container}>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.Promotion")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.promotion === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.promotion}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.SousPromotion")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.subPromotion === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.subPromotion}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.SousPromotionEnvisagee")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.wantedSubPromotion === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.wantedSubPromotion}
            </p>
          </div>
        </div>
        <div className={styles.user_info_container}>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.NomDiplome")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.diploma?.name === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.diploma?.name}
            </p>
          </div>
          <div className={styles.user_info_card}>
            <label className={styles.label}>{t("app.suivezVosContratsPartner.CodeDiplome")}</label>
            <p className={styles.info_container}>
              {props?.recruitment?.student?.schoolProgramEnrollment?.diploma?.code === undefined
                ? "//////////"
                : props?.recruitment?.student?.schoolProgramEnrollment?.diploma?.code}
            </p>
          </div>
        </div>
      </>
    );
  }
};

export default StudientSection;
