import NextStepButton from "components/nextStepBtn/nextStepBtn";
import DivLine from "components/DivLine/DivLine";
import Card from "components/ConfirmationFormsPage/Card/Card";
import { t } from "i18next";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
  patchStepThunk,
} from "app/slices/declareARecruitment/declareARecruitment.slice";

import { DISCOVER_OMNES_EDUCATION_URL, DISCOVER_OUR_SCHOOLS_URL } from "utils/globalConstantsAndURL";

import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

import styles from "./Step4.module.scss";

const Step4 = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  const handleSubmit = () => {
    // Data to send object initialization
    const dataToSend = { stage: "Etape5" };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionHeader}>
        <h1 className={styles.title}>{t("app.declarezUnRecrutement.ConfirmationEtape")}</h1>
        <div className={styles.content}>
          <p>{t("app.declarezUnRecrutement.Confirmation1")}</p>
          <p>{t("app.declarezUnRecrutement.Confirmation2")}</p>
        </div>
        <div className={styles.btnWrpper}>
          <NextStepButton text={t("app.declarezUnRecrutement.DeclarezVosInformations")} handleNextStep={handleSubmit} />
        </div>
      </div>

      <section className={styles.third_container}>
        <div className={styles.title_third_container}>
          <DivLine />
          <h2>{t("app.homePageEntreprise.MieuxNousConnaitres")}</h2>
        </div>
        <div className={styles.cards_container}>
          <Card
            path={DISCOVER_OMNES_EDUCATION_URL}
            img={cardKnow3Img}
            h3={t("app.homePageEntreprise.OMNES")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
          <Card
            path={DISCOVER_OUR_SCHOOLS_URL}
            img={cardKnow2Img}
            h3={t("app.homePageEntreprise.NosEcoles")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
        </div>
      </section>
    </div>
  );
};

export default Step4;
