import DivLine from "components/DivLine/DivLine";
import Card from "components/ConfirmationFormsPage/Card/Card";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { DISCOVER_OMNES_EDUCATION_URL, DISCOVER_OUR_SCHOOLS_URL } from "utils/globalConstantsAndURL";
import { useEffect } from "react";

import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

import styles from "./Confirmation.module.scss";

const Confirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionHeader}>
        <h1 className={styles.title}>{t("app.declarezUnRecrutement.Confirmation")}</h1>
        <div className={styles.content}>
          <p>{t("app.declarezUnRecrutement.Confirmation22")}</p>
        </div>
        <div className={styles.btnWrpper}>
          <Link to="/">{t("app.declarezUnRecrutement.RetourPageAccueil")}</Link>
        </div>
      </div>

      <section className={styles.third_container}>
        <div className={styles.title_third_container}>
          <DivLine />
          <h2>{t("app.homePageEntreprise.MieuxNousConnaitres")}</h2>
        </div>
        <div className={styles.cards_container}>
          <Card
            path={DISCOVER_OMNES_EDUCATION_URL}
            img={cardKnow3Img}
            h3={t("app.homePageEntreprise.OMNES")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
          <Card
            path={DISCOVER_OUR_SCHOOLS_URL}
            img={cardKnow2Img}
            h3={t("app.homePageEntreprise.NosEcoles")}
            text={t("app.homePageEntreprise.NousConnaitre1")}
          />
        </div>
      </section>
    </div>
  );
};

export default Confirmation;
