import { configureStore } from "@reduxjs/toolkit";
import { redirectToRootIf401 } from "./customMiddlewares/customMiddlewares";

import deviceWidthReducer from "./slices/deviceWidth/deviceWidth.slice";
import dropdownReducer from "./slices/dropdown/dropdown.slice";
import checkYourCVReducer from "./slices/checkYourCV/checkYourCV.slice";
import coachingSessionsReducer from "./slices/coachingSessions/coachingSessions.slice";
import subdomainReducer from "./slices/subdomain/subdomain.slice";
import publishAnOfferSlice from "./slices/publishAnOffer/publishAnOffer.slice";
import createYourCVSlice from "./slices/createYourCV/createYourCV.slice";
import checkYourOffersReducer from "./slices/followOffers/followOffers.slice";
import userConnexionReducer from "./slices/userConnexion/userConnexion.slice";
import invoiceSlice from "./slices/createYourCV/additems.slice";
import languagesSlice from "./slices/language/language.slice";
import checkAllOffersSlice, { checkAllOffersKey } from "./slices/offre/checkAllOffers.slice";
import checkAllCVSlice, { checkgetCVsKey } from "./slices/ListOfCV/checkAllCV.slice";
import referentialsReducer from "./slices/referentials/referentials.slice";
import modifyYourCVSlice from "./slices/modifyYourCV/modifyYourCV.slice";
import academicExperiencesSlice from "./slices/modifyYourCV/academicExperiences.slice";
import academicProjectsSlice from "./slices/modifyYourCV/academicProjects.slice";
import associativeExperiencesSlice from "./slices/modifyYourCV/associativeExperiences.slice";
import professionalExperiencesSlice from "./slices/modifyYourCV/professionalExperiences.slice";
import languageSkillsSlice from "./slices/modifyYourCV/languageSkills.slice";
import computerSkillsSlice from "./slices/modifyYourCV/computerSkills.slice";
import offerDetailsSlice from "./slices/offerDetails/offerDetails.slice";
import createYourExperienceReducer from "./slices/createYourCV/createYourExperience.slice";
import createYourProExperienceReducer from "./slices/createYourCV/createYourProExperience.slice";
import getYourExperiencesReducer from "./slices/createYourCV/getYourExperiences.slice";
import createYourProjectReducer from "./slices/createYourCV/createYourProject.slice";
import createYourAssoReducer from "./slices/createYourCV/createYourAsso.slice";
import createYourLanguageSkillReducer from "./slices/createYourCV/createLanguageSkill.slice";
import createYourComputerSkillReducer from "./slices/createYourCV/createComputerSkill.slice";
import uploadFileReducer from "./slices/uploadFile/uploadFile.slice";
import declareARecruitmentReducer from "./slices/declareARecruitment/declareARecruitment.slice";
import checkDetailsCompanyReducer from "./slices/checkDetailsCompany/checkDetailsCompany.slice";
import getYourProjectsReducer from "./slices/createYourCV/getYourProjects.slice";
import getYourAssoReducer from "./slices/createYourCV/getYourAsso.slice";
import getYourProExperiencesReducer from "./slices/createYourCV/getYourProExperiences.slice";
import getYourLanguageSkillReducer from "./slices/createYourCV/getYourLanguageSkill.slice";
import getYourComputerSkillsReducer from "./slices/createYourCV/getYourComputerSkills.slice";
import recruitmentListReducer from "./slices/companyRecruitment/getCompanyRecruitment.slice";
import recruitementForm1Reducer from "./slices/companyRecruitment/Form1/getCompanyRecruitmentForm1.slice";
import recruitementForm2Reducer from "./slices/companyRecruitment/Form2/getCompanyRecruitmentForm2.slice";
import recruitementForm3Reducer from "./slices/companyRecruitment/Form3/getCompanyRecruitmentForm3.slice";
import recruitementForm4Reducer from "./slices/companyRecruitment/Form4/getCompanyRecruitmentForm4.slice";
import recruitementForm5Reducer from "./slices/companyRecruitment/Form5/getCompanyRecruitmentForm5.slice";
import recruitementForm6Reducer from "./slices/companyRecruitment/Form6/getCompanyRecruitmentForm6.slice";
import recruitementForm7Reducer from "./slices/companyRecruitment/Form7/getCompanyRecruitmentForm7.slice";
import recruitementForm8Reducer from "./slices/companyRecruitment/Form8/getCompanyRecruitmentForm8.slice";
import recruitementForm9Reducer from "./slices/companyRecruitment/Form9/getCompanyRecruitmentForm9.slice";
import recruitementForm10Reducer from "./slices/companyRecruitment/Form10/getCompanyRecruitmentForm10.slice";
import getAllCompaniesReducer from "./slices/checkDetailsCompany/getAllCompanies.slice";
import getCitiesReducer from "./slices/getCities/getCities.slice";
import getCodeIdccReducer from "./slices/codeIdcc/getCodeIdcc.slice";
import getAllCompaniesReducerWithoutToken from "./slices/checkDetailsCompanyWithoutToken/getAllCompaniesWithoutToken.slice";
import checkDetailsCompanyReducerWithoutToken from "./slices/checkDetailsCompanyWithoutToken/checkDetailsCompanyWithoutToken.slice";
import learningBookletsReducer from "./slices/learningBooklets/learningBooklets.slice";
import getCompanyReducer from "./slices/detailsCompany/detailsCompany.slice";

export const store = configureStore({
  reducer: {
    deviceWidth: deviceWidthReducer,
    dropdown: dropdownReducer,
    checkYourCV: checkYourCVReducer,
    checkYourCoaching: coachingSessionsReducer,
    checkYourOffers: checkYourOffersReducer,
    subdomain: subdomainReducer,
    publishAnOffer: publishAnOfferSlice,
    userConnexion: userConnexionReducer,
    createYourCV: createYourCVSlice,
    addNewForm: invoiceSlice,
    [checkAllOffersKey]: checkAllOffersSlice,
    [checkgetCVsKey]: checkAllCVSlice,
    referentials: referentialsReducer,
    languages: languagesSlice,
    modifyYourCV: modifyYourCVSlice,
    academicExperiences: academicExperiencesSlice,
    academicProjects: academicProjectsSlice,
    associativeExperiences: associativeExperiencesSlice,
    profesionalExperiences: professionalExperiencesSlice,
    languageSkills: languageSkillsSlice,
    computerSkills: computerSkillsSlice,
    offerDetails: offerDetailsSlice,
    createYourExperience: createYourExperienceReducer,
    createYourProExperience: createYourProExperienceReducer,
    getYourExperiences: getYourExperiencesReducer,
    createYourProject: createYourProjectReducer,
    createYourAsso: createYourAssoReducer,
    createYourLanguageSkill: createYourLanguageSkillReducer,
    createYourComputerSkill: createYourComputerSkillReducer,
    uploadFile: uploadFileReducer,
    declareARecruitment: declareARecruitmentReducer,
    checkDetailsCompany: checkDetailsCompanyReducer,
    getYourAcademicExperiences: getYourExperiencesReducer,
    getYourAcademicProjects: getYourProjectsReducer,
    getYourAssociativeExperiences: getYourAssoReducer,
    getYourProfessionalExperiences: getYourProExperiencesReducer,
    getYourLanguageSkills: getYourLanguageSkillReducer,
    getYourComputerSkills: getYourComputerSkillsReducer,
    checkYourRecruitments: recruitmentListReducer,
    checkYourRecruitmentsForm1: recruitementForm1Reducer,
    checkYourRecruitmentsForm2: recruitementForm2Reducer,
    checkYourRecruitmentsForm3: recruitementForm3Reducer,
    checkYourRecruitmentsForm4: recruitementForm4Reducer,
    checkYourRecruitmentsForm5: recruitementForm5Reducer,
    checkYourRecruitmentsForm6: recruitementForm6Reducer,
    checkYourRecruitmentsForm7: recruitementForm7Reducer,
    checkYourRecruitmentsForm8: recruitementForm8Reducer,
    checkYourRecruitmentsForm9: recruitementForm9Reducer,
    checkYourRecruitmentsForm10: recruitementForm10Reducer,
    allCompanies: getAllCompaniesReducer,
    getCities: getCitiesReducer,
    getCodeIdcc: getCodeIdccReducer,
    allCompaniesWithoutToken: getAllCompaniesReducerWithoutToken,
    checkDetailsCompanyWithoutToken: checkDetailsCompanyReducerWithoutToken,
    learningBooklets: learningBookletsReducer,
    detailsCompany: getCompanyReducer,
  },
  middleware: (gDM) => gDM().concat(redirectToRootIf401),
});
