import { t } from "i18next";

import { nanoid } from "@reduxjs/toolkit";

import styles from "./SummaryCard.module.scss";

const SummaryCard = ({ data }) => (
  <div className={styles.wrapper__colRight}>
    <div className={styles.boxRecap}>
      <h4>{t("app.declarezUnRecrutement.RecapitulatifEtapePrecedente")}</h4>
      {data?.map((obj) => (
        <div className={styles.listInfo} key={nanoid()}>
          <span className={styles.listInfo__name}>{obj.title}</span>
          <span className={styles.info}>{obj.text}</span>
        </div>
      ))}
    </div>
  </div>
);

export default SummaryCard;
