import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { DropdownList } from "react-widgets";
import {
  getUserRecruitmentsPending,
  getYourRecruitmentPropsData,
} from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { formatDate } from "utils/formatDate";

import { createObjAucun } from "utils/localReferentials";
import { toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import styles from "./Form7.module.scss";

const apprenticeshipType = {
  key: "apprenticeshipType",
};
const contractType = {
  key: "contractType",
};

/* eslint-disable */
const Form7 = (props) => {
  const dispatch = useDispatch();
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const objAucun = createObjAucun();
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  console.log("recruitmentPropsGeneral", recruitmentPropsGeneral);
  const pending = useSelector(getUserRecruitmentsPending);

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);
  const [clicked, seIsClicked] = useState(false);
  const [grossSalaryDisable, setGrossSalaryDisable] = useState(false);

  const [checkboxOtherPlaceOfExecution, setCheckboxOtherPlaceOfExecution] = useState(false);

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    if (values) {
      if (values?.apprenticeshipType?.key === "Contrat de professionnalisation") setGrossSalaryDisable(false);
      else setGrossSalaryDisable(true);
    }
  }, []);

  useEffect(() => {
    if (
      recruitmentPropsGeneral?.contract?.executionAddress?.street ||
      recruitmentPropsGeneral?.contract?.executionAddress?.zipCode ||
      recruitmentPropsGeneral?.contract?.executionAddress?.city
    ) {
      setCheckboxOtherPlaceOfExecution(true);
    }
  }, [recruitmentPropsGeneral]);

  const handleMountPingStatus = () => {
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };
  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const today = new Date();
  const todayPlus7 = new Date(new Date(today).setDate(today.getDate() + 7));

  // Yup validation schema
  const Form7Schema = Yup.object().shape({
    apprenticeshipType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    })
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-")
      .nullable(),
    jobTitle: Yup.string().required(t("app.errorMessages.RemplirChamp")).max(40, `${t("app.errorMessages.Max40")}`),
    contractType: Yup.object({ key: Yup.string() })
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-")
      .nullable(),
    startDate: Yup.date()
      .min(formatDate(todayPlus7), t("app.errorMessages.dateEndPlus"))
      .typeError(t("app.errorMessages.FormatDateInvalide")),
    // .required()
    // .nullable(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")),
    // .required()
    // .nullable(),
    weeklyWorkingHours: Yup.number()
      .min(0.0, t("app.errorMessages.numberGreaterThan0.0"))
      .max(99.99, t("app.errorMessages.numberLesserThan99.99"))
      .required(t("app.errorMessages.RemplirChamp"))
      .nullable(),
    grossSalary: Yup.string().when("apprenticeshipType", {
      is: (apprenticeshipType) => apprenticeshipType?.key === "Contrat de professionnalisation",
      then: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    }),

    publicSector: Yup.boolean(),
    dangerousMachineryUse: Yup.boolean(),
    mission1: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    mission2: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    mission3: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    adress:
      checkboxOtherPlaceOfExecution &&
      Yup.string()
        .max(255, t("app.errorMessages.Max255"))
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    additionalAddress: checkboxOtherPlaceOfExecution && Yup.string().max(255, t("app.errorMessages.Max255")),
    zipCode:
      checkboxOtherPlaceOfExecution &&
      Yup.string()
        .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
        .min(5, t("app.errorMessages.NombreItemRequis"))
        .max(5, t("app.errorMessages.NombreItemRequis"))
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    city:
      checkboxOtherPlaceOfExecution &&
      Yup.string()
        .max(255, t("app.errorMessages.Max255"))
        .required(`${t("app.errorMessages.RemplirChamp")}`),
  });

  const onSubmit = (values) => {
    if (!formikErrorJustMount) {
      console.log("CONTRACT VALUE TO SEND 1");

      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      const dataToSend = {
        status: "En cours",
        contract: {
          apprenticeshipType: valuesCopy?.apprenticeshipType?.key,
          jobTitle: valuesCopy.jobTitle,
          type: valuesCopy?.contractType?.key,
          startDate: formatDate(valuesCopy.startDate),
          endDate: formatDate(valuesCopy.endDate),
          weeklyWorkingHours: valuesCopy.weeklyWorkingHours,
          grossSalary: valuesCopy.grossSalary,
          dangerousMachineryUse: valuesCopy.dangerousMachineryUse,
          publicSector: valuesCopy.publicSector,
          missions: [valuesCopy.mission1, valuesCopy.mission2, valuesCopy.mission3],
          executionAddress: {},
        },
      };

      if (checkboxOtherPlaceOfExecution) {
        dataToSend.contract.executionAddress = {
          street: valuesCopy.adress,
          complement: valuesCopy.additionalAddress,
          zipCode: valuesCopy.zipCode,
          city: valuesCopy.city,
        };
      }

      props.submitSection(dataToSend, 7);
    } else handleMountPingStatus();
  };

  const weeklyWorkingHoursValue = recruitmentPropsGeneral?.company?.contract?.weeklyWorkingHours.replace(/,/, ".");

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      apprenticeshipType: recruitmentPropsGeneral?.contract?.apprenticeshipType
        ? recruitmentPropsGeneral?.contract?.apprenticeshipType
        : { ...objAucun },
      jobTitle: recruitmentPropsGeneral?.contract?.jobTitle ? recruitmentPropsGeneral?.contract?.jobTitle : "",
      contractType: recruitmentPropsGeneral?.contract?.type ? recruitmentPropsGeneral?.contract?.type : { ...objAucun },
      startDate: recruitmentPropsGeneral?.contract?.startDate
        ? new Date(recruitmentPropsGeneral?.contract?.startDate)
        : null,
      endDate: recruitmentPropsGeneral?.contract?.endDate ? new Date(recruitmentPropsGeneral?.contract?.endDate) : null,
      weeklyWorkingHours: recruitmentPropsGeneral?.contract?.weeklyWorkingHours
        ? recruitmentPropsGeneral?.contract?.weeklyWorkingHours
        : null,

      grossSalary: recruitmentPropsGeneral?.contract?.grossSalary ? recruitmentPropsGeneral?.contract?.grossSalary : "",
      dangerousMachineryUse: recruitmentPropsGeneral?.contract?.dangerousMachineryUse
        ? recruitmentPropsGeneral?.contract?.dangerousMachineryUse
        : false,
      publicSector: recruitmentPropsGeneral?.contract?.publicSector
        ? recruitmentPropsGeneral?.contract?.publicSector
        : false,
      mission1: recruitmentPropsGeneral?.contract?.missions[0] ? recruitmentPropsGeneral?.contract?.missions[0] : "",
      mission2: recruitmentPropsGeneral?.contract?.missions[1] ? recruitmentPropsGeneral?.contract?.missions[1] : "",
      mission3: recruitmentPropsGeneral?.contract?.missions[2] ? recruitmentPropsGeneral?.contract?.missions[2] : "",
      adress: recruitmentPropsGeneral?.contract?.executionAddress?.street
        ? recruitmentPropsGeneral?.contract?.executionAddress?.street
        : "",
      additionalAddress: recruitmentPropsGeneral?.contract?.executionAddress?.complement
        ? recruitmentPropsGeneral?.contract?.executionAddress?.complement
        : "",
      zipCode: recruitmentPropsGeneral?.contract?.executionAddress?.zipCode
        ? recruitmentPropsGeneral?.contract?.executionAddress?.zipCode
        : "",
      city: recruitmentPropsGeneral?.contract?.executionAddress?.city
        ? recruitmentPropsGeneral?.contract?.executionAddress?.city
        : "",
    },
    validationSchema: Form7Schema,
    onSubmit,
  });

  const validApprenticeshipType = touched.apprenticeshipType && errors.apprenticeshipType;
  const validJobTitle = touched.jobTitle && errors.jobTitle;
  const validType = touched.contractType && errors.contractType;
  const validStartDate = touched.startDate && errors.startDate;
  const validEndDate = touched.endDate && errors.endDate;
  const validWeeklyWorkingHours = touched.weeklyWorkingHours && errors.weeklyWorkingHours;
  const validGrossSalary = touched.grossSalary && errors.grossSalary;
  const validMission1 = touched.mission1 && errors.mission1;
  const validMission2 = touched.mission2 && errors.mission2;
  const validMission3 = touched.mission3 && errors.mission3;

  const isTrueDangerousMachineryUse = recruitmentPropsGeneral?.contract?.dangerousMachineryUse === true;

  const isCheckedDsTrueDangerousMachineryUse = isTrueDangerousMachineryUse
    ? { defaultChecked: true }
    : { defaultChecked: false };

  const isTruepublicSector = recruitmentPropsGeneral?.contract?.publicSector === true;

  const isCheckedDsTruePublicSector = isTruepublicSector ? { defaultChecked: true } : { defaultChecked: false };
  console.log("values", values);
  console.log("FORM7 ME", errors);

  useEffect(() => {
    if (formikErrorJustMount) onSubmit();
  }, [errors]);

  useEffect(() => {
    console.log("Clicked");
    if (formikErrorJustMount) {
      handleSubmit();
    }
    console.log("Formik errors", errors);
    console.log("isOpen", props.formOpen);
  }, [errors]);

  if (props.formOpen) {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <div className={styles.formGroup}>
              <label htmlFor="apprenticeshipType">{t("app.suivezVosContratsPartner.apprenticeshipType")}</label>
              <DropdownList
                id="apprenticeshipType"
                dataKey="key"
                textField="label"
                value={values.apprenticeshipType}
                data={[{ ...objAucun }, ...props?.referencialData?.APPRENTICESHIP_TYPE]}
                className={
                  !formikErrorJustMount && clicked ? `${validApprenticeshipType && `${styles.errorInput}`}` : null
                }
                onChange={(newValue) => {
                  setFieldValue(apprenticeshipType?.key, newValue);
                  setFieldValue("grossSalary", "");
                  if (newValue?.key === "Contrat de professionnalisation") setGrossSalaryDisable(false);
                  else setGrossSalaryDisable(true);
                }}
              />
              {validApprenticeshipType && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.apprenticeshipType}</span>
              )}
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="jobTitle">{t("app.suivezVosContratsPartner.jobTitle")}</label>
              <input
                id="jobTitle"
                name="jobTitle"
                type="text"
                value={values.jobTitle}
                onChange={(e) => setFieldValue("jobTitle", e.target.value)}
                className={!formikErrorJustMount && clicked ? `${validJobTitle && `${styles.errorInput}`}` : null}
              />
              {validJobTitle && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.jobTitle}</span>
              )}
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="contractType">{t("app.suivezVosContratsPartner.contractType")}</label>
              <DropdownList
                id="contractType"
                dataKey="key"
                textField="label"
                value={values.contractType}
                data={[{ ...objAucun }, ...props?.referencialData?.APPRENTICESHIP_CONTRACT_NATURE]}
                className={!formikErrorJustMount && clicked ? `${validType && `${styles.errorInput}`}` : null}
                onChange={(newValue) => setFieldValue(contractType?.key, newValue)}
              />
              {validType && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.contractType}</span>
              )}
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="startDate">{t("app.suivezVosContratsPartner.startDate")}</label>
              {/* <DatePicker
								id="startDate"
								value={values.startDate}
								onChange={(value) => setFieldValue("startDate", value)}
								className={
									!formikErrorJustMount
										? `${
												validStartDate
													? `${styles.errorInput}`
													: `${styles.dateInput}`
										  }`
										: null
								}
							/> */}
              <DatePickerCustom
                error={errors.startDate}
                touched={touched.startDate}
                id="startDate"
                formikErrorJustMount={formikErrorJustMount}
                value={values.startDate}
                onChange={(value) => setFieldValue("startDate", value)}
              />
              {validStartDate && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.startDate}</span>
              )}
              <span className={styles.nb}>{t("app.suivezVosContratsPartner.startDateNB")}</span>
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="endDate">{t("app.suivezVosContratsPartner.endDate")}</label>
              {/* <DatePicker
								id="endDate"
								value={values.endDate}
								onChange={(value) => setFieldValue("endDate", value)}
								className={
									!formikErrorJustMount
										? `${
												validEndDate
													? `${styles.errorInput}`
													: `${styles.dateInput}`
										  }`
										: null
								}
							/> */}

              <DatePickerCustom
                error={errors.endDate}
                touched={touched.endDate}
                id="endDate"
                value={values.endDate}
                onChange={(value) => setFieldValue("endDate", value)}
                formikErrorJustMount={formikErrorJustMount}
              />
              {validEndDate && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.endDate}</span>
              )}
              <span className={styles.nb}>{t("app.suivezVosContratsPartner.endDateNB")}</span>
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="weeklyWorkingHours">{t("app.suivezVosContratsPartner.weeklyWorkingHours")}</label>
              <input
                id="weeklyWorkingHours"
                name="weeklyWorkingHours"
                type="number"
                min="0.00"
                step="0.01"
                value={values.weeklyWorkingHours}
                onChange={(e) => setFieldValue("weeklyWorkingHours", e.target.value)}
                className={
                  !formikErrorJustMount && clicked ? `${validWeeklyWorkingHours && `${styles.errorInput}`}` : null
                }
              />
              {validWeeklyWorkingHours && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.weeklyWorkingHours}</span>
              )}
            </div>
            <div className={`${styles.formGroup} input-like-rw`}>
              <div className={styles.infoBulle}>
                <svg
                  className="slds-button__icon"
                  focusable="false"
                  data-key="info"
                  aria-hidden="true"
                  viewBox="0 0 52 52"
                  part="icon"
                >
                  <g>
                    <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                  </g>
                </svg>
                <div className={styles.infoBulleInfo}>
                  le salaire brut/mois est obligatoire uniquement si le contrat est un contrat de professionnalisation
                </div>
              </div>
              <label htmlFor="grossSalary">{t("app.suivezVosContratsPartner.grossSalary")}</label>
              <input
                id="grossSalary"
                name="grossSalary"
                type="number"
                disabled={grossSalaryDisable}
                value={values.grossSalary}
                onChange={(e) => setFieldValue("grossSalary", e.target.value)}
                className={!formikErrorJustMount && clicked ? `${validGrossSalary && `${styles.errorInput}`}` : null}
              />
              {validGrossSalary && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.grossSalary}</span>
              )}
            </div>
          </div>

          <div className={styles.fullContainer}>
            <div className={styles.formGroup}>
              <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.dangerousMachineryUse")}</h6>
              <input
                type="checkbox"
                name="dangerousMachineryUse"
                id="dangerousMachineryUse"
                value={values.dangerousMachineryUse}
                {...isCheckedDsTrueDangerousMachineryUse}
                onChange={() => {
                  setFieldValue("dangerousMachineryUse", !values.dangerousMachineryUse);
                }}
              />
              <label htmlFor="dangerousMachineryUse" className={styles.labelCheckBox}>
                {t("app.suivezVosContratsPartner.dangerousMachineryUseLabel")}
              </label>
            </div>
            <div className={styles.formGroup}>
              <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.publicSector")}</h6>
              <input
                type="checkbox"
                name="publicSector"
                id="publicSector"
                value={values.publicSector}
                {...isCheckedDsTruePublicSector}
                onChange={() => {
                  setFieldValue("publicSector", !values.publicSector);
                }}
              />
              <label htmlFor="publicSector" className={styles.labelCheckBox}>
                {t("app.suivezVosContratsPartner.publicSectorLabel")}
              </label>
            </div>
            <div className={styles.explication}>
              <p>{t("app.suivezVosContratsPartner.explication1")}</p>
              <p>{t("app.suivezVosContratsPartner.explication2")}</p>
            </div>
            <div className={styles.box}>
              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="mission1">{t("app.suivezVosContratsPartner.mission")} 1</label>
                <textarea
                  id="mission1"
                  value={values.mission1}
                  onChange={(e) => setFieldValue("mission1", e.target.value)}
                  className={!formikErrorJustMount && clicked ? `${validMission1 && `${styles.errorInput}`}` : null}
                />
                {validMission1 && !formikErrorJustMount && clicked && clicked && (
                  <span className={styles.error}>{errors.mission1}</span>
                )}
              </div>
              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="mission2">{t("app.suivezVosContratsPartner.mission")} 2</label>
                <textarea
                  id="mission2"
                  value={values.mission2}
                  onChange={(e) => setFieldValue("mission2", e.target.value)}
                  className={!formikErrorJustMount && clicked ? `${validMission2 && `${styles.errorInput}`}` : null}
                />
                {validMission2 && !formikErrorJustMount && clicked && (
                  <span className={styles.error}>{errors.mission2}</span>
                )}
              </div>
              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="mission3">{t("app.suivezVosContratsPartner.mission")} 3</label>
                <textarea
                  id="mission3"
                  value={values.mission3}
                  onChange={(e) => setFieldValue("mission3", e.target.value)}
                  className={!formikErrorJustMount && clicked ? `${validMission3 && `${styles.errorInput}`}` : null}
                />
                {validMission3 && !formikErrorJustMount && clicked && (
                  <span className={styles.error}>{errors.mission3}</span>
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.OtherPlaceOfExecutionTitle")}</h6>
              <input
                type="checkbox"
                name="otherPlaceOfExecution"
                id="otherPlaceOfExecution"
                checked={checkboxOtherPlaceOfExecution}
                onChange={(e) => setCheckboxOtherPlaceOfExecution(e.target.checked)}
              />
              <label htmlFor="otherPlaceOfExecution" className={styles.labelCheckBox}>
                {t("app.suivezVosContratsPartner.OtherPlaceOfExecutionLabel")}
              </label>
              <div className={styles.explication}>
                <p>{t("app.suivezVosContratsPartner.OtherPlaceOfExecutionHelpText")}</p>
              </div>
            </div>

            {checkboxOtherPlaceOfExecution && (
              <div className={styles.wrapper}>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="adress">{t("app.suivezVosContratsPartner.Adresse")}</label>
                  <input
                    id="adress"
                    name="adress"
                    type="text"
                    value={values.adress}
                    onChange={(e) => setFieldValue("adress", e.target.value)}
                    className={!formikErrorJustMount && clicked && errors.adress ? styles.errorInput : null}
                  />
                  {!formikErrorJustMount && clicked && errors.adress && (
                    <span className={styles.error}>{errors.adress}</span>
                  )}
                </div>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="additionalAddress">{t("app.suivezVosContratsPartner.ComplementDadresse")}</label>
                  <input
                    id="additionalAddress"
                    name="additionalAddress"
                    type="text"
                    value={values.additionalAddress}
                    onChange={(e) => setFieldValue("additionalAddress", e.target.value)}
                    className={!formikErrorJustMount && clicked && errors.additionalAddress ? styles.errorInput : null}
                  />
                  {!formikErrorJustMount && clicked && errors.additionalAddress && (
                    <span className={styles.error}>{errors.additionalAddress}</span>
                  )}
                </div>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="zipCode">{t("app.suivezVosContratsPartner.CodePostal2")}</label>
                  <input
                    id="zipCode"
                    name="zipCode"
                    type="number"
                    min="0"
                    value={values.zipCode}
                    onChange={(e) => setFieldValue("zipCode", e.target.value)}
                    className={!formikErrorJustMount && clicked && errors.zipCode ? styles.errorInput : null}
                  />
                  {!formikErrorJustMount && clicked && errors.zipCode && (
                    <span className={styles.error}>{errors.zipCode}</span>
                  )}
                </div>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="city">{t("app.suivezVosContratsPartner.Ville")}</label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={values.city}
                    onChange={(e) => setFieldValue("city", e.target.value)}
                    className={!formikErrorJustMount && clicked && errors.city ? styles.errorInput : null}
                  />
                  {!formikErrorJustMount && clicked && errors.city && (
                    <span className={styles.error}>{errors.city}</span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn
              onClick={() => {
                handlePingStatus();
                seIsClicked(true);
                if (errors.startDate) {
                  toastError(<ToastMessageStructure secondMessage={t("app.toastMessages.ErrorDateSupASeptJours")} />);
                }
                if (
                  errors.startDate === "startDate is a required field" ||
                  errors.endDate === "endDate is a required field"
                ) {
                  toastError(
                    <ToastMessageStructure
                      secondMessage={t("app.toastMessages.ErrorDateDebutDateFinContratUndefined")}
                    />,
                  );
                }
              }}
              //disabled={generalEnable}
              text={t("app.visualisationOffre.ValidezLesModifications")}
            />
          </div>
        </form>
      </>
    );
  }
};

export default Form7;
