import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets/cjs";
import { createBooleanReferential, createObjAucun } from "utils/localReferentials";
import {
  getcreateYourCVPending,
  getcreateYourCVErrorMessage,
  postStep1Thunk,
  patchStepThunk,
  getcreateYourCVId,
  handleStepsData,
  handlePreviousStep,
  getcreateYourCVYourProfileData,
} from "../../../../../app/slices/createYourCV/createYourCV.slice";
import { getReferentialsData } from "../../../../../app/slices/referentials/referentials.slice";
import Header from "../../../../../components/Header/Header";
import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";
import PreviousFormBtn from "../../../../../components/PreviousFormBtn/PreviousFormBtn";
import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "../../../../../utils/tools";

import ResumeCardCv from "../../components/ResumeCardCv/ResumeCardCv";

import styles from "../../CreateYourCV.module.scss";

/* eslint-disable */
const YourProfile = (props) => {
  const referencialData = useSelector(getReferentialsData);

  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourCVPending);
  const errorMessage = useSelector(getcreateYourCVErrorMessage);
  const id = useSelector(getcreateYourCVId);
  const yourProfileData = useSelector(getcreateYourCVYourProfileData);
  const BooleanReferential = createBooleanReferential();
  const empty = createObjAucun();
  // Yup validation schema
  const yourProfileSchema = Yup.object().shape({
    educationLevel: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== empty.key),
    mobility: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== empty.key),
    carLicence: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== empty.key),
    motorcycleLicence: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    hasVehicle: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    introduceYourself: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    mobilityComment: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
  });

  // onSubmit form function
  const onSubmit = (values) => {
    dispatch(
      handleStepsData({
        formName: "yourProfile",
        formData: {
          educationLevel: values.educationLevel,
          introduceYourself: values.introduceYourself,
          mobility: values.mobility,
          mobilityComment: values.mobilityComment,
          carLicence: values.carLicence,
          motorcycleLicence: values.motorcycleLicence,
          hasVehicle: values.hasVehicle,
        },
      }),
    );
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);
    const dataToSend = {
      educationLevel: valuesCopy.educationLevel.key,
      introduceYourself: valuesCopy.introduceYourself,
      mobility: valuesCopy.mobility.key,
      mobilityComment: valuesCopy.mobilityComment,
      carLicence: valuesCopy.carLicence.key,
      motorcycleLicence: valuesCopy.motorcycleLicence.key,
      hasVehicle: valuesCopy.hasVehicle.key,
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      (async () => {
        try {
          if (!id) {
            await dispatch(
              postStep1Thunk({
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourProfile post success !");
          } else {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourProfile put success !");
          }
        } catch (error) {
          console.log("YourProfile post fail !", error.message);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: yourProfileData
      ? yourProfileData
      : {
          educationLevel: { ...empty },
          introduceYourself: "",
          mobility: { ...empty },
          carLicence: { ...empty },
          mobilityComment: "",
          motorcycleLicence: { ...empty },
          hasVehicle: { ...empty },
          whichSchool: { ...empty },
        },
    validationSchema: yourProfileSchema,
    onSubmit,
  });

  return (
    <>
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && (
        <>
          <Header
            h1={t("app.depotCv.DeposerCV")}
            textElement={<p>{t("app.depotCv.Etape2")}</p>}
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.title_container}>
              <h2>{t("app.depotCv.VotreProfil")}</h2>
              <div>(Etape 2/5)</div>
            </div>
            <div className={styles.form_container}>
              <form className={styles.your_need_form} onSubmit={handleSubmit}>
                <span style={{ color: "red" }}>{errorMessage}</span>
                <div className={styles.inputs_container}>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="educationLevel">{t("app.depotCv.NiveauEtude")}</label>
                    <DropdownList
                      style={
                        errors.educationLevel &&
                        touched.educationLevel && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="educationLevel"
                      dataKey="key"
                      textField="label"
                      data={[empty, ...referencialData?.STUDENT_EDUCATION_LEVEL]}
                      value={values.educationLevel}
                      onChange={(newValue) => setFieldValue("educationLevel", newValue)}
                    />
                    <InputErrorMessage message={errors.educationLevel} touched={touched.educationLevel} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="introduceYourself">{t("app.depotCv.PresentezVous")}</label>
                    <textarea
                      style={
                        errors.introduceYourself &&
                        touched.introduceYourself && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="introduceYourself"
                      value={values.introduceYourself}
                      maxLength={255}
                      onChange={(e) => setFieldValue("introduceYourself", e.target.value)}
                    />
                    <InputErrorMessage message={errors.introduceYourself} touched={touched.introduceYourself} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="mobility">{t("app.depotCv.Mobilite")}</label>
                    <DropdownList
                      style={
                        errors.mobility &&
                        touched.mobility && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="mobility"
                      dataKey="key"
                      textField="label"
                      data={referencialData ? [empty, ...referencialData?.MOBILITY_LEVEL] : [empty]}
                      value={values.mobility}
                      onChange={(newValue) => setFieldValue("mobility", newValue)}
                    />
                    <InputErrorMessage message={errors.mobility} touched={touched.mobility} />
                  </div>

                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="mobilityComment">{t("app.depotCv.PrecisezMobilite")}</label>
                    <textarea
                      style={
                        errors.mobilityComment &&
                        touched.mobilityComment && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="mobilityComment"
                      value={values.mobilityComment}
                      maxLength={255}
                      onChange={(e) => setFieldValue("mobilityComment", e.target.value)}
                    />
                    <InputErrorMessage message={errors.mobilityComment} touched={touched.mobilityComment} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="carLicence">{t("app.depotCv.PermisAuto")}</label>
                    <DropdownList
                      style={
                        errors.carLicence &&
                        touched.carLicence && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="carLicence"
                      dataKey="key"
                      textField="label"
                      data={BooleanReferential}
                      value={values.carLicence}
                      onChange={(newValue) => setFieldValue("carLicence", newValue)}
                    />
                    <InputErrorMessage message={errors.carLicence} touched={touched.carLicence} />
                    {/* {errors.carLicence  ? (
											<div>{errors.carLicence}</div>
										) : null} */}
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="motorcycleLicence">{t("app.depotCv.PermisMoto")}</label>
                    <DropdownList
                      style={
                        errors.motorcycleLicence &&
                        touched.motorcycleLicence && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="motorcycleLicence"
                      dataKey="key"
                      textField="label"
                      data={BooleanReferential}
                      value={values.motorcycleLicence}
                      onChange={(newValue) => setFieldValue("motorcycleLicence", newValue)}
                    />
                    <InputErrorMessage message={errors.motorcycleLicence} touched={touched.motorcycleLicence} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="hasVehicle">{t("app.depotCv.Vehicule")}</label>
                    <DropdownList
                      style={
                        errors.hasVehicle &&
                        touched.hasVehicle && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="hasVehicle"
                      dataKey="key"
                      textField="label"
                      data={BooleanReferential}
                      value={values.hasVehicle}
                      onChange={(newValue) => setFieldValue("hasVehicle", newValue)}
                    />
                    <InputErrorMessage message={errors.hasVehicle} touched={touched.hasVehicle} />
                  </div>
                </div>
                <SubmitFormBtn text={t("app.depotCv.ValiderEtape")} />
                <PreviousFormBtn
                  text={t("app.depotCv.EtapePrecedente")}
                  handlePreviousStep={() => dispatch(handlePreviousStep())}
                />
              </form>
              <ResumeCardCv />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default YourProfile;
