import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { DropdownList } from "react-widgets/cjs";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";

import {
  getEmployeesThunk,
  getEmployeeDetails,
  getCompanyDetailsIsPending,
  getCompanyEmployees,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";

import { getMeData } from "app/slices/userConnexion/userConnexion.slice";
import { createBooleanReferential, createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form3.module.scss";

/* eslint-disable */
const Form3 = (props) => {
  const dispatch = useDispatch();
  const FORM = "FORM_3";

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const employeeDetails = useSelector(getEmployeeDetails);
  const pendingEmployeeDetails = useSelector(getCompanyDetailsIsPending);
  const companyEmployees = useSelector(getCompanyEmployees);
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  const myInfo = useSelector(getMeData);

  const [formStep, setFormStep] = useState("");

  const [generalDisable, setGeneralDisable] = useState(false);
  const [mailDisable, setMailDisable] = useState(false);
  const [mailWasFound, setMailWasFound] = useState(false);
  const [dataWasChange, setDataWasChange] = useState(false);
  const [takeInfoDisable, setTakeInfoDisable] = useState(false);

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);

  const [fromOtherCompany, setFromOtherCompany] = useState(false);

  const [fromTakeInfo, setFromTakeInfo] = useState(false);
  const [justMounted, setJustMounted] = useState(false);

  const timedOutRef = useRef(null);

  const [enableCivilityIfEmpty, setEnableCivilityIfEmpty] = useState(false);
  const [enableLastnameIfEmpty, setEnableLastnameIfEmpty] = useState(false);
  const [enableFirstnameIfEmpty, setEnableFirstnameIfEmpty] = useState(false);
  const [enableFixPhoneIfEmpty, setEnableFixPhoneIfEmpty] = useState(false);
  const [enablePhoneIfEmpty, setEnablePhoneIfEmpty] = useState(false);
  const [enableFunctionIfEmpty, setEnableFunctionIfEmpty] = useState(false);

  const handleResetStatesIfEmptyValue = () => {
    setEnableCivilityIfEmpty(false);
    setEnableLastnameIfEmpty(false);
    setEnableFirstnameIfEmpty(false);
    setEnableFixPhoneIfEmpty(false);
    setEnablePhoneIfEmpty(false);
    setEnableFunctionIfEmpty(false);
  };

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    if (values && formikErrorJustMount)
      handleEnableOnMount(values.contactTaxe, recruitmentPropsGeneral?.company?.contacts?.taxes?.id);
  }, []);

  useEffect(() => {
    console.log("MAIL FORM 4");
    if (employeeDetails) {
      if (employeeDetails.length > 0 && formStep === FORM) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetails]);

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus = () => {
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  // Yup validation schema
  const Form3Schema = Yup.object().shape({
    contactTaxe: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    takeInfoFrom: Yup.object().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),

    email: Yup.string().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) => schema.email(t("app.errorMessages.EmailInvalid")).required(t("app.errorMessages.RemplirChamp")),
    }),
    // fixPhone: Yup.string().when("contactTaxe", {
    // 	is: (contactTaxe) => contactTaxe.key === "Oui",
    // 	then: (schema) =>
    // 		schema
    // 			.min(2)
    // 			.matches(phoneRegExp)
    // 			.required(t("app.errorMessages.RemplirChamp")),
    // }),
    fixPhone: Yup.string().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName: Yup.string().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) =>
        schema
          .min(2)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),

    civility: Yup.object().when(["contactTaxe", "mandatoryField"], {
      is: (contactTaxe, mandatoryField) => contactTaxe?.key === "Oui" && mandatoryField,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });

  const onSubmit = (values, actions) => {
    if (!formikErrorJustMount) {
      console.log("CONTRACT VALUE TO SEND 1");

      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      let dataToSend = {};

      if (valuesCopy?.contactTaxe?.key === true) {
        if (valuesCopy?.takeInfoFrom?.key === "Aucun") {
          dataToSend = {
            status: "En cours",
            company: {
              contacts: {
                taxes: {
                  copyFrom: valuesCopy?.takeInfoFrom?.key,
                  email: valuesCopy.email,
                  civility: valuesCopy?.civility?.key,
                  name: valuesCopy.lastName,
                  firstname: valuesCopy.firstName,
                  phones: {
                    office: valuesCopy.fixPhone,
                    cellular: valuesCopy.phone,
                  },
                  function: valuesCopy.function,
                },
              },
            },
          };
        } else if (valuesCopy?.takeInfoFrom?.key !== "-Aucun-" && valuesCopy?.takeInfoFrom?.key !== "Aucun") {
          dataToSend = {
            status: "En cours",
            company: {
              contacts: {
                taxes: {
                  copyFrom: valuesCopy?.takeInfoFrom?.key,
                },
              },
            },
          };
        }
      } else {
        dataToSend = {
          status: "En cours",
          company: {
            contacts: {
              taxes: {
                id: null,
              },
            },
          },
        };
      }
      props.submitSection(dataToSend, 3);
    } else handleMountPingStatus();
  };

  const initialContactTaxesYesNo = (value) => {
    if (value) return { key: "Oui", label: "Oui" };
    else return { key: "Non", label: "Non" };
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      contactTaxe: initialContactTaxesYesNo(recruitmentPropsGeneral?.company?.contacts?.taxes),
      takeInfoFrom: recruitmentPropsGeneral?.company?.contacts?.taxes?.copyFrom
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.copyFrom
        : { ...objAucun },
      email: recruitmentPropsGeneral?.company?.contacts?.taxes?.email
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.email
        : "",
      civility: recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
        : { ...objAucun },
      lastName: recruitmentPropsGeneral?.company?.contacts?.taxes?.name
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.name
        : "",
      firstName: recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
        : "",
      phone: recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
        : "",
      fixPhone: recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
        : "",
      function: recruitmentPropsGeneral?.company?.contacts?.taxes?.function
        ? recruitmentPropsGeneral?.company?.contacts?.taxes?.function
        : "",
      mandatoryField: false,
    },
    validationSchema: Form3Schema,
    onSubmit,
  });

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
  }, [recruitmentPropsGeneral]);

  useEffect(() => {
    if (formikErrorJustMount) onSubmit();
  }, [errors]);

  const setInfoFrom = (value, update) => {
    console.log("SET INFO", value);
    setMailWasFound(false);
    if (values?.contactTaxe?.key === "Oui") {
      switch (value?.key) {
        case "Contact signataire":
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.email
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.name
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.signatory?.function
              ? recruitmentPropsGeneral?.company?.contacts?.signatory?.function
              : "",
          );
          setGeneralDisable(true);
          setMailDisable(true);
          setFromTakeInfo(true);
          setFieldValue("mandatoryField", false);
          break;
        case "Contact Facturation":
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.billing?.email
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.billing?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.billing?.name
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.billing?.function
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
              : "",
          );
          setGeneralDisable(true);
          setMailDisable(true);
          setFromTakeInfo(true);
          setFieldValue("mandatoryField", false);
          break;
        case "Contact établissement du contrat":
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
              ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
              : "",
          );
          setGeneralDisable(true);
          setMailDisable(true);
          setFromTakeInfo(true);
          setFieldValue("mandatoryField", false);
          break;
        case "Contact administratif durant la formation":
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
              : "",
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.name
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.name
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.email
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.email
              : "",
          );
          setFieldValue(
            "telephone",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
              : "",
          );
          setFieldValue(
            "portable",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fonction",
            recruitmentPropsGeneral?.company?.contacts?.administrative?.function
              ? recruitmentPropsGeneral?.company?.contacts?.administrative?.function
              : "",
          );
          setGeneralDisable(true);
          setMailDisable(true);
          setFromTakeInfo(true);
          setFieldValue("mandatoryField", false);
          break;
        case "Contact tuteur / maître d’apprentissage":
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.email
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.name
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.tutor?.function
              ? recruitmentPropsGeneral?.company?.contacts?.tutor?.function
              : "",
          );
          setGeneralDisable(true);
          setMailDisable(true);
          setFromTakeInfo(true);
          setFieldValue("mandatoryField", false);
          break;
        case "Aucun":
          if (!update) {
            setFieldValue("email", "");
            setFieldValue("civility", { ...objAucun });
            setFieldValue("lastName", "");
            setFieldValue("firstName", "");
            setFieldValue("phone", "");
            setFieldValue("fixPhone", "");
            setFieldValue("function", "");
            setGeneralDisable(false);
            setMailDisable(false);
            setFromTakeInfo(false);
            setFieldValue("mandatoryField", true);
          } else {
            setFieldValue(
              "email",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.email
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.email
                : "",
            );
            setFieldValue(
              "civility",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
                : { ...objAucun },
            );
            setFieldValue(
              "lastName",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.name
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.name
                : "",
            );
            setFieldValue(
              "firstName",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
                : "",
            );
            setFieldValue(
              "phone",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
                : "",
            );
            setFieldValue(
              "fixPhone",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
                : "",
            );
            setFieldValue(
              "function",
              recruitmentPropsGeneral?.company?.contacts?.taxes?.function
                ? recruitmentPropsGeneral?.company?.contacts?.taxes?.function
                : "",
            );
            let tmpGenDisable = generalDisable;
            let tmpFromTake = fromTakeInfo;
            let tmpMandatory = values.mandatoryField;
            setGeneralDisable(tmpGenDisable);
            setMailDisable(false);
            setFromTakeInfo(tmpFromTake);
            setFieldValue("mandatoryField", tmpMandatory);
          }
          setGeneralDisable(true);
          break;
        case "-Aucun-":
          setFieldValue("email", "");
          setFieldValue("civility", { ...objAucun });
          setFieldValue("lastName", "");
          setFieldValue("firstName", "");
          setFieldValue("phone", "");
          setFieldValue("fixPhone", "");
          setFieldValue("function", "");
          setGeneralDisable(false);
          setMailDisable(false);
          setFromTakeInfo(false);
          setFieldValue("mandatoryField", true);
          setGeneralDisable(true);
          break;
        default:
          break;
      }
    } else {
      setFieldValue("email", "");
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("phone", "");
      setFieldValue("fixPhone", "");
      setFieldValue("function", "");
      setGeneralDisable(true);
      setMailDisable(true);
      setFromTakeInfo(true);
      setFieldValue("mandatoryField", false);
    }
  };

  const displayUserData = () => {
    if (formStep === FORM) {
      setFieldValue("lastName", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstName", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civility", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phone", employeeDetails[0]?.phones?.cellular ? employeeDetails[0]?.phones?.cellular : "");
      setFieldValue("function", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");

      if (employeeDetails[0]?.company?.id === myInfo?.companyId) {
        setGeneralDisable(false);
        setFromOtherCompany(false);
        setFieldValue("mandatoryField", true);
      } else {
        setGeneralDisable(true);
        setFromOtherCompany(true);
        setFieldValue("mandatoryField", false);
      }

      setMailWasFound(true);
      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    //TODO A tester
    if (formStep === FORM) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");

      setMailWasFound(false);
      setGeneralDisable(false);
      setFieldValue("mandatoryField", true);
      setFormStep("");
    }
  };

  useEffect(() => {
    if (values.takeInfoFrom.key === "Aucun") {
      if (values.email.length >= 1) setGeneralDisable(false);
      else setGeneralDisable(true);
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken: props.axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfEmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfEmptyValue();
              console.log("get fail Employees !", error.message);
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("fixPhone", "");
        setFieldValue("phone", "");
        setFieldValue("function", "");
        setFieldValue("mandatoryField", true);
        return;
      }
    }
  }, [errors.email, values.email]);

  const handleEnableOnMount = (taxesContact, companyId) => {
    setJustMounted(true);
    if (taxesContact?.key === "Oui") {
      setTakeInfoDisable(false);
      setFieldValue("mandatoryField", true);
      if (values?.takeInfoFrom?.key === "Aucun") {
        setMailDisable(false);
        setFromTakeInfo(false);
        if (values.email.length >= 1) {
          if (companyId === myInfo?.companyId) {
            setGeneralDisable(false);
            setFieldValue("mandatoryField", true);
          } else {
            setGeneralDisable(true);
            setFieldValue("mandatoryField", false);
          }
        } else setGeneralDisable(true);
      } else if (values?.takeInfoFrom?.key !== "Aucun" && values?.takeInfoFrom?.key !== "-Aucun-") {
        setFromTakeInfo(true);
        setMailDisable(true);
        setGeneralDisable(true);
        setFieldValue("mandatoryField", false);
      }
    } else {
      setMailDisable(true);
      setGeneralDisable(true);
      setTakeInfoDisable(true);
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setFieldValue("civility", { ...objAucun });
      setFieldValue("takeInfoFrom", { ...objAucun });
      setFieldValue("mandatoryField", false);
    }
  };

  const handleContactTaxes = (value) => {
    setFieldValue("contactTaxe", value);

    setFieldValue("lastName", "");
    setFieldValue("firstName", "");
    setFieldValue("fixPhone", "");
    setFieldValue("phone", "");
    setFieldValue("function", "");
    setFieldValue("civility", { ...objAucun });
    setFieldValue("takeInfoFrom", { ...objAucun });
    setFieldValue("email", "");

    if (value?.key === "Oui") {
      setMailDisable(false);
      setGeneralDisable(false);
      setTakeInfoDisable(false);
      setFieldValue("mandatoryField", true);
      if (values.email.length >= 1) setGeneralDisable(false);
      else setGeneralDisable(true);
    } else {
      setMailDisable(true);
      setGeneralDisable(true);
      setTakeInfoDisable(true);
      setFieldValue("mandatoryField", false);
    }
  };

  const validPhone = touched.phone && errors.phone;
  const validFixPhone = errors.fixPhone && touched.fixPhone;

  useEffect(() => {
    if (values?.contactTaxe?.key === "Oui" && values?.takeInfoFrom?.key !== "-Aucun-" && values.email !== "") {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityIfEmpty(true);
      }
      if (values?.lastName === "") {
        setEnableLastnameIfEmpty(true);
      }
      if (values?.firstName === "") {
        setEnableFirstnameIfEmpty(true);
      }
      if (values?.fixPhone === "") {
        setEnableFixPhoneIfEmpty(true);
      }
      if (!values?.phone) {
        setEnablePhoneIfEmpty(true);
      }
      if (values?.function === "") {
        setEnableFunctionIfEmpty(true);
      }
    } else {
      handleResetStatesIfEmptyValue();
    }
  }, [values]);

  // console.log("ERRORS FORM 3", generalDisable, mailDisable, takeInfoDisable);
  console.log("ERRORS FORM 3", errors);

  if (props.formOpen) {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <div className={styles.formGroup}>
              <label htmlFor="contactTaxe">{t("app.suivezVosContratsPartner.AjouterContactTaxe")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.takeInfoFrom &&
                      touched.takeInfoFrom && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="contactTaxe"
                dataKey="key"
                textField="label"
                data={YesNo}
                value={values.contactTaxe}
                onChange={(value) => {
                  handleContactTaxes(value);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.contactTaxe} touched={touched.contactTaxe} />
              )}
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="takeInfoFrom">{t("app.suivezVosContratsPartner.ReprendreInfo")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.takeInfoFrom &&
                      touched.takeInfoFrom && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="takeInfoFrom"
                dataKey="key"
                textField="label"
                disabled={takeInfoDisable}
                data={[{ ...objAucun }, ...props?.referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_TAXES_CONTACT]}
                value={values.takeInfoFrom}
                onChange={(e) => {
                  setInfoFrom(e, false);
                  setFieldValue("takeInfoFrom", e);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.takeInfoFrom} touched={touched.takeInfoFrom} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="email">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.email &&
                      touched.email && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="email"
                type="email"
                disabled={mailDisable}
                value={values.email}
                onChange={(e) => {
                  setFormStep(FORM);
                  setFieldValue("email", e.target.value);
                }}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.email} touched={touched.email} />}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="civility">{t("app.suivezVosContratsPartner.Civilite")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.civility &&
                      touched.civility && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="civility"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                disabled={generalDisable && !enableCivilityIfEmpty && true}
                value={values.civility}
                onChange={(newValue) => setFieldValue("civility", newValue)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.civility} touched={touched.civility} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="lastName">{t("app.suivezVosContratsPartner.Nom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.lastName &&
                      touched.lastName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="lastName"
                type="text"
                disabled={generalDisable && !enableLastnameIfEmpty && true}
                value={values.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.lastName} touched={touched.lastName} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="firstName">{t("app.suivezVosContratsPartner.Prenom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.firstName &&
                      touched.firstName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstName"
                type="text"
                disabled={generalDisable && !enableFirstnameIfEmpty && true}
                value={values.firstName}
                onChange={(e) => setFieldValue("firstName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.firstName} touched={touched.firstName} />}
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="fixPhone">{t("app.suivezVosContratsPartner.Telephone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.fixPhone &&
                      touched.fixPhone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="fixPhone"
                id="fixPhone"
                country={"FR"}
                disabled={generalDisable && !enableFixPhoneIfEmpty && true}
                value={values.fixPhone}
                onChange={(e) => {
                  setFieldValue("fixPhone", e);
                }}
                className={!formikErrorJustMount ? `${validFixPhone && `${styles.errorInput}`}` : null}
              />
              {validFixPhone && !formikErrorJustMount && <span className={styles.error}>{errors.fixPhone}</span>}
            </div>

            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="phone">{t("app.suivezVosContratsPartner.Portable")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.phone &&
                      touched.phone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="phone"
                id="phone"
                country={"FR"}
                disabled={generalDisable && !enablePhoneIfEmpty && true}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e);
                }}
                className={!formikErrorJustMount ? `${validPhone && `${styles.errorInput}`}` : null}
              />
              {/* <input
								style={
									!formikErrorJustMount
										? errors.phone &&
										  touched.phone && {
												border: "2px solid #EA001E",
										  }
										: null
								}
								id="phone"
								name="phone"
								type="text"
								disabled={generalDisable}
								value={values.phone}
								onChange={(e) => setFieldValue("phone", e.target.value)}
								className={
									!formikErrorJustMount
										? `${validPhone && `${styles.errorInput}`}`
										: null
								}
							/> */}
              {validPhone && !formikErrorJustMount && <span className={styles.error}>{errors.phone}</span>}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="function">{t("app.suivezVosContratsPartner.Fonction")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.function &&
                      touched.function && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="function"
                type="text"
                disabled={generalDisable && !enableFunctionIfEmpty && true}
                value={values.function}
                onChange={(e) => setFieldValue("function", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.function} touched={touched.function} />}
            </div>
          </div>
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn onClick={handlePingStatus} text={t("app.visualisationOffre.ValidezLesModifications")} />
          </div>
        </form>
      </div>
    );
  }
};

export default Form3;
