import { t } from "i18next";

import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  putThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  getLearningBookletsErrorMessage,
  getLearningBookletsListData,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { createObjAucun } from "utils/localReferentials";

import { DropdownList } from "react-widgets/cjs";

import Header from "components/Header/Header";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import BackButton from "../../components/BackButton/BackButton";

import bgConsulOffreImg from "../../../assets/bg-consul-offre.png";

import styles from "../../SelectedBooklet.module.scss";

const EndTermEvalFirstYear = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const listData = useSelector(getLearningBookletsListData);

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);
  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const referentials = useSelector(getReferentialsData);

  const objAucun = createObjAucun();

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("BOOKLET DATA", idData, listData);

  // Yup validation schema
  const formSchema = Yup.object().shape({
    insertion: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    teamMindSet: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    autonomie: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    initiative: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    normsAndRegulations: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    organisation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    selfControl: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    reporting: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    companyKnowledge: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    communicationQuality: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    knowledgeAquisition: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    personalOrganisation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    masteringSkills: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    respectDetails: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    processMastering: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    // commentSection: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  // onSubmit form function
  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Complété",
      insertion: values.insertion.key,
      teamSpirit: values.teamMindSet.key,
      autonomy: values.autonomie.key,
      initiative: values.initiative.key,
      standardAndRegulationRespect: values.normsAndRegulations.key,
      planificationOrganisation: values.organisation.key,
      autoControl: values.selfControl.key,
      reporting: values.reporting.key,
      companyUnderstanding: values.companyKnowledge.key,
      communicationSkills: values.communicationQuality.key,
      knowledgeAcquisition: values.knowledgeAquisition.key,
      personalOrganizationWork: values.personalOrganisation.key,
      toolsAndMethodsMastery: values.masteringSkills.key,
      deadlinesRespect: values.respectDetails.key,
      methodMastery: values.processMastering.key,
      comment: values.commentSection ? values.commentSection : null,
      signature: values.signature,
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/suivez-vos-alternants/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      insertion: idData?.insertion ? idData?.insertion : { ...objAucun },
      teamMindSet: idData?.teamSpirit ? idData?.teamSpirit : { ...objAucun },
      autonomie: idData?.autonomy ? idData?.autonomy : { ...objAucun },
      initiative: idData?.initiative ? idData?.initiative : { ...objAucun },
      normsAndRegulations: idData?.standardAndRegulationRespect
        ? idData?.standardAndRegulationRespect
        : { ...objAucun },
      organisation: idData?.planificationOrganisation ? idData?.planificationOrganisation : { ...objAucun },
      selfControl: idData?.autoControl ? idData?.autoControl : { ...objAucun },
      reporting: idData?.reporting ? idData?.reporting : { ...objAucun },
      companyKnowledge: idData?.companyUnderstanding ? idData?.companyUnderstanding : { ...objAucun },
      communicationQuality: idData?.communicationSkills ? idData?.communicationSkills : { ...objAucun },
      knowledgeAquisition: idData?.knowledgeAcquisition ? idData?.knowledgeAcquisition : { ...objAucun },
      personalOrganisation: idData?.personalOrganizationWork ? idData?.personalOrganizationWork : { ...objAucun },
      masteringSkills: idData?.toolsAndMethodsMastery ? idData?.toolsAndMethodsMastery : { ...objAucun },
      respectDetails: idData?.deadlinesRespect ? idData?.deadlinesRespect : { ...objAucun },
      processMastering: idData?.methodMastery ? idData?.methodMastery : { ...objAucun },
      commentSection: idData?.companyTutorComment ? idData?.companyTutorComment : "",
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  /* eslint-disable */
  return (
    <>
      {referentials && (
        <>
          <Header
            h1={t("app.formulairesLivretApprentissage.Suivez")}
            textElement={
              <div className={styles.headerContent}>
                <p>
                  {t("app.formulairesLivretApprentissage.headerPart1")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart2")}
                  <br />
                  {t("app.formulairesLivretApprentissage.headerPart3")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart4")}
                  <br />
                  {t("app.formulairesLivretApprentissage.headerPart5")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart6")}
                  <br />
                  <ul>
                    <li>{t("app.formulairesLivretApprentissage.headerLi1")}</li>
                    <li>{t("app.formulairesLivretApprentissage.headerLi2")}</li>
                    <li> {t("app.formulairesLivretApprentissage.headerLi3")}</li>
                    <li>{t("app.formulairesLivretApprentissage.headerLi4")}</li>
                  </ul>
                </p>
              </div>
            }
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <div className={styles.title_container}>
                  <h2>{t("app.formulairesLivretApprentissage.titleM1Final")}</h2>{" "}
                </div>
                <form className={styles.the_form} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <h3>{t("app.formulairesLivretApprentissage.CompetencesPerso")}</h3>{" "}
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="insertion" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.InsertionDesc")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.Insertion")}</p>
                      <DropdownList
                        style={
                          errors.insertion &&
                          touched.insertion && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="insertion"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials?.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.insertion}
                        onChange={(newValue) => {
                          setFieldValue("insertion", newValue);
                        }}
                      />
                      <InputErrorMessage message={errors.insertion} touched={touched.insertion} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="teamMindSet" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Equipe")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.EquipeDesc")}</p>
                      <DropdownList
                        style={
                          errors.teamMindSet &&
                          touched.teamMindSet && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="teamMindSet"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.teamMindSet}
                        onChange={(newValue) => setFieldValue("teamMindSet", newValue)}
                      />
                      <InputErrorMessage message={errors.teamMindSet} touched={touched.teamMindSet} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="autonomie" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Autonomie")}
                      </label>
                      <p className={styles.static_line_value}>
                        {t("app.formulairesLivretApprentissage.AutonomieDesc")}
                      </p>
                      <DropdownList
                        style={
                          errors.autonomie &&
                          touched.autonomie && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="autonomie"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.autonomie}
                        onChange={(newValue) => setFieldValue("autonomie", newValue)}
                      />
                      <InputErrorMessage message={errors.autonomie} touched={touched.autonomie} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="initiative" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Initiative")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.InitiativeDesc")}</p>
                      <DropdownList
                        style={
                          errors.initiative &&
                          touched.initiative && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="initiative"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.initiative}
                        onChange={(newValue) => setFieldValue("initiative", newValue)}
                      />
                      <InputErrorMessage message={errors.initiative} touched={touched.initiative} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="normsAndRegulations" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Respect")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.RespectDesc")}</p>
                      <DropdownList
                        style={
                          errors.normsAndRegulations &&
                          touched.normsAndRegulations && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="normsAndRegulations"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.normsAndRegulations}
                        onChange={(newValue) => setFieldValue("normsAndRegulations", newValue)}
                      />
                      <InputErrorMessage message={errors.normsAndRegulations} touched={touched.normsAndRegulations} />
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.formulairesLivretApprentissage.CompetencePro")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="organisation" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Planification")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.PlanificationDesc")}</p>
                      <DropdownList
                        style={
                          errors.organisation &&
                          touched.organisation && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="organisation"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.organisation}
                        onChange={(newValue) => setFieldValue("organisation", newValue)}
                      />
                      <InputErrorMessage message={errors.organisation} touched={touched.organisation} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="selfControl" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Controle")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.ControleDesc")}</p>
                      <DropdownList
                        style={
                          errors.selfControl &&
                          touched.selfControl && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="selfControl"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.selfControl}
                        onChange={(newValue) => setFieldValue("selfControl", newValue)}
                      />
                      <InputErrorMessage message={errors.selfControl} touched={touched.selfControl} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="reporting" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Reporting")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.ReportingDesc")}</p>
                      <DropdownList
                        style={
                          errors.reporting &&
                          touched.reporting && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="reporting"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.reporting}
                        onChange={(newValue) => setFieldValue("reporting", newValue)}
                      />
                      <InputErrorMessage message={errors.reporting} touched={touched.reporting} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="companyKnowledge" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Comprehension")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.ComprehensionDesc")}</p>
                      <DropdownList
                        style={
                          errors.companyKnowledge &&
                          touched.companyKnowledge && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="companyKnowledge"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.companyKnowledge}
                        onChange={(newValue) => setFieldValue("companyKnowledge", newValue)}
                      />
                      <InputErrorMessage message={errors.companyKnowledge} touched={touched.companyKnowledge} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="communicationQuality" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Communication")}
                      </label>
                      <p className={styles.static_line_value}>
                        {t("app.formulairesLivretApprentissage.QualiteCommDesc")}
                      </p>
                      <DropdownList
                        style={
                          errors.communicationQuality &&
                          touched.communicationQuality && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="communicationQuality"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.communicationQuality}
                        onChange={(newValue) => setFieldValue("communicationQuality", newValue)}
                      />
                      <InputErrorMessage message={errors.communicationQuality} touched={touched.communicationQuality} />
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.formulairesLivretApprentissage.CompetenceTech")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="knowledgeAquisition" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Connaissances")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.ConnaissancesDesc")}</p>
                      <DropdownList
                        style={
                          errors.knowledgeAquisition &&
                          touched.knowledgeAquisition && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="knowledgeAquisition"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.knowledgeAquisition}
                        onChange={(newValue) => setFieldValue("knowledgeAquisition", newValue)}
                      />
                      <InputErrorMessage message={errors.knowledgeAquisition} touched={touched.knowledgeAquisition} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="personalOrganisation" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.OrgaPerso")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.OrgaPersoDesc")}</p>
                      <DropdownList
                        style={
                          errors.personalOrganisation &&
                          touched.personalOrganisation && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="personalOrganisation"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.personalOrganisation}
                        onChange={(newValue) => setFieldValue("personalOrganisation", newValue)}
                      />
                      <InputErrorMessage message={errors.personalOrganisation} touched={touched.personalOrganisation} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="masteringSkills" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Outils")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.OutilsDesc")}</p>
                      <DropdownList
                        style={
                          errors.masteringSkills &&
                          touched.masteringSkills && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="masteringSkills"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.masteringSkills}
                        onChange={(newValue) => setFieldValue("masteringSkills", newValue)}
                      />
                      <InputErrorMessage message={errors.masteringSkills} touched={touched.masteringSkills} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="respectDetails" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Delais")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.DelaisDesc")}</p>
                      <DropdownList
                        style={
                          errors.respectDetails &&
                          touched.respectDetails && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="respectDetails"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.respectDetails}
                        onChange={(newValue) => setFieldValue("respectDetails", newValue)}
                      />
                      <InputErrorMessage message={errors.respectDetails} touched={touched.respectDetails} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="processMastering" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Methodes")}
                      </label>
                      <p>{t("app.formulairesLivretApprentissage.MethodesDesc")}</p>
                      <DropdownList
                        style={
                          errors.processMastering &&
                          touched.processMastering && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="processMastering"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                            { ...objAucun },
                            ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                          ]
                        }
                        value={values.processMastering}
                        onChange={(newValue) => setFieldValue("processMastering", newValue)}
                      />
                      <InputErrorMessage message={errors.processMastering} touched={touched.processMastering} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="commentSection">{t("app.formulairesLivretApprentissage.Commentaires")}</label>
                      <textarea
                        style={
                          errors.commentSection &&
                          touched.commentSection && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="commentSection"
                        rows="5"
                        maxLength={32768}
                        value={values.commentSection}
                        onChange={(e) => setFieldValue("commentSection", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.commentSection} touched={touched.commentSection} />
                    </div>
                    <div className={styles.checkbox_container}>
                      <label htmlFor="signature" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.SignatureMaitre")}{" "}
                      </label>
                      <input
                        id="signature"
                        type="checkbox"
                        checked={values.signature}
                        onChange={(e) => setFieldValue("signature", e.target.checked)}
                      />
                      <InputErrorMessage message={errors.signature} touched={touched.signature} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.formulairesLivretApprentissage.BoutonValider")} />
                  <BackButton text={t("app.formulairesLivretApprentissage.BoutonRetour")} />
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EndTermEvalFirstYear;
