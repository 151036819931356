import { t } from "i18next";

import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";

import {
  getAllThunk,
  getLearningBookletsListData,
  getLearningBookletsPending,
  handleReset,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";

import bgContractImg from "./assets/bg-consul-offre.png";
import arrow from "./assets/icon-arrow-right.svg";
import arrowPagination from "./assets/icon-arrow-pagination.svg";

import styles from "./LearningBooklets.module.scss";

const LearningBooklets = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const listData = useSelector(getLearningBookletsListData);
  const pending = useSelector(getLearningBookletsPending);
  //   const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const [actualPagination, setActualPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(null);

  const userToken = useSelector(getUserToken);

  useLayoutEffect(() => {
    if (listData.length > 0 && listData.length >= 26 && !totalPagination) {
      setTotalPagination(Math.floor(listData.length / 25 + 1));
    } else if (listData.length > 0 && listData.length <= 25 && !totalPagination) {
      setTotalPagination(1);
    }
  }, [totalPagination, listData]);

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getAllThunk({ axiosCancelToken })).unwrap();
          console.log("useLayoutEffect success ! LearningBooklets");
        } catch (error) {
          console.log("useLayoutEffect fail ! LearningBooklets", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      dispatch(handleReset());
      axiosCancelToken.cancel();
    },
    [],
  );

  const handleNextPage = () => {
    if (actualPagination < totalPagination) {
      setActualPagination((value) => value + 1);
    }
  };

  const handlePrevPage = () => {
    if (actualPagination > 1) {
      setActualPagination((value) => value - 1);
    }
  };

  console.log("listData : ", listData);

  return (
    <>
      {pending && <Loader />}
      <Header
        h1={t("app.suivezLivretsPartner.Title")}
        textElement={(
          <p>
            {t("app.suivezLivretsPartner.IntroPartner_1")}
            <br />
            <br />
            {t("app.suivezLivretsPartner.IntroPartner_2")}
            <br />
            {t("app.suivezLivretsPartner.IntroPartner_3")}
          </p>
        )}
        bgImg={bgContractImg}
      />
      <div className={styles.containerResult}>
        <div className={styles.containerResult__head}>
          <div className={styles.containerResult__head__title}>{t("app.suivezLivretsPartner.NomLivret")}</div>
          <div className={styles.containerResult__head__title}>{t("app.suivezLivretsPartner.NomEtudiant")}</div>
          <div className={styles.containerResult__head__title}>{t("app.suivezLivretsPartner.NomTuteur")}</div>
          <div className={styles.containerResult__head__title}>{t("app.suivezLivretsPartner.NomMaitre")}</div>
          <div className={styles.containerResult__head__title}>{t("app.suivezLivretsPartner.Statut")}</div>
        </div>
        <div className={styles.allResults}>
          {listData.slice((actualPagination - 1) * 24, actualPagination * 24)?.map((item) => (
            <div className={styles.result} key={nanoid()}>
              <div className={styles.result__body__id}>
                <span>{item?.name}</span>
              </div>
              <div className={styles.result__body}>
                <span> {item?.student?.firstname}</span>
                <span> {item?.student?.name}</span>
              </div>
              <div className={styles.result__body}>
                <span> {item?.schoolTutor?.firstname}</span>
                <span> {item?.schoolTutor?.name}</span>
              </div>
              <div className={styles.result__body}>
                <span> {item?.companyTutor?.firstname}</span>
                <span> {item?.companyTutor?.name}</span>
              </div>
              <div className={styles.result__body}>
                <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Statut")}</span>
                <span> {item?.status?.label}</span>
              </div>
              <div className={styles.result__body__link}>
                <Link to={item?.id?.toString()}>
                  <div className={styles.btn_seeMore}>
                    <span>{t("app.SuivezVosContrats.Consulter")}</span>
                    <img src={arrow} alt={t("app.allOffers.btnDetails")} />
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.arrowPagination}>
        <button type="button" disabled={actualPagination <= 1} onClick={handlePrevPage} className={styles.arrowLeft}>
          <img src={arrowPagination} alt="prev" />
        </button>
        <span className={styles.totalPage}>PAGE</span>
        {actualPagination}/{totalPagination}
        <button type="button" disabled={actualPagination >= totalPagination} onClick={handleNextPage}>
          <img src={arrowPagination} alt="prev" />
        </button>
      </div>
    </>
  );
};

export default LearningBooklets;
