import { useState, useLayoutEffect } from "react";

import axios from "axios";

import { t } from "i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  getDeclareARecruitmentStudentInformations,
  getDeclareARecruitmentActualStep,
  getDeclareARecruitmentPending,
  getDeclareARecruitmentErrorMessage,
  getStudentInformationsThunk,
  getPreContractualizationInformationsThunk,
  resetSliceInformations,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { getMeData, getUserConnexionErrorMessage } from "app/slices/userConnexion/userConnexion.slice";

import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import Step1 from "./steps/Step1/Step1";
import Step2 from "./steps/Step2/Step2";
import Step3 from "./steps/Step3/Step3";
import Step4 from "./steps/Step4/Step4";
import Step5 from "./steps/Step5/Step5";
import Step8 from "./steps/Step8/Step8";
import Step6 from "./steps/Step6/Step6";
import Step7 from "./steps/Step7/Step7";
import Confirmation from "./steps/Confirmation/Confirmation";

import bgFollowOffreImg from "./assets/bg-consul-offre.png";

import styles from "./DeclareARecruitment.module.scss";

const DeclareARecruitment = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const [firstCall, setFirstCall] = useState(false);

  const dispatch = useDispatch();

  const studentInformations = useSelector(getDeclareARecruitmentStudentInformations);
  const actualStep = useSelector(getDeclareARecruitmentActualStep);
  const pending = useSelector(getDeclareARecruitmentPending);
  const errorMessage = useSelector(getDeclareARecruitmentErrorMessage);

  const userMeData = useSelector(getMeData);
  const userConnexionErrorMessage = useSelector(getUserConnexionErrorMessage);

  useLayoutEffect(() => {
    if (!pending && userMeData && !firstCall) {
      (async () => {
        try {
          await dispatch(getStudentInformationsThunk({ id: userMeData.id, axiosCancelToken })).unwrap();
          console.log("DeclareARecuitment useEffect success !");
        } catch (error) {
          setFirstCall(true);
          console.log("DeclareARecuitment useEffect failed !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMeData]);

  useLayoutEffect(() => {
    if (userMeData && studentInformations && !firstCall) {
      if (studentInformations?.apprenticeshipPreContractualizationId) {
        (async () => {
          try {
            await dispatch(
              getPreContractualizationInformationsThunk({
                apprenticeshipPreContractualizationId: studentInformations.apprenticeshipPreContractualizationId,
                axiosCancelToken,
              }),
            ).unwrap();

            setFirstCall(true);
            console.log("DeclareARecuitment preContract useEffect success !");
          } catch (error) {
            setFirstCall(true);
            console.log("DeclareARecuitment preContract useEffect failed !", error.message);
          }
        })();
      } else {
        setFirstCall(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInformations]);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetSliceInformations());
      console.log("DeclareARecruitment unmount");
    },
    [],
  );

  /* eslint-disable */
  return (
    <div className={styles.main_container}>
      {!firstCall && !errorMessage && !userConnexionErrorMessage && pending && (
        <Header
          h1={t("app.StepThree.DeclarezProjet")}
          textElement={<p>Chargement des données est en cours, merci de patienter.</p>}
          bgImg={bgFollowOffreImg}
        />
      )}
      {userConnexionErrorMessage && (
        <Header
          h1={t("app.StepThree.DeclarezProjet")}
          textElement={
            <p style={{ color: "red" }}>
              Impossible de se récupérer les informations du getMe : {userConnexionErrorMessage}
            </p>
          }
          bgImg={bgFollowOffreImg}
        />
      )}
      {errorMessage && (
        <h1 style={{ color: "red" }}>Erreur serveur lors de la déclaration du projet d'alternance : {errorMessage}</h1>
      )}
      {firstCall && studentInformations && (
        <>
          {pending && <Loader />}
          {actualStep === "Etape1" && <Step1 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape2" && <Step2 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape3" && <Step3 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape4" && <Step4 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape5" && <Step5 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape6" && <Step6 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape7" && <Step7 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape8" && <Step8 axiosCancelToken={axiosCancelToken} />}
          {actualStep === "Etape9" && <Confirmation axiosCancelToken={axiosCancelToken} />}
        </>
      )}
    </div>
  );
};

export default DeclareARecruitment;
