import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  AssociativeExperiencesList: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getAssociativeExperiencesThunk = createAsyncThunk(
  "getYourAssociativeExperiences/getAssociativeExperiencesCardsList",
  async ({ cvId }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${cvId}/experiences/associative`);
    return response.data;
  },
);

// CREATESLICE SECTION

const getYourAssociativeExperiencesSlice = createSlice({
  name: "getYourAssociativeExperiences",
  initialState,
  extraReducers: {
    [getAssociativeExperiencesThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getAssociativeExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.AssociativeExperiencesList = action.payload;
    },
    [getAssociativeExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getAllAssociativeExperiences = (state) => state.getYourAssociativeExperiences.AssociativeExperiencesList;
export const getAssociativeExperiencesCardsIsLoading = (state) => state.getYourAssociativeExperiences.pending;
export const getAssociativeExperiencesCardsErrorMessage = (state) => state.getYourAssociativeExperiences.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getYourAssociativeExperiencesSlice.reducer;
