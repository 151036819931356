/* eslint-disable */
import { t } from "i18next";

export const createObjAucun = () => {
  return { key: "-Aucun-", label: t("app.referentials.Selectionner") };
};

export const createObjAucunIdName = () => {
  return { id: "-Aucun-", name: t("app.referentials.Selectionner") };
};

export const createObjCodeAucun = () => {
  return { code: "-Aucun-", label: t("app.referentials.Selectionner") };
};

export const createObjOui = () => {
  return { key: "Oui", label: t("app.referentials.Oui") };
};

export const createObjNon = () => {
  return { key: "Non", label: t("app.referentials.Non") };
};

export const createBooleanReferential = () => {
  return [
    { key: "-Aucun-", label: t("app.referentials.Selectionner") },
    { key: "Oui", label: t("app.referentials.Oui") },
    { key: "Non", label: t("app.referentials.Non") },
  ];
};
