import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets/cjs";

import { t } from "i18next";

import { useEffect, useState } from "react";
import * as Yup from "yup";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import {
  getAllProfessionalExperiences,
  getProfessionalExperiencesThunk,
} from "app/slices/createYourCV/getYourProExperiences.slice";
import { createObjAucun } from "utils/localReferentials";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import Header from "../../../../../../components/Header/Header";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import {
  getExperiencesCardsIsLoading,
  getExperiencesThunk,
} from "../../../../../../app/slices/createYourCV/getYourExperiences.slice";
import {
  getcreateYourProExperienceData,
  getcreateYourProExperienceErrorMessage,
  getcreateYourProExperienceId,
  getcreateYourProExperiencePending,
  PostProExperienceThunk,
} from "../../../../../../app/slices/createYourCV/createYourProExperience.slice";
import ProExpCard from "../components/proExpCard";
import { getReferentialsData } from "../../../../../../app/slices/referentials/referentials.slice";
import Modal from "../components/ProfessionalExperiencesModal";
import style from "./AddExperienceBtn.module.scss";
import styles from "../../../CreateYourCV.module.scss";

/* eslint-disable */
const ProExperiences = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourProExperiencePending);
  const errorMessage = useSelector(getcreateYourProExperienceErrorMessage);
  const id = useSelector(getcreateYourProExperienceId);
  const ProExperiencesData = useSelector(getcreateYourProExperienceData);
  const experiencesList = useSelector(getAllProfessionalExperiences);
  const getExperienceLoading = useSelector(getExperiencesCardsIsLoading);
  const [isFinishedProExp, setIsFinishedProExp] = useState(true);
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(true);
  const [proExpList, setProExpList] = useState([]);
  const referencialData = useSelector(getReferentialsData);
  const cvId = useSelector(getcreateYourCVId);
  const empty = createObjAucun();
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };
  useEffect(() => {
    if (!getExperienceLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getProfessionalExperiencesThunk({
                  cvId: cvId,
                }),
              ).unwrap();
              console.log("this is the experiences Data:", experiencesList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedProExp, isModal]);

  useEffect(() => {
    if (!getExperienceLoading) {
      (async () => {
        try {
          await dispatch(getExperiencesThunk({ cvId }));
          console.log("useEffect Experiences success !", experiencesList);
        } catch (error) {
          console.log("useEffect fail !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedProExp]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const ProExpSchema = Yup.object().shape({
    organizationName: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    position: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
    performedTasks: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    acquiredSkills: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    contractType: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== empty.key),
  });
  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    dispatch(
      handleStepsData({
        formName: "Experiences",
        formData: {
          organizationName: values.organizationName,
          contractType: values.contractType,
          position: values.position,
          performedTasks: values.performedTasks,
          acquiredSkills: values.acquiredSkills,
          startDate: formatDate(values.startDate),
          endDate: formatDate(values.endDate),
        },
      }),
    );

    const dataToSend = {
      organizationName: valuesCopy.organizationName,
      contractType: valuesCopy.contractType.key,
      position: valuesCopy.position,
      performedTasks: valuesCopy.performedTasks,
      acquiredSkills: valuesCopy.acquiredSkills,
      startDate: formatDate(valuesCopy.startDate),
      endDate: formatDate(valuesCopy.endDate),
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedProExp(true);
    if (!pending) {
      (async () => {
        if (experiencesList.length < 5) {
          try {
            await dispatch(
              PostProExperienceThunk({
                cvId,
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutExpProTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutExpProMessage")}
              />,
            );
            console.log("YourProfile post success !");
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        }
      })();
    }
    resetForm({ values: "" });
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: ProExperiencesData
      ? {
          ...ProExperiencesData,
          startDate: new Date(JSON.parse(ProExperiencesData.startDate)),
          endDate: new Date(JSON.parse(ProExperiencesData.endDate)),
        }
      : {
          organizationName: "",
          contractType: empty,
          startDate: null,
          endDate: null,
          position: "",
          performedTasks: "",
          acquiredSkills: "",
        },
    validationSchema: ProExpSchema,
    onSubmit,
  });
  const HandlePushExperienceButton = ({ text }) => {
    return (
      <button
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
      >
        {text}
      </button>
    );
  };

  const AddExperienceBtn = ({ text, disabled }) => {
    return (
      <button
        type="submit"
        disabled={no ? true : experiencesList.length >= 5 ? true : false}
        color={no ? "grey" : "red"}
        className={style.add_proj_btn}
        style={{ fontSize: 13, fontFamily: "Inseec", textSizeAdjust: "100%" }}
        onClick={() => setIsFinishedProExp(false)}
      >
        <span style={{ color: no ? "grey" : "white" }}>{text}</span>
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span>
                  <p>{t("app.depotCv.ExperienceProfessionnelle")}</p>
                </span>
              </div>
              <>
                <div className={styles.checkbox_container}>
                  <div>
                    <input
                      type="checkbox"
                      checked={yes}
                      onChange={() => {
                        setIsFinishedProExp(false);
                        setYes(true);
                        setNo(false);
                      }}
                    />
                    <span>{t("app.depotCv.Oui")}</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={no}
                      onChange={() => {
                        setIsFinishedProExp(true);
                        setNo(true);
                        setYes(false);
                      }}
                    />
                    <span>{t("app.depotCv.Non")}</span>
                  </div>
                </div>
                {experiencesList.length > 0 &&
                  experiencesList.map((obj, index) => (
                    <ProExpCard
                      modifyForm={goToModify}
                      isModify={isModify}
                      handleModalData={handleModalData}
                      {...obj}
                      key={index}
                    />
                  ))}
                {isFinishedProExp ? (
                  <>
                    {proExpList.length >= 0 ? (
                      <div className={style.add_proj_btn_container} style={{ width: "auto", alignSelf: "center" }}>
                        <AddExperienceBtn text={t("app.depotCv.ADD")} />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="organizationName">{t("app.depotCv.NomDeEntreprise")}</label>
                      <input
                        style={
                          errors.organizationName &&
                          touched.organizationName && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="organizationName"
                        type="text"
                        value={values.organizationName}
                        onChange={(e) => setFieldValue("organizationName", e.target.value)}
                      />
                      <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                    </div>
                    <div className={styles.label_test_input_container}>
                      <label htmlFor="contractType">{t("app.depotCv.TypeDeContrat")}</label>
                      <DropdownList
                        style={
                          errors.contractType &&
                          touched.contractType && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="contractType"
                        dataKey="key"
                        textField="label"
                        data={[empty, ...referencialData?.CONTRACT_TYPE]}
                        value={values.contractType}
                        onChange={(newValue) => setFieldValue("contractType", newValue)}
                      />
                      <InputErrorMessage message={errors.contractType} touched={touched.contractType} />
                    </div>

                    <div className={styles.label_plus_input_container} style={{ marginTop: "5%" }}>
                      <label htmlFor="StartDate">{t("app.depotCv.DateDebut")}</label>
                      {/* <DatePicker
												style={
													errors.startDate &&
													touched.startDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.startDate}
												onChange={(value) => setFieldValue("startDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="endDate">{t("app.depotCv.DateFin")}</label>
                      {/* <DatePicker
												style={
													errors.endDate &&
													touched.endDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.endDate}
												onChange={(value) => setFieldValue("endDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.endDate}
                        touched={touched.endDate}
                        value={values.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="position">{t("app.depotCv.Fonction")}</label>
                      <textarea
                        id="position"
                        type="text"
                        value={values.position}
                        style={
                          errors.position &&
                          touched.position && {
                            border: "2px solid #EA001E",
                          }
                        }
                        onChange={(e) => setFieldValue("position", e.target.value)}
                      />
                      <InputErrorMessage message={errors.position} touched={touched.position} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="performedTasks">{t("app.depotCv.MissionsRealisees")}</label>
                      <textarea
                        style={
                          errors.performedTasks &&
                          touched.performedTasks && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="performedTasks"
                        value={values.performedTasks}
                        onChange={(e) => setFieldValue("performedTasks", e.target.value)}
                      />
                      <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="acquiredSkills">{t("app.depotCv.3Competences")}</label>
                      <textarea
                        style={
                          errors.acquiredSkills &&
                          touched.acquiredSkills && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="acquiredSkills"
                        value={values.acquiredSkills}
                        onChange={(e) => setFieldValue("acquiredSkills", e.target.value)}
                      />
                      <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                    </div>
                    <div className={style.validate_exp_btn_container}>
                      <HandlePushExperienceButton text={t("app.depotCv.ValiderExp")} />
                    </div>
                  </div>
                )}
              </>
            </form>
          </section>
        </>
      )}
    </>

    // End Academic experiences
  );
};

export default ProExperiences;
