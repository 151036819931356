import { t } from "i18next";

import axios from "axios";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getUploadFilePending,
  getUploadFIleErrorMessage,
  postFileThunk,
  postFileStudentThunk,
  deleteOfferFileThunk,
  deleteCVFileThunk,
  setFileData,
  selectFileData,
  selectDeleteFilePending,
} from "app/slices/uploadFile/uploadFile.slice";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";

import Loader from "components/Loader/Loader";

import binImg from "./bin.svg";

import styles from "./UploadDeleteOrganism.module.scss";

const UploadDeleteOrganism = ({ id, middleware, endpoint, labelText, uploadMessages, deleteMessages }) => {
  /**
   * Check PositionToBeFilled if you want an example
   */
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const fileData = useSelector(selectFileData);
  const fileDataDeletePending = useSelector(selectDeleteFilePending);

  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);
  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);

  useEffect(() => {
    console.log("FILE : ", file);
    if (file && !fileData) {
      (async () => {
        try {
          let response;
          if (middleware === "student") {
            response = await dispatch(
              postFileStudentThunk({
                file,
                endpoint,
                axiosCancelToken,
              }),
            ).unwrap();
          } else if (middleware === "company") {
            response = await dispatch(
              postFileThunk({
                file,
                endpoint,
                axiosCancelToken,
              }),
            ).unwrap();
          } else {
            console.log("Wrong or missing middleware props");
            return;
          }
          console.log("response job file", response);
          dispatch(setFileData(response));
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          toastSuccess(
            <ToastMessageStructure
              firstMessage={uploadMessages?.success?.firstMessage}
              secondMessage={`${uploadMessages?.success?.secondMessage} ${fileUploaded + 1}`}
            />,
          );
          console.log("upload success UploadDeleteOrganism !");
        } catch (error) {
          console.log("upload fail UploadDeleteOrganism !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={uploadMessages?.error?.firstMessage} secondMessage={file?.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      {fileDataDeletePending && <Loader />}

      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />

      <div className={styles.label_plus_input_container}>
        <label htmlFor="uploadInput">{labelText}</label>
        <div className={styles.custom_input_container}>
          <div className={styles.disabled_div_upload} style={{ display: `${fileData ? "block" : "none"}` }}></div>
          <label htmlFor="file-upload" className={styles.input_label}>
            <span className={styles.uploadFile}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.9229 9.55394H13.9998C13.7537 9.55394 13.5383 9.80009 13.5383 10.0155V13.0924C13.5383 13.3386 13.3229 13.5539 13.0768 13.5539H2.92293C2.67677 13.5539 2.46139 13.3386 2.46139 13.0924V10.0155C2.46139 9.80009 2.246 9.55394 1.99985 9.55394H1.07677C0.830619 9.55394 0.615234 9.80009 0.615234 10.0155V14.1693C0.615234 14.8462 1.16908 15.4001 1.846 15.4001H14.1537C14.8306 15.4001 15.3845 14.8462 15.3845 14.1693V10.0155C15.3845 9.80009 15.1691 9.55394 14.9229 9.55394ZM8.30754 0.75394C8.12293 0.569325 7.846 0.569325 7.66139 0.75394L3.50754 4.90779C3.32293 5.0924 3.32293 5.36932 3.50754 5.55394L4.1537 6.20009C4.33831 6.38471 4.61523 6.38471 4.79985 6.20009L6.52293 4.47702C6.70754 4.2924 7.07677 4.41548 7.07677 4.6924V11.2155C7.07677 11.4616 7.26139 11.677 7.50754 11.677H8.43062C8.67677 11.677 8.92293 11.4309 8.92293 11.2155V4.72317C8.92293 4.44625 9.23062 4.32317 9.446 4.50779L11.1691 6.23086C11.3537 6.41548 11.6306 6.41548 11.8152 6.23086L12.4614 5.58471C12.646 5.40009 12.646 5.12317 12.4614 4.93856L8.30754 0.75394Z"
                  fill="#3E0056"
                />
              </svg>
              {t("app.declarezUnRecrutement.ChargerFichiers")}
            </span>
            <span className={styles.dragDrop}>{t("app.declarezUnRecrutement.DeposerFichiers")}</span>
          </label>
        </div>
        <input
          style={{ display: "none" }}
          disabled={fileData}
          type="file"
          id="file-upload"
          onChange={(e) => {
            setFile(e.target.files[0]);
            setDisplayModal(true);
            e.target.value = null;
          }}
        />

        <InputErrorMessage message={spanErrorMessage ? t("app.errorMessages.FichierRequis") : ""} touched />

        {fileData && (
          <div className={styles.filename_and_delete_container}>
            <span>{fileData.title}</span>
            <button
              className={styles.delete_btn}
              type="button"
              onClick={async () => {
                if (!fileDataDeletePending) {
                  try {
                    let response;
                    if (middleware === "student") {
                      response = await dispatch(
                        deleteCVFileThunk({
                          id,
                          axiosCancelToken,
                        }),
                      ).unwrap();
                    } else if (middleware === "company") {
                      response = await dispatch(
                        deleteOfferFileThunk({
                          id,
                          axiosCancelToken,
                        }),
                      ).unwrap();
                    } else {
                      console.log("Wrong or missing middleware props");
                      return;
                    }
                    dispatch(setFileData(response));
                    setFileUploaded(0);
                    setSpanErrorMessage(false);
                    toastSuccess(
                      <ToastMessageStructure
                        firstMessage={deleteMessages?.success?.firstMessage}
                        secondMessage={file?.name}
                      />,
                    );
                    console.log("delete success !");
                  } catch (error) {
                    console.log("delete failed !", error.message);
                    toastError(
                      <ToastMessageStructure
                        firstMessage={deleteMessages?.error?.firstMessage}
                        secondMessage={file?.name}
                      />,
                    );
                  }
                }
              }}
            >
              <img src={binImg} alt="poubelle" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadDeleteOrganism;
