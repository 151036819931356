import { t } from "i18next";

import { useState } from "react";

import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import UploadOrganism from "../../../components/UploadOrganism/UploadOrganism";
import CheckboxOrganism from "../components/CheckboxOrganism";
import BlueBanner from "../components/BlueBanner";

import styles from "../Step2.module.scss";

const ContinuingEducationForm = ({ axiosCancelToken }) => {
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [displayModal, setDisplayModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);

  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  // onSubmit form function
  const onSubmit = () => {
    // Data to send object initialization
    const dataToSend = { stage: "Etape3" };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  // useFormik from Formik
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      apprenticeshipContractCheckbox: false,
      breachOfContractCheckbox: false,
      disabilityCheckbox: false,
      businessProjectCheckbox: false,
      sportsmanCheckbox: false,
      professionalizationContractCheckbox: false,
      jobseekerCheckbox: false,
      socialMinimaCheckbox: false,
      singleIntegrationContractCheckbox: false,
      notHoldingATeachingCheckbox: false,
    },
    onSubmit,
  });

  return (
    <>
      {disabled && <BlueBanner />}
      <form onSubmit={handleSubmit}>
        <div className={styles.inputs_container}>
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="apprenticeshipContractCheckbox"
            label={t("app.declarezUnRecrutement.ContratDapprentissage")}
            text={t("app.declarezUnRecrutement.ContratDapprentissageDesc")}
            infoBubbleContent={t("app.declarezUnRecrutement.ContratDapprentissageDesc")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="breachOfContractCheckbox"
            label={t("app.declarezUnRecrutement.RuptureDeContrat")}
            text={t("app.declarezUnRecrutement.RuptureContratCausesIndependantes")}
            infoBubbleContent={t("app.declarezUnRecrutement.RuptureContratCausesIndependantes")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="disabilityCheckbox"
            label={t("app.declarezUnRecrutement.Handicap")}
            text={t("app.declarezUnRecrutement.HandicapReconnu")}
            infoBubbleContent={t("app.declarezUnRecrutement.HandicapReconnu")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="businessProjectCheckbox"
            label={t("app.declarezUnRecrutement.ProjetDentreprise")}
            text={t("app.declarezUnRecrutement.ProjetCreationNecessitantDiplomeDetermine")}
            infoBubbleContent={t("app.declarezUnRecrutement.ProjetCreationNecessitantDiplomeDetermine")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="sportsmanCheckbox"
            label={t("app.declarezUnRecrutement.Sportif")}
            text={t("app.declarezUnRecrutement.ReconnaissanceSportifHautNiveau")}
            infoBubbleContent={t("app.declarezUnRecrutement.ReconnaissanceSportifHautNiveau")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <UploadOrganism
            labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
            disabled={disabled}
            setFile={setFile}
            setDisplayModal={setDisplayModal}
            spanErrorMessage={spanErrorMessage}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="professionalizationContractCheckbox"
            label={t("app.declarezUnRecrutement.ContratDeProfessionalisation")}
            text={t("app.declarezUnRecrutement.DemandeurInscritPoleEmploi")}
            infoBubbleContent={t("app.declarezUnRecrutement.DemandeurInscritPoleEmploi")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="jobseekerCheckbox"
            label={t("app.declarezUnRecrutement.DemandeurDemploi")}
            text={t("app.declarezUnRecrutement.DemandeurInscritPlusUnAnPoleEmploi")}
            infoBubbleContent={t("app.declarezUnRecrutement.DemandeurInscritPlusUnAnPoleEmploi")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="socialMinimaCheckbox"
            label={t("app.declarezUnRecrutement.MinimasSociaux")}
            text={t("app.declarezUnRecrutement.BeneficiaireMinimasSociaux")}
            infoBubbleContent={t("app.declarezUnRecrutement.BeneficiaireMinimasSociaux")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="singleIntegrationContractCheckbox"
            label={t("app.declarezUnRecrutement.ContratUniqueDinsertion")}
            text={t("app.declarezUnRecrutement.BeneficieDuContratUniqueDinsertion")}
            infoBubbleContent={t("app.declarezUnRecrutement.BeneficieDuContratUniqueDinsertion")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="notHoldingATeachingCheckbox"
            label={t("app.declarezUnRecrutement.NonTitulaire")}
            text={t("app.declarezUnRecrutement.JeuneSansBac")}
            infoBubbleContent={t("app.declarezUnRecrutement.JeuneSansBac")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <UploadOrganism
            labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
            disabled={disabled}
            setFile={setFile}
            setDisplayModal={setDisplayModal}
            spanErrorMessage={spanErrorMessage}
          />
        </div>
      </form>
      <div className={styles.btns_container}>
        <SubmitContractBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} handleNextStep={handleSubmit} />
        <PreviousFormBtn
          text={t("app.declarezUnRecrutement.EtapePrecedente")}
          handlePreviousStep={() => dispatch(handlePreviousStep("Etape1"))}
        />
      </div>
    </>
  );
};

export default ContinuingEducationForm;
