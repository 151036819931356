import { Link } from "react-router-dom";

import styles from "./BackButton.module.scss";

const BackButton = ({ text }) => (
  <div className={styles.container_btn}>
    <Link to="/livret-dapprentissage">
      <button type="button" className={styles.submit_form_btn}>
        {text}
      </button>
    </Link>
  </div>
);

export default BackButton;
