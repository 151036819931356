import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  ProjectsList: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getProjectsThunk = createAsyncThunk("getYourProjects/getProjectsCardsList", async ({ cvId }) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${cvId}/projects`);
  return response.data;
});

// CREATESLICE SECTION

const getYourProjectsSlice = createSlice({
  name: "getYourProjects",
  initialState,
  extraReducers: {
    [getProjectsThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getProjectsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.ProjectsList = action.payload;
    },
    [getProjectsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getAllProjects = (state) => state.getYourAcademicProjects.ProjectsList;
export const getProjectsCardsIsLoading = (state) => state.getYourAcademicProjects.pending;
export const getProjectsCardsErrorMessage = (state) => state.getYourAcademicProjects.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getYourProjectsSlice.reducer;
