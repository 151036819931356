import { nanoid } from "@reduxjs/toolkit";

import DivLine from "components/DivLine/DivLine";
import Header from "./Header/Header";
import Card from "./Card/Card";

import styles from "./ConfirmationFormsPage.module.scss";

const ConfirmationFormsPage = ({ h1, textElement, bgImg, leftBtn, rightBtn, h2, cardsArray }) => (
  <>
    <Header
      h1={h1}
      textElement={textElement}
      bgImg={bgImg}
      leftBtn={{
        text: leftBtn.text,
        path: leftBtn.path,
      }}
      rightBtn={{
        text: rightBtn.text,
        path: rightBtn.path,
      }}
    />

    <section className={styles.third_container}>
      <div className={styles.title_third_container}>
        <DivLine />
        <h2>{h2}</h2>
      </div>
      <div className={styles.cards_container}>
        {cardsArray.map((obj) => (
          <Card key={nanoid()} {...obj} />
        ))}
      </div>
    </section>
  </>
);

export default ConfirmationFormsPage;
