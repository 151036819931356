import styles from "./LoadingModal.module.scss";
import closeIcon from "./asset/close-svg.svg";

import successIcon from "./asset/approval.svg";
import errorIcon from "./asset/close.svg";
import pendingIcon from "./asset/download.svg";

import { getFileType } from "../../../../../utils/fileTypesCard/FileTypesCard";

/* eslint-disable */
const LoadingModal = (props) => {
  if (!props.file.size) {
    console.log("Component LoadingModal.js canceled because !props.file.size");
    return;
  }

  const convertOctetToBite = () => {
    if (props.pending || props.error) return 0;
    else if (!props.pending && !props.error) {
      let convdertedSize = props.file.size.toString();
      convdertedSize = convdertedSize.substr(0, 1) + "." + convdertedSize.substr(1, 2);
      return convdertedSize;
    }
  };

  const getFileNumber = () => {
    if (props.pending || props.error) return 0;
    else if (!props.pending && !props.error) return 1;
  };

  const displatLoadIcon = () => {
    if (!props.pending && !props.error) return successIcon;
    else if (props.pending) return pendingIcon;
    else if (props.error && !props.pending) return errorIcon;
  };

  if (props.show)
    return (
      <>
        <div className={styles.loading_modal}>
          <div className={styles.loading_modal_content}>
            <button
              type="button"
              className={styles.modal_close_button}
              onClick={() => {
                props.setShow(false);
              }}
            >
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>CHARGER DES FICHIERS</h2>
            <div className={styles.separator_top} />

            <div className={styles.modal_loading_contentBox}>
              <img className={styles.img_file} src={getFileType(props)} />
              <div className={styles.img_txt_file}>
                <p className={styles.file_name}>{props.file.name}</p>
                <p>{convertOctetToBite()} B</p>
              </div>
              {props.pending && <div id="loadingBar" className={styles.loading_bar_pending} />}
              {props.error && !props.pending && <div id="loadingBar" className={styles.loading_bar_error} />}
              {!props.pending && !props.error && <div id="loadingBar" className={styles.loading_bar_success} />}

              <img className={styles.status_img} src={displatLoadIcon()} alt="loadingIcon" />
            </div>

            <div className={styles.separator_bottom} />
            <div className={styles.bottom_container}>
              <p>{getFileNumber()} fichier sur 1 chargé</p>
              {!props.pending && !props.error && (
                <div disabled={false} className={styles.close_loading_modal} onClick={props.handleClose}>
                  <p>Terminé</p>
                </div>
              )}
              {props.pending && (
                <div
                  disabled={true}
                  className={styles.close_loading_modal_pending}
                  //onClick={props.handleClose}
                >
                  <p>Terminé</p>
                </div>
              )}
              {props.error && !props.pending && (
                <div
                  disabled={true}
                  className={styles.close_loading_modal_error}
                  //onClick={props.handleClose}
                >
                  <p>Terminé</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  if (!props.show) return null;
};

export default LoadingModal;
