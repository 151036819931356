import { useSelector } from "react-redux";
import { getDeviceWidth } from "../../app/slices/deviceWidth/deviceWidth.slice";

import styles from "./Header.module.scss";

const Header = ({ bgImg, h1, textElement }) => {
  const deviceWidth = useSelector(getDeviceWidth);
  const actualBg = deviceWidth > 1350 ? bgImg : "none";

  const headerStyle = {
    background: `no-repeat url(${actualBg}) top -58px right -18px`,
  };

  return (
    <header className={styles.header_container} style={headerStyle}>
      <div className={styles.header_content}>
        <h1>{h1}</h1>
        {textElement}
      </div>
    </header>
  );
};

export default Header;
