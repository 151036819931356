import { t } from "i18next";

import { useState } from "react";
import { useSelector } from "react-redux";
import { getcreateYourCVPending } from "app/slices/createYourCV/createYourCV.slice";

import Header from "../../../../../components/Header/Header";
import ComputerSkills from "./subForms/ComputerSkills";
import LanguageSkills from "./subForms/LanguageSkills";
import CompementarySkills from "./subForms/ComplementarySkills";
import ResumeCardCv from "../../components/ResumeCardCv/ResumeCardCv";
import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../../CreateYourCV.module.scss";

const YourSkills = () => {
  const [frenchLevel, setFrenchLevel] = useState("");
  const [isValidFrenchLevel, setIsValidFrenchLevel] = useState(false);
  const handleChildCallBack = (childData) => {
    setFrenchLevel(childData);
    console.log("FR DATA", frenchLevel);
  };
  const handleFrenchLevelValidityCallBack = (childData) => {
    setIsValidFrenchLevel(childData);
    console.log("validity", isValidFrenchLevel);
  };
  const pending = useSelector(getcreateYourCVPending);
  return (
    <>
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && (
        <>
          <Header h1="CREEZ VOTRE CV" textElement={<p>{t("app.depotCv.Etape4")}</p>} />
          <section className={styles.main_container}>
            <div className={styles.title_container} style={{ marginLeft: 80 }}>
              <div style={{ marginLeft: "65%" }}>(Etape 4/5)</div>
            </div>

            <div className={styles.wrapper}>
              <div className={styles.leftCol}>
                <LanguageSkills isValidFrenchLevel={isValidFrenchLevel} handleCallBack={handleChildCallBack} />
                <ComputerSkills />
                <CompementarySkills handleCallBack={handleFrenchLevelValidityCallBack} frenchlevel={frenchLevel} />
              </div>
              <ResumeCardCv />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default YourSkills;
