import styles from "./ToastMessageStructure.module.scss";

const ToastMessageStructure = ({ firstMessage, secondMessage }) => (
  <div className={styles.main_container}>
    <span className={styles.first_message}>{firstMessage}</span>
    <span className={styles.second_message}>{secondMessage}</span>
  </div>
);

export default ToastMessageStructure;
