/* eslint-disable */
import { toast } from "react-toastify";

export const toastSuccess = (message = "", position = "top-center", autoClose = 3000, hideProgressBar = true) => {
  toast.success(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
  });
};

export const toastWarning = (message = "", position = "top-center", autoClose = 3000, hideProgressBar = true) => {
  toast.warning(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
  });
};

export const toastError = (message = "", position = "top-center", autoClose = 3000, hideProgressBar = true) => {
  toast.error(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
  });
};

export const toastBrowser = (message = "", position = "top-center", autoClose = 3000, hideProgressBar = true) => {
  toast.info(message, {
    // position: "bottom-center",
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    className: "custom-toast", // use your custom class
  });
};

export const toastInfoCenter = (message = "", position = "top-center", autoClose = 3000, hideProgressBar = true) => {
  toast.info(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    style: {
      marginTop: "50%",
      transform: "translateY(-50%)",
    },
  });
};
