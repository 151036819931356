import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getOneThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  handleReset,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import Loader from "components/Loader/Loader";
import EndTermEvalFirstYear from "./forms/EndTermEvalFirstYear/EndTermEvalFirstYear";
import StaticEndTermEvalFirstYear from "./staticForms/StaticEndTermEvalFirstYear";
import BilanInteETU from "./forms/BilanInteETU";
import EndTermEvalSecondYearETU from "./forms/EndTermEvalSecondYearETU";
import StaticBilanInteETU from "./staticForms/StaticTemplateForms/StaticBilanInteETU";
import StaticEndTermEvalSecondYearETU from "./staticForms/StaticEndTermEvalSecondYearETU";
import Mission from "./forms/Missions/Mission";
import IntermediateEvaluationM2 from "./forms/IntermediateEvaluationM2/IntermediateEvaluationM2";
import IntermediateEvaluationM2Valid from "./staticForms/StaticTemplateForms/IntermediateEvaluationM2Valid";
import IntermediateEvaluationM1 from "./forms/IntermediateEvaluationM1/IntermediateEvaluationM1";
import IntermediateEvaluationM1Valid from "./staticForms/StaticTemplateForms/IntermediateEvaluationM1Valid";

const SelectedBookletETU = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();

  const dispatch = useDispatch();

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);

  const referentials = useSelector(getReferentialsData);

  const userToken = useSelector(getUserToken);

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getOneThunk({ id, axiosCancelToken })).unwrap();
          console.log("useLayoutEffect success ! SelectedBooklet");
        } catch (error) {
          console.log("useLayoutEffect fail ! SelectedBooklet", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      dispatch(handleReset());
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("idData : ", idData);

  /* eslint-disable */
  return (
    <>
      {(pending || !referentials) && <Loader />}
      {/* {idData?.status?.key === "Complété par le maitre d'apprentissage" &&
				referentials && <TemplateForm />} */}
      {idData?.status?.key === "Complété" && idData?.type === "INTEGRATION" && referentials && <BilanInteETU />}

      {idData?.type === "MISSION" && referentials && <Mission />}

      {idData?.status?.key === "Complété" && idData?.type === "END_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <EndTermEvalSecondYearETU />
      )}

      {idData?.status?.key === "Complété" && idData?.type === "MID_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <IntermediateEvaluationM2 />
      )}

      {idData?.status?.key === "Complété" && idData?.type === "MID_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <IntermediateEvaluationM1 />
      )}

      {idData?.status?.key !== "Complété" && idData?.type === "MID_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <IntermediateEvaluationM1Valid />
      )}

      {idData?.status?.key !== "Complété" && idData?.type === "END_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <StaticEndTermEvalSecondYearETU />
      )}
      {/* {idData?.status?.key !== "Complété par le maitre d'apprentissage" &&
				referentials && <StaticTemplateForm />} */}
      {idData?.status?.key !== "Complété" && idData?.type === "INTEGRATION" && referentials && <StaticBilanInteETU />}
      {idData?.status?.key === "Complété" && idData?.type === "END_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <EndTermEvalFirstYear />
      )}
      {idData?.status?.key !== "Complété" && idData?.type === "END_TERM_EVALUATION_FIRST_YEAR" && referentials && (
        <StaticEndTermEvalFirstYear />
      )}
      {idData?.status?.key !== "Complété" && idData?.type === "MID_TERM_EVALUATION_SECOND_YEAR" && referentials && (
        <IntermediateEvaluationM2Valid />
      )}
    </>
  );
};

export default SelectedBookletETU;
