import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { DropdownList } from "react-widgets/cjs";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import { useSelector, useDispatch } from "react-redux";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { postComputerSkillsThunk } from "../../../../app/slices/modifyYourCV/computerSkills.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

/* eslint-disable */
const PostModalComputerSkills = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const referentialData = useSelector(getReferentialsData);

  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const onSubmit = (values) => {
    const dataToSend = {
      domain: values.computerSkills.key,
      software: values.software,
    };
    (async () => {
      try {
        await dispatch(
          postComputerSkillsThunk({
            dataToSend,
            id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationAjoutInformatiqueTitle")}
            secondMessage={t("app.toastMessages.ConfirmationAjoutInformatiqueMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };
  const PostModalComputerSkillsSchema = Yup.object().shape({
    computerSkills: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    software: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      computerSkills: { ...objAucun },
      software: "",
    },
    validationSchema: PostModalComputerSkillsSchema,
    onSubmit,
  });

  if (props.showPost)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.CreationCompetenceInfo")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="computerSkills">
                    {t("app.visualisationCv.CompetencesInformatiquesConcernees")}
                  </label>
                  <DropdownList
                    style={
                      errors.computerSkills &&
                      touched.computerSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="computerSkills"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_COMPUTER_SKILL]}
                    value={values.computerSkills}
                    onChange={(newValue) => {
                      setFieldValue("computerSkills", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.computerSkills} touched={touched.computerSkills} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="software">
                    {t("app.visualisationCv.Logiciel")}
                  </label>
                  <input
                    style={
                      errors.software &&
                      touched.software && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.software}
                    maxLength={80}
                    type="text"
                    name="software"
                    id="software"
                    onChange={(e) => setFieldValue("software", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.software} touched={touched.software} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezCompetence")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showPost) return null;
};

export default PostModalComputerSkills;
