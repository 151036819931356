import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import {
  getcreateYourAssoErrorMessage,
  getcreateYourAssoPending,
  putAssoThunk,
} from "app/slices/createYourCV/createYourAsso.slice";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import styles from "./Modal.module.scss";

/* eslint-disable */
const Modal = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourAssoPending);
  const errorMessage = useSelector(getcreateYourAssoErrorMessage);
  const cvId = useSelector(getcreateYourCVId);
  const data = props.modalData;
  const closeModal = props.closeModal;
  const modifyForm = props.modifyForm;
  const isModify = props.isModify;
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const AssoSchema = Yup.object().shape({
    organizationName: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    position: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
    performedTasks: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    acquiredSkills: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
  });
  const onSubmit = (values) => {
    dispatch(
      handleStepsData({
        formName: "AssociativeModal",
        formData: {
          organizationName: values.organizationName,
          startDate: JSON.stringify(values.startDate),
          endDate: JSON.stringify(values.endDate),
          position: values.position,
          performedTasks: values.performedTasks,
          acquiredSkills: values.acquiredSkills,
        },
      }),
    );
    const id = data.id;

    const dataToSend = {
      organizationName: values.organizationName,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      position: values.position,
      performedTasks: values.performedTasks,
      acquiredSkills: values.acquiredSkills,
    };
    console.log("dataToSend : ", dataToSend);
    props.closeModal();
    if (!pending) {
      (async () => {
        try {
          await dispatch(
            putAssoThunk({
              dataToSend,
              id,
              cvId: cvId,
              // axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ConfirmationModifExpAssoTitle")}
              secondMessage={t("app.toastMessages.ConfirmationModifExpAssoMessage")}
            />,
          );
          console.log("Your experience put success !");
        } catch (error) {
          console.log("Your experience put fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      organizationName: data.organizationName,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      position: data.position,
      performedTasks: data.performedTasks,
      acquiredSkills: data.acquiredSkills,
    },
    validationSchema: AssoSchema,
    onSubmit,
  });
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <div style={{ flexDirection: "column" }}>
          {isModify ? (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ModiFyAssoExpModal")}</span>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="organizationName">{t("app.depotCv.NomAssociation")}</label>
                      <input
                        id="organizationName"
                        style={
                          errors.organizationName &&
                          touched.organizationName && {
                            border: "2px solid #EA001E",
                          }
                        }
                        type="text"
                        value={values.organizationName}
                        onChange={(e) => setFieldValue("organizationName", e.target.value)}
                      />
                      <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="StartDate">{t("app.depotCv.DateDebut")}</label>
                      {/* <DatePicker
												value={values.startDate}
												onChange={(value) => setFieldValue("startDate", value)}
												style={
													errors.startDate &&
													touched.startDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
											/> */}
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="endDate">{t("app.depotCv.DateFin")}</label>
                      {/* <DatePicker
												style={
													errors.endDate &&
													touched.endDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.endDate}
												onChange={(value) => setFieldValue("endDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.endDate}
                        touched={touched.endDate}
                        value={values.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="position">{t("app.depotCv.Fonction")}</label>
                      <textarea
                        style={
                          errors.position &&
                          touched.position && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="position"
                        value={values.position}
                        onChange={(e) => setFieldValue("position", e.target.value)}
                      />
                      <InputErrorMessage message={errors.position} touched={touched.position} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="performedTasks">{t("app.depotCv.MissionsRealisees")}</label>
                      <textarea
                        style={
                          errors.performedTasks &&
                          touched.performedTasks && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="performedTasks"
                        value={values.performedTasks}
                        onChange={(e) => setFieldValue("performedTasks", e.target.value)}
                      />
                      <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="acquiredSkills">{t("app.depotCv.3Competences")}</label>
                      <textarea
                        style={
                          errors.acquiredSkills &&
                          touched.acquiredSkills && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="acquiredSkills"
                        value={values.acquiredSkills}
                        onChange={(e) => setFieldValue("acquiredSkills", e.target.value)}
                      />
                      <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                    </div>
                  </div>
                  {/* <div className={styles.label_plus_input_container}></div> */}
                </div>
                <div className={styles.submit_button_container}>
                  <button type="submit" className={styles.submit_button}>
                    {t("app.depotCv.ValidateExperience")}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <p className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ConsultAssoExpModal")}</span>
                </div>
              </p>
              <div className={styles.formContainer}>
                <div>
                  <div className={styles.etab}>
                    {t("app.depotCv.NomAssociation")}: {data.organizationName}
                  </div>
                  <div className={styles.institution}>
                    {t("app.depotCv.Fonction")}:{data.position}
                  </div>
                  <div className={styles.date}>
                    {t("app.depotCv.du")} {data.startDate} {t("app.depotCv.au")} {data.endDate}
                  </div>
                  <div className={styles.description}>{t("app.depotCv.MissionsRealisees")}</div>
                  <div className={styles.dataDescription}>{data.performedTasks}</div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={modifyForm} className={styles.modifyButton}>
                  {t("app.depotCv.ModifyExp")}
                </button>
              </div>
            </>
          )}
        </div>

        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
