import { createSlice } from "@reduxjs/toolkit";

// Initial State declaration
const initialState = window.location.host.split(".")[0];

// CREATESLICE SECTION

const subdomainSlice = createSlice({
  name: "subdomain",
  initialState,
});

// SELECTORS EXPORTS SECTION

export const getSubdomain = (state) => state.subdomain;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default subdomainSlice.reducer;
