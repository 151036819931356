import * as Yup from "yup";
import { t } from "i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { createObjAucun } from "utils/localReferentials";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import {
  getcreateYourExperienceData,
  getcreateYourExperienceErrorMessage,
  getcreateYourExperiencePending,
  PutExperienceThunk,
} from "../../../../../../app/slices/createYourCV/createYourExperience.slice";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import styles from "./Modal.module.scss";

/* eslint-disable */
const Modal = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourExperiencePending);

  const errorMessage = useSelector(getcreateYourExperienceErrorMessage);
  const AcademicExperiencesData = useSelector(getcreateYourExperienceData);
  const cvId = useSelector(getcreateYourCVId);
  const data = props.modalData;
  const empty = { label: "--Aucun--", key: "--Aucun--" };
  const objAucun = createObjAucun();
  const closeModal = props.closeModal;
  const referencialData = useSelector(getReferentialsData);
  // const [isFinishedAcaExp, setIsFinishedAcaExp] = useState(true);
  const modifyForm = props.modifyForm;
  const isModify = props.isModify;
  const Data = {
    institution: "",
    course: "",
    graduationSuccessIndicator: [
      { label: "-Aucun-", key: "--None--" },
      { label: "Oui", key: "Oui" },
      { label: "Non", key: "Non" },
      { label: "In Progress", key: "InProgress" },
    ],
    description: "",
    country: [
      { label: "France", key: "Fr" },
      { label: "Spain", key: "Spa" },
      { label: "Portugal", key: "Por" },
    ],
    city: "",
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const AcademicExperienceSchema = Yup.object().shape({
    graduationSuccessIndicator: Yup.object().test(
      "if-is-aucun",
      "Remplissez ce champ.",
      (value) => value.key !== "-Aucun-",
    ),
    country: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== { ...empty }.key),
    course: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    institution: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    description: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    city: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
  });
  const onSubmit = (values, actions) => {
    dispatch(
      handleStepsData({
        formName: "Experiences",
        formData: {
          institution: values.institution,
          course: values.course,
          graduationSuccessIndicator: values.graduationSuccessIndicator,
          description: values.description,
          country: values.country,
          city: values.city,
          startDate: JSON.stringify(values.startDate),
          endDate: JSON.stringify(values.endDate),
        },
      }),
    );
    const id = data.id;
    const dataToSend = {
      institution: values.institution,
      course: values.course,
      graduationSuccessIndicator: values.graduationSuccessIndicator.key,
      description: values.description,
      country: values.country.key,
      city: values.city,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    };

    console.log("dataToSend : ", dataToSend);
    props.closeModal();
    if (!pending) {
      (async () => {
        try {
          await dispatch(
            PutExperienceThunk({
              dataToSend,
              id,
              cvId: cvId,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ConfirmationModifExpAcademicTitle")}
              secondMessage={t("app.toastMessages.ConfirmationModifExpAcademicMessage")}
            />,
          );
          console.log("Your experience put success !");
        } catch (error) {
          console.log("Your experience put fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: AcademicExperiencesData
      ? {
          ...AcademicExperiencesData,
          startDate: new Date(JSON.parse(AcademicExperiencesData.startDate)),
          endDate: new Date(JSON.parse(AcademicExperiencesData.endDate)),
        }
      : {
          institution: data.institution,
          course: data.course,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          graduationSuccessIndicator: data.graduationSuccessIndicator,
          description: data.description,
          country: data.country,
          city: data.city,
        },
    validationSchema: AcademicExperienceSchema,
    onSubmit,
  });
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <div style={{ flexDirection: "column" }}>
          {isModify ? (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ModiFyAcaExpModal")}</span>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <span style={{ color: "red" }}>{errorMessage}</span>
                <div className={styles.formContainer}>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="institution">{t("app.depotCv.Etablissement")} </label>
                    <input
                      style={
                        errors.institution &&
                        touched.institution && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="institution"
                      type="text"
                      value={values.institution}
                      onChange={(e) => setFieldValue("institution", e.target.value)}
                    />
                    <InputErrorMessage message={errors.institution} touched={touched.institution} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="course">{t("app.depotCv.FormationSuivie")} </label>
                    <input
                      style={
                        errors.course &&
                        touched.course && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="course"
                      type="text"
                      value={values.course}
                      onChange={(e) => setFieldValue("course", e.target.value)}
                    />
                    <InputErrorMessage message={errors.course} touched={touched.course} />
                  </div>

                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="startDate">{t("app.depotCv.DateDebut")} </label>
                    {/* <DatePicker
											style={
												errors.startDate &&
												touched.startDate && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
												}
											}
											value={values.startDate}
											onChange={(value) => setFieldValue("startDate", value)}
										/> */}
                    <DatePickerCustom
                      error={errors.startDate}
                      touched={touched.startDate}
                      value={values.startDate}
                      onChange={(value) => setFieldValue("startDate", value)}
                    />
                    <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="endDate">{t("app.depotCv.DateFin")} </label>
                    {/* <DatePicker
											style={
												errors.endDate &&
												touched.endDate && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
												}
											}
											value={values.endDate}
											onChange={(value) => setFieldValue("endDate", value)}
										/> */}
                    <DatePickerCustom
                      error={errors.endDate}
                      touched={touched.endDate}
                      value={values.endDate}
                      onChange={(value) => setFieldValue("endDate", value)}
                    />
                    <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="graduationSuccessIndicator">{t("app.depotCv.DiplomeObtenu")} </label>
                    <DropdownList
                      style={
                        errors.graduationSuccessIndicator &&
                        touched.graduationSuccessIndicator && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="graduationSuccessIndicator"
                      dataKey="key"
                      textField="label"
                      data={[{ ...objAucun }, ...referencialData?.STUDENT_GRADUATION_STATUS]}
                      value={values.graduationSuccessIndicator}
                      onChange={(newValue) => setFieldValue("graduationSuccessIndicator", newValue)}
                    />
                    <InputErrorMessage
                      message={errors.graduationSuccessIndicator}
                      touched={touched.graduationSuccessIndicator}
                    />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="description">{t("app.depotCv.Description")} </label>
                    <textarea
                      style={
                        errors.description &&
                        touched.description && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="description"
                      value={values.description}
                      onChange={(e) => setFieldValue("description", e.target.value)}
                    />
                    <InputErrorMessage message={errors.description} touched={touched.description} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="country">{t("app.depotCv.Pays")} </label>
                    <DropdownList
                      style={
                        errors.country &&
                        touched.country && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="country"
                      dataKey="key"
                      textField="label"
                      data={[{ ...empty }, ...referencialData?.COUNTRY]}
                      value={values.country}
                      onChange={(newValue) => setFieldValue("country", newValue)}
                    />
                    <InputErrorMessage message={errors.country} touched={touched.country} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="city">{t("app.depotCv.Ville")} </label>
                    <input
                      style={
                        errors.city &&
                        touched.city && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="city"
                      type="text"
                      value={values.city}
                      onChange={(e) => setFieldValue("city", e.target.value)}
                    />
                    <InputErrorMessage message={errors.city} touched={touched.city} />
                  </div>
                  {/* <div className={styles.label_plus_input_container}></div> */}
                </div>
                <div className={styles.submit_button_container}>
                  <button type="submit" className={styles.submit_button}>
                    {t("app.depotCv.ValidateExperience")}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ConsultAcadExp")}</span>
                </div>
              </div>
              <div className={styles.formContainer}>
                <div>
                  <div className={styles.etab}>
                    {t("app.depotCv.Etablissement")}:{data.institution}
                  </div>
                  <div className={styles.institution}>
                    {t("app.depotCv.FormationSuivie")}:{data.course}
                  </div>
                  <div className={styles.diploma}>
                    {t("app.depotCv.DiplomeObtenu")}:{data.graduationSuccessIndicator.label}
                  </div>
                  <div className={styles.date}>
                    {t("app.depotCv.du")} {data.startDate} {t("app.depotCv.au")} {data.endDate}
                  </div>
                  <div className={styles.country}>
                    {data.country.label}-{data.city}
                  </div>
                  <div className={styles.description}>{t("app.depotCv.Description")}</div>
                  <div className={styles.dataDescription}>{data.description}</div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={modifyForm} className={styles.modifyButton}>
                  {t("app.depotCv.ModifyExp")}
                </button>
              </div>
            </>
          )}
        </div>

        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
