import { useEffect, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllComputerSkills,
  getComputerSkillsThunk,
  getComputerSkillsCardsIsLoading,
} from "app/slices/createYourCV/getYourComputerSkills.slice";
import { DropdownList } from "react-widgets";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { createObjAucun } from "utils/localReferentials";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";

import Modal from "../components/ComputerSkillsModal";
import Header from "../../../../../../components/Header/Header";
import styles from "../../../CreateYourCV.module.scss";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import {
  getcreateYourComputerSkillData,
  getcreateYourComputerSkillErrorMessage,
  getcreateYourComputerSkillId,
  getcreateYourComputerSkillPending,
  PostComputerSkillThunk,
} from "../../../../../../app/slices/createYourCV/createComputerSkill.slice";
import SkillCard from "../components/SkillCard";
import { getReferentialsData } from "../../../../../../app/slices/referentials/referentials.slice";
import style from "./AddExperienceBtn.module.scss";

/* eslint-disable */
const ComputerSkills = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourComputerSkillPending);
  const errorMessage = useSelector(getcreateYourComputerSkillErrorMessage);
  const id = useSelector(getcreateYourComputerSkillId);
  const computerSkillsData = useSelector(getcreateYourComputerSkillData);
  const referencialData = useSelector(getReferentialsData);
  const empty = createObjAucun();
  const cvId = useSelector(getcreateYourCVId);
  const [isFinishedAcaProj, setIsFinishedAcaProj] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const getComputerSkillsIsLoading = useSelector(getComputerSkillsCardsIsLoading);
  const computerSkillsList = useSelector(getAllComputerSkills);
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };
  useEffect(() => {
    if (!getComputerSkillsIsLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getComputerSkillsThunk({
                  cvId,
                }),
              ).unwrap();
              console.log("this is the project Data:", computerSkillsList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedAcaProj, isModal]);
  const LanguageSchema = Yup.object().shape({
    domain: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== { ...empty }.key,
    ),
    software: Yup.string().max(255, "Maximum 255 caractères").required(t("app.errorMessages.RemplirChamp")),
  });
  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    dispatch(
      handleStepsData({
        formName: "domain",
        formData: {
          software: values.software,
          domain: values.domain,
        },
      }),
    );

    const dataToSend = {
      software: valuesCopy.software,
      domain: valuesCopy.domain.key,
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedAcaProj(true);
    if (!pending) {
      (async () => {
        if (computerSkillsList.length < 5) {
          try {
            await dispatch(
              PostComputerSkillThunk({
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
                cvId: cvId,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutInformatiqueTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutInformatiqueMessage")}
              />,
            );
            console.log("YourProfile post success !");
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        }
      })();
    }
    resetForm({ values: "" });
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: computerSkillsData
      ? computerSkillsData
      : {
          software: "",
          domain: { ...empty },
        },
    validationSchema: LanguageSchema,
    onSubmit,
  });
  const HandlePushProjectButton = ({ text, disabled }) => {
    return (
      <button
        disabled={disabled}
        style={{ color: disabled ? "grey" : null }}
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
      >
        {text}
      </button>
    );
  };

  const AddProjectBtn = ({ text, disabled }) => {
    return (
      <button
        disabled={disabled}
        style={{ color: disabled ? "grey" : null }}
        type="submit"
        className={style.add_proj_btn}
        onClick={() => setIsFinishedAcaProj(false)}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span>
                  <p className={styles.sub_Title}>{t("app.depotCv.CompetencesInformatiques")}</p>
                </span>
              </div>
              <>
                {computerSkillsList.map((obj, index) => (
                  <SkillCard
                    modifyForm={goToModify}
                    isModify={isModify}
                    handleModalData={handleModalData}
                    {...obj}
                    key={index}
                  />
                ))}
                {isFinishedAcaProj ? (
                  <div className={style.add_proj_btn_container} style={{ width: "auto", alignSelf: "center" }}>
                    <AddProjectBtn
                      disabled={computerSkillsList.length >= 5 ? true : false}
                      text={t("app.depotCv.AjoutCompetence")}
                    />
                  </div>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="domain">{t("app.depotCv.CompetencesInformatiques")}</label>

                      <DropdownList
                        id="domain"
                        dataKey="key"
                        textField="label"
                        data={[{ ...empty }, ...referencialData?.STUDENT_RESUME_COMPUTER_SKILL]}
                        style={
                          errors.domain &&
                          touched.domain && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        value={values.domain}
                        onChange={(newValue) => setFieldValue("domain", newValue)}
                      />
                      <InputErrorMessage message={errors.domain} touched={touched.domain} />
                    </div>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="software">{t("app.librairyCV.software")}</label>
                      <input
                        id="software"
                        style={
                          errors.software && touched.software
                            ? {
                                minHeight: "38px",
                                borderRadius: "4px",
                                color: "#495057",
                                outline: "none",
                                paddingLeft: "13.712px",
                                fontSize: "15px",
                                border: "2px solid #EA001E",
                              }
                            : {
                                minHeight: "38px",
                                borderRadius: "4px",
                                color: "#495057",
                                border: "#ccc 1px solid",
                                outline: "none",
                                paddingLeft: "13.712px",
                                fontSize: "15px",
                              }
                        }
                        value={values.software}
                        onChange={(e) => setFieldValue("software", e.target.value)}
                      />
                      <InputErrorMessage message={errors.software} touched={touched.software} />
                    </div>
                    <div className={style.validate_exp_btn_container}>
                      <HandlePushProjectButton text={t("app.depotCv.ValidezCompetence")} />
                    </div>
                  </div>
                )}
              </>
            </form>
          </section>
        </>
      )}
    </>
  );
};

export default ComputerSkills;
