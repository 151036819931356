import { t } from "i18next";
import { Link } from "react-router-dom";

import { handleOfferCardProps } from "app/slices/followOffers/followOffers.slice";
import { useDispatch } from "react-redux";

import styles from "../AllOffers.module.scss";

/* eslint-disable */
const FollowCard = (props) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.card}>
      <span className={styles.status}>{props?.status?.label}</span>
      <div className={styles.offer_name}>{props?.name}</div>

      <span className={styles.offer_title}>{props?.position?.title}</span>

      <div className={styles.margin_info} />

      <div className={styles.dataDisplay}>
        <div className={styles.dataName}>
          <span className={styles.text}>{t("app.suivezOffres.Date")}</span>
          <span className={styles.data_style}>{props?.availability?.startDate}</span>
        </div>

        <div className={styles.dataName}>
          <span className={styles.text}>{t("app.suivezOffres.Duree")}</span>
          <span className={styles.data_style}>{props?.availability?.contractDuration?.label}</span>
        </div>

        <div className={styles.dataName}>
          <span className={styles.text}>{t("app.suivezOffres.Localisation")}</span>
          <span className={styles.data_style}>{props?.localisation?.country?.label}</span>
        </div>
      </div>

      <div className={styles.margin_info} />

      <Link
        to={`${props.id}`}
        // style={{ backgroundImage: `url(${iconArrowRightImg})` }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          <div className={styles.btn_container} onClick={() => dispatch(handleOfferCardProps(props))}>
            {" "}
            {t("app.suivezOffres.ModifierConsulter")}{" "}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default FollowCard;
