import { t } from "i18next";
import Header from "../../../../../components/Header/Header";

import bgFollowOffreImg from "../../assets/bg-consul-offre.png";
import styles from "./HeaderContract.module.scss";

const HeaderContract = () => (
  <Header
    h1={t("app.autoInscription.Inscription")}
    textElement={(
      <div className={styles.headerContent}>
        <p>{t("app.autoInscription.Etape1")}</p>
        <p>{t("app.autoInscription.Etape2")}</p>
      </div>
    )}
    bgImg={bgFollowOffreImg}
  />
);

export default HeaderContract;
