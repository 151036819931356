import { t } from "i18next";

import styles from "./Modal.module.scss";
import closeIcon from "../../../../assets/close-svg.svg";

/* eslint-disable */
const TeletransmisionModal = (props) => {
  console.log("CLICK MODAL");

  const handleTransmissionChekcBox = (value) => {
    props.setFieldValue("teletransmissionCheckbox", value);
    props.onClose();
  };

  if (props.show)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            {/* <h2 className={styles.modal_title}>Teletransmission TITTLE</h2> */}
            <div className={styles.separator_top} />
            <div className={styles.modal_contentBox}>
              <p className={styles.text_area}>{t("app.suivezVosContratsPartner.OpcoModal")}</p>
            </div>
            <div className={styles.separator_bottom} />

            <div className={styles.button_container}>
              <div className={styles.true_btn} onClick={() => handleTransmissionChekcBox(true)}>
                <p>{t("app.suivezVosContratsPartner.AcceptOCPO")}</p>
              </div>
              <div className={styles.false_btn} onClick={() => handleTransmissionChekcBox(false)}>
                <p>{t("app.suivezVosContratsPartner.DeclineOPCO")}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  if (!props.show) return null;
};

export default TeletransmisionModal;
