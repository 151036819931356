import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  numberOfContracts: 0,
  currentPagination: 1,
  maxPagination: undefined,
  offset: 0,
  endIndex: 20,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getConpanyRecruitmentListThunk = createAsyncThunk(
  "checkYourRecruitments/getCompanyRecruitmentList",
  async ({ axiosCancelToken }, { getState }) => {
    const { offset } = getState().checkYourRecruitments;
    const { endIndex } = getState().checkYourRecruitments;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v2/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const nextPaginationCompanyRecruitmentListThunk = createAsyncThunk(
  "checkYourRecruitments/nextPaginationCompanyRecruitmentListThunk",
  async ({ axiosCancelToken }, { getState }) => {
    const offset = getState().checkYourRecruitments.offset + 20;
    const endIndex = getState().checkYourRecruitments.endIndex + 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v2/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const previousPaginationCompanyRecruitmentListThunk = createAsyncThunk(
  "checkYourRecruitments/previousPaginationCompanyRecruitmentListThunk",
  async ({ axiosCancelToken }, { getState }) => {
    const offset = getState().checkYourRecruitments.offset - 20;
    const endIndex = getState().checkYourRecruitments.endIndex - 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v2/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const patchRecruitmentsThunk = createAsyncThunk(
  "checkYourRecruitments/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsSlice = createSlice({
  name: "checkYourRecruitments",
  initialState,
  reducers: {
    handleRecruitmentPropsData: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    resetRecruitmentState: (state) => {
      state.recruitmentList = [];
      state.numberOfContracts = 0;
      state.currentPagination = 1;
      state.maxPagination = undefined;
      state.offset = 0;
      state.endIndex = 20;
      state.sections = {};
      state.pending = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [getConpanyRecruitmentListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getConpanyRecruitmentListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.recruitmentList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination = 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [getConpanyRecruitmentListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [nextPaginationCompanyRecruitmentListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [nextPaginationCompanyRecruitmentListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.recruitmentList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination += 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.offset += 20;
      state.endIndex += 20;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [nextPaginationCompanyRecruitmentListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [previousPaginationCompanyRecruitmentListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [previousPaginationCompanyRecruitmentListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.recruitmentList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination -= 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.offset -= 20;
      state.endIndex -= 20;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [previousPaginationCompanyRecruitmentListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchRecruitmentsThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getYourRecruitmentPropsData = (state) => state.checkYourRecruitments.recruitmentPropsData;
export const getAllUserRecruitments = (state) => state.checkYourRecruitments.recruitmentList;
export const getUserRecruitmentsPending = (state) => state.checkYourRecruitments.pending;
export const getUserRecruitmentsErrorMessage = (state) => state.checkYourRecruitments.errorMessage;
export const getNumberOfContracts = (state) => state.checkYourRecruitments.numberOfContracts;
export const selectCurrentPaginationOfContracts = (state) => state.checkYourRecruitments.currentPagination;
export const selectMaxPaginationOfContracts = (state) => state.checkYourRecruitments.maxPagination;

// ACTIONS CREATOR EXPORTS SECTION
export const { handleRecruitmentPropsData, resetRecruitmentState } = checkYourRecruitmentsSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsSlice.reducer;
