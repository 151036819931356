import { t } from "i18next";
import ItemSidenav from "../ItemSidenav/ItemSidenav";
import ItemDropdownSidenav from "../ItemDropdownSidenav/ItemDropdownSidenav";

import accueilImg from "../assets/Acceuil.png";
import depotOffreImg from "../assets/Depot_Offre.png";
import cvImg from "../assets/CV.png";
// import contractImg from "../assets/Contract.png";
import studentImg from "../assets/Student.svg";
import offreImg from "../assets/Offre.png";

const SidenavETUContent = () => (
  <>
    <ItemSidenav name={t("app.horizontalNavLoginStudent.PageAccueil")} img={accueilImg} path="/" />
    <ItemDropdownSidenav
      name={t("app.horizontalNavLoginStudent.MesCvs")}
      img={depotOffreImg}
      liList={[
        {
          name: t("app.horizontalNavLoginStudent.DeposezUnCv"),
          path: "deposez-un-cv",
          liImg: depotOffreImg,
        },
        {
          name: t("app.horizontalNavLoginStudent.ConsultezVosCV"),
          path: "mes-cvs",
          liImg: offreImg,
        },
      ]}
    />
    <ItemSidenav
      name={t("app.horizontalNavLoginStudent.ConsultezLesOffres")}
      img={cvImg}
      path="/consultez-les-offres"
    />
    {/* <ItemSidenav
      name={t("app.horizontalNavLoginStudent.ConsultezVosPostulations")}
      img={contractImg}
      path="/consultez-vos-postulations"
    /> */}
    {/* <ItemSidenav
      name={t("app.horizontalNavLoginStudent.SeancesDeCoaching")}
      img={contractImg}
      path="/seance-coaching"
    /> */}
    <ItemDropdownSidenav
      name={t("app.horizontalNavLoginStudent.DeclarezMenu")}
      img={depotOffreImg}
      liList={[
        {
          name: t("app.horizontalNavLoginStudent.DeclarezUnRecrutement"),
          path: "declarezUnRecrutement",
          liImg: depotOffreImg,
        },
        // {
        //   name: t("app.horizontalNavLoginStudent.ConventionDeStage"),
        //   path: "conventionDeStage",
        //   liImg: depotOffreImg,
        // },
      ]}
    />
    <ItemSidenav
      name={t("app.horizontalNavLoginStudent.LivretsApprentissage")}
      img={studentImg}
      path="/livret-dapprentissage"
    />
  </>
);

export default SidenavETUContent;
