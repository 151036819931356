import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getLanguageSkillsThunk = createAsyncThunk("languageSkills/getLanguageSkillsThunk", async (id) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${id}/skills/language`);
  return response.data;
});

export const getLanguageSkillsByIdThunk = createAsyncThunk(
  "languageSkills/getLanguageSkillsByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/skills/language/${ID}`);
    return response.data;
  },
);

export const postLanguageSkillsThunk = createAsyncThunk(
  "languageSkills/postLanguageSkillsThunk",
  async ({ dataToSend, id }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${id}/skills/language`, dataToSend);
    return response.data;
  },
);

export const patchLanguageSkillsThunk = createAsyncThunk(
  "languageSkills/patchLanguageSkillsThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/skills/language/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const languageSkillsSlice = createSlice({
  name: "languageSkills",
  initialState,
  extraReducers: {
    [getLanguageSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getLanguageSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getLanguageSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postLanguageSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postLanguageSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postLanguageSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchLanguageSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchLanguageSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchLanguageSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getLanguageSkillsByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
      return state;
    },
    [getLanguageSkillsByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getLanguageSkillsByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
  },
});

export const getLanguageSkills = (state) => state.languageSkills.data;
export const getLanguageSkillsPending = (state) => state.languageSkills.pending;

export default languageSkillsSlice.reducer;
