import styles from "./ItemStep.module.scss";

const ItemStep = ({ number, h3, textElement }) => (
  <div className={styles.step_container}>
    <div className={styles.title_container}>
      <div>{number}</div>
      <h3>{h3}</h3>
    </div>
    {textElement}
  </div>
);

export default ItemStep;
