import { t } from "i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { DropdownList } from "react-widgets";
import { useEffect, useState } from "react";
import { getcreateYourCVYourCompementarySkillData } from "app/slices/createCompany/createCompany.slice";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { toastSuccess } from "utils/toasts";

import {
  getAllLanguageSkills,
  getLanguageSkillsCardsIsLoading,
  getLanguageSkillsThunk,
} from "app/slices/createYourCV/getYourLanguageSkill.slice";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { createObjAucun } from "utils/localReferentials";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import Header from "../../../../../../components/Header/Header";
import Modal from "../components/LanguageModal";
import LanguageCard from "../components/LanguageCard";
import { getReferentialsData } from "../../../../../../app/slices/referentials/referentials.slice";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import {
  getcreateYourLanguageSkillData,
  getcreateYourLanguageSkillErrorMessage,
  getcreateYourLanguageSkillId,
  getcreateYourLanguageSkillPending,
  PostLanguageSkillThunk,
} from "../../../../../../app/slices/createYourCV/createLanguageSkill.slice";

import style from "./AddExperienceBtn.module.scss";
import styles from "../../../CreateYourCV.module.scss";

/* eslint-disable */
const LanguageSkills = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourLanguageSkillPending);
  const errorMessage = useSelector(getcreateYourLanguageSkillErrorMessage);
  const id = useSelector(getcreateYourLanguageSkillId);
  const languageSkillsData = useSelector(getcreateYourLanguageSkillData);
  const referencialData = useSelector(getReferentialsData);
  const empty = createObjAucun();
  const LanguageSkillList = useSelector(getAllLanguageSkills);
  const getLanguageSkillisLoading = useSelector(getLanguageSkillsCardsIsLoading);
  //   const getProjectLoading = useSelector(getProjectsCardsIsLoading);
  const [isFinishedAcaProj, setIsFinishedAcaProj] = useState(false);
  const cvId = useSelector(getcreateYourCVId);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isValid, setIsValid] = useState(true);
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };
  const Data = useSelector(getcreateYourCVYourCompementarySkillData);
  const handleValidity = () => {
    if (values.frenchLevel.key === { ...empty }.key) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };
  console.log("Fr Data Used", Data);
  useEffect(() => {
    if (!getLanguageSkillisLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getLanguageSkillsThunk({
                  cvId,
                }),
              ).unwrap();
              console.log("this is the project Data:", LanguageSkillList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedAcaProj, isModal]);
  const LanguageSchema = Yup.object().shape({
    lang: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== { ...empty }.key,
    ),
    level: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== { ...empty }.key,
    ),
    other: Yup.string().when("lang", {
      is: (lang) => lang.key === "Autre",
      then: (schema) => schema.max(255, "Maximum 255 caractères").required(t("app.errorMessages.RemplirChamp")),
    }),
  });
  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);
    dispatch(
      handleStepsData({
        formName: "languageSkills",
        formData: {
          lang: values.lang.key,
          // frenchLevel: Data.frenchLevel,
          other: values.other,
          level: values.level,
          certification: values.certification,
        },
      }),
    );

    const dataToSend = {
      lang: valuesCopy.lang.key,
      other: valuesCopy.other,
      level: valuesCopy.level.key,
      certification: valuesCopy.certification,
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedAcaProj(true);
    if (!pending) {
      (async () => {
        if (LanguageSkillList.length < 5) {
          try {
            await dispatch(
              PostLanguageSkillThunk({
                cvId,
                dataToSend,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutLanguageTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutLanguageMessage")}
              />,
            );
            console.log("YourProfile post success !");
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        }
      })();
    }
    resetForm({ values: "" });
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: languageSkillsData
      ? languageSkillsData
      : {
          lang: { ...empty },
          frenchLevel: Data?.frenchLevel ? Data?.frenchLevel : { ...empty },
          other: "",
          level: { ...empty },
          certification: "",
        },
    validationSchema: LanguageSchema,
    onSubmit,
  });
  const HandlePushProjectButton = ({ text }) => {
    return (
      <button
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
      >
        {text}
      </button>
    );
  };

  const AddProjectBtn = ({ text, disabled }) => {
    return (
      <button
        disabled={disabled}
        style={{ color: disabled ? "grey" : null }}
        type="submit"
        className={style.add_proj_btn}
        onClick={() => setIsFinishedAcaProj(false)}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span>
                  <p className={styles.sub_Title}>{t("app.depotCv.MaitriseDesLangues")}</p>
                </span>
              </div>
              <>
                {LanguageSkillList.length > 0 &&
                  LanguageSkillList.map((obj, index) => (
                    <LanguageCard
                      modifyForm={goToModify}
                      isModify={isModify}
                      handleModalData={handleModalData}
                      {...obj}
                      key={index}
                    />
                  ))}
                {isFinishedAcaProj ? (
                  <div className={style.add_proj_btn_container} style={{ width: "auto", alignSelf: "center" }}>
                    <AddProjectBtn
                      disabled={LanguageSkillList.length >= 5 ? true : false}
                      text={t("app.depotCv.AjoutLangue")}
                    />
                  </div>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        alignContent: "space-between",
                      }}
                    >
                      <div className={styles.label_plus_input_container} style={{ width: "100%" }}>
                        <label htmlFor="lang">{t("app.depotCv.Langue")}</label>
                        <DropdownList
                          id="lang"
                          dataKey="key"
                          textField="label"
                          data={[{ ...empty }, ...referencialData?.STUDENT_RESUME_LANGUAGE]}
                          value={values.lang}
                          onChange={(newValue) => setFieldValue("lang", newValue)}
                          style={
                            errors.lang &&
                            touched.lang && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                        />
                        {errors.lang && touched.lang ? (
                          <InputErrorMessage message={errors.lang} touched={touched.lang} />
                        ) : (
                          <div style={{ height: "18px" }} />
                        )}
                      </div>
                      <div
                        className={`${styles.label_plus_input_container} input-like-rw`}
                        style={{ marginLeft: "3%", width: "50%" }}
                      >
                        <label htmlFor="other">{t("app.depotCv.PrecisezSiAutre")}</label>
                        <textarea
                          style={
                            errors.other && touched.other
                              ? {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                  height: 40,
                                }
                              : { height: 40 }
                          }
                          id="other"
                          value={values.other}
                          onChange={(e) => setFieldValue("other", e.target.value)}
                          disabled={values.lang.key === "Autre" ? false : true}
                        />
                        {errors.other && touched.other ? (
                          <InputErrorMessage message={errors.other} touched={touched.other} />
                        ) : (
                          <div style={{ height: "18px" }} />
                        )}
                      </div>
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="level">{t("app.depotCv.Niveau")}</label>
                      <DropdownList
                        style={
                          errors.level &&
                          touched.level && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="level"
                        dataKey="key"
                        textField="label"
                        data={[{ ...empty }, ...referencialData?.LANGUAGE_LEVEL]}
                        value={values.level}
                        onChange={(newValue) => setFieldValue("level", newValue)}
                      />
                      <InputErrorMessage message={errors.level} touched={touched.level} />
                    </div>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="certification">{t("app.depotCv.CertificationsLinguistiques")}</label>
                      <textarea
                        style={
                          errors.certification &&
                          touched.certification && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="certification"
                        value={values.certification}
                        onChange={(e) => setFieldValue("certification", e.target.value)}
                      />
                      <InputErrorMessage message={errors.certification} touched={touched.certification} />
                    </div>
                    <div className={style.validate_exp_btn_container}>
                      <HandlePushProjectButton text={t("app.depotCv.ValidezLangue")} />
                    </div>
                    <div
                      style={{
                        background: "grey",
                        height: "1px",
                        maxWidth: "320px",
                        width: "100%",
                        margin: "10px",
                      }}
                    />
                  </div>
                )}
                <div className={styles.inputs_container}>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="frenchLevel">{t("app.librairyCV.frenchLevel")}</label>
                    <DropdownList
                      style={
                        isValid === false
                          ? {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          : null
                      }
                      id="frenchLevel"
                      dataKey="key"
                      textField="label"
                      data={[{ ...empty }, ...referencialData?.LANGUAGE_LEVEL]}
                      value={values.frenchLevel}
                      onBlur={() => {
                        props.handleCallBack(values.frenchLevel);
                        handleValidity();
                      }}
                      onChange={(newValue) => {
                        setFieldValue("frenchLevel", newValue);
                      }}
                    />
                    {isValid === false && <span className={styles.message}>{t("app.errorMessages.RemplirChamp")}</span>}
                  </div>
                </div>
              </>
            </form>
          </section>
        </>
      )}
    </>
  );
};

export default LanguageSkills;
