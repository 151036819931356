import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  ProfessionalExperiencesList: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getProfessionalExperiencesThunk = createAsyncThunk(
  "getYourProfessionalExperiences/getProfessionalExperiencesCardsList",
  async ({ cvId }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${cvId}/experiences/professional`);
    return response.data;
  },
);

// CREATESLICE SECTION

const getYourProfessionalExperiencesSlice = createSlice({
  name: "getYourProfessionalExperiences",
  initialState,
  extraReducers: {
    [getProfessionalExperiencesThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getProfessionalExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.ProfessionalExperiencesList = action.payload;
    },
    [getProfessionalExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getAllProfessionalExperiences = (state) => (
  state.getYourProfessionalExperiences.ProfessionalExperiencesList
);
export const getProfessionalExperiencesCardsIsLoading = (state) => state.getYourProfessionalExperiences.pending;
export const getProfessionalExperiencesCardsErrorMessage = (state) => state.getYourProfessionalExperiences.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getYourProfessionalExperiencesSlice.reducer;
