import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION

export const PostAssoThunk = createAsyncThunk(
  "createAsso/PostAssoThunk",
  async ({ dataToSend, cvId }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/experiences/associative`, dataToSend);
    return response.data;
  },
);

export const putAssoThunk = createAsyncThunk(
  "createYourProject/patchProjectThunk",
  async ({ dataToSend, cvId, id }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${cvId}/experiences/associative/${id}`, dataToSend);
    return response.data;
  },
);

// CREATESLICE SECTION
const createYourAssoSlice = createSlice({
  name: "createYourAsso",
  initialState,
  reducers: {},
  extraReducers: {
    [PostAssoThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostAssoThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostAssoThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [putAssoThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [putAssoThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [putAssoThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourAssoPending = (state) => state.createYourAsso.pending;
export const getcreateYourAssoErrorMessage = (state) => state.createYourAsso.errorMessage;
export const getcreateYourAssoId = (state) => state.createYourAsso.id;
export const getcreateYourAssoData = (state) => state.createYourAsso.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourAssoSlice.reducer;
