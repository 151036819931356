import { t } from "i18next";

import { useState, useRef } from "react";

import UploadOrganism from "pages/ETU/DeclareARecruitment/components/UploadOrganism/UploadOrganism";
import { getDeclareARecruitmentPreContractualizationInformations } from "app/slices/declareARecruitment/declareARecruitment.slice";

import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";
import { useSelector, useDispatch } from "react-redux";
import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";

import closeIcon from "../../../../assets/close-svg.svg";

import styles from "./FileModal.module.scss";

/* eslint-disable */
const FileModal = (props) => {
  //console.log("CLICK MODAL");
  const [uploadStatus, setUploadStatus] = useState("");
  const [noFileHandler, setNoFileHandler] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [uselessShow, setUselessShow] = useState(false);
  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  const filePending = useSelector(getUploadFilePending);
  const fileError = useSelector(getUploadFIleErrorMessage);

  const [fileSuccess, setFileSuccess] = useState(false);

  const [fileLoadModal, setFileLoadModal] = useState({});

  const fileRef = useRef(null);

  const setFileFunction = (file) => {
    props.setFile(file);
    setFileLoadModal(file);
  };

  const handleSubmitFile = (endpoint) => {
    if (props.actualFile) {
      console.log("YES FILE MODAL", props.actualFile);
      // setNoFileHandler(false);
      setShowLoadingModal(true);

      if (!filePending) {
        (async () => {
          try {
            await dispatch(
              postFileThunk({
                file: props.actualFile,
                endpoint,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("upload success TestUploadFile !");
            if (props.forOutEu) {
              props.setRightToWorkInFrance(true);
            }
            //setUploadStatus("success");
            setFileSuccess(true);
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.genericSuccess")}
                secondMessage={props.actualFile.name}
              />,
            );
          } catch (error) {
            setFileSuccess(false);
            if (props.forOutEu) {
              props.setRightToWorkInFrance(false);
            }
            toastError(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.genericError")}
                secondMessage={props.actualFile.name}
              />,
            );

            console.log("upload fail TestUploadFile !", error.message);
            //setUploadStatus("failed");
          }
        })();

        if (props.both) {
          console.log("BOTH MODAL");
          if (props.first) {
            //props.setSecondModal(true);
            //props.setShow(false);
          } else if (props.second) {
            props.setPathIndicator("MODAL");
          }
        } else {
          props.setPathIndicator("MODAL");
          //props.summit(props.values);
        }
      } else {
        setNoFileHandler(true);
        //Put in place when an error handling will be decided
      }
    }
  };

  const handleCloseLoadingModal = () => {
    if (props.both) {
      console.log("LAST MODAL BOTH");
      if (props.first) {
        console.log("LAST MODAL FIRST");

        props.setShow(false);
        setShowLoadingModal(false);

        props.setShowSecondModal(true);
      } else if (props.second) {
        console.log("LAST MODAL SECOND");

        props.summit(props.fileValue);
      }
      //else if (!props.second) props.displaySecondStep(true)
    } else {
      console.log("LAST MODAL ALONE");

      props.setShow(false);
      props.summit(props.fileValue);
      setShowLoadingModal(false);
    }

    setFileSuccess(false);
  };

  if (props.show)
    return (
      <>
        <div className={styles.modal}>
          <LoadingModal
            setShow={setShowLoadingModal}
            show={showLoadingModal}
            file={{
              name: fileLoadModal?.name,
              type: fileLoadModal?.type,
              size: fileLoadModal?.size,
            }}
            pending={filePending}
            error={fileError}
            handleClose={handleCloseLoadingModal}
          />
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.declarezUnRecrutement.DeclarerUnRecrutement")}</h2>
            <div className={styles.separator_top} />
            {props.appStudient && !props.forOutEu && (
              <p className={styles.text_area}>{t("app.declarezUnRecrutement.ChargerFormulaireRupture")}</p>
            )}
            <div className={styles.modal_contentBox}>
              <div>
                {props.appStudient && !props.forOutEu && (
                  <p className={styles.text_file_area}>{t("app.declarezUnRecrutement.DeposezFormulaireRupture")}</p>
                )}
                {props.forOutEu && !props.appStudient && (
                  <p className={styles.text_file_area}>
                    {t("app.declarezUnRecrutement.DeposezUneCopieDuTitreDeSejour")}
                  </p>
                )}
                <UploadOrganism disabled={false} setFile={setFileFunction} setDisplayModal={setUselessShow} />
              </div>
            </div>
            <div className={styles.footer_modal}>
              <button
                className={styles.close_btn}
                type="button"
                onClick={() =>
                  handleSubmitFile(
                    `${
                      process.env.REACT_APP_MIDDLEWARE_COMPANY
                    }/v1/companies/recruitments/apprenticeships/pre-contractualizations/${
                      preContractualizationInformations.id
                    }/supporting-documents/${props.forOutEu ? "WORK_PERMIT" : "BREACH_CONTRACT"}`,
                  )
                }
              >
                {t("app.declarezUnRecrutement.ValidezEtPassezAEtapeSuivante")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  if (!props.show) return null;
};

export default FileModal;
