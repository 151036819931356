import { t } from "i18next";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { createObjAucun, createObjAucunIdName } from "utils/localReferentials";
import { Listbox, DropdownList } from "react-widgets/cjs";
import {
  getPublishAnOfferPending,
  getPublishAnOfferErrorMessage,
  postStep1Thunk,
  patchStepThunk,
  getPublishAnOfferId,
  getPublishAnOfferYourNeedData,
  handleStepsData,
} from "../../../../../app/slices/publishAnOffer/publishAnOffer.slice";
import { getReferentialsData, getTrainingSchoolsData } from "../../../../../app/slices/referentials/referentials.slice";

import Header from "../../../../../components/Header/Header";
import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../../PublishAnOffer.module.scss";

const YourNeed = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();
  const pending = useSelector(getPublishAnOfferPending);
  const errorMessage = useSelector(getPublishAnOfferErrorMessage);
  const id = useSelector(getPublishAnOfferId);
  const yourNeedData = useSelector(getPublishAnOfferYourNeedData);
  const referentials = useSelector(getReferentialsData);
  const trainingSchools = useSelector(getTrainingSchoolsData);
  console.log("trainingSchools", trainingSchools);

  const objAucun = createObjAucun();
  const objAucunIdName = createObjAucunIdName();

  // Yup validation schema
  const yourNeedSchema = Yup.object().shape({
    countryOfThePosition: Yup.object(),
    typeOfContract: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    targetLevelOfStudy: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    companyLineOfBusiness: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    department: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    doNotKnow: Yup.boolean().when([], {
      is: () => values?.whichSchool?.id === "-Aucun-",
      then: () => Yup.boolean().oneOf([true], t("app.errorMessages.RemplirChamp")),
    }),
    whichSchool: Yup.object().when("doNotKnow", {
      is: (doNotKnow) => !doNotKnow,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.id !== "-Aucun-"),
    }),
  });

  // onSubmit form function
  const onSubmit = (values) => {
    dispatch(
      handleStepsData({
        formName: "yourNeed",
        formData: values,
      }),
    );

    const dataToSend = {
      localisation: {
        country: values.countryOfThePosition.key,
      },
      contractTypes: values.typeOfContract.map((obj) => obj.key),
      requirements: {
        educationLevels: values.targetLevelOfStudy.map((obj) => obj.key),
        trainingSchoolId: values.whichSchool.id === "-Aucun-" ? null : values.whichSchool.id,
        needHelpTrainingSchool: values.doNotKnow,
      },
      position: {
        sector: values.companyLineOfBusiness.key,
        subSector: values.department.key,
      },
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      (async () => {
        try {
          if (!id) {
            await dispatch(
              postStep1Thunk({
                dataToSend,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourNeed post success !");
          } else {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourNeed put success !");
          }
        } catch (error) {
          console.log("YourNeed post fail !", error.message);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: yourNeedData
      ? yourNeedData
      : {
        countryOfThePosition: { key: "FR" },
        typeOfContract: [],
        targetLevelOfStudy: [],
        companyLineOfBusiness: { ...objAucun },
        department: { ...objAucun },
        whichSchool: { ...objAucunIdName },
        doNotKnow: false,
      },
    validationSchema: yourNeedSchema,
    onSubmit,
  });

  return (
    <>
      {pending && referentials && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && referentials && trainingSchools && (
        <>
          <Header
            h1={t("app.depotOffre.DeposezUneOffre")}
            textElement={(
              <p>
                {t("app.depotOffre.Description1_part1")}
                <br /> {t("app.depotOffre.Description1_part2")}
              </p>
            )}
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <div className={styles.title_container}>
                  <h2>{t("app.depotOffre.VotreBesoin")}</h2>
                  <div>{t("app.depotOffre.Etape1Sur3")}</div>
                </div>
                <form className={styles.your_need_form} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="countryOfThePosition">{t("app.depotOffre.LieuDuPoste")}</label>
                      <DropdownList
                        id="countryOfThePosition"
                        dataKey="key"
                        textField="label"
                        data={referentials.COUNTRY}
                        value={values.countryOfThePosition}
                        onChange={(newValue) => {
                          setFieldValue("countryOfThePosition", newValue);
                          setFieldValue("typeOfContract", []);
                        }}
                      />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="typeOfContract">{t("app.depotOffre.TypeDeContrat")}</label>
                      <Listbox
                        style={
                          errors.typeOfContract &&
                          touched.typeOfContract && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="typeOfContract"
                        multiple
                        dataKey="key"
                        textField="label"
                        data={
                          values.countryOfThePosition.key === "FR"
                            ? referentials.CONTRACT_TYPE
                            : referentials.CONTRACT_TYPE.filter((obj) => obj.key !== "Alternance")
                        }
                        value={values.typeOfContract}
                        onChange={(newValue) => setFieldValue("typeOfContract", newValue)}
                      />
                      <InputErrorMessage message={errors.typeOfContract} touched={touched.typeOfContract} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="targetLevelOfStudy">{t("app.depotOffre.NiveauEtude")}</label>
                      <Listbox
                        style={
                          errors.targetLevelOfStudy &&
                          touched.targetLevelOfStudy && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="targetLevelOfStudy"
                        multiple
                        dataKey="key"
                        textField="label"
                        data={referentials.STUDENT_EDUCATION_LEVEL}
                        value={values.targetLevelOfStudy}
                        onChange={(newValue) => setFieldValue("targetLevelOfStudy", newValue)}
                      />
                      <InputErrorMessage message={errors.targetLevelOfStudy} touched={touched.targetLevelOfStudy} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="companyLineOfBusiness">{t("app.depotOffre.SecteurActivite")}</label>
                      <DropdownList
                        style={
                          errors.companyLineOfBusiness &&
                          touched.companyLineOfBusiness && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="companyLineOfBusiness"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.COMPANY_JOB_OFFER_SECTOR && [
                            { ...objAucun },
                            ...referentials.COMPANY_JOB_OFFER_SECTOR,
                          ]
                        }
                        value={values.companyLineOfBusiness}
                        onChange={(newValue) => setFieldValue("companyLineOfBusiness", newValue)}
                      />
                      <InputErrorMessage
                        message={errors.companyLineOfBusiness}
                        touched={touched.companyLineOfBusiness}
                      />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="department">{t("app.depotOffre.Metier")}</label>
                      <DropdownList
                        style={
                          errors.department &&
                          touched.department && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="department"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.COMPANY_JOB_OFFER_SUBSECTOR && [
                            { ...objAucun },
                            ...referentials.COMPANY_JOB_OFFER_SUBSECTOR,
                          ]
                        }
                        value={values.department}
                        onChange={(newValue) => setFieldValue("department", newValue)}
                      />
                      <InputErrorMessage message={errors.department} touched={touched.department} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="whichSchool">{t("app.depotOffre.EcoleOffre")}</label>
                      <DropdownList
                        style={
                          errors.whichSchool &&
                          touched.whichSchool && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="whichSchool"
                        dataKey="id"
                        textField="name"
                        data={trainingSchools && [{ ...objAucunIdName }, ...trainingSchools]}
                        value={values.whichSchool}
                        onChange={(newValue) => setFieldValue("whichSchool", newValue)}
                      />
                      <InputErrorMessage message={errors.whichSchool} touched={touched.whichSchool} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="doNotKnow">{t("app.depotOffre.JeNeSaisPas")}</label>
                      <div className={styles.checkbox_container}>
                        <input
                          style={
                            errors.doNotKnow &&
                            touched.doNotKnow && {
                              outline: "2px solid #EA001E",
                              borderRadius: "50%",
                              outlineOffset: "-1.9px",
                            }
                          }
                          id="doNotKnow"
                          type="checkbox"
                          checked={values.doNotKnow}
                          onChange={(e) => setFieldValue("doNotKnow", e.target.checked)}
                        />
                      </div>
                      <InputErrorMessage message={errors.doNotKnow} touched={touched.doNotKnow} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.depotOffre.ValidezEtapeSuivante")} />
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default YourNeed;
