import { t } from "i18next";

import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  putThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  getLearningBookletsErrorMessage,
  getLearningBookletsListData,
} from "app/slices/learningBooklets/learningBooklets.slice";

import Header from "components/Header/Header";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../SelectedBookletETU.module.scss";

const EndTermEvalSecondYearETU = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const listData = useSelector(getLearningBookletsListData);

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);
  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const referentials = useSelector(getReferentialsData);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("BOOKLET DATA", idData, listData);

  // Yup validation schema
  const formSchema = Yup.object().shape({
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  // onSubmit form function
  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Signé par l'étudiant",
      comment: values.studentComment ? values.studentComment : null,
      signature: values.signature,
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/livret-dapprentissage/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      interpersonalSkills: idData?.interpersonalSkills?.label ? idData?.interpersonalSkills?.label : "",
      teamSpirit: idData?.teamSpirit?.label ? idData?.teamSpirit?.label : "",
      adaptability: idData?.adaptability?.label ? idData?.adaptability?.label : "",
      initiative: idData?.initiative?.label ? idData?.initiative?.label : "",
      standardAndRegulationRespect: idData?.standardAndRegulationRespect?.label
        ? idData?.standardAndRegulationRespect?.label
        : "",
      planificationOrganisation: idData?.planificationOrganisation?.label
        ? idData?.planificationOrganisation?.label
        : "",
      autoControl: idData?.autoControl?.label ? idData?.autoControl?.label : "",
      reporting: idData?.reporting?.label ? idData?.reporting?.label : "",
      communicationSkills: idData?.communicationSkills?.label ? idData?.communicationSkills?.label : "",
      mobilizationAbility: idData?.mobilizationAbility?.label ? idData?.mobilizationAbility?.label : "",
      knowledgeAquisition: idData?.knowledgeAcquisition?.label ? idData?.knowledgeAcquisition?.label : "",
      personalOrganizationWork: idData?.personalOrganizationWork?.label ? idData?.personalOrganizationWork?.label : "",
      toolsAndMethodsMastery: idData?.toolsAndMethodsMastery?.label ? idData?.toolsAndMethodsMastery?.label : "",
      workQuality: idData?.workQuality?.label ? idData?.workQuality?.label : "",
      deadlinesRespect: idData?.deadlinesRespect?.label ? idData?.deadlinesRespect?.label : "",
      companyTutorComment: idData?.companyTutorComment ? idData?.companyTutorComment : "",
      studentComment: idData?.studentComment ? idData?.studentComment : "",
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  /* eslint-disable */
  return (
    <>
      {referentials && (
        <>
          <Header
            h1={t("app.m2EvalFinal.Suivez")}
            textElement={
              <div className={styles.headerContent}>
                <p>
                  {t("app.m2EvalFinal.Intro1")} <br /> <br />
                  {t("app.m2EvalFinal.Intro2")}
                  <br />
                  {t("app.m2EvalFinal.Intro3")}
                  <br /> <br />
                  {t("app.m2EvalFinal.Intro4")}
                  <br />
                  {t("app.m2EvalFinal.Intro5")}
                  <br /> <br />
                  {t("app.m2EvalFinal.Intro6")} <br />
                  <ul>
                    <li>{t("app.m2EvalFinal.NonAcquis")}</li>
                    <li>{t("app.m2EvalFinal.EnCoursDacquisition")}</li>
                    <li>{t("app.m2EvalFinal.Acquis")}</li>
                    <li>{t("app.m2EvalFinal.Expert")}</li>
                  </ul>
                </p>
              </div>
            }
            bgImg={bgConsulOffreImg}
          />
          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <form className={styles.the_form} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <h3>{t("app.m2EvalFinal.CompetencesPerso")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Relationnel")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.RelationnelDesc")}</p>
                      <p className={styles.p_text_value}>{values?.interpersonalSkills}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.EspritDequipe")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.EquipeDesc")}</p>
                      <p className={styles.p_text_value}>{values?.teamSpirit}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Adaptabilite")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.AdaptabiliteDesc")}</p>
                      <p className={styles.p_text_value}>{values?.adaptability}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Initiative")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.InitiativeDesc")}</p>
                      <p className={styles.p_text_value}>{values?.initiative}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Respect")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.RespectDesc")}</p>
                      <p className={styles.p_text_value}>{values?.standardAndRegulationRespect}</p>
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.m2EvalFinal.CompetencesPro")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Planification")}</label>
                      <p className={styles.p_text}> {t("app.m2EvalFinal.PlanificationDesc")}</p>
                      <p className={styles.p_text_value}>{values?.planificationOrganisation}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Controle")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.ControleDesc")}</p>
                      <p className={styles.p_text_value}>{values?.autoControl}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Reporting")}</label>
                      <p className={styles.p_text}> {t("app.m2EvalFinal.ReportingDesc")}</p>
                      <p className={styles.p_text_value}>{values?.reporting}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.QualiteComm")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.QualiteCommDesc")}</p>
                      <p className={styles.p_text_value}>{values?.communicationSkills}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Mobilise")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.MobiliseDesc")}</p>
                      <p className={styles.p_text_value}>{values?.mobilizationAbility}</p>
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.m2EvalFinal.CompetenceTech")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Connaissances")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.ConnaissancesDesc")}</p>
                      <p className={styles.p_text_value}>{values?.knowledgeAquisition}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}> {t("app.m2EvalFinal.OrgaPerso")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.OrgaPersoDesc")}</p>
                      <p className={styles.p_text_value}>{values?.personalOrganizationWork}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Outils")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.OutilsDesc")}</p>
                      <p className={styles.p_text_value}>{values?.toolsAndMethodsMastery}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.QualiteTravail")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.QualiteTravailDesc")}</p>
                      <p className={styles.p_text_value}>{values?.workQuality}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Delais")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.DelaisDesc")}</p>
                      <p className={styles.p_text_value}>{values?.deadlinesRespect}</p>
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="companyTutorComment" className={styles.label_text}>
                        {t("app.m2EvalFinal.Commentaires")}
                      </label>
                      <textarea
                        disabled
                        style={
                          errors.companyTutorComment &&
                          touched.companyTutorComment && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="companyTutorComment"
                        rows="5"
                        maxLength={32768}
                        value={values.companyTutorComment}
                        onChange={(e) => setFieldValue("companyTutorComment", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.companyTutorComment} touched={touched.companyTutorComment} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="studentComment">{t("app.m2EvalFinal.CommentairesPreconisations")}</label>
                      <textarea
                        style={
                          errors.studentComment &&
                          touched.studentComment && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="studentComment"
                        rows="5"
                        maxLength={32768}
                        value={values.studentComment}
                        onChange={(e) => setFieldValue("studentComment", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.studentComment} touched={touched.studentComment} />
                    </div>
                    <div className={styles.checkbox_container}>
                      <label htmlFor="signature" className={styles.label_text}>
                        {t("app.m2EvalFinal.Signature")}
                      </label>
                      <input
                        id="signature"
                        type="checkbox"
                        checked={values.signature}
                        onChange={(e) => setFieldValue("signature", e.target.checked)}
                      />
                      <InputErrorMessage message={errors.signature} touched={touched.signature} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.formulairesLivretApprentissage.BoutonValider")} />
                  {/* <BackButton
										text={t("app.formulairesLivretApprentissage.BoutonRetour")}
									/> */}
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EndTermEvalSecondYearETU;
