import DivLine from "components/DivLine/DivLine";
import { t } from "i18next";
import styles from "./RelationEnt.module.scss";

// {
//   t("app.faqEntreprise.Question2");
// }

const RelationEnt = () => (
  <div className={styles.wrapper}>
    <section>
      <div className={styles.title}>
        <DivLine />
        <h2>{t("app.ourServices.jobDatingPersoTitre")}</h2>
      </div>
      <p>{t("app.ourServices.jobDatingPersoDescription")}</p>
      <ol>
        <li>{t("app.ourServices.jobDatingPersoList1")}</li>
        <li>{t("app.ourServices.jobDatingPersoList2")}</li>
        <li> {t("app.ourServices.jobDatingPersoList3")}</li>
        <li>{t("app.ourServices.jobDatingPersoList4")}</li>
        <li> {t("app.ourServices.jobDatingPersoList5")}</li>
        <li>{t("app.ourServices.jobDatingPersoList6")}</li>
        <li> {t("app.ourServices.jobDatingPersoList7")}</li>
        <li>{t("app.ourServices.jobDatingPersoList8")}</li>
      </ol>
    </section>
    <section className={styles.sectionBg}>
      <div className={styles.title}>
        <DivLine />
        <h2>{t("app.ourServices.sourcingTitre")}</h2>
      </div>
      <p>{t("app.ourServices.sourcingDescription")}</p>
      <ol>
        <li>{t("app.ourServices.sourcingList1")}</li>
        <li>{t("app.ourServices.sourcingList2")}</li>
        <li>{t("app.ourServices.sourcingList3")}</li>
        <li>{t("app.ourServices.sourcingList4")}</li>
        <li>{t("app.ourServices.sourcingList5")}</li>
        <li>{t("app.ourServices.sourcingList6")}</li>
      </ol>
    </section>
    <section>
      <div className={styles.title}>
        <DivLine />
        <h2>{t("app.ourServices.matchingTitre")}</h2>
        <span>{t("app.ourServices.DisponibleAPartir")}</span>
      </div>
      <p>{t("app.ourServices.matchingDescription")}</p>
      <ol>
        <li>{t("app.ourServices.matchingList1")}</li>
        <li>{t("app.ourServices.matchingList2")}</li>
        <li>{t("app.ourServices.matchingList3")}</li>
        <li>{t("app.ourServices.matchingList4")}</li>
        <li>{t("app.ourServices.matchingList5")}</li>
      </ol>
    </section>
  </div>
);

export default RelationEnt;
