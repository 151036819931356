import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "./services/internationalisation/config";

import { STUDENT_SUBDOMAIN_NAME, COMPANY_SUBDOMAIN_NAME } from "utils/globalConstantsAndURL";
import { store } from "./app/store";

import App from "./App";

import "./index.scss";

import reportWebVitals from "./reportWebVitals";

// Logic for impersonate
export const impersonateQueryName = "impersonate";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
if (urlParams.get(impersonateQueryName)) {
  const impersonate = urlParams.get(impersonateQueryName);
  console.log("impersonate", impersonate);
  sessionStorage.setItem(impersonateQueryName, impersonate);
  console.log("sessionStorage impersonate", sessionStorage.getItem(impersonateQueryName));
}

// Logic setup for auth property in PublicClientApplication
const subdomain = window.location.host.split(".")[0];
const auth = {};
if (subdomain === STUDENT_SUBDOMAIN_NAME && !sessionStorage.getItem(impersonateQueryName)) {
  auth.clientId = process.env.REACT_APP_AAD_ETU_CLIENT_ID;
  auth.authority = `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_ETU_TENANT_ID}`;
  auth.redirectUri = process.env.REACT_APP_AAD_ETU_REDIRECT_URI;
} else if (subdomain === COMPANY_SUBDOMAIN_NAME && !sessionStorage.getItem(impersonateQueryName)) {
  auth.clientId = process.env.REACT_APP_AAD_ENT_CLIENT_ID;
  auth.authority = `${process.env.REACT_APP_AAD_ENT_AUTHORITY}`;
  auth.knownAuthorities = [`${process.env.REACT_APP_AAD_ENT_KNOWN_AUTHORITIES}`];
  auth.redirectUri = process.env.REACT_APP_AAD_ENT_REDIRECT_URI;
} else if (sessionStorage.getItem(impersonateQueryName)) {
  let redirectUri = "";
  if (subdomain === STUDENT_SUBDOMAIN_NAME) {
    redirectUri = process.env.REACT_APP_AAD_ETU_REDIRECT_URI;
    console.log("login as redirectURI");
  } else if (subdomain === COMPANY_SUBDOMAIN_NAME) {
    redirectUri = process.env.REACT_APP_AAD_ENT_REDIRECT_URI;
    console.log("login as redirectURI");
  }

  auth.clientId = process.env.REACT_APP_AAD_COSMOS_CLIENT_ID;
  auth.authority = `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_COSMOS_TENANT_ID}`;
  auth.redirectUri = redirectUri;
}

// Instantiation of PublicClientApplication
export const pca = new PublicClientApplication({
  auth,
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        console.log(message);
      },
      logLevel: "Verbose",
    },
  },
});

// Check and clean any conflict in msal redirect cache
pca
  .handleRedirectPromise()
  .then((res) => {
    console.log("handleRedirectPromise.then in index.js", res);
  })
  .catch((err) => {
    console.log("handleRedirectPromise.catch in index.js", err);
  });

// Callback logic of Msal
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    console.log("event LOGIN_SUCCESS in addEventCallback", event);
    pca.setActiveAccount(event.payload.account);
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    console.log("LOGOUT_SUCCESS", event);
    sessionStorage.removeItem(impersonateQueryName);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
