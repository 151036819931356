import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  ComputerSkillsList: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getComputerSkillsThunk = createAsyncThunk(
  "getYourComputerSkills/getComputerSkillsCardsList",
  async ({ cvId }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${cvId}/skills/computer`);
    return response.data;
  },
);

// CREATESLICE SECTION

const getYourComputerSkillsSlice = createSlice({
  name: "getYourComputerSkills",
  initialState,
  extraReducers: {
    [getComputerSkillsThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getComputerSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.ComputerSkillsList = action.payload;
    },
    [getComputerSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getAllComputerSkills = (state) => state.getYourComputerSkills.ComputerSkillsList;
export const getComputerSkillsCardsIsLoading = (state) => state.getYourComputerSkills.pending;
export const getComputerSkillsCardsErrorMessage = (state) => state.getYourComputerSkills.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getYourComputerSkillsSlice.reducer;
