import { t } from "i18next";

import ConfirmationFormsPage from "components/ConfirmationFormsPage/ConfirmationFormsPage";
import {
  DISCOVER_OMNES_EDUCATION_URL,
  DISCOVER_OUR_SCHOOLS_URL,
  DISCOVER_LEARNING_TAXE_URL,
} from "utils/globalConstantsAndURL";

import bgImg from "./assets/bg-consul-offre.png";
import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

import styles from "./ConfirmationBooklet.module.scss";

const ConfirmationBooklet = () => (
  <div className={styles.wrapper}>
    <ConfirmationFormsPage
      h1={t("app.depotOffre.Confirmation")}
      textElement={<p>{t("app.formulairesLivretConfirmation.success")}</p>}
      bgImg={bgImg}
      leftBtn={{
        text: t("app.formulairesLivretConfirmation.SuivezAlternants"),
        path: "/suivez-vos-alternants",
      }}
      rightBtn={{
        text: t("app.formulairesLivretConfirmation.Acceuil"),
        path: "/",
      }}
      h2={t("app.depotOffre.MieuxConnaitre")}
      cardsArray={[
        {
          path: DISCOVER_OMNES_EDUCATION_URL,
          img: cardKnow3Img,
          h3: t("app.depotOffre.OMNES"),
          text: t("app.SuivezVosContrats.NousConnaitre"),
        },
        {
          path: DISCOVER_OUR_SCHOOLS_URL,
          img: cardKnow2Img,
          h3: t("app.depotOffre.NosEcole"),
          text: t("app.SuivezVosContrats.NousConnaitre"),
        },
        {
          path: DISCOVER_LEARNING_TAXE_URL,
          img: cardKnow3Img,
          h3: t("app.depotOffre.TaxeApprentissage"),
          text: t("app.SuivezVosContrats.NousConnaitre"),
        },
      ]}
    />
  </div>
);

export default ConfirmationBooklet;
