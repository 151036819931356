import { createSlice } from "@reduxjs/toolkit";

// Initial State declaration
const initialState = window.innerWidth;

// CREATESLICE SECTION

const deviceWidthSlice = createSlice({
  name: "deviceWidth",
  initialState,
  reducers: {
    changeDeviceWidth: (state) => {
      state = window.innerWidth;
      return state;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getDeviceWidth = (state) => state.deviceWidth;

// ACTIONS CREATOR EXPORTS SECTION

export const { changeDeviceWidth } = deviceWidthSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default deviceWidthSlice.reducer;
