import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getMeData } from "app/slices/userConnexion/userConnexion.slice";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { DropdownList } from "react-widgets/cjs";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";

import { getEmployeesThunk, getEmployeeDetails } from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import { createBooleanReferential, createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form9.module.scss";

/* eslint-disable */
const dropDownData = {
  sameAdress: [
    { key: "-Aucun-", label: "-Aucun-" },
    { key: "Oui", label: "Oui" },
    { key: "Non", label: "Non" },
  ],
  takeInfoFrom: [
    { key: "-Aucun-", label: "-Aucun-" },
    { key: "Contact signataire", label: "Contact signataire" },
    {
      key: "Contact Taxe d'apprendtissage",
      label: "Contact Taxe d'apprendtissage",
    },
    {
      key: "Contact établissement du contrat",
      label: "Contact établissement du contrat",
    },
    { key: "Contact administratif", label: "Contact administratif" },
    {
      key: "Contact facturation",
      label: "Contact facturation",
    },
    { key: "Aucun", label: "Aucun" },
  ],
  civility: [
    { key: "-Aucun-", label: "-Aucun-" },
    { key: "Madame", label: "Madame" },
    { key: "Monsieur", label: "Monsieur" },
  ],
  YesNo: [
    { key: "-Aucun-", label: "-Aucun-" },
    { key: "Oui", label: "Oui" },
    { key: "Non", label: "Non" },
  ],
};

const Form9 = (props) => {
  const dispatch = useDispatch();

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const TAKE_INFO_FROM = "INFO_FROM";
  const INFO_ORDER_BILL = "ORDER_BILL";

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const employeeDetails = useSelector(getEmployeeDetails);
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  //const myRecruitProps = recruitmentPropsGeneral[0];
  const myInfo = useSelector(getMeData);
  console.log("GetMe", myInfo);
  const [takeInfoFromDisable, setTakeInfoFromDisable] = useState(false);
  const [takeInfoFromMailDisable, setTakeInfoFromMailDisable] = useState(false);
  const [EmployeeId, setEmployeeId] = useState(null);
  const [billingAddressDisable, setBillingAddressDisable] = useState(false);
  const [orderInfoBillMailDisable, setOrderInfoBillMailDisable] = useState(false);
  const [orderInfoBillDisable, setOrderInfoBillDisable] = useState(false);

  const [mailTakeInfoWasFound, setMailTakeInfoWasFound] = useState(false);
  const [mailOrderBillWasFound, setMailOrderBillWasFound] = useState(false);

  const [fromTakeInfo, setFromTakeInfo] = useState(false);
  const [justMounted, setJustMounted] = useState(false);

  const [takeInfoDataWasChange, setTakeInfoDataWasChange] = useState(false);
  const [orderBillDataWasChange, setOrderBillDataWasChange] = useState(false);

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);

  const [mailInfoFrom, setMailInfoFrom] = useState("");

  const timedOutRef = useRef(null);

  const [enableCivilityIfEmpty, setEnableCivilityIfEmpty] = useState(false);
  const [enableLastnameIfEmpty, setEnableLastnameIfEmpty] = useState(false);
  const [enableFirstnameIfEmpty, setEnableFirstnameIfEmpty] = useState(false);
  const [enableFixPhoneIfEmpty, setEnableFixPhoneIfEmpty] = useState(false);
  const [enablePhoneIfEmpty, setEnablePhoneIfEmpty] = useState(false);
  const [enableFunctionIfEmpty, setEnableFunctionIfEmpty] = useState(false);
  const [enableCivilityOrderBillIfEmpty, setEnableCivilityOrderBillIfEmpty] = useState(false);
  const [enableLastnameOrderBillIfEmpty, setEnableLastnameOrderBillIfEmpty] = useState(false);
  const [enableFirstnameOrderBillIfEmpty, setEnableFirstnameOrderBillIfEmpty] = useState(false);
  const [enableFixPhoneOrderBillIfEmpty, setEnableFixPhoneOrderBillIfEmpty] = useState(false);
  const [enablePhoneOrderBillIfEmpty, setEnablePhoneOrderBillIfEmpty] = useState(false);
  const [enableFunctionOrderBillIfEmpty, setEnableFunctionOrderBillIfEmpty] = useState(false);

  const handleResetStatesIfEmptyValue = () => {
    setEnableCivilityIfEmpty(false);
    setEnableLastnameIfEmpty(false);
    setEnableFirstnameIfEmpty(false);
    setEnableFixPhoneIfEmpty(false);
    setEnablePhoneIfEmpty(false);
    setEnableFunctionIfEmpty(false);
  };

  const handleResetStatesOrderBillIfEmptyValue = () => {
    setEnableCivilityOrderBillIfEmpty(false);
    setEnableLastnameOrderBillIfEmpty(false);
    setEnableFirstnameOrderBillIfEmpty(false);
    setEnableFixPhoneOrderBillIfEmpty(false);
    setEnablePhoneOrderBillIfEmpty(false);
    setEnableFunctionOrderBillIfEmpty(false);
  };

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    if (values) {
      console.log("INITIZAL VALUE", errors);
      handleOrderInfoOnBill(values.orderInfoOnBill, recruitmentPropsGeneral?.company?.contacts?.purchase);

      handleTakeInfoFromMount(recruitmentPropsGeneral?.company?.contacts?.billing);
      convertBillingBoolToYesNo(
        values.sameAdress,
        // recruitmentPropsGeneral?.company?.addresses?.billing
        // 	?.copyFromHeadquarters
      );

      setJustMounted(true);

      //getObjAucun();
      handleSubmit();
    }

    // if (
    // 	values.takeInfoFrom.key !== "Aucun" ||
    // 	values.takeInfoFrom.key !== "-Aucun-"
    // )
    // 	setTakeInfoFromDisable(true);
    console.log("MY VALUES", values.orderInfoOnBill);
  }, []);

  useEffect(() => {
    if (employeeDetails) {
      if (employeeDetails.length > 0) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetails]);

  const getObjAucun = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handlePingStatus = () => {
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  // Yup validation schema
  const Form9Schema = Yup.object().shape({
    sameAdress: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    address: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["sameAdress"], {
        is: (sameAdress) => sameAdress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    //addressMoreInfo: ,
    zipCode: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["sameAdress"], {
        is: (sameAdress) => sameAdress?.key === "Oui",
        then: (schema) =>
          schema
            .min(5, t("app.errorMessages.NombreItemRequis"))
            .max(5, t("app.errorMessages.NombreItemRequis"))
            .required(t("app.errorMessages.RemplirChamp")),
      }),
    city: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["sameAdress"], {
        is: (sameAdress) => sameAdress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    takeInfoFrom: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    email: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.email(t("app.errorMessages.EmailInvalid")).required(t("app.errorMessages.RemplirChamp")),
    }),
    civility: Yup.object().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    lastName: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema
          .min(2)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    // fixPhone: Yup.string()
    // 	.matches(phoneRegExp, t("app.errorMessages.PhoneFormat"))
    // 	.required(t("app.errorMessages.PhoneFormat")),
    fixPhone: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    orderInfoOnBill: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    emailOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "mandatoryOrderField"], {
        is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key !== "Non" && mandatoryOrderField,
        then: (schema) =>
          schema.email(`${t("app.errorMessages.EmailInvalid")}`).required(`${t("app.errorMessages.RemplirChamp")}`),
      }),
    civilityOrderBill: Yup.object()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "mandatoryOrderField"], {
        is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key === "Oui" && mandatoryOrderField,
        then: (schema) =>
          schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
      }),
    lastNameOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "mandatoryOrderField"], {
        is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key === "Oui" && mandatoryOrderField,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    firstNameOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "mandatoryOrderField"], {
        is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key === "Oui" && mandatoryOrderField,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    // fixPhoneOrderBill: Yup.string().when("orderInfoOnBill", {
    // 	is: (orderInfoOnBill) => orderInfoOnBill.key === "Oui",
    // 	then: Yup.string()
    // 		.matches(phoneRegExp, t("app.errorMessages.PhoneFormat"))
    // 		.required(t("app.errorMessages.PhoneFormat")),
    // }),
    fixPhoneOrderBill: Yup.string().when(["orderInfoOnBill", "mandatoryOrderField"], {
      is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key === "Oui" && mandatoryOrderField,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phoneOrderBill: Yup.string().when(["orderInfoOnBill", "mandatoryOrderField"], {
      is: (orderInfoOnBill, mandatoryOrderField) => orderInfoOnBill?.key === "Oui" && mandatoryOrderField,
      then: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    }),
    functionOrderBill: Yup.string().max(40, `${t("app.errorMessages.Max40")}`),
    // .typeError(t("app.errorMessages.RemplirChamp"))
    // .when("orderInfoOnBill", {
    // 	is: (orderInfoOnBill) => orderInfoOnBill.key === "Oui",
    // 	then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    // }),
  });

  const onSubmit = (values, actions) => {
    if (!formikErrorJustMount) {
      console.log("CONTRACT VALUE TO SEND 9", errors);

      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      let contactPart = {};
      let adressPart = {};
      let pushasePart = {};

      if (valuesCopy?.takeInfoFrom?.key === "Aucun") {
        contactPart = {
          billing: {
            copyFrom: valuesCopy?.takeInfoFrom?.key,
            email: valuesCopy.email,
            civility: valuesCopy?.civility?.key,
            name: valuesCopy.lastName,
            firstname: valuesCopy.firstName,
            phones: {
              office: valuesCopy.fixPhone,
              cellular: valuesCopy.phone,
            },
            function: valuesCopy.function,
          },
        };
      } else if (valuesCopy?.takeInfoFrom?.key !== "Aucun" && valuesCopy?.takeInfoFrom?.key !== "-Aucun-") {
        contactPart = {
          billing: {
            copyFrom: valuesCopy.takeInfoFrom.key,
          },
        };
      }

      if (valuesCopy?.sameAdress?.key === true) {
        adressPart = {
          billing: {
            copyFromHeadquarters: false,
            street: valuesCopy.address,
            complement: valuesCopy.addressMoreInfo,
            zipCode: valuesCopy.zipCode,
            city: valuesCopy.city,
          },
        };
      } else {
        adressPart = {
          billing: {
            copyFromHeadquarters: true,
          },
        };
      }
      const dataToSendWithoutPurchase = {
        status: "En cours",
        company: {
          addresses: {
            ...adressPart,
          },
          contacts: {
            ...contactPart,
          },
        },
        contract: {
          financing: {
            purchaseOrderMandatory: valuesCopy?.orderInfoOnBill?.key,
          },
        },
      };
      const dataToSend = {
        status: "En cours",
        company: {
          addresses: {
            ...adressPart,
          },
          contacts: {
            ...contactPart,
            purchase: {
              id: EmployeeId,
              email: valuesCopy.emailOrderBill,
              civility: valuesCopy?.civilityOrderBill?.key,
              name: valuesCopy.lastNameOrderBill,
              firstname: valuesCopy.firstNameOrderBill,
              phones: {
                office: valuesCopy.fixPhoneOrderBill,
                cellular: valuesCopy.phoneOrderBill,
              },
              function: valuesCopy.functionOrderBill,
            },
          },
        },
        contract: {
          financing: {
            purchaseOrderMandatory: valuesCopy?.orderInfoOnBill?.key,
          },
        },
      };

      props.submitSection(values.orderInfoOnBill?.key === "Oui" ? dataToSend : dataToSendWithoutPurchase, 9);
    } else handleMountPingStatus();
  };

  const handleTakeInfoFromMount = (infoFrom) => {
    if (values?.takeInfoFrom?.key === "Aucun") {
      setFromTakeInfo(false);
      setTakeInfoFromMailDisable(false);
      if (values.email.length >= 1) {
        if (infoFrom?.id === myInfo?.companyId) {
          setTakeInfoFromDisable(false);
          setFieldValue("mandatoryField", true);
        } else {
          setTakeInfoFromDisable(true);
          setFieldValue("mandatoryField", false);
        }
      } else setTakeInfoFromDisable(true);
    } else if (values?.takeInfoFrom?.key !== "Aucun" && values?.takeInfoFrom?.key !== "-Aucun-") {
      setTakeInfoFromDisable(true);
      setTakeInfoFromMailDisable(true);
      setFromTakeInfo(true);
      setFieldValue("mandatoryField", false);
    }
  };

  const convertBillingBoolToYesNo = (value) => {
    console.log("CONVEZRT", value);

    switch (value?.key) {
      case "Oui":
        setBillingAddressDisable(false);
        break;
      case "Non":
        setBillingAddressDisable(true);
        break;
      default:
        setBillingAddressDisable(false);
        break;
    }
  };

  const handleYesNoOnMount = (value) => {
    switch (value) {
      case true:
        return { key: "Oui", label: "Oui" };
        break;
      case false:
        return { key: "Non", label: "Non" };
        break;
      default:
        return { ...objAucun };
        break;
    }
  };

  const handleYesNoOnMountForSameAdress = (value) => {
    switch (value) {
      case true:
        return { key: "Non", label: "Non" };
        break;
      case false:
        return { key: "Oui", label: "Oui" };
        break;
      default:
        return { ...objAucun };
        break;
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      sameAdress: handleYesNoOnMountForSameAdress(
        recruitmentPropsGeneral?.company?.addresses?.billing?.copyFromHeadquarters,
      ),
      address: recruitmentPropsGeneral?.company?.addresses?.billing?.street
        ? recruitmentPropsGeneral?.company?.addresses?.billing?.street
        : "",
      addressMoreInfo: recruitmentPropsGeneral?.company?.addresses?.billing?.complement
        ? recruitmentPropsGeneral?.company?.addresses?.billing?.complement
        : "",
      zipCode: recruitmentPropsGeneral?.company?.addresses?.billing?.zipCode
        ? recruitmentPropsGeneral?.company?.addresses?.billing?.zipCode
        : "",
      city: recruitmentPropsGeneral?.company?.addresses?.billing?.city
        ? recruitmentPropsGeneral?.company?.addresses?.billing?.city
        : "",
      takeInfoFrom: recruitmentPropsGeneral?.company?.contacts?.billing?.copyFrom
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.copyFrom
        : { ...objAucun },
      email: recruitmentPropsGeneral?.company?.contacts?.billing?.email
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
        : "",
      civility: recruitmentPropsGeneral?.company?.contacts?.billing?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
        : { ...objAucun },
      lastName: recruitmentPropsGeneral?.company?.contacts?.billing?.name
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
        : "",
      firstName: recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
        : "",
      function: recruitmentPropsGeneral?.company?.contacts?.billing?.function
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
        : "",
      fixPhone: recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
        : "",
      phone: recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
        : "",
      orderInfoOnBill: handleYesNoOnMount(recruitmentPropsGeneral?.contract?.financing?.purchaseOrderMandatory),
      emailOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.email
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.email
        : "",
      civilityOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.civility
        : { ...objAucun },
      lastNameOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.name
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.name
        : "",
      firstNameOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.firstname
        : "",
      fixPhoneOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.phones?.office
        : "",
      phoneOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.purchase?.phones?.cellular
        : "",
      functionOrderBill: recruitmentPropsGeneral?.company?.contacts?.purchase?.function,
      mandatoryField: false,
      mandatoryOrderField: false,
    },
    validationSchema: Form9Schema,
    onSubmit,
  });

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
  }, [recruitmentPropsGeneral]);

  const setInfoFrom = (value, update) => {
    console.log("SET INFO", value);
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            : "",
        );
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        break;
      case "Contact taxe d’apprentissage":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.email
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            : "",
        );
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        break;
      case "Contact administratif":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            : "",
        );
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        break;
      case "Contact facturation":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.billing?.email
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.billing?.name
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.billing?.function
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
            : "",
        );
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email", "");
          setFieldValue("civility", { ...objAucun });
          setFieldValue("lastName", "");
          setFieldValue("firstName", "");
          setFieldValue("phone", "");
          setFieldValue("fixPhone", "");
          setFieldValue("function", "");
          setTakeInfoFromDisable(true);
          setTakeInfoFromMailDisable(false);
          setFromTakeInfo(false);
          setFieldValue("mandatoryField", true);
        } else {
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.billing?.email
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.billing?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.billing?.name
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.billing?.function
              ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
              : "",
          );
          let tmpGenDisable = takeInfoFromDisable;
          let tmpFromTake = fromTakeInfo;
          let tmpMandatory = values.mandatoryField;
          setTakeInfoFromDisable(tmpGenDisable);
          setTakeInfoFromMailDisable(false);
          setFromTakeInfo(tmpFromTake);
          setFieldValue("mandatoryField", tmpMandatory);

          setTakeInfoFromDisable(true);
        }
        break;
      case "-Aucun-":
        setFieldValue("email", "");
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("phone", "");
        setFieldValue("fixPhone", "");
        setFieldValue("function", "");
        setTakeInfoFromDisable(true);
        setTakeInfoFromMailDisable(false);
        setFromTakeInfo(false);
        setFieldValue("mandatoryField", true);
        break;
      default:
        break;
    }
  };

  const displayUserData = () => {
    if (mailInfoFrom === TAKE_INFO_FROM) {
      setFieldValue("lastName", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstName", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civility", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phone", employeeDetails[0]?.phones?.cellular ? employeeDetails[0]?.phones?.cellular : "");
      setFieldValue("function", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");
      setTakeInfoDataWasChange(true);
      setMailInfoFrom("");
    } else if (mailInfoFrom === INFO_ORDER_BILL) {
      setFieldValue("lastNameOrderBill", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstNameOrderBill", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civilityOrderBill", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhoneOrderBill", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phoneOrderBill", employeeDetails[0]?.phones?.cellular);
      setFieldValue("functionOrderBill", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");
      setOrderBillDataWasChange(true);
      setMailInfoFrom("");
      setEmployeeId(employeeDetails[0]?.id);
      console.log("WeirdEmployee", EmployeeId);
    }

    switch (mailInfoFrom) {
      case TAKE_INFO_FROM:
        if (employeeDetails[0]?.company?.id === myInfo.companyId) {
          setTakeInfoFromDisable(false);
          setFieldValue("mandatoryField", true);
        } else {
          setTakeInfoFromDisable(true);
          setFieldValue("mandatoryField", false);
        }
        setMailTakeInfoWasFound(true);
        break;
      case INFO_ORDER_BILL:
        if (employeeDetails[0]?.company?.id === myInfo.companyId) {
          setOrderInfoBillDisable(false);
          setFieldValue("mandatoryOrderField", true);
        } else {
          setOrderInfoBillDisable(true);
          setFieldValue("mandatoryOrderField", false);
        }
        setMailOrderBillWasFound(true);
        break;
      default:
        break;
    }
  };

  const displayDefaultData = () => {
    if (mailInfoFrom === TAKE_INFO_FROM) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setMailTakeInfoWasFound(false);
      setTakeInfoDataWasChange(true);
      setMailInfoFrom("");
      setFieldValue("mandatoryField", true);
    } else if (mailInfoFrom === INFO_ORDER_BILL) {
      setFieldValue("lastNameOrderBill", "");
      setFieldValue("firstNameOrderBill", "");
      setFieldValue("civilityOrderBill", { ...objAucun });
      setFieldValue("fixPhoneOrderBill", "");
      setFieldValue("phoneOrderBill", "");
      setFieldValue("functionOrderBill", "");
      setMailOrderBillWasFound(false);
      setOrderBillDataWasChange(true);
      setMailInfoFrom("");
      setFieldValue("mandatoryOrderField", true);
    }

    //setMailWasFound(false);
  };

  useEffect(() => {
    if (values.takeInfoFrom.key === "Aucun") {
      if (values.email.length >= 1) setTakeInfoFromDisable(false);
      else setTakeInfoFromDisable(true);
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken: props.axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get fail Employees !", error.message);
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("fixPhone", "");
        setFieldValue("phone", "");
        setFieldValue("function", "");
        setFieldValue("mandatoryField", true);
        return;
      }
    }
  }, [errors.email, values.email]);

  const setBillingInfo = (value) => {
    if (value?.key === "Non") {
      setBillingAddressDisable(true);
      setFieldValue(
        "address",
        recruitmentPropsGeneral?.company?.addresses?.headquarter?.street
          ? recruitmentPropsGeneral?.company?.addresses?.headquarter?.street
          : "",
      );
      setFieldValue(
        "addressMoreInfo",
        recruitmentPropsGeneral?.company?.addresses?.headquarter?.complement
          ? recruitmentPropsGeneral?.company?.addresses?.headquarter?.complement
          : "",
      );
      setFieldValue(
        "zipCode",
        recruitmentPropsGeneral?.company?.addresses?.headquarter?.zipCode
          ? recruitmentPropsGeneral?.company?.addresses?.headquarter?.zipCode
          : "",
      );
      setFieldValue(
        "city",
        recruitmentPropsGeneral?.company?.addresses?.headquarter?.city
          ? recruitmentPropsGeneral?.company?.addresses?.headquarter?.city
          : "",
      );
    } else {
      setFieldValue("address", "");
      setFieldValue("addressMoreInfo", "");
      setFieldValue("zipCode", "");
      setFieldValue("city", "");
      setBillingAddressDisable(false);
    }
  };

  const setInfoOrderBill = (value) => {
    switch (value?.key) {
      case "Oui":
        setOrderInfoBillMailDisable(false);
        setFieldValue("mandatoryOrderField", true);
        break;
      default:
        setOrderInfoBillMailDisable(true);
        setOrderInfoBillDisable(true);
        setFieldValue("mandatoryOrderField", false);
        break;
    }
    if (value?.key === "Oui") {
      setOrderInfoBillMailDisable(false);
      setFieldValue("mandatoryOrderField", true);
    } else {
      setOrderInfoBillMailDisable(true);
      setOrderInfoBillDisable(true);
      setFieldValue("mandatoryOrderField", false);
    }

    setFieldValue("emailOrderBill", "");
    setFieldValue("civilityOrderBill", { ...objAucun });
    setFieldValue("lastNameOrderBill", "");
    setFieldValue("firstNameOrderBill", "");
    setFieldValue("phoneOrderBill", "");
    setFieldValue("fixPhoneOrderBill", "");
    setFieldValue("functionOrderBill", "");
  };

  useEffect(() => {
    if (values.emailOrderBill.length >= 1) {
      setOrderInfoBillDisable(false);
      if (!errors.emailOrderBill) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.emailOrderBill,
                  axiosCancelToken: props.axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get fail Employees !", error.message);
            }
          })();
        }, "500");
      } else {
        setFieldValue("lastNameOrderBill", "");
        setFieldValue("firstNameOrderBill", "");
        setFieldValue("civilityOrderBill", { ...objAucun });
        setFieldValue("fixPhoneOrderBill", "");
        setFieldValue("phoneOrderBill", "");
        setFieldValue("functionOrderBill", "");
        setFieldValue("mandatoryOrderField", true);
        return;
      }
    } else setOrderInfoBillDisable(true);
  }, [errors.emailOrderBill, values.emailOrderBill]);

  const handleOrderInfoOnBill = (value, pushaseContact) => {
    console.log("HANDLE YES NO", value, "\npurshasecontact", pushaseContact);
    switch (value?.key) {
      case "Oui":
        setOrderInfoBillMailDisable(false);
        console.log("HANDLE YES NO -> CASE TRUE", values.orderInfoOnBill);
        if (pushaseContact?.email?.length >= 1 && pushaseContact?.id === myInfo?.companyId) {
          setOrderInfoBillDisable(false);
          setFieldValue("mandatoryOrderField", true);
        } else {
          setOrderInfoBillDisable(true);
          setFieldValue("mandatoryOrderField", false);
        }
        break;
      case "Non":
        setOrderInfoBillMailDisable(true);
        setOrderInfoBillDisable(true);
        console.log("HANDLE YES NO -> CASE FALSE", values.orderInfoOnBill);
        setFieldValue("mandatoryOrderField", false);
        break;
      default:
        setOrderInfoBillMailDisable(false);
        setOrderInfoBillDisable(true);
        console.log("HANDLE YES NO -> CASE DEFAULT", values.orderInfoOnBill);
        break;
    }
  };

  const validPhone = touched.phone && errors.phone;
  const validFixPhone = errors.fixPhone;
  const validPhoneOnBill = touched.phoneOrderBill && errors.phoneOrderBill;
  const validFixPhoneOnBill = touched.fixPhoneOrderBill && errors.fixPhoneOrderBill;

  useEffect(() => {
    if (values?.takeInfoFrom?.key !== "-Aucun-" && values.email !== "") {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityIfEmpty(true);
      }
      if (values?.lastName === "") {
        setEnableLastnameIfEmpty(true);
      }
      if (values?.firstName === "") {
        setEnableFirstnameIfEmpty(true);
      }
      if (values?.fixPhone === "") {
        setEnableFixPhoneIfEmpty(true);
      }
      if (!values?.phone) {
        setEnablePhoneIfEmpty(true);
      }
      if (values?.function === "") {
        setEnableFunctionIfEmpty(true);
      }
    } else {
      handleResetStatesIfEmptyValue();
    }

    if (
      values?.orderInfoOnBill?.key !== "-Aucun-" &&
      values?.orderInfoOnBill?.key !== "Non" &&
      values.emailOrderBill !== ""
    ) {
      if (values?.civilityOrderBill?.key === "-Aucun-") {
        setEnableCivilityOrderBillIfEmpty(true);
      }
      if (values?.lastNameOrderBill === "") {
        setEnableLastnameOrderBillIfEmpty(true);
      }
      if (values?.firstNameOrderBill === "") {
        setEnableFirstnameOrderBillIfEmpty(true);
      }
      if (values?.fixPhoneOrderBill === "") {
        setEnableFixPhoneOrderBillIfEmpty(true);
      }
      if (!values?.phoneOrderBill) {
        setEnablePhoneOrderBillIfEmpty(true);
      }
      if (values?.functionOrderBill === "") {
        setEnableFunctionOrderBillIfEmpty(true);
      }
    } else {
      handleResetStatesOrderBillIfEmptyValue();
    }
  }, [values]);

  if (props.formOpen) {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.company_name_container}>
            <label>{t("app.suivezVosContratsPartner.NomDeLaSociete")}</label>
            <p>{recruitmentPropsGeneral?.company?.name}</p>
          </div>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="sameAdress">{t("app.suivezVosContratsPartner.AdresseDifferente")}</label>
              <DropdownList
                style={
                  errors.sameAdress &&
                  touched.sameAdress && {
                    border: "2px solid #EA001E",
                  }
                }
                id="sameAdress"
                dataKey="key"
                textField="label"
                disabled={false}
                data={YesNo}
                value={values.sameAdress}
                onChange={(e) => {
                  setBillingInfo(e);
                  setFieldValue("sameAdress", e);
                }}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.sameAdress} touched={touched.sameAdress} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="address">{t("app.suivezVosContratsPartner.Adresse")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.address &&
                      touched.address && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="address"
                type="text"
                disabled={billingAddressDisable}
                value={values.address}
                onChange={(e) => setFieldValue("address", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.address} touched={touched.address} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="addressMoreInfo">{t("app.suivezVosContratsPartner.ComplementDadresse")}</label>
              <input
                style={
                  errors.addressMoreInfo &&
                  touched.addressMoreInfo && {
                    border: "2px solid #EA001E",
                  }
                }
                id="addressMoreInfo"
                type="text"
                disabled={billingAddressDisable}
                value={values.addressMoreInfo}
                onChange={(e) => setFieldValue("addressMoreInfo", e.target.value)}
              />
              <InputErrorMessage message={errors.addressMoreInfo} touched={touched.addressMoreInfo} />
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="zipCode">{t("app.suivezVosContratsPartner.CodePostal2")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.zipCode &&
                      touched.zipCode && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="zipCode"
                type="text"
                disabled={billingAddressDisable}
                value={values.zipCode}
                onChange={(e) => setFieldValue("zipCode", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.zipCode} touched={touched.zipCode} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="city">{t("app.suivezVosContratsPartner.Ville")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.city &&
                      touched.city && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="city"
                type="text"
                disabled={billingAddressDisable}
                value={values.city}
                onChange={(e) => setFieldValue("city", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.city} touched={touched.city} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="takeInfoFrom">{t("app.suivezVosContratsPartner.ReprendreInfo")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.takeInfoFrom &&
                      touched.takeInfoFrom && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="takeInfoFrom"
                dataKey="key"
                textField="label"
                disabled={false}
                data={[{ ...objAucun }, ...props?.referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_BILLING_CONTACT]} //TO CHANGE
                value={values.takeInfoFrom}
                onChange={(e) => {
                  setInfoFrom(e, false);
                  setFieldValue("takeInfoFrom", e);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.takeInfoFrom} touched={touched.takeInfoFrom} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="email">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.email &&
                      touched.email && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="email"
                type="email"
                disabled={takeInfoFromMailDisable}
                value={values.email}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                  setMailInfoFrom(TAKE_INFO_FROM);
                }}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.email} touched={touched.email} />}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="civility">{t("app.suivezVosContratsPartner.Civilite")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.civility &&
                      touched.civility && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="civility"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                disabled={takeInfoFromDisable && !enableCivilityIfEmpty && true}
                value={values.civility}
                onChange={(newValue) => setFieldValue("civility", newValue)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.civility} touched={touched.civility} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="lastName">{t("app.suivezVosContratsPartner.Nom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.lastName &&
                      touched.lastName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="lastName"
                type="text"
                disabled={takeInfoFromDisable && !enableLastnameIfEmpty && true}
                value={values.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.lastName} touched={touched.lastName} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="firstName">{t("app.suivezVosContratsPartner.Prenom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.firstName &&
                      touched.firstName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstName"
                type="text"
                disabled={takeInfoFromDisable && !enableFirstnameIfEmpty && true}
                value={values.firstName}
                onChange={(e) => setFieldValue("firstName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.firstName} touched={touched.firstName} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="function">{t("app.suivezVosContratsPartner.Fonction")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.function &&
                      touched.function && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="function"
                type="text"
                disabled={takeInfoFromDisable && !enableFunctionIfEmpty && true}
                value={values.function}
                onChange={(e) => setFieldValue("function", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.function} touched={touched.function} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="fixPhone">{t("app.suivezVosContratsPartner.Telephone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.fixPhone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="fixPhone"
                id="fixPhone"
                country={"FR"}
                disabled={takeInfoFromDisable && !enableFixPhoneIfEmpty && true}
                value={values.fixPhone}
                onChange={(e) => {
                  setFieldValue("fixPhone", e);
                }}
                className={!formikErrorJustMount ? `${validFixPhone && `${styles.errorInput}`}` : null}
              />
              {/* <input
								type="text"
								name="fixPhone"
								id="fixPhone"
								disabled={takeInfoFromDisable}
								value={values.fixPhone}
								onChange={(e) => setFieldValue("fixPhone", e.target.value)}
								className={
									!formikErrorJustMount
										? `${validFixPhone && `${styles.errorInput}`}`
										: null
								}
							/> */}

              {validFixPhone && !formikErrorJustMount && <span className={styles.error}>{errors.fixPhone}</span>}
            </div>

            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="phone">{t("app.suivezVosContratsPartner.Portable")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.phone &&
                      touched.phone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="phone"
                name="phone"
                type="text"
                disabled={takeInfoFromDisable && !enablePhoneIfEmpty && true}
                value={values.phone}
                onChange={(e) => setFieldValue("phone", e.target.value)}
                className={!formikErrorJustMount ? `${validPhone && `${styles.errorInput}`}` : null}
              />
              {validPhone && !formikErrorJustMount && <span className={styles.error}>{errors.phone}</span>}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="orderInfoOnBill">{t("app.suivezVosContratsPartner.BonCommandeFactureObligatoire")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.orderInfoOnBill &&
                      touched.orderInfoOnBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="orderInfoOnBill"
                dataKey="key"
                textField="label"
                disabled={false}
                data={YesNo}
                value={values.orderInfoOnBill}
                onChange={(e) => {
                  setFieldValue("orderInfoOnBill", e);
                  setInfoOrderBill(e);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.orderInfoOnBill} touched={touched.orderInfoOnBill} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="emailOrderBill">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.emailOrderBill &&
                      touched.emailOrderBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="emailOrderBill"
                type="email"
                disabled={orderInfoBillMailDisable}
                value={values.emailOrderBill}
                onChange={(e) => {
                  //getUserDataByMail(e.target.value);
                  setMailInfoFrom(INFO_ORDER_BILL);
                  setFieldValue("emailOrderBill", e.target.value);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.emailOrderBill} touched={touched.emailOrderBill} />
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="civilityOrderBill">{t("app.suivezVosContratsPartner.Civilite")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.civilityOrderBill &&
                      touched.civilityOrderBill && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="civilityOrderBill"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                disabled={orderInfoBillDisable && !enableCivilityOrderBillIfEmpty && true}
                value={values.civilityOrderBill}
                onChange={(newValue) => setFieldValue("civilityOrderBill", newValue)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.civilityOrderBill} touched={touched.civilityOrderBill} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="lastNameOrderBill">{t("app.suivezVosContratsPartner.Nom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.lastNameOrderBill &&
                      touched.lastNameOrderBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="lastNameOrderBill"
                type="text"
                disabled={orderInfoBillDisable && !enableLastnameOrderBillIfEmpty && true}
                value={values.lastNameOrderBill}
                onChange={(e) => setFieldValue("lastNameOrderBill", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.lastNameOrderBill} touched={touched.lastNameOrderBill} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="firstNameOrderBill">{t("app.suivezVosContratsPartner.Prenom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.firstNameOrderBill &&
                      touched.firstNameOrderBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstNameOrderBill"
                type="text"
                disabled={orderInfoBillDisable && !enableFirstnameOrderBillIfEmpty && true}
                value={values.firstNameOrderBill}
                onChange={(e) => setFieldValue("firstNameOrderBill", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.firstNameOrderBill} touched={touched.firstNameOrderBill} />
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="fixPhoneOrderBill">{t("app.suivezVosContratsPartner.Telephone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.fixPhone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="fixPhoneOrderBill"
                id="fixPhoneOrderBill"
                country={"FR"}
                disabled={orderInfoBillDisable && !enableFixPhoneOrderBillIfEmpty && true}
                value={values.fixPhoneOrderBill}
                onChange={(e) => {
                  setFieldValue("fixPhoneOrderBill", e);
                }}
                className={!formikErrorJustMount ? `${validFixPhone && `${styles.errorInput}`}` : null}
              />
              {/* <input
								style={
									!formikErrorJustMount
										? errors.fixPhoneOrderBill &&
										  touched.fixPhoneOrderBill && {
												border: "2px solid #EA001E",
										  }
										: null
								}
								type="text"
								name="fixPhoneOrderBill"
								id="fixPhoneOrderBill"
								disabled={orderInfoBillDisable}
								value={values.fixPhoneOrderBill}
								onChange={(e) =>
									setFieldValue("fixPhoneOrderBill", e.target.value)
								}
								className={
									!formikErrorJustMount
										? `${validFixPhoneOnBill && `${styles.errorInput}`}`
										: null
								}
							/> */}
              {validFixPhoneOnBill && !formikErrorJustMount && (
                <span className={styles.error}>{errors.fixPhoneOrderBill}</span>
              )}
            </div>

            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="phoneOrderBill">{t("app.suivezVosContratsPartner.Portable")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.phoneOrderBill &&
                      touched.phoneOrderBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="phoneOrderBill"
                name="phoneOrderBill"
                type="text"
                disabled={orderInfoBillDisable && !enablePhoneOrderBillIfEmpty && true}
                value={values.phoneOrderBill}
                onChange={(e) => setFieldValue("phoneOrderBill", e.target.value)}
                className={!formikErrorJustMount ? `${validPhoneOnBill && `${styles.errorInput}`}` : null}
              />
              {validPhoneOnBill && !formikErrorJustMount && (
                <span className={styles.error}>{errors.phoneOrderBill}</span>
              )}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="functionOrderBill">{t("app.suivezVosContratsPartner.Fonction")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.functionOrderBill &&
                      touched.functionOrderBill && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="functionOrderBill"
                type="text"
                disabled={orderInfoBillDisable && !enableFunctionOrderBillIfEmpty && true}
                value={values.functionOrderBill}
                onChange={(e) => setFieldValue("functionOrderBill", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.functionOrderBill} touched={touched.functionOrderBill} />
              )}
            </div>
          </div>
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn onClick={handlePingStatus} text={t("app.visualisationOffre.ValidezLesModifications")} />
          </div>
        </form>
      </div>
    );
  }
};

export default Form9;
