import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Initial State declaration
const initialState = {
  employee: null,
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getEmployeesThunkWithoutToken = createAsyncThunk(
  "checkDetailsCompanyWithoutToken/getEmployeesThunkWithoutToken",
  async ({ email, axiosCancelToken }, { getState }) => {
    const language = getState().languages.data;
    const response = await axios.get(
      `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/employees?email=${email}`,

      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${language}`,
        },
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

const checkDetailsCompanyWithoutTokenSlice = createSlice({
  name: "checkDetailsCompanyWithoutToken",
  initialState,
  extraReducers: {
    [getEmployeesThunkWithoutToken.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getEmployeesThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.employee = action.payload;
    },
    [getEmployeesThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getEmployeeDetailsWithoutToken = (state) => state?.checkDetailsCompanyWithoutToken?.employee;
export const getCompanyDetailsIsPendingWithoutToken = (state) => state?.checkDetailsCompanyWithoutToken?.pending;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkDetailsCompanyWithoutTokenSlice.reducer;
