import { t } from "i18next";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "components/Header/Header";
import { DropdownList } from "react-widgets";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { createObjAucun } from "utils/localReferentials";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLearningBookletsPending, putThunk } from "app/slices/learningBooklets/learningBooklets.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import bgContractImg from "../../../assets/bg-consul-offre.png";
import styles from "./IntermediateEvaluationM2.module.scss";

const IntermediateEvaluationM2 = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const pending = useSelector(getLearningBookletsPending);

  const referencialData = useSelector(getReferentialsData);
  const objAucun = createObjAucun();

  // Yup validation schema
  const formSchema = Yup.object().shape({
    interpersonalSkills: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    teamSpirit: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    adaptability: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    initiative: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    standardAndRegulationRespect: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    planificationOrganisation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    autoControl: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    reporting: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    mobilizationAbility: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    communicationSkills: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    knowledgeAcquisition: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    personalOrganizationWork: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    toolsAndMethodsMastery: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    deadlinesRespect: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    workQuality: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    comment: Yup.string(),
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  // onSubmit form function
  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Complété",
      interpersonalSkills: values.interpersonalSkills.key,
      teamSpirit: values.teamSpirit.key,
      adaptability: values.adaptability.key,
      initiative: values.initiative.key,
      standardAndRegulationRespect: values.standardAndRegulationRespect.key,
      planificationOrganisation: values.planificationOrganisation.key,
      autoControl: values.autoControl.key,
      reporting: values.reporting.key,
      mobilizationAbility: values.mobilizationAbility.key,
      communicationSkills: values.communicationSkills.key,
      knowledgeAcquisition: values.knowledgeAcquisition.key,
      personalOrganizationWork: values.personalOrganizationWork.key,
      toolsAndMethodsMastery: values.toolsAndMethodsMastery.key,
      deadlinesRespect: values.deadlinesRespect.key,
      workQuality: values.workQuality.key,
      signature: values.signature,
      comment: values.comment,
    };
    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/suivez-vos-alternants/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };
  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      interpersonalSkills: { ...objAucun },
      teamSpirit: { ...objAucun },
      adaptability: { ...objAucun },
      initiative: { ...objAucun },
      standardAndRegulationRespect: { ...objAucun },
      planificationOrganisation: { ...objAucun },
      autoControl: { ...objAucun },
      reporting: { ...objAucun },
      mobilizationAbility: { ...objAucun },
      communicationSkills: { ...objAucun },
      knowledgeAcquisition: { ...objAucun },
      personalOrganizationWork: { ...objAucun },
      toolsAndMethodsMastery: { ...objAucun },
      deadlinesRespect: { ...objAucun },
      workQuality: { ...objAucun },
      comment: "",
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  return (
    <div>
      <Header
        h1={t("app.formulairesLivretConfirmation.SuivezAlternant")}
        textElement={(
          <div>
            <p>
              {t("app.formulairesLivretConfirmation.Intermediaire1")}
              <br />
              <br />
              {t("app.formulairesLivretConfirmation.Intermediaire2")}
              <br />
              <br />
              {t("app.formulairesLivretConfirmation.Intermediaire3")}
            </p>
            <ul className={styles.listHeader}>
              <li> {t("app.formulairesLivretConfirmation.Intermediaire4")}</li>
              <li> {t("app.formulairesLivretConfirmation.Intermediaire5")}</li>

              <li> {t("app.formulairesLivretConfirmation.Intermediaire6")}</li>
              <li> {t("app.formulairesLivretConfirmation.Intermediaire7")}</li>
            </ul>
            <br />
            <p>{t("app.formulairesLivretConfirmation.Intermediaire2")}</p>
          </div>
        )}
        bgImg={bgContractImg}
      />
      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.container}>
            <div className={styles.title_container}>
              <h2>{t("app.formulairesLivretConfirmation.EvalIntermediaireM2")}</h2>
            </div>
            <form className={styles.the_form} onSubmit={handleSubmit}>
              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.Competences")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="interpersonalSkills" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Relationnel")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.RelationnelDesc")}</p>
                  <DropdownList
                    style={
                      errors.interpersonalSkills &&
                      touched.interpersonalSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="interpersonalSkills"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.interpersonalSkills}
                    onChange={(newValue) => setFieldValue("interpersonalSkills", newValue)}
                  />
                  <InputErrorMessage message={errors.interpersonalSkills} touched={touched.interpersonalSkills} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="teamSpirit" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Equipe")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.EquipeDesc")}</p>
                  <DropdownList
                    style={
                      errors.teamSpirit &&
                      touched.teamSpirit && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="teamSpirit"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.teamSpirit}
                    onChange={(newValue) => setFieldValue("teamSpirit", newValue)}
                  />
                  <InputErrorMessage message={errors.teamSpirit} touched={touched.teamSpirit} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="adaptability" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Adaptabilite")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.AdaptabiliteDesc")}</p>
                  <DropdownList
                    style={
                      errors.adaptability &&
                      touched.adaptability && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="adaptability"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.adaptability}
                    onChange={(newValue) => setFieldValue("adaptability", newValue)}
                  />
                  <InputErrorMessage message={errors.adaptability} touched={touched.adaptability} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="initiative" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Initiative")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.InitiativeDesc")}</p>
                  <DropdownList
                    style={
                      errors.initiative &&
                      touched.initiative && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="initiative"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.initiative}
                    onChange={(newValue) => setFieldValue("initiative", newValue)}
                  />
                  <InputErrorMessage message={errors.initiative} touched={touched.initiative} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="standardAndRegulationRespect" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.RespectNormes")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.RespectNormesDesc")}</p>
                  <DropdownList
                    style={
                      errors.standardAndRegulationRespect &&
                      touched.standardAndRegulationRespect && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="standardAndRegulationRespect"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.standardAndRegulationRespect}
                    onChange={(newValue) => setFieldValue("standardAndRegulationRespect", newValue)}
                  />
                  <InputErrorMessage
                    message={errors.standardAndRegulationRespect}
                    touched={touched.standardAndRegulationRespect}
                  />
                </div>
              </div>
              <div className={styles.line} />

              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.Competences")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="planificationOrganisation" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Planification")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.PlanificationDesc")}</p>
                  <DropdownList
                    style={
                      errors.planificationOrganisation &&
                      touched.planificationOrganisation && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="planificationOrganisation"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.planificationOrganisation}
                    onChange={(newValue) => setFieldValue("planificationOrganisation", newValue)}
                  />
                  <InputErrorMessage
                    message={errors.planificationOrganisation}
                    touched={touched.planificationOrganisation}
                  />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="autoControl" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Controle")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ControleDesc")}</p>
                  <DropdownList
                    style={
                      errors.autoControl &&
                      touched.autoControl && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="autoControl"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.autoControl}
                    onChange={(newValue) => setFieldValue("autoControl", newValue)}
                  />
                  <InputErrorMessage message={errors.autoControl} touched={touched.autoControl} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="reporting" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Reporting")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ReportingDesc")}</p>
                  <DropdownList
                    style={
                      errors.reporting &&
                      touched.reporting && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="reporting"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.reporting}
                    onChange={(newValue) => setFieldValue("reporting", newValue)}
                  />
                  <InputErrorMessage message={errors.reporting} touched={touched.reporting} />
                </div>

                <div className={styles.inputs_container}>
                  <label htmlFor="communicationSkills" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.QualiteComm")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.QualiteCommDesc")}</p>
                  <DropdownList
                    style={
                      errors.communicationSkills &&
                      touched.communicationSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="communicationSkills"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.communicationSkills}
                    onChange={(newValue) => setFieldValue("communicationSkills", newValue)}
                  />
                  <InputErrorMessage message={errors.communicationSkills} touched={touched.communicationSkills} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="mobilizationAbility" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Mobilise")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.MobiliseDesc")}</p>
                  <DropdownList
                    style={
                      errors.mobilizationAbility &&
                      touched.mobilizationAbility && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="mobilizationAbility"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.mobilizationAbility}
                    onChange={(newValue) => setFieldValue("mobilizationAbility", newValue)}
                  />
                  <InputErrorMessage message={errors.mobilizationAbility} touched={touched.mobilizationAbility} />
                </div>
              </div>
              <div className={styles.line} />
              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.CompetencesTech")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="knowledgeAcquisition" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Connaissances")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ConnaissancesDesc")}</p>
                  <DropdownList
                    style={
                      errors.knowledgeAcquisition &&
                      touched.knowledgeAcquisition && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="knowledgeAcquisition"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.knowledgeAcquisition}
                    onChange={(newValue) => setFieldValue("knowledgeAcquisition", newValue)}
                  />
                  <InputErrorMessage message={errors.knowledgeAcquisition} touched={touched.knowledgeAcquisition} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="personalOrganizationWork" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.OrgaPerso")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.OrgaPersoDesc")}</p>
                  <DropdownList
                    style={
                      errors.personalOrganizationWork &&
                      touched.personalOrganizationWork && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="personalOrganizationWork"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.personalOrganizationWork}
                    onChange={(newValue) => setFieldValue("personalOrganizationWork", newValue)}
                  />
                  <InputErrorMessage
                    message={errors.personalOrganizationWork}
                    touched={touched.personalOrganizationWork}
                  />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="toolsAndMethodsMastery" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Outils")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.OutilsDesc")}</p>
                  <DropdownList
                    style={
                      errors.toolsAndMethodsMastery &&
                      touched.toolsAndMethodsMastery && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="toolsAndMethodsMastery"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.toolsAndMethodsMastery}
                    onChange={(newValue) => setFieldValue("toolsAndMethodsMastery", newValue)}
                  />
                  <InputErrorMessage message={errors.toolsAndMethodsMastery} touched={touched.toolsAndMethodsMastery} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="workQuality" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.QualiteTravail")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.QualiteTravailDesc")}</p>
                  <DropdownList
                    style={
                      errors.workQuality &&
                      touched.workQuality && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="workQuality"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.workQuality}
                    onChange={(newValue) => setFieldValue("workQuality", newValue)}
                  />
                  <InputErrorMessage message={errors.workQuality} touched={touched.workQuality} />
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="deadlinesRespect" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Delais")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.DelaisDesc")}</p>
                  <DropdownList
                    style={
                      errors.deadlinesRespect &&
                      touched.deadlinesRespect && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="deadlinesRespect"
                    dataKey="key"
                    textField="label"
                    data={referencialData?.APPRENTICESHIP_SKILLS_EVALUATION}
                    value={values.deadlinesRespect}
                    onChange={(newValue) => setFieldValue("deadlinesRespect", newValue)}
                  />
                  <InputErrorMessage message={errors.deadlinesRespect} touched={touched.deadlinesRespect} />
                </div>

                <div className={styles.inputs_container}>
                  <label htmlFor="comment">{t("app.formulairesLivretConfirmation.Commentaires")}</label>

                  <textarea
                    id="comment"
                    rows="5"
                    maxLength={32768}
                    value={values.comment}
                    onChange={(e) => setFieldValue("comment", e.target.value.trimStart())}
                  >
                  </textarea>
                </div>
                <div className={styles.checkbox_container}>
                  <label htmlFor="signature" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.SignatureMaitre")}
                  </label>
                  <input
                    id="signature"
                    type="checkbox"
                    checked={values.signature}
                    onChange={(e) => setFieldValue("signature", e.target.checked)}
                  />
                  <InputErrorMessage message={errors.signature} touched={touched.signature} />
                </div>
              </div>
              <div className={styles.submitButton}>
                <SubmitFormBtn text={t("app.formulairesLivretConfirmation.BoutonValider")} />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default IntermediateEvaluationM2;
