import { t } from "i18next";
import styles from "./DisableBtnTrue.module.scss";

const DisableBtnTrue = ({ disabled, onClick }) => (
  <button type="submit" className={styles.disable_btn} onClick={onClick}>
    {disabled ? t("app.visualisationCv.DesactiverCV") : t("app.visualisationCv.ActiverCV")}
  </button>
);

export default DisableBtnTrue;
