import { Routes, Route } from "react-router-dom";

import Subdomain404 from "./Subdomain404";

const RoutesSubdomain404 = () => (
  <Routes>
    <Route path="/*" element={<Subdomain404 />} />
  </Routes>
);
export default RoutesSubdomain404;
