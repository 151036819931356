import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

const allOffersKey = "allOffers";
const offerByIdKey = "offerById";

const initialState = {
  [allOffersKey]: {
    listOffers: [],
    numberOfOffers: 0,
    currentPagination: 1,
    maxPagination: undefined,
    offset: 0,
    endIndex: 20,
    pending: false,
    errorMessage: null,
  },
  [offerByIdKey]: {
    id: null,
    offer: null,
    pending: false,
    errorMessage: null,
  },
};
// THUNKS SECTION

export const getOfferThunk = createAsyncThunk(
  "checkAllOffers/getOfferThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v2/companies/job-offers?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const { offset } = getState().checkAllOffers[allOffersKey];
    const { endIndex } = getState().checkAllOffers[allOffersKey];

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE OFFERS", response);

    const numberOfOffers = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfOffers);

    return {
      data: response.data,
      numberOfOffers: response.headers["content-range"].split("/")[1],
    };
  },
);

export const nextOffersPaginationThunk = createAsyncThunk(
  "checkAllOffers/nextOffersPaginationThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v2/companies/job-offers?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const offset = getState().checkAllOffers[allOffersKey].offset + 20;
    const endIndex = getState().checkAllOffers[allOffersKey].endIndex + 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE OFFERS", response);

    const numberOfOffers = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfOffers);

    return {
      data: response.data,
      numberOfOffers: response.headers["content-range"].split("/")[1],
    };
  },
);

export const previousOffersPaginationThunk = createAsyncThunk(
  "checkAllOffers/previousOffersPaginationThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v2/companies/job-offers?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const offset = getState().checkAllOffers[allOffersKey].offset - 20;
    const endIndex = getState().checkAllOffers[allOffersKey].endIndex - 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE OFFERS", response);

    const numberOfOffers = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfOffers);

    return {
      data: response.data,
      numberOfOffers: response.headers["content-range"].split("/")[1],
    };
  },
);

export const getOfferByIdThunk = createAsyncThunk("checkAllOffers/getOfferByIdThunk", async (id) => {
  const response = await axiosCompany().get(`/v2/companies/job-offers/${id}`);
  return response.data;
});

// CREATESLICE SECTION

const checkAllOffersSlice = createSlice({
  name: "checkAllOffers",
  initialState,
  reducers: {
    resetAllOffersKeyState: (state) => {
      state[allOffersKey].listOffers = [];
      state[allOffersKey].numberOfOffers = 0;
      state[allOffersKey].currentPagination = 1;
      state[allOffersKey].maxPagination = undefined;
      state[allOffersKey].offset = 0;
      state[allOffersKey].endIndex = 20;
      state[allOffersKey].pending = false;
      state[allOffersKey].errorMessage = null;
    },
  },
  extraReducers: {
    [getOfferByIdThunk.pending]: (state) => {
      state[offerByIdKey].errorMessage = null;
      state[offerByIdKey].pending = true;
    },
    [getOfferByIdThunk.fulfilled]: (state, action) => {
      state[offerByIdKey].pending = false;
      state[offerByIdKey].offer = action.payload;
    },
    [getOfferByIdThunk.rejected]: (state, action) => {
      state[offerByIdKey].pending = false;
      state[offerByIdKey].errorMessage = action.error.message;
    },
    [getOfferThunk.pending]: (state) => {
      state[allOffersKey].errorMessage = null;
      state[allOffersKey].pending = true;
    },
    [getOfferThunk.fulfilled]: (state, action) => {
      state[allOffersKey].listOffers = action.payload.data;
      state[allOffersKey].numberOfOffers = action.payload.numberOfOffers;
      state[allOffersKey].currentPagination = 1;
      state[allOffersKey].maxPagination =
        action.payload.numberOfOffers % 20 === 0
          ? action.payload.numberOfOffers / 20
          : Math.floor(action.payload.numberOfOffers / 20) + 1;
      state[allOffersKey].pending = false;
    },
    [getOfferThunk.rejected]: (state, action) => {
      state[allOffersKey].pending = false;
      state[allOffersKey].errorMessage = action.error.message;
    },
    [nextOffersPaginationThunk.pending]: (state) => {
      state[allOffersKey].errorMessage = null;
      state[allOffersKey].pending = true;
    },
    [nextOffersPaginationThunk.fulfilled]: (state, action) => {
      state[allOffersKey].listOffers = action.payload.data;
      state[allOffersKey].numberOfOffers = action.payload.numberOfOffers;
      state[allOffersKey].currentPagination += 1;
      state[allOffersKey].maxPagination =
        action.payload.numberOfOffers % 20 === 0
          ? action.payload.numberOfOffers / 20
          : Math.floor(action.payload.numberOfOffers / 20) + 1;
      state[allOffersKey].offset += 20;
      state[allOffersKey].endIndex += 20;
      state[allOffersKey].pending = false;
    },
    [nextOffersPaginationThunk.rejected]: (state, action) => {
      state[allOffersKey].pending = false;
      state[allOffersKey].errorMessage = action.error.message;
    },
    [previousOffersPaginationThunk.pending]: (state) => {
      state[allOffersKey].errorMessage = null;
      state[allOffersKey].pending = true;
    },
    [previousOffersPaginationThunk.fulfilled]: (state, action) => {
      state[allOffersKey].listOffers = action.payload.data;
      state[allOffersKey].numberOfOffers = action.payload.numberOfOffers;
      state[allOffersKey].currentPagination -= 1;
      state[allOffersKey].maxPagination =
        action.payload.numberOfOffers % 20 === 0
          ? action.payload.numberOfOffers / 20
          : Math.floor(action.payload.numberOfOffers / 20) + 1;
      state[allOffersKey].offset -= 20;
      state[allOffersKey].endIndex -= 20;
      state[allOffersKey].pending = false;
    },
    [previousOffersPaginationThunk.rejected]: (state, action) => {
      state[allOffersKey].pending = false;
      state[allOffersKey].errorMessage = action.error.message;
    },
  },
});

export const checkAllOffersKey = "checkAllOffers";

export const getOffersList = (state) => console.log(state) || state?.[checkAllOffersKey]?.[allOffersKey]?.listOffers;
export const getOffersListPending = (state) => state?.[checkAllOffersKey]?.[allOffersKey]?.pending;
export const getOffersListErrorMessage = (state) => state?.[checkAllOffersKey]?.[allOffersKey]?.errorMessage;
export const getNumberOfOffers = (state) => state?.[checkAllOffersKey]?.[allOffersKey]?.numberOfOffers;
export const selectCurrentPaginationOfOffers = (state) => state?.[checkAllOffersKey]?.[allOffersKey]?.currentPagination;
export const selectMaxPaginationOfOffers = (state) => state?.[checkAllOffersKey]?.[allOffersKey]?.maxPagination;
export const getOfferById = (state) => state?.[checkAllOffersKey]?.[offerByIdKey]?.offer;
export const getOfferByIdPending = (state) => state?.[checkAllOffersKey]?.[offerByIdKey]?.pending;
export const getOfferByIdErrorMessage = (state) => state?.[checkAllOffersKey]?.[offerByIdKey]?.errorMessage;

export const { resetAllOffersKeyState } = checkAllOffersSlice.actions;

// Reducers section
export default checkAllOffersSlice.reducer;
