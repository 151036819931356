import { Link } from "react-router-dom";

import { ReactComponent as Eye } from "../assets/eye.svg";
// import { ReactComponent as Pencil } from "../assets/pencil.svg";
import styles from "./Card.module.scss";

/* eslint-disable */
const SkillCard = (props) => {
  const ModifyForm = props.modifyForm;
  return (
    <div className={styles.card_container}>
      <div className={styles.cv_id}>{props.domain.label}</div>
      <div className={styles.name}>{props.software}</div>
      <div className={styles.edit_link}>
        <div
          onClick={() => ModifyForm(props)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Eye width="1rem" style={{ marginRight: "2%" }} />
          <Link>Modifier</Link>
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
