import React, { useEffect, useRef, useState } from "react";
import icon from "./circlesfaq.svg";
import arrow from "./icon-arrowDropDownUp.svg";

import styles from "./accordion.module.scss";

function Accordion({ title, content }) {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {}, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${contentRef.current.scrollHeight}px`);
  }

  /* eslint-disable */
  return (
    <div className="accordion__section">
      <div className={`${styles.accordion} ${active ? "active" : ""}`} onClick={toggleAccordion}>
        <img src={icon} alt="" className={styles.icon} />
        <p className={styles.accordion__title}>{title}</p>
        <span className={styles.arrow}>
          {active ? (
            <img src={arrow} alt="Flèche" style={{ transform: "rotate(180deg)" }} />
          ) : (
            <img src={arrow} alt="Flèche" />
          )}
        </span>
      </div>
      <div ref={contentRef} style={{ maxHeight: `${height}` }} className={styles.accordion__content}>
        <div className={styles.accordion__text} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
}

export default Accordion;
