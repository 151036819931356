import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { nanoid } from "@reduxjs/toolkit";

import Header from "components/Header/Header";
import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { useSelector } from "react-redux";
import { formatDate } from "utils/formatDate";
import { changeBooleanToOuiNon } from "utils/tools";

import bgContractImg from "../../../../../assets/bg-consul-offre.png";
import styles from "./Recapitulative.module.scss";

/* eslint-disable */
const ListDetail = ({ label, value }) => {
  return (
    <div className={styles.listDetails} key={nanoid()}>
      <h6>{label}</h6>
      <p>{value}</p>
    </div>
  );
};
const CheckBoxBloc = (checked) => {
  return <input type="checkbox" name="checkbox" disabled defaultChecked={checked} />;
};

const Recapitulative = () => {
  const detailsRecrutement = useSelector(getYourRecruitmentPropsData);
  const navigate = useNavigate();
  const isChecked = detailsRecrutement?.contract?.dangerousMachineryUse === true;
  const isPublicSector = detailsRecrutement?.contract?.publicSector === true;
  const missions = detailsRecrutement?.contract?.missions;
  const remunerations = detailsRecrutement?.contract?.remunerations;
  useEffect(() => {
    if (!detailsRecrutement) {
      console.log("REDIRECT");

      navigate("/suivez-vos-contrats");
    }
  }, [detailsRecrutement]);

  const studentDetails = [
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: formatDate(detailsRecrutement?.student?.birthdate),
    },

    {
      label: t("app.suivezVosContratsPartner.EcoleDeFormation"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.trainingSchool,
    },
    {
      label: t("app.suivezVosContratsPartner.Uai"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.AnneeUniversitaire"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.schoolYear,
    },

    {
      label: t("app.suivezVosContratsPartner.Session"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.session?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Annee"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.year,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeDebutDeFormation"),
      values: formatDate(detailsRecrutement?.student?.schoolProgramEnrollment?.startDate),
    },

    {
      label: t("app.suivezVosContratsPartner.Promotion"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.promotion,
    },
    {
      label: t("app.suivezVosContratsPartner.SousPromotion"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.subPromotion,
    },
    {
      label: t("app.suivezVosContratsPartner.SousPromotionEnvisagee"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.wantedSubPromotion,
    },
    {
      label: t("app.suivezVosContratsPartner.NomDiplome"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.diploma?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.CodeDiplome"),
      values: detailsRecrutement?.student?.schoolProgramEnrollment?.diploma?.code,
    },
  ];
  const generalInformation = [
    {
      label: t("app.suivezVosContratsPartner.NomDeLentreprise"),
      values: detailsRecrutement?.company?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.siretLabel"),
      values: detailsRecrutement?.company?.siret,
    },
    {
      label: t("app.suivezVosContratsPartner.CodeNaf"),
      values: detailsRecrutement?.company?.activities?.map((CodeNaf) => CodeNaf.code),
    },
    {
      label: t("app.suivezVosContratsPartner.CodeNafDescription"),
      values: detailsRecrutement?.company?.activities?.map((CodeNaf) => CodeNaf.label),
    },
    {
      label: t("app.suivezVosContratsPartner.CodeIdcc"),
      values: detailsRecrutement?.company?.collectiveAgreement?.code,
    },
    {
      label: t("app.suivezVosContratsPartner.TypeEmployeur"),
      values: detailsRecrutement?.company?.employerType?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.FormeJuridique"),
      values: detailsRecrutement?.company?.legalForm?.code,
    },
    {
      label: t("app.suivezVosContratsPartner.Tva"),
      values: detailsRecrutement?.company?.vatNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.CaisseDeRetraite"),
      values: detailsRecrutement?.company?.pensionFund,
    },
    {
      label: t("app.suivezVosContratsPartner.Effectif"),
      values: detailsRecrutement?.company?.workforce,
    },
    {
      label: t("app.suivezVosContratsPartner.RegimeSocial"),
      values: detailsRecrutement?.company?.socialSecuritySystem?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.OrganismePrevoyance"),
      values: detailsRecrutement?.company?.providentFund,
    },
    {
      label: t("app.suivezVosContratsPartner.Adresse"),
      values: detailsRecrutement?.company?.addresses?.headquarters?.street,
    },
    {
      label: t("app.suivezVosContratsPartner.ComplementDadresse"),
      values: detailsRecrutement?.company?.addresses?.headquarters?.complement,
    },
    {
      label: t("app.suivezVosContratsPartner.CodePostal2"),
      values: detailsRecrutement?.company?.addresses?.headquarters?.zipCode,
    },
    {
      label: t("app.suivezVosContratsPartner.Ville"),
      values: detailsRecrutement?.company?.addresses?.headquarters?.city,
    },
  ];
  const signatoryAgreement = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.signatory?.civility?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.signatory?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.signatory?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.signatory?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.signatory?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.signatory?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.signatory?.function,
    },
  ];
  const apprenticeshipTax = [
    {
      label: t("app.suivezVosContratsPartner.AjouterContactTaxe"),
      values: "",
    },
    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: detailsRecrutement?.company?.contacts?.taxes?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.taxes?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.taxes?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.taxes?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.taxes?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.taxes?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.taxes?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.taxes?.function,
    },
  ];
  const administrativeContactTraining = [
    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: detailsRecrutement?.company?.contacts?.administrative?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.administrative?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.administrative?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.administrative?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.administrative?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.administrative?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.administrative?.email,
    },

    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.administrative?.function,
    },
  ];

  const establishmentContract = [
    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.email,
    },

    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.contractDrafter?.function,
    },
  ];
  const tutorApprenticeshipMaster = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.birthdate,
    },
    {
      label: t("app.suivezVosContratsPartner.SocialSecurityNumber"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.socialSecurityNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.function,
    },
    {
      label: t("app.suivezVosContratsPartner.HighestDegree"),
      values: detailsRecrutement?.company?.contacts?.tutors[0]?.maximalObtainedDiploma?.label,
    },
  ];
  const tutorApprenticeshipMaster2 = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.birthdate,
    },
    {
      label: t("app.suivezVosContratsPartner.SocialSecurityNumber"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.socialSecurityNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.email,
    },

    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.function,
    },
    {
      label: t("app.suivezVosContratsPartner.HighestDegree"),
      values: detailsRecrutement?.company?.contacts?.tutors[1]?.maximalObtainedDiploma?.label,
    },
  ];
  const contractGeneralInformation = [
    {
      label: t("app.suivezVosContratsPartner.apprenticeshipType"),
      values: detailsRecrutement?.contract?.apprenticeshipType?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.jobTitle"),
      values: detailsRecrutement?.contract?.jobTitle,
    },
    {
      label: t("app.suivezVosContratsPartner.contractType"),
      values: detailsRecrutement?.contract?.type?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.startDate"),
      values: detailsRecrutement?.contract?.startDate,
    },
    {
      label: t("app.suivezVosContratsPartner.endDate"),
      values: detailsRecrutement?.contract?.endDate,
    },
    {
      label: t("app.suivezVosContratsPartner.weeklyWorkingHours"),
      values: detailsRecrutement?.contract?.weeklyWorkingHours,
    },
    {
      label: t("app.suivezVosContratsPartner.grossSalary"),
      values: detailsRecrutement?.contract?.grossSalary,
    },
  ];

  const businessContractFinancing = [
    {
      label: t("app.suivezVosContratsPartner.AdresseDifferente"),
      values: changeBooleanToOuiNon(detailsRecrutement?.company?.addresses?.billing?.copyFromHeadquarters)?.label,
    },
    // {
    // 	label: t("app.suivezVosContratsPartner.NomDeLaSociete"),
    // 	values: detailsRecrutement?.company?.name,
    // },

    {
      label: t("app.suivezVosContratsPartner.Adresse"),
      values: detailsRecrutement?.company?.addresses?.billing?.street,
    },

    {
      label: t("app.suivezVosContratsPartner.ComplementDadresse"),
      values: detailsRecrutement?.company?.addresses?.billing?.complement,
    },
    {
      label: t("app.suivezVosContratsPartner.CodePostal2"),
      values: detailsRecrutement?.company?.addresses?.billing?.zipCode,
    },

    {
      label: t("app.suivezVosContratsPartner.Ville"),
      values: detailsRecrutement?.company?.addresses?.billing?.city,
    },

    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: detailsRecrutement?.company?.contacts?.billing?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.billing?.copyFrom?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.billing?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.billing?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.billing?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.billing?.function,
    },

    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.billing?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.billing?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.BonCommandeFactureObligatoire"),
      values: changeBooleanToOuiNon(detailsRecrutement?.contract?.financing?.purchaseOrderMandatory)?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: detailsRecrutement?.company?.contacts?.purchase?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: detailsRecrutement?.company?.contacts?.purchase?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: detailsRecrutement?.company?.contacts?.purchase?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: detailsRecrutement?.company?.contacts?.purchase?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: detailsRecrutement?.company?.contacts?.purchase?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: detailsRecrutement?.company?.contacts?.purchase?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: detailsRecrutement?.company?.contacts?.purchase?.function,
    },
  ];
  const contractFinancing = [
    {
      label: t("app.Step10ContractEnt.FromatAmount"),
      values: detailsRecrutement?.contract?.financing?.trainingAmount,
    },
    {
      label: t("app.Step10ContractEnt.NumAdh"),
      values: detailsRecrutement?.contract?.financing?.skillsOperator?.membershipNumber,
    },
    {
      label: t("app.Step10ContractEnt.NomOpco"),
      values: detailsRecrutement?.contract?.financing?.skillsOperator?.name,
    },
    {
      label: t("app.Step10ContractEnt.lvlCharge"),
      values: detailsRecrutement?.contract?.financing?.skillsOperator?.coveredAmount,
    },
    {
      label: t("app.Step10ContractEnt.ComOpco"),
      values: changeBooleanToOuiNon(detailsRecrutement?.contract?.financing?.skillsOperator?.hasContact)?.label,
    },
    {
      label: t("app.Step10ContractEnt.NameContact"),
      values: detailsRecrutement?.company?.contacts?.skillsOperator?.name,
    },
    {
      label: t("app.Step10ContractEnt.FirstName"),
      values: detailsRecrutement?.company?.contacts?.skillsOperator?.firstname,
    },
    {
      label: t("app.Step10ContractEnt.ContactPhone"),
      values: detailsRecrutement?.company?.contacts?.skillsOperator?.phones?.office,
    },
    {
      label: t("app.Step10ContractEnt.ContactMail"),
      values: detailsRecrutement?.company?.contacts?.skillsOperator?.email,
    },
    {
      label: "S'engage à financer ler RAC",
      values: detailsRecrutement?.contract?.financing?.agreementToPayRemainingCost ? t("app.referentials.Oui") : t("app.referentials.Non"),
    },
    {
      label: "Certifie Informations",
      values: detailsRecrutement?.contract?.financing?.certifiedData ? t("app.referentials.Oui") : t("app.referentials.Non"),
    },
  ];

  return (
    <div className={styles.container}>
      <Header h1={t("app.suivezVosContratsPartner.SuivezContrats")} bgImg={bgContractImg} />
      <div className={styles.containerBox}>
        <h3 className={styles.name}>
          {detailsRecrutement?.student?.name} {detailsRecrutement?.student?.firstname}
        </h3>
        <div className={styles.box}>
          {studentDetails?.map((elem) => (
            <ListDetail key={nanoid()} label={elem?.label} value={elem?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.InformationsEntreprise")}</h6>
        <div className={styles.box}>
          {generalInformation?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.SignataireDeLaConvention")}</h6>
        <div className={styles.box}>
          {signatoryAgreement?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContactTaxeDapprentissage")}</h6>
        <div className={styles.box}>
          {apprenticeshipTax?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContactAdministratifDurantLaFormation")}</h6>
        <div className={styles.box}>
          {administrativeContactTraining?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContactEtablissementDuContrat")}</h6>
        <div className={styles.box}>
          {establishmentContract?.map((item) => (
            <ListDetail key={nanoid()} label={item.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage1")}</h6>
        <div className={styles.box}>
          {tutorApprenticeshipMaster?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
        {detailsRecrutement?.company?.contacts?.tutors[1] && (
          <>
            <h6 className={styles.subtitle} style={{ paddingTop: "25px" }}>
              {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage2")}
            </h6>
            <div className={styles.box}>
              {tutorApprenticeshipMaster2?.map((item) => (
                <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContratRenseignementsGeneraux")}</h6>
        <div className={styles.box}>
          {contractGeneralInformation?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
        <div className={styles.listDetails}>
          <h6> {t("app.suivezVosContratsPartner.dangerousMachineryUse")}</h6>
          <div className={styles.flexBox}>
            <p>{t("app.suivezVosContratsPartner.dangerousMachineryUseTitle")}</p>
            {isChecked ? CheckBoxBloc(true) : CheckBoxBloc(false)}
          </div>
        </div>
        <div className={styles.listDetails}>
          <h6>{t("app.suivezVosContratsPartner.publicSector")}</h6>
          <div className={styles.flexBox}>
            {t("app.suivezVosContratsPartner.publicSectorLabel")}
            {isPublicSector ? CheckBoxBloc(true) : CheckBoxBloc(false)}
          </div>
        </div>

        {missions &&
          missions?.map((lang, index) => (
            <div className={styles.listMission} key={nanoid()}>
              <h6>
                {t("app.suivezVosContratsPartner.mission")}
                {index + 1}
              </h6>
              <p> {lang}</p>
            </div>
          ))}
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.RemunerationContratDapprentissage")}</h6>

        <div>
          {remunerations &&
            remunerations?.map((lang, index) => (
              <div key={nanoid()} className={`${styles.box}  ${styles.flex}`}>
                <div className={styles.listDetails}>
                  <h6>
                    {t("app.suivezVosContratsPartner.Annees")} {index + 1}{" "}
                    {t("app.suivezVosContratsPartner.AnneesStart")}
                  </h6>
                  <p>{lang?.startDate}</p>
                </div>
                <div className={styles.listDetails}>
                  <h6>
                    {t("app.suivezVosContratsPartner.Annees")} {index + 1}{" "}
                    {t("app.suivezVosContratsPartner.AnneesAvant")}
                  </h6>
                  <p>{lang?.endDate}</p>
                </div>
                <div className={styles.listDetails}>
                  <h6>{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</h6>
                  <p>{lang?.grossSalaryPercent}</p>
                </div>
                <div className={styles.listDetails}>
                  <h6>{t("app.suivezVosContratsPartner.TypeDeSalaire")}</h6>
                  <p>{lang?.salaryType?.label}</p>
                </div>
                <div className={styles.listDetails}>
                  <h6>{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</h6>
                  <p>{lang?.grossSalary}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.FinancementDuContrat")}</h6>
        <div className={styles.box}>
          {businessContractFinancing?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.FinancementDuContratOpco")}</h6>
        <div className={styles.box}>
          {contractFinancing?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recapitulative;
