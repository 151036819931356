import { t } from "i18next";

import { useMsal } from "@azure/msal-react";

import profilImg from "../assets/profil.png";

import styles from "./SignInBtn.module.scss";

const SignInBtn = ({ displayProfil }) => {
  const { instance, inProgress } = useMsal();

  const handleSignIn = () => {
    const scopes = ["openid"];

    if (inProgress === "none") {
      instance.loginRedirect({
        scopes,
        prompt: "select_account",
      });
    }
  };

  return (
    <div className={styles.main_container}>
      {displayProfil && <img className={styles.profil_img} src={profilImg} alt="profil" />}
      <button type="button" onClick={handleSignIn} className={styles.btnCnx}>
        {t("app.horizontalNavLogIn.SeConnecter")}
      </button>
    </div>
  );
};

export default SignInBtn;
