import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION

export const PostLanguageSkillThunk = createAsyncThunk(
  "createLanguageSkill/PostLanguageSkillThunk",
  async ({ dataToSend, cvId }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/skills/language`, dataToSend);
    return response.data;
  },
);

export const PutLanguageSkillThunk = createAsyncThunk(
  "createLanguageSkill/PostLanguageSkillThunk",
  async ({ dataToSend, cvId, id }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${cvId}/skills/language/${id}`, dataToSend);
    return response.data;
  },
);

// CREATESLICE SECTION
const createYourLanguageSkillSlice = createSlice({
  name: "createYourLanguageSkill",
  initialState,
  reducers: {},
  extraReducers: {
    [PostLanguageSkillThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostLanguageSkillThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostLanguageSkillThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [PutLanguageSkillThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PutLanguageSkillThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PutLanguageSkillThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourLanguageSkillPending = (state) => state.createYourLanguageSkill.pending;
export const getcreateYourLanguageSkillErrorMessage = (state) => state.createYourLanguageSkill.errorMessage;
export const getcreateYourLanguageSkillId = (state) => state.createYourLanguageSkill.id;
export const getcreateYourLanguageSkillData = (state) => state.createYourLanguageSkill.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourLanguageSkillSlice.reducer;
