import styles from "./Card.module.scss";

const Card = ({ path, img, h3, text }) => (
  <div className={styles.card_container}>
    <a href={path} target="_blank" rel="noopener noreferrer">
      <img src={img} alt="" />
      <div>
        <h3>{h3}</h3>
        <p>{text}</p>
      </div>
    </a>
  </div>
);

export default Card;
