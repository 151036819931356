import { t } from "i18next";

import { useSelector } from "react-redux";

import {
  getLearningBookletsIdData,
  getLearningBookletsErrorMessage,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import Header from "components/Header/Header";
import BackButton from "../components/BackButton/BackButton";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../SelectedBooklet.module.scss";

const StaticBilanInte = () => {
  const idData = useSelector(getLearningBookletsIdData); // Utilisez les data de idData pour remplir les <p> du form
  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const referentials = useSelector(getReferentialsData);
  console.log("Data", idData);
  // useEffect(() => {
  //     Dispatch(getOneThunk({
  //         id:idData
  //     }))

  // }, []);

  /* eslint-disable */
  return (
    <>
      {referentials && (
        <>
          <Header
            h1={t("app.Booklet.BilanInteTitle")}
            textElement={
              <p>
                {t("app.Booklet.BilanInteSubTitle1")} <br /> <br />
                {t("app.Booklet.BilanInteSubTitle2")}
                <br />
                <br />
                {t("app.Booklet.BilanInteSubTitle3")}
                <br />
                <br />
                {t("app.Booklet.BilanInteSubTitle4")}
                <br />
                <br />
                {t("app.Booklet.BilanInteSubTitle5")}
              </p>
            }
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <div className={styles.title_container}>
                  <h2>{t("app.Booklet.BilanInteMainSub")}</h2>
                </div>
                <form className={styles.the_form}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="adaptationIntegration" className={styles.label_text}>
                        {t("app.Booklet.adaptationIntegration")}
                      </label>
                      <span className={styles.p_text}>{idData?.teamIntegration?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="communicationTuteur" className={styles.label_text}>
                        {t("app.Booklet.communicationTuteur")}
                      </label>
                      <span className={styles.p_text}>{idData?.communication?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="travaillerEnEquipe" className={styles.label_text}>
                        {t("app.Booklet.travaillerEnEquipe")}
                      </label>
                      <span className={styles.p_text}>{idData?.workInTeam?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="partageDesInformations" className={styles.label_text}>
                        {t("app.Booklet.partageDesInformations")}
                      </label>
                      <span className={styles.p_text}>{idData?.informationSharing?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="comprehensionMission" className={styles.label_text}>
                        {t("app.Booklet.comprehensionMission")}
                      </label>
                      <span className={styles.p_text}>{idData?.understanding?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="collectionCooperation" className={styles.label_text}>
                        {t("app.Booklet.collectionCooperation")}
                      </label>
                      <span className={styles.p_text}>{idData?.teamworkAndCooperation?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="performancePoste" className={styles.label_text}>
                        {t("app.Booklet.performancePoste")}
                      </label>
                      <span className={styles.p_text}>{idData?.performance?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="fiabilite" className={styles.label_text}>
                        {t("app.Booklet.fiabilite")}
                      </label>
                      <span className={styles.p_text}>{idData?.reliability?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="investissementMission" className={styles.label_text}>
                        {t("app.Booklet.investissementMission")}
                      </label>
                      <span className={styles.p_text}>{idData?.commitment?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="appliquerMethode" className={styles.label_text}>
                        {t("app.Booklet.appliquerMethode")}
                      </label>
                      <span className={styles.p_text}>{idData?.methodical?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="maitriseDesOutils" className={styles.label_text}>
                        {t("app.Booklet.maitriseDesOutils")}
                      </label>
                      <span className={styles.p_text}>{idData?.toolsMastery?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="ponctualite" className={styles.label_text}>
                        {t("app.Booklet.ponctualite")}
                      </label>
                      <span className={styles.p_text}>{idData?.punctuality?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="assiduite" className={styles.label_text}>
                        {t("app.Booklet.assiduite")}
                      </label>
                      <span className={styles.p_text}>{idData?.attendance?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="respectDelais" className={styles.label_text}>
                        {t("app.Booklet.respectDelais")}
                      </label>
                      <span className={styles.p_text}>{idData?.deadlinesRespect?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="autonomie" className={styles.label_text}>
                        {t("app.Booklet.autonomie")}
                      </label>
                      <span className={styles.p_text}>{idData?.autonomy?.label}</span>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="qualiteTravauxEffectue" className={styles.label_text}>
                        {t("app.Booklet.qualiteTravauxEffectue")}
                      </label>
                      <span className={styles.p_text}>{idData?.workQuality?.label}</span>
                    </div>
                  </div>
                  <BackButton text={t("app.formulairesLivretPartnerBilan.BoutonRetour")} />
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default StaticBilanInte;
