import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";

import ItemSidenav from "../ItemSidenav/ItemSidenav";
import ItemDropdownSidenav from "../ItemDropdownSidenav/ItemDropdownSidenav";

import acceuilImg from "../assets/Acceuil.png";
import contractImg from "../assets/Contract.png";
import cvImg from "../assets/CV.png";
// import ficheInfoImg from "../assets/Fiche_Info.png";
import studentImg from "../assets/Student.svg";
import depotOffreImg from "../assets/Depot_Offre.png";

const SidenavENTContent = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {!isAuthenticated && <ItemSidenav name={t("app.horizontalNavLogIn.PageAccueil")} img={acceuilImg} path="/" />}
      {isAuthenticated && <ItemSidenav name={t("app.horizontalNavLogIn.PageAccueil")} img={acceuilImg} path="/" />}
      {isAuthenticated && (
        <ItemDropdownSidenav
          name={t("app.horizontalNavLogIn.MesOffres")}
          img={contractImg}
          liList={[
            {
              name: t("app.horizontalNavLogIn.DeposezUneOffre"),
              path: "deposer-une-offre",
              liImg: depotOffreImg,
            },

            {
              name: t("app.horizontalNavLogIn.SuivezVosOffres"),
              path: "mes-offres",
              liImg: contractImg,
            },
          ]}
        />
      )}

      {isAuthenticated && <ItemSidenav name={t("app.horizontalNavLogIn.Cvtheque")} img={cvImg} path="/liste-des-cvs" />}

      {/* {isAuthenticated && (
        <ItemSidenav name={t("app.horizontalNavLogIn.FicheEntreprise")} img={ficheInfoImg} path="/fiche-entreprise" />
      )} */}

      {isAuthenticated && (
        <ItemDropdownSidenav
          name={t("app.horizontalNavLogIn.SuivezContratsMenu")}
          img={contractImg}
          liList={[
            {
              name: t("app.horizontalNavLogIn.SuivezVosContrats"),
              path: "suivez-vos-contrats",
              liImg: contractImg,
            },
            // {
            //   name: t("app.horizontalNavLogIn.ConventionDeStage"),
            //   path: "conventions-de-stage",
            //   liImg: contractImg,
            // },
          ]}
        />
      )}

      {isAuthenticated && (
        <ItemSidenav
          name={t("app.horizontalNavLogIn.SuivezVosAlternants")}
          img={studentImg}
          path="/suivez-vos-alternants"
        />
      )}
    </>
  );
};

export default SidenavENTContent;
