import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { DropdownList } from "react-widgets";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { PutComputerSkillThunk } from "app/slices/createYourCV/createComputerSkill.slice";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import {
  getcreateYourExperienceErrorMessage,
  getcreateYourExperiencePending,
} from "../../../../../../app/slices/createYourCV/createYourExperience.slice";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import styles from "./Modal.module.scss";

/* eslint-disable */
const Modal = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourExperiencePending);
  const errorMessage = useSelector(getcreateYourExperienceErrorMessage);
  const cvId = useSelector(getcreateYourCVId);
  const data = props.modalData;

  const closeModal = props.closeModal;
  const modifyForm = props.modifyForm;
  const isModify = props.isModify;
  const referencialData = useSelector(getReferentialsData);
  const empty = { label: "--Aucun--", key: "--Aucun--" };
  const onSubmit = (values) => {
    dispatch(
      handleStepsData({
        formName: "languageSkillsModal",
        formData: {
          software: values.software,
          domain: values.domain.key,
        },
      }),
    );
    const id = data.id;

    const dataToSend = {
      software: values.software,
      domain: values.domain.key,
    };
    console.log("dataToSend : ", dataToSend);
    props.closeModal();
    if (!pending) {
      (async () => {
        try {
          await dispatch(
            PutComputerSkillThunk({
              dataToSend,
              id,
              cvId: cvId,
              // axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ConfirmationModifInformatiqueTitle")}
              secondMessage={t("app.toastMessages.ConfirmationModifInformatiqueMessage")}
            />,
          );
          console.log("Your experience put success !");
        } catch (error) {
          console.log("Your experience put fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      software: data.software,
      domain: data.domain,
    },
    // validationSchema: yourProfileSchema,
    onSubmit,
  });
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <div style={{ flexDirection: "column" }}>
          {isModify ? (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ModifyComputerSkill")}</span>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="domain">{t("app.depotCv.CompetencesInformatiques")}</label>

                      <DropdownList
                        id="domain"
                        dataKey="key"
                        textField="label"
                        data={[empty, ...referencialData?.STUDENT_RESUME_COMPUTER_SKILL]}
                        value={values.domain}
                        onChange={(newValue) => setFieldValue("domain", newValue)}
                      />
                      <InputErrorMessage message={errors.domain} touched={touched.domain} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="software">{t("app.librairyCV.software")}</label>
                      <input
                        id="software"
                        value={values.software}
                        onChange={(e) => setFieldValue("software", e.target.value)}
                      />
                      <InputErrorMessage message={errors.software} touched={touched.software} />
                    </div>
                  </div>
                  {/* <div className={styles.label_plus_input_container}></div> */}
                </div>
                <div className={styles.submit_button_container}>
                  <button type="submit" className={styles.submit_button}>
                    {t("app.depotCv.ValidateExperience")}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <p className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ConsultComputerSkill")} </span>
                </div>
              </p>
              <div className={styles.formContainer}>
                <div>
                  <div className={styles.etab}>
                    {t("app.depotCv.CompetencesInformatiques")}: {data.domain}
                  </div>
                  <div className={styles.institution}>
                    {t("app.librairyCV.software")}:{data.software}
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={modifyForm} className={styles.modifyButton}>
                  {t("app.depotCv.ModifyComp")}
                </button>
              </div>
            </>
          )}
        </div>

        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
