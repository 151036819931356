import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { DropdownList } from "react-widgets/cjs";

import { createBooleanReferential } from "utils/localReferentials";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useSelector, useDispatch } from "react-redux";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import {
  getAcademicExperiences,
  patchAcademicExperiencesThunk,
} from "../../../../app/slices/modifyYourCV/academicExperiences.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

/* eslint-disable */
const ModifyModalAcademicExperiences = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const referentialData = useSelector(getReferentialsData);
  const academicExperiencesById = useSelector(getAcademicExperiences);

  const YesNo = createBooleanReferential();
  const inProgress = { key: "En cours", label: "En cours" };
  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const handleProps = (value) => {
    if (value !== undefined) {
      setFieldValue("city", value.city);
      setFieldValue("country", value.country);
      setFieldValue("course", value.course);
      setFieldValue("description", value.description);
      setFieldValue("endDate", new Date(value.endDate));
      setFieldValue("graduationSuccessIndicator", value.graduationSuccessIndicator);
      setFieldValue("institution", value.institution);
      setFieldValue("startDate", new Date(value.startDate));
    }
  };
  useEffect(() => {
    handleProps(props.object);
  }, [props.object]);

  const onSubmit = (values) => {
    const dataToSend = {
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      institution: values.institution,
      course: values.course,
      graduationSuccessIndicator: values.graduationSuccessIndicator ? values.graduationSuccessIndicator.key : "-Aucun-",
      description: values.description,
      country: values.country ? values.country.key : "-Aucun-",
      city: values.city,
    };
    (async () => {
      try {
        await dispatch(
          patchAcademicExperiencesThunk({
            dataToSend,
            id: id,
            ID: props.id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationModifExpAcademicTitle")}
            secondMessage={t("app.toastMessages.ConfirmationModifExpAcademicMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const ModifyAcademicExperiencesSchema = Yup.object().shape({
    institution: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    course: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    graduationSuccessIndicator: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    description: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    country: Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      institution: props.object?.institution ? props.object?.institution : "",
      course: props.object?.course ? props.object?.course : "",
      startDate: null,
      endDate: null,
      graduationSuccessIndicator: props.object?.graduationSuccessIndicator
        ? {
            key: props.object?.graduationSuccessIndicator?.key,
            label: props.object?.graduationSuccessIndicator?.label,
          }
        : { ...objAucun },
      description: "",
      country: props.object?.country ? props.object?.country : { ...objAucun },
      city: props.object?.city ? props.object?.city : "",
    },
    validationSchema: ModifyAcademicExperiencesSchema,
    onSubmit,
  });

  if (props.showModify && academicExperiencesById)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModificationExperienceAcademic")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="etablissement">
                    {t("app.visualisationCv.Etablissement")}
                  </label>
                  <input
                    style={
                      errors.institution &&
                      touched.institution && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.institution}
                    maxLength={80}
                    type="text"
                    name="institution"
                    id="institution"
                    onChange={(e) => setFieldValue("institution", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.institution} touched={touched.institution} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="course">
                    {t("app.visualisationCv.FormationSuivie")}
                  </label>
                  <input
                    style={
                      errors.course &&
                      touched.course && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.course}
                    maxLength={80}
                    type="text"
                    name="course"
                    id="course"
                    onChange={(e) => setFieldValue("course", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.course} touched={touched.course} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  {/* <DatePicker
										style={
											errors.startDate &&
											touched.startDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.startDate}
										onChange={(value) => setFieldValue("startDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  {/* <DatePicker
										style={
											errors.endDate &&
											touched.endDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.endDate}
										onChange={(value) => setFieldValue("endDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.endDate}
                    touched={touched.endDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                  <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="graduationSuccessIndicator">
                    {t("app.visualisationCv.DiplomeObtenu")}
                  </label>
                  <DropdownList
                    style={
                      errors.graduationSuccessIndicator &&
                      touched.graduationSuccessIndicator && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="graduationSuccessIndicator"
                    dataKey="key"
                    textField="label"
                    data={[...YesNo, { ...inProgress }]}
                    value={values.graduationSuccessIndicator}
                    onChange={(newValue) => {
                      setFieldValue("graduationSuccessIndicator", newValue);
                    }}
                  />
                  <InputErrorMessage
                    message={errors.graduationSuccessIndicator}
                    touched={touched.graduationSuccessIndicator}
                  />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="typeOfContract">
                    {t("app.visualisationCv.Description")}
                  </label>
                  <textarea
                    style={
                      errors.description &&
                      touched.description && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.description}
                    maxLength={500}
                    type="description"
                    name="description"
                    id="description"
                    onChange={(e) => setFieldValue("description", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.description} touched={touched.description} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="country">
                    {t("app.visualisationCv.Pays")}
                  </label>
                  <DropdownList
                    style={
                      errors.country &&
                      touched.country && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="country"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.COUNTRY]}
                    value={values.country}
                    onChange={(newValue) => {
                      setFieldValue("country", newValue);
                    }}
                  />

                  <InputErrorMessage message={errors.country} touched={touched.country} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="city">
                    {t("app.visualisationCv.Ville")}
                  </label>
                  <input
                    value={values.city}
                    maxLength={50}
                    type="text"
                    name="city"
                    id="city"
                    onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
                  />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezModification")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showModify) return null;
};

export default ModifyModalAcademicExperiences;
