import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getAcademicExperiencesThunk = createAsyncThunk(
  "academicExperiences/getAcademicExperiencesThunk",
  async (id) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/academic`);
    return response.data;
  },
);

export const getAcademicExperiencesByIdThunk = createAsyncThunk(
  "academicExperiences/getAcademicExperiencesByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/academic/${ID}`);
    return response.data;
  },
);

export const postAcademicExperiencesThunk = createAsyncThunk(
  "academicExperiences/postAcademicExperiencesThunk",
  async ({ dataToSend, id }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${id}/experiences/academic`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

export const patchAcademicExperiencesThunk = createAsyncThunk(
  "academicExperiences/patchAcademicExperiencesThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/experiences/academic/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const academicExperiencesSlice = createSlice({
  name: "academicExperiences",
  initialState,
  extraReducers: {
    [getAcademicExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getAcademicExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAcademicExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [getAcademicExperiencesByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getAcademicExperiencesByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAcademicExperiencesByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postAcademicExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postAcademicExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postAcademicExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchAcademicExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchAcademicExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchAcademicExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

export const getAcademicExperiences = (state) => state.academicExperiences.data;
export const getAcademicExperiencesPending = (state) => state.academicExperiences.pending;

export default academicExperiencesSlice.reducer;
