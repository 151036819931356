import { t } from "i18next";
import { Link } from "react-router-dom";

import styles from "./Card.module.scss";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { ReactComponent as Pencil } from "../assets/pencil.svg";

/* eslint-disable */
const ProjectCard = (props) => {
  const handleModalData = props.handleModalData;
  const ModifyForm = props.modifyForm;
  return (
    <div className={styles.card_container}>
      <div className={styles.cv_id}>{props.title}</div>
      <div className={styles.date}>
        {t("app.depotCv.du")} {props.startDate} {t("app.depotCv.au")} {props.endDate}
      </div>
      <div className={styles.edit_link}>
        <div
          onClick={() => handleModalData(props)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Eye width="1rem" style={{ marginRight: "2%" }} />
          <Link>{t("app.depotCv.Consult")}</Link>
        </div>
        <div
          onClick={() => ModifyForm(props)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Pencil width="1rem" style={{ marginRight: "2%" }} />
          <Link>{t("app.depotCv.Modify")}</Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
