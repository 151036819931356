import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "fr",
  pending: false,
  errorMessage: null,
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    changeLanguage(state, action) {
      state.data = action.payload;
    },
  },
});

export const getLanguage = (state) => state.languages.data;

export const { changeLanguage } = languagesSlice.actions;

export default languagesSlice.reducer;
