import Header from "../../components/Header/Header";

import illustrationPageNotFoundImg from "./assets/illustration-page-not-found.png";

// import styles from "./Subdomain404.module.scss";

const Subdomain404 = () => (
  <Header h1="ERREUR 404 !" textElement={<p>Ce sous-domaine n'existe pas.</p>} bgImg={illustrationPageNotFoundImg} />
);

export default Subdomain404;
