import { useState } from "react";

import Accordion from "components/accordion/accordion";
import { t } from "i18next";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { getLanguage } from "app/slices/language/language.slice";

import styles from "./Faq.module.scss";

const Faq = () => {
  const [isStageVisible, setIsStageVisible] = useState(true);
  const [isAlternanceVisible, setIsAlternanceVisible] = useState(false);
  const languageSelector = useSelector(getLanguage);

  const handleStagePress = () => {
    setIsStageVisible((isVisible) => !isVisible);
    setIsAlternanceVisible(false);
  };

  const handleAlternancePress = () => {
    setIsAlternanceVisible((isVisible) => !isVisible);
    setIsStageVisible(false);
  };
  const questionAlternance = [
    {
      title: `${t("app.faqStudentAlternance.Question1")}`,
      content: `${t("app.faqStudentAlternance.Answer1")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question2")}`,
      content: `${t("app.faqStudentAlternance.Answer2")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question3")}`,
      content: `${t("app.faqStudentAlternance.Answer3")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question4")}`,
      content: `${t("app.faqStudentAlternance.Answer4")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question5")}`,
      content: `${t("app.faqStudentAlternance.Answer5")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question6")}`,
      content: `${t("app.faqStudentAlternance.Answer6")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question7")}`,
      content: `${t("app.faqStudentAlternance.Answer7")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question8")}`,
      content: `${t("app.faqStudentAlternance.Answer8")}`,
    },
    {
      title: `${t("app.faqStudentAlternance.Question9")}`,
      content: `${t("app.faqStudentAlternance.Answer9")}`,
    },
  ];

  const questionStage = [
    {
      title: `${t("app.faqStudentStage.Question1")}`,
      content: `${t("app.faqStudentStage.Answer1")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question2")}`,
      content: `${t("app.faqStudentStage.Answer2")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question3")}`,
      content: `${t("app.faqStudentStage.Answer3")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question4")}`,
      content: `${t("app.faqStudentStage.Answer4")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question5")}`,
      content: `${t("app.faqStudentStage.Answer5")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question6")}`,
      content: `${t("app.faqStudentStage.Answer6")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question7")}`,
      content: `${t("app.faqStudentStage.Answer7")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question8")}`,
      content: `${t("app.faqStudentStage.Answer8")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question9")}`,
      content: `${t("app.faqStudentStage.Answer9")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question10")}`,
      content: `${t("app.faqStudentStage.Answer10")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question11")}`,
      content: `${t("app.faqStudentStage.Answer11")}`,
    },
    {
      title: `${t("app.faqStudentStage.Question12")}`,
      content: `${t("app.faqStudentStage.Answer12")}`,
    },
  ];

  /* eslint-disable */
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("app.faqEntreprise.intro1")}</h1>
        <p>
          {t("app.faqEntreprise.Description1")}
          <br />
          {t("app.faqEntreprise.Description2")}
          <br />
          {t("app.faqEntreprise.Description3")}
          {languageSelector === "fr" ? (
            <a href="/statics/CGU_Nemo_OmnesEducation.pdf" target="_blank">
              CGU
            </a>
          ) : (
            <a href="/statics/CGU_Nemo_OmnesEducation_ENG.pdf" target="_blank">
              CGU
            </a>
          )}
        </p>
        <div className={styles.tab}>
          <button onClick={handleStagePress} className={`${styles.stage} ${isStageVisible ? ` ${styles.active}` : ""}`}>
            {t("app.faqStudentStage.btnStage")}
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.9006 2.52499L8.24797 9.69037C8.05326 9.89807 7.72874 9.89807 7.53403 9.69037L0.881385 2.52499C0.686674 2.3173 0.686674 1.97115 0.881385 1.76345L1.59533 1.00191C1.79004 0.794223 2.11456 0.794223 2.30927 1.00191L7.53403 6.64422C7.72874 6.85191 8.05326 6.85191 8.24797 6.64422L13.4727 1.03653C13.6674 0.828838 13.992 0.828838 14.1867 1.03653L14.9006 1.79807C15.0629 2.00576 15.0629 2.3173 14.9006 2.52499Z"
                fill="#3E0056"
              />
            </svg>
          </button>
          <button
            onClick={handleAlternancePress}
            className={`${styles.alternance} ${isAlternanceVisible ? ` ${styles.active}` : ""}`}
          >
            {t("app.faqStudentAlternance.btnAlternance")}
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.9006 2.52499L8.24797 9.69037C8.05326 9.89807 7.72874 9.89807 7.53403 9.69037L0.881385 2.52499C0.686674 2.3173 0.686674 1.97115 0.881385 1.76345L1.59533 1.00191C1.79004 0.794223 2.11456 0.794223 2.30927 1.00191L7.53403 6.64422C7.72874 6.85191 8.05326 6.85191 8.24797 6.64422L13.4727 1.03653C13.6674 0.828838 13.992 0.828838 14.1867 1.03653L14.9006 1.79807C15.0629 2.00576 15.0629 2.3173 14.9006 2.52499Z"
                fill="#3E0056"
              />
            </svg>
          </button>
        </div>
      </div>
      {isStageVisible && (
        <div className={styles.content}>
          {questionStage?.map((item) => (
            <Accordion title={item.title} content={item.content} key={nanoid()} />
          ))}
        </div>
      )}
      {isAlternanceVisible && (
        <div className={styles.content}>
          {questionAlternance?.map((ss) => (
            <Accordion title={ss.title} content={ss.content} key={nanoid()} />
          ))}
        </div>
      )}
    </div>
  );
};
export default Faq;
