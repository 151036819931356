import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import Loader from "components/Loader/Loader";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import Header from "components/Header/Header";

import {
  patchRecruitmentsThunk,
  getYourRecruitmentPropsData,
  getUserRecruitmentsPending,
  handleRecruitmentPropsData,
} from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import {
  patchRecruitmentsForm1Thunk,
  getUserRecruitmentsPendingForm1,
  getUserRecruitmentsErrorMessageForm1,
} from "app/slices/companyRecruitment/Form1/getCompanyRecruitmentForm1.slice";
import {
  patchRecruitmentsForm2Thunk,
  getUserRecruitmentsPendingForm2,
  getUserRecruitmentsErrorMessageForm2,
} from "app/slices/companyRecruitment/Form2/getCompanyRecruitmentForm2.slice";
import {
  patchRecruitmentsForm3Thunk,
  getUserRecruitmentsPendingForm3,
  getUserRecruitmentsErrorMessageForm3,
} from "app/slices/companyRecruitment/Form3/getCompanyRecruitmentForm3.slice";
import {
  patchRecruitmentsForm4Thunk,
  getUserRecruitmentsPendingForm4,
  getUserRecruitmentsErrorMessageForm4,
} from "app/slices/companyRecruitment/Form4/getCompanyRecruitmentForm4.slice";
import {
  patchRecruitmentsForm5Thunk,
  getUserRecruitmentsPendingForm5,
  getUserRecruitmentsErrorMessageForm5,
} from "app/slices/companyRecruitment/Form5/getCompanyRecruitmentForm5.slice";
import {
  patchRecruitmentsForm6Thunk,
  getUserRecruitmentsPendingForm6,
  getUserRecruitmentsErrorMessageForm6,
} from "app/slices/companyRecruitment/Form6/getCompanyRecruitmentForm6.slice";
import {
  patchRecruitmentsForm7Thunk,
  getUserRecruitmentsPendingForm7,
  getUserRecruitmentsErrorMessageForm7,
} from "app/slices/companyRecruitment/Form7/getCompanyRecruitmentForm7.slice";
import {
  patchRecruitmentsForm8Thunk,
  getUserRecruitmentsPendingForm8,
  getUserRecruitmentsErrorMessageForm8,
} from "app/slices/companyRecruitment/Form8/getCompanyRecruitmentForm8.slice";
import {
  patchRecruitmentsForm9Thunk,
  getUserRecruitmentsPendingForm9,
  getUserRecruitmentsErrorMessageForm9,
} from "app/slices/companyRecruitment/Form9/getCompanyRecruitmentForm9.slice";
import {
  patchRecruitmentsForm10Thunk,
  getUserRecruitmentsPendingForm10,
  getUserRecruitmentsErrorMessageForm10,
} from "app/slices/companyRecruitment/Form10/getCompanyRecruitmentForm10.slice";

import Sections from "./Sections/Sections";

import { getReferentialsData } from "../../../../../app/slices/referentials/referentials.slice";

import StudientSection from "./conponents/StudientSection";

import TeletransmisionModal from "./conponents/Modal/TeletransmisionModal";
import BlueBanner from "./conponents/BlueBanner";

import Form1 from "./Sections/Forms/Form1/Form1";
import Form2 from "./Sections/Forms/Form2/Form2";
import Form3 from "./Sections/Forms/Form3/Form3";
import Form4 from "./Sections/Forms/Form4/Form4";
import Form5 from "./Sections/Forms/Form5/Form5";
import Form6 from "./Sections/Forms/Form6/Form6";
import Form7 from "./Sections/Forms/Form7/Form7";
import Form8 from "./Sections/Forms/Form8/Form8";
import Form9 from "./Sections/Forms/Form9/Form9";
import Form10 from "./Sections/Forms/Form10/Form10";

import bgContractImg from "../../../PublishAnOffer/assets/bg-consul-offre.png";
import styles from "./PartTimeDetails.module.scss";

/* eslint-disable */
const PartTimeDetail = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const referencialData = useSelector(getReferentialsData);

  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);

  const recruitmentPendingForm1 = useSelector(getUserRecruitmentsPendingForm1);
  const recruitmentErrorForm1 = useSelector(getUserRecruitmentsErrorMessageForm1);

  const recruitmentPendingForm2 = useSelector(getUserRecruitmentsPendingForm2);
  const recruitmentErrorForm2 = useSelector(getUserRecruitmentsErrorMessageForm2);

  const recruitmentPendingForm3 = useSelector(getUserRecruitmentsPendingForm3);
  const recruitmentErrorForm3 = useSelector(getUserRecruitmentsErrorMessageForm3);

  const recruitmentPendingForm4 = useSelector(getUserRecruitmentsPendingForm4);
  const recruitmentErrorForm4 = useSelector(getUserRecruitmentsErrorMessageForm4);

  const recruitmentPendingForm5 = useSelector(getUserRecruitmentsPendingForm5);
  const recruitmentErrorForm5 = useSelector(getUserRecruitmentsErrorMessageForm5);

  const recruitmentPendingForm6 = useSelector(getUserRecruitmentsPendingForm6);
  const recruitmentErrorForm6 = useSelector(getUserRecruitmentsErrorMessageForm6);

  const recruitmentPendingForm7 = useSelector(getUserRecruitmentsPendingForm7);
  const recruitmentErrorForm7 = useSelector(getUserRecruitmentsErrorMessageForm7);

  const recruitmentPendingForm8 = useSelector(getUserRecruitmentsPendingForm8);
  const recruitmentErrorForm8 = useSelector(getUserRecruitmentsErrorMessageForm8);

  const recruitmentPendingForm9 = useSelector(getUserRecruitmentsPendingForm9);
  const recruitmentErrorForm9 = useSelector(getUserRecruitmentsErrorMessageForm9);

  const recruitmentPendingForm10 = useSelector(getUserRecruitmentsPendingForm10);
  const recruitmentErrorForm10 = useSelector(getUserRecruitmentsErrorMessageForm10);

  const finalPatchSubmitPending = useSelector(getUserRecruitmentsPending);

  const [submitAllSections, setSubmitAllSections] = useState(false);
  const [section1Open, setSection1Open] = useState(false);
  const [section2Open, setSection2Open] = useState(false);
  const [section3Open, setSection3Open] = useState(false);
  const [section4Open, setSection4Open] = useState(false);
  const [section5Open, setSection5Open] = useState(false);
  const [section6Open, setSection6Open] = useState(false);
  const [section7Open, setSection7Open] = useState(false);
  const [section8Open, setSection8Open] = useState(false);
  const [section9Open, setSection9Open] = useState(false);
  const [section10Open, setSection10Open] = useState(false);

  const [areaFufiledForm1, setAreaFufiledForm1] = useState(false);
  const [areaFufiledForm2, setAreaFufiledForm2] = useState(false);
  const [areaFufiledForm3, setAreaFufiledForm3] = useState(false);
  const [areaFufiledForm4, setAreaFufiledForm4] = useState(false);
  const [areaFufiledForm5, setAreaFufiledForm5] = useState(false);
  const [areaFufiledForm6, setAreaFufiledForm6] = useState(false);
  const [areaFufiledForm7, setAreaFufiledForm7] = useState(false);
  const [areaFufiledForm8, setAreaFufiledForm8] = useState(false);
  const [areaFufiledForm9, setAreaFufiledForm9] = useState(false);
  const [areaFufiledForm10, setAreaFufiledForm10] = useState(false);

  const [successForm1, setSuccessForm1] = useState(false);
  const [successForm2, setSuccessForm2] = useState(false);
  const [successForm3, setSuccessForm3] = useState(false);
  const [successForm4, setSuccessForm4] = useState(false);
  const [successForm5, setSuccessForm5] = useState(false);
  const [successForm6, setSuccessForm6] = useState(false);
  const [successForm7, setSuccessForm7] = useState(false);
  const [successForm8, setSuccessForm8] = useState(false);
  const [successForm9, setSuccessForm9] = useState(false);
  const [successForm10, setSuccessForm10] = useState(false);

  const [successServerForm1, setSuccessServerForm1] = useState(false);
  const [successServerForm2, setSuccessServerForm2] = useState(false);
  const [successServerForm3, setSuccessServerForm3] = useState(false);
  const [successServerForm4, setSuccessServerForm4] = useState(false);
  const [successServerForm5, setSuccessServerForm5] = useState(false);
  const [successServerForm6, setSuccessServerForm6] = useState(false);
  const [successServerForm7, setSuccessServerForm7] = useState(false);
  const [successServerForm8, setSuccessServerForm8] = useState(false);
  const [successServerForm9, setSuccessServerForm9] = useState(false);
  const [successServerForm10, setSuccessServerForm10] = useState(false);

  useEffect(() => {
    if (
      successServerForm1 &&
      successServerForm2 &&
      successServerForm3 &&
      successServerForm4 &&
      successServerForm5 &&
      successServerForm6 &&
      successServerForm7 &&
      successServerForm8 &&
      successServerForm9 &&
      successServerForm10
    ) {
      console.log("sucess200");
      const valuesCopy = structuredClone(values);
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      const dataToSend = {
        status: "Soumis",
        contract: {
          financing: {
            skillsOperator: {
              teletransmission: valuesCopy.teletransmissionCheckbox,
            },
          },
        },
        electronicSignature: valuesCopy.electronicSignCheckbox,
      };

      if (!finalPatchSubmitPending) {
        (async () => {
          try {
            await dispatch(
              patchRecruitmentsThunk({
                dataToSend,
                id: recruitmentPropsGeneral.id,
                axiosCancelToken: axiosCancelToken,
              }),
            )
              .unwrap()
              .then((res) => navigateToStaticPage(res));
            console.log("RecuitmentENT PatchGolobal success !", dataToSend);
          } catch (error) {
            console.log("RecuitmentENT PatchGolobal fail !", error.message);
          }
        })();
      }
    }
  }, [
    successServerForm1,
    successServerForm2,
    successServerForm3,
    successServerForm4,
    successServerForm5,
    successServerForm6,
    successServerForm7,
    successServerForm8,
    successServerForm9,
    successServerForm10,
  ]);

  const [submitAllPending, setSubmitAllPending] = useState(false);
  const [submitAllFailed, setSubmitAllFailed] = useState(false);
  const [numberCallsSuccess, setNumberCallsSuccess] = useState(0);

  const [globalDataToSend, setGlobalDataToSend] = useState(null);

  const [doubleClickPending, setDoubleClickPending] = useState(false);
  const buttonRef = useRef(null);

  const [showTransmisionModal, setShowTransmisionModal] = useState(false);

  useEffect(() => {
    if (!recruitmentPropsGeneral) {
      console.log("REDIRECT");
      navigate("/suivez-vos-contrats");
    }
  }, [recruitmentPropsGeneral]);

  // Error toast for submitAllSections
  useEffect(() => {
    console.log("submitAllSections in main", submitAllSections);
    if (
      submitAllSections &&
      (!areaFufiledForm1 ||
        !areaFufiledForm2 ||
        !areaFufiledForm3 ||
        !areaFufiledForm4 ||
        !areaFufiledForm5 ||
        !areaFufiledForm6 ||
        !areaFufiledForm7 ||
        !areaFufiledForm8 ||
        !areaFufiledForm9 ||
        !areaFufiledForm10)
    ) {
      console.log("USE TOAST ERROR ALL");
      toastError(<ToastMessageStructure secondMessage={t("app.toastMessages.ErrorValidateAllSections")} />);
    }
  }, [submitAllSections]);

  useEffect(() => {
    if (
      submitAllPending &&
      numberCallsSuccess === 10 &&
      !submitAllFailed &&
      values.validData &&
      successForm1 &&
      successForm2 &&
      successForm3 &&
      successForm4 &&
      successForm5 &&
      successForm6 &&
      successForm7 &&
      successForm8 &&
      successForm9 &&
      successForm10
    ) {
      console.log(
        "CASE PATH 0 INSIDE IF",
        submitAllSections,
        successForm1,
        successForm2,
        successForm3,
        successForm4,
        successForm5,
        successForm6,
        successForm7,
        successForm8,
        successForm9,
        successForm10,
      );

      setSuccessForm1(false);
      setSuccessForm2(false);
      setSuccessForm3(false);
      setSuccessForm4(false);
      setSuccessForm5(false);
      setSuccessForm6(false);
      setSuccessForm7(false);
      setSuccessForm8(false);
      setSuccessForm9(false);
      setSuccessForm10(false);
      setSubmitAllPending(false);
      setNumberCallsSuccess(0);
      setSubmitAllFailed(false);
    }
  }, [
    submitAllSections,
    successForm1,
    successForm2,
    successForm3,
    successForm4,
    successForm5,
    successForm6,
    successForm7,
    successForm8,
    successForm9,
    successForm10,
    submitAllPending,
    numberCallsSuccess,
    submitAllFailed,
  ]);

  const hangleMutlipleClicks = () => {
    if (!doubleClickPending && buttonRef.current) {
      setDoubleClickPending(true);
      setSubmitAllPending(true);
      setNumberCallsSuccess(0);
      setSubmitAllFailed(false);
      setTimeout(() => {
        buttonRef.current.click();
        console.log("click");
        setDoubleClickPending(false);
      }, 100);
    }

    console.log(buttonRef, "useRef hangleMutlipleClicks");
  };

  const PartTimeSchema = Yup.object().shape({
    teletransmissionCheckbox: Yup.boolean(),

    electronicSignCheckbox: Yup.boolean(),

    validData: Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  const onSubmit = (values) => {
    console.log("CONTRACT VALUE TO SEND 1");
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "Soumis",
      contract: {
        financing: {
          skillsOperator: {
            teletransmission: valuesCopy.teletransmissionCheckbox,
          },
        },
      },
      electronicSignature: valuesCopy.electronicSignCheckbox,
    };

    setGlobalDataToSend(dataToSend);

    submitAll();

    hangleMutlipleClicks();
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      teletransmissionCheckbox: recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.teletransmission
        ? recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.teletransmission
        : false,
      electronicSignCheckbox: recruitmentPropsGeneral?.electronicSignature
        ? recruitmentPropsGeneral?.electronicSignature
        : false,
      validData: false,
    },
    validationSchema: PartTimeSchema,
    onSubmit,
  });

  const navigateToStaticPage = (res) => {
    dispatch(handleRecruitmentPropsData(res));
    navigate("/suivez-vos-contrats/" + recruitmentPropsGeneral.id + "/recap");
  };

  const submitSection = (dataToSend, section) => {
    switch (section) {
      case 1:
        console.log("CASE PATH 1");
        if (!recruitmentPendingForm1) {
          (async () => {
            try {
              setSuccessServerForm1(false);
              await dispatch(
                patchRecruitmentsForm1Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm1(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifRenseignementGeneraux")} />,
                );
                setSuccessForm1(true);
              } else {
                setSuccessForm1(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm1 success !", dataToSend);
              setAreaFufiledForm1(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              setSuccessForm1(false);
              console.log("RecuitmentENT PatchForm1 fail !", error.message);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm1(false);
            }
          })();
        }
        break;
      case 2:
        console.log("CASE PATH 2");
        if (!recruitmentPendingForm2) {
          (async () => {
            try {
              setSuccessServerForm2(false);
              await dispatch(
                patchRecruitmentsForm2Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm2(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactSignataireConvention")} />,
                );
                setSuccessForm2(true);
              } else {
                setSuccessForm2(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm2 success !", dataToSend);
              setAreaFufiledForm2(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm2 fail !", error.message);
              setSuccessForm2(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm2(false);
            }
          })();
        }
        break;
      case 3:
        console.log("CASE PATH 3");
        if (!recruitmentPendingForm3) {
          (async () => {
            try {
              setSuccessServerForm3(false);
              await dispatch(
                patchRecruitmentsForm3Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm3(true);
                });
              if (!submitAllSections) {
                toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactTA")} />);
                setSuccessForm3(true);
              } else {
                setSuccessForm3(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm3 success !", dataToSend);
              setAreaFufiledForm3(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm3 fail !", error.message);
              setSuccessForm3(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm3(false);
            }
          })();
        }
        break;
      case 4:
        console.log("CASE PATH 4");
        if (!recruitmentPendingForm4) {
          (async () => {
            try {
              setSuccessServerForm4(false);
              await dispatch(
                patchRecruitmentsForm4Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm4(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactAdminFormation")} />,
                );
                setSuccessForm4(true);
              } else {
                submitSection(globalDataToSend, 0);
                setSuccessForm4(true);
              }
              console.log("RecuitmentENT PatchForm4 success !", dataToSend);
              setAreaFufiledForm4(true);
              console.log("NEW RECRUIT DATA RESPONSE", recruitmentPropsGeneral);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm4 fail !", error.message);
              setSuccessForm4(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm4(false);
            }
          })();
          console.log("NEW RECRUIT DATA RESPONSE", recruitmentPropsGeneral);
        }
        break;
      case 5:
        console.log("CASE PATH 5");
        if (!recruitmentPendingForm5) {
          (async () => {
            try {
              setSuccessServerForm5(false);
              await dispatch(
                patchRecruitmentsForm5Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm5(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactEtablissementContrat")} />,
                );
                setSuccessForm5(true);
              } else {
                submitSection(globalDataToSend, 0);
                setSuccessForm5(true);
              }
              console.log("RecuitmentENT PatchForm5 success !", dataToSend);
              setAreaFufiledForm5(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm5 fail !", error.message);
              setAreaFufiledForm5(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm5(false);
            }
          })();
        }
        break;
      case 6:
        console.log("CASE PATH 6");
        if (!recruitmentPendingForm6) {
          (async () => {
            try {
              setSuccessServerForm6(false);
              await dispatch(
                patchRecruitmentsForm6Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm6(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactTuteurMaitreApp")} />,
                );
                setSuccessForm6(true);
              } else {
                setSuccessForm6(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm6 success !", dataToSend);
              setAreaFufiledForm6(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm6 fail !", error.message);
              setSuccessForm6(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm6(false);
            }
          })();
        }
        break;
      case 7:
        console.log("CASE PATH 7");
        if (!recruitmentPendingForm7) {
          (async () => {
            try {
              setSuccessServerForm7(false);
              await dispatch(
                patchRecruitmentsForm7Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm7(true);
                });
              if (!submitAllSections) {
                toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContratRensGeneraux")} />);
                setSuccessForm7(true);
              } else {
                setSuccessForm7(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm7 success !", dataToSend);
              setAreaFufiledForm7(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              setSuccessForm7(false);
              console.log("RecuitmentENT PatchForm7 fail !", error.message);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm7(false);
            }
          })();
        }
        break;
      case 8:
        console.log("CASE PATH 8");
        if (!recruitmentPendingForm8) {
          (async () => {
            try {
              setSuccessServerForm8(false);
              await dispatch(
                patchRecruitmentsForm8Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm8(true);
                });
              if (!submitAllSections) {
                toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifRemuContratApp")} />);
                setSuccessForm8(true);
              } else {
                setSuccessForm8(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm8 success !", dataToSend);
              setAreaFufiledForm8(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              setSuccessForm8(false);
              console.log("RecuitmentENT PatchForm8 fail !", error.message);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm8(false);
            }
          })();
        }
        break;
      case 9:
        console.log("CASE PATH 9");
        if (!recruitmentPendingForm9) {
          (async () => {
            try {
              setSuccessServerForm9(false);
              await dispatch(
                patchRecruitmentsForm9Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm9(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifFinancementContratEnt")} />,
                );
                setSuccessForm9(true);
              } else {
                setSuccessForm9(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm9 success !", dataToSend);
              setAreaFufiledForm9(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              setSuccessForm9(false);
              console.log("RecuitmentENT PatchForm9 fail !", error.message);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm9(false);
            }
          })();
        }
        break;
      case 10:
        console.log("CASE PATH 10");
        if (!recruitmentPendingForm10) {
          (async () => {
            try {
              setSuccessServerForm10(false);
              await dispatch(
                patchRecruitmentsForm10Thunk({
                  dataToSend,
                  id: recruitmentPropsGeneral.id,
                  axiosCancelToken: axiosCancelToken,
                }),
              )
                .unwrap()
                .then((res) => {
                  dispatch(handleRecruitmentPropsData(res));
                  setSuccessServerForm10(true);
                });
              if (!submitAllSections) {
                toastSuccess(
                  <ToastMessageStructure secondMessage={t("app.toastMessages.ModifFinancementContratOPCO")} />,
                );
                setSuccessForm10(true);
              } else {
                setSuccessForm10(true);
                submitSection(globalDataToSend, 0);
              }
              console.log("RecuitmentENT PatchForm10 success !", dataToSend);
              setAreaFufiledForm10(true);

              if (submitAllPending && !submitAllFailed) {
                setNumberCallsSuccess((value) => value + 1);
              } else if (submitAllFailed) {
                setSubmitAllPending(false);
                setNumberCallsSuccess(0);
              }
            } catch (error) {
              console.log("RecuitmentENT PatchForm10 fail !", error.message);
              setSuccessForm10(false);

              setSubmitAllPending(false);
              setNumberCallsSuccess(0);
              setSubmitAllFailed(true);

              setSuccessServerForm10(false);
            }
          })();
        }
        break;
      case 0:
        // console.log(
        // 	"CASE PATH 0 OUTSIDE IF",
        // 	submitAllSections,
        // 	successForm1,
        // 	successForm2,
        // 	successForm3,
        // 	successForm4,
        // 	successForm5,
        // 	successForm6,
        // 	successForm7,
        // 	successForm8,
        // 	successForm9,
        // 	successForm10
        // );
        // if (
        // 	submitAllSections &&
        // 	successForm1 &&
        // 	successForm2 &&
        // 	successForm3 &&
        // 	successForm4 &&
        // 	successForm5 &&
        // 	successForm6 &&
        // 	successForm7 &&
        // 	successForm8 &&
        // 	successForm9 &&
        // 	successForm10
        // ) {
        // 	console.log(
        // 		"CASE PATH 0 INSIDE IF",
        // 		submitAllSections,
        // 		successForm1,
        // 		successForm2,
        // 		successForm3,
        // 		successForm4,
        // 		successForm5,
        // 		successForm6,
        // 		successForm7,
        // 		successForm8,
        // 		successForm9,
        // 		successForm10
        // 	);
        // 	(async () => {
        // 		try {
        // 			await dispatch(
        // 				patchRecruitmentsThunk({
        // 					dataToSend,
        // 					id: recruitmentPropsGeneral.id,
        // 					axiosCancelToken: axiosCancelToken,
        // 				})
        // 			)
        // 				.unwrap()
        // 				.then((res) => navigateToStaticPage(res));
        // 			console.log("RecuitmentENT PatchGolobal success !", dataToSend);
        // 		} catch (error) {
        // 			console.log("RecuitmentENT PatchGolobal fail !", error.message);
        // 		}
        // 	})();
        // 	setSuccessForm1(false);
        // 	setSuccessForm2(false);
        // 	setSuccessForm3(false);
        // 	setSuccessForm4(false);
        // 	setSuccessForm5(false);
        // 	setSuccessForm6(false);
        // 	setSuccessForm7(false);
        // 	setSuccessForm8(false);
        // 	setSuccessForm9(false);
        // 	setSuccessForm10(false);
        // 	return;
        // }
        break;
      default:
        break;
    }
  };

  const submitAll = () => {
    setSubmitAllSections(true);
  };

  const handleCheckModal = (value) => {
    if (value === false) {
      console.log("CHECKD FALSE");
      setShowTransmisionModal(true);
    } else {
      setShowTransmisionModal(false);
    }
  };

  console.log("submitAllPending", submitAllPending);
  console.log("numberCallsSuccess", numberCallsSuccess);
  console.log("submitAllFailed", submitAllFailed);

  if (recruitmentPropsGeneral) {
    return (
      <>
        {(recruitmentPendingForm1 ||
          recruitmentPendingForm2 ||
          recruitmentPendingForm3 ||
          recruitmentPendingForm4 ||
          recruitmentPendingForm5 ||
          recruitmentPendingForm6 ||
          recruitmentPendingForm7 ||
          recruitmentPendingForm8 ||
          recruitmentPendingForm9 ||
          recruitmentPendingForm10 ||
          finalPatchSubmitPending) && <Loader />}
        <TeletransmisionModal
          show={showTransmisionModal}
          onClose={() => {
            setShowTransmisionModal(false);
          }}
          setFieldValue={setFieldValue}
        />
        <Header
          h1={t("app.suivezVosContratsPartner.SuivezContrats")}
          bgImg={bgContractImg}
          textElement={t("app.suivezVosContratsPartner.Intro2")}
        />
        <div className={styles.blue_banner_container}>
          <BlueBanner
            text={t("app.suivezVosContratsPartner.CerfaWarning")}
            text2={t("app.suivezVosContratsPartner.CerfaWarning2")}
            text3={t("app.suivezVosContratsPartner.CerfaWarning3")}
            text4={t("app.suivezVosContratsPartner.CerfaWarning4")}
          />
        </div>
        <section className={styles.main_container}>
          <div className={styles.static_part}>
            <StudientSection recruitment={recruitmentPropsGeneral} />
          </div>

          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm1}
            error={recruitmentErrorForm1}
            formOpen={section1Open}
            setFormOpen={setSection1Open}
            tittle={t("app.suivezVosContratsPartner.InformationsEntreprise") + " -"}
            areaFufiled={areaFufiledForm1}
            Form={
              <Form1
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section1Open}
                setAreaFufiled={setAreaFufiledForm1}
                referencialData={referencialData}
                areaFufiled={areaFufiledForm1}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm2}
            error={recruitmentErrorForm2}
            formOpen={section2Open}
            setFormOpen={setSection2Open}
            tittle={t("app.suivezVosContratsPartner.SignataireDeLaConvention") + " -"}
            areaFufiled={areaFufiledForm2}
            Form={
              <Form2
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section2Open}
                setAreaFufiled={setAreaFufiledForm2}
                areaFufiled={areaFufiledForm2}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm3}
            error={recruitmentErrorForm3}
            formOpen={section3Open}
            setFormOpen={setSection3Open}
            tittle={t("app.suivezVosContratsPartner.ContactTaxeDapprentissage") + " -"}
            optionnal={"Optionnel"}
            areaFufiled={areaFufiledForm3}
            Form={
              <Form3
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section3Open}
                setAreaFufiled={setAreaFufiledForm3}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm4}
            error={recruitmentErrorForm4}
            formOpen={section4Open}
            setFormOpen={setSection4Open}
            tittle={t("app.suivezVosContratsPartner.ContactAdministratifDurantLaFormation") + " -"}
            isSubTittle={true}
            subTittle={t("app.suivezVosContratsPartner.Referent")}
            areaFufiled={areaFufiledForm4}
            Form={
              <Form4
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section4Open}
                setAreaFufiled={setAreaFufiledForm4}
                referencialData={referencialData}
                areaFufiled={areaFufiledForm4}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm5}
            error={recruitmentErrorForm5}
            formOpen={section5Open}
            setFormOpen={setSection5Open}
            tittle={t("app.suivezVosContratsPartner.ContactEtablissementDuContrat") + " -"}
            areaFufiled={areaFufiledForm5}
            Form={
              <Form5
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section5Open}
                setAreaFufiled={setAreaFufiledForm5}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm6}
            error={recruitmentErrorForm6}
            formOpen={section6Open}
            setFormOpen={setSection6Open}
            tittle={t("app.suivezVosContratsPartner.ContactTuteurEtMaitreDapprentissage") + " -"}
            isSubTittle={true}
            subTittle={t("app.suivezVosContratsPartner.AttentionNote")}
            areaFufiled={areaFufiledForm6}
            Form={
              <Form6
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section6Open}
                setAreaFufiled={setAreaFufiledForm6}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm7}
            error={recruitmentErrorForm7}
            formOpen={section7Open}
            setFormOpen={setSection7Open}
            tittle={t("app.suivezVosContratsPartner.ContratRenseignementsGeneraux") + " -"}
            areaFufiled={areaFufiledForm7}
            Form={
              <Form7
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section7Open}
                setAreaFufiled={setAreaFufiledForm7}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm8}
            error={recruitmentErrorForm8}
            formOpen={section8Open}
            setFormOpen={setSection8Open}
            tittle={t("app.suivezVosContratsPartner.RemunerationContratDapprentissage") + " -"}
            areaFufiled={areaFufiledForm8}
            Form={
              <Form8
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section8Open}
                setAreaFufiled={setAreaFufiledForm8}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm9}
            error={recruitmentErrorForm9}
            formOpen={section9Open}
            setFormOpen={setSection9Open}
            tittle={t("app.suivezVosContratsPartner.FinancementDuContrat") + " -"}
            isSubTittle={true}
            subTittle={"Le contact à renseigner est le contact comptabilité fournisseur entreprise"}
            areaFufiled={areaFufiledForm9}
            Form={
              <Form9
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={false}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section9Open}
                setAreaFufiled={setAreaFufiledForm9}
                referencialData={referencialData}
              />
            }
          />
          <Sections
            submitAll={submitAllSections}
            pending={recruitmentPendingForm10}
            error={recruitmentErrorForm10}
            formOpen={section10Open}
            setFormOpen={setSection10Open}
            tittle={t("app.suivezVosContratsPartner.FinancementDuContratOpco") + " -"}
            areaFufiled={areaFufiledForm10}
            Form={
              <Form10
                submitAll={submitAllSections}
                setSubmitAll={setSubmitAllSections}
                lastStep={true}
                axiosCancelToken={axiosCancelToken}
                submitSection={submitSection}
                formOpen={section10Open}
                setAreaFufiled={setAreaFufiledForm10}
                referencialData={referencialData}
              />
            }
          />

          <form className={styles.checkbox_part} onSubmit={handleSubmit}>
            <div className={styles.checkbox_row}>
              <div className={` ${styles.checkbox_container} input-like-rw`}>
                <div className={styles.infoBulle}>
                  <svg
                    className="slds-button__icon"
                    focusable="false"
                    data-key="info"
                    aria-hidden="true"
                    viewBox="0 0 52 52"
                    part="icon"
                  >
                    <g>
                      <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                    </g>
                  </svg>
                  <div className={styles.infoBulleInfo}>{t("app.suivezVosContratsPartner.BulleInfo")}</div>
                </div>
                <div>
                  <input
                    style={
                      errors.teletransmissionCheckbox &&
                      touched.teletransmissionCheckbox && {
                        border: "2px solid #EA001E",
                      }
                    }
                    id="teletransmissionCheckbox"
                    type="checkbox"
                    // disabled={generalEnable}
                    checked={values.teletransmissionCheckbox}
                    onChange={(e) => {
                      handleCheckModal(e.target.checked);
                      setFieldValue("teletransmissionCheckbox", e.target.checked);
                    }}
                  />
                  <div className={styles.checkbox_text}>{t("app.suivezVosContratsPartner.Teletransmission")}</div>
                </div>
                <InputErrorMessage
                  message={errors.teletransmissionCheckbox}
                  touched={touched.teletransmissionCheckbox}
                />
              </div>
            </div>
            <div className={styles.checkbox_row}>
              <div className={` ${styles.checkbox_container} input-like-rw`}>
                <div>
                  <input
                    style={
                      errors.electronicSignCheckbox &&
                      touched.electronicSignCheckbox && {
                        border: "2px solid #EA001E",
                      }
                    }
                    id="electronicSignCheckbox"
                    type="checkbox"
                    // disabled={generalEnable}
                    checked={values.electronicSignCheckbox}
                    onChange={(e) => setFieldValue("electronicSignCheckbox", e.target.checked)}
                  />
                  <div className={styles.checkbox_text}>{t("app.suivezVosContratsPartner.SignatureElectronique")}</div>
                </div>
                <InputErrorMessage message={errors.electronicSignCheckbox} touched={touched.electronicSignCheckbox} />
              </div>
            </div>
            <div className={styles.checkbox_row}>
              <div className={` ${styles.checkbox_container} input-like-rw`}>
                <div>
                  <input
                    style={
                      errors.validData &&
                      touched.validData && {
                        border: "2px solid #EA001E",
                      }
                    }
                    id="validData"
                    type="checkbox"
                    // disabled={generalEnable}
                    checked={values.validData}
                    onChange={(e) => setFieldValue("validData", e.target.checked)}
                  />
                  <div className={styles.checkbox_text}>{t("app.suivezVosContratsPartner.validInfo")}</div>
                </div>
                <InputErrorMessage message={errors.validData} touched={touched.validData} />
              </div>
            </div>

            <div className={styles.submit_brn}>
              <SubmitFormBtn
                refBtn={buttonRef}
                // onClick={hangleMutlipleClicks}
                disabled={submitAllSections}
                text={"Validez votre contrat"}
              />
            </div>
          </form>
        </section>
      </>
    );
  }
};

export default PartTimeDetail;
