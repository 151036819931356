import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";
import {
  getUploadFIleErrorMessage,
  getUploadFilePending,
  postFileStudentThunk,
} from "app/slices/uploadFile/uploadFile.slice";
import UploadDeleteOrganism from "components/UploadDeleteOrganism/UploadDeleteOrganism";
import {
  getcreateYourCVPending,
  getcreateYourCVErrorMessage,
  postStep1Thunk,
  patchStepThunk,
  getcreateYourCVId,
  handleStepsData,
  handlePreviousStep,
  getcreateYourCVContactData,
} from "../../../../../app/slices/createYourCV/createYourCV.slice";
import Header from "../../../../../components/Header/Header";
import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";
import PreviousFormBtn from "../../../../../components/PreviousFormBtn/PreviousFormBtn";
import bgConsulOffreImg from "../../assets/bg-consul-offre.png";
import ResumeCardCv from "../../components/ResumeCardCv/ResumeCardCv";
import styles from "../../CreateYourCV.module.scss";

/* eslint-disable */
const Contact = (props) => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourCVPending);
  const errorMessage = useSelector(getcreateYourCVErrorMessage);
  const id = useSelector(getcreateYourCVId);
  const contactData = useSelector(getcreateYourCVContactData);
  const [checked, setChecked] = useState(false);

  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  // Yup validation schema
  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(t("app.errorMessages.MailFormat")).required(t("app.errorMessages.RemplirChamp")),
    phone: Yup.string()
      .matches(phoneRegExp, t("app.errorMessages.PhoneFormat"))
      .required(t("app.errorMessages.RemplirChamp")),
    youtubeUrl: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("app.errorMessages.urlFormat"),
    ),

    linkedinUrl: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("app.errorMessages.urlFormat"),
    ),

    blogUrl: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("app.errorMessages.urlFormat"),
    ),

    videoUrl: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("app.errorMessages.urlFormat"),
    ),
    checked: Yup.boolean().oneOf([true], t("app.errorMessages.RemplirChamp")),
  });
  useEffect(() => {
    console.log(errors);
  });
  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          console.log("FILE CV UPLOAD", file);
          await dispatch(
            postFileStudentThunk({
              file,
              endpoint: `/v1/students/resumes/${id}/supporting-documents`,
              axiosCancelToken: axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersAjoutes")}
              secondMessage={`${t("app.toastMessages.FichiersAjoutesMessage")} 1`}
            />,
          );
          // setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          console.log("upload success TestUploadFile !");
        } catch (error) {
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.ImpossibleLoad")} secondMessage={file.name} />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // onSubmit form function
  const onSubmit = (values, actions) => {
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    dispatch(
      handleStepsData({
        formName: "Contact",
        formData: {
          phone: values.phone,
          email: values.email,
          instagramUrl: values.instagramUrl,
          youtubeUrl: values.youtubeUrl,
          linkedinUrl: values.linkedinUrl,
          blogUrl: values.blogUrl,
          videoUrl: values.videoUrl,
        },
      }),
    );

    const dataToSend = {
      phone: valuesCopy.phone,
      email: valuesCopy.email,
      instagramUrl: valuesCopy.instagramUrl,
      youtubeUrl: valuesCopy.youtubeUrl,
      linkedinUrl: valuesCopy.linkedinUrl,
      blogUrl: valuesCopy.blogUrl,
      videoUrl: valuesCopy.videoUrl,
      public: valuesCopy.checked,
      status: "Complété",
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      (async () => {
        try {
          if (!id) {
            await dispatch(
              postStep1Thunk({
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourProfile post success !");
          } else {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourProfile put success !");
          }
        } catch (error) {
          console.log("YourProfile post fail !", error.message);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: contactData
      ? contactData
      : {
          phone: "",
          email: "",
          instagramUrl: "",
          youtubeUrl: "",
          linkedinUrl: "",
          blogUrl: "",
          videoUrl: "",
          checked: false,
        },
    validationSchema: ContactSchema,
    onSubmit,
  });

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && (
        <>
          <Header
            h1={t("app.depotCv.DeposerCV")}
            textElement={<p>{t("app.depotCv.Etape5")}</p>}
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.wrapper}>
              <div className={styles.leftCol}>
                <div className={styles.title_container}>
                  <h2>CONTACT</h2>
                  <div>{t("app.depotCv.Step5")}</div>
                </div>
                <div className={styles.form_container}>
                  <form className={styles.your_need_form} onSubmit={handleSubmit}>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                    <div className={styles.inputs_container}>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="phone">{t("app.depotCv.Telephone")}</label>
                        <input
                          id="phone"
                          type="text"
                          style={
                            errors.phone &&
                            touched.phone && {
                              border: "2px solid #EA001E",
                            }
                          }
                          value={values.phone}
                          onChange={(e) => setFieldValue("phone", e.target.value)}
                        />
                        <InputErrorMessage message={errors.phone} touched={touched.phone} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="email">{t("app.depotCv.Email")}</label>
                        <input
                          style={
                            errors.email &&
                            touched.email && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="email"
                          type="text"
                          value={values.email}
                          onChange={(e) => setFieldValue("email", e.target.value)}
                        />
                        <InputErrorMessage message={errors.email} touched={touched.email} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="instagramUrl">
                          {t("app.depotCv.Instagram")}
                        </label>
                        <input
                          id="instagramUrl"
                          type="text"
                          value={values.instagramUrl}
                          onChange={(e) => setFieldValue("instagramUrl", e.target.value)}
                        />
                      </div>

                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="youtubeUrl">
                          {t("app.depotCv.Youtube")}
                        </label>
                        <input
                          style={
                            errors.youtubeUrl &&
                            touched.youtubeUrl && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="youtubeUrl"
                          type="text"
                          value={values.youtubeUrl}
                          onChange={(e) => setFieldValue("youtubeUrl", e.target.value)}
                        />
                        <InputErrorMessage message={errors.youtubeUrl} touched={touched.youtubeUrl} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="linkedinUrl">
                          {t("app.depotCv.LinkedIn")}
                        </label>
                        <input
                          style={
                            errors.linkedinUrl &&
                            touched.linkedinUrl && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="linkedinUrl"
                          type="text"
                          value={values.linkedinUrl}
                          onChange={(e) => setFieldValue("linkedinUrl", e.target.value)}
                        />
                        <InputErrorMessage message={errors.linkedinUrl} touched={touched.linkedinUrl} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="blogUrl">
                          {t("app.depotCv.BlogBook")}
                        </label>
                        <input
                          style={
                            errors.blogUrl &&
                            touched.blogUrl && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="blogUrl"
                          type="text"
                          value={values.blogUrl}
                          onChange={(e) => setFieldValue("blogUrl", e.target.value)}
                        />
                        <InputErrorMessage message={errors.blogUrl} touched={touched.blogUrl} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="blogUrl">
                          {t("app.depotCv.ChargementCV")}
                        </label>
                        <UploadDeleteOrganism
                          id={id}
                          middleware="student"
                          endpoint={`/v1/students/resumes/${id}/supporting-documents`}
                          // labelText={t(
                          // 	"app.declarezUnRecrutement.DeposezUnDocument"
                          // )}
                          uploadMessages={{
                            success: {
                              firstMessage: t("app.toastMessages.FichiersAjoutes"),
                              secondMessage: `${t("app.toastMessages.FichiersAjoutesMessage")}`,
                            },
                            error: {
                              firstMessage: t("app.toastMessages.genericError"),
                            },
                          }}
                          deleteMessages={{
                            success: {
                              firstMessage: t("app.toastMessages.genericDeleteSuccess"),
                            },
                            error: {
                              firstMessage: t("app.toastMessages.genericDeleteError"),
                            },
                          }}
                        />
                      </div>

                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label className={styles.optionalLabel} htmlFor="videoUrl">
                          {t("app.depotCv.URLCV")}
                        </label>
                        <input
                          style={
                            errors.videoUrl &&
                            touched.videoUrl && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="videoUrl"
                          type="text"
                          value={values.videoUrl}
                          onChange={(e) => setFieldValue("videoUrl", e.target.value)}
                        />
                        <InputErrorMessage message={errors.videoUrl} touched={touched.videoUrl} />
                      </div>
                      <div>
                        <label className={styles.star_for_consent}>*</label>
                        <div style={{ flexDirection: "column" }}>
                          <input
                            type="checkbox"
                            name="checked"
                            style={
                              touched.checked && values.checked === false
                                ? {
                                    outline: "2px solid #EA001E",
                                    borderRadius: "50%",
                                    outlineOffset: "-1.9px",
                                  }
                                : null
                            }
                            id="checked"
                            checked={values.checked}
                            onClick={() => setChecked(values.checked)}
                            onChange={(e) => setFieldValue("checked", !values.checked)}
                          ></input>

                          <InputErrorMessage message={errors.checked} touched={touched.checked} />
                        </div>
                        <p className={styles.consentement_text}>{t("app.visualisationCv.ConsentementText")}</p>
                      </div>
                    </div>
                    <SubmitFormBtn text={t("app.depotCv.SoumettezCV")} />
                    <PreviousFormBtn
                      text={t("app.depotCv.EtapePrecedente")}
                      handlePreviousStep={() => dispatch(handlePreviousStep())}
                    />
                  </form>
                </div>
              </div>
              <ResumeCardCv />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Contact;
