import { t } from "i18next";
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Listbox } from "react-widgets";
import { createObjAucun } from "utils/localReferentials";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import {
  handlePreviousStep,
  handleStepsData,
  postStep1Thunk,
  patchStepThunk,
  getcreateYourCVId,
  getcreateYourCVYourCompementarySkillData,
  getcreateYourCVErrorMessage,
  getcreateYourCVPending,
} from "../../../../../../app/slices/createYourCV/createYourCV.slice";

import Header from "../../../../../../components/Header/Header";

import styles from "../../../CreateYourCV.module.scss";

import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import PreviousFormBtn from "../../../../../../components/PreviousFormBtn/PreviousFormBtn";
import SubmitFormBtn from "../../../../../../components/SubmitFormBtn/SubmitFormBtn";
import { getReferentialsData } from "../../../../../../app/slices/referentials/referentials.slice";

/* eslint-disable */
const CompementarySkills = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourCVPending);
  const errorMessage = useSelector(getcreateYourCVErrorMessage);
  const id = useSelector(getcreateYourCVId);
  const savoirEtreData = useSelector(getcreateYourCVYourCompementarySkillData);
  const referencialData = useSelector(getReferentialsData);
  const LanguageSchema = Yup.object().shape({
    softSkills: Yup.array()
      .min(1, "Une option doit être sélectionnée.")
      .max(5, t("app.errorMessages.fiveChoicesMaximum")),
    extraActivities: Yup.string().max(255, "Maximum 255 caractères").required(t("app.errorMessages.RemplirChamp")),
  });
  const empty = createObjAucun();
  const [isLevelValid, setisLevelValid] = useState(false);
  const handleValidity = () => {
    if (props?.frenchlevel?.key === { ...empty }.key) {
      setisLevelValid(false);
    }
    if (props?.frenchlevel && props?.frenchlevel?.key !== { ...empty }.key) {
      setisLevelValid(true);
    }
    return isLevelValid;
  };
  const onSubmit = (values) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);
    dispatch(
      handleStepsData({
        formName: "yourSkills",
        formData: {
          softSkills: values.softSkills,
          extraActivities: values.extraActivities,
          frenchLevel: props?.frenchlevel,
        },
      }),
    );
    console.log("this is the best FR", props?.frenchlevel);
    console.log("this is the best FR2", errors);

    const dataToSend = {
      skills: {
        softSkills: valuesCopy.softSkills.map((obj) => obj.key),
      },
      extraActivities: valuesCopy.extraActivities,
      frenchLevel: props?.frenchlevel?.key,
    };
    console.log("dataToSend : ", dataToSend);
    if (!pending) {
      (async () => {
        try {
          if (!id) {
            await dispatch(
              postStep1Thunk({
                dataToSend,
              }),
            ).unwrap();
            console.log("YourSearch post success !");
          } else {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();

            console.log("YourSearch put success !");
          }
        } catch (error) {
          console.log("YourSearch post fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: savoirEtreData
      ? savoirEtreData
      : {
          softSkills: [],
          extraActivities: "",
        },
    validationSchema: LanguageSchema,
    onSubmit,
  });
  return (
    <>
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>
                {touched && props?.frenchlevel?.key === { ...empty }.key
                  ? `${t("app.errorMessages.MissingParam")} ${t("app.librairyCV.frenchLevel")}`
                  : errors
                    ? errorMessage
                    : null}
              </span>
              <div className={styles.sub_Title}>
                <span>
                  <p className={styles.sub_Title}>{t("app.depotCv.CompetencesComplementaires")}</p>
                </span>
              </div>
              <>
                <div className={styles.inputs_container}>
                  {/* <span style={{ color: "red" }}>{errorMessage}</span> */}

                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="softSkills">{t("app.depotCv.SavoirEtre")}</label>
                    <Listbox
                      id="softSkills"
                      multiple
                      dataKey="key"
                      textField="label"
                      data={referencialData?.STUDENT_RESUME_SOFT_SKILL}
                      value={values.softSkills}
                      onChange={(newValue) => setFieldValue("softSkills", newValue)}
                      style={
                        errors.softSkills &&
                        touched.softSkills && {
                          border: "2px solid #EA001E",
                        }
                      }
                    />
                    <InputErrorMessage message={errors.softSkills} touched={touched.softSkills} />
                  </div>

                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="extraActivities">{t("app.depotCv.ActiviteExtraScolaires")}</label>
                    <textarea
                      id="extraActivities"
                      value={values.extraActivities}
                      onChange={(e) => setFieldValue("extraActivities", e.target.value)}
                      style={
                        errors.extraActivities &&
                        touched.extraActivities && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                    />
                    <InputErrorMessage message={errors.extraActivities} touched={touched.extraActivities} />
                  </div>
                </div>
              </>
              <SubmitFormBtn
                onClick={() => {
                  handleValidity();
                  props?.handleCallBack(isLevelValid);
                }}
                text={t("app.depotCv.ValiderEtape")}
              />
              <PreviousFormBtn
                text={t("app.depotCv.EtapePrecedente")}
                handlePreviousStep={() => dispatch(handlePreviousStep())}
              />
            </form>
          </section>
        </>
      )}
    </>
  );
};

export default CompementarySkills;
