import { t } from "i18next";

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { getDeclareARecruitmentPreContractualizationInformations } from "app/slices/declareARecruitment/declareARecruitment.slice";

import HeaderContract from "../../components/HeaderContract/HeaderContract";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import ApprenticeshipForm from "./forms/ApprenticeshipForm";

import styles from "./Step7.module.scss";

const Step2 = (props) => {
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* eslint-disable */
  return (
    <div className={styles.wrapper}>
      <HeaderContract />
      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.form_and_card_container}>
            <div className={styles.title_container}>
              <h2>{t("app.declarezUnRecrutement.SituationPedagogique")}</h2>
              <div className={styles.step_indicator}>{t("app.declarezUnRecrutement.Etape3Sur4")}</div>
            </div>

            <ApprenticeshipForm axiosCancelToken={props.axiosCancelToken} />
          </div>
          <SummaryCard
            data={[
              {
                title: t("app.declarezUnRecrutement.EtesVousInscritAPoleEmploi"),
                text: preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker
                  ? t("app.referentials.Oui")
                  : t("app.referentials.Non"),
              },
              {
                title: t("app.declarezUnRecrutement.NumSecuriteSociale"),
                text: preContractualizationInformations.student?.socialSecurityNumber,
              },
              {
                title: t("app.declarezUnRecrutement.NumDinscriptionPoleEmploi"),
                text: preContractualizationInformations.student?.employmentCenterRegistration?.number,
              },
              {
                title: t("app.declarezUnRecrutement.DateInscriptionPoleEmploi"),
                text: preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate,
              },
            ]}
          />
        </div>
      </section>
    </div>
  );
};

export default Step2;
