import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DropdownList } from "react-widgets/cjs";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import { changeOuiOrNonToBoolean } from "utils/tools";
import { createBooleanReferential, createObjAucun } from "utils/localReferentials";

import { toastWarning } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import {
  postStep1Thunk,
  patchStepThunk,
  getDeclareARecruitmentPending,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentStudentInformations,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
// eslint-disable-next-line
import FileModal from "./component/FileModal/FileModal";
// eslint-disable-next-line
import FileModal2 from "./component/FileModal/FileModal";
import HeaderContract from "../../components/HeaderContract/HeaderContract";

import styles from "./Step1.module.scss";

/* eslint-disable */
const Step1 = (props) => {
  const [forbidenStep2, setForbidenStep2] = useState(true);

  const dispatch = useDispatch();

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  const studientInformation = useSelector(getDeclareARecruitmentStudentInformations);
  const referencialData = useSelector(getReferentialsData);

  const pending = useSelector(getDeclareARecruitmentPending);

  const [enabledPrograms, setEnabledPrograms] = useState([]);

  const [file, setFile] = useState(null);

  const [show, setShow] = useState(false);
  const [showSecondayModal, setShowSecondayModal] = useState(false);
  const [pathIndicator, setPathIndicator] = useState("STEP1");

  const [displaySecondStep, setDisplaySecondStep] = useState(false);

  const [forOutEu, setForOutEu] = useState(false);
  const [appStudient, setAppStudient] = useState(false);
  const [simpmleModal, setSimpleModal] = useState(false);
  const [bothModal, setBothModal] = useState(false);

  const [rightToWorkInFrance, setRightToWorkInFrance] = useState(false);

  useEffect(() => {
    console.log("ERRORS", errors);
    window.scrollTo(0, 0);

    generateDisplayedYears(studientInformation.schoolProgramEnrollments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActualSchoolProgram = (schoolProgram) => {
    let displayedProgram = {
      id: schoolProgram.id,
      label: schoolProgram.schoolYear + " - " + schoolProgram.trainingSchool,
      value: schoolProgram,
    };
    return displayedProgram;
  };

  const generateDisplayedYears = (schoolProgram) => {
    let displayedShoolProgram = [];

    schoolProgram.forEach((value, index) => {
      if (value.status !== "ANCIEN") {
        displayedShoolProgram.push({
          id: value.id,
          label: value.schoolYear + " - " + value.trainingSchool,
          value: value,
        });
      }
    });
    displayedShoolProgram = displayedShoolProgram.reverse();

    setEnabledPrograms(displayedShoolProgram);
  };

  const handleOutEuRight = () => {
    if (studientInformation?.nationalities[0]?.isEuropean) return true;
    else return rightToWorkInFrance;
  };

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);

    changeOuiOrNonToBoolean(valuesCopy);
    let localOutEu = false;
    let localAppStudient = false;

    const dataToSend = {
      stage: "Etape2",
      contractType: valuesCopy.contractType.key,
      student: {
        id: studientInformation.id,
        rightToWorkFullTimeInFrance: handleOutEuRight(),
        socialSecurityNumber: studientInformation.socialSecurityNumber,
        apprenticeshipProject: {
          trainingPath: valuesCopy.formationType.key,
          schoolProgramEnrollmentId: valuesCopy.universityYear.id,
          freeToBeRecruited: valuesCopy.engagementCheckbox,
        },
      },
    };

    // console.log("WARNING POPUP", studientInformation.nationalities[0].isEuropean, valuesCopy.formationType.key, valuesCopy.residencePermit.key)

    // Checks if the student can declare a recruitment other than an internship.
    if (
      valuesCopy.universityYear.value.trainingMode.search("Classique - CL") > -1 ||
      (valuesCopy.universityYear.value.trainingMode.search("Passage CAN-CLA") > -1 &&
        valuesCopy.contractType.key !== "Formation continue")
    ) {
      setForbidenStep2(true);
      toastWarning(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.Attention")}
          secondMessage={t("app.toastMessages.DeclarerUnSeulRecrutementStage")}
        />,
      );
      return;
    }
    if (
      !studientInformation?.nationalities[0]?.isEuropean &&
      valuesCopy.formationType.key === "Bachelor" &&
      !valuesCopy.residencePermit.key
    ) {
      console.log("WARNING POPUP");
      setForbidenStep2(true);
      toastWarning(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.Attention")}
          secondMessage={t("app.toastMessages.MerciVousRapprocherServiceRelationsEntreprises")}
        />,
      );
      return;
    } else {
      setForbidenStep2(false);
    }

    if (valuesCopy.formationType.key === "Bachelor" && studientInformation?.nationalities[0]?.isEuropean === false) {
      setForOutEu(true);
      console.log("OUT OF EU");
      localOutEu = true;
    }
    if (valuesCopy.universityYear.value.trainingMode.search("APP") !== -1) {
      setAppStudient(true);
      console.log("STUDIENT TYPE");
      localAppStudient = true;
    }
    if (localAppStudient && localOutEu) {
      console.log("BOTH MODAL");
      setBothModal(true);
    } else if ((localAppStudient && !localOutEu) || (!localAppStudient && localOutEu)) {
      console.log("SIMPLE MODAL");
      setSimpleModal(true);
    }

    console.log("TURE SUMMIT 2", localOutEu, localAppStudient);

    if (
      !preContractualizationInformations &&
      !pending &&
      (localAppStudient || (localOutEu && values.formationType.key === "Bachelor"))
    ) {
      console.log("false pré contra");
      try {
        dataToSend.stage = "Etape1";
        await dispatch(
          postStep1Thunk({
            dataToSend, //Etape1
            axiosCancelToken: props.axiosCancelToken,
          }),
        ).unwrap();
        console.log("post success !");
      } catch (error) {
        console.log("post fail !", error.message);
      }
      console.log("false pré contra next");
    } else if (!preContractualizationInformations && !pending) {
      console.log("false pré contra");
      try {
        await dispatch(
          postStep1Thunk({
            dataToSend, //Etape2
            axiosCancelToken: props.axiosCancelToken,
          }),
        ).unwrap();
        console.log("post success !");
      } catch (error) {
        console.log("post fail !", error.message);
      }
      console.log("false pré contra next");
      return;
    }

    if ((!localOutEu && !localAppStudient) || pathIndicator === "MODAL") {
      if (!pending) {
        try {
          await dispatch(
            patchStepThunk({
              dataToSend,
              id: preContractualizationInformations.id,
              axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          console.log("patch success !");
        } catch (error) {
          console.log("patch fail !", error.message);
        }
      }
      setForOutEu(false);
      setAppStudient(false);
      setPathIndicator("STEP1");
    } else {
      console.log("GO TO MODAL");
      setShow(true);
    }
  };

  const informationSchema = Yup.object().shape({
    formationType: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    contractType: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    universityYear: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    engagementCheckbox: Yup.boolean().test(false, t("app.errorMessages.freeToBeRecruited"), (value) => value !== false),
    residencePermit: Yup.object().when("formationType", {
      is: (formationType) =>
        formationType.key === "Bachelor" && studientInformation?.nationalities[0].isEuropean === false,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      formationType: preContractualizationInformations?.student?.apprenticeshipProject?.trainingPath
        ? preContractualizationInformations?.student?.apprenticeshipProject?.trainingPath
        : { ...objAucun },
      contractType: preContractualizationInformations?.contractType
        ? preContractualizationInformations?.contractType
        : { ...objAucun },
      universityYear: preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment
        ? getActualSchoolProgram(
            preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment,
          )
        : { ...objAucun },
      nationalities: preContractualizationInformations?.student?.nationalities[0]
        ? preContractualizationInformations?.student?.nationalities[0]
        : studientInformation?.nationalities[0],
      engagementCheckbox: preContractualizationInformations?.student?.apprenticeshipProject?.freeToBeRecruited
        ? preContractualizationInformations?.student?.apprenticeshipProject?.freeToBeRecruited
        : false,
      residencePermit: { ...objAucun },
    },
    validationSchema: informationSchema,
    onSubmit,
  });

  return (
    <>
      {simpmleModal && !forbidenStep2 && (
        <FileModal
          onClose={() => {
            setPathIndicator("STEP1");
            setShow(false);
            setAppStudient(false);
            setForOutEu(false);
          }}
          show={show}
          setShow={setShow}
          summit={handleSubmit}
          values={values}
          setFile={setFile}
          actualFile={file}
          forOutEu={forOutEu}
          appStudient={appStudient}
          both={false}
          pathIndicator={pathIndicator}
          setPathIndicator={setPathIndicator}
          axiosCancelToken={props.axiosCancelToken}
          setRightToWorkInFrance={setRightToWorkInFrance}
        />
      )}
      {bothModal && !forbidenStep2 && (
        <>
          <FileModal
            onClose={() => {
              setPathIndicator("STEP1");
              setShow(false);
              setAppStudient(false);
              setForOutEu(false);
            }}
            show={show}
            setShow={setShow}
            summit={handleSubmit}
            values={values}
            setFile={setFile}
            actualFile={file}
            forOutEu={true}
            appStudient={false}
            first={true}
            second={displaySecondStep}
            displaySecondStep={setDisplaySecondStep}
            both={true}
            setShowSecondModal={setShowSecondayModal}
            pathIndicator={pathIndicator}
            axiosCancelToken={props.axiosCancelToken}
            setRightToWorkInFrance={setRightToWorkInFrance}
          />

          <FileModal2
            onClose={() => {
              setPathIndicator("STEP1");
              setShowSecondayModal(false);
              setAppStudient(false);
              setForOutEu(false);
            }}
            show={showSecondayModal}
            setShow={setShowSecondayModal}
            summit={handleSubmit}
            values={values}
            setFile={setFile}
            actualFile={file}
            forOutEu={false}
            appStudient={true}
            first={false}
            second={true}
            both={true}
            pathIndicator={pathIndicator}
            setPathIndicator={setPathIndicator}
            axiosCancelToken={props.axiosCancelToken}
            setRightToWorkInFrance={setRightToWorkInFrance}
          />
        </>
      )}

      <HeaderContract />

      {referencialData && (
        <section className={styles.main_container}>
          <div className={styles.title_container}>
            <h2>{t("app.declarezUnRecrutement.Informations")}</h2>
            <div>{t("app.declarezUnRecrutement.Etape1Sur3")}</div>
          </div>
          <div className={styles.form_container}>
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <div className={styles.inputs_container}>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="formationType">
                    {t("app.declarezUnRecrutement.SurQuelParcoursDeFormationVousPositionnezVous")}
                  </label>
                  <DropdownList
                    style={
                      errors.formationType &&
                      touched.formationType && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="formationType"
                    dataKey="key"
                    textField="label"
                    data={
                      referencialData.APPRENTICESHIP_TRAINING_PATH && [
                        { ...objAucun },
                        ...referencialData.APPRENTICESHIP_TRAINING_PATH,
                      ]
                    }
                    value={values.formationType}
                    onChange={(newValue) => {
                      setFieldValue("formationType", newValue);
                      setFieldValue("residencePermit", { ...objAucun });
                    }}
                  />
                  <InputErrorMessage message={errors.formationType} touched={touched.formationType} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="contractType">
                    {t("app.declarezUnRecrutement.TypeDeContratEnvisageParEntreprise")}
                  </label>
                  <DropdownList
                    style={
                      errors.contractType &&
                      touched.contractType && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="contractType"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referencialData.APPRENTICESHIP_CONTRACT_TYPE]}
                    value={values.contractType}
                    onChange={(newValue) => setFieldValue("contractType", newValue)}
                  />
                  <InputErrorMessage message={errors.contractType} touched={touched.contractType} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="universityYear">{t("app.declarezUnRecrutement.AnneeUniversitaireConcernee")}</label>
                  <DropdownList
                    style={
                      errors.universityYear &&
                      touched.universityYear && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="universityYear"
                    dataKey="id"
                    textField="label"
                    data={enabledPrograms}
                    value={values.universityYear}
                    onChange={(newValue) => {
                      console.log("SELECTED PROGRAM", newValue);
                      setFieldValue("universityYear", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.universityYear} touched={touched.universityYear} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="nationalities">{t("app.declarezUnRecrutement.Nationalite")}</label>
                  <DropdownList
                    style={
                      errors.nationalities &&
                      touched.nationalities && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="nationalities"
                    dataKey="key"
                    textField="label"
                    data={studientInformation.nationalities}
                    disabled={true}
                    defaultValue={studientInformation?.nationalities[0]?.label}
                    value={values.nationalities}
                  />
                  <InputErrorMessage message={errors.nationalities} touched={touched.nationalities} />
                </div>
                {!studientInformation?.nationalities[0]?.isEuropean && values.formationType.key === "Bachelor" && (
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="residencePermit">{t("app.declarezUnRecrutement.pas-dans-exel-4")}</label>
                    <DropdownList
                      style={
                        errors.residencePermit &&
                        touched.residencePermit && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="residencePermit"
                      dataKey="key"
                      textField="label"
                      data={[...YesNo]}
                      defaultValue={studientInformation?.nationalities[0]}
                      value={values.residencePermit}
                      onChange={(newValue) => {
                        console.log("RESIDENCE VALUE", newValue);
                        setFieldValue("residencePermit", newValue);
                      }}
                    />
                    <InputErrorMessage message={errors.residencePermit} touched={touched.residencePermit} />
                  </div>
                )}
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="engagementCheckbox">{t("app.declarezUnRecrutement.Engagement")}</label>
                  <div className={styles.checkbox_container}>
                    <div>
                      <input
                        style={
                          errors.engagementCheckbox &&
                          touched.engagementCheckbox && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="engagementCheckbox"
                        type="checkbox"
                        checked={values.engagementCheckbox}
                        onChange={(e) => setFieldValue("engagementCheckbox", e.target.checked)}
                      />
                      <div className={styles.info_bubble}>i</div>
                    </div>
                    <div className={styles.checkbox_text}>
                      {t("app.declarezUnRecrutement.EngagementContractuelText")}
                    </div>
                  </div>
                  <InputErrorMessage message={errors.engagementCheckbox} touched={touched.engagementCheckbox} />
                </div>
              </div>
              <SubmitFormBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} />
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Step1;
