import { t } from "i18next";
import { handleCardProps } from "app/slices/modifyYourCV/modifyYourCV.slice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import iconArrowRightImg from "../assets/icon-arrow-right.svg";

import styles from "./Card.module.scss";

/* eslint-disable */
const Card = (props) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.card_container}>
      <div className={styles.status}>{props.status?.label}</div>
      <div className={styles.cv_id}>{props.name}</div>
      <div className={styles.name}>{props.title}</div>
      <div className={styles.duo_container}>
        <div className={styles.first_item}>{t("app.visualisationCv.TypeExpRecherchee")}</div>
        <div className={styles.second_item}>{props.search?.contractTypes?.map((obj) => obj.label)?.join("; ")}</div>
      </div>
      <div className={styles.duo_container}>
        <div className={styles.first_item}>{t("app.visualisationCv.LangueCV")}</div>
        <div className={styles.second_item}>{props.lang?.label}</div>
      </div>
      <div className={styles.duo_container}>
        <div className={styles.first_item}>{t("app.visualisationCv.Specialite")}</div>
        <div className={styles.second_item}>{props.search?.speciality?.label}</div>
      </div>

      <div className={styles.duo_container}>
        <div className={styles.first_item}>{t("app.visualisationCv.Mobilite")}</div>
        <div className={styles.second_item}>{props.mobility?.mobility?.label}</div>
      </div>
      <Link to={`${props.id}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => dispatch(handleCardProps(props))}
        >
          <div className={styles.edit_link}>{t("app.visualisationCv.ModifierConsulter")}</div>
          <img src={iconArrowRightImg} alt="iconArrowRightImg" />
        </div>
      </Link>
    </div>
  );
};

export default Card;
