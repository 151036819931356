import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import axios from "axios";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import Header from "../../../components/Header/Header";
import bgFollowOffersImg from "./assets/bg-follow-offre.png";
import FollowCard from "./AllOffers/FollowCard/FollowCard";
import {
  getAllUserOffersCards,
  getUserOffersCardsPending,
  getUserOffersCardsErrorMessage,
  getConpanyOffersCardsListThunk,
} from "../../../app/slices/followOffers/followOffers.slice";

import styles from "./FollowOffers.module.scss";

const FollowOffers = () => {
  const dispatch = useDispatch();

  const referencialData = useSelector(getReferentialsData);

  const userOffersList = useSelector(getAllUserOffersCards);
  const pending = useSelector(getUserOffersCardsPending);
  const errorMessage = useSelector(getUserOffersCardsErrorMessage);
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getConpanyOffersCardsListThunk({ axiosCancelToken })).unwrap();
          console.log("useEffect success ! ALL OFFER", userOffersList);
        } catch (error) {
          console.log("useEffect fail ! ALL OFFER", error.message);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <>
      <Header
        h1={t("app.suivezOffres.SuivezOffre")}
        textElement={<p>{t("app.suivezOffres.Intro1")}</p>}
        bgImg={bgFollowOffersImg}
      />

      <section className={styles.main_container}>
        <div className={styles.title_container}>
          <h2>{t("app.suivezOffres.ListeOffre")}</h2>
        </div>

        <div className={styles.offer_container}>
          {pending && <div>Chargement en cours...</div>}
          {userOffersList.length <= 0 && !pending && !errorMessage && <div>Vous n'avez pas d'offre</div>}
          {userOffersList.length > 0 &&
            !pending &&
            !errorMessage &&
            userOffersList.map((obj) => <FollowCard {...obj} referencialData={referencialData} key={nanoid()} />)}
          {errorMessage && <div>{errorMessage}</div>}
        </div>
      </section>
    </>
  );
};

export default FollowOffers;
