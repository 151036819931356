import Header from "../../../components/Header/Header";

import illustrationPageNotFoundImg from "./assets/illustration-page-not-found.png";

// import styles from "./Page404.module.scss";

/* eslint-disable */
const Page404 = () => (
  <Header
    h1="Page not available"
    textElement={
      <p>
        Maybe the page was deleted, the URL is incorrect, or something else went wrong. If you know the page exists but
        you still can’t get to it, please ask the community administrator for help.
      </p>
    }
    bgImg={illustrationPageNotFoundImg}
  />
);

export default Page404;
