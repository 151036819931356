import { useEffect } from "react";

import { nanoid } from "@reduxjs/toolkit";

import styles from "./ResumeCard.module.scss";

/* eslint-disable */
const ResumeCard = (props) => {
  useEffect(() => {
    //  console.log("LOCATION INDICATOR", props.myElem)
    // console.log("yourNeed", props.yourNeed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObjArray = (values) => {
    let allValue;
    let tmpArray = [];

    for (let i = 0; i < values?.length; i++) tmpArray[i] = values[i]?.label;

    allValue = tmpArray.join(", ");
    return allValue;
  };

  const handleDate = (inputDate) => {
    let date = new Date(JSON.parse(inputDate));
    let formatDate;

    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();

    formatDate = dd + "/" + mm + "/" + yyyy;

    return formatDate;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      {props.myElem.map((obj) => (
        <div className={styles.valueContainer} key={nanoid()}>
          {obj.toDisplay && <p className={styles.libele}>{obj.libel}</p>}
          {!obj.isObjArray && !obj.isDate && !obj.isPostalCode && !obj.isCity && obj.toDisplay && (
            <p className={styles.value}>{obj.value}</p>
          )}
          {obj.isObjArray && obj.toDisplay && <p className={styles.value}>{handleObjArray(obj.value)}</p>}
          {obj.isDate && obj.toDisplay && <p className={styles.value}>{handleDate(obj.value)}</p>}
          {obj.isCity && obj.toDisplay && <p className={styles.value}>{obj.value}</p>}
          {obj.isPostalCode && obj.toDisplay && <p className={styles.value}>{obj.value}</p>}
          {/* {  (obj.toDisplay && obj.isString) &&
                    <p className={styles.value}>{obj.value}</p>
                } */}
        </div>
      ))}
    </div>
  );
};

export default ResumeCard;
