import { useEffect, useState } from "react";
import axios from "axios";

import { t } from "i18next";

import ConfirmationFormsPage from "components/ConfirmationFormsPage/ConfirmationFormsPage";
import { useSelector, useDispatch } from "react-redux";
import { resetFileData } from "app/slices/uploadFile/uploadFile.slice";
import {
  DISCOVER_OMNES_EDUCATION_URL,
  DISCOVER_OUR_SCHOOLS_URL,
  DISCOVER_LEARNING_TAXE_URL,
} from "utils/globalConstantsAndURL";

import { toastInfoCenter } from "utils/toasts";
import {
  getPublishAnOfferActualStep,
  handleResetToStep1,
} from "../../../app/slices/publishAnOffer/publishAnOffer.slice";

// Forms components imports
import YourNeed from "./forms/YourNeed/YourNeed";
import DateAndPlace from "./forms/DateAndPlace/DateAndPlace";
import PositionToBeFilled from "./forms/PositionToBeFilled/PositionToBeFilled";

import bgImg from "./assets/bg-consul-offre.png";
import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

const PublishAnOffer = () => {
  const actualStep = useSelector(getPublishAnOfferActualStep);
  const dispatch = useDispatch();

  const [axiosCancelToken] = useState(axios.CancelToken.source());

  useEffect(() => {
    if (actualStep === 4) {
      toastInfoCenter(
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "20px", marginBottom: "15px" }}>
            Découvrez nos profils à la recherche d'une alternance en cliquant sur les liens ci-dessous :{" "}
          </span>
          <span style={{ fontSize: "15px", marginBottom: "10px" }}>
            Paris :{" "}
            <a
              style={{ textDecoration: "underline", color: "white" }}
              href="https://drive.google.com/drive/u/0/folders/1MrEU0h3B6rZfNzai3xxINiipz__86RHL"
              target="_blank"
              rel="noreferrer"
            >
              Lien vers la CVthèque
            </a>
          </span>
          <span style={{ fontSize: "15px", marginBottom: "10px" }}>
            Bordeaux :{" "}
            <a
              style={{ textDecoration: "underline", color: "white" }}
              href="https://drive.google.com/drive/u/0/folders/1ppqL9malVP7Kgjq5MkpizyYEmbLTGVE7"
              target="_blank"
              rel="noreferrer"
            >
              Lien vers la CVthèque
            </a>
          </span>
          <span style={{ fontSize: "15px", marginBottom: "10px" }}>
            Lyon :{" "}
            <a
              style={{ textDecoration: "underline", color: "white" }}
              href="https://drive.google.com/drive/folders/1-AthcZb6RAeVPeQv-i2MjKgL1ayKQhHQ"
              target="_blank"
              rel="noreferrer"
            >
              Lien vers la CVthèque
            </a>
          </span>
          <span style={{ fontSize: "15px", marginBottom: "10px" }}>
            Rennes :{" "}
            <a
              style={{ textDecoration: "underline", color: "white" }}
              href="https://inseecadmin-my.sharepoint.com/personal/cbenoit_omneseducation_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fcbenoit%5Fomneseducation%5Fcom%2FDocuments%2FBureau%2FCAMPUS%20RENNES%2FSREA%2FCVth%C3%A8que%2FCVTHEQUE%20RENNES%20CAN&ga=1"
              target="_blank"
              rel="noreferrer"
            >
              Lien vers la CVthèque
            </a>
          </span>
        </div>,
        "top-center",
        false,
      );
    }
  }, [actualStep]);

  useEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(handleResetToStep1());
      dispatch(resetFileData());
      console.log("PublishAnOffer unmount");
    },
    [],
  );

  return (
    <>
      {actualStep === 1 && <YourNeed axiosCancelToken={axiosCancelToken} />}
      {actualStep === 2 && <DateAndPlace axiosCancelToken={axiosCancelToken} />}
      {actualStep === 3 && <PositionToBeFilled axiosCancelToken={axiosCancelToken} />}
      {actualStep === 4 && (
        <ConfirmationFormsPage
          h1={t("app.depotOffre.Confirmation")}
          textElement={(
            <p>
              {t("app.depotOffre.Description4_part1")}
              <br />
              {t("app.depotOffre.Description4_part2")}
            </p>
          )}
          bgImg={bgImg}
          leftBtn={{
            text: t("app.depotOffre.SuivezOffre"),
            path: "/mes-offres",
          }}
          rightBtn={{
            text: t("app.depotOffre.ConsultezCV"),
            path: "/liste-des-cvs",
          }}
          h2={t("app.depotOffre.MieuxConnaitre")}
          cardsArray={[
            {
              path: DISCOVER_OMNES_EDUCATION_URL,
              img: cardKnow3Img,
              h3: t("app.depotOffre.OMNES"),
              text: "",
            },
            {
              path: DISCOVER_OUR_SCHOOLS_URL,
              img: cardKnow2Img,
              h3: t("app.depotOffre.NosEcole"),
              text: "",
            },
            {
              path: DISCOVER_LEARNING_TAXE_URL,
              img: cardKnow3Img,
              h3: t("app.depotOffre.TaxeApprentissage"),
              text: "",
            },
          ]}
        />
      )}
    </>
  );
};

export default PublishAnOffer;
