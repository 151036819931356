import { t } from "i18next";
import Header from "components/Header/Header";

const ContactUsENT = () => (
  <div>
    <Header
      h1={t("app.contactezNous.ContactezNous")}
      textElement={(
        <p>
          {t("app.contactezNous.Intro1")}
          <br />
          <br />
          {t("app.contactezNous.Intro2")}
          <br />
          {t("app.contactezNous.Text1")}
          <br />
          {t("app.contactezNous.Text2")}
          <br />
          {t("app.contactezNous.Text3")}
          <br />
          {t("app.contactezNous.Text4")}
          <br />
          {t("app.contactezNous.Text5")}
          <br />
          {t("app.contactezNous.Text6")}
          <br />
        </p>
      )}
    />
  </div>
);

export default ContactUsENT;
