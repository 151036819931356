import { t } from "i18next";

import { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import {
  getAllUserOffersCards,
  getUserOffersCardsPending,
  getConpanyOffersCardsListThunk,
} from "app/slices/followOffers/followOffers.slice";

import {
  getConpanyRecruitmentListThunk,
  getAllUserRecruitments,
  getUserRecruitmentsPending,
} from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { getDeviceWidth } from "../../../../app/slices/deviceWidth/deviceWidth.slice";

import styles from "./HomepageHeader.module.scss";

const HomepageHeader = ({ bgImg, h1, textElement }) => {
  const deviceWidth = useSelector(getDeviceWidth);
  const actualBg = deviceWidth > 1350 ? bgImg : "none";

  const headerStyle = {
    background: `no-repeat top right url(${actualBg})`,
  };

  const [numberUserOffers, setNumberUserOffers] = useState(0);
  const [numberUserContracts, setNumberUserContracts] = useState(0);
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const userToken = useSelector(getUserToken);

  const userOffersList = useSelector(getAllUserOffersCards);
  const pending = useSelector(getUserOffersCardsPending);
  //   const errorMessage = useSelector(getUserOffersCardsErrorMessage);

  const userContractsList = useSelector(getAllUserRecruitments);
  const pending2 = useSelector(getUserRecruitmentsPending);
  //   const errorMessage2 = useSelector(getUserRecruitmentsErrorMessage);

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getConpanyOffersCardsListThunk({ axiosCancelToken })).unwrap();
          console.log("useLayoutEffect success ! userOffersList", userOffersList);
        } catch (error) {
          console.log("useLayoutEffect fail ! userOffersList", error.message);
        }
      })();
    }

    if (!pending2 && userToken) {
      (async () => {
        try {
          await dispatch(dispatch(getConpanyRecruitmentListThunk({ axiosCancelToken }))).unwrap();
          console.log("useLayoutEffect success ! userContractsList", userContractsList);
        } catch (error) {
          console.log("useLayoutEffect fail ! userContractsList", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  useLayoutEffect(() => {
    if (userOffersList.length > 0) {
      let newArray = [];

      newArray = userOffersList?.filter((obj) => obj?.status?.key !== "Pourvue" && obj?.status?.key !== "Annulée");

      setNumberUserOffers(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOffersList]);

  useLayoutEffect(() => {
    if (userContractsList.length > 0) {
      let newArray = [];

      newArray = userContractsList?.filter((obj) => obj?.status?.key === "Validation CES");

      setNumberUserContracts(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContractsList]);

  return (
    <header className={styles.header_container} style={headerStyle}>
      <div className={styles.header_content}>
        <h1>{h1}</h1>
        {textElement}
      </div>
      <div className={styles.white_squares_container}>
        <div className={styles.white_square_card}>
          <div className={styles.cv_number_card}>{pending ? "..." : numberUserOffers?.length}</div>
          <div className={styles.cv_text_card}>{t("app.homePageEntreprise.OffresDeposées")}</div>
          <div className={styles.cv_link_card}>{t("app.homePageEntreprise.EnSavoirPlus")}</div>
        </div>
        <div className={styles.white_square_card}>
          <div className={styles.cv_number_card}>{pending2 ? "..." : numberUserContracts?.length}</div>
          <div className={styles.cv_text_card}>{t("app.homePageEntreprise.ContratSignes")}</div>
          <div className={styles.cv_link_card}>{t("app.homePageEntreprise.EnSavoirPlus")}</div>
        </div>
      </div>
    </header>
  );
};

export default HomepageHeader;
