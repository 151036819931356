import { t } from "i18next";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets/cjs";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { changeAucunToNull, changeEmptyStringToNull } from "utils/tools";
import { createObjAucun } from "utils/localReferentials";
import InputErrorMessage from "../../../../../../../components/InputErrorMessage/InputErrorMessage";

import {
  getcreateYourExperienceErrorMessage,
  getcreateYourExperienceId,
  getcreateYourExperiencePending,
  PostExperienceThunk,
} from "../../../../../../../app/slices/createYourCV/createYourExperience.slice";
import {
  getAllExperiences,
  getExperiencesCardsIsLoading,
  getExperiencesThunk,
} from "../../../../../../../app/slices/createYourCV/getYourExperiences.slice";
import Header from "../../../../../../../components/Header/Header";
import { getReferentialsData } from "../../../../../../../app/slices/referentials/referentials.slice";
import Card from "../../components/experiencesCard";
import Modal from "../../components/AcademicExperiencesGetModal";
import { getcreateYourCVId, handleStepsData } from "../../../../../../../app/slices/createYourCV/createYourCV.slice";
import style from "../AddExperienceBtn.module.scss";
import styles from "../../../../CreateYourCV.module.scss";

/* eslint-disable */
const AcademicExperiences = () => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourExperiencePending);
  const errorMessage = useSelector(getcreateYourExperienceErrorMessage);
  const id = useSelector(getcreateYourExperienceId);
  const experiencesList = useSelector(getAllExperiences);
  const getExperienceLoading = useSelector(getExperiencesCardsIsLoading);
  const cvId = useSelector(getcreateYourCVId);
  // const [isId,setIsId]= useState(null)
  const [isFinishedAcaExp, setIsFinishedAcaExp] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const referencialData = useSelector(getReferentialsData);
  const [isPossible, setIsPossible] = useState(true);
  const empty = createObjAucun();
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };

  useEffect(() => {
    if (!getExperienceLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getExperiencesThunk({
                  cvId,
                }),
              ).unwrap();
              console.log("this is the experiences Data:", experiencesList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedAcaExp, isModal]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const Data = {
    institution: "",
    course: "",
    graduationSuccessIndicator: [
      { label: "-Aucun-", key: "--None--" },
      { label: t("app.referentials.Oui"), key: "Oui" },
      { label: t("app.referentials.Non"), key: "Non" },
      { label: t("app.referentials.InProgress"), key: "InProgress" },
    ],
    description: "",
    country: [
      { label: "France", key: "Fr" },
      { label: "Spain", key: "Spa" },
      { label: "Portugal", key: "Por" },
    ],
    city: "",
  };
  const AcademicExperienceSchema = Yup.object().shape({
    graduationSuccessIndicator: Yup.object().test(
      "if-is-aucun",
      "Remplissez ce champ.",
      (value) => value.key !== empty.key,
    ),
    country: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== empty.key),
    course: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    institution: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    description: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    city: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
  });

  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    // changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    dispatch(
      handleStepsData({
        formName: "ExperiencesAcademics",
        formData: {
          institution: values.institution,
          course: values.course,
          graduationSuccessIndicator: values.graduationSuccessIndicator,
          description: values.description,
          country: values.country,
          city: values.city,
          startDate: JSON.stringify(values.startDate),
          endDate: JSON.stringify(values.endDate),
        },
      }),
    );

    const dataToSend = {
      institution: valuesCopy.institution,
      course: valuesCopy.course,
      graduationSuccessIndicator: valuesCopy.graduationSuccessIndicator.key,
      description: valuesCopy.description,
      country: valuesCopy.country.key,
      city: valuesCopy.city,
      startDate: formatDate(valuesCopy.startDate),
      endDate: formatDate(valuesCopy.endDate),
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedAcaExp(true);
    if (!pending) {
      (async () => {
        if (experiencesList.length < 5) {
          try {
            await dispatch(
              PostExperienceThunk({
                cvId: cvId,
                dataToSend,
                // axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutExpAcademicTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutExpAcademicMessage")}
              />,
            );
            console.log("YourProfile post success !");
            setIsPossible(true);
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        } else {
          setIsPossible(false);
        }
      })();
    }
    resetForm({ values: "" });
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      institution: "",
      course: "",
      startDate: null,
      endDate: null,
      graduationSuccessIndicator: { ...empty },
      description: "",
      country: { ...empty },
      city: "",
    },
    validationSchema: AcademicExperienceSchema,
    onSubmit,
  });
  const HandlePushExperienceButton = ({ text, disabled }) => {
    return (
      <button
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
        disabled={disabled}
      >
        {text}
      </button>
    );
  };

  const AddExperienceBtn = ({ text, disabled }) => {
    return (
      <button
        disabled={disabled}
        style={{ color: disabled ? "grey" : null }}
        type="submit"
        className={style.add_exp_btn}
        onClick={() => setIsFinishedAcaExp(false)}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}

      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              {isPossible === false && (
                <span style={{ color: "red" }}>Vous ne Pouvez pas Ajouter plus que 5 expériences</span>
              )}
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span>
                  <p>{t("app.depotCv.ExperienceAcademiques")}</p>
                </span>
              </div>
              <>
                {experiencesList.map((obj) => (
                  <>
                    <Card
                      modifyForm={goToModify}
                      isModify={isModify}
                      handleModalData={handleModalData}
                      {...obj}
                      key={nanoid()}
                    />
                  </>
                ))}

                {isFinishedAcaExp ? (
                  <div className={style.add_exp_btn_container}>
                    <AddExperienceBtn
                      disabled={experiencesList.length >= 5 ? true : false}
                      text={t("app.depotCv.addExp")}
                    />
                  </div>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="institution">{t("app.depotCv.Etablissement")} </label>
                      <input
                        id="institution"
                        type="text"
                        style={
                          errors.institution &&
                          touched.institution && {
                            border: "2px solid #EA001E",
                          }
                        }
                        value={values.institution}
                        onChange={(e) => setFieldValue("institution", e.target.value)}
                      />
                      <InputErrorMessage message={errors.institution} touched={touched.institution} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="course">{t("app.depotCv.FormationSuivie")} </label>
                      <input
                        style={
                          errors.course &&
                          touched.course && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="course"
                        type="text"
                        value={values.course}
                        onChange={(e) => setFieldValue("course", e.target.value)}
                      />
                      <InputErrorMessage message={errors.course} touched={touched.course} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="startDate">{t("app.depotCv.DateDebut")} </label>
                      {/* <DatePicker
												style={
													errors.startDate &&
													touched.startDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.startDate}
												onChange={(value) => setFieldValue("startDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="endDate">{t("app.depotCv.DateFin")} </label>
                      {/* <DatePicker
												style={
													errors.endDate &&
													touched.endDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.endDate}
												onChange={(value) => setFieldValue("endDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.endDate}
                        touched={touched.endDate}
                        value={values.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="graduationSuccessIndicator">{t("app.depotCv.DiplomeObtenu")} </label>
                      <DropdownList
                        style={
                          errors.graduationSuccessIndicator &&
                          touched.graduationSuccessIndicator && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="graduationSuccessIndicator"
                        dataKey="key"
                        textField="label"
                        data={[{ ...empty }, ...referencialData?.STUDENT_GRADUATION_STATUS]}
                        value={values.graduationSuccessIndicator}
                        onChange={(newValue) => setFieldValue("graduationSuccessIndicator", newValue)}
                      />
                      <InputErrorMessage
                        message={errors.graduationSuccessIndicator}
                        touched={touched.graduationSuccessIndicator}
                      />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="description">{t("app.depotCv.Description")} </label>
                      <textarea
                        style={
                          errors.graduationSuccessIndicator &&
                          touched.graduationSuccessIndicator && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="description"
                        value={values.description}
                        onChange={(e) => setFieldValue("description", e.target.value)}
                      />
                      <InputErrorMessage message={errors.description} touched={touched.description} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="country">{t("app.depotCv.Pays")} </label>
                      <DropdownList
                        style={
                          errors.country &&
                          touched.country && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="country"
                        dataKey="key"
                        textField="label"
                        data={[empty, ...referencialData?.COUNTRY]}
                        value={values.country}
                        onChange={(newValue) => setFieldValue("country", newValue)}
                      />
                      <InputErrorMessage message={errors.country} touched={touched.country} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="city">{t("app.depotCv.Ville")} </label>
                      <input
                        id="city"
                        style={
                          errors.graduationSuccessIndicator &&
                          touched.graduationSuccessIndicator && {
                            border: "2px solid #EA001E",
                          }
                        }
                        type="text"
                        value={values.city}
                        onChange={(e) => setFieldValue("city", e.target.value)}
                      />
                      <InputErrorMessage message={errors.city} touched={touched.city} />
                    </div>

                    <div className={style.validate_exp_btn_container}>
                      <HandlePushExperienceButton text={t("app.depotCv.ValiderExp")} />
                    </div>
                  </div>
                )}
              </>
            </form>
          </section>
        </>
      )}
    </>

    // End Academic experiences
  );
};

export default AcademicExperiences;
