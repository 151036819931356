import { t } from "i18next";

import { useEffect, useState } from "react";

import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";

import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";
import CheckboxOrganism from "../components/CheckboxOrganism";
import UploadOrganism from "../../../components/UploadOrganism/UploadOrganism";
import BlueBanner from "../components/BlueBanner";

import styles from "../Step2.module.scss";

const ApprenticeshipForm = ({ studentAge, axiosCancelToken }) => {
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);

  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);

  useEffect(() => {
    console.log("VALUES : ", values);
    console.log("AGE : ", studentAge);
  });

  useEffect(() => {
    if (studentAge >= 16 && studentAge < 30) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [studentAge]);

  // useEffect onChange for UploadOrganism
  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          await dispatch(
            postFileThunk({
              file,
              endpoint: `/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/DEROGATORY`,
              axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersAjoutes")}
              secondMessage={`${t("app.toastMessages.FichiersAjoutesMessage")} 1`}
            />,
          );
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          console.log("upload success TestUploadFile !");
        } catch (error) {
          console.log("upload fail TestUploadFile !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericError")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // onSubmit form function
  const onSubmit = (values) => {
    if (!disabled && fileUploaded === 0) {
      setSpanErrorMessage(true);
      return;
    }

    setSpanErrorMessage(false);

    // Data to send object initialization
    const dataToSend = disabled
      ? { stage: "Etape3" }
      : {
        stage: "Etape3",
        student: {
          companyProject: values.companyProjectCheckbox ? values.companyProjectCheckbox : null,
          apprenticeshipProject: {
            derogatoryCase: {
              contractInProgressOrTerminatedLessThan1Year: values.apprenticeshipContractCheckbox,
              contractBreach: values.breachOfContractCheckbox,
              disability: values.disabilityCheckbox,
              sportsman: values.sportsmanCheckbox,
            },
          },
        },
      };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  // useFormik from Formik
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      apprenticeshipContractCheckbox: preContractualizationInformations.student.apprenticeshipProject.derogatoryCase
        .contractInProgressOrTerminatedLessThan1Year
        ? true
        : false,
      breachOfContractCheckbox: preContractualizationInformations.student.apprenticeshipProject.derogatoryCase
        .contractBreach
        ? true
        : false,
      disabilityCheckbox: preContractualizationInformations.student.apprenticeshipProject.derogatoryCase.disability
        ? true
        : false,
      companyProjectCheckbox: preContractualizationInformations.student.companyProject ? true : false,
      sportsmanCheckbox: preContractualizationInformations.student.apprenticeshipProject.derogatoryCase.sportsman
        ? true
        : false,
    },
    onSubmit,
  });

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      {disabled && <BlueBanner />}
      <form onSubmit={handleSubmit}>
        <div className={styles.inputs_container}>
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="apprenticeshipContractCheckbox"
            label={t("app.declarezUnRecrutement.ContratDapprentissage")}
            text={t("app.declarezUnRecrutement.ContratDapprentissageDesc")}
            infoBubbleContent={t("app.declarezUnRecrutement.ContratDapprentissageDesc")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="breachOfContractCheckbox"
            label={t("app.declarezUnRecrutement.RuptureDeContrat")}
            text={t("app.declarezUnRecrutement.RuptureContratCausesIndependantes")}
            infoBubbleContent={t("app.declarezUnRecrutement.RuptureContratCausesIndependantes")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="disabilityCheckbox"
            label={t("app.declarezUnRecrutement.Handicap")}
            text={t("app.declarezUnRecrutement.HandicapReconnu")}
            infoBubbleContent={t("app.declarezUnRecrutement.HandicapReconnu")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="companyProjectCheckbox"
            label={t("app.declarezUnRecrutement.ProjetDentreprise")}
            text={t("app.declarezUnRecrutement.ProjetCreationNecessitantDiplomeDetermine")}
            infoBubbleContent={t("app.declarezUnRecrutement.ProjetCreationNecessitantDiplomeDetermine")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <CheckboxOrganism
            disabled={disabled}
            checkboxId="sportsmanCheckbox"
            label={t("app.declarezUnRecrutement.Sportif")}
            text={t("app.declarezUnRecrutement.ReconnaissanceSportifHautNiveau")}
            infoBubbleContent={t("app.declarezUnRecrutement.ReconnaissanceSportifHautNiveau")}
            values={values}
            setFieldValue={setFieldValue}
          />
          <UploadOrganism
            labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
            disabled={disabled}
            setFile={setFile}
            setDisplayModal={setDisplayModal}
            spanErrorMessage={spanErrorMessage}
          />
        </div>
      </form>
      <div className={styles.btns_container}>
        <SubmitContractBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} handleNextStep={handleSubmit} />
        <PreviousFormBtn
          text={t("app.declarezUnRecrutement.EtapePrecedente")}
          handlePreviousStep={() => dispatch(handlePreviousStep("Etape1"))}
        />
      </div>
    </>
  );
};

export default ApprenticeshipForm;
