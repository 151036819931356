import { t } from "i18next";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { createObjAucun, createBooleanReferential } from "utils/localReferentials";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { Listbox, DropdownList } from "react-widgets/cjs";
import UploadDeleteOrganism from "components/UploadDeleteOrganism/UploadDeleteOrganism";
import {
  handlePreviousStep,
  getPublishAnOfferPending,
  getPublishAnOfferErrorMessage,
  patchStepThunk,
  getPublishAnOfferId,
  getPublishAnOfferDateAndPlace,
  getPublishAnOfferYourNeedData,
} from "../../../../../app/slices/publishAnOffer/publishAnOffer.slice";
import { getReferentialsData } from "../../../../../app/slices/referentials/referentials.slice";

import Header from "../../../../../components/Header/Header";
import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import PreviousFormBtn from "../../../../../components/PreviousFormBtn/PreviousFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";

import ResumeCard from "../../../../../components/ResumeCard/ResumeCard";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../../PublishAnOffer.module.scss";

/* eslint-disable */
const PositionToBeFilled = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getPublishAnOfferPending);
  const errorMessage = useSelector(getPublishAnOfferErrorMessage);
  const id = useSelector(getPublishAnOfferId);
  const dateAndPlaceData = useSelector(getPublishAnOfferDateAndPlace);
  const yourNeedData = useSelector(getPublishAnOfferYourNeedData);
  const offerInfo = useSelector(getPublishAnOfferYourNeedData);
  const referentials = useSelector(getReferentialsData);

  const objAucun = createObjAucun();
  const booleanReferential = createBooleanReferential();

  const handleDisplay = (country) => {
    let displayPostalCode;
    let displayTown;

    if (country.key === "FR" || country.key === "CH") {
      displayPostalCode = true;
      displayTown = true;
    } else {
      displayPostalCode = false;
      displayTown = true;
    }

    return [displayPostalCode, displayTown];
  };

  // Yup validation schema
  const yourNeedSchema = Yup.object().shape({
    positionTitle: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    jobDescritpion: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    offerOpenToPerson: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    requiredLanguages: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    specifyOtherLanguage: Yup.string().when("requiredLanguages", {
      is: (requiredLanguages) => requiredLanguages.some((obj) => obj.key === "Autre"),
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),
    paidInternship: Yup.object().when([], {
      is: () => yourNeedData.typeOfContract.some((obj) => obj.key === "Stage"),
      then: (schema) =>
        schema.test("if-is-stage", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    currency: Yup.object().when(["paidInternship"], {
      is: (paidInternship) =>
        paidInternship.key === "Oui" || yourNeedData.typeOfContract.some((obj) => obj.key !== "Stage"),
      then: (schema) =>
        schema.test("if-is-currency", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    specifyIfOtherCurrency: Yup.string().when(["currency"], {
      is: (currency) => currency.key === "Autre",
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),
    gratification: Yup.string().when("paidInternship", {
      is: (paidInternship) =>
        paidInternship.key === "Oui" || yourNeedData.typeOfContract.some((obj) => obj.key !== "Stage"),
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),
    howToApply: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    urlOfYourWebsite: Yup.string().when("howToApply", {
      is: (howToApply) => howToApply.key === "Via votre site internet",
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),
    emailAdressToApply: Yup.string().when("howToApply", {
      is: (howToApply) => howToApply.key === "Via une adresse email",
      then: (schema) =>
        schema.email(t("app.errorMessages.FormatEmailInvalide")).required(t("app.errorMessages.RemplirChamp")),
    }),
    iWouldLikeToTalk: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
  });

  // onSubmit form function
  const onSubmit = (values) => {
    console.log("PositionToBeFilled values : ", values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    // Data to send object initialization
    const dataToSend = {
      status: "Proposée",
      position: {
        title: valuesCopy.positionTitle,
        description: valuesCopy.jobDescritpion,
        openToDisabledPeople: valuesCopy.offerOpenToPerson.key,
      },
      requirements: {
        requiredLanguages: {
          known: valuesCopy.requiredLanguages.map((obj) => obj.key),
          others: valuesCopy.specifyOtherLanguage ? [valuesCopy.specifyOtherLanguage] : null,
        },
      },
      gratification: {
        paid: valuesCopy.paidInternship.key,
        currency: valuesCopy.currency.key,
        otherCurrency: valuesCopy.specifyIfOtherCurrency,
        gratification: valuesCopy.gratification ? parseInt(valuesCopy.gratification) : null,
      },
      contact: {
        applicationChannel: valuesCopy.howToApply.key,
        wantToSpeakToAdministration: valuesCopy.iWouldLikeToTalk.key,
        applicationEmail: valuesCopy.emailAdressToApply,
        companyWebsiteURL: valuesCopy.urlOfYourWebsite,
      },
    };

    console.log("dataToSend", dataToSend);

    if (!pending) {
      (async () => {
        try {
          console.log("try id : ", id);
          await dispatch(
            patchStepThunk({
              dataToSend,
              id,
              axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          console.log("DateAndPlace put success !");
        } catch (error) {
          console.log("DateAndPlace put fail !", error.message);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      positionTitle: "",
      jobDescritpion: "",
      offerOpenToPerson: { ...objAucun },
      requiredLanguages: [],
      specifyOtherLanguage: "",
      paidInternship: { ...objAucun },
      currency: { ...objAucun },
      specifyIfOtherCurrency: "",
      gratification: "",
      howToApply: { ...objAucun },
      urlOfYourWebsite: "",
      emailAdressToApply: "",
      iWouldLikeToTalk: { ...objAucun },
    },
    validationSchema: yourNeedSchema,
    onSubmit,
  });

  return (
    <>
      {pending && referentials && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && referentials && (
        <>
          <Header
            h1={t("app.depotOffre.DeposezUneOffre")}
            textElement={
              <p>
                {t("app.depotOffre.Description3_part1")}
                <br />
                {t("app.depotOffre.Description3_part2")}
              </p>
            }
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.form_and_card_container}>
                <div className={styles.title_container}>
                  <h2>{t("app.depotOffre.PosteAPourvoir")}</h2>
                  <div>{t("app.depotOffre.Etape3Sur3")}</div>
                </div>

                <form className={styles.position_to_be_filled} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="positionTitle">{t("app.depotOffre.IntitulePoste")}</label>
                      <input
                        style={
                          errors.positionTitle &&
                          touched.positionTitle && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="positionTitle"
                        type="text"
                        maxLength={255}
                        value={values.positionTitle}
                        onChange={(e) => setFieldValue("positionTitle", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.positionTitle} touched={touched.positionTitle} />
                    </div>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="jobDescritpion">{t("app.depotOffre.DescriptifDuPoste")}</label>
                      <textarea
                        style={
                          errors.jobDescritpion &&
                          touched.jobDescritpion && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="jobDescritpion"
                        rows="5"
                        maxLength={32768}
                        value={values.jobDescritpion}
                        onChange={(e) => setFieldValue("jobDescritpion", e.target.value.trimStart())}
                      ></textarea>
                      <InputErrorMessage message={errors.jobDescritpion} touched={touched.jobDescritpion} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="offerOpenToPerson">{t("app.depotOffre.OuvertRQTH")}</label>
                      <span className={styles.span_for_label}>{t("app.depotOffre.OuvertRQTHHelpText")}</span>
                      <div className={styles.info_bubble} info-bubble-content={t("app.depotOffre.OuvertRQTHHelpText")}>
                        <svg
                          className="slds-button__icon"
                          focusable="false"
                          data-key="info"
                          aria-hidden="true"
                          viewBox="0 0 52 52"
                          part="icon"
                          fill="grey"
                        >
                          <g>
                            <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                          </g>
                        </svg>
                      </div>
                      <DropdownList
                        style={
                          errors.offerOpenToPerson &&
                          touched.offerOpenToPerson && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="offerOpenToPerson"
                        dataKey="key"
                        textField="label"
                        data={[...booleanReferential]}
                        value={values.offerOpenToPerson}
                        onChange={(newValue) => setFieldValue("offerOpenToPerson", newValue)}
                      />
                      <InputErrorMessage message={errors.offerOpenToPerson} touched={touched.offerOpenToPerson} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="requiredLanguages">{t("app.depotOffre.LanguesRequises")}</label>
                      <Listbox
                        style={
                          errors.requiredLanguages &&
                          touched.requiredLanguages && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="requiredLanguages"
                        multiple
                        dataKey="key"
                        textField="label"
                        data={referentials.LANGUAGE}
                        value={values.requiredLanguages}
                        onChange={(newValue) => {
                          setFieldValue("specifyOtherLanguage", "");
                          setFieldValue("requiredLanguages", newValue);
                        }}
                      />
                      <InputErrorMessage message={errors.requiredLanguages} touched={touched.requiredLanguages} />
                    </div>

                    {values.requiredLanguages.some((obj) => obj.key === "Autre") && (
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="specifyOtherLanguage">{t("app.depotOffre.PrecisezAutreLangue")}</label>
                        <input
                          style={
                            errors.specifyOtherLanguage &&
                            touched.specifyOtherLanguage && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="specifyOtherLanguage"
                          type="text"
                          value={values.specifyOtherLanguage}
                          onChange={(e) => setFieldValue("specifyOtherLanguage", e.target.value.trimStart())}
                        />
                        <InputErrorMessage
                          message={errors.specifyOtherLanguage}
                          touched={touched.specifyOtherLanguage}
                        />
                      </div>
                    )}

                    {yourNeedData.typeOfContract.some((obj) => obj.key === "Stage") && (
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="paidInternship">{t("app.depotOffre.StageRemunere")}</label>
                        <DropdownList
                          style={
                            errors.paidInternship &&
                            touched.paidInternship && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="paidInternship"
                          dataKey="key"
                          textField="label"
                          data={[...booleanReferential]}
                          value={values.paidInternship}
                          onChange={(newValue) => {
                            setFieldValue("currency", { ...objAucun });
                            setFieldValue("gratification", "");
                            setFieldValue("paidInternship", newValue);
                          }}
                        />
                        <InputErrorMessage message={errors.paidInternship} touched={touched.paidInternship} />
                      </div>
                    )}

                    {(values.paidInternship.key === "Oui" ||
                      yourNeedData.typeOfContract.some((obj) => obj.key !== "Stage")) && (
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="currency">{t("app.depotOffre.Devise")}</label>
                        <DropdownList
                          style={
                            errors.currency &&
                            touched.currency && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="currency"
                          dataKey="key"
                          textField="label"
                          data={referentials.CURRENCY && [{ ...objAucun }, ...referentials.CURRENCY]}
                          value={values.currency}
                          onChange={(newValue) => {
                            setFieldValue("specifyIfOtherCurrency", "");
                            setFieldValue("currency", newValue);
                          }}
                        />
                        <InputErrorMessage message={errors.currency} touched={touched.currency} />
                      </div>
                    )}

                    {values.currency.key === "Autre" && (
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="specifyIfOtherCurrency">{t("app.depotOffre.PrecisezAutreDevise")}</label>
                        <input
                          style={
                            errors.specifyIfOtherCurrency &&
                            touched.specifyIfOtherCurrency && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="specifyIfOtherCurrency"
                          type="text"
                          maxLength={255}
                          value={values.specifyIfOtherCurrency}
                          onChange={(e) => setFieldValue("specifyIfOtherCurrency", e.target.value.trimStart())}
                        />
                        <InputErrorMessage
                          message={errors.specifyIfOtherCurrency}
                          touched={touched.specifyIfOtherCurrency}
                        />
                      </div>
                    )}

                    {(values.paidInternship.key === "Oui" ||
                      yourNeedData.typeOfContract.some((obj) => obj.key !== "Stage")) && (
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="gratification">{t("app.depotOffre.Salaire")}</label>
                        <input
                          style={
                            errors.gratification &&
                            touched.gratification && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="gratification"
                          type="number"
                          min="0"
                          value={values.gratification}
                          onChange={(e) => setFieldValue("gratification", e.target.value)}
                        />
                        <InputErrorMessage message={errors.gratification} touched={touched.gratification} />
                      </div>
                    )}

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="howToApply">{t("app.depotOffre.ModaliteCandidature")}</label>
                      <DropdownList
                        style={
                          errors.howToApply &&
                          touched.howToApply && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="howToApply"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.COMPANY_JOB_OFFER_APPLICATION_CHANNEL && [
                            { ...objAucun },
                            ...referentials.COMPANY_JOB_OFFER_APPLICATION_CHANNEL,
                          ]
                        }
                        value={values.howToApply}
                        onChange={(newValue) => {
                          setFieldValue("urlOfYourWebsite", "");
                          setFieldValue("emailAdressToApply", "");
                          setFieldValue("howToApply", newValue);
                        }}
                      />
                      <InputErrorMessage message={errors.howToApply} touched={touched.howToApply} />
                    </div>

                    {values.howToApply.key === "Via votre site internet" && (
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="urlOfYourWebsite">{t("app.depotOffre.URLSite")}</label>
                        <input
                          style={
                            errors.urlOfYourWebsite &&
                            touched.urlOfYourWebsite && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="urlOfYourWebsite"
                          type="text"
                          value={values.urlOfYourWebsite}
                          onChange={(e) => setFieldValue("urlOfYourWebsite", e.target.value.trimStart())}
                        />
                        <InputErrorMessage message={errors.urlOfYourWebsite} touched={touched.urlOfYourWebsite} />
                      </div>
                    )}

                    {values.howToApply.key === "Via une adresse email" && (
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="emailAdressToApply">{t("app.depotOffre.EmailPostuler")}</label>
                        <input
                          style={
                            errors.emailAdressToApply &&
                            touched.emailAdressToApply && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="emailAdressToApply"
                          type="email"
                          value={values.emailAdressToApply}
                          onChange={(e) => setFieldValue("emailAdressToApply", e.target.value)}
                        />
                        <InputErrorMessage message={errors.emailAdressToApply} touched={touched.emailAdressToApply} />
                      </div>
                    )}

                    <UploadDeleteOrganism
                      id={id}
                      middleware="company"
                      endpoint={`/v1/companies/job-offers/${id}/supporting-documents`}
                      labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
                      uploadMessages={{
                        success: {
                          firstMessage: t("app.toastMessages.FichiersAjoutes"),
                          secondMessage: `${t("app.toastMessages.FichiersAjoutesMessage")}`,
                        },
                        error: {
                          firstMessage: t("app.toastMessages.genericError"),
                        },
                      }}
                      deleteMessages={{
                        success: {
                          firstMessage: t("app.toastMessages.genericDeleteSuccess"),
                        },
                        error: {
                          firstMessage: t("app.toastMessages.genericDeleteError"),
                        },
                      }}
                    />

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="iWouldLikeToTalk">{t("app.depotOffre.EchangerServiceRelation")}</label>
                      <DropdownList
                        style={
                          errors.iWouldLikeToTalk &&
                          touched.iWouldLikeToTalk && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="iWouldLikeToTalk"
                        dataKey="key"
                        textField="label"
                        data={[...booleanReferential]}
                        value={values.iWouldLikeToTalk}
                        onChange={(newValue) => setFieldValue("iWouldLikeToTalk", newValue)}
                      />
                      <InputErrorMessage message={errors.iWouldLikeToTalk} touched={touched.iWouldLikeToTalk} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.depotOffre.Validation")} />
                  <PreviousFormBtn
                    text={t("app.depotOffre.EtapePrecedente")}
                    handlePreviousStep={() => dispatch(handlePreviousStep())}
                  />
                </form>
              </div>

              <ResumeCard
                myElem={[
                  {
                    libel: t("app.depotOffre.TypeDeContrat"),
                    value: yourNeedData.typeOfContract,
                    isObjArray: true,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.NiveauEtude"),
                    value: yourNeedData.targetLevelOfStudy,
                    isObjArray: true,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.DateDebut"),
                    value: dateAndPlaceData.startDate,
                    isObjArray: false,
                    isDate: true,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.LieuDuPoste"),
                    value: referentials.COUNTRY.find((obj) => obj.key === offerInfo.countryOfThePosition.key)?.label,
                    isObjArray: false,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.CodePostal"),
                    value: dateAndPlaceData.postalCode,
                    isObjArray: false,
                    isDate: false,
                    isPostalCode: true,
                    isCity: false,
                    toDisplay: handleDisplay(yourNeedData.countryOfThePosition)[0],
                  },
                  {
                    libel: t("app.depotOffre.Ville"),
                    value: dateAndPlaceData.city,
                    isObjArray: false,
                    isDate: false,
                    isCity: true,
                    toDisplay: handleDisplay(yourNeedData.countryOfThePosition)[1],
                  },
                ]}
                title={t("app.depotOffre.Recapitulatif")}
                dateAndPlace={dateAndPlaceData}
                yourNeed={yourNeedData}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default PositionToBeFilled;
