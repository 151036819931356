import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getAssociativeExperiencesThunk = createAsyncThunk(
  "associativeExperiences/getAssociativeExperiencesThunk",
  async (id) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/associative`);
    return response.data;
  },
);

export const getAssociativeExperiencesByIdThunk = createAsyncThunk(
  "associativeExperiences/getAssociativeExperiencesByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/associative/${ID}`);
    return response.data;
  },
);

export const postAssociativeExperiencesThunk = createAsyncThunk(
  "associativeExperiences/postAssociativeExperiencesThunk",
  async ({ dataToSend, id }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${id}/experiences/associative`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

export const patchAssociativeExperiencesThunk = createAsyncThunk(
  "associativeExperiences/patchAssociativeExperiencesThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/experiences/associative/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const associativeExperiencesSlice = createSlice({
  name: "associativeExperiences",
  initialState,
  extraReducers: {
    [getAssociativeExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getAssociativeExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAssociativeExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postAssociativeExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postAssociativeExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postAssociativeExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchAssociativeExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchAssociativeExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchAssociativeExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getAssociativeExperiencesByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
      return state;
    },
    [getAssociativeExperiencesByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getAssociativeExperiencesByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
  },
});

export const getAssociativeExperiences = (state) => state.associativeExperiences.data;
export const getAssociativeExperiencesPending = (state) => state.associativeExperiences.pending;

export default associativeExperiencesSlice.reducer;
