import { useSelector } from "react-redux";
import { t } from "i18next";
import { useIsAuthenticated } from "@azure/msal-react";

import {
  DISCOVER_OMNES_EDUCATION_URL,
  STUDENT_SUBDOMAIN_NAME,
  COMPANY_SUBDOMAIN_NAME,
} from "utils/globalConstantsAndURL";
import { getSubdomain } from "../../app/slices/subdomain/subdomain.slice";

import ItemSidenav from "./ItemSidenav/ItemSidenav";
import SidenavETUContent from "./data/SidenavETUContent";
import SidenavENTContent from "./data/SidenavENTContent";

import omnesLogo from "./assets/Logo-blanc-violet.png";
import inseecImg from "./assets/INSEEC.png";
import faqImg from "./assets/FAQ.png";
import contactImg from "./assets/Contact.png";

import styles from "./Sidenav.module.scss";

const Sidenav = () => {
  /**
   *  This component adapts according to the sub-domain.
   */

  // The subdomain is used to display relevant content.
  const subdomain = useSelector(getSubdomain);

  const isAuthenticated = useIsAuthenticated();

  return (
    <nav className={styles.side_nav}>
      <div className={styles.wrapper}>
        <div className={styles.logo_container}>
          <img src={omnesLogo} alt="Logo Omnes éducation" />
        </div>
        <div className={styles.nav_blocs_container}>
          {subdomain === STUDENT_SUBDOMAIN_NAME && isAuthenticated && <SidenavETUContent />}
          {subdomain === COMPANY_SUBDOMAIN_NAME && <SidenavENTContent />}
        </div>
        <div className={styles.footer_sidenav_container}>
          <ItemSidenav
            name={t("app.horizontalNavLoginStudent.DecouvrirOmnesEducation")}
            img={inseecImg}
            blankURL={DISCOVER_OMNES_EDUCATION_URL}
          />
          <ItemSidenav name={t("app.horizontalNavLoginStudent.FAQ")} img={faqImg} path="/faq" />
          {/* {isAuthenticated && (
            <ItemSidenav
              name={t("app.horizontalNavLoginStudent.ContactezNous")}
              img={contactImg}
              path="/contactez-nous"
            />
          )} */}
          <ItemSidenav
            name={t("app.horizontalNavLoginStudent.ContactezNous")}
            img={contactImg}
            path="/contactez-nous"
          />
        </div>
      </div>
    </nav>
  );
};

export default Sidenav;
