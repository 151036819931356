import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useSelector, useDispatch } from "react-redux";
import { patchProjectsThunk, getProjects } from "../../../../app/slices/modifyYourCV/academicProjects.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    padTo2Digits(convertToDate.getDate()),
    padTo2Digits(convertToDate.getMonth() + 1),
    convertToDate.getFullYear(),
  ].join("-");
}

/* eslint-disable */
const ModifyModalAcademicProjects = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const AcademicProjectsById = useSelector(getProjects);

  const handleProps = (value) => {
    if (value !== undefined) {
      setFieldValue("title", value.title);
      setFieldValue("startDate", value.startDate);
      setFieldValue("endDate", value.endDate);
      setFieldValue("description", value.description);
    }
  };

  useEffect(() => {
    handleProps(props.object);
  }, [props.object]);

  const onSubmit = (values) => {
    const dataToSend = {
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      title: values.title,
      description: values.description,
    };
    (async () => {
      try {
        await dispatch(
          patchProjectsThunk({
            dataToSend,
            id: id,
            ID: props.id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationModifProjetAcademicTitle")}
            secondMessage={t("app.toastMessages.ConfirmationModifProjetAcademicMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const ModifyModalAcademicProjectsSchema = Yup.object().shape({
    title: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      title: props.object?.title ? props.object?.title : "",
      startDate: null,
      endDate: null,
      description: props.object?.description ? props.object?.description : "",
    },
    validationSchema: ModifyModalAcademicProjectsSchema,
    onSubmit,
  });

  if (props.showModify && AcademicProjectsById)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModifiezProjet")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="title">
                    {t("app.visualisationCv.TitreProjet")}
                  </label>
                  <input
                    style={
                      errors.title &&
                      touched.title && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.title}
                    maxLength={80}
                    type="text"
                    name="title"
                    id="title"
                    onChange={(e) => setFieldValue("title", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.title} touched={touched.title} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  {/* <DatePicker
										value={new Date(values.startDate)}
										onChange={(value) => setFieldValue("startDate", value)}
									/> */}
                  <DatePickerCustom
                    value={new Date(values.startDate)}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  {/* <DatePicker
										value={new Date(values.endDate)}
										onChange={(value) => setFieldValue("endDate", value)}
									/> */}
                  <DatePickerCustom
                    value={new Date(values.endDate)}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="typeOfContract">
                    {t("app.visualisationCv.Description")}
                  </label>
                  <textarea
                    value={values.description}
                    maxLength={500}
                    type="description"
                    name="description"
                    id="description"
                    onChange={(e) => setFieldValue("description", e.target.value.trimStart())}
                  ></textarea>
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezModification")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showModify) return null;
};

export default ModifyModalAcademicProjects;
