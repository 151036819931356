import { t } from "i18next";

import Header from "components/Header/Header";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import {
  getLearningBookletsIdData,
  getLearningBookletsPending,
  getOneThunk,
} from "app/slices/learningBooklets/learningBooklets.slice";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import bgContractImg from "../../../assets/bg-consul-offre.png";
import BackButton from "../../components/BackButton/BackButton";
import styles from "./Mission.module.scss";

const Mission = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();

  const dispatch = useDispatch();

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);

  const userToken = useSelector(getUserToken);

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getOneThunk({ id, axiosCancelToken })).unwrap();
          console.log("useLayoutEffect success ! SelectedBooklet");
        } catch (error) {
          console.log("useLayoutEffect fail ! SelectedBooklet", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const allMissions = idData?.missions;

  /* eslint-disable */
  return (
    <div>
      <Header
        h1={t("app.suivezLivretsPartner.SuivezAlternant")}
        textElement={<p>{t("app.suivezLivretsPartner.MissionDescription")}</p>}
        bgImg={bgContractImg}
      />

      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.container}>
            <div className={styles.title_container}>
              <h2>{t("app.suivezLivretsPartner.Formulaires")}</h2>
            </div>
            <form className={styles.the_form}>
              <div className={styles.inputs_container}>
                {allMissions?.map((item, index) => (
                  <div key={nanoid()} className={styles.label_plus_input_container}>
                    <label>
                      {t("app.suivezLivretsPartner.Mission")} {index + 1}
                    </label>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <BackButton text={t("app.formulairesLivretPartnerBilan.BoutonRetour")} />
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Mission;
