import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm7Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm7/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm7Slice = createSlice({
  name: "checkYourRecruitmentsForm7",
  initialState,
  reducers: {
    handleRecruitmentPropsForm7Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm7Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm7Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm7Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm7Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm7Data, handleRecruitmentSectionsForm7Data } =
  checkYourRecruitmentsForm7Slice.actions;

export const getYourRecruitmentPropsForm7Data = (state) => state.checkYourRecruitmentsForm7.recruitmentPropsForm7Data;
export const getAllUserRecruitmentsForm7 = (state) => state.checkYourRecruitmentsForm7.recruitmentList;
export const getUserRecruitmentsPendingForm7 = (state) => state.checkYourRecruitmentsForm7.pending;
export const getUserRecruitmentsErrorMessageForm7 = (state) => state.checkYourRecruitmentsForm7.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm7Slice.reducer;
