import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getProfesionalExperiencesThunk = createAsyncThunk(
  "profesionalExperiences/getProfesionalExperiencesThunk",
  async (id) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/professional`);
    return response.data;
  },
);

export const getProfesionalExperiencesByIdThunk = createAsyncThunk(
  "profesionalExperiences/getProfesionalExperiencesByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/experiences/professional/${ID}`);
    return response.data;
  },
);

export const postProfesionalExperiencesThunk = createAsyncThunk(
  "profesionalExperiences/postProfesionalExperiencesThunk",
  async ({ dataToSend, id }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${id}/experiences/professional`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

export const patchProfesionalExperiencesThunk = createAsyncThunk(
  "profesionalExperiences/patchProfesionalExperiencesThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/experiences/professional/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const profesionalExperiencesSlice = createSlice({
  name: "profesionalExperiences",
  initialState,
  extraReducers: {
    [getProfesionalExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getProfesionalExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getProfesionalExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postProfesionalExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postProfesionalExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postProfesionalExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchProfesionalExperiencesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchProfesionalExperiencesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchProfesionalExperiencesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getProfesionalExperiencesByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
      return state;
    },
    [getProfesionalExperiencesByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getProfesionalExperiencesByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
  },
});

export const getProfesionalExperiences = (state) => state.profesionalExperiences.data;
export const getProfesionalExperiencesPending = (state) => state.profesionalExperiences.pending;

export default profesionalExperiencesSlice.reducer;
