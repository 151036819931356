/* eslint-disable */
import { t } from "i18next";
export const reloadIf401 = (errorMessage) => {
  if (errorMessage === "Request failed with status code 401") window.location.reload();
};

export const changeAucunToNull = (values) => {
  for (const property in values) {
    if (typeof values[property] === "object" && !Array.isArray(values[property]) && values[property]?.key === "-Aucun-")
      values[property] = { key: null, label: null };
  }
};

export const changeOuiOrNonToBoolean = (values) => {
  for (const property in values) {
    if (typeof values[property] === "object" && !Array.isArray(values[property])) {
      if (values[property]?.key === "Oui") {
        values[property] = { key: true, label: true };
      } else if (values[property]?.key === "Non") {
        values[property] = { key: false, label: false };
      }
    }
  }
};

export const changeBooleanToOuiNon = (values) => {
  if (values === true) {
    return { key: true, label: t("app.referentials.Oui") };
  } else if (values === false) {
    return { key: false, label: t("app.referentials.Non") };
  }
};

export const changeEmptyStringToNull = (values) => {
  for (const property in values) {
    if (values[property] === "") values[property] = null;
  }
};

export const removeEverythingExceptNumbers = (element) => {
  if (typeof element === "string") {
    return element.replace(/\D/g, "");
  } else {
    console.error("Le paramètre element de la fonction removeEverythingExceptNumbers() n'est pas une string.");
    return element;
  }
};
