import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { Listbox, DropdownList } from "react-widgets/cjs";
import { createBooleanReferential, createObjAucun } from "utils/localReferentials";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import {
  getcreateYourCVPending,
  getcreateYourCVErrorMessage,
  postStep1Thunk,
  patchStepThunk,
  getcreateYourCVId,
  getcreateYourCVYourSearchData,
  handleStepsData,
} from "../../../../../app/slices/createYourCV/createYourCV.slice";

import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";
import { getReferentialsData } from "../../../../../app/slices/referentials/referentials.slice";
import styles from "../../CreateYourCV.module.scss";
import Header from "../../../../../components/Header/Header";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "../../../../../utils/tools";

/* eslint-disable */
const YourSearch = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourCVPending);
  const errorMessage = useSelector(getcreateYourCVErrorMessage);
  const id = useSelector(getcreateYourCVId);
  const yourSearchData = useSelector(getcreateYourCVYourSearchData);
  const referencialData = useSelector(getReferentialsData);
  const empty = createObjAucun();
  const BooleanReferential = createBooleanReferential();
  // Yup validation schema
  const yourSearchSchema = Yup.object().shape({
    lang: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    availableFrom: Yup.date().required("la date est obligatoire").nullable(true),
    title: Yup.string().max(100, "Maximum 100 caractères.").required("Remplissez ce champ."),
    contractTypes: Yup.array().min(1, "Une option doit être sélectionnée."),
    speciality: Yup.object().test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    subSpeciality: Yup.object().when("speciality", {
      is: (speciality) =>
        speciality.key === "Management" ||
        speciality.key === "Finance" ||
        speciality.key === "Sciences_Politiques_et_Relations_Internationales" ||
        speciality.key === "Ingéniérie",
      then: (schema) => schema.test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    }),
    durationInternship: Yup.object().when("contractTypes", {
      is: (contractTypes) => contractTypes.some((obj) => obj.key === "Stage"),
      then: (schema) => schema.test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    }),
    durationContract: Yup.object().when("contractTypes", {
      is: (contractTypes) => contractTypes.some((obj) => obj.key !== "Stage"),
      then: (schema) => schema.test("if-is-aucun", "Remplissez ce champ.", (value) => value.key !== "-Aucun-"),
    }),
    recognizedDisabilityStatus: Yup.object().test(
      "if-is-aucun",
      "Remplissez ce champ.",
      (value) => value.key !== "-Aucun-",
    ),
    workStudyProgram: Yup.string()
      // .max(100, "Maximum 100 caractères.")
      .when("contractTypes", {
        is: (contractTypes) => contractTypes.some((obj) => obj.key === "Alternance"),
        then: (schema) => schema.required("Remplissez ce champ."),
      })
      .nullable(true),
  });
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const onSubmit = (values) => {
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    dispatch(
      handleStepsData({
        formName: "yourSearch",
        formData: {
          lang: values.lang,
          title: values.title,
          contractTypes: values.contractTypes,
          availableFrom: JSON.stringify(values.availableFrom),
          speciality: values.speciality,
          subSpeciality: values.subSpeciality,
          durationInternship: values.durationInternship,
          durationContract: values.durationContract,
          recognizedDisabilityStatus: values.recognizedDisabilityStatus,
          workStudyProgram: values.workStudyProgram,
        },
      }),
    );

    const dataToSend = {
      lang: valuesCopy.lang.key,
      title: valuesCopy.title,
      contractTypes: valuesCopy.contractTypes.map((obj) => obj.key),
      speciality: valuesCopy.speciality.key,
      subSpeciality: valuesCopy.subSpeciality.key === empty.key ? "" : valuesCopy.subSpeciality.key,
      availableFrom: formatDate(valuesCopy.availableFrom),
      durationInternship: valuesCopy.durationInternship.key,
      durationContract: valuesCopy.durationContract.key === null ? "" : valuesCopy.durationContract.key,
      recognizedDisabilityStatus: valuesCopy.recognizedDisabilityStatus.key,
      workStudyProgram: valuesCopy.workStudyProgram,
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      (async () => {
        try {
          if (!id) {
            await dispatch(
              postStep1Thunk({
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            console.log("YourSearch post success !");
          } else {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();

            console.log("YourSearch patch success !");
          }
        } catch (error) {
          // error is handled in catch block

          console.log("Data :", error);
          //   console.log("Status :" + error.response.status);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: yourSearchData
      ? {
          ...yourSearchData,
          availableFrom: new Date(JSON.parse(yourSearchData.availableFrom)),
        }
      : {
          lang: { ...empty },
          title: "",
          contractTypes: [],
          availableFrom: null,
          speciality: { ...empty },
          subSpeciality: { ...empty },
          durationInternship: { ...empty },
          durationContract: { ...empty },
          recognizedDisabilityStatus: { ...empty },
          workStudyProgram: "",
        },
    validationSchema: yourSearchSchema,
    onSubmit,
  });

  return (
    <>
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && (
        <>
          <Header
            h1={t("app.depotCv.DeposerCV")}
            textElement={
              <p>
                {t("app.depotCv.Etape1")}
                <br /> {t("app.depotCv.Etape1bis")}
              </p>
            }
            bgImg={bgConsulOffreImg}
          />
          <section className={styles.main_container}>
            <div className={styles.title_container}>
              <h2>{t("app.depotCv.VotreRecherche")}</h2>
              <div>(Etape 1/5)</div>
            </div>
            <div className={styles.form_container}>
              <form className={styles.your_need_form} onSubmit={handleSubmit}>
                <span style={{ color: "red" }}>
                  {errorMessage === "Request failed with status code 400"
                    ? `${t("app.depotCv.errorMessages")}`
                    : errorMessage}
                </span>
                <div className={styles.inputs_container}>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="lang">{t("app.depotCv.LangueCV")}</label>
                    <DropdownList
                      id="lang"
                      dataKey="key"
                      textField="label"
                      style={
                        errors.lang &&
                        touched.lang && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      data={referencialData ? [{ ...empty }, ...referencialData?.STUDENT_RESUME_LANG] : [{ ...empty }]}
                      value={values.lang}
                      onChange={(newValue) => setFieldValue("lang", newValue)}
                    />
                    <InputErrorMessage message={errors.lang} touched={touched.lang} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="title">{t("app.depotCv.TitreDuCV")}</label>
                    <input
                      style={
                        errors.title &&
                        touched.title && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="title"
                      value={values.title}
                      type="text"
                      maxLength={100}
                      onChange={(e) => setFieldValue("title", e.target.value.trimStart())}
                    />
                    <InputErrorMessage message={errors.title} touched={touched.title} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="contractTypes">{t("app.depotCv.TypeExperience")}</label>
                    <Listbox
                      id="contractTypes"
                      multiple
                      dataKey="key"
                      textField="label"
                      style={
                        errors.contractTypes &&
                        touched.contractTypes && {
                          border: "2px solid #EA001E",
                        }
                      }
                      data={referencialData ? [...referencialData?.CONTRACT_TYPE] : []}
                      value={values.contractTypes}
                      onChange={(newValue) => {
                        setFieldValue("contractTypes", newValue);
                        setFieldValue("durationInternship", { ...empty });
                        setFieldValue("durationContract", { ...empty });
                        setFieldValue("workStudyProgram", "");
                      }}
                    />
                    <InputErrorMessage message={errors.contractTypes} touched={touched.contractTypes} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="durationInternship">{t("app.depotCv.DureeStage")}</label>

                    <DropdownList
                      style={
                        errors.durationInternship &&
                        touched.durationInternship && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="durationInternship"
                      dataKey="key"
                      textField="label"
                      data={referencialData ? [{ ...empty }, ...referencialData?.INTERNSHIP_DURATION] : [{ ...empty }]}
                      value={values.durationInternship}
                      onChange={(newValue) => setFieldValue("durationInternship", newValue)}
                      disabled={values.contractTypes.some((obj) => obj.key === "Stage") ? false : true}
                    />
                    <InputErrorMessage message={errors.durationInternship} touched={touched.durationInternship} />
                  </div>

                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="availableFrom">{t("app.depotCv.Dispo")}</label>
                    {/* <DatePicker
											style={
												errors.availableFrom &&
												touched.availableFrom && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
												}
											}
											value={values.availableFrom}
											onChange={(value) =>
												setFieldValue("availableFrom", value)
											}
										/> */}
                    <DatePickerCustom
                      error={errors.availableFrom}
                      touched={touched.availableFrom}
                      value={values.availableFrom}
                      onChange={(value) => setFieldValue("availableFrom", value)}
                    />
                    <InputErrorMessage message={errors.availableFrom} touched={touched.availableFrom} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="speciality">{t("app.depotCv.Specialite")}</label>
                    <DropdownList
                      style={
                        errors.speciality &&
                        touched.speciality && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="speciality"
                      multiple
                      dataKey="key"
                      textField="label"
                      data={
                        referencialData ? [{ ...empty }, ...referencialData?.STUDENT_RESUME_SPECIALITY] : [{ ...empty }]
                      }
                      value={values.speciality}
                      onChange={(newValue) => {
                        setFieldValue("speciality", newValue);
                        setFieldValue("subSpeciality", { ...empty });
                      }}
                    />
                    <InputErrorMessage message={errors.speciality} touched={touched.speciality} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="subSpeciality">{t("app.depotCv.SousSpecialite")}</label>
                    <DropdownList
                      style={
                        errors.subSpeciality &&
                        touched.subSpeciality && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="subSpeciality"
                      dataKey="key"
                      textField="label"
                      data={
                        referencialData && values.speciality.key === "Ingéniérie"
                          ? [{ ...empty }, ...referencialData?.STUDENT_RESUME_SUBSPECIALITY_Ingéniérie]
                          : values.speciality.key === "Sciences Politiques et Relations Internationales"
                            ? [
                                { ...empty },
                                ...referencialData?.STUDENT_RESUME_SUBSPECIALITY_Sciences_Politiques_et_Relations_Internationales,
                              ]
                            : values.speciality.key === "Finance"
                              ? [{ ...empty }, ...referencialData?.STUDENT_RESUME_SUBSPECIALITY_Finance]
                              : values.speciality.key === "Management"
                                ? [{ ...empty }, ...referencialData?.STUDENT_RESUME_SUBSPECIALITY_Management]
                                : [{ ...empty }]
                      }
                      value={values.subSpeciality}
                      onChange={(newValue) => setFieldValue("subSpeciality", newValue)}
                      disabled={
                        values.speciality.key === "Ingéniérie" ||
                        values.speciality.key === "Finance" ||
                        values.speciality.key === "Sciences Politiques et Relations Internationales" ||
                        values.speciality.key === "Management"
                          ? false
                          : true
                      }
                    />
                    <InputErrorMessage message={errors.subSpeciality} touched={touched.subSpeciality} />
                  </div>

                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="durationContract">{t("app.depotCv.DureeContrat")}</label>
                    <DropdownList
                      style={
                        errors.durationContract &&
                        touched.durationContract && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="durationContract"
                      dataKey="key"
                      textField="label"
                      data={referencialData ? [{ ...empty }, ...referencialData?.CONTRACT_DURATION] : [{ ...empty }]}
                      value={values.durationContract}
                      onChange={(newValue) => setFieldValue("durationContract", newValue)}
                      disabled={
                        values.contractTypes.some(
                          (obj) =>
                            obj.key === "Emploi CDI" ||
                            obj.key === "Emploi CDD" ||
                            obj.key === "Alternance" ||
                            obj.key === "VIE" ||
                            obj.key === "Service civique",
                        )
                          ? false
                          : true
                      }
                    />
                    <InputErrorMessage message={errors.durationContract} touched={touched.durationContract} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="workStudyProgram">{t("app.depotCv.RythmeAlternance")}</label>
                    <input
                      style={
                        errors.workStudyProgram &&
                        touched.workStudyProgram && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="workStudyProgram"
                      value={values.workStudyProgram}
                      type="text"
                      maxLength={100}
                      disabled={values.contractTypes.some((obj) => obj.key === "Alternance") ? false : true}
                      onChange={(e) => setFieldValue("workStudyProgram", e.target.value.trimStart())}
                    />
                    <InputErrorMessage message={errors.workStudyProgram} touched={touched.workStudyProgram} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="recognizedDisabilityStatus">{t("app.depotCv.RQTH")}</label>
                    <DropdownList
                      style={
                        errors.recognizedDisabilityStatus &&
                        touched.recognizedDisabilityStatus && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="recognizedDisabilityStatus"
                      dataKey="key"
                      textField="label"
                      data={BooleanReferential}
                      value={values.recognizedDisabilityStatus}
                      onChange={(newValue) => setFieldValue("recognizedDisabilityStatus", newValue)}
                    />
                    <InputErrorMessage
                      message={errors.recognizedDisabilityStatus}
                      touched={touched.recognizedDisabilityStatus}
                    />
                  </div>
                </div>
                <SubmitFormBtn text={t("app.depotCv.ValiderEtape")} />
              </form>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default YourSearch;
