import { t } from "i18next";

import { useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { createObjAucun, createBooleanReferential } from "utils/localReferentials";
import { formatDate } from "utils/formatDate";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import { DropdownList } from "react-widgets/cjs";

import {
  handlePreviousStep,
  getPublishAnOfferPending,
  getPublishAnOfferErrorMessage,
  patchStepThunk,
  getPublishAnOfferId,
  getPublishAnOfferDateAndPlace,
  getPublishAnOfferYourNeedData,
  handleStepsData,
} from "../../../../../app/slices/publishAnOffer/publishAnOffer.slice";
import { getReferentialsData } from "../../../../../app/slices/referentials/referentials.slice";

import Header from "../../../../../components/Header/Header";
import SubmitFormBtn from "../../../../../components/SubmitFormBtn/SubmitFormBtn";
import PreviousFormBtn from "../../../../../components/PreviousFormBtn/PreviousFormBtn";
import InputErrorMessage from "../../../../../components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../../PublishAnOffer.module.scss";
import ResumeCard from "../../../../../components/ResumeCard/ResumeCard";

/* eslint-disable */
const DateAndPlace = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getPublishAnOfferPending);
  const errorMessage = useSelector(getPublishAnOfferErrorMessage);
  const id = useSelector(getPublishAnOfferId);

  const offerInfo = useSelector(getPublishAnOfferYourNeedData);
  const dateAndPlaceData = useSelector(getPublishAnOfferDateAndPlace);
  const yourNeedData = useSelector(getPublishAnOfferYourNeedData);
  const referentials = useSelector(getReferentialsData);

  const objAucun = createObjAucun();
  const booleanReferential = createBooleanReferential();

  const countryKey = yourNeedData.countryOfThePosition?.key;

  useEffect(() => {
    if (yourNeedData) {
      if (countryKey !== "FR") {
        setFieldValue("region", { ...objAucun });
        setFieldValue("postalCode", "");
        setFieldValue("city", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yourNeedData]);

  // Yup validation schema
  const yourNeedSchema = Yup.object().shape({
    immediateCheckbox: Yup.boolean(),
    recurrentCheckbox: Yup.boolean(),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    flexibleDateCheckbox: Yup.boolean(),
    estimatedDuration: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    remoteWork: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    remoteWorkFrequency: Yup.object().when("remoteWork", {
      is: (remoteWork) => remoteWork.key === "Oui",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    region: Yup.object().when([], {
      is: () => yourNeedData.countryOfThePosition.key === "FR" || yourNeedData.countryOfThePosition.key === "CH",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    postalCode: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => yourNeedData.countryOfThePosition.key === "FR" || yourNeedData.countryOfThePosition.key === "CH",
        then: (schema) =>
          schema
            .min(4, t("app.errorMessages.Min4Chiffres"))
            .max(5, t("app.errorMessages.Max5Chiffres"))
            .required(t("app.errorMessages.RemplirChamp")),
      }),
    city: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  // onSubmit form function
  const onSubmit = (values, actions) => {
    console.log("DateAndPlace values : ", values);
    dispatch(
      handleStepsData({
        formName: "dateAndPlace",
        formData: {
          ...values,
          startDate: JSON.stringify(values.startDate),
        },
      }),
    );

    // Logic to setup needFrequency in dataToSend
    const needFrequencyArray = [values.immediateCheckbox, values.recurrentCheckbox];

    const newNeedFrequencyArray = [];

    needFrequencyArray.forEach((value, index) => {
      if (value && index === 0) newNeedFrequencyArray.push("IMMEDIATE");
      else if (value && index === 1) newNeedFrequencyArray.push("RECURRENT");
    });

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    // Data to send object initialization
    const dataToSend = {
      availability: {
        needFrequency: newNeedFrequencyArray,
        startDate: formatDate(valuesCopy.startDate),
        flexibleStartDate: valuesCopy.flexibleDateCheckbox,
        contractDuration: valuesCopy.estimatedDuration.key,
      },
      localisation: {
        region: valuesCopy.region.key,
        city: valuesCopy.city,
        zipCode: valuesCopy.postalCode,
        remoteWork: valuesCopy.remoteWork.key,
        remoteWorkFrequency: valuesCopy.remoteWorkFrequency.key,
      },
    };
    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      (async () => {
        try {
          console.log("try id : ", id);
          await dispatch(
            patchStepThunk({
              dataToSend,
              id,
              axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          console.log("DateAndPlace put success !");
        } catch (error) {
          console.log("DateAndPlace put fail !", error.message);
        }
      })();
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: dateAndPlaceData
      ? {
          ...dateAndPlaceData,
          startDate: new Date(JSON.parse(dateAndPlaceData.startDate)),
        }
      : {
          immediateCheckbox: false,
          recurrentCheckbox: false,
          startDate: null,
          flexibleDateCheckbox: false,
          estimatedDuration: { ...objAucun },
          remoteWork: { ...objAucun },
          remoteWorkFrequency: { ...objAucun },
          region: { ...objAucun },
          postalCode: "",
          city: "",
        },
    validationSchema: yourNeedSchema,
    onSubmit,
  });

  return (
    <>
      {pending && referentials && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && referentials && (
        <>
          <Header
            h1={t("app.depotOffre.DeposezUneOffre")}
            textElement={
              <p>
                {t("app.depotOffre.Description2_part1")}
                <br />
                {t("app.depotOffre.Description2_part2")}
              </p>
            }
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.form_and_card_container}>
                <div className={styles.title_container}>
                  <h2>{t("app.depotOffre.DateEtLieu")}</h2>
                  <div className={styles.step_indicator}>{t("app.depotOffre.Etape2Sur3")}</div>
                </div>
                <form className={styles.date_and_place_form} onSubmit={handleSubmit}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.depotOffre.Besoin")}</label>
                      <div className={styles.immediate_and_recurrent_container}>
                        <div className={styles.checkbox_container}>
                          <input
                            id="immediateCheckbox"
                            type="checkbox"
                            checked={values.immediateCheckbox}
                            onChange={(e) => setFieldValue("immediateCheckbox", e.target.checked)}
                          />
                          <div>{t("app.depotOffre.Immediat")}</div>
                        </div>
                        <div className={styles.checkbox_container}>
                          <input
                            id="recurrentCheckbox"
                            type="checkbox"
                            checked={values.recurrentCheckbox}
                            onChange={(e) => setFieldValue("recurrentCheckbox", e.target.checked)}
                          />
                          <div>{t("app.depotOffre.Recurent")}</div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="startDate">{t("app.depotOffre.DateDebut")}</label>
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                      <div className={styles.checkbox_container} style={{ marginTop: "12px" }}>
                        <input
                          type="checkbox"
                          checked={values.flexibleDateCheckbox}
                          onChange={(e) => setFieldValue("flexibleDateCheckbox", e.target.checked)}
                        />
                        <div>{t("app.depotOffre.DateFlexible")}</div>
                      </div>
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="estimatedDuration">{t("app.depotOffre.DureePrevisionnelle")}</label>
                      <DropdownList
                        style={
                          errors.estimatedDuration &&
                          touched.estimatedDuration && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="estimatedDuration"
                        dataKey="key"
                        textField="label"
                        data={
                          referentials.COMPANY_JOB_CONTRACT_DURATION && [
                            { ...objAucun },
                            ...referentials.COMPANY_JOB_CONTRACT_DURATION,
                          ]
                        }
                        value={values.estimatedDuration}
                        onChange={(newValue) => setFieldValue("estimatedDuration", newValue)}
                      />
                      <InputErrorMessage message={errors.estimatedDuration} touched={touched.estimatedDuration} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="remoteWork">{t("app.depotOffre.Teletravail")}</label>
                      <DropdownList
                        style={
                          errors.remoteWork &&
                          touched.remoteWork && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="remoteWork"
                        dataKey="key"
                        textField="label"
                        data={[...booleanReferential]}
                        value={values.remoteWork}
                        onChange={(newValue) => {
                          setFieldValue("remoteWorkFrequency", { ...objAucun });
                          setFieldValue("remoteWork", newValue);
                        }}
                      />
                      <InputErrorMessage message={errors.remoteWork} touched={touched.remoteWork} />
                    </div>

                    {values.remoteWork.key === "Oui" && (
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="remoteWorkFrequency">{t("app.depotOffre.FrequenceDuTeletravail")}</label>
                        <DropdownList
                          style={
                            errors.remoteWorkFrequency &&
                            touched.remoteWorkFrequency && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="remoteWorkFrequency"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.REMOTE_WORK_FREQUENCY && [
                              { ...objAucun },
                              ...referentials.REMOTE_WORK_FREQUENCY,
                            ]
                          }
                          value={values.remoteWorkFrequency}
                          onChange={(newValue) => setFieldValue("remoteWorkFrequency", newValue)}
                        />
                        <InputErrorMessage message={errors.remoteWorkFrequency} touched={touched.remoteWorkFrequency} />
                      </div>
                    )}

                    {
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="region">{t("app.depotOffre.Region")}</label>
                        <DropdownList
                          style={
                            errors.region &&
                            touched.region && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="region"
                          dataKey="key"
                          textField="label"
                          data={countryKey === "CH" ? referentials.REGION_CH : referentials.REGION_FR}
                          value={values.region}
                          onChange={(newValue) => setFieldValue("region", newValue)}
                          disabled={
                            yourNeedData.countryOfThePosition.key === "FR" ||
                            yourNeedData.countryOfThePosition.key === "CH"
                              ? false
                              : true
                          }
                        />
                        <InputErrorMessage message={errors.region} touched={touched.region} />
                      </div>
                    }

                    {
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="postalCode">{t("app.depotOffre.CodePostal")}</label>
                        <input
                          style={
                            errors.postalCode &&
                            touched.postalCode && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="postalCode"
                          type="number"
                          min="0"
                          value={values.postalCode}
                          onChange={(e) => setFieldValue("postalCode", e.target.value)}
                          disabled={
                            yourNeedData.countryOfThePosition.key === "FR" ||
                            yourNeedData.countryOfThePosition.key === "CH"
                              ? false
                              : true
                          }
                        />
                        <InputErrorMessage message={errors.postalCode} touched={touched.postalCode} />
                      </div>
                    }

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="city">{t("app.depotOffre.Ville")}</label>
                      <input
                        style={
                          errors.city &&
                          touched.city && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="city"
                        maxLength={255}
                        type="text"
                        value={values.city}
                        onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
                      />
                      <InputErrorMessage message={errors.city} touched={touched.city} />
                    </div>
                  </div>
                  <SubmitFormBtn text={t("app.depotOffre.ValidezEtapeSuivante")} />
                  <PreviousFormBtn
                    text={t("app.depotOffre.EtapePrecedente")}
                    handlePreviousStep={() => dispatch(handlePreviousStep())}
                  />
                </form>
              </div>

              <ResumeCard
                myElem={[
                  {
                    libel: t("app.depotOffre.LieuDuPoste"),
                    value: referentials.COUNTRY.find((obj) => obj.key === offerInfo.countryOfThePosition.key)?.label,
                    isObjArray: false,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.TypeDeContrat"),
                    value: offerInfo.typeOfContract,
                    isObjArray: true,
                    isDate: false,
                    isPostalCode: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.NiveauEtude"),
                    value: offerInfo.targetLevelOfStudy,
                    isObjArray: true,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.SecteurActivite"),
                    value: offerInfo.companyLineOfBusiness.label,
                    isObjArray: false,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                  {
                    libel: t("app.depotOffre.Metier"),
                    value: offerInfo.department.label,
                    isObjArray: false,
                    isDate: false,
                    isPostalCode: false,
                    isCity: false,
                    toDisplay: true,
                  },
                ]}
                title={t("app.depotOffre.Recapitulatif")}
                offerInfo={offerInfo}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DateAndPlace;
