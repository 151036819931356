import { t } from "i18next";

import { useIsAuthenticated } from "@azure/msal-react";

import { DISCOVER_OMNES_EDUCATION_URL } from "utils/globalConstantsAndURL";

import SignInBtn from "components/Navbar/SignInBtn/SignInBtn";
import SignOutBtn from "components/Navbar/SignOutBtn/SignOutBtn";
import DropDownLanguage from "components/DropDownLanguage/DropDownLanguage";
import ItemDropdown from "../ItemDropdown/ItemDropdown";

import acceuilImg from "../assets/Acceuil.png";
import depotOffreImg from "../assets/Depot_Offre.png";
import offreImg from "../assets/Offre.png";
// import ficheInfoImg from "../assets/Fiche_Info.png";
import contractImg from "../assets/Contract.png";
import studentImg from "../assets/Student.svg";
import inseecImg from "../assets/INSEEC.png";
import faqImg from "../assets/FAQ.png";
import contactImg from "../assets/Contact.png";

import styles from "./Nav.module.scss";

const NavENTContent = ({ name }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={styles.wrapper}>
      {!isAuthenticated && (
        <div>
          <ItemDropdown name={t("app.horizontalNavLogIn.PageAccueil")} img={acceuilImg} path="/" />
          <ItemDropdown
            name={t("app.horizontalNavLoginStudent.DecouvrirOmnesEducation")}
            img={inseecImg}
            blankURL={DISCOVER_OMNES_EDUCATION_URL}
          />
          <ItemDropdown name={t("app.horizontalNavLoginStudent.FAQ")} img={faqImg} path="/faq" />
          <ItemDropdown
            name={t("app.horizontalNavLoginStudent.ContactezNous")}
            img={contactImg}
            path="/contactez-nous"
          />
          <div className={styles.burger_item}>
            <SignInBtn name={name} />
          </div>
          <div className={styles.burger_item}>
            <DropDownLanguage />
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div>
          <ItemDropdown name={t("app.horizontalNavLogIn.PageAccueil")} img={acceuilImg} path="/" />
          <ItemDropdown
            name={t("app.horizontalNavLogIn.DeposezUneOffre")}
            img={depotOffreImg}
            path="/deposer-une-offre"
          />
          <ItemDropdown name={t("app.horizontalNavLogIn.SuivezVosOffres")} img={offreImg} path="/mes-offres" />
          <ItemDropdown name={t("app.horizontalNavLogIn.Cvtheque")} img={acceuilImg} path="/liste-des-cvs" />
          {/* <ItemDropdown
            name={t("app.horizontalNavLogIn.FicheEntreprise")}
            img={ficheInfoImg}
            path="/fiche-entreprise"
          /> */}
          <ItemDropdown
            name={t("app.horizontalNavLogIn.SuivezVosContrats")}
            img={contractImg}
            path="/suivez-vos-contrats"
          />
          <ItemDropdown
            name={t("app.horizontalNavLogIn.SuivezVosAlternants")}
            img={studentImg}
            path="/suivez-vos-alternants"
          />
          {/* <ItemDropdown
            name={t("app.horizontalNavLogIn.ConventionDeStage")}
            img={contractImg}
            path="/conventions-de-stage"
          /> */}
          <ItemDropdown
            name={t("app.horizontalNavLoginStudent.DecouvrirOmnesEducation")}
            img={inseecImg}
            blankURL={DISCOVER_OMNES_EDUCATION_URL}
          />
          <ItemDropdown name={t("app.horizontalNavLoginStudent.FAQ")} img={faqImg} path="/faq" />
          <ItemDropdown
            name={t("app.horizontalNavLoginStudent.ContactezNous")}
            img={contactImg}
            path="/contactez-nous"
          />
          <div className={styles.burger_item}>
            <SignOutBtn name={name} />
          </div>
          <div className={styles.burger_item}>
            <DropDownLanguage />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavENTContent;
