import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  pending: false,
  errorMessage: "",
};

export const getComputerSkillsThunk = createAsyncThunk("computerSkills/getComputerSkillsThunk", async (id) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${id}/skills/computer`);
  return response.data;
});

export const getComputerSkillsByIdThunk = createAsyncThunk(
  "computerSkills/getComputerSkillsByIdThunk",
  async ({ id, ID }) => {
    const response = await axiosStudent().get(`/v1/students/resumes/${id}/skills/computer/${ID}`);
    return response.data;
  },
);

export const postComputerSkillsThunk = createAsyncThunk(
  "computerSkills/postComputerSkillsThunk",
  async ({ dataToSend, id }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${id}/skills/computer`, dataToSend);
    return response.data;
  },
);

export const patchComputerSkillsThunk = createAsyncThunk(
  "computerSkills/patchComputerSkillsThunk",
  async ({ dataToSend, id, ID }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${id}/skills/computer/${ID}`, dataToSend);
    console.log("BACK", dataToSend);
    return response.data;
  },
);

const computerSkillsSlice = createSlice({
  name: "computerSkills",
  initialState,
  extraReducers: {
    [getComputerSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getComputerSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getComputerSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
    [postComputerSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [postComputerSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [postComputerSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchComputerSkillsThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchComputerSkillsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchComputerSkillsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getComputerSkillsByIdThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
      return state;
    },
    [getComputerSkillsByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
      return state;
    },
    [getComputerSkillsByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
      return state;
    },
  },
});

export const getComputerSkills = (state) => state.computerSkills.data;
export const getComputerSkillsPending = (state) => state.computerSkills.pending;

export default computerSkillsSlice.reducer;
