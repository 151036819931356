import { t } from "i18next";

import { useState } from "react";

import { useMsal } from "@azure/msal-react";

import profilImg from "../assets/profil.png";
import arrowImg from "./assets/flagArrow.png";

import styles from "./SignOutBtn.module.scss";

const SignOutBtn = ({ displayProfil, name }) => {
  const [displaySignoutDropdown, setDisplaySignoutDropdown] = useState(false);

  const { instance, inProgress } = useMsal();

  const handleLogOutDropdown = () => {
    setDisplaySignoutDropdown(!displaySignoutDropdown);
  };

  const handleLogOut = () => {
    if (inProgress === "none") {
      instance.logoutRedirect();
    }
  };

  /* eslint-disable */
  return (
    <div className={styles.main_container}>
      {displayProfil && <img className={styles.profil_img} src={profilImg} alt="profil" />}
      <button type="button" className={styles.btnCnx} onClick={handleLogOutDropdown}>
        {name}
      </button>
      <div className={styles.arrow_container} onClick={handleLogOutDropdown}>
        <img src={arrowImg} alt="arrow" />
      </div>
      <div
        className={styles.signout_dropdown}
        style={{ display: `${displaySignoutDropdown ? "block" : "none"}` }}
        onClick={handleLogOut}
      >
        {t("app.horizontalNavLogIn.SeDeconnecter")}
      </div>
    </div>
  );
};

export default SignOutBtn;
