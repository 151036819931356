import { createSlice } from "@reduxjs/toolkit";

// Initial State declaration
const initialState = {
  displayed: false,
};

// CREATESLICE SECTION

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    handleDropdownDisplay: (state) => {
      state.displayed = !state.displayed;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getDropdownDisplayStatus = (state) => state.dropdown.displayed;

// ACTIONS CREATOR EXPORTS SECTION

export const { handleDropdownDisplay } = dropdownSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default dropdownSlice.reducer;
