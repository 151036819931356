import { Link, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { handleDropdownDisplay } from "../../../../app/slices/dropdown/dropdown.slice";

import styles from "./ItemDropdown.module.scss";

const ItemDropdown = ({ path, img, name, blankURL }) => {
  /**
   *  This component adapts depending on whether it receives the props path or blankURL.
   *  path for a Link.
   *  blankURL for a <a href={props.blankURL} target="_blank" rel="noopener noreferrer">
   *
   * This components need 3 props :
   * - name (string)
   * - img (imported image)
   * - path ("/your-path") or blankURL ("https://your-url.com")
   */

  const dispatch = useDispatch();
  const location = useLocation();

  const reloadPage = () => {
    if (location.pathname === path) {
      window.location.reload(false);
    }
  };

  /* eslint-disable */
  return (
    <div>
      {path ? (
        <Link onClick={reloadPage} to={path}>
          <div className={styles.nav_bloc} onClick={() => dispatch(handleDropdownDisplay())}>
            <div className={styles.content_container}>
              <img src={img} alt="" />
              <div className={styles.name}>{name}</div>
            </div>
          </div>
        </Link>
      ) : (
        <a href={blankURL} target="_blank" rel="noopener noreferrer">
          <div className={styles.nav_bloc} onClick={() => dispatch(handleDropdownDisplay())}>
            <div className={styles.content_container}>
              <img src={img} alt="" />
              <div className={styles.name}>{name}</div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default ItemDropdown;
