import { t } from "i18next";
import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import {
  getLearningBookletsIdData,
  getLearningBookletsErrorMessage,
  getLearningBookletsListData,
} from "app/slices/learningBooklets/learningBooklets.slice";

import Header from "components/Header/Header";
import BackButton from "../components/BackButton/BackButton";
import bgConsulOffreImg from "../../assets/bg-consul-offre.png";
import styles from "../SelectedBooklet.module.scss";

const StaticEndTermEvalSecondYear = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const listData = useSelector(getLearningBookletsListData);
  const idData = useSelector(getLearningBookletsIdData);

  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );
  console.log("BOOKLET DATA", idData, listData);

  /* eslint-disable */
  return (
    <>
      {
        // referentials  &&
        <>
          {" "}
          <Header
            h1={t("app.m2EvalFinal.Suivez")}
            textElement={
              <div className={styles.headerContent}>
                <p>
                  {t("app.m2EvalFinal.Intro1")} <br /> <br />
                  {t("app.m2EvalFinal.Intro2")}
                  <br />
                  {t("app.m2EvalFinal.Intro3")}
                  <br /> <br />
                  {t("app.m2EvalFinal.Intro4")}
                  <br />
                  {t("app.m2EvalFinal.Intro5")}
                  <br /> <br />
                  {t("app.m2EvalFinal.Intro6")} <br />
                  <ul>
                    <li>{t("app.m2EvalFinal.NonAcquis")}</li>
                    <li>{t("app.m2EvalFinal.EnCoursDacquisition")}</li>
                    <li>{t("app.m2EvalFinal.Acquis")}</li>
                    <li>{t("app.m2EvalFinal.Expert")}</li>
                  </ul>
                </p>
              </div>
            }
            bgImg={bgConsulOffreImg}
          />
          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <div className={styles.title_container}>
                  <h2>{t("app.m2EvalFinal.EvalFinaleM2")}</h2>
                </div>
                <form className={styles.the_form}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <h3>{t("app.m2EvalFinal.CompetencesPerso")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Relationnel")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.RelationnelDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.interpersonalSkills?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.EspritDequipe")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.EquipeDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.teamSpirit?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Adaptabilite")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.AdaptabiliteDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.adaptability?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Initiative")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.InitiativeDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.initiative?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="normsAndRegulations" className={styles.label_text}>
                        {t("app.m2EvalFinal.Respect")}
                      </label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.RespectDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.standardAndRegulationRespect?.label}</p>
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.m2EvalFinal.CompetencesPro")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Planification")}</label>
                      <p className={styles.p_text}> {t("app.m2EvalFinal.PlanificationDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.planificationOrganisation?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Controle")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.ControleDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.autoControl?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Reporting")}</label>
                      <p className={styles.p_text}> {t("app.m2EvalFinal.ReportingDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.reporting?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.QualiteComm")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.QualiteCommDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.communicationSkills?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Mobilise")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.MobiliseDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.mobilizationAbility?.label}</p>
                    </div>
                    <div className={styles.div_line} />
                    <h3>{t("app.m2EvalFinal.CompetenceTech")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Connaissances")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.ConnaissancesDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.knowledgeAcquisition?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}> {t("app.m2EvalFinal.OrgaPerso")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.OrgaPersoDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.personalOrganizationWork?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Outils")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.OutilsDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.toolsAndMethodsMastery?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.QualiteTravail")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.QualiteTravailDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.workQuality?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label className={styles.label_text}>{t("app.m2EvalFinal.Delais")}</label>
                      <p className={styles.p_text}>{t("app.m2EvalFinal.DelaisDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.deadlinesRespect?.label}</p>
                    </div>
                  </div>
                  <BackButton text={t("app.m2EvalFinal.BoutonRetour")} />
                </form>
              </div>
            </div>
          </section>
        </>
      }
    </>
  );
};
export default StaticEndTermEvalSecondYear;
