import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useDispatch } from "react-redux";
import { postProjectsThunk } from "../../../../app/slices/modifyYourCV/academicProjects.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

/* eslint-disable */
const PostModalAcademicProjects = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const dataToSend = {
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      title: values.title,
      description: values.description,
    };
    (async () => {
      try {
        await dispatch(
          postProjectsThunk({
            dataToSend,
            id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationAjoutProjetAcademicTitle")}
            secondMessage={t("app.toastMessages.ConfirmationAjoutProjetAcademicMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const ModifyModalAcademicProjectsSchema = Yup.object().shape({
    title: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    description: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      title: "",
      description: "",
    },
    validationSchema: ModifyModalAcademicProjectsSchema,
    onSubmit,
  });

  if (props.showPost)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.CreationProjetAcademic")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="title">
                    {t("app.visualisationCv.TitreProjet")}
                  </label>
                  <input
                    style={
                      errors.title &&
                      touched.title && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.title}
                    maxLength={80}
                    type="text"
                    name="title"
                    id="title"
                    onChange={(e) => setFieldValue("title", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.title} touched={touched.title} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  <DatePickerCustom
                    error={errors.endDate}
                    touched={touched.endDate}
                    value={values.endDate}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                  <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="description">
                    {t("app.visualisationCv.Description")}
                  </label>
                  <textarea
                    style={
                      errors.description &&
                      touched.description && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.description}
                    maxLength={500}
                    type="description"
                    name="description"
                    id="description"
                    onChange={(e) => setFieldValue("description", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.description} touched={touched.description} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezProjet")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showPost) return null;
};

export default PostModalAcademicProjects;
