import styles from "../../Register.module.scss";

const ButtonSubmit = ({ text, handleSubmit, pending, pendingPostCompany, pendingPostEmployee }) => (
  <button
    type="submit"
    disabled={(pending || pendingPostCompany || pendingPostEmployee) && true}
    onClick={() => {
      handleSubmit();
    }}
    className={styles.buttonSubmit}
  >
    {text}
  </button>
);

export default ButtonSubmit;
