import { t } from "i18next";

import { DISCOVER_OMNES_EDUCATION_URL } from "utils/globalConstantsAndURL";

import SignOutBtn from "components/Navbar/SignOutBtn/SignOutBtn";
import DropDownLanguage from "components/DropDownLanguage/DropDownLanguage";
import ItemDropdown from "../ItemDropdown/ItemDropdown";

import acceuilImg from "../assets/Acceuil.png";
import offreImg from "../assets/Offre.png";
import depotOffreImg from "../assets/Depot_Offre.png";
import cvImg from "../assets/CV.png";
import contractImg from "../assets/Contract.png";
import studentImg from "../assets/Student.svg";
import inseecImg from "../assets/INSEEC.png";
import faqImg from "../assets/FAQ.png";
import contactImg from "../assets/Contact.png";

import styles from "./Nav.module.scss";

const NavETUContent = ({ name }) => (
  <div className={styles.wrapper}>
    <div>
      <ItemDropdown name={t("app.horizontalNavLoginStudent.PageAccueil")} img={acceuilImg} path="/" />
      <ItemDropdown name={t("app.horizontalNavLoginStudent.DeposezUnCv")} img={depotOffreImg} path="/deposez-un-cv" />
      <ItemDropdown name={t("app.horizontalNavLoginStudent.ConsultezVosCV")} img={offreImg} path="/mes-cvs" />
      <ItemDropdown
        name={t("app.horizontalNavLoginStudent.ConsultezLesOffres")}
        img={cvImg}
        path="/consultez-les-offres"
      />
      {/* <ItemDropdown
        name={t("app.horizontalNavLoginStudent.ConsultezVosPostulations")}
        img={contractImg}
        path="/consultez-vos-postulations"
      /> */}
      {/* <ItemDropdown
        name={t("app.horizontalNavLoginStudent.SeancesDeCoaching")}
        img={contractImg}
        path="/seance-coaching"
      /> */}
      <ItemDropdown
        name={t("app.horizontalNavLoginStudent.DeclarezUnRecrutement")}
        img={contractImg}
        path="/declarezUnRecrutement"
      />
      {/* <ItemDropdown
        name={t("app.horizontalNavLoginStudent.ConventionDeStage")}
        img={contractImg}
        path="/conventionDeStage"
      /> */}
      <ItemDropdown
        name={t("app.horizontalNavLoginStudent.LivretsApprentissage")}
        img={studentImg}
        path="/livret-dapprentissage"
      />
      <ItemDropdown
        name={t("app.horizontalNavLoginStudent.DecouvrirOmnesEducation")}
        img={inseecImg}
        blankURL={DISCOVER_OMNES_EDUCATION_URL}
      />
      <ItemDropdown name={t("app.horizontalNavLoginStudent.FAQ")} img={faqImg} path="/faq" />
      <ItemDropdown name={t("app.horizontalNavLoginStudent.ContactezNous")} img={contactImg} path="/contactez-nous" />
      <div className={styles.burger_item}>
        <SignOutBtn name={name} />
      </div>
      <div className={styles.burger_item}>
        <DropDownLanguage />
      </div>
    </div>
  </div>
);

export default NavETUContent;
