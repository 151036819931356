import { t } from "i18next";
import { useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import {
  getCheckYourCVUserCVSCards,
  getCheckYourCVErrorMessage,
  getCheckYourCVPending,
  getUserCVSCardsListThunk,
} from "../../../app/slices/checkYourCV/checkYourCV.slice";

import DivLine from "../../../components/DivLine/DivLine";
import Header from "../../../components/Header/Header";
import Card from "./Card/Card";

import bgFollowOffreImg from "./assets/bg-follow-offre.png";

import styles from "./CheckYourCV.module.scss";

/* eslint-disable */
const CheckYourCV = () => {
  const dispatch = useDispatch();

  const userCVSList = useSelector(getCheckYourCVUserCVSCards);
  const pending = useSelector(getCheckYourCVPending);
  const errorMessage = useSelector(getCheckYourCVErrorMessage);
  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getUserCVSCardsListThunk()).unwrap();
          console.log("useEffect success !", userCVSList);
        } catch (error) {
          console.log("useEffect fail !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <>
      <Header
        h1={t("app.visualisationCv.ConsultezVosCV")}
        textElement={<p>{t("app.visualisationCv.Intro1")}</p>}
        bgImg={bgFollowOffreImg}
      />

      <section className={styles.cv_list_container}>
        <div className={styles.divline_h2_container}>
          <DivLine />
          <h2>{t("app.visualisationCv.ListeCV")}</h2>
        </div>
        <div className={styles.cards_container}>
          {pending && <div>Chargement en cours...</div>}
          {userCVSList.length <= 0 && !pending && !errorMessage && <div>Vous n'avez pas de CV</div>}
          {userCVSList.length > 0 &&
            !pending &&
            !errorMessage &&
            userCVSList.map((obj) => <Card {...obj} key={nanoid()} />)}
          {errorMessage && <div>{errorMessage}</div>}
        </div>
      </section>
    </>
  );
};

export default CheckYourCV;
