import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { getMeData, getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { getEmployeesThunk, getEmployeeDetails } from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import { DropdownList } from "react-widgets";
import { createBooleanReferential, createObjAucun } from "utils/localReferentials";
import { nanoid } from "@reduxjs/toolkit";

import { axiosCompany } from "app/axiosInstances/axiosInstances";

import { ReactComponent as Euro } from "./assets/Euro.svg";
import { ReactComponent as Pencil } from "./assets/search.svg";
import codeNafSvg from "./assets/codeNaf.svg";

import styles from "./Form10.module.scss";

/* eslint-disable */
const Form10 = (props) => {
  const dispatch = useDispatch();
  const objAucun = createObjAucun();

  const [apiResponse, setApiResponse] = useState([]);
  const userToken = useSelector(getUserToken);
  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);
  const myInfo = useSelector(getMeData);
  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const [employeeCompanyId, setEmployeeCompanyId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  console.log("GetMe", myInfo);
  const companyEmployees = useSelector(getEmployeeDetails);
  const BooleanReferential = createBooleanReferential();
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  const [clicked, seIsClicked] = useState(false);
  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);
  const [formStep, setFormStep] = useState("");
  const [opcoDisable, setOpcoDisable] = useState(false);

  const timedOutRef = useRef(null);

  const [enableLastnameIfEmpty, setEnableLastnameIfEmpty] = useState(false);
  const [enableFirstnameIfEmpty, setEnableFirstnameIfEmpty] = useState(false);
  const [enablePhoneIfEmpty, setEnablePhoneIfEmpty] = useState(false);

  const handleResetStatesIfEmptyValue = () => {
    setEnableLastnameIfEmpty(false);
    setEnableFirstnameIfEmpty(false);
    setEnablePhoneIfEmpty(false);
  };

  console.log("MegaProps", recruitmentPropsGeneral);

  const FORM = "FORM_10";

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
    console.log("error MLM", errors);
  };

  const handleMountPingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
    setFormikErrorJustMount(false);
  };

  // Yup validation schema
  const Form1OSchema = Yup.object().shape({
    membershipNumber: Yup.string().max(255, t("app.errorMessages.Max255")),
    coveredAmount: Yup.string().required(t("app.errorMessages.RemplirChamp")).max(255, t("app.errorMessages.Max255")),
    teletransmission: Yup.bool().oneOf([true], t("app.errorMessages.RemplirChamp")),
    email: Yup.string().when("hasContact", {
      is: (obj) => obj?.key === "Oui",
      then: (schema) =>
        schema.email(`${t("app.errorMessages.EmailInvalid")}`).required(t("app.errorMessages.RemplirChamp")),
    }),
    name: Yup.string().when("hasContact", {
      is: (obj) =>
        (obj?.key === "Oui" && companyEmployees?.length === 1 && employeeCompanyId === myInfo?.companyId) ||
        (obj?.key === "Oui" && companyEmployees?.length !== 1),
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    firstname: Yup.string().when("hasContact", {
      is: (obj) =>
        (obj?.key === "Oui" && companyEmployees?.length === 1 && employeeCompanyId === myInfo?.companyId) ||
        (obj?.key === "Oui" && companyEmployees?.length !== 1),
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    phones: Yup.string().when("hasContact", {
      is: (obj) =>
        (obj?.key === "Oui" && companyEmployees?.length === 1 && employeeCompanyId === myInfo?.companyId) ||
        (obj?.key === "Oui" && companyEmployees?.length !== 1),
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    hasContact: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    agreementToPayRemainingCost: Yup.bool().oneOf([true], t("app.errorMessages.RemplirChamp")),
    // certifiedData: false,
    IdOpco: Yup.number().required(t("app.errorMessages.RemplirChamp")).nullable(),
  });

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setFieldValue("NameOpco", value);
    if (value.length >= 3) {
      const response = await axiosCompany().get(`/v1/companies/skill-operators?name=*${value.toUpperCase()}*`);
      setApiResponse(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse([]);
    }
  };

  const handleOptionSelect = (option) => {
    setFieldValue("IdOpco", option.id);

    setFieldValue("NameOpco", option.name);
    console.log("CFA", values.IdOpco);

    setApiResponse([]);
  };

  const onSubmit = (values, actions) => {
    console.log("CONTRACT VALUE TO SEND 10", values);

    if (!formikErrorJustMount) {
      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);
      const dataToSend = employeeCompanyId
        ? {
            status: "En cours",
            company: {
              contacts: {
                skillsOperator: {
                  id: employeeId,
                },
              },
            },

            contract: {
              financing: {
                trainingAmount: valuesCopy.trainingAmount,
                skillsOperator: {
                  id: parseInt(valuesCopy.IdOpco),
                  membershipNumber: valuesCopy.membershipNumber,
                  hasContact: valuesCopy?.hasContact?.key,

                  coveredAmount: valuesCopy.coveredAmount,
                },
                agreementToPayRemainingCost: valuesCopy.agreementToPayRemainingCost,
                certifiedData: valuesCopy.certifiedData,
              },
            },
          }
        : {
            status: "En cours",
            company: {
              contacts: {
                skillsOperator: {
                  email: valuesCopy.email,
                  name: valuesCopy.name,
                  firstname: valuesCopy.firstname,
                  phones: {
                    cellular: valuesCopy.phones,
                    office: valuesCopy.phones,
                  },
                },
              },
            },

            contract: {
              financing: {
                trainingAmount: valuesCopy.trainingAmount,
                skillsOperator: {
                  id: parseInt(valuesCopy.IdOpco),
                  membershipNumber: valuesCopy.membershipNumber,
                  hasContact: valuesCopy?.hasContact?.key,

                  coveredAmount: valuesCopy.coveredAmount,
                },
                agreementToPayRemainingCost: valuesCopy.agreementToPayRemainingCost,
                certifiedData: valuesCopy.certifiedData,
              },
            },
          };
      props.submitSection(dataToSend, 10);
    } else handleMountPingStatus();
  };

  const handleHasContact = (value) => {
    switch (value) {
      case true:
        return {
          key: t("app.referentials.Oui"),
          label: t("app.referentials.Oui"),
        };
        break;
      case false:
        return {
          key: t("app.referentials.Non"),
          label: t("app.referentials.Non"),
        };
        break;
      default:
        return { ...objAucun };
        break;
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      membershipNumber: recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.membershipNumber
        ? recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.membershipNumber
        : "",
      trainingAmount: recruitmentPropsGeneral?.contract?.financing?.trainingAmount
        ? recruitmentPropsGeneral?.contract?.financing?.trainingAmount
        : "",
      coveredAmount: recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.coveredAmount
        ? recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.coveredAmount
        : "",
      email: recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.email
        ? recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.email
        : "",
      name: recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.name
        ? recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.name
        : "",
      firstname: recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.firstname
        : "",
      phones: recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.skillsOperator?.phones?.office
        : "",
      hasContact: handleHasContact(recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.hasContact),
      agreementToPayRemainingCost: recruitmentPropsGeneral?.contract?.financing?.agreementToPayRemainingCost
        ? recruitmentPropsGeneral?.contract?.financing?.agreementToPayRemainingCost
        : false,
      certifiedData: recruitmentPropsGeneral?.contract?.financing?.certifiedData
        ? recruitmentPropsGeneral?.contract?.financing?.certifiedData
        : false,
      IdOpco: recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.id
        ? recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.id
        : null,
      NameOpco: recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.name
        ? recruitmentPropsGeneral?.contract?.financing?.skillsOperator?.name
        : "",
    },
    validationSchema: Form1OSchema,
    onSubmit,
  });

  useEffect(() => {
    if (values) handleEnableOnMount(recruitmentPropsGeneral?.company?.contacts?.skillsOperator);
  }, []);

  useEffect(() => {
    if (formikErrorJustMount) onSubmit();
  }, [errors]);

  useEffect(() => {
    console.log("HEEERE", employeeCompanyId);
    if (companyEmployees && FORM === formStep) {
      if (companyEmployees.length > 0) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [companyEmployees]);

  const handleEnableOnMount = (skillsOperatorContact) => {
    if (values?.hasContact?.key === "Oui") {
      if (values.email.length >= 1) {
        if (myInfo?.companyId === skillsOperatorContact?.companyId) setOpcoDisable(false);
        else setOpcoDisable(true);
      } else setOpcoDisable(true);
    } else setOpcoDisable(true);
  };

  const displayDefaultData = () => {
    if (FORM === formStep) {
      setFieldValue("name", "");
      setFieldValue("firstName", "");
      setFieldValue("phones", "");
      setFormStep("");
      setOpcoDisable(false);
    }
  };
  const displayUserData = () => {
    if (FORM === formStep) {
      setFieldValue("name", companyEmployees[0]?.name);
      setFieldValue("firstname", companyEmployees[0]?.firstname);
      setFieldValue("phones", companyEmployees[0]?.phones?.office);
      setEmployeeCompanyId(companyEmployees[0]?.company.id);
      setEmployeeId(companyEmployees[0]?.id);
      console.log("NAAAAME", companyEmployees[0].company.id);

      if (companyEmployees[0]?.company?.id === myInfo?.companyId) setOpcoDisable(false);
      else setOpcoDisable(true);

      setFormStep("");
    }
  };

  useEffect(() => {
    if (values.email.length >= 1) setOpcoDisable(false);
    else setOpcoDisable(true);
    if (!errors.email && values.email.length > 1) {
      if (timedOutRef.current) clearTimeout(timedOutRef.current);
      timedOutRef.current = setTimeout(() => {
        (async () => {
          try {
            await dispatch(
              getEmployeesThunk({
                email: values.email,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            handleResetStatesIfEmptyValue();
            console.log("get success Employees !");
          } catch (error) {
            handleResetStatesIfEmptyValue();
            console.log("get fail Employees !", error.message);
          }
        })();
      }, "500");
    } else {
      setFieldValue("name", "");
      setFieldValue("firstname", "");
      setFieldValue("phones", "");
      return;
    }
  }, [errors.email, values.email]);

  useEffect(() => {
    console.log("Clicked", clicked);
    if (formikErrorJustMount) {
      handleSubmit();
    }
    console.log("Formik errors", errors);
    console.log("isOpen", props.formOpen);
  }, [errors, clicked]);

  useEffect(() => {
    if (values?.hasContact?.key !== "-Aucun-" && values?.hasContact?.key !== "Non" && values.email !== "") {
      if (values?.lastName === "") {
        setEnableLastnameIfEmpty(true);
      }
      if (values?.firstName === "") {
        setEnableFirstnameIfEmpty(true);
      }
      if (!values?.phones) {
        setEnablePhoneIfEmpty(true);
      }
    } else {
      handleResetStatesIfEmptyValue();
    }
  }, [values]);

  if (props.formOpen) {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className={`${styles.wrapper} input-like-rw`}>
            <div className={styles.formGroup}>
              <label htmlFor="trainingAmount">{t("app.Step10ContractEnt.FromatAmount")}</label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span>{values.trainingAmount}</span>
                <Euro height={13} width={15} />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="membershipNumber">{t("app.Step10ContractEnt.NumAdh")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.membershipNumber &&
                      touched.membershipNumber && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="membershipNumber"
                maxLength={255}
                type="text"
                disabled={false}
                value={values.membershipNumber}
                onChange={(e) => setFieldValue("membershipNumber", e.target.value.trim())}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.membershipNumber} touched={touched.membershipNumber} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="OPCO">{t("app.Step10ContractEnt.NomOpco")}</label>
              <div className={`${styles.searchOpco} input-like-rw`}>
                <div className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}>
                  <input
                    style={
                      !formikErrorJustMount && clicked
                        ? errors.membershipNumber &&
                          touched.membershipNumber && {
                            border: "2px solid #EA001E",
                            textTransform: "uppercase",
                          }
                        : { textTransform: "uppercase" }
                    }
                    type="text"
                    value={values.NameOpco}
                    onChange={handleInputChange}
                  />
                  <Pencil className={styles.search_icon} />
                </div>
                <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
                  <ul>
                    {apiResponse.map((result) => (
                      <div key={nanoid()}>
                        {/* <Pencil className={styles.search_icon} /> */}

                        <div className={styles.output_plus_image_Container}>
                          <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                          <div className={styles.outputKeyLabel} onClick={() => handleOptionSelect(result)}>
                            <li key={result?.key}> {result?.number}</li>
                            <li key={result?.key}>{result?.name}</li>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.IdOpco} touched={touched.IdOpco} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="coveredAmount">{t("app.Step10ContractEnt.lvlCharge")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.coveredAmount &&
                      touched.coveredAmount && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="coveredAmount"
                maxLength={255}
                type="text"
                disabled={false}
                value={values.coveredAmount}
                onChange={(e) => setFieldValue("coveredAmount", e.target.value.trim())}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.coveredAmount} touched={touched.coveredAmount} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="hasContact">{t("app.Step10ContractEnt.ComOpco")}</label>
              <DropdownList
                id="hasContact"
                dataKey="key"
                textField="label"
                style={
                  !formikErrorJustMount && clicked
                    ? errors.hasContact &&
                      touched.hasContact && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                data={[...BooleanReferential]}
                value={values.hasContact}
                onChange={(newValue) => {
                  setOpcoDisable(true);
                  setFieldValue("hasContact", newValue);
                  setFieldValue("name", "");
                  setFieldValue("firstname", "");
                  setFieldValue("phones", "");
                  setFieldValue("email", "");
                }}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.hasContact} touched={touched.hasContact} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">{t("app.Step10ContractEnt.ContactMail")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.email &&
                      touched.email && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="email"
                maxLength={255}
                // onBlur={(e) => handleEmployeesCalls(e.target.value)}
                type="text"
                disabled={values?.hasContact?.key === t("app.referentials.Oui") ? false : true}
                value={values.email}
                onChange={(e) => {
                  setFormStep(FORM);
                  setFieldValue("email", e.target.value);
                  // handleMailData(e.target.value);
                  setFieldValue("name", "");
                  setFieldValue("firstname", "");
                  setFieldValue("phones", "");
                  setEmployeeCompanyId("");
                  setEmployeeId("");
                }}
              />
              {!formikErrorJustMount && clicked && <InputErrorMessage message={errors.email} touched={touched.email} />}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="name">{t("app.Step10ContractEnt.NameContact")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.name &&
                      touched.name && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="name"
                maxLength={255}
                type="text"
                disabled={opcoDisable && !enableLastnameIfEmpty && true}
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value.trim())}
              />
              {!formikErrorJustMount && clicked && <InputErrorMessage message={errors.name} touched={touched.name} />}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="firstname">{t("app.Step10ContractEnt.FirstName")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.firstname &&
                      touched.firstname && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstname"
                maxLength={255}
                type="text"
                disabled={opcoDisable && !enableFirstnameIfEmpty && true}
                value={values.firstname}
                onChange={(e) => setFieldValue("firstname", e.target.value.trim())}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.firstname} touched={touched.firstname} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phones">{t("app.Step10ContractEnt.ContactPhone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount && clicked
                    ? errors.phones && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="phones"
                id="phones"
                country={"FR"}
                disabled={opcoDisable && !enablePhoneIfEmpty && true}
                value={values.phones}
                onChange={(e) => {
                  setFieldValue("phones", e);
                }}
              />
              {/* <input
								style={
									!formikErrorJustMount
										? errors.phones &&
										  touched.phones && {
												border: "2px solid #EA001E",
										  }
										: null
								}
								id="phones"
								maxLength={255}
								type="text"
								disabled={DisabledContact()}
								value={values.phones}
								onChange={(e) => setFieldValue("phones", e.target.value.trim())}
							/> */}
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.phones} touched={touched.phones} />
              )}
            </div>
          </div>
          <div className={styles.fullContainer}>
            <div className={styles.formGroup}>
              <input
                type="checkbox"
                name="agreementToPayRemainingCost"
                id="agreementToPayRemainingCost"
                value={values.agreementToPayRemainingCost}
                checked={values.agreementToPayRemainingCost}
                // {...isCheckedDsTrueDangerousMachineryUse}
                style={
                  !formikErrorJustMount && clicked
                    ? touched.agreementToPayRemainingCost && values.agreementToPayRemainingCost === false
                      ? {
                          outline: "2px solid #EA001E",
                          borderRadius: "50%",
                          outlineOffset: "-1.9px",
                        }
                      : null
                    : null
                }
                onChange={() => {
                  setFieldValue("agreementToPayRemainingCost", !values.agreementToPayRemainingCost);
                }}
              />
              <label htmlFor="agreementToPayRemainingCost" className={styles.labelCheckBox}>
                {t("app.suivezVosContratsPartner.checkbox1Opco")}
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="checkbox"
                name="certifiedData"
                id="certifiedData"
                value={values.certifiedData}
                checked={values.certifiedData}
                // {...isCheckedDsTruePublicSector}
                style={
                  !formikErrorJustMount && clicked
                    ? touched.certifiedData && values.certifiedData === false
                      ? {
                          outline: "2px solid #EA001E",
                          borderRadius: "50%",
                          outlineOffset: "-1.9px",
                        }
                      : null
                    : null
                }
                onChange={() => {
                  setFieldValue("certifiedData", !values.certifiedData);
                }}
              />
              <label htmlFor="certifiedData" className={styles.labelCheckBox}>
                {t("app.suivezVosContratsPartner.checkbox2Opco")}
              </label>
            </div>
          </div>
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn
              onClick={() => {
                handlePingStatus();
                seIsClicked(true);
              }}
              //disabled={generalEnable}
              text={t("app.visualisationOffre.ValidezLesModifications")}
            />
          </div>
        </form>
      </>
    );
  }
};

export default Form10;
