import { t } from "i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationFormsPage from "components/ConfirmationFormsPage/ConfirmationFormsPage";
import { resetFileData } from "app/slices/uploadFile/uploadFile.slice";
import { DISCOVER_OMNES_EDUCATION_URL, DISCOVER_OUR_SCHOOLS_URL } from "utils/globalConstantsAndURL";
import Experiences from "./forms/Experiences/Experiences";
import YourProfile from "./forms/YourProfile/YourProfile";
import YourSearch from "./forms/YourSearch/YourSearch";
import { getActualStep, handleResetToStep1 } from "../../../app/slices/createYourCV/createYourCV.slice";
import YourSkills from "./forms/YourSkills/YourSkills";
import Contact from "./forms/Contact/Contact";

import bgImg from "./assets/bg-consul-offre.png";
import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

const CreateCv = () => {
  const actualStep = useSelector(getActualStep);
  const dispatch = useDispatch();

  const [axiosCancelToken] = useState(axios.CancelToken.source());

  useEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(handleResetToStep1());
      dispatch(resetFileData());
      console.log("CreateYourCV unmount");
    },
    [],
  );
  return (
    <>
      {actualStep === 1 && <YourSearch axiosCancelToken={axiosCancelToken} />}
      {actualStep === 2 && <YourProfile axiosCancelToken={axiosCancelToken} />}
      {actualStep === 3 && <Experiences axiosCancelToken={axiosCancelToken} />}
      {actualStep === 4 && <YourSkills axiosCancelToken={axiosCancelToken} />}
      {actualStep === 5 && <Contact axiosCancelToken={axiosCancelToken} />}
      {actualStep === 6 && (
        <ConfirmationFormsPage
          h1={t("app.depotCv.Confirmation")}
          textElement={<p>{t("app.depotCv.Success")}</p>}
          bgImg={bgImg}
          leftBtn={{
            text: t("app.depotCv.SuivezCV"),
            path: "/mes-cvs",
          }}
          rightBtn={{
            text: t("app.depotCv.ConsultezOffre"),
            path: "/consultez-les-offres",
          }}
          h2={t("app.depotCv.MieuxNousConnaitre")}
          cardsArray={[
            {
              path: DISCOVER_OMNES_EDUCATION_URL,
              img: cardKnow3Img,
              h3: t("app.depotCv.OMNES"),
              text: t("app.depotCv.NousConnaitre1"),
            },
            {
              path: DISCOVER_OUR_SCHOOLS_URL,
              img: cardKnow2Img,
              h3: t("app.depotCv.NosEcoles"),
              text: t("app.depotCv.NousConnaitre1"),
            },
          ]}
        />
      )}
    </>
  );
};

export default CreateCv;
