import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent, axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  actualStep: "Etape1",
  studentInformations: null,
  preContractualizationInformations: null,
  pending: false,
  pending2: false,
  pending3: false,
  errorMessage: "",
  newCompany: null,
  newEmployee: null,
};

// THUNKS SECTION

export const getStudentInformationsThunk = createAsyncThunk(
  "declareARecruitment/getStudentInformationsThunk",
  async ({ id, axiosCancelToken }) => {
    const response = await axiosStudent().get(`/v1/students/${id}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const getPreContractualizationInformationsThunk = createAsyncThunk(
  "declareARecruitment/getPreContractualizationInformationsThunk",
  async ({ apprenticeshipPreContractualizationId, axiosCancelToken }) => {
    const response = await axiosCompany().get(
      `/v2/companies/apprenticeships/pre-contractualizations/${apprenticeshipPreContractualizationId}`,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

export const createNewEmployee = createAsyncThunk(
  "createEmployee/createEmployeeThunk",
  async ({ dataToSend, axiosCancelToken, companyId }) => {
    const response = await axiosCompany().post(`/v1/companies/${companyId}/employees`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const createNewCompany = createAsyncThunk(
  "creatCompany/postCompanyThunk",
  async ({ dataToSend, axiosCancelToken }) => {
    const response = await axiosCompany().post("/v1/companies", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postStep1Thunk = createAsyncThunk(
  "declareARecruitment/postStep1Thunk",
  async ({ dataToSend, axiosCancelToken }) => {
    const response = await axiosCompany().post("/v2/companies/apprenticeships/pre-contractualizations", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const patchStepThunk = createAsyncThunk(
  "declareARecruitment/patchStepThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const declareARecruitmentSlice = createSlice({
  name: "declareARecruitment",
  initialState,
  reducers: {
    handlePreviousStep: (state, action) => {
      state.actualStep = action.payload;
    },
    resetSliceInformations: (state) => {
      state.actualStep = "Etape1";
      state.studentInformations = null;
      state.preContractualizationInformations = null;
      state.pending = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [createNewEmployee.pending]: (state) => {
      state.errorMessage = "";
      state.pending3 = true;
    },
    [createNewEmployee.fulfilled]: (state, action) => {
      console.log("new Employee creation fulfilled !", action.payload);
      state.newEmployee = action.payload;
      state.pending3 = false;
      state.pending = false;
    },
    [createNewEmployee.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending3 = false;
      state.errorMessage = action.error.message;
    },
    [createNewCompany.pending]: (state) => {
      state.errorMessage = "";
      state.pending2 = true;
    },
    [createNewCompany.fulfilled]: (state, action) => {
      console.log("new company got created fulfilled !", action.payload);
      state.newCompany = action.payload;
      state.pending2 = false;
      state.pending = false;
    },
    [createNewCompany.rejected]: (state, action) => {
      console.log("rejected !", action.error.message);
      state.pending2 = false;
      state.errorMessage = action.error.message;
    },
    [getStudentInformationsThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getStudentInformationsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.studentInformations = action.payload;
    },
    [getStudentInformationsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getPreContractualizationInformationsThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getPreContractualizationInformationsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.preContractualizationInformations = action.payload;
      state.actualStep = action.payload.stage;
    },
    [getPreContractualizationInformationsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [postStep1Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [postStep1Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.preContractualizationInformations = action.payload;
      state.actualStep = action.payload.stage;
    },
    [postStep1Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchStepThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchStepThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.preContractualizationInformations = action.payload;
      state.actualStep = action.payload.stage;
    },
    [patchStepThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getDeclareARecruitmentStudentInformations = (state) => state.declareARecruitment.studentInformations;
export const getDeclareARecruitmentPreContractualizationInformations = (state) => (
  state.declareARecruitment.preContractualizationInformations
);
export const getDeclareARecruitmentActualStep = (state) => state.declareARecruitment.actualStep;
export const getDeclareARecruitmentPending = (state) => state.declareARecruitment.pending;
export const getDeclareARecruitmentErrorMessage = (state) => state.declareARecruitment.errorMessage;
export const newCompanyData = (state) => state.declareARecruitment.newCompany;
export const newEmployeeData = (state) => state.declareARecruitment.newEmployee;
export const createNewCompanyPending = (state) => state.declareARecruitment.pending2;
export const createNewEmployeePending = (state) => state.declareARecruitment.pending3;
// ACTIONS CREATOR EXPORTS SECTION

export const { handlePreviousStep, resetSliceInformations } = declareARecruitmentSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default declareARecruitmentSlice.reducer;
