import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION
export const PostComputerSkillThunk = createAsyncThunk(
  "createComputerSkill/PostComputerSkillThunk",
  async ({ dataToSend, cvId }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/skills/computer`, dataToSend);
    return response.data;
  },
);

export const PutComputerSkillThunk = createAsyncThunk(
  "createComputerSkill/PostComputerSkillThunk",
  async ({ dataToSend, cvId, id }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${cvId}/skills/computer/${id}`, dataToSend);
    return response.data;
  },
);

// CREATESLICE SECTION
const createYourComputerSkillSlice = createSlice({
  name: "createYourComputerSkill",
  initialState,
  reducers: {},
  extraReducers: {
    [PostComputerSkillThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostComputerSkillThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostComputerSkillThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [PutComputerSkillThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PutComputerSkillThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PutComputerSkillThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourComputerSkillPending = (state) => state.createYourComputerSkill.pending;
export const getcreateYourComputerSkillErrorMessage = (state) => state.createYourComputerSkill.errorMessage;
export const getcreateYourComputerSkillId = (state) => state.createYourComputerSkill.id;
export const getcreateYourComputerSkillData = (state) => state.createYourComputerSkill.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourComputerSkillSlice.reducer;
