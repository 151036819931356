import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosCompany, axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  fileData: null,
  deleteFilePending: false,
  errorMessage: "",
};

// THUNKS SECTION

export const postFileThunk = createAsyncThunk(
  "uploadFile/postFileThunk",
  async ({ file, endpoint, axiosCancelToken }) => {
    console.log("file in thunk", file);

    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await axiosCompany("multipart/form-data").post(endpoint, formData, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postFileStudentThunk = createAsyncThunk(
  "uploadFile/postFileStudentThunk",
  async ({ file, endpoint, axiosCancelToken }) => {
    console.log("file in thunk", file);

    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await axiosStudent("multipart/form-data").post(endpoint, formData, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const deleteOfferFileThunk = createAsyncThunk(
  "uploadFile/deleteOfferFileThunk",
  async ({ id, axiosCancelToken }, { getState }) => {
    const downloadUrlArray = getState().uploadFile.fileData.downloadUrl.split("/");
    const fileId = downloadUrlArray[downloadUrlArray.length - 1];
    console.log("fileId", fileId);

    const response = await axiosCompany().delete(`/v1/companies/job-offers/${id}/supporting-documents/${fileId}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const deleteCVFileThunk = createAsyncThunk(
  "uploadFile/deleteCVFileThunk",
  async ({ id, axiosCancelToken }, { getState }) => {
    const downloadUrlArray = getState().uploadFile.fileData.downloadUrl.split("/");
    const fileId = downloadUrlArray[downloadUrlArray.length - 1];
    console.log("fileId", fileId);

    const response = await axiosStudent().delete(`/v1/students/resumes/${id}/supporting-documents/${fileId}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const downloadFileStudentThunk = createAsyncThunk(
  "uploadFile/downloadFileStudentThunk",
  async ({ id, axiosCancelToken }, { getState }) => {
    const downloadUrlArray = getState().uploadFile.fileData.downloadUrl.split("/");
    const fileId = downloadUrlArray[downloadUrlArray.length - 1];
    console.log("fileId", fileId);

    const response = await axiosStudent().get(`v1/students/resumes/${id}/supporting-documents/${fileId}`, {
      cancelToken: axiosCancelToken.token,
      responseType: "blob",
    });
    return response.data;
  },
);

export const downloadFileCompanyThunk = createAsyncThunk(
  "uploadFile/downloadFileCompanyThunk",
  async ({ id, axiosCancelToken }, { getState }) => {
    const downloadUrlArray = getState().uploadFile.fileData.downloadUrl.split("/");
    const fileId = downloadUrlArray[downloadUrlArray.length - 1];
    console.log("fileId", fileId);

    const response = await axiosCompany().get(`v1/companies/job-offers/${id}/supporting-documents/${fileId}`, {
      cancelToken: axiosCancelToken.token,
      responseType: "blob",
    });
    return response.data;
  },
);

// CREATESLICE SECTION

const uploadFile = createSlice({
  name: "uploadFile",
  initialState,
  reducers: {
    setFileData: (state, action) => {
      console.log("setFileData", action.payload);
      state.fileData = action.payload;
    },
    resetFileData: (state) => {
      console.log("resetFileData");
      state.pending = false;
      state.fileData = null;
      state.deleteFilePending = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [postFileThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [postFileThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [postFileThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [deleteOfferFileThunk.pending]: (state) => {
      state.deleteFilePending = true;
      state.errorMessage = "";
    },
    [deleteOfferFileThunk.fulfilled]: (state, action) => {
      console.log("fulfilled deleteOfferFileThunk !", action.payload);
      state.fileData = null;
      state.deleteFilePending = false;
    },
    [deleteOfferFileThunk.rejected]: (state, action) => {
      console.log("rejected deleteOfferFileThunk !", action.error);
      state.errorMessage = action.error.message;
      state.deleteFilePending = false;
    },
    [deleteCVFileThunk.pending]: (state) => {
      state.deleteFilePending = true;
      state.errorMessage = "";
    },
    [deleteCVFileThunk.fulfilled]: (state, action) => {
      console.log("fulfilled deleteCVFileThunk !", action.payload);
      state.fileData = null;
      state.deleteFilePending = false;
    },
    [deleteCVFileThunk.rejected]: (state, action) => {
      console.log("rejected deleteCVFileThunk !", action.error);
      state.errorMessage = action.error.message;
      state.deleteFilePending = false;
    },
    [postFileStudentThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [postFileStudentThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [postFileStudentThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [downloadFileStudentThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [downloadFileStudentThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [downloadFileStudentThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [downloadFileCompanyThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [downloadFileCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [downloadFileCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getUploadFilePending = (state) => state.uploadFile.pending;
export const getUploadFIleErrorMessage = (state) => state.uploadFile.errorMessage;
export const selectFileData = (state) => state.uploadFile.fileData;
export const selectDeleteFilePending = (state) => state.uploadFile.deleteFilePending;

// ACTIONS CREATOR EXPORTS SECTION

export const { setFileData, resetFileData } = uploadFile.actions;

// REDUCER DEFAULT EXPORT SECTION

export default uploadFile.reducer;
