import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { DropdownList } from "react-widgets/cjs";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import { useSelector, useDispatch } from "react-redux";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { getComputerSkills, patchComputerSkillsThunk } from "../../../../app/slices/modifyYourCV/computerSkills.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

/* eslint-disable */
const ModifyModalComputerSkills = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const referentialData = useSelector(getReferentialsData);
  const computerSkillsById = useSelector(getComputerSkills);

  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const handleProps = (value) => {
    if (value !== undefined) {
      setFieldValue("domain", value.domain);
      setFieldValue("software", value.software);
    }
  };

  useEffect(() => {
    handleProps(props.object);
  }, [props.object]);

  const onSubmit = (values) => {
    const dataToSend = {
      domain: values.domain.key,
      software: values.software,
    };
    (async () => {
      try {
        await dispatch(
          patchComputerSkillsThunk({
            dataToSend,
            id: id,
            ID: props.id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationModifInformatiqueTitle")}
            secondMessage={t("app.toastMessages.ConfirmationModifInformatiqueMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const yourSearchSchema = Yup.object().shape({
    domain: Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-"),
    software: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      domain: props.object?.domain ? { key: props.object?.domain?.key } : { key: "-Aucun-" },
      software: props.object?.software ? props.object?.software : "",
    },
    validationSchema: yourSearchSchema,
    onSubmit,
  });

  if (props.showModify && computerSkillsById)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModificationCompetencesInfo")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="domain">
                    {t("app.visualisationCv.CompetencesInformatiquesConcernees")}
                  </label>
                  <DropdownList
                    style={
                      errors.domain &&
                      touched.domain && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="domain"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_COMPUTER_SKILL]}
                    value={values.domain}
                    onChange={(newValue) => {
                      setFieldValue("domain", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.domain} touched={touched.domain} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="software">
                    {t("app.visualisationCv.Logiciel")}
                  </label>
                  <input
                    style={
                      errors.software &&
                      touched.software && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.software}
                    maxLength={80}
                    type="text"
                    name="software"
                    id="software"
                    onChange={(e) => setFieldValue("software", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.software} touched={touched.software} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezModification")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showModify) return null;
};

export default ModifyModalComputerSkills;
