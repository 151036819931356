import { useIsAuthenticated } from "@azure/msal-react";

import { STUDENT_SUBDOMAIN_NAME, COMPANY_SUBDOMAIN_NAME } from "utils/globalConstantsAndURL";

import { useSelector } from "react-redux";
import { getDropdownDisplayStatus } from "../../../app/slices/dropdown/dropdown.slice";
import { getSubdomain } from "../../../app/slices/subdomain/subdomain.slice";

import NavETUContent from "./data/NavETUContent";
import NavENTContent from "./data/NavENTContent";

import styles from "./Dropdown.module.scss";

const Dropdown = ({ name }) => {
  /**
   *  This component adapts according to the sub-domain.
   */

  // The subdomain is used to display relevant content.
  const subdomain = useSelector(getSubdomain);

  const isAuthenticated = useIsAuthenticated();

  // The state of dropdown.slice.js is used to determine if the dropdown is displayed or not.
  const displayValue = useSelector(getDropdownDisplayStatus) ? "flex" : "none";

  return (
    <nav className={styles.dropdown_container} style={{ display: displayValue }}>
      <div className={styles.dropdown_blocs_container}>
        {subdomain === STUDENT_SUBDOMAIN_NAME && isAuthenticated && <NavETUContent name={name} />}
        {subdomain === COMPANY_SUBDOMAIN_NAME && <NavENTContent name={name} />}
      </div>
    </nav>
  );
};

export default Dropdown;
