import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm4Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm4/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm4Slice = createSlice({
  name: "checkYourRecruitmentsForm4",
  initialState,
  reducers: {
    handleRecruitmentPropsForm4Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm4Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm4Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm4Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm4Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm4Data, handleRecruitmentSectionsForm4Data } =
  checkYourRecruitmentsForm4Slice.actions;

export const getYourRecruitmentPropsForm4Data = (state) => state.checkYourRecruitmentsForm4.recruitmentPropsForm4Data;
export const getAllUserRecruitmentsForm4 = (state) => state.checkYourRecruitmentsForm4.recruitmentList;
export const getUserRecruitmentsPendingForm4 = (state) => state.checkYourRecruitmentsForm4.pending;
export const getUserRecruitmentsErrorMessageForm4 = (state) => state.checkYourRecruitmentsForm4.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm4Slice.reducer;
