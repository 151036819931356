import { Link } from "react-router-dom";

import styles from "./Card.module.scss";
import { ReactComponent as Eye } from "../assets/eye.svg";

/* eslint-disable */
const LanguageCard = (props) => {
  const ModifyForm = props.modifyForm;
  return (
    <div className={styles.card_container}>
      <div className={styles.cv_id}>{props.lang.key !== "Autre" ? props.lang.label : props.other}</div>
      <div className={styles.name}>{props.level.label}</div>
      <div className={styles.name}>{props.languageCertification}</div>
      <div className={styles.edit_link}>
        <div
          onClick={() => ModifyForm(props)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Eye width="1rem" style={{ marginRight: "2%" }} />
          <Link>Modifier</Link>
        </div>
      </div>
    </div>
  );
};

export default LanguageCard;
