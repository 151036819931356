import styles from "./Loader.module.scss";

const Loader = () => (
  <div className={styles.main_container}>
    <div className={styles.lds_spinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
