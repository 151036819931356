import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION

export const PostProExperienceThunk = createAsyncThunk(
  "createProExperience/PostProExperienceThunk",
  async ({ dataToSend, cvId }) => {
    const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/experiences/professional`, dataToSend);
    return response.data;
  },
);

export const PutProExperienceThunk = createAsyncThunk(
  "createYourExperience/patchExperienceThunk",
  async ({ dataToSend, id, cvId }) => {
    const response = await axiosStudent().put(
      `/v1/students/resumes/${cvId}/experiences/professional/${id}`,
      dataToSend,
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const createYourProExperienceSlice = createSlice({
  name: "createYourProExperience",
  initialState,
  reducers: {},
  extraReducers: {
    [PostProExperienceThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostProExperienceThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostProExperienceThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [PutProExperienceThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PutProExperienceThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PutProExperienceThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourProExperiencePending = (state) => state.createYourProExperience.pending;
export const getcreateYourProExperienceErrorMessage = (state) => state.createYourProExperience.errorMessage;
export const getcreateYourProExperienceId = (state) => state.createYourProExperience.id;
export const getcreateYourProExperienceData = (state) => state.createYourProExperience.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourProExperienceSlice.reducer;
