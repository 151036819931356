import styles from "../StepThree.module.scss";

const ButtonSubmit = ({ text, handleSubmit }) => (
  <button
    type="submit"
    onClick={() => {
      handleSubmit();
    }}
    className={styles.buttonSubmit}
  >
    {text}
  </button>
);

export default ButtonSubmit;
