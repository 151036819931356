import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Initial State declaration
const initialState = {
  company: null,
  pending: false,
  pendingPost: false,
  pendingPostEmployee: false,
  errorMessage: null,
  id: null,
  data: null,
  newCompanyId: null,
  newEmployeeId: null,
};

// THUNKS SECTION

export const getSiretThunkWithoutToken = createAsyncThunk(
  "allCompaniesWithoutToken/getSiretThunkWithoutToken",
  async ({ siret, axiosCancelToken, isSiret }, { getState }) => {
    const language = getState().languages.data;
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?siret=${siret}`);
    const response = await axios.get(
      `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?siret=${siret}&searchInSiretBase=${isSiret}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${language}`,
        },
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

export const getDunsThunkWithoutToken = createAsyncThunk(
  "allCompaniesWithoutToken/getDunsThunkWithoutToken",
  async ({ duns, axiosCancelToken }, { getState }) => {
    const language = getState().languages.data;
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?duns=${duns}`);
    const response = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies?duns=${duns}`, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${language}`,
      },
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postCompanyThunkWithoutToken = createAsyncThunk(
  "allCompaniesWithoutToken/postCompanyThunkWithoutToken",
  async ({ dataToSend, axiosCancelToken }, { getState }) => {
    const language = getState().languages.data;
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies`);
    const response = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies`, dataToSend, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${language}`,
      },
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postEmployeeThunkWithoutToken = createAsyncThunk(
  "allCompaniesWithoutToken/postEmployeeThunkWithoutToken",
  async ({ dataToSend, id, axiosCancelToken }, { getState }) => {
    const language = getState().languages.data;
    console.log(`${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/${id}/employees`);
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/${id}/employees`,
      dataToSend,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${language}`,
        },
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

export const activateEmployeeThunkWithoutToken = createAsyncThunk(
  "allCompaniesWithoutToken/activateEmployeeThunkWithoutToken",
  async ({ dataToSend, companyId, companyEmployeeId, axiosCancelToken }, { getState }) => {
    const language = getState().languages.data;
    console.log(
      `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/${companyId}/employees/${companyEmployeeId}/activate`,
    );
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/${companyId}/employees/${companyEmployeeId}/activate`,
      dataToSend,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${language}`,
        },
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

const getAllCompaniesWithoutTokenSlice = createSlice({
  name: "allCompaniesWithoutToken",
  initialState,
  extraReducers: {
    [getSiretThunkWithoutToken.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getSiretThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getSiretThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getDunsThunkWithoutToken.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getDunsThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getDunsThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [postCompanyThunkWithoutToken.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPost = true;
    },
    [postCompanyThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newCompanyId = action.payload.id;
      state.pendingPost = false;
    },
    [postCompanyThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPost = false;
      state.errorMessage = action.error.message;
    },
    [postEmployeeThunkWithoutToken.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostEmployee = true;
    },
    [postEmployeeThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newEmployeeId = action.payload.id;
      state.pendingPostEmployee = false;
    },
    [postEmployeeThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostEmployee = false;
      state.errorMessage = action.error.message;
    },
    [activateEmployeeThunkWithoutToken.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [activateEmployeeThunkWithoutToken.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newEmployeeId = action.payload.id;
      state.pending = false;
    },
    [activateEmployeeThunkWithoutToken.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getAllCompaniesWithoutToken = (state) => state.allCompaniesWithoutToken.company;
export const getAllCompaniesWithoutTokenPostCompanyPending = (state) => state.allCompaniesWithoutToken.pendingPost;
export const getAllCompaniesWithoutTokenPostEmployeePending = (state) =>
  state.allCompaniesWithoutToken.pendingPostEmployee;
export const selectAllCompaniesWithoutTokenPending = (state) => state.allCompaniesWithoutToken.pending;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getAllCompaniesWithoutTokenSlice.reducer;
