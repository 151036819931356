import { t } from "i18next";
import { useSelector } from "react-redux";
import ResumeCard from "components/ResumeCard/ResumeCard";
import { getcreateYourCVYourSearchData } from "../../../../../app/slices/createYourCV/createYourCV.slice";

/* eslint-disable */
const ResumeCardCv = () => {
  const yourSearchData = useSelector(getcreateYourCVYourSearchData);

  const handleFirstIntershipDurationDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    console.log("handleFirstIntershipDurationDisplay", yourSearchData.contractTypes);

    if (yourSearchData) {
      for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
        switch (yourSearchData?.contractTypes[i].key) {
          case "Stage":
            isInerShip = true;
            break;
          case "Alternance":
            isPartTime = true;
            break;
          case "Emploi CDI":
            isCDI = true;
            break;
          case "Emploi CDD":
            isCDD = true;
            break;
          case "VIE":
            isVIE = true;
            break;
          case "Service civique":
            isCivicalServvice = true;
            break;
          default:
            isInerShip = false;
            isPartTime = false;
            isCDI = false;
            isCDD = false;
            isVIE = false;
            isCivicalServvice = false;
            break;
        }
      }

      console.log("handleFirstIntershipDurationDisplay", isInerShip, isPartTime, isCDI, isCDD, isCivicalServvice);

      //IF STAGE || STAGE + ALTERNANCE || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
      if (
        isInerShip ||
        (isInerShip && isPartTime) ||
        (isInerShip && (isCDI || isCDD || isVIE || isCivicalServvice)) ||
        (isInerShip && isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice))
      ) {
        return true;
      } else return false;
    }
  };

  const handleFirstDisponibiliti_ThirdParttimeRithmDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF ALTERNANCE || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if ((isPartTime && !isInerShip) || (isPartTime && !isInerShip && (isCDI || isCDD || isVIE || isCivicalServvice))) {
      return true;
    } else return false;
  };

  const handleFirstDisponibilitiFrom_ThirdRqthDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    //IF CDI et/ou CDD et/ou VIE et/ou Service Civique
    if ((isCDI || isCDD || isVIE || isCivicalServvice) && !isPartTime && !isInerShip) {
      return true;
    } else return false;
  };

  const handleSecondDisponibilitiFromDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF STAGE || Stage + Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if (
      isInerShip ||
      (isInerShip && isPartTime) ||
      (isInerShip && (isCDI || isCDD || isVIE || isCivicalServvice)) ||
      (isInerShip && isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice))
    ) {
      return true;
    } else return false;
  };

  const handleSecondSecialtyDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF ALTERNANCE || CDI et/ou CDD et/ou VIE et/ou Service Civique || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if (
      (isPartTime && !isInerShip) ||
      ((isCDI || isCDD || isVIE || isCivicalServvice) && !isPartTime && !isInerShip) ||
      (isPartTime && !isInerShip && (isCDI || isCDD || isVIE || isCivicalServvice))
    ) {
      return true;
    } else return false;
  };

  const handleThirdSecialtyDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF STAGE || Stage + Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if (
      (isInerShip && !isPartTime) ||
      (isInerShip && isPartTime) ||
      (isInerShip && !isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice)) ||
      (isInerShip && isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice))
    ) {
      return true;
    } else return false;
  };

  const handleFourthRqthDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF STAGE || Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if (
      (isInerShip && !isPartTime) ||
      (!isInerShip && isPartTime) ||
      (isInerShip && !isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice)) ||
      (!isInerShip && isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice))
    ) {
      return true;
    } else return false;
  };

  const handleFourthParttimeRithm_FithRqthDisplay = () => {
    let isInerShip = false;
    let isPartTime = false;
    let isCDI = false;
    let isCDD = false;
    let isVIE = false;
    let isCivicalServvice = false;

    for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
      switch (yourSearchData?.contractTypes[i].key) {
        case "Stage":
          isInerShip = true;
          break;
        case "Alternance":
          isPartTime = true;
          break;
        case "Emploi CDI":
          isCDI = true;
          break;
        case "Emploi CDD":
          isCDD = true;
          break;
        case "VIE":
          isVIE = true;
          break;
        case "Service civique":
          isCivicalServvice = true;
          break;
        default:
          isInerShip = false;
          isPartTime = false;
          isCDI = false;
          isCDD = false;
          isVIE = false;
          isCivicalServvice = false;
          break;
      }
    }

    // IF Stage + Alternance || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
    if ((isInerShip && isPartTime) || (isInerShip && isPartTime && (isCDI || isCDD || isVIE || isCivicalServvice))) {
      return true;
    } else return false;
  };

  const displayContractType = () => {
    let tmp = [];
    let allValue;

    if (yourSearchData) {
      console.log("CONTRACT TYPE LENGTH", yourSearchData.contractTypes.length);

      for (let i = 0; i < yourSearchData.contractTypes.length; i++) {
        console.log("CONTRACT TYPE LABEL", yourSearchData?.contractTypes[i]?.label);
        tmp.push(yourSearchData.contractTypes[i].label);
      }

      allValue = tmp.join(", ");
      return allValue;
    }
  };

  if (yourSearchData) {
    return (
      <ResumeCard
        myElem={[
          {
            libel: t("app.depotCv.TypeExperience"),
            value: displayContractType(),
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: true,
            isString: true,
          },

          {
            libel: t("app.depotCv.DureeStage"), // IF STAGE || STAGE + ALTERNANCE || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.durationInternship?.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFirstIntershipDurationDisplay(),
            isString: true,
          },
          {
            libel: t("app.depotCv.Dispo"), //IF ALTERNANCE || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.availableFrom,
            isObjArray: false,
            isDate: true,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFirstDisponibiliti_ThirdParttimeRithmDisplay(),
            isString: false,
          },
          {
            libel: t("app.depotCv.Dispo"), //IF CDI et/ou CDD et/ou VIE et/ou Service Civique
            value: yourSearchData?.availableFrom,
            isObjArray: false,
            isDate: true,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFirstDisponibilitiFrom_ThirdRqthDisplay(),
            isString: false,
          },

          {
            libel: t("app.depotCv.Dispo"), // IF STAGE || Stage + Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.availableFrom,
            isObjArray: false,
            isDate: true,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleSecondDisponibilitiFromDisplay(),
            isString: false,
          },
          {
            libel: t("app.depotCv.Specialite"), // IF ALTERNANCE || CDI et/ou CDD et/ou VIE et/ou Service Civique || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.speciality?.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleSecondSecialtyDisplay(),
            isString: true,
          },

          {
            libel: t("app.depotCv.Specialite"), // IF STAGE || Stage + Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.speciality?.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleThirdSecialtyDisplay(),
            isString: true,
          },
          {
            libel: t("app.depotCv.workStudyProgram"), // IF ALTERNANCE || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.workStudyProgram,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFirstDisponibiliti_ThirdParttimeRithmDisplay(),
            isString: true,
          },
          {
            libel: t("app.depotCv.RQTH"), // IF CDI et/ou CDD et/ou VIE et/ou Service Civique
            value: yourSearchData?.recognizedDisabilityStatus.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFirstDisponibilitiFrom_ThirdRqthDisplay(),
            isString: true,
          },

          {
            libel: t("app.depotCv.RQTH"), // IF STAGE || Alternance || Stage + (CDI et/ou CDD et/ou VIE et/ou Service Civique) || Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.recognizedDisabilityStatus.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFourthRqthDisplay(),
            isString: true,
          },
          {
            libel: t("app.depotCv.workStudyProgram"), // IF Stage + Alternance || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.workStudyProgram,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFourthParttimeRithm_FithRqthDisplay(),
            isString: true,
          },

          {
            libel: t("app.depotCv.RQTH"), // IF Stage + Alternance || Stage + Alternance + (CDI et/ou CDD et/ou VIE et/ou Service Civique)
            value: yourSearchData?.recognizedDisabilityStatus.label,
            isObjArray: false,
            isDate: false,
            isPostalCode: false,
            isCity: false,
            toDisplay: handleFourthParttimeRithm_FithRqthDisplay(),
            isString: true,
          },
        ]}
        title={t("app.depotCv.Recap")}
      />
    );
  }
};

export default ResumeCardCv;
