import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { referentialsParamsETU } from "utils/referentialsParamsArrays/referentialsParamsETU";
import { referentialsParamsENT } from "utils/referentialsParamsArrays/referentialsParamsENT";
// import { referentialsParamsTutor } from "utils/referentialsParamsArrays/referentialsParamsTutor";
import { STUDENT_SUBDOMAIN_NAME, COMPANY_SUBDOMAIN_NAME } from "utils/globalConstantsAndURL";
import { axiosCommon, axiosSchool } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  data: null,
  trainingSchools: null,
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getReferentialsThunk = createAsyncThunk("referentials/getReferentialsThunk", async (_, { getState }) => {
  const { subdomain } = getState();

  // Define the good referentialsParams array
  let keysArray = [];
  if (subdomain === STUDENT_SUBDOMAIN_NAME) {
    keysArray = referentialsParamsETU;
  } else if (subdomain === COMPANY_SUBDOMAIN_NAME) {
    keysArray = referentialsParamsENT;
  }

  // create the params string according to keysArray
  let paramsString = "";
  keysArray.forEach((key) => {
    if (!paramsString) {
      paramsString += `keys[]=${key}`;
    } else {
      paramsString += `&keys[]=${key}`;
    }
  });

  // API call
  const response = await axiosCommon().get(`/v1/referentials?${paramsString}`);

  // Transform the response.data to an object of arrays, where all properties are equal to the params keys
  const newDataFormat = {};
  response.data.forEach((obj) => {
    newDataFormat[obj.key] = obj.options;
  });
  console.log("Referentials : ", newDataFormat);

  return newDataFormat;
});

export const getTrainingSchoolsThunk = createAsyncThunk("referentials/getTrainingSchoolsThunk", async () => {
  const response = await axiosSchool().get("/v1/schools/training-schools");

  return response.data;
});

// CREATESLICE SECTION

const referentialsSlice = createSlice({
  name: "referentials",
  initialState,
  extraReducers: {
    [getReferentialsThunk.pending]: (state) => {
      state.pending = true;
      state.errorMessage = null;
    },
    [getReferentialsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.data = action.payload;
      state.pending = false;
    },
    [getReferentialsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.errorMessage = action.error.message;
      state.pending = false;
    },
    [getTrainingSchoolsThunk.pending]: (state) => {
      state.pending = true;
      state.errorMessage = null;
    },
    [getTrainingSchoolsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.trainingSchools = action.payload;
      state.pending = false;
    },
    [getTrainingSchoolsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.errorMessage = action.error.message;
      state.pending = false;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getReferentialsData = (state) => state.referentials.data;
export const getTrainingSchoolsData = (state) => state.referentials.trainingSchools;
export const getReferentialsPending = (state) => state.referentials.pending;
export const getReferentialsErrorMessage = (state) => state.referentials.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default referentialsSlice.reducer;
