import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  pending: false,
  errorMessage: "",
  id: null,
  data: null,
};

// THUNKS SECTION

export const PostProjectThunk = createAsyncThunk("createProject/PostProjectThunk", async ({ dataToSend, cvId }) => {
  const response = await axiosStudent().post(`/v1/students/resumes/${cvId}/projects`, dataToSend);
  return response.data;
});

export const putProjectThunk = createAsyncThunk(
  "createYourProject/patchProjectThunk",
  async ({ dataToSend, cvId, id }) => {
    const response = await axiosStudent().put(`/v1/students/resumes/${cvId}/projects/${id}`, dataToSend);
    return response.data;
  },
);

// CREATESLICE SECTION
const createYourProjectSlice = createSlice({
  name: "createYourProject",
  initialState,
  reducers: {},
  extraReducers: {
    [PostProjectThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PostProjectThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [PostProjectThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [putProjectThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [putProjectThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [putProjectThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getcreateYourProjectPending = (state) => state.createYourProject.pending;
export const getcreateYourProjectErrorMessage = (state) => state.createYourProject.errorMessage;
export const getcreateYourProjectId = (state) => state.createYourProject.id;
export const getcreateYourProjectData = (state) => state.createYourProject.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default createYourProjectSlice.reducer;
