import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  actualStep: 1,
  numberOfSteps: 6,
  pending: false,
  errorMessage: "",
  id: null,
  stepsData: {
    yourSearch: null,
    yourProfile: null,
    experiences: null,
    yourSkills: null,
    contact: null,
  },
};

// THUNKS SECTION

export const postStep1Thunk = createAsyncThunk(
  "createYourCV/postStep1Thunk",
  async ({ dataToSend, axiosCancelToken }) => {
    const response = await axiosStudent().post("/v1/students/resumes", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const patchStepThunk = createAsyncThunk("createYourCV/patchStepThunk", async ({ dataToSend, id }) => {
  const response = await axiosStudent().patch(`/v1/students/resumes/${id}`, dataToSend);
  return response.data;
});

// CREATESLICE SECTION

const createYourCVSlice = createSlice({
  name: "createYourCV",
  initialState,
  reducers: {
    handlePreviousStep: (state) => {
      if (state.actualStep - 1 <= 0) {
        console.log("L'étape précédente n'existe pas !");
        return;
      }
      state.actualStep -= 1;
    },
    handleResetToStep1: (state) => {
      console.log("reset");
      state.actualStep = 1;
      state.pending = false;
      state.errorMessage = "";
      state.id = null;
      state.stepsData = {
        yourSearch: null,
        yourProfile: null,
        experiences: null,
        yourSkills: null,
        contact: null,
      };
    },
    handleStepsData: (state, action) => {
      console.log(action);
      state.stepsData[action.payload.formName] = action.payload.formData;
    },
    handleNexStep: (state) => {
      state.actualStep += 1;
    },
  },
  extraReducers: {
    [postStep1Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [postStep1Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.id = action.payload.id;
      state.actualStep += 1;
    },
    [postStep1Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error.message);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchStepThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchStepThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.actualStep += 1;
    },
    [patchStepThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error.message);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getActualStep = (state) => state.createYourCV.actualStep;
export const getcreateYourCVPending = (state) => state.createYourCV.pending;
export const getcreateYourCVErrorMessage = (state) => state.createYourCV.errorMessage;
export const getcreateYourCVId = (state) => state.createYourCV.id;
export const getcreateYourCVYourSearchData = (state) => state.createYourCV.stepsData.yourSearch;
export const getcreateYourCVYourProfileData = (state) => state.createYourCV.stepsData.yourProfile;
export const getcreateYourCVYourCompementarySkillData = (state) => state.createYourCV.stepsData.yourSkills;
export const getcreateYourCVContactData = (state) => state.createYourCV.stepsData.contact;

// ACTIONS CREATOR EXPORTS SECTION

export const { handlePreviousStep, handleResetToStep1, handleStepsData, handleNexStep } = createYourCVSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default createYourCVSlice.reducer;
