import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toastSuccess } from "utils/toasts";
import * as Yup from "yup";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import {
  getAllProjects,
  getProjectsCardsIsLoading,
  getProjectsThunk,
} from "app/slices/createYourCV/getYourProjects.slice";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";

import Header from "../../../../../../components/Header/Header";

import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import {
  getcreateYourProjectData,
  getcreateYourProjectErrorMessage,
  getcreateYourProjectId,
  getcreateYourProjectPending,
  PostProjectThunk,
} from "../../../../../../app/slices/createYourCV/createYourProject.slice";
import ProjectCard from "../components/projectsCard";
import Modal from "../components/AcademicProjectsModal";
import style from "./AddExperienceBtn.module.scss";
import styles from "../../../CreateYourCV.module.scss";

/* eslint-disable */
const AcademicProjects = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourProjectPending);
  const errorMessage = useSelector(getcreateYourProjectErrorMessage);
  const id = useSelector(getcreateYourProjectId);
  const AcademicProjectsData = useSelector(getcreateYourProjectData);
  const ProjectsList = useSelector(getAllProjects);
  const [isFinishedAcaProj, setIsFinishedAcaProj] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const cvId = useSelector(getcreateYourCVId);
  const getProjectsLoading = useSelector(getProjectsCardsIsLoading);
  const handleModalData = (data) => {
    setIsModal(!isModal);
    setModalData(data);
    console.log("this is the Modal Data", modalData);
  };
  const [isModify, setIsModify] = useState(false);
  const closeModal = () => {
    setIsModal(false);
    setIsModify(false);
  };
  const modifyForm = () => {
    setIsModify(true);
  };
  const goToModify = (data) => {
    setIsModal(!isModal);
    setIsModify(true);
    setModalData(data);
  };
  useEffect(() => {
    if (!getProjectsLoading) {
      setTimeout(() => {
        (async () => {
          try {
            if (!id) {
              await dispatch(
                getProjectsThunk({
                  cvId,
                }),
              ).unwrap();
              console.log("this is the project Data:", ProjectsList);
            }
          } catch (error) {
            console.log("useEffect fail !", error.message);
          }
        })();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedAcaProj, isModal]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const AcademicProjectSchema = Yup.object().shape({
    title: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    description: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
  });
  const onSubmit = (values, { resetForm }) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);
    dispatch(
      handleStepsData({
        formName: "Projects",
        formData: {
          title: values.title,
          description: values.description,
          startDate: formatDate(values.startDate),
          endDate: formatDate(values.endDate),
        },
      }),
    );

    const dataToSend = {
      title: valuesCopy.title,
      description: valuesCopy.description,
      startDate: formatDate(valuesCopy.startDate),
      endDate: formatDate(valuesCopy.endDate),
    };

    console.log("dataToSend : ", dataToSend);
    setIsFinishedAcaProj(true);
    if (!pending) {
      (async () => {
        if (ProjectsList.length < 5) {
          try {
            await dispatch(
              PostProjectThunk({
                cvId,
                dataToSend,
                axiosCancelToken: props.axiosCancelToken,
              }),
            ).unwrap();
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ConfirmationAjoutProjetAcademicTitle")}
                secondMessage={t("app.toastMessages.ConfirmationAjoutProjetAcademicMessage")}
              />,
            );
            console.log("YourProfile post success !");
          } catch (error) {
            console.log("YourProfile post fail !", error.message);
          }
        }

        resetForm({ values: "" });
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: AcademicProjectsData
      ? {
          ...AcademicProjectsData,
          startDate: new Date(JSON.parse(AcademicProjectsData.startDate)),
          endDate: new Date(JSON.parse(AcademicProjectsData.endDate)),
        }
      : {
          title: "",
          startDate: null,
          endDate: null,
          description: "",
        },
    validationSchema: AcademicProjectSchema,
    onSubmit,
  });
  const HandlePushProjectButton = ({ text }) => {
    return (
      <button
        type="submit"
        className={style.validate_exp_btn}
        onClick={() => {
          onSubmit();
        }}
      >
        {text}
      </button>
    );
  };

  const AddProjectBtn = ({ text, disabled }) => {
    return (
      <button
        disabled={disabled}
        style={{
          color: disabled ? "grey" : null,
          fontSize: 13,
          fontFamily: "Inseec",
          textSizeAdjust: "100%",
        }}
        type="submit"
        className={style.add_proj_btn}
        onClick={() => setIsFinishedAcaProj(false)}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      {isModal && <Modal closeModal={closeModal} modifyForm={modifyForm} isModify={isModify} modalData={modalData} />}
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
        />
      )}
      {!pending && (
        <>
          <section className={styles.form_container}>
            {/* Academic experiences */}
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <span style={{ color: "red" }}>{errorMessage}</span>
              <div className={styles.sub_Title}>
                <span className={styles.test}>
                  <p>{t("app.depotCv.ProjetAcademique")}</p>
                  <p></p>
                </span>
              </div>
              <>
                {ProjectsList.map((obj, index) => (
                  <ProjectCard
                    modifyForm={goToModify}
                    isModify={isModify}
                    handleModalData={handleModalData}
                    {...obj}
                    key={index}
                  />
                ))}
                {isFinishedAcaProj ? (
                  <div className={style.add_proj_btn_container}>
                    <AddProjectBtn disabled={ProjectsList.length >= 5 ? true : false} text={t("app.depotCv.ADD")} />
                  </div>
                ) : (
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="title">{t("app.depotCv.TitreProjet")}</label>
                      <input
                        id="title"
                        style={
                          errors.title &&
                          touched.title && {
                            border: "2px solid #EA001E",
                          }
                        }
                        type="text"
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                      />
                      <InputErrorMessage message={errors.title} touched={touched.title} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="StartDate">{t("app.depotCv.DateDebut")}</label>
                      {/* <DatePicker
												style={
													errors.startDate &&
													touched.startDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.startDate}
												onChange={(value) => setFieldValue("startDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.startDate}
                        touched={touched.startDate}
                        value={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="endDate">{t("app.depotCv.DateFin")}</label>
                      {/* <DatePicker
												style={
													errors.endDate &&
													touched.endDate && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
													}
												}
												value={values.endDate}
												onChange={(value) => setFieldValue("endDate", value)}
											/> */}
                      <DatePickerCustom
                        error={errors.endDate}
                        touched={touched.endDate}
                        value={values.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                    </div>
                    <div className={`${styles.label_plus_input_container} input-like-rw`}>
                      <label htmlFor="description">{t("app.depotCv.Description")}</label>
                      <textarea
                        style={
                          errors.description &&
                          touched.description && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="description"
                        value={values.description}
                        onChange={(e) => setFieldValue("description", e.target.value)}
                      />
                      <InputErrorMessage message={errors.description} touched={touched.description} />
                    </div>
                    <div className={style.validate_exp_btn_container}>
                      <HandlePushProjectButton text={t("app.depotCv.VALIDERPROJ")} />
                    </div>
                  </div>
                )}
              </>
            </form>
          </section>
        </>
      )}
    </>

    // End Academic experiences
  );
};

export default AcademicProjects;
