import pdfIcon from "./fileIconType/PDF.svg";
import txtIcon from "./fileIconType/txt.svg";
import wordIcon from "./fileIconType/Word.svg";
import htmlIcon from "./fileIconType/HTML.svg";
import csvIcon from "./fileIconType/CSV.svg";
import aiIcon from "./fileIconType/Ai.svg";
import epsIcon from "./fileIconType/EPS.svg";
import imageIcon from "./fileIconType/Image.svg";
import defaultIcon from "./fileIconType/Unknown.svg";
import xlstIcon from "./fileIconType/XLS.svg";
import odsIcon from "./fileIconType/G-Sheet.svg";
import exeIcon from "./fileIconType/EXE.svg";
import mp4Icon from "./fileIconType/MP4.svg";
import pgsIcon from "./fileIconType/Pages.svg";
import psdIcon from "./fileIconType/PSD.svg";
import rtfIcon from "./fileIconType/RTF.svg";
import videoIcon from "./fileIconType/Video.svg";
import xmlIcon from "./fileIconType/XML.svg";
import xmdIcon from "./fileIconType/XMD.svg";
import zipIcon from "./fileIconType/Zip.svg";

/* eslint-disable */
export function getFileTypeDownload(props) {
  if (props !== undefined) {
    switch (props) {
      case "ai":
        return aiIcon;
        break;
      case "eps":
        return epsIcon;
      case "txt":
        return txtIcon;
        break;
      case "pdf":
        return pdfIcon;
        break;
      case "docx":
        return wordIcon;
        break;
      case "html":
        return htmlIcon;
        break;
      case "csv":
        return csvIcon;
        break;
      case "xlsx" || "xls":
        return xlstIcon;
        break;
      case "ods":
        return odsIcon;
        break;
      case "exe":
        return exeIcon;
        break;
      case "mp4":
        return mp4Icon;
        break;
      case "pgs":
        return pgsIcon;
        break;
      case "psd":
        return psdIcon;
        break;
      case "rtf":
        return rtfIcon;
        break;
      case "xml":
        return xmlIcon;
        break;
      case "xmd":
        return xmdIcon;
        break;
      case "zip":
        return zipIcon;
        break;
      default:
        switch (props) {
          case "video":
            return videoIcon;
            break;
          case "image":
            return imageIcon;
            break;
          default:
            return defaultIcon;
            break;
        }
        break;
    }
  }
}
