import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";

import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { toastSuccess } from "utils/toasts";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useSelector, useDispatch } from "react-redux";
import {
  getAssociativeExperiences,
  patchAssociativeExperiencesThunk,
} from "../../../../app/slices/modifyYourCV/associativeExperiences.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

/* eslint-disable */
const ModifyModalAssociativeExperiences = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const associativeExperiencesById = useSelector(getAssociativeExperiences);

  const handleProps = (value) => {
    if (value !== undefined) {
      setFieldValue("organizationName", value.organizationName);
      setFieldValue("startDate", value.startDate);
      setFieldValue("endDate", value.endDate);
      setFieldValue("position", value.position);
      setFieldValue("performedTasks", value.performedTasks);
      setFieldValue("acquiredSkills", value.acquiredSkills);
    }
  };

  useEffect(() => {
    handleProps(props.object);
  }, [props.object]);

  const onSubmit = (values) => {
    const dataToSend = {
      organizationName: values.organizationName,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      position: values.position,
      performedTasks: values.performedTasks,
      acquiredSkills: values.acquiredSkills,
    };
    (async () => {
      try {
        await dispatch(
          patchAssociativeExperiencesThunk({
            dataToSend,
            id: id,
            ID: props.id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationModifExpAssoTitle")}
            secondMessage={t("app.toastMessages.ConfirmationModifExpAssoMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const yourSearchSchema = Yup.object().shape({
    organizationName: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    position: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    performedTasks: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    acquiredSkills: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      organizationName: props.object?.organizationName ? props.object?.organizationName : "",
      startDate: null,
      endDate: null,
      position: props.object?.position ? props.object?.position : "",
      performedTasks: props.object?.performedTasks ? props.object?.performedTasks : "",
      acquiredSkills: props.object?.acquiredSkills ? props.object?.acquiredSkills : "",
    },
    validationSchema: yourSearchSchema,
    onSubmit,
  });

  if (props.showModify && associativeExperiencesById)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModificationExperienceAssociative")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="organizationName">
                    {t("app.visualisationCv.NomAssociation")}
                  </label>
                  <input
                    style={
                      errors.organizationName &&
                      touched.organizationName && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.organizationName}
                    maxLength={80}
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    onChange={(e) => setFieldValue("organizationName", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  {/* <DatePicker
										style={
											errors.startDate &&
											touched.startDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={new Date(values.startDate)}
										onChange={(value) => setFieldValue("startDate", value)}
									/>
									 */}
                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  {/* <DatePicker
										style={
											errors.endDate &&
											touched.endDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={new Date(values.endDate)}
										onChange={(value) => setFieldValue("endDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.endDate}
                    touched={touched.endDate}
                    value={values.endDate}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                  <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="position">
                    {t("app.visualisationCv.Fonction")}
                  </label>
                  <textarea
                    style={
                      errors.position &&
                      touched.position && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.position}
                    maxLength={80}
                    type="position"
                    name="position"
                    id="position"
                    onChange={(e) => setFieldValue("position", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.position} touched={touched.position} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="performedTasks">
                    {t("app.visualisationCv.MissionsRealisees")}
                  </label>
                  <textarea
                    style={
                      errors.performedTasks &&
                      touched.performedTasks && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.performedTasks}
                    maxLength={255}
                    type="performedTasks"
                    name="performedTasks"
                    id="performedTasks"
                    onChange={(e) => setFieldValue("performedTasks", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="acquiredSkills">
                    {t("app.visualisationCv.3Competences")}
                  </label>
                  <textarea
                    style={
                      errors.acquiredSkills &&
                      touched.acquiredSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.acquiredSkills}
                    maxLength={500}
                    type="acquiredSkills"
                    name="acquiredSkills"
                    id="acquiredSkills"
                    onChange={(e) => setFieldValue("acquiredSkills", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn
                  onClick={errors ? null : props.onClose}
                  text={t("app.visualisationCv.ValidezModification")}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showModify) return null;
};

export default ModifyModalAssociativeExperiences;
