import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  company: null,
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getCodeIdccThunk = createAsyncThunk(
  "allCodeIdcc/getCodeIdccThunk",
  async ({ codeIdcc, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v1/companies/collective-agreements?code=${codeIdcc}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

const getAllCodeIdccSlice = createSlice({
  name: "allCodeIdcc",
  initialState,
  extraReducers: {
    [getCodeIdccThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getCodeIdccThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.codeIdcc = action.payload;
      state.pending = false;
    },
    [getCodeIdccThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getAllCodeIdcc = (state) => state.allCodeIdcc.codeIdcc;
export const getCodeIdccIsPending = (state) => state.allCodeIdcc.pending;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default getAllCodeIdccSlice.reducer;
