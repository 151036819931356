import { t } from "i18next";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import {
  getcreateYourProjectData,
  getcreateYourProjectErrorMessage,
  getcreateYourProjectPending,
  putProjectThunk,
} from "../../../../../../app/slices/createYourCV/createYourProject.slice";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import styles from "./Modal.module.scss";

/* eslint-disable */
const Modal = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourProjectPending);
  const errorMessage = useSelector(getcreateYourProjectErrorMessage);
  const AcademicProjectData = useSelector(getcreateYourProjectData);
  const cvId = useSelector(getcreateYourCVId);
  const data = props.modalData;

  const closeModal = props.closeModal;
  const modifyForm = props.modifyForm;
  const isModify = props.isModify;
  const AcademicProjectSchema = Yup.object().shape({
    title: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    description: Yup.string().max(255, "Maximum 255 caractères").required("Remplissez ce champ."),
    startDate: Yup.date().required("la date est obligatoire").nullable(true),
    endDate: Yup.date().required("la date est obligatoire").nullable(true),
  });
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    const convertToDate = new Date(date);

    return [
      convertToDate.getFullYear(),

      padTo2Digits(convertToDate.getMonth() + 1),

      padTo2Digits(convertToDate.getDate()),
    ].join("-");
  }
  const onSubmit = (values, actions) => {
    dispatch(
      handleStepsData({
        formName: "Experiences",
        formData: {
          title: values.title,
          description: values.description,
          startDate: JSON.stringify(values.startDate),
          endDate: JSON.stringify(values.endDate),
        },
      }),
    );
    const id = data.id;
    const dataToSend = {
      title: values.title,
      description: values.description,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    };

    console.log("dataToSend : ", dataToSend);
    props.closeModal();
    if (!pending) {
      (async () => {
        try {
          await dispatch(
            putProjectThunk({
              dataToSend,
              id,
              cvId: cvId,
              // axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ConfirmationModifProjetAcademicTitle")}
              secondMessage={t("app.toastMessages.ConfirmationModifProjetAcademicMessage")}
            />,
          );
          console.log("Your experience put success !");
        } catch (error) {
          console.log("Your experience put fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: AcademicProjectData
      ? {
          ...AcademicProjectData,
          startDate: new Date(JSON.parse(AcademicProjectData.startDate)),
          endDate: new Date(JSON.parse(AcademicProjectData.endDate)),
        }
      : {
          title: data.title,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          description: data.description,
        },
    validationSchema: AcademicProjectSchema,
    onSubmit,
  });
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <div style={{ flexDirection: "column" }}>
          {isModify ? (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ModiFyAcaProjModal")}</span>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <span style={{ color: "red" }}>{errorMessage}</span>
                <div className={styles.formContainer}>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="title">{t("app.depotCv.TitreProjet")}</label>
                    <input
                      id="title"
                      style={
                        errors.title &&
                        touched.title && {
                          border: "2px solid #EA001E",
                        }
                      }
                      type="text"
                      value={values.title}
                      onChange={(e) => setFieldValue("title", e.target.value)}
                    />
                    <InputErrorMessage message={errors.title} touched={touched.title} />
                  </div>

                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="StartDate">{t("app.depotCv.DateDebut")}</label>
                    {/* <DatePicker
											style={
												errors.startDate &&
												touched.startDate && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
												}
											}
											value={values.startDate}
											onChange={(value) => setFieldValue("startDate", value)}
										/> */}
                    <DatePickerCustom
                      error={errors.startDate}
                      touched={touched.startDate}
                      value={values.startDate}
                      onChange={(value) => setFieldValue("startDate", value)}
                    />
                    <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="endDate">{t("app.depotCv.DateFin")}</label>
                    {/* <DatePicker
											style={
												errors.endDate &&
												touched.endDate && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
												}
											}
											value={values.endDate}
											onChange={(value) => setFieldValue("endDate", value)}
										/> */}
                    <DatePickerCustom
                      error={errors.endDate}
                      touched={touched.endDate}
                      value={values.endDate}
                      onChange={(value) => setFieldValue("endDate", value)}
                    />
                    <InputErrorMessage message={errors.endDate} touched={touched.endDate} />
                  </div>
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="description">{t("app.depotCv.Description")}</label>
                    <textarea
                      style={
                        errors.description &&
                        touched.description && {
                          border: "2px solid #EA001E",
                        }
                      }
                      id="description"
                      value={values.description}
                      onChange={(e) => setFieldValue("description", e.target.value)}
                    />
                    <InputErrorMessage message={errors.description} touched={touched.description} />
                  </div>
                  {/* <div className={styles.label_plus_input_container}></div> */}
                </div>
                <div className={styles.submit_button_container}>
                  <button type="submit" className={styles.submit_button}>
                    {t("app.depotCv.ValidateProj")}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <p className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.depotCv.ConsultAcadProj")}</span>
                </div>
              </p>
              <div className={styles.formContainer}>
                <div>
                  <div className={styles.etab}>
                    {t("app.depotCv.TitreProjet")}: {data.title}
                  </div>
                  <div className={styles.date}>
                    {t("app.depotCv.du")} {formatDate(data.startDate)} {t("app.depotCv.au")} {data.endDate}
                  </div>
                  <div className={styles.description}>{t("app.depotCv.Description")}</div>
                  <div className={styles.dataDescription}>{data.description}</div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={modifyForm} className={styles.modifyButton}>
                  {t("app.depotCv.ModifyProj")}
                </button>
              </div>
            </>
          )}
        </div>

        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
