import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";

import { t } from "i18next";
import { DropdownList, Listbox } from "react-widgets";

import DivLine from "components/DivLine/DivLine";
import Header from "components/Header/Header";
import { useFormik } from "formik";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { isString } from "utils/isString";
import {
  getCVList,
  getCVListErrorMessage,
  getCVListPending,
  getCVThunk,
  getNumberOfCvs,
  nextCVPaginationThunk,
  previousCVPaginationThunk,
  resetGetCVsKeyState,
  selectCurrentPaginationOfCVS,
  selectMaxPaginationOfCVS,
} from "app/slices/ListOfCV/checkAllCV.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { isEmpty } from "utils/isEmpty";
import { formatDate } from "utils/formatDate";
import { nanoid } from "@reduxjs/toolkit";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import arrowPagination from "./assets/icon-arrow-pagination.svg";
import bgConsulOffreImg from "./assets/bg-consul-offre.png";
import styles from "./ListOfCV.module.scss";

export const disabilityProfileOptions = [
  { key: "true", label: `${t("app.visualisationOffre.Oui")}` },
  { key: "false", label: `${t("app.visualisationOffre.Non")}` },
];

const speciality = {
  key: "speciality",
};

const educationLevel = {
  key: "educationLevel",
};
const availableFrom = {
  key: "availableFrom",
};
const contractTypes = {
  key: "contractTypes",
  max: 6,
};
const softSkills = {
  key: "softSkills",
  max: 18,
};
const mobility = {
  key: "mobility",
};
const disabilityProfile = {
  options: disabilityProfileOptions,
  key: "disabilityProfile",
};
const professionalExperienceNumber = {
  key: "professionalExperienceNumber",
};
const professionalExperienceMonthNumber = {
  key: "professionalExperienceMonthNumber",
};

const language = {
  key: "language",
};

const languageLevel = {
  key: "languageLevel",
};

const frenchLevel = {
  key: "frenchLevel",
};
const computerSkill = {
  key: "computerSkill",
};
const otherLanguage = {
  key: "otherLanguage",
};

const normalizeFilter = {
  speciality: "speciality",
  educationLevel: "educationLevel",
  availableFrom: "availableFrom",
  contractTypes: "contractTypes",
  softSkills: "softSkills",
  mobility: "mobility",
  disabilityProfile: "disabilityProfile",
  professionalExperienceNumber: "professionalExperienceNumber",
  professionalExperienceMonthNumber: "professionalExperienceMonthNumber",
  language: "language",
  otherLanguage: "otherLanguage",
  languageLevel: "languageLevel",
  languageCertification: "languageCertification",
  frenchLevel: "frenchLevel",
  computerSkill: "computerSkill",
  software: "software",
};

const emptySelect = "-Aucun-";

function normalizeData(values) {
  return Object.keys(values).reduce((acc, key) => {
    let value = values[key];
    if (key === "availableFrom") {
      value = formatDate(value);
    }

    const filterKey = normalizeFilter[key] || key;

    if (!value) {
      return acc;
    }
    if (Array.isArray(values[key])) {
      acc[filterKey] = value.map((v) => v.key);
    } else if (isString(value)) {
      acc[filterKey] = value;
    } else {
      if (value?.key === emptySelect) {
        return acc;
      }
      acc[filterKey] = value.key;
    }

    return acc;
  }, {});
}

const generateParams = (values) => {
  const normalizedData = normalizeData(values);
  const params = Object.keys(normalizedData).reduce((acc, key) => {
    const value = normalizedData[key];

    if (Array.isArray(value) && value.length === 0) {
      return acc;
    }
    if (Array.isArray(value)) {
      return [...acc, `${key}[]=${value.join(`&${key}[]=`)}`];
    }

    if (acc.includes("language=Autre")) {
      return [`otherLanguage=${value}`];
    }
    return [...acc, `${key}=${value}`];
  }, []);
  return params.join("&");
};

export default function ConsultTheOffers() {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const referencialData = useSelector(getReferentialsData);
  const NumberOfCvs = useSelector(getNumberOfCvs);
  console.log("NumberCv", NumberOfCvs);
  const listCV = useSelector(getCVList);
  const pending = useSelector(getCVListPending);
  const isError = useSelector(getCVListErrorMessage);
  const userToken = useSelector(getUserToken);

  const currentPagination = useSelector(selectCurrentPaginationOfCVS);
  const maxPagination = useSelector(selectMaxPaginationOfCVS);
  console.log("MAXPAGINATION", maxPagination);

  const [firstLoad, setFirstLoad] = useState(false);

  console.log("listCV", listCV.length);
  console.log("Front Number", NumberOfCvs);

  useEffect(() => {
    if (!pending && userToken && !firstLoad) {
      (async () => {
        try {
          await dispatch(getCVThunk({ query: `${generateParams(filter)}`, axiosCancelToken })).unwrap();
          setFirstLoad(true);
        } catch (error) {
          console.log("useEffect fail !", isError);
        }
      })();
    }
  }, [userToken]);

  useEffect(() => {
    if (!pending && userToken && firstLoad) {
      (async () => {
        try {
          dispatch(resetGetCVsKeyState());
          await dispatch(getCVThunk({ query: `${generateParams(filter)}`, axiosCancelToken })).unwrap();
        } catch (error) {
          console.log("useEffect fail !", isError);
        }
      })();
    }
  }, [filter]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetGetCVsKeyState());
      console.log("RESET STATE");
    },
    [],
  );

  // useFormik from Formik

  const { values, setFieldValue } = useFormik({
    initialValues: {
      [speciality?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      [educationLevel?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },

      [availableFrom?.key]: null,
      [contractTypes?.key]: [],
      [softSkills?.key]: [],
      [mobility?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      [disabilityProfile?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      [professionalExperienceNumber?.key]: "",
      [professionalExperienceMonthNumber?.key]: "",
      [language?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      [otherLanguage?.key]: "",

      [frenchLevel?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      [computerSkill?.key]: {
        key: emptySelect,
        label: `${t("app.librairyCV.selectOption")}`,
      },
      software: "",
      ...filter,
    },
  });

  const isOtherLangague = values[language.key].key === "Autre" ? { disabled: false } : { disabled: true };

  const isCompleteProfile = `${listCV?.status?.key}` === "Complété";

  /* eslint-disable */
  if (isEmpty(referencialData)) {
    return <p>Data loading ...</p>;
  }

  return (
    <div>
      <Header
        h1={t("app.librairyCV.title")}
        textElement={<p>{t("app.librairyCV.description")}</p>}
        bgImg={bgConsulOffreImg}
      />

      <div className={styles.container}>
        <div className={styles.divline_h2_container}>
          <DivLine />
          <h2 className={styles.titleFilter}>{t("app.librairyCV.titleFilter")}</h2>
        </div>
        <div className={styles.filter_wrapper}>
          <div className={styles.subForm}>
            <div className={styles.subForm__row__threeCol}>
              <div className={styles.formGroup}>
                <label htmlFor={speciality?.key}>{t("app.librairyCV.sector")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.STUDENT_RESUME_SPECIALITY}
                  dataKey="key"
                  textField="label"
                  id={speciality?.key}
                  value={values[speciality?.key]}
                  onChange={(newValue) => {
                    setFieldValue(speciality?.key, newValue);
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={educationLevel?.key}>{t("app.librairyCV.educationLevel")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.STUDENT_EDUCATION_LEVEL}
                  dataKey="key"
                  textField="label"
                  id={educationLevel?.key}
                  value={values[educationLevel?.key]}
                  onChange={(newValue) => setFieldValue(educationLevel?.key, newValue)}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={availableFrom?.key}>{t("app.librairyCV.disponibility")}</label>
                <DatePickerCustom
                  id={availableFrom?.key}
                  value={values[availableFrom?.key]}
                  onChange={(newValue) => setFieldValue(availableFrom?.key, newValue)}
                />
              </div>
            </div>
            <hr />
            <div className={styles.subForm__row__threeCol}>
              <div className={styles.formGroup}>
                <label htmlFor={contractTypes?.key}>{t("app.librairyCV.contractType")}</label>
                <Listbox
                  multiple
                  data={referencialData?.CONTRACT_TYPE}
                  dataKey="key"
                  textField="label"
                  id={contractTypes?.key}
                  value={values[contractTypes?.key]}
                  onChange={(newValue) => {
                    if (newValue.length > contractTypes.max) {
                      return null;
                    }
                    setFieldValue(contractTypes?.key, newValue);
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={softSkills?.key}>{t("app.librairyCV.softSkill")}</label>
                <Listbox
                  multiple
                  data={referencialData?.STUDENT_RESUME_SOFT_SKILL}
                  dataKey="key"
                  textField="label"
                  id={softSkills?.key}
                  value={values[softSkills?.key]}
                  onChange={(newValue) => {
                    if (newValue.length > softSkills.max) {
                      return null;
                    }
                    setFieldValue(softSkills?.key, newValue);
                  }}
                />
              </div>
              <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label htmlFor={mobility?.key}>{t("app.librairyCV.mobility")}</label>
                  <DropdownList
                    defaultValue={[]}
                    data={referencialData?.MOBILITY_LEVEL}
                    dataKey="key"
                    textField="label"
                    id={mobility?.key}
                    value={values[mobility?.key]}
                    onChange={(newValue) => setFieldValue(mobility?.key, newValue)}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor={disabilityProfile?.key}>{t("app.librairyCV.disablityProfile")}</label>
                  <DropdownList
                    defaultValue={[]}
                    data={disabilityProfile?.options}
                    dataKey="key"
                    textField="label"
                    id={disabilityProfile?.key}
                    value={values[disabilityProfile?.key]}
                    onChange={(newValue) => setFieldValue(disabilityProfile?.key, newValue)}
                  />
                </div>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor={professionalExperienceNumber.key}>
                    {t("app.librairyCV.professionnalExperienceNumber")}
                  </label>

                  <input
                    value={values.professionalExperienceNumber}
                    type="number"
                    name="professionalExperienceNumber"
                    id="professionalExperienceNumber"
                    onChange={(e) => setFieldValue("professionalExperienceNumber", e.target.value.trim())}
                  />
                </div>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="professionalExperienceMonthNumber">
                    {t("app.librairyCV.professionnalExperienceMonthNumber")}
                  </label>

                  <input
                    value={values.professionalExperienceMonthNumber}
                    type="number"
                    name="professionalExperienceMonthNumber"
                    id="professionalExperienceMonthNumber"
                    onChange={(e) => setFieldValue("professionalExperienceMonthNumber", e.target.value.trim())}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className={styles.subForm__row}>
              <div className={styles.formGroup}>
                <label htmlFor={language?.key}>{t("app.librairyCV.language")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.LANGUAGE}
                  dataKey="key"
                  textField="label"
                  id={language?.key}
                  value={values[language?.key]}
                  onChange={(newValue) => {
                    setFieldValue(language?.key, newValue);
                  }}
                />
              </div>
              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="otherLanguage">{t("app.librairyCV.otherLanguage")}</label>

                <input
                  value={values.otherLanguage}
                  type="text"
                  name="otherLanguage"
                  {...isOtherLangague}
                  id="otherLanguage"
                  onChange={(e) => setFieldValue("otherLanguage", e.target.value.trim())}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor={languageLevel?.key}>{t("app.librairyCV.languageLevel")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.LANGUAGE_LEVEL}
                  dataKey="key"
                  textField="label"
                  id={languageLevel?.key}
                  value={values[languageLevel?.key]}
                  onChange={(newValue) => setFieldValue(languageLevel?.key, newValue)}
                />
              </div>

              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="languageCertification">{t("app.librairyCV.languageCertification")}</label>

                <input
                  value={values.languageCertification}
                  type="text"
                  name="languageCertification"
                  id="languageCertification"
                  onChange={(e) => setFieldValue("languageCertification", e.target.value.trim())}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor={frenchLevel?.key}>{t("app.librairyCV.frenchLevel")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.LANGUAGE_LEVEL}
                  dataKey="key"
                  textField="label"
                  id={frenchLevel?.key}
                  value={values[frenchLevel?.key]}
                  onChange={(newValue) => setFieldValue(frenchLevel?.key, newValue)}
                />
              </div>
            </div>
            <hr />
            <div className={styles.subForm__row}>
              <div className={styles.formGroup}>
                <label htmlFor={computerSkill?.key}>{t("app.librairyCV.computerSkill")}</label>
                <DropdownList
                  defaultValue={[]}
                  data={referencialData?.STUDENT_RESUME_COMPUTER_SKILL}
                  dataKey="key"
                  textField="label"
                  id={computerSkill?.key}
                  value={values[computerSkill?.key]}
                  onChange={(newValue) => setFieldValue(computerSkill?.key, newValue)}
                />
              </div>
              <div className={`${styles.formGroup} input-like-rw`}>
                <label htmlFor="software">{t("app.librairyCV.software")}</label>

                <input
                  value={values.software}
                  type="text"
                  name="software"
                  id="software"
                  onChange={(e) => setFieldValue("software", e.target.value.trim())}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnFilter}>
          <SubmitFormBtn
            text={t("app.allOffers.btnSubmitFilter")}
            type="buttom"
            onClick={() => {
              setFilter(values);
            }}
          />
        </div>

        <div className={styles.filterResult}>
          <div className={styles.divline_h2_container}>
            <DivLine />
            <h2>{t("app.librairyCV.listTalent")}</h2>
          </div>

          <div className={styles.containerResult}>
            <div className={styles.containerResult__head}>
              <div className={styles.containerResult__head__title}>{t("app.librairyCV.identifant")}</div>
              <div className={styles.containerResult__head__title}>{t("app.librairyCV.contractType")}</div>
              <div className={styles.containerResult__head__title}>{t("app.librairyCV.educationLevel")}</div>
              <div className={styles.containerResult__head__title}>{t("app.librairyCV.sector")}</div>
              <div className={styles.containerResult__head__title}>{t("app.librairyCV.mobility")}</div>
            </div>

            <div className={styles.allResults}>
              {!isCompleteProfile && (
                <>
                  {listCV?.map((cv) => (
                    <div className={styles.result} key={nanoid()}>
                      <div className={styles.result__body__id}>{cv?.name}</div>
                      <div className={styles.result__body}>
                        {cv?.search?.experienceType?.label}

                        {cv?.search?.contractTypes &&
                          cv?.search?.contractTypes.map((item) => <span key={nanoid()}>{item.label}</span>)}
                      </div>
                      <div className={styles.result__body}>{cv?.profile?.educationLevel?.label}</div>
                      <div className={styles.result__body}>{cv?.search?.speciality?.label}</div>
                      <div className={styles.result__body}>{cv?.mobility?.mobility?.label}</div>
                      <div className={styles.result__body__link}>
                        <Link to={`/details/${cv?.id}`} className={styles.seeMore}>
                          {t("app.librairyCV.btnSeeMore")}
                        </Link>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={styles.arrowPagination}>
            <button
              disabled={currentPagination <= 1}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    previousCVPaginationThunk({
                      query: `${generateParams(filter)}`,
                      axiosCancelToken,
                    }),
                  );
                }
              }}
              className={styles.arrowLeft}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
            <span className={styles.totalPage}>PAGE</span>
            {currentPagination}/{maxPagination}
            <button
              disabled={currentPagination >= maxPagination}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    nextCVPaginationThunk({
                      query: `${generateParams(filter)}`,
                      axiosCancelToken,
                    }),
                  );
                }
              }}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
