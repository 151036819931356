import { t } from "i18next";

import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import {
  getLearningBookletsIdData,
  getLearningBookletsErrorMessage,
  getLearningBookletsListData,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import Header from "components/Header/Header";
import BackButton from "../components/BackButton/BackButton";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../SelectedBooklet.module.scss";

const StaticEndTermEvalFirstYear = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const listData = useSelector(getLearningBookletsListData);

  const idData = useSelector(getLearningBookletsIdData);
  const errorMessage = useSelector(getLearningBookletsErrorMessage);

  const referentials = useSelector(getReferentialsData);

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  console.log("BOOKLET DATA", idData, listData);

  /* eslint-disable */
  return (
    <>
      {referentials && (
        <>
          <Header
            h1={t("app.formulairesLivretApprentissage.Suivez")}
            textElement={
              <div className={styles.headerContent}>
                <p>
                  {t("app.formulairesLivretApprentissage.headerPart1")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart2")}
                  <br />
                  {t("app.formulairesLivretApprentissage.headerPart3")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart4")}
                  <br />
                  {t("app.formulairesLivretApprentissage.headerPart5")}
                  <br /> <br />
                  {t("app.formulairesLivretApprentissage.headerPart6")}
                  <br />
                  <ul>
                    <li>{t("app.formulairesLivretApprentissage.headerLi1")}</li>
                    <li>{t("app.formulairesLivretApprentissage.headerLi2")}</li>
                    <li> {t("app.formulairesLivretApprentissage.headerLi3")}</li>
                    <li>{t("app.formulairesLivretApprentissage.headerLi4")}</li>
                  </ul>
                </p>
              </div>
            }
            bgImg={bgConsulOffreImg}
          />

          <section className={styles.main_container}>
            <div className={styles.form_container}>
              <div className={styles.container}>
                <div className={styles.title_container}>
                  <h2>{t("app.formulairesLivretApprentissage.titleM1Final")}</h2>
                </div>
                <form className={styles.the_form}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                  <div className={styles.inputs_container}>
                    <h3>{t("app.formulairesLivretApprentissage.CompetencesPerso")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.InsertionDesc")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.Insertion")}</p>
                      <p className={styles.p_text_value}>{idData?.insertion?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Equipe")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.EquipeDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.teamSpirit?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Autonomie")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.AutonomieDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.autonomy?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Initiative")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.InitiativeDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.initiative?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="normsAndRegulations" className={styles.label_text}>
                        {t("app.formulairesLivretApprentissage.Respect")}
                      </label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.RespectDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.standardAndRegulationRespect?.label}</p>
                    </div>
                    <div className={styles.div_line} />

                    <h3>{t("app.formulairesLivretApprentissage.CompetencePro")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Planification")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.PlanificationDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.planificationOrganisation?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Controle")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.ControleDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.autoControl?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Reporting")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.ReportingDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.reporting?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Comprehension")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.ComprehensionDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.companyUnderstanding?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Communication")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.QualiteCommDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.communicationSkills?.label}</p>
                    </div>
                    <div className={styles.div_line} />

                    <h3>{t("app.formulairesLivretApprentissage.CompetenceTech")}</h3>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Connaissances")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.ConnaissancesDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.knowledgeAcquisition?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.OrgaPerso")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.OrgaPersoDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.personalOrganizationWork?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Outils")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.OutilsDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.toolsAndMethodsMastery?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Delais")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.DelaisDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.deadlinesRespect?.label}</p>
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label>{t("app.formulairesLivretApprentissage.Methodes")}</label>
                      <p className={styles.p_text}>{t("app.formulairesLivretApprentissage.MethodesDesc")}</p>
                      <p className={styles.p_text_value}>{idData?.methodMastery?.label}</p>
                    </div>
                  </div>
                  <BackButton text={t("app.formulairesLivretApprentissage.BoutonRetour")} />
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default StaticEndTermEvalFirstYear;
