import { t } from "i18next";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import styles from "./DocumentsToDownload.module.scss";
import { getFileTypeDownload } from "./GetFileTypeDownload";

/* eslint-disable */
const DocumentsToDownload = ({
  fileType,
  href,
  download,
  fileTitle,
  onClick,
  spanErrorMessage,
  supportingDocument,
}) => {
  return (
    <div className={styles.label_plus_input_container}>
      <div className={styles.custom_input_container}>
        <div
          className={styles.disabled_div_upload}
          style={{
            display: `${supportingDocument !== undefined ? "none" : "block"}`,
          }}
        ></div>
        <label className={styles.input_label}>
          <img className={styles.img_file} src={getFileTypeDownload(fileType)} alt={fileType} />
          <span className={styles.uploadFile} onClick={() => onClick()}>
            {fileTitle}
            <div className={styles.buttonDownload_container}>
              <a href={href} download={download} className={styles.buttonDownload_container}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9229 9.55394H13.9998C13.7537 9.55394 13.5383 9.80009 13.5383 10.0155V13.0924C13.5383 13.3386 13.3229 13.5539 13.0768 13.5539H2.92293C2.67677 13.5539 2.46139 13.3386 2.46139 13.0924V10.0155C2.46139 9.80009 2.246 9.55394 1.99985 9.55394H1.07677C0.830619 9.55394 0.615234 9.80009 0.615234 10.0155V14.1693C0.615234 14.8462 1.16908 15.4001 1.846 15.4001H14.1537C14.8306 15.4001 15.3845 14.8462 15.3845 14.1693V10.0155C15.3845 9.80009 15.1691 9.55394 14.9229 9.55394ZM8.30754 0.75394C8.12293 0.569325 7.846 0.569325 7.66139 0.75394L3.50754 4.90779C3.32293 5.0924 3.32293 5.36932 3.50754 5.55394L4.1537 6.20009C4.33831 6.38471 4.61523 6.38471 4.79985 6.20009L6.52293 4.47702C6.70754 4.2924 7.07677 4.41548 7.07677 4.6924V11.2155C7.07677 11.4616 7.26139 11.677 7.50754 11.677H8.43062C8.67677 11.677 8.92293 11.4309 8.92293 11.2155V4.72317C8.92293 4.44625 9.23062 4.32317 9.446 4.50779L11.1691 6.23086C11.3537 6.41548 11.6306 6.41548 11.8152 6.23086L12.4614 5.58471C12.646 5.40009 12.646 5.12317 12.4614 4.93856L8.30754 0.75394Z"
                    fill="#3E0056"
                  />
                </svg>
                <h5>{t("app.visualisationCv.Telecharger")}</h5>
              </a>
            </div>
          </span>
        </label>
      </div>
      <InputErrorMessage message={spanErrorMessage ? t("app.errorMessages.FichierRequis") : ""} touched={true} />
    </div>
  );
};
export default DocumentsToDownload;
