import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm2Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm2/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm2Slice = createSlice({
  name: "checkYourRecruitmentsForm2",
  initialState,
  reducers: {
    handleRecruitmentPropsForm2Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm2Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm2Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm2Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm2Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm2Data, handleRecruitmentSectionsForm2Data } =
  checkYourRecruitmentsForm2Slice.actions;

export const getYourRecruitmentPropsForm2Data = (state) => state.checkYourRecruitmentsForm2.recruitmentPropsForm2Data;
export const getAllUserRecruitmentsForm2 = (state) => state.checkYourRecruitmentsForm2.recruitmentList;
export const getUserRecruitmentsPendingForm2 = (state) => state.checkYourRecruitmentsForm2.pending;
export const getUserRecruitmentsErrorMessageForm2 = (state) => state.checkYourRecruitmentsForm2.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm2Slice.reducer;
