import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, getLanguage } from "app/slices/language/language.slice";
import i18n from "../../services/internationalisation/config";

import flagFR from "./assets/flagFR.jpg";
import flagUS from "./assets/flagUS.jpeg";
import arrowImg from "./assets/flagArrow.png";

import styles from "./DropDownLanguage.module.scss";

/* eslint-disable */
const DropDownLanguage = () => {
  const dispatch = useDispatch();
  // I keep this language, setLanguage for the moment but the condition is now managed by languageCondition
  const [language, setLanguage] = useState("fr");
  const [displayLanguageDropdown, setDisplayLanguageDropdown] = useState(false);
  const languageSelector = useSelector(getLanguage);
  const languageCondition = languageSelector;

  const handleChangeLanguage = (choosenLanguage) => {
    setDisplayLanguageDropdown(false);
    setLanguage(choosenLanguage);
    i18n.changeLanguage(choosenLanguage);
    localStorage.setItem("lang", choosenLanguage);
    dispatch(changeLanguage(choosenLanguage));
  };

  return (
    <div className={styles.main_container} style={{ display: "flex", alignItems: "center" }}>
      {languageCondition === "fr" ? (
        <img
          src={flagFR}
          alt="flag"
          style={{ paddingRight: "1rem" }}
          onClick={() => setDisplayLanguageDropdown((value) => !value)}
        />
      ) : (
        <img
          src={flagUS}
          alt="flag"
          style={{ paddingRight: "1rem" }}
          onClick={() => setDisplayLanguageDropdown((value) => !value)}
        />
      )}
      <div className={styles.arrow_container} onClick={() => setDisplayLanguageDropdown((value) => !value)}>
        <img src={arrowImg} alt="arrow" />
      </div>
      {languageCondition === "fr" ? (
        <div
          className={styles.signout_dropdown}
          style={{ display: `${displayLanguageDropdown ? "block" : "none"}` }}
          onClick={() => handleChangeLanguage("en")}
        >
          Anglais
        </div>
      ) : (
        <div
          className={styles.signout_dropdown}
          style={{ display: `${displayLanguageDropdown ? "block" : "none"}` }}
          onClick={() => handleChangeLanguage("fr")}
        >
          French
        </div>
      )}
    </div>
  );
};

export default DropDownLanguage;
