import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  offersList: [],
  offerCardProps: null,
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getConpanyOffersCardsListThunk = createAsyncThunk(
  "checkYourOffers/getCompanyOffersCardsList",
  async ({ axiosCancelToken }) => {
    const response = await axiosCompany().get("/v2/companies/job-offers", {
      cancelToken: axiosCancelToken.token,
    });
    console.log("ALL OFFER RESPONSE", response);
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourOffersSlice = createSlice({
  name: "checkYourOffers",
  initialState,
  reducers: {
    handleOfferCardProps: (state, action) => {
      state.offerCardProps = action.payload;
    },
  },
  extraReducers: {
    [getConpanyOffersCardsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getConpanyOffersCardsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.offersList = action.payload;
    },
    [getConpanyOffersCardsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleOfferCardProps } = checkYourOffersSlice.actions;

export const getYourOfferCardProps = (state) => state.checkYourOffers.offerCardProps;
export const getAllUserOffersCards = (state) => state.checkYourOffers.offersList;
export const getUserOffersCardsPending = (state) => state.checkYourOffers.pending;
export const getUserOffersCardsErrorMessage = (state) => state.checkYourOffers.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourOffersSlice.reducer;
