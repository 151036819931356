import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { DropdownList } from "react-widgets/cjs";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";

import {
  getEmployeesThunk,
  getEmployeeDetails,
  getCompanyEmployees,
  getEmployeeDetails2,
  getEmployeesThunk2,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import { getMeData } from "app/slices/userConnexion/userConnexion.slice";
import { formatDate } from "utils/formatDate";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { createBooleanReferential, createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form6.module.scss";

/* eslint-disable */
const Form6 = (props) => {
  const dispatch = useDispatch();

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const employeeDetails = useSelector(getEmployeeDetails);
  const companyEmployees = useSelector(getCompanyEmployees);
  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  const myInfo = useSelector(getMeData);
  const FORM = "FORM_6";

  const [formStep, setFormStep] = useState("");

  const [generalDisable, setGeneralDisable] = useState(false);
  const [mailDisable, setMailDisable] = useState(false);
  const [mailWasFound, setMailWasFound] = useState(false);
  const [fromTakeInfo, setFromTakeInfo] = useState(false);
  const [justMounted, setJustMounted] = useState(false);
  const [dataWasChange, setDataWasChange] = useState(false);
  const [phoneAndFunctionDisable, setPhoneAndFunctionDisable] = useState(false);
  const [birthDateDisable, setBirthDateDisable] = useState(false);

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);

  const [checkboxSecondTutor, setCheckboxSecondTutor] = useState(false);
  const [generalDisable2, setGeneralDisable2] = useState(false);
  const [mailDisable2, setMailDisable2] = useState(false);
  const [mailWasFound2, setMailWasFound2] = useState(false);
  const [fromTakeInfo2, setFromTakeInfo2] = useState(false);
  // const [justMounted, setJustMounted] = useState(false);
  const [dataWasChange2, setDataWasChange2] = useState(false);
  const [phoneAndFunctionDisable2, setPhoneAndFunctionDisable2] = useState(false);
  const [birthDateDisable2, setBirthDateDisable2] = useState(false);
  const [formikErrorJustMount2, setFormikErrorJustMount2] = useState(true);

  const employeeDetails2 = useSelector(getEmployeeDetails2);

  const timedOutRef = useRef(null);
  const timedOutRef2 = useRef(null);

  const [enableCivilityTutor1IfEmpty, setEnableCivilityTutor1IfEmpty] = useState(false);
  const [enableCivilityTutor2IfEmpty, setEnableCivilityTutor2IfEmpty] = useState(false);

  const handleResetStatesIfTutor1EmptyValue = () => {
    setEnableCivilityTutor1IfEmpty(false);
  };
  const handleResetStatesIfTutor2EmptyValue = () => {
    setEnableCivilityTutor2IfEmpty(false);
  };

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    if (values) handleEnableOnMount(recruitmentPropsGeneral?.company?.contacts?.tutors[0]);
    handleEnableOnMount2(recruitmentPropsGeneral?.company?.contacts?.tutors[1]);
  }, []);

  useEffect(() => {
    if (employeeDetails) {
      if (employeeDetails.length > 0 && formStep === FORM) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (employeeDetails2) {
      if (employeeDetails2.length > 0 && formStep === FORM) {
        displayUserData2();
      } else displayDefaultData2();
    }
  }, [employeeDetails2]);

  useEffect(() => {
    if (recruitmentPropsGeneral) {
      if (recruitmentPropsGeneral?.company?.contacts?.tutors[1]) {
        setCheckboxSecondTutor(true);
      }
    }
  }, [recruitmentPropsGeneral]);

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus = () => {
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus2 = () => {
    setFormikErrorJustMount2(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  // Yup validation schema
  const Form6Schema = Yup.object().shape({
    takeInfoFrom: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    email: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.email(t("app.errorMessages.EmailInvalid")).required(t("app.errorMessages.RemplirChamp")),
    }),
    fixPhone: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema
          .min(2)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    civility: Yup.object().when(["takeInfoFrom", "mandatoryField"], {
      is: (takeInfoFrom, mandatoryField) => takeInfoFrom?.key === "Aucun" && mandatoryField,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    birthDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required().nullable(),
    validMa: Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
    highestDegree: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    takeInfoFrom2:
      checkboxSecondTutor &&
      Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    email2:
      checkboxSecondTutor &&
      Yup.string().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) =>
          schema.email(t("app.errorMessages.EmailInvalid")).required(t("app.errorMessages.RemplirChamp")),
      }),
    fixPhone2:
      checkboxSecondTutor &&
      Yup.string().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) =>
          schema
            .test(
              "if-is-aucun",
              t("app.errorMessages.PhoneFormat"),
              (value) => value !== undefined && isValidPhoneNumber(value) !== false,
            )
            .required(t("app.errorMessages.RemplirChamp")),
      }),
    phone2: checkboxSecondTutor && Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName2:
      checkboxSecondTutor &&
      Yup.string().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
      }),
    firstName2:
      checkboxSecondTutor &&
      Yup.string().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
      }),
    function2:
      checkboxSecondTutor &&
      Yup.string().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) =>
          schema
            .min(2)
            .required(t("app.errorMessages.RemplirChamp"))
            .max(40, `${t("app.errorMessages.Max40")}`),
      }),
    civility2:
      checkboxSecondTutor &&
      Yup.object().when(["takeInfoFrom2", "mandatoryField2"], {
        is: (takeInfoFrom2, mandatoryField2) => takeInfoFrom2?.key === "Aucun" && mandatoryField2,
        then: (schema) =>
          schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
      }),
    birthDate2:
      checkboxSecondTutor && Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required().nullable(),
    validMa2:
      checkboxSecondTutor &&
      Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
    highestDegree2:
      checkboxSecondTutor &&
      Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
  });

  const onSubmit = (values, actions) => {
    if (!formikErrorJustMount && !formikErrorJustMount2) {
      console.log("CONTRACT VALUE TO SEND 1");

      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      const dataToSend = {
        status: "En cours",
        company: {
          contacts: {
            tutors: [],
          },
        },
      };

      if (valuesCopy?.takeInfoFrom?.key === "Aucun") {
        dataToSend.company.contacts.tutors[0] = {
          copyFrom: valuesCopy?.takeInfoFrom?.key,
          email: valuesCopy.email,
          civility: valuesCopy?.civility?.key,
          name: valuesCopy.lastName,
          firstname: valuesCopy.firstName,
          phones: {
            office: valuesCopy.fixPhone,
            cellular: valuesCopy.phone,
          },
          birthdate: formatDate(valuesCopy.birthDate),
          function: valuesCopy.function,
          validatedBySkillsOperator: valuesCopy.validMa,
          maximalObtainedDiploma: valuesCopy.highestDegree.key,
        };
      } else if (valuesCopy?.takeInfoFrom?.key !== "Aucun" && valuesCopy?.takeInfoFrom?.key !== "-Aucun-") {
        dataToSend.company.contacts.tutors[0] = {
          copyFrom: valuesCopy?.takeInfoFrom?.key,
          validatedBySkillsOperator: valuesCopy.validMa,
          birthdate: formatDate(valuesCopy.birthDate),
          maximalObtainedDiploma: valuesCopy.highestDegree.key,
        };
      }

      // Second Tutor logic
      if (valuesCopy?.takeInfoFrom2?.key === "Aucun" && checkboxSecondTutor) {
        dataToSend.company.contacts.tutors[1] = {
          copyFrom: valuesCopy?.takeInfoFrom2?.key,
          email: valuesCopy.email2,
          civility: valuesCopy?.civility2?.key,
          name: valuesCopy.lastName2,
          firstname: valuesCopy.firstName2,
          phones: {
            office: valuesCopy.fixPhone2,
            cellular: valuesCopy.phone2,
          },
          birthdate: formatDate(valuesCopy.birthDate2),
          function: valuesCopy.function2,
          validatedBySkillsOperator: valuesCopy.validMa2,
          maximalObtainedDiploma: valuesCopy.highestDegree2.key,
        };
      } else if (
        valuesCopy?.takeInfoFrom2?.key !== "Aucun" &&
        valuesCopy?.takeInfoFrom2?.key !== "-Aucun-" &&
        checkboxSecondTutor
      ) {
        dataToSend.company.contacts.tutors[1] = {
          copyFrom: valuesCopy?.takeInfoFrom2?.key,
          validatedBySkillsOperator: valuesCopy.validMa2,
          birthdate: formatDate(valuesCopy.birthDate2),
          maximalObtainedDiploma: valuesCopy.highestDegree2.key,
        };
      }

      props.submitSection(dataToSend, 6);
    } else {
      handleMountPingStatus();
      handleMountPingStatus2();
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      takeInfoFrom: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.copyFrom
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.copyFrom
        : { ...objAucun },
      email: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.email
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.email
        : "",
      civility: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.civility
        : { ...objAucun },

      birthDate: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate
        ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate)
        : null,
      validMa: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.validatedBySkillsOperator
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.validatedBySkillsOperator
        : false,
      lastName: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.name
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.name
        : "",
      firstName: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.firstname
        : "",
      phone: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.cellular
        : "",
      fixPhone: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.office
        : "",
      function: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.function
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.function
        : "",
      mandatoryField: false,
      specificMandatory: false,
      highestDegree: recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.maximalObtainedDiploma
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.maximalObtainedDiploma
        : { ...objAucun },
      takeInfoFrom2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.copyFrom
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.copyFrom
        : { ...objAucun },
      email2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.email
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.email
        : "",
      civility2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.civility
        : { ...objAucun },

      birthDate2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate
        ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate)
        : null,
      validMa2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.validatedBySkillsOperator
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.validatedBySkillsOperator
        : false,
      lastName2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.name
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.name
        : "",
      firstName2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.firstname
        : "",
      phone2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.cellular
        : "",
      fixPhone2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.office
        : "",
      function2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.function
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.function
        : "",
      mandatoryField2: false,
      specificMandatory2: false,
      highestDegree2: recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.maximalObtainedDiploma
        ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.maximalObtainedDiploma
        : { ...objAucun },
    },
    validationSchema: Form6Schema,
    onSubmit,
  });

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
    setInfoFrom2(values.takeInfoFrom2, true);
  }, [recruitmentPropsGeneral]);

  useEffect(() => {
    console.log("errors amar", errors);
    if (formikErrorJustMount) onSubmit();
    if (formikErrorJustMount2) onSubmit();
  }, [errors]);

  const handleBirthDateUpdt = (update, contactType) => {
    if (update) {
      return recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate
        ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate)
        : null;
    } else {
      switch (contactType) {
        case "SIGN":
          return recruitmentPropsGeneral?.company?.contacts?.signatory?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.signatory?.birthdate)
            : null;
          break;
        case "TAXE":
          return recruitmentPropsGeneral?.company?.contacts?.taxes?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.taxes?.birthdate)
            : null;
          break;
        case "DRAFT":
          return recruitmentPropsGeneral?.company?.taxes?.contractDrafter?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.birthdate)
            : null;
          break;
        case "ADMIN":
          return recruitmentPropsGeneral?.company?.taxes?.administrative?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.administrative?.birthdate)
            : null;
          break;
        case "BILL":
          return recruitmentPropsGeneral?.company?.taxes?.billing?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.billing?.birthdate)
            : null;
          break;
        default:
          return null;
          break;
      }
    }
  };

  const handleBirthDateUpdt2 = (update, contactType) => {
    if (update) {
      return recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate
        ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate)
        : null;
    } else {
      switch (contactType) {
        case "SIGN":
          return recruitmentPropsGeneral?.company?.contacts?.signatory?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.signatory?.birthdate)
            : null;
          break;
        case "TAXE":
          return recruitmentPropsGeneral?.company?.contacts?.taxes?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.taxes?.birthdate)
            : null;
          break;
        case "DRAFT":
          return recruitmentPropsGeneral?.company?.taxes?.contractDrafter?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.birthdate)
            : null;
          break;
        case "ADMIN":
          return recruitmentPropsGeneral?.company?.taxes?.administrative?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.administrative?.birthdate)
            : null;
          break;
        case "BILL":
          return recruitmentPropsGeneral?.company?.taxes?.billing?.birthdate
            ? new Date(recruitmentPropsGeneral?.company?.contacts?.billing?.birthdate)
            : null;
          break;
        default:
          return null;
          break;
      }
    }
  };

  const setInfoFrom = (value, update) => {
    console.log("SET INFO", value);
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue("fixPhone", recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office);
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "SIGN"));
        setGeneralDisable(true);
        setMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        setPhoneAndFunctionDisable(true);
        setBirthDateDisable(false);
        break;
      case "Contact taxe d'apprentissage":
        setFieldValue(
          "email",
          recruitmentPropsGeneral.company?.contacts?.taxes?.email
            ? recruitmentPropsGeneral.company?.contacts?.taxes?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "TAXE"));
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        setBirthDateDisable(false);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "DRAFT"));
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        setBirthDateDisable(false);
        break;
      case "Contact administratif":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "ADMIN"));
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setMailDisable(true);
        setFromTakeInfo(true);
        setFieldValue("mandatoryField", false);
        setBirthDateDisable(false);
        break;
      case "Contact facturation":
        setFieldValue(
          "email",
          recruitmentPropsGeneral?.company?.contacts?.billing?.email
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
            : "",
        );
        setFieldValue(
          "civility",
          recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          recruitmentPropsGeneral?.company?.contacts?.billing?.name
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
            : "",
        );
        setFieldValue(
          "firstName",
          recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          recruitmentPropsGeneral?.company?.contacts?.billing?.function
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "BILL"));
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setMailDisable(true);
        setFromTakeInfo(true);
        setBirthDateDisable(false);
        setFieldValue("mandatoryField", false);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email", "");
          setFieldValue("civility", { ...objAucun });
          setFieldValue("lastName", "");
          setFieldValue("firstName", "");
          setFieldValue("phone", "");
          setFieldValue("fixPhone", "");
          setFieldValue("function", "");
          setFieldValue("birthDate", null);
          setGeneralDisable(false);
          setMailDisable(false);
          setFromTakeInfo(false);
          setFieldValue("mandatoryField", true);

          // TODO   ->   handle user from other company for phone & function
        } else {
          setFieldValue(
            "email",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.email
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.email
              : "",
          );
          setFieldValue(
            "civility",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.name
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.name
              : "",
          );
          setFieldValue(
            "firstName",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.function
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.function
              : "",
          );
          setFieldValue(
            "birthDate",
            recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate
              ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[0]?.birthdate)
              : null,
          );
          let tmpGenDisable = generalDisable;
          let tmpFromTake = fromTakeInfo;
          let tmpMandatory = values.mandatoryField;
          setGeneralDisable(tmpGenDisable);
          setMailDisable(false);
          setFromTakeInfo(tmpFromTake);
          setFieldValue("mandatoryField", tmpMandatory);

          // TODO   ->   handle user from other company for phone & function
        }

        setBirthDateDisable(true);
        setGeneralDisable(true);
        break;
      case "-Aucun-":
        setFieldValue("email", "");
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("phone", "");
        setFieldValue("fixPhone", "");
        setFieldValue("function", "");
        setFieldValue("birthDate", null);
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setBirthDateDisable(true);
        setMailDisable(false);
        setFromTakeInfo(false);
        setFieldValue("mandatoryField", true);
        break;
      default:
        break;
    }
  };

  const setInfoFrom2 = (value, update) => {
    console.log("SET INFO 2", value);
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.email
            : "",
        );
        setFieldValue(
          "civility2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue("fixPhone2", recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office);
        setFieldValue(
          "function2",
          recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            ? recruitmentPropsGeneral?.company?.contacts?.signatory?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "SIGN"));
        setGeneralDisable2(true);
        setMailDisable2(true);
        setFromTakeInfo2(true);
        setFieldValue("mandatoryField2", false);
        setPhoneAndFunctionDisable2(true);
        setBirthDateDisable2(false);
        break;
      case "Contact taxe d'apprentissage":
        setFieldValue(
          "email2",
          recruitmentPropsGeneral.company?.contacts?.taxes?.email
            ? recruitmentPropsGeneral.company?.contacts?.taxes?.email
            : "",
        );
        setFieldValue(
          "civility2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            ? recruitmentPropsGeneral?.company?.contacts?.taxes?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "TAXE"));
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setMailDisable2(true);
        setFromTakeInfo2(true);
        setFieldValue("mandatoryField2", false);
        setBirthDateDisable2(false);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            ? recruitmentPropsGeneral?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "DRAFT"));
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setMailDisable2(true);
        setFromTakeInfo2(true);
        setFieldValue("mandatoryField2", false);
        setBirthDateDisable2(false);
        break;
      case "Contact administratif":
        setFieldValue(
          "email2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            ? recruitmentPropsGeneral?.company?.contacts?.administrative?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "ADMIN"));
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setMailDisable2(true);
        setFromTakeInfo2(true);
        setFieldValue("mandatoryField2", false);
        setBirthDateDisable2(false);
        break;
      case "Contact facturation":
        setFieldValue(
          "email2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.email
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.email
            : "",
        );
        setFieldValue(
          "civility2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.name
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          recruitmentPropsGeneral?.company?.contacts?.billing?.function
            ? recruitmentPropsGeneral?.company?.contacts?.billing?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "BILL"));
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setMailDisable2(true);
        setFromTakeInfo2(true);
        setBirthDateDisable2(false);
        setFieldValue("mandatoryField2", false);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email2", "");
          setFieldValue("civility2", { ...objAucun });
          setFieldValue("lastName2", "");
          setFieldValue("firstName2", "");
          setFieldValue("phone2", "");
          setFieldValue("fixPhone2", "");
          setFieldValue("function2", "");
          setFieldValue("birthDate2", null);
          setGeneralDisable2(false);
          setMailDisable2(false);
          setFromTakeInfo2(false);
          setFieldValue("mandatoryField2", true);

          // TODO   ->   handle user from other company for phone & function
        } else {
          setFieldValue(
            "email2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.email
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.email
              : "",
          );
          setFieldValue(
            "civility2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.civility
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.name
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.name
              : "",
          );
          setFieldValue(
            "firstName2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.firstname
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.firstname
              : "",
          );
          setFieldValue(
            "phone2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.cellular
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.office
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.phones?.office
              : "",
          );
          setFieldValue(
            "function2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.function
              ? recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.function
              : "",
          );
          setFieldValue(
            "birthDate2",
            recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate
              ? new Date(recruitmentPropsGeneral?.company?.contacts?.tutors[1]?.birthdate)
              : null,
          );
          let tmpGenDisable = generalDisable2;
          let tmpFromTake = fromTakeInfo2;
          let tmpMandatory = values.mandatoryField2;
          setGeneralDisable2(tmpGenDisable);
          setMailDisable2(false);
          setFromTakeInfo2(tmpFromTake);
          setFieldValue("mandatoryField2", tmpMandatory);

          // TODO   ->   handle user from other company for phone & function
        }

        setBirthDateDisable2(true);
        setGeneralDisable2(true);
        break;
      case "-Aucun-":
        setFieldValue("email2", "");
        setFieldValue("civility2", { ...objAucun });
        setFieldValue("lastName2", "");
        setFieldValue("firstName2", "");
        setFieldValue("phone2", "");
        setFieldValue("fixPhone2", "");
        setFieldValue("function2", "");
        setFieldValue("birthDate2", null);
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setBirthDateDisable2(true);
        setMailDisable2(false);
        setFromTakeInfo2(false);
        setFieldValue("mandatoryField2", true);
        break;
      default:
        break;
    }
  };

  const displayUserData = () => {
    if (FORM === formStep) {
      setFieldValue("lastName", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstName", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civility", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phone", employeeDetails[0]?.phones?.cellular ? employeeDetails[0]?.phones?.cellular : "");
      setFieldValue("function", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");
      setFieldValue("birthDate", employeeDetails[0]?.birthdate ? new Date(employeeDetails[0]?.birthdate) : null);

      if (employeeDetails[0]?.company?.id === myInfo?.companyId) {
        setGeneralDisable(false);
        setFieldValue("mandatoryField", true);
        setPhoneAndFunctionDisable(false);
      } else {
        setGeneralDisable(true);
        setFieldValue("mandatoryField", false);
        setPhoneAndFunctionDisable(false);
      }

      setMailWasFound(true);
      setFormStep("");
    }
  };

  const displayUserData2 = () => {
    if (FORM === formStep) {
      setFieldValue("lastName2", employeeDetails2[0]?.name ? employeeDetails2[0]?.name : "");
      setFieldValue("firstName2", employeeDetails2[0]?.firstname ? employeeDetails2[0]?.firstname : "");
      setFieldValue("civility2", employeeDetails2[0]?.civility ? employeeDetails2[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone2", employeeDetails2[0]?.phones?.office ? employeeDetails2[0]?.phones?.office : "");
      setFieldValue("phone2", employeeDetails2[0]?.phones?.cellular ? employeeDetails2[0]?.phones?.cellular : "");
      setFieldValue("function2", employeeDetails2[0]?.function ? employeeDetails2[0]?.function : "");
      setFieldValue("birthDate2", employeeDetails2[0]?.birthdate ? new Date(employeeDetails2[0]?.birthdate) : null);

      if (employeeDetails2[0]?.company?.id === myInfo?.companyId) {
        setGeneralDisable2(false);
        setFieldValue("mandatoryField2", true);
        setPhoneAndFunctionDisable2(false);
      } else {
        setGeneralDisable2(true);
        setFieldValue("mandatoryField2", false);
        setPhoneAndFunctionDisable2(false);
      }

      setMailWasFound2(true);
      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    if (FORM === formStep) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setFieldValue("birthDate", null);
      setMailWasFound(false);
      setGeneralDisable(false);
      setPhoneAndFunctionDisable(false);
      // setPhoneAndFunctionDisable(true);
      setFormStep("");
      setFieldValue("mandatoryField", true);
    }
  };

  const displayDefaultData2 = () => {
    if (FORM === formStep) {
      setFieldValue("civility2", { ...objAucun });
      setFieldValue("lastName2", "");
      setFieldValue("firstName2", "");
      setFieldValue("fixPhone2", "");
      setFieldValue("phone2", "");
      setFieldValue("function2", "");
      setFieldValue("birthDate2", null);
      setMailWasFound2(false);
      setGeneralDisable2(false);
      setPhoneAndFunctionDisable2(false);
      // setPhoneAndFunctionDisable(true);
      setFormStep("");
      setFieldValue("mandatoryField2", true);
    }
  };

  useEffect(() => {
    if (values?.takeInfoFrom?.key === "-Aucun-" || values?.takeInfoFrom?.key === "Aucun") {
      if (values.email.length >= 1) {
        setGeneralDisable(false);
        setPhoneAndFunctionDisable(false);
        setBirthDateDisable(false);
      } else {
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setBirthDateDisable(true);
      }
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken: props.axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfTutor1EmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfTutor1EmptyValue();
              console.log("get fail Employees !", error.message);
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("fixPhone", "");
        setFieldValue("phone", "");
        setFieldValue("function", "");
        setFieldValue("birthDate", null);
        setFieldValue("mandatoryField", true);
        return;
      }
    }
  }, [errors.email, values.email]);

  useEffect(() => {
    if (values?.takeInfoFrom2?.key === "-Aucun-" || values?.takeInfoFrom2?.key === "Aucun") {
      if (values.email2.length >= 1) {
        setGeneralDisable2(false);
        setPhoneAndFunctionDisable2(false);
        setBirthDateDisable2(false);
      } else {
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setBirthDateDisable2(true);
      }
      if (!errors.email2 && values.email2.length > 1) {
        if (timedOutRef2.current) clearTimeout(timedOutRef2.current);
        timedOutRef2.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk2({
                  email: values.email2,
                  axiosCancelToken: props.axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfTutor2EmptyValue();
              console.log("get success Employees2 !");
            } catch (error) {
              handleResetStatesIfTutor2EmptyValue();
              console.log("get fail Employees2 !", error.message);
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility2", { ...objAucun });
        setFieldValue("lastName2", "");
        setFieldValue("firstName2", "");
        setFieldValue("fixPhone2", "");
        setFieldValue("phone2", "");
        setFieldValue("function2", "");
        setFieldValue("birthDate2", null);
        setFieldValue("mandatoryField2", true);
        return;
      }
    }
  }, [errors.email2, values.email2]);

  console.log("values?.takeInfoFrom", values?.takeInfoFrom);
  console.log("values?.takeInfoFrom2", values?.takeInfoFrom2);

  const handleEnableOnMount = (tutorContact) => {
    setJustMounted(true);
    if (values?.takeInfoFrom?.key === "Aucun" || values?.takeInfoFrom?.key === "-Aucun-") {
      setFromTakeInfo(false);
      setMailDisable(false);
      setFieldValue("mandatoryField", true);
      if (values.email.length >= 1) {
        setBirthDateDisable(false);
        if (tutorContact?.id === myInfo?.companyId) {
          setFieldValue("mandatoryField", true);
          setGeneralDisable(false);
          setPhoneAndFunctionDisable(false);
        } else {
          setFieldValue("mandatoryField", false);
          setGeneralDisable(true);
          setPhoneAndFunctionDisable(false);
        }
      } else {
        setGeneralDisable(true);
        setPhoneAndFunctionDisable(true);
        setBirthDateDisable(true);
      }
    } else if (values?.takeInfoFrom?.key !== "Aucun" || values?.takeInfoFrom?.key !== "-Aucun-") {
      setMailDisable(false);
      setGeneralDisable(false);
      setPhoneAndFunctionDisable(false);
      setBirthDateDisable(false);
      setFromTakeInfo(true);
      setFieldValue("mandatoryField", false);
    }
  };

  const handleEnableOnMount2 = (tutorContact) => {
    setJustMounted(true);
    if (values?.takeInfoFrom2?.key === "Aucun" || values?.takeInfoFrom2?.key === "-Aucun-") {
      setFromTakeInfo2(false);
      setMailDisable2(false);
      setFieldValue("mandatoryField2", true);
      if (values.email2.length >= 1) {
        setBirthDateDisable2(false);
        if (tutorContact?.id === myInfo?.companyId) {
          setFieldValue("mandatoryField2", true);
          setGeneralDisable2(false);
          setPhoneAndFunctionDisable2(false);
        } else {
          setFieldValue("mandatoryField2", false);
          setGeneralDisable2(true);
          setPhoneAndFunctionDisable2(false);
        }
      } else {
        setGeneralDisable2(true);
        setPhoneAndFunctionDisable2(true);
        setBirthDateDisable2(true);
      }
    } else if (values?.takeInfoFrom2?.key !== "Aucun" || values?.takeInfoFrom2?.key !== "-Aucun-") {
      setMailDisable2(false);
      setGeneralDisable2(false);
      setPhoneAndFunctionDisable2(false);
      setBirthDateDisable2(false);
      setFromTakeInfo2(true);
      setFieldValue("mandatoryField2", false);
    }
  };

  const validPhone = touched.phone && errors.phone;
  const validFixPhone = errors.fixPhone && touched.fixPhone;
  const validPhone2 = touched.phone2 && errors.phone2;
  const validFixPhone2 = errors.fixPhone2 && touched.fixPhone2;

  useEffect(() => {
    if (values?.takeInfoFrom?.key !== "-Aucun-" && values.email) {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityTutor1IfEmpty(true);
      }
    } else {
      handleResetStatesIfTutor1EmptyValue();
    }

    if (values?.takeInfoFrom2?.key !== "-Aucun-" && values.email2) {
      if (values?.civility2?.key === "-Aucun-") {
        setEnableCivilityTutor2IfEmpty(true);
      }
    } else {
      handleResetStatesIfTutor2EmptyValue();
    }
  }, [values]);

  if (props.formOpen) {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <p
                style={{
                  fontFamily: "Soleil-Leger",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "130%",
                  paddingBottom: "24px",
                  color: "#190038",
                  width: "300px",
                  marginTop: "22px",
                }}
              >
                {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage1")}
              </p>
              <label htmlFor="takeInfoFrom">{t("app.suivezVosContratsPartner.ReprendreInfo")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.takeInfoFrom &&
                      touched.takeInfoFrom && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="takeInfoFrom"
                dataKey="key"
                textField="label"
                disabled={false}
                data={[{ ...objAucun }, ...props?.referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_TUTOR_CONTACT]}
                value={values.takeInfoFrom}
                onChange={(e) => {
                  setInfoFrom(e, false);
                  setFieldValue("takeInfoFrom", e);
                }}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.takeInfoFrom} touched={touched.takeInfoFrom} />
              )}
            </div>
          </div>
          <p
            style={{
              fontFamily: "Soleil-Leger",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "130%",
              paddingBottom: "24px",
              color: "#190038",
            }}
          >
            {t("app.suivezVosContratsPartner.CoordonneesTitle")}
          </p>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="email">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.email &&
                      touched.email && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="email"
                type="email"
                disabled={mailDisable}
                value={values.email}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                  setFormStep(FORM);
                }}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.email} touched={touched.email} />}
              <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="fixPhone">{t("app.suivezVosContratsPartner.Telephone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.fixPhone &&
                      touched.fixPhone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="fixPhone"
                id="fixPhone"
                country={"FR"}
                disabled={phoneAndFunctionDisable}
                value={values.fixPhone}
                onChange={(e) => {
                  setFieldValue("fixPhone", e);
                }}
                className={!formikErrorJustMount ? `${validFixPhone && `${styles.errorInput}`}` : null}
              />
              {validFixPhone && !formikErrorJustMount && <span className={styles.error}>{errors.fixPhone}</span>}
            </div>

            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="phone">{t("app.suivezVosContratsPartner.Portable")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.phone &&
                      touched.phone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="phone"
                name="phone"
                type="text"
                disabled={phoneAndFunctionDisable}
                value={values.phone}
                onChange={(e) => setFieldValue("phone", e.target.value)}
                className={!formikErrorJustMount ? `${validPhone && `${styles.errorInput}`}` : null}
              />
              {validPhone && !formikErrorJustMount && <span className={styles.error}>{errors.phone}</span>}
            </div>
          </div>
          <p
            style={{
              fontFamily: "Soleil-Leger",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "130%",
              paddingBottom: "24px",
              color: "#190038",
            }}
          >
            {t("app.suivezVosContratsPartner.IdentiteTitle")}
          </p>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="lastName">{t("app.suivezVosContratsPartner.Nom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.lastName &&
                      touched.lastName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="lastName"
                type="text"
                disabled={generalDisable}
                value={values.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.lastName} touched={touched.lastName} />}
            </div>

            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="firstName">{t("app.suivezVosContratsPartner.Prenom")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.firstName &&
                      touched.firstName && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstName"
                type="text"
                disabled={generalDisable}
                value={values.firstName}
                onChange={(e) => setFieldValue("firstName", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.firstName} touched={touched.firstName} />}
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label className={styles.label}>{t("app.suivezVosContratsPartner.DateDeNaissance")}</label>
              <DatePickerCustom
                error={errors.birthDate}
                touched={touched.birthDate}
                formikErrorJustMount={formikErrorJustMount}
                disabled={birthDateDisable}
                id="birthDate"
                value={values.birthDate}
                onChange={(value) => setFieldValue("birthDate", value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.birthDate} touched={touched.birthDate} />}
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.formGroup}>
              <label htmlFor="civility">{t("app.suivezVosContratsPartner.Civilite")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.civility &&
                      touched.civility && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="civility"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                disabled={generalDisable && !enableCivilityTutor1IfEmpty && true}
                value={values.civility}
                onChange={(newValue) => setFieldValue("civility", newValue)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.civility} touched={touched.civility} />}
            </div>
          </div>

          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="function">{t("app.suivezVosContratsPartner.Fonction")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.function &&
                      touched.function && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="function"
                type="text"
                disabled={phoneAndFunctionDisable}
                value={values.function}
                onChange={(e) => setFieldValue("function", e.target.value)}
              />
              {!formikErrorJustMount && <InputErrorMessage message={errors.function} touched={touched.function} />}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="highestDegree">{t("app.suivezVosContratsPartner.HighestDegree")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount
                    ? errors.highestDegree &&
                      touched.highestDegree && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="highestDegree"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.APPRENTICESHIP_STUDENT_DIPLOMA]}
                // disabled={generalDisable}
                value={values.highestDegree}
                onChange={(newValue) => setFieldValue("highestDegree", newValue)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.highestDegree} touched={touched.highestDegree} />
              )}
            </div>
          </div>

          <div className={` ${styles.checkbox_container} input-like-rw`}>
            <label htmlFor="habilitationForm2">{t("app.suivezVosContratsPartner.Habilitation")}</label>
            <div>
              <input
                style={
                  !formikErrorJustMount
                    ? touched.validMa && values.validMa === false
                      ? {
                          outline: "2px solid #EA001E",
                          borderRadius: "50%",
                          outlineOffset: "-1.9px",
                        }
                      : null
                    : null
                }
                id="validMa"
                type="checkbox"
                disabled={false}
                checked={values.validMa}
                onChange={(e) => setFieldValue("validMa", e.target.checked)}
              />
              <p>{t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite")}</p>
            </div>
            {!formikErrorJustMount && <InputErrorMessage message={errors.validMa} touched={touched.validMa} />}
          </div>

          <div className={` ${styles.checkbox_container} input-like-rw`}>
            <label htmlFor="addSecondTutorCheckbox">{t("app.suivezVosContratsPartner.SecondTutorCheckbox")}</label>
            <div>
              <input
                id="addSecondTutorCheckbox"
                type="checkbox"
                disabled={false}
                checked={checkboxSecondTutor}
                onChange={(e) => setCheckboxSecondTutor(e.target.checked)}
              />
              {/* <p>
								{t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite")}
							</p> */}
            </div>
          </div>
          {checkboxSecondTutor && (
            <>
              <div className={styles.wrapper}>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <p
                    style={{
                      fontFamily: "Soleil-Leger",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "130%",
                      paddingBottom: "24px",
                      color: "#190038",
                      width: "300px",
                    }}
                  >
                    {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage2")}
                  </p>
                  <label htmlFor="takeInfoFrom2">{t("app.suivezVosContratsPartner.ReprendreInfo")}</label>
                  <DropdownList
                    style={
                      !formikErrorJustMount2
                        ? errors.takeInfoFrom2 &&
                          touched.takeInfoFrom2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="takeInfoFrom2"
                    dataKey="key"
                    textField="label"
                    disabled={false}
                    data={[{ ...objAucun }, ...props?.referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_TUTOR_CONTACT]}
                    value={values.takeInfoFrom2}
                    onChange={(e) => {
                      setInfoFrom2(e, false);
                      setFieldValue("takeInfoFrom2", e);
                    }}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.takeInfoFrom2} touched={touched.takeInfoFrom2} />
                  )}
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Soleil-Leger",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "130%",
                  paddingBottom: "24px",
                  color: "#190038",
                }}
              >
                {t("app.suivezVosContratsPartner.CoordonneesTitle")}
              </p>
              <div className={styles.wrapper}>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label htmlFor="email2">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? errors.email2 &&
                          touched.email2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="email2"
                    type="email"
                    disabled={mailDisable2}
                    value={values.email2}
                    onChange={(e) => {
                      setFieldValue("email2", e.target.value);
                      setFormStep(FORM);
                    }}
                  />
                  {!formikErrorJustMount2 && <InputErrorMessage message={errors.email2} touched={touched.email2} />}
                  <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
                </div>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label htmlFor="fixPhone2">{t("app.suivezVosContratsPartner.Telephone")}</label>
                  <PhoneInput
                    style={
                      !formikErrorJustMount2
                        ? errors.fixPhone2 &&
                          touched.fixPhone2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    type="text"
                    name="fixPhone2"
                    id="fixPhone2"
                    country={"FR"}
                    disabled={phoneAndFunctionDisable2}
                    value={values.fixPhone2}
                    onChange={(e) => {
                      setFieldValue("fixPhone2", e);
                    }}
                    className={!formikErrorJustMount2 ? `${validFixPhone2 && `${styles.errorInput}`}` : null}
                  />
                  {validFixPhone2 && !formikErrorJustMount2 && <span className={styles.error}>{errors.fixPhone2}</span>}
                </div>

                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="phone2">{t("app.suivezVosContratsPartner.Portable")}</label>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? errors.phone2 &&
                          touched.phone2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="phone2"
                    name="phone2"
                    type="text"
                    disabled={phoneAndFunctionDisable2}
                    value={values.phone2}
                    onChange={(e) => setFieldValue("phone2", e.target.value)}
                    className={!formikErrorJustMount2 ? `${validPhone2 && `${styles.errorInput}`}` : null}
                  />
                  {validPhone2 && !formikErrorJustMount2 && <span className={styles.error}>{errors.phone2}</span>}
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Soleil-Leger",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "130%",
                  paddingBottom: "24px",
                  color: "#190038",
                }}
              >
                {t("app.suivezVosContratsPartner.IdentiteTitle")}
              </p>
              <div className={styles.wrapper}>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label htmlFor="lastName2">{t("app.suivezVosContratsPartner.Nom")}</label>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? errors.lastName2 &&
                          touched.lastName2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="lastName2"
                    type="text"
                    disabled={generalDisable2}
                    value={values.lastName2}
                    onChange={(e) => setFieldValue("lastName2", e.target.value)}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.lastName2} touched={touched.lastName2} />
                  )}
                </div>

                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label htmlFor="firstName2">{t("app.suivezVosContratsPartner.Prenom")}</label>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? errors.firstName2 &&
                          touched.firstName2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="firstName2"
                    type="text"
                    disabled={generalDisable2}
                    value={values.firstName2}
                    onChange={(e) => setFieldValue("firstName2", e.target.value)}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.firstName2} touched={touched.firstName2} />
                  )}
                </div>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label className={styles.label}>{t("app.suivezVosContratsPartner.DateDeNaissance")}</label>
                  <DatePickerCustom
                    error={errors.birthDate2}
                    touched={touched.birthDate2}
                    formikErrorJustMount={formikErrorJustMount2}
                    disabled={birthDateDisable2}
                    id="birthDate2"
                    value={values.birthDate2}
                    onChange={(value) => setFieldValue("birthDate2", value)}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.birthDate2} touched={touched.birthDate2} />
                  )}
                </div>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.formGroup}>
                  <label htmlFor="civility2">{t("app.suivezVosContratsPartner.Civilite")}</label>
                  <DropdownList
                    style={
                      !formikErrorJustMount2
                        ? errors.civility2 &&
                          touched.civility2 && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    id="civility2"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                    disabled={generalDisable2 && !enableCivilityTutor2IfEmpty && true}
                    value={values.civility2}
                    onChange={(newValue) => setFieldValue("civility2", newValue)}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.civility2} touched={touched.civility2} />
                  )}
                </div>
              </div>

              <div className={styles.wrapper}>
                <div className={` ${styles.formGroup} input-like-rw`}>
                  <label htmlFor="function2">{t("app.suivezVosContratsPartner.Fonction")}</label>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? errors.function2 &&
                          touched.function2 && {
                            border: "2px solid #EA001E",
                          }
                        : null
                    }
                    id="function2"
                    type="text"
                    disabled={phoneAndFunctionDisable2}
                    value={values.function2}
                    onChange={(e) => setFieldValue("function2", e.target.value)}
                  />
                  {!formikErrorJustMount2 && (
                    <InputErrorMessage message={errors.function2} touched={touched.function2} />
                  )}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="highestDegree2">{t("app.suivezVosContratsPartner.HighestDegree")}</label>
                  <DropdownList
                    style={
                      !formikErrorJustMount
                        ? errors.highestDegree2 &&
                          touched.highestDegree2 && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    id="highestDegree2"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...props?.referencialData?.APPRENTICESHIP_STUDENT_DIPLOMA]}
                    // disabled={generalDisable2}
                    value={values.highestDegree2}
                    onChange={(newValue) => setFieldValue("highestDegree2", newValue)}
                  />
                  {!formikErrorJustMount && (
                    <InputErrorMessage message={errors.highestDegree2} touched={touched.highestDegree2} />
                  )}
                </div>
              </div>

              <div className={` ${styles.checkbox_container} input-like-rw`}>
                <label htmlFor="validMa2">{t("app.suivezVosContratsPartner.Habilitation")}</label>
                <div>
                  <input
                    style={
                      !formikErrorJustMount2
                        ? touched.validMa2 && values.validMa2 === false
                          ? {
                              outline: "2px solid #EA001E",
                              borderRadius: "50%",
                              outlineOffset: "-1.9px",
                            }
                          : null
                        : null
                    }
                    id="validMa2"
                    type="checkbox"
                    disabled={false}
                    checked={values.validMa2}
                    onChange={(e) => setFieldValue("validMa2", e.target.checked)}
                  />
                  <p>{t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite")}</p>
                </div>
                {!formikErrorJustMount2 && <InputErrorMessage message={errors.validMa2} touched={touched.validMa2} />}
              </div>
            </>
          )}

          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn onClick={handlePingStatus} text={t("app.visualisationOffre.ValidezLesModifications")} />
          </div>
        </form>
      </div>
    );
  }
};

export default Form6;
