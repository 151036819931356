import { t } from "i18next";

import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { putThunk, getLearningBookletsPending } from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { createObjAucun } from "utils/localReferentials";

import { DropdownList } from "react-widgets/cjs";

import Header from "components/Header/Header";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import styles from "../SelectedBooklet.module.scss";

const BilanInte = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const pending = useSelector(getLearningBookletsPending);

  const referentials = useSelector(getReferentialsData);

  const objAucun = createObjAucun();

  // This useEffect is only for cleanup function !!!
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  // Yup validation schema
  const formSchema = Yup.object().shape({
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
    teamIntegration: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    communication: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    workInTeam: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    informationSharing: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    understanding: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    performance: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    reliability: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    commitment: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    methodical: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    toolsMastery: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    punctuality: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    attendance: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    deadlinesRespect: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    autonomy: Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    workQuality: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    comment: Yup.string().max(255),
    teamworkAndCooperation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
  });

  // onSubmit form function
  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Complété",
      teamIntegration: values.teamIntegration.key,
      communication: values.communication.key,
      workInTeam: values.workInTeam.key,
      informationSharing: values.informationSharing.key,
      understanding: values.understanding.key,
      performance: values.performance.key,
      reliability: values.reliability.key,
      commitment: values.commitment.key,
      methodical: values.methodical.key,
      toolsMastery: values.toolsMastery.key,
      punctuality: values.punctuality.key,
      attendance: values.attendance.key,
      deadlinesRespect: values.deadlinesRespect.key,
      autonomy: values.autonomy.key,
      workQuality: values.workQuality.key,
      teamworkAndCooperation: values.teamworkAndCooperation.key,
      signature: values.signature,
      comment: values.comment,
    };

    console.log("dataToSend : ", dataToSend);

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/suivez-vos-alternants/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      teamIntegration: { ...objAucun },
      communication: { ...objAucun },
      workInTeam: { ...objAucun },
      informationSharing: { ...objAucun },
      understanding: { ...objAucun },
      performance: { ...objAucun },
      reliability: { ...objAucun },
      commitment: { ...objAucun },
      methodical: { ...objAucun },
      toolsMastery: { ...objAucun },
      punctuality: { ...objAucun },
      attendance: { ...objAucun },
      deadlinesRespect: { ...objAucun },
      autonomy: { ...objAucun },
      workQuality: { ...objAucun },
      comment: "",
      teamworkAndCooperation: { ...objAucun },
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  return (
    <>
      <Header
        h1={t("app.Booklet.BilanInteTitle")}
        textElement={(
          <p>
            {t("app.Booklet.BilanInteSubTitle1")} <br /> <br />
            {t("app.Booklet.BilanInteSubTitle2")}
            <br />
            <br />
            {t("app.Booklet.BilanInteSubTitle3")}
            <br />
            <br />
            {t("app.Booklet.BilanInteSubTitle4")}
            <br />
            <br />
            {t("app.Booklet.BilanInteSubTitle5")}
          </p>
        )}
        bgImg={bgConsulOffreImg}
      />

      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.container}>
            <div className={styles.title_container}>
              <h2>{t("app.Booklet.BilanInteMainSub")}</h2>
            </div>
            <form className={styles.the_form} onSubmit={handleSubmit}>
              {/* <span style={{ color: "red" }}>{errorMessage}</span> */}
              <div className={styles.inputs_container}>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="adaptationIntegration" className={styles.label_text}>
                    {t("app.Booklet.adaptationIntegration")}
                  </label>
                  <DropdownList
                    style={
                      errors.teamIntegration &&
                      touched.teamIntegration && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="adaptationIntegration"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.teamIntegration}
                    onChange={(newValue) => setFieldValue("teamIntegration", newValue)}
                  />
                  <InputErrorMessage message={errors.teamIntegration} touched={touched.teamIntegration} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="communication" className={styles.label_text}>
                    {t("app.Booklet.communicationTuteur")}
                  </label>
                  <DropdownList
                    style={
                      errors.communication &&
                      touched.communication && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="communication"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.communication}
                    onChange={(newValue) => setFieldValue("communication", newValue)}
                  />
                  <InputErrorMessage message={errors.communication} touched={touched.communication} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="workInTeam" className={styles.label_text}>
                    {t("app.Booklet.travaillerEnEquipe")}
                  </label>
                  <DropdownList
                    style={
                      errors.workInTeam &&
                      touched.workInTeam && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="workInTeam"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.workInTeam}
                    onChange={(newValue) => setFieldValue("workInTeam", newValue)}
                  />
                  <InputErrorMessage message={errors.workInTeam} touched={touched.workInTeam} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="informationSharing" className={styles.label_text}>
                    {t("app.Booklet.partageDesInformations")}
                  </label>
                  <DropdownList
                    style={
                      errors.informationSharing &&
                      touched.informationSharing && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="informationSharing"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.informationSharing}
                    onChange={(newValue) => setFieldValue("informationSharing", newValue)}
                  />
                  <InputErrorMessage message={errors.informationSharing} touched={touched.informationSharing} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="understanding" className={styles.label_text}>
                    {t("app.Booklet.comprehensionMission")}
                  </label>
                  <DropdownList
                    style={
                      errors.understanding &&
                      touched.understanding && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="understanding"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.understanding}
                    onChange={(newValue) => setFieldValue("understanding", newValue)}
                  />
                  <InputErrorMessage message={errors.understanding} touched={touched.understanding} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="teamworkAndCooperation" className={styles.label_text}>
                    {t("app.Booklet.collectionCooperation")}
                  </label>
                  <DropdownList
                    style={
                      errors.teamworkAndCooperation &&
                      touched.teamworkAndCooperation && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="teamworkAndCooperation"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.teamworkAndCooperation}
                    onChange={(newValue) => setFieldValue("teamworkAndCooperation", newValue)}
                  />
                  <InputErrorMessage message={errors.teamworkAndCooperation} touched={touched.teamworkAndCooperation} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="performance" className={styles.label_text}>
                    {t("app.Booklet.performancePoste")}
                  </label>
                  <DropdownList
                    style={
                      errors.performance &&
                      touched.performance && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="performance"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.performance}
                    onChange={(newValue) => setFieldValue("performance", newValue)}
                  />
                  <InputErrorMessage message={errors.performance} touched={touched.performance} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="reliability" className={styles.label_text}>
                    {t("app.Booklet.fiabilite")}
                  </label>
                  <DropdownList
                    style={
                      errors.reliability &&
                      touched.reliability && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="reliability"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.reliability}
                    onChange={(newValue) => setFieldValue("reliability", newValue)}
                  />
                  <InputErrorMessage message={errors.reliability} touched={touched.reliability} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="commitment" className={styles.label_text}>
                    {t("app.Booklet.investissementMission")}
                  </label>
                  <DropdownList
                    style={
                      errors.commitment &&
                      touched.commitment && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="commitment"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.commitment}
                    onChange={(newValue) => setFieldValue("commitment", newValue)}
                  />
                  <InputErrorMessage message={errors.commitment} touched={touched.commitment} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="methodical" className={styles.label_text}>
                    {t("app.Booklet.appliquerMethode")}
                  </label>
                  <DropdownList
                    style={
                      errors.methodical &&
                      touched.methodical && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="methodical"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.methodical}
                    onChange={(newValue) => setFieldValue("methodical", newValue)}
                  />
                  <InputErrorMessage message={errors.methodical} touched={touched.methodical} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="toolsMastery" className={styles.label_text}>
                    {t("app.Booklet.maitriseDesOutils")}
                  </label>
                  <DropdownList
                    style={
                      errors.toolsMastery &&
                      touched.toolsMastery && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="toolsMastery"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.toolsMastery}
                    onChange={(newValue) => setFieldValue("toolsMastery", newValue)}
                  />
                  <InputErrorMessage message={errors.toolsMastery} touched={touched.toolsMastery} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="punctuality" className={styles.label_text}>
                    {t("app.Booklet.ponctualite")}
                  </label>
                  <DropdownList
                    style={
                      errors.punctuality &&
                      touched.punctuality && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="punctuality"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.punctuality}
                    onChange={(newValue) => setFieldValue("punctuality", newValue)}
                  />
                  <InputErrorMessage message={errors.punctuality} touched={touched.punctuality} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="attendance" className={styles.label_text}>
                    {t("app.Booklet.assiduite")}
                  </label>
                  <DropdownList
                    style={
                      errors.attendance &&
                      touched.attendance && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="attendance"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.attendance}
                    onChange={(newValue) => setFieldValue("attendance", newValue)}
                  />
                  <InputErrorMessage message={errors.attendance} touched={touched.attendance} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="deadlinesRespect" className={styles.label_text}>
                    {t("app.Booklet.respectDelais")}
                  </label>
                  <DropdownList
                    style={
                      errors.deadlinesRespect &&
                      touched.deadlinesRespect && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="deadlinesRespect"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.deadlinesRespect}
                    onChange={(newValue) => setFieldValue("deadlinesRespect", newValue)}
                  />
                  <InputErrorMessage message={errors.deadlinesRespect} touched={touched.deadlinesRespect} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="autonomy" className={styles.label_text}>
                    {t("app.Booklet.autonomie")}
                  </label>
                  <DropdownList
                    style={
                      errors.autonomy &&
                      touched.autonomy && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="autonomy"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.autonomy}
                    onChange={(newValue) => setFieldValue("autonomy", newValue)}
                  />
                  <InputErrorMessage message={errors.autonomy} touched={touched.autonomy} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="workQuality" className={styles.label_text}>
                    {t("app.Booklet.qualiteTravauxEffectue")}
                  </label>
                  <DropdownList
                    style={
                      errors.workQuality &&
                      touched.workQuality && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="workQuality"
                    dataKey="key"
                    textField="label"
                    data={
                      referentials.APPRENTICESHIP_EVALUATION && [
                        { ...objAucun },
                        ...referentials.APPRENTICESHIP_EVALUATION,
                      ]
                    }
                    value={values.workQuality}
                    onChange={(newValue) => setFieldValue("workQuality", newValue)}
                  />
                  <InputErrorMessage message={errors.workQuality} touched={touched.workQuality} />
                </div>
                <div className={`${styles.label_plus_input_container} input-like-rw`}>
                  <label htmlFor="comment">{t("app.Booklet.commentaires")}</label>
                  <textarea
                    style={
                      errors.comment &&
                      touched.comment && {
                        border: "2px solid #EA001E",
                      }
                    }
                    id="comment"
                    rows="5"
                    maxLength={32768}
                    value={values.comment}
                    onChange={(e) => setFieldValue("comment", e.target.value.trimStart())}
                  >
                  </textarea>
                  <InputErrorMessage message={errors.comment} touched={touched.comment} />
                </div>
                <div className={styles.checkbox_container}>
                  <label htmlFor="signature" className={styles.label_text}>
                    {t("app.Booklet.signeMaitreApprentissage")}
                  </label>
                  <input
                    id="signature"
                    type="checkbox"
                    checked={values.signature}
                    onChange={(e) => setFieldValue("signature", e.target.checked)}
                  />
                  <InputErrorMessage message={errors.signature} touched={touched.signature} />
                </div>
              </div>
              <SubmitFormBtn text={t("app.formulairesLivretPartnerBilan.BoutonValider")} />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default BilanInte;
