import { t } from "i18next";

import { DISCOVER_OMNES_EDUCATION_URL, DISCOVER_OUR_SCHOOLS_URL } from "utils/globalConstantsAndURL";

// Global components
import DivLine from "../../../components/DivLine/DivLine";
// Local components
import HomepageHeader from "./HomepageHeader/HomepageHeader";
import ItemStep from "./ItemStep/ItemStep";
import Card from "./Card/Card";

// Images
import bgHomeImg from "./assets/bg-home.png";
import discoverImg from "./assets/discover.png";
import discoverMobileImg from "./assets/discoverMobile.png";
import cardKnow2Img from "./assets/cardKnow2.png";
import cardKnow3Img from "./assets/cardKnow3.png";

import styles from "./Homepage.module.scss";

/* eslint-disable */
const Homepage = () => (
  <>
    <HomepageHeader
      h1={t("app.homePageStudent.Intro1Title")}
      textElement={
        <p>
          {t("app.homePageStudent.Intro1")}
          <br /> {t("app.homePageStudent.Intro2")}
        </p>
      }
      bgImg={bgHomeImg}
    />

    <section className={styles.discover_container}>
      <img className={styles.discover_trapeze_img} src={discoverImg} alt="Mains sur un clavier d'ordinateur" />
      <img className={styles.discover_mobile_img} src={discoverMobileImg} alt="Mains sur un clavier d'ordinateur" />
      <div className={styles.discover_content_container}>
        <DivLine />
        <h2 className={styles.discover_content_title}>{t("app.homePageStudent.DecouvrezFormation")}</h2>
        <div className={styles.discover_content_desc}>
          <p>{t("app.homePageStudent.Alternance1")}</p>
          <p>{t("app.homePageStudent.Alternance2")}</p>
        </div>
      </div>
    </section>

    <section className={styles.second_container}>
      <div className={styles.title_second_container}>
        <DivLine />
        <h2>{t("app.homePageStudent.EtapeASuivre")}</h2>
      </div>
      <div className={styles.steps_container}>
        <ItemStep
          number="1"
          h3={t("app.homePageStudent.DeposerCV")}
          textElement={<p>{t("app.homePageStudent.Etape1")}</p>}
        />
        <ItemStep
          number="3"
          h3={t("app.homePageStudent.PostulerOffre")}
          textElement={<p>{t("app.homePageStudent.Etape3")}</p>}
        />
        <ItemStep
          number="2"
          h3={t("app.homePageStudent.VisualiserOffres")}
          textElement={<p>{t("app.homePageStudent.Etape2")}</p>}
        />
        <ItemStep
          number="4"
          h3={t("app.homePageStudent.FournirInfos")}
          textElement={<p>{t("app.homePageStudent.Etape4")}</p>}
        />
      </div>
    </section>

    <section className={styles.third_container}>
      <div className={styles.title_third_container}>
        <DivLine />
        <h2>{t("app.homePageStudent.MieuxNousConnaitre")}</h2>
      </div>
      <div className={styles.cards_container}>
        <Card
          path={DISCOVER_OMNES_EDUCATION_URL}
          img={cardKnow3Img}
          h3={t("app.homePageStudent.OMNES")}
          text={t("app.homePageStudent.NousConnaitre1")}
        />
        <Card
          path={DISCOVER_OUR_SCHOOLS_URL}
          img={cardKnow2Img}
          h3={t("app.homePageStudent.NosEcoles")}
          text={t("app.homePageStudent.NousConnaitre1")}
        />
      </div>
    </section>
  </>
);

export default Homepage;
