import Header from "components/Header/Header";

import { t } from "i18next";

import bgFollowOffreImg from "../../assets/bg-consul-offre.png";
import styles from "./HeaderContract.module.scss";

/* eslint-disable */
const HeaderContract = () => (
  <Header
    h1={t("app.StepThree.DeclarezProjet")}
    textElement={
      <div className={styles.headerContent}>
        <p>{t("app.StepThree.Intro1")}</p>
        <ul>
          <li>{t("app.StepThree.Intro2")}</li>
          <li>{t("app.StepThree.Intro3")}</li>
        </ul>
      </div>
    }
    bgImg={bgFollowOffreImg}
  />
);

export default HeaderContract;
