import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  cvsList: [],
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const getUserCVSCardsListThunk = createAsyncThunk("checkYourCV/getUserCVSCardsListThunk", async () => {
  const response = await axiosStudent().get("/v1/students/resumes?embedded=true");
  return response.data;
});

// CREATESLICE SECTION

const checkYourCVSlice = createSlice({
  name: "checkYourCV",
  initialState,
  extraReducers: {
    [getUserCVSCardsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getUserCVSCardsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.cvsList = action.payload;
    },
    [getUserCVSCardsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getCheckYourCVUserCVSCards = (state) => state.checkYourCV.cvsList;
export const getCheckYourCVPending = (state) => state.checkYourCV.pending;
export const getCheckYourCVErrorMessage = (state) => state.checkYourCV.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourCVSlice.reducer;
