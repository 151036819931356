import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";

import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { toastSuccess } from "utils/toasts";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { useDispatch } from "react-redux";
import { postAssociativeExperiencesThunk } from "../../../../app/slices/modifyYourCV/associativeExperiences.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import styles from "../modalWrapper.module.scss";
import closeIcon from "../assets/close-svg.svg";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

/* eslint-disable */
const PostModalAssociativeExperiences = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const dataToSend = {
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      organizationName: values.organizationName,
      position: values.position,
      performedTasks: values.performedTasks,
      acquiredSkills: values.acquiredSkills,
    };
    (async () => {
      try {
        await dispatch(
          postAssociativeExperiencesThunk({
            dataToSend,
            id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationAjoutExpAssoTitle")}
            secondMessage={t("app.toastMessages.ConfirmationAjoutExpAssoMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const PostModalAssociativeExperiencesSchema = Yup.object().shape({
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    endDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    organizationName: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    position: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    performedTasks: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    acquiredSkills: Yup.string().required(t("app.errorMessages.RemplirChamp")),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      organizationName: "",
      position: "",
      performedTasks: "",
      acquiredSkills: "",
    },
    validationSchema: PostModalAssociativeExperiencesSchema,
    onSubmit,
  });

  if (props.showPost)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.CreationExpAssociative")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="organizationName">
                    {t("app.visualisationCv.NomAssociation")}
                  </label>
                  <input
                    style={
                      errors.organizationName &&
                      touched.organizationName && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.organizationName}
                    maxLength={80}
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    onChange={(e) => setFieldValue("organizationName", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.organizationName} touched={touched.organizationName} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="startDate">
                    {t("app.visualisationCv.DateDebut")}
                  </label>
                  {/* <DatePicker
										style={
											errors.startDate &&
											touched.startDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.startDate}
										onChange={(value) => setFieldValue("startDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    value={values.startDate}
                    onChange={(value) => setFieldValue("startDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="endDate">
                    {t("app.visualisationCv.DateFin")}
                  </label>
                  {/* <DatePicker
										style={
											errors.endDate &&
											touched.endDate && {
												border: "2px solid #EA001E",
												borderRadius: "5px",
											}
										}
										value={values.endDate}
										onChange={(value) => setFieldValue("endDate", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.endDate}
                    touched={touched.endDate}
                    value={values.endDate}
                    onChange={(value) => setFieldValue("endDate", value)}
                  />
                  <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="position">
                    {t("app.visualisationCv.Fonction")}
                  </label>
                  <textarea
                    style={
                      errors.position &&
                      touched.position && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.position}
                    maxLength={80}
                    type="position"
                    name="position"
                    id="position"
                    onChange={(e) => setFieldValue("position", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.position} touched={touched.position} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="performedTasks">
                    {t("app.visualisationCv.MissionsRealisees")}
                  </label>
                  <textarea
                    style={
                      errors.performedTasks &&
                      touched.performedTasks && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.performedTasks}
                    maxLength={255}
                    type="performedTasks"
                    name="performedTasks"
                    id="performedTasks"
                    onChange={(e) => setFieldValue("performedTasks", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.performedTasks} touched={touched.performedTasks} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="acquiredSkills">
                    {t("app.visualisationCv.3Competences")}
                  </label>
                  <textarea
                    style={
                      errors.acquiredSkills &&
                      touched.acquiredSkills && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    value={values.acquiredSkills}
                    maxLength={500}
                    type="acquiredSkills"
                    name="acquiredSkills"
                    id="acquiredSkills"
                    onChange={(e) => setFieldValue("acquiredSkills", e.target.value.trimStart())}
                  ></textarea>
                  <InputErrorMessage message={errors.acquiredSkills} touched={touched.acquiredSkills} />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezExperience")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showPost) return null;
};

export default PostModalAssociativeExperiences;
