import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm5Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm5/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm5Slice = createSlice({
  name: "checkYourRecruitmentsForm5",
  initialState,
  reducers: {
    handleRecruitmentPropsForm5Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm5Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm5Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm5Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm5Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm5Data, handleRecruitmentSectionsForm5Data } =
  checkYourRecruitmentsForm5Slice.actions;

export const getYourRecruitmentPropsForm5Data = (state) => state.checkYourRecruitmentsForm5.recruitmentPropsForm5Data;
export const getAllUserRecruitmentsForm5 = (state) => state.checkYourRecruitmentsForm5.recruitmentList;
export const getUserRecruitmentsPendingForm5 = (state) => state.checkYourRecruitmentsForm5.pending;
export const getUserRecruitmentsErrorMessageForm5 = (state) => state.checkYourRecruitmentsForm5.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm5Slice.reducer;
