import Header from "components/Header/Header";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useLayoutEffect } from "react";

import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import {
  getAllUserRecruitments,
  getUserRecruitmentsPending,
  getConpanyRecruitmentListThunk,
  handleRecruitmentPropsData,
  selectCurrentPaginationOfContracts,
  selectMaxPaginationOfContracts,
  nextPaginationCompanyRecruitmentListThunk,
  previousPaginationCompanyRecruitmentListThunk,
  resetRecruitmentState,
} from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import axios from "axios";
import { nanoid } from "@reduxjs/toolkit";
import arrow from "./icon-arrow-right.svg";

import arrowPagination from "./icon-arrow-pagination.svg";

import bgContractImg from "../../../PublishAnOffer/assets/bg-consul-offre.png";

import styles from "./AllPartTimeProject.module.scss";

/* eslint-disable */
const AllPartTimeProject = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const recruitmentsList = useSelector(getAllUserRecruitments);
  console.log("🚀 ~ file: AllPartTimeProject.js:24 ~ AllPartTimeProject ~ recruitmentsList", recruitmentsList);
  const pending = useSelector(getUserRecruitmentsPending);
  //   const errorMessage = useSelector(getUserRecruitmentsErrorMessage);

  const userToken = useSelector(getUserToken);

  const currentPagination = useSelector(selectCurrentPaginationOfContracts);
  const maxPagination = useSelector(selectMaxPaginationOfContracts);
  console.log("MAXPAGINATION", maxPagination);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (!pending && userToken && !firstLoad) {
      (async () => {
        try {
          await dispatch(getConpanyRecruitmentListThunk({ axiosCancelToken })).unwrap();
          setFirstLoad(true);
          console.log("useEffect success ! ALL RECRUITMENTS", recruitmentsList);
        } catch (error) {
          console.log("useEffect fail ! ALL RECRUITMENTS", error.message);
        }
      })();
    }
  }, [userToken]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetRecruitmentState());
      console.log("RESET STATE");
    },
    [],
  );

  return (
    <>
      {recruitmentsList && (
        <>
          <Header h1={t("app.SuivezVosContrats.SuivezContrats")} bgImg={bgContractImg} />
          <div className={styles.containerResult}>
            <div className={styles.containerResult__head}>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.NomEtudiant")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.EcoleFormation")}</div>
              <div className={`${styles.containerResult__head__title}  ${styles.special}`}>
                {t("app.SuivezVosContrats.Specialisation")}
              </div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.DateDebut")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.Programme")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.Statut")}</div>
            </div>
            <div className={styles.allResults}>
              {recruitmentsList?.map((item) => (
                <div className={styles.result} key={nanoid()}>
                  <div className={styles.result__body__id}>
                    <span>{item?.student?.name}</span>
                    <span> {item?.student?.firstname}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.EcoleFormation")}</span>
                    <span>{item?.student?.schoolProgramEnrollment?.trainingSchool}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Specialisation")}</span>
                    <span>{item?.student?.schoolProgramEnrollment?.trainingSchoolERP}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.DateDebut")}</span>
                    <span className={styles.date}>{item?.student?.schoolProgramEnrollment?.startDate}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Programme")}</span>
                    <span>{item?.student?.schoolProgramEnrollment?.product}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Statut")}</span>
                    <span className={styles.status}>{item?.statusForCustomerDisplay?.label}</span>
                  </div>
                  <div className={styles.result__body__link}>
                    {(item?.status?.key === "À compléter" || item?.status?.key === "En cours") && (
                      <Link to={item?.id?.toString()}>
                        <div className={styles.btn_seeMore} onClick={() => dispatch(handleRecruitmentPropsData(item))}>
                          <span>{t("app.SuivezVosContrats.Consulter")}</span>
                          <img src={arrow} alt={t("app.allOffers.btnDetails")} />
                        </div>
                      </Link>
                    )}
                    {item?.status?.key !== "À compléter" && item?.status?.key !== "En cours" && (
                      <Link to={"/suivez-vos-contrats/" + item?.id?.toString() + "/recap"}>
                        <div className={styles.btn_seeMore} onClick={() => dispatch(handleRecruitmentPropsData(item))}>
                          <span>{t("app.SuivezVosContrats.Consulter")}</span>
                          <img src={arrow} alt={t("app.allOffers.btnDetails")} />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.arrowPagination}>
            <button
              disabled={currentPagination <= 1}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    previousPaginationCompanyRecruitmentListThunk({
                      axiosCancelToken,
                    }),
                  );
                }
              }}
              className={styles.arrowLeft}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
            <span className={styles.totalPage}>PAGE</span>
            {currentPagination}/{maxPagination}
            <button
              disabled={currentPagination >= maxPagination}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    nextPaginationCompanyRecruitmentListThunk({
                      axiosCancelToken,
                    }),
                  );
                }
              }}
            >
              <img src={arrowPagination} alt="prev" />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AllPartTimeProject;
