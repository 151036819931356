import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getDeviceWidth } from "../../../app/slices/deviceWidth/deviceWidth.slice";

import styles from "./Header.module.scss";

const Header = ({ bgImg, h1, textElement, leftBtn, rightBtn }) => {
  const deviceWidth = useSelector(getDeviceWidth);
  const actualBg = deviceWidth > 1350 ? bgImg : "none";

  const headerStyle = {
    background: `no-repeat top right url(${actualBg})`,
  };

  return (
    <header className={styles.header_container} style={headerStyle}>
      <div className={styles.header_content}>
        <h1>{h1}</h1>
        {textElement}
      </div>
      <div className={styles.btns_container}>
        <Link to={leftBtn.path}>
          <button className={styles.left_btn} type="button">
            {leftBtn.text}
          </button>
        </Link>
        <Link to={rightBtn.path}>
          <button className={styles.right_btn} type="button">
            {rightBtn.text}
          </button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
