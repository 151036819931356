import { t } from "i18next";
import DivLine from "components/DivLine/DivLine";
import {
  DISCOVER_OMNES_EDUCATION_URL,
  DISCOVER_OUR_SCHOOLS_URL,
  DISCOVER_LEARNING_TAXE_URL,
} from "utils/globalConstantsAndURL";
import Card from "pages/ENT/Homepage/Card/Card";

import cardKnow2Img from "../../../Homepage/assets/cardKnow2.png";
import cardKnow3Img from "../../../Homepage/assets/cardKnow3.png";

import styles from "./NewContactConfirmation.module.scss";

const NewContactConfirmation = () => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <h1>{t("app.autoInscription.SuccessTitre")}</h1>
      <p style={{ paddingTop: "36px" }}>{t("app.autoInscription.Success1")}</p>
      <p>{t("app.autoInscription.Success2")}</p>
      <p>{t("app.autoInscription.Success3")}</p>
      <p>{t("app.autoInscription.Success4")}</p>
      <p>{t("app.autoInscription.Success5")}</p>
    </div>
    <section className={styles.third_container}>
      <div className={styles.title_third_container}>
        <DivLine />
        <h2>{t("app.homePageEntreprise.MieuxNousConnaitres")}</h2>
      </div>
      <div className={styles.cards_container}>
        <Card
          path={DISCOVER_OMNES_EDUCATION_URL}
          img={cardKnow3Img}
          h3={t("app.homePageEntreprise.OMNES")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
        <Card
          path={DISCOVER_OUR_SCHOOLS_URL}
          img={cardKnow2Img}
          h3={t("app.homePageEntreprise.NosEcoles")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
        <Card
          path={DISCOVER_LEARNING_TAXE_URL}
          img={cardKnow3Img}
          h3={t("app.homePageEntreprise.TaxeApprentissage")}
          text={t("app.homePageEntreprise.NousConnaitre1")}
        />
      </div>
    </section>
  </div>
);

export default NewContactConfirmation;
