import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const initialState = {
  data: [],
  cardProps: null,
  pending: false,
  errorMessage: null,
  triggerSuccess: false,
  speciality: null,
  status: false,
};

export const getOneCV = createAsyncThunk("modifyYourCV/getOneCV", async (id) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${id}`);
  return response.data;
});

export const patchCVByIdThunk = createAsyncThunk("modifyYourCV/patchCVByIdThunk", async ({ dataToSend, id }) => {
  const response = await axiosStudent().patch(`/v1/students/resumes/${id}`, dataToSend);
  console.log("BACK", response);
  return response.data;
});

export const patchCVStatusToDeactivate = createAsyncThunk(
  "modifyYourCV/patchCVStatusToDeactivate",
  async ({ dataToSend, id }) => {
    console.log("BACK", dataToSend);
    const response = await axiosStudent().patch(`/v1/students/resumes/${id}`, dataToSend);
    return response.data;
  },
);

const modifyYourCVSlice = createSlice({
  name: "modifyYourCV",
  initialState,
  reducers: {
    handleCardProps: (state, action) => {
      state.cardProps = action.payload;
    },
  },
  extraReducers: {
    [patchCVByIdThunk.pending]: (state) => {
      state.pending = true;
      state.triggerSuccess = false;
      state.errorMessage = null;
    },
    [patchCVByIdThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action);
      state.pending = false;
      state.triggerSuccess = true;
      state.data = action.payload;
    },
    [patchCVByIdThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.triggerSuccess = false;
      state.errorMessage = action.error.message;
    },
    [patchCVStatusToDeactivate.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [patchCVStatusToDeactivate.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [patchCVStatusToDeactivate.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getOneCV.pending]: (state) => {
      state.errorMessage = null;
      state.data = undefined;
      state.pending = true;
    },
    [getOneCV.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.status = action.payload.status;
      state.data = action.payload;
      state.speciality = action.payload.search.subSpeciality;
    },
    [getOneCV.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.data = undefined;
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

export const { handleCardProps } = modifyYourCVSlice.actions;

export const getYourCardProps = (state) => state.modifyYourCV.cardProps;
export const getYourCVStatus = (state) => state.modifyYourCV.status;
export const getYourTriggerSuccess = (state) => state.modifyYourCV.triggerSuccess;
export const getYourSpeciality = (state) => state.modifyYourCV.speciality;
export const getYourCVById = (state) => state.modifyYourCV.data;
export const getYourCVByIdPending = (state) => state.modifyYourCV.pending;

export default modifyYourCVSlice.reducer;
