import { t } from "i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { DropdownList } from "react-widgets";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { PutLanguageSkillThunk } from "app/slices/createYourCV/createLanguageSkill.slice";
import { getcreateYourCVId, handleStepsData } from "../../../../../../app/slices/createYourCV/createYourCV.slice";
import {
  getcreateYourExperienceErrorMessage,
  getcreateYourExperiencePending,
} from "../../../../../../app/slices/createYourCV/createYourExperience.slice";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import styles from "./Modal.module.scss";

/* eslint-disable */
const Modal = (props) => {
  const dispatch = useDispatch();
  const pending = useSelector(getcreateYourExperiencePending);
  const errorMessage = useSelector(getcreateYourExperienceErrorMessage);
  const cvId = useSelector(getcreateYourCVId);
  const data = props.modalData;

  const closeModal = props.closeModal;
  const modifyForm = props.modifyForm;
  const isModify = props.isModify;
  const referencialData = useSelector(getReferentialsData);
  const empty = { label: "--Aucun--", key: "--Aucun--" };
  const onSubmit = (values) => {
    dispatch(
      handleStepsData({
        formName: "languageSkillsModal",
        formData: {
          lang: values.lang,
          frenchLevel: values.frenchLevel,
          other: values.other,
          level: values.level,
          certification: values.certification,
        },
      }),
    );
    const id = data.id;

    const dataToSend = {
      lang: values.lang.key,
      // frenchLevel: values.frenchLevel.label,
      other: values.other,
      level: values.level.key,
      certification: values.certification,
    };
    console.log("dataToSend : ", dataToSend);
    props.closeModal();
    if (!pending) {
      (async () => {
        try {
          await dispatch(
            PutLanguageSkillThunk({
              dataToSend,
              id,
              cvId: cvId,
              // axiosCancelToken: props.axiosCancelToken,
            }),
          ).unwrap();
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ConfirmationModifLanguageTitle")}
              secondMessage={t("app.toastMessages.ConfirmationModifLanguageMessage")}
            />,
          );
          console.log("Your experience put success !");
        } catch (error) {
          console.log("Your experience put fail !", error.message);
        }
      })();
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      lang: data.lang,
      // frenchLevel: values.frenchLevel.label,
      other: data.other,
      level: data.level,
      certification: data.certification,
    },
    // validationSchema: yourProfileSchema,
    onSubmit,
  });
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <div style={{ flexDirection: "column" }}>
          {isModify ? (
            <>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>{t("app.visualisationCv.ModificationMaitriseLangue")}</span>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.inputs_container}>
                    <span style={{ color: "red" }}>{errorMessage}</span>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="lang">{t("app.depotCv.Langue")}</label>
                      <DropdownList
                        id="lang"
                        dataKey="key"
                        textField="label"
                        data={[empty, ...referencialData?.LANGUAGE]}
                        value={values.lang}
                        onChange={(newValue) => setFieldValue("lang", newValue)}
                      />
                      <InputErrorMessage message={errors.lang} touched={touched.lang} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="other">{t("app.depotCv.PrecisezSiAutre")}</label>
                      <textarea
                        style={{ height: 40 }}
                        id="other"
                        value={values.other}
                        onChange={(e) => setFieldValue("other", e.target.value)}
                      />
                      <InputErrorMessage message={errors.other} touched={touched.other} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="level">{t("app.depotCv.Niveau")}</label>
                      <DropdownList
                        id="level"
                        dataKey="key"
                        textField="label"
                        data={[empty, ...referencialData?.LANGUAGE_LEVEL]}
                        value={values.level}
                        onChange={(newValue) => setFieldValue("level", newValue)}
                      />
                      <InputErrorMessage message={errors.level} touched={touched.level} />
                    </div>

                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="certification">{t("app.depotCv.CertificationsLinguistiques")}</label>
                      <textarea
                        id="certification"
                        value={values.certification}
                        onChange={(e) => setFieldValue("certification", e.target.value)}
                      />
                      <InputErrorMessage message={errors.certification} touched={touched.certification} />
                    </div>
                  </div>
                  {/* <div className={styles.label_plus_input_container}></div> */}
                </div>
                <div className={styles.submit_button_container}>
                  <button type="submit" className={styles.submit_button}>
                    {t("app.depotCv.ValidateExperience")}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <p className={styles.titleContainer}>
                <div className={styles.title}>
                  <span>CONSULTATION D'UNE EXPÉRIENCE ASSOCIATIVE {t("app.depotCv.ModifyComputerSkill")}</span>
                </div>
              </p>
              <div className={styles.formContainer}>
                <div>
                  <div className={styles.etab}>
                    {t("app.depotCv.Langue")}: {data.lang}
                  </div>
                  <div className={styles.institution}>Fonction:{data.position}</div>
                  <div className={styles.date}>
                    Du {data.startDate} au {data.endDate}
                  </div>
                  <div className={styles.description}>Missions réalisées</div>
                  <div className={styles.dataDescription}>{data.performedTasks}</div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={modifyForm} className={styles.modifyButton}>
                  Modify This Experience {t("app.depotCv.ModifyComputerSkill")}
                </button>
              </div>
            </>
          )}
        </div>

        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
