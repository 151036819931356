import { t } from "i18next";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  putThunk,
  getLearningBookletsIdData,
  getLearningBookletsPending,
  getLearningBookletsErrorMessage,
} from "app/slices/learningBooklets/learningBooklets.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { createObjAucun } from "utils/localReferentials";

import { DropdownList } from "react-widgets/cjs";

import Header from "components/Header/Header";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import bgConsulOffreImg from "../../../assets/bg-consul-offre.png";

import styles from "../../SelectedBooklet.module.scss";

const EndTermEvalSecondYear = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const idData = useSelector(getLearningBookletsIdData);
  const pending = useSelector(getLearningBookletsPending);
  const errorMessage = useSelector(getLearningBookletsErrorMessage);
  const referentials = useSelector(getReferentialsData);
  const objAucun = createObjAucun();

  // This useEffect is only for cleanup function !!!

  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
    },
    [],
  );

  // Yup validation schema

  const formSchema = Yup.object().shape({
    interpersonalSkills: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    teamSpirit: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    adaptability: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    initiative: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    standardAndRegulationRespect: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    planificationOrganisation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    autoControl: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    reporting: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    mobilizationAbility: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    communicationSkills: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    knowledgeAcquisition: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    personalOrganizationWork: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    toolsAndMethodsMastery: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    deadlinesRespect: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    workQuality: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    signature: Yup.boolean().test("if-is-false", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  // onSubmit form function

  const onSubmit = async (values) => {
    const dataToSend = {
      status: "Complété",
      interpersonalSkills: values.interpersonalSkills?.key,
      teamSpirit: values.teamSpirit?.key,
      adaptability: values.adaptability?.key,
      initiative: values.initiative?.key,
      standardAndRegulationRespect: values.standardAndRegulationRespect?.key,
      planificationOrganisation: values.planificationOrganisation?.key,
      autoControl: values.autoControl?.key,
      reporting: values.reporting?.key,
      mobilizationAbility: values.mobilizationAbility?.key,
      communicationSkills: values.communicationSkills?.key,
      knowledgeAcquisition: values.knowledgeAcquisition?.key,
      personalOrganizationWork: values.personalOrganizationWork?.key,
      toolsAndMethodsMastery: values.toolsAndMethodsMastery?.key,
      deadlinesRespect: values.deadlinesRespect?.key,
      workQuality: values.workQuality?.key,
      comment: values?.companyTutorComment ? values?.companyTutorComment : "",
      signature: values.signature,
    };

    if (!pending) {
      try {
        await dispatch(
          putThunk({
            dataToSend,
            id,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log(`put on id : ${id} success !`);
        navigate("/suivez-vos-alternants/confirmation");
      } catch (error) {
        console.log(`put on id : ${id} failed !`, error);
      }
    }
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      interpersonalSkills: idData?.interpersonalSkills ? idData?.interpersonalSkills : { ...objAucun },
      teamSpirit: idData?.teamSpirit ? idData?.teamSpirit : { ...objAucun },
      adaptability: idData?.adaptability ? idData?.adaptability : { ...objAucun },
      initiative: idData?.initiative ? idData?.initiative : { ...objAucun },
      standardAndRegulationRespect: idData?.standardAndRegulationRespect
        ? idData?.standardAndRegulationRespect
        : { ...objAucun },
      planificationOrganisation: idData?.planificationOrganisation
        ? idData?.planificationOrganisation
        : { ...objAucun },
      autoControl: idData?.autoControl ? idData?.autoControl : { ...objAucun },
      reporting: idData?.reporting ? idData?.reporting : { ...objAucun },
      mobilizationAbility: idData?.mobilizationAbility ? idData?.mobilizationAbility : { ...objAucun },
      communicationSkills: idData?.communicationSkills ? idData?.communicationSkills : { ...objAucun },
      knowledgeAcquisition: idData?.knowledgeAcquisition ? idData?.knowledgeAcquisition : { ...objAucun },
      personalOrganizationWork: idData?.personalOrganizationWork ? idData?.personalOrganizationWork : { ...objAucun },
      toolsAndMethodsMastery: idData?.toolsAndMethodsMastery ? idData?.toolsAndMethodsMastery : { ...objAucun },
      deadlinesRespect: idData?.deadlinesRespect ? idData?.deadlinesRespect : { ...objAucun },
      workQuality: idData?.workQuality ? idData?.workQuality : { ...objAucun },
      companyTutorComment: idData?.companyTutorComment ? idData?.companyTutorComment : "",
      signature: false,
    },
    validationSchema: formSchema,
    onSubmit,
  });

  /* eslint-disable */
  return (
    <>
      <>
        {idData && referentials && (
          <>
            <Header
              h1={t("app.m2EvalFinal.Suivez")}
              textElement={
                <div className={styles.headerContent}>
                  <p>
                    {t("app.m2EvalFinal.Intro1")} <br /> <br />
                    {t("app.m2EvalFinal.Intro2")}
                    <br />
                    {t("app.m2EvalFinal.Intro3")}
                    <br /> <br />
                    {t("app.m2EvalFinal.Intro4")}
                    <br />
                    {t("app.m2EvalFinal.Intro5")}
                    <br /> <br />
                    {t("app.m2EvalFinal.Intro6")} <br />
                    <ul>
                      <li>{t("app.m2EvalFinal.NonAcquis")}</li>
                      <li>{t("app.m2EvalFinal.EnCoursDacquisition")}</li>
                      <li>{t("app.m2EvalFinal.Acquis")}</li>
                      <li>{t("app.m2EvalFinal.Expert")}</li>
                    </ul>
                  </p>
                </div>
              }
              bgImg={bgConsulOffreImg}
            />
            <section className={styles.main_container}>
              <div className={styles.form_container}>
                <div className={styles.container}>
                  <div className={styles.title_container}>
                    <h2>{t("app.m2EvalFinal.EvalFinaleM2")}</h2>
                  </div>
                  <form className={styles.the_form} onSubmit={handleSubmit}>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                    <div className={styles.inputs_container}>
                      <h3>{t("app.m2EvalFinal.CompetencesPerso")}</h3>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="interpersonalSkills" className={styles.label_text}>
                          {t("app.m2EvalFinal.Relationnel")}
                        </label>
                        <p>{t("app.m2EvalFinal.RelationnelDesc")}</p>
                        <DropdownList
                          style={
                            errors.interpersonalSkills &&
                            touched.interpersonalSkills && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="interpersonalSkills"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.interpersonalSkills}
                          onChange={(newValue) => setFieldValue("interpersonalSkills", newValue)}
                        />
                        <InputErrorMessage message={errors.interpersonalSkills} touched={touched.interpersonalSkills} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="teamSpirit" className={styles.label_text}>
                          {t("app.m2EvalFinal.EspritDequipe")}
                        </label>
                        <p>{t("app.m2EvalFinal.EquipeDesc")}</p>
                        <DropdownList
                          style={
                            errors.teamSpirit &&
                            touched.teamSpirit && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="teamSpirit"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.teamSpirit}
                          onChange={(newValue) => setFieldValue("teamSpirit", newValue)}
                        />
                        <InputErrorMessage message={errors.teamSpirit} touched={touched.teamSpirit} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="adaptability" className={styles.label_text}>
                          {t("app.m2EvalFinal.Adaptabilite")}
                        </label>
                        <p>{t("app.m2EvalFinal.AdaptabiliteDesc")}</p>
                        <DropdownList
                          style={
                            errors.adaptability &&
                            touched.adaptability && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="adaptability"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.adaptability}
                          onChange={(newValue) => setFieldValue("adaptability", newValue)}
                        />
                        <InputErrorMessage message={errors.adaptability} touched={touched.adaptability} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="initiative" className={styles.label_text}>
                          {t("app.m2EvalFinal.Initiative")}
                        </label>
                        <p>{t("app.m2EvalFinal.InitiativeDesc")}</p>
                        <DropdownList
                          style={
                            errors.initiative &&
                            touched.initiative && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="initiative"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.initiative}
                          onChange={(newValue) => setFieldValue("initiative", newValue)}
                        />
                        <InputErrorMessage message={errors.initiative} touched={touched.initiative} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="standardAndRegulationRespect" className={styles.label_text}>
                          {t("app.m2EvalFinal.Respect")}
                        </label>
                        <p>{t("app.m2EvalFinal.RespectDesc")}</p>
                        <DropdownList
                          style={
                            errors.standardAndRegulationRespect &&
                            touched.standardAndRegulationRespect && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="standardAndRegulationRespect"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.standardAndRegulationRespect}
                          onChange={(newValue) => setFieldValue("standardAndRegulationRespect", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.standardAndRegulationRespect}
                          touched={touched.standardAndRegulationRespect}
                        />
                      </div>
                      <div className={styles.div_line} />
                      <h3>{t("app.m2EvalFinal.CompetencesPro")}</h3>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="planificationOrganisation" className={styles.label_text}>
                          {t("app.m2EvalFinal.Planification")}
                        </label>
                        <p>{t("app.m2EvalFinal.PlanificationDesc")}</p>
                        <DropdownList
                          style={
                            errors.planificationOrganisation &&
                            touched.planificationOrganisation && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="planificationOrganisation"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.planificationOrganisation}
                          onChange={(newValue) => setFieldValue("planificationOrganisation", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.planificationOrganisation}
                          touched={touched.planificationOrganisation}
                        />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="autoControl" className={styles.label_text}>
                          {t("app.m2EvalFinal.Controle")}
                        </label>
                        <p>{t("app.m2EvalFinal.ControleDesc")}</p>
                        <DropdownList
                          style={
                            errors.autoControl &&
                            touched.autoControl && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="autoControl"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.autoControl}
                          onChange={(newValue) => setFieldValue("autoControl", newValue)}
                        />
                        <InputErrorMessage message={errors.autoControl} touched={touched.autoControl} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="reporting" className={styles.label_text}>
                          {t("app.m2EvalFinal.Reporting")}
                        </label>
                        <p>{t("app.m2EvalFinal.ReportingDesc")}</p>
                        <DropdownList
                          style={
                            errors.reporting &&
                            touched.reporting && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="reporting"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.reporting}
                          onChange={(newValue) => setFieldValue("reporting", newValue)}
                        />
                        <InputErrorMessage message={errors.reporting} touched={touched.reporting} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="communicationSkills" className={styles.label_text}>
                          {t("app.m2EvalFinal.QualiteComm")}
                        </label>
                        <p>{t("app.m2EvalFinal.QualiteCommDesc")}</p>
                        <DropdownList
                          style={
                            errors.communicationSkills &&
                            touched.communicationSkills && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="communicationSkills"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.communicationSkills}
                          onChange={(newValue) => setFieldValue("communicationSkills", newValue)}
                        />
                        <InputErrorMessage message={errors.communicationSkills} touched={touched.communicationSkills} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="mobilizationAbility" className={styles.label_text}>
                          {t("app.m2EvalFinal.Mobilise")}
                        </label>
                        <p>{t("app.m2EvalFinal.MobiliseDesc")}</p>
                        <DropdownList
                          style={
                            errors.mobilizationAbility &&
                            touched.mobilizationAbility && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="mobilizationAbility"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.mobilizationAbility}
                          onChange={(newValue) => setFieldValue("mobilizationAbility", newValue)}
                        />
                        <InputErrorMessage message={errors.mobilizationAbility} touched={touched.mobilizationAbility} />
                      </div>
                      <div className={styles.div_line} />
                      <h3>{t("app.m2EvalFinal.CompetenceTech")}</h3>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="knowledgeAcquisition" className={styles.label_text}>
                          {t("app.m2EvalFinal.Connaissances")}
                        </label>
                        <p>{t("app.m2EvalFinal.ConnaissancesDesc")}</p>
                        <DropdownList
                          style={
                            errors.knowledgeAcquisition &&
                            touched.knowledgeAcquisition && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="knowledgeAcquisition"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.knowledgeAcquisition}
                          onChange={(newValue) => setFieldValue("knowledgeAcquisition", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.knowledgeAcquisition}
                          touched={touched.knowledgeAcquisition}
                        />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="personalOrganizationWork" className={styles.label_text}>
                          {t("app.m2EvalFinal.OrgaPerso")}
                        </label>
                        <p>{t("app.m2EvalFinal.OrgaPersoDesc")}</p>
                        <DropdownList
                          style={
                            errors.personalOrganizationWork &&
                            touched.personalOrganizationWork && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="personalOrganizationWork"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.personalOrganizationWork}
                          onChange={(newValue) => setFieldValue("personalOrganizationWork", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.personalOrganizationWork}
                          touched={touched.personalOrganizationWork}
                        />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="toolsAndMethodsMastery" className={styles.label_text}>
                          {t("app.m2EvalFinal.Outils")}
                        </label>
                        <p>{t("app.m2EvalFinal.OutilsDesc")}</p>
                        <DropdownList
                          style={
                            errors.toolsAndMethodsMastery &&
                            touched.toolsAndMethodsMastery && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="toolsAndMethodsMastery"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.toolsAndMethodsMastery}
                          onChange={(newValue) => setFieldValue("toolsAndMethodsMastery", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.toolsAndMethodsMastery}
                          touched={touched.toolsAndMethodsMastery}
                        />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="workQuality" className={styles.label_text}>
                          {t("app.m2EvalFinal.QualiteTravail")}
                        </label>
                        <p>{t("app.m2EvalFinal.QualiteTravailDesc")}</p>
                        <DropdownList
                          style={
                            errors.workQuality &&
                            touched.workQuality && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="workQuality"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.workQuality}
                          onChange={(newValue) => setFieldValue("workQuality", newValue)}
                        />
                        <InputErrorMessage message={errors.workQuality} touched={touched.workQuality} />
                      </div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="deadlinesRespect" className={styles.label_text}>
                          {t("app.m2EvalFinal.Delais")}
                        </label>
                        <p>{t("app.m2EvalFinal.DelaisDesc")}</p>
                        <DropdownList
                          style={
                            errors.deadlinesRespect &&
                            touched.deadlinesRespect && {
                              border: "2px solid #EA001E",

                              borderRadius: "5px",
                            }
                          }
                          id="deadlinesRespect"
                          dataKey="key"
                          textField="label"
                          data={
                            referentials.APPRENTICESHIP_SKILLS_EVALUATION && [
                              { ...objAucun },

                              ...referentials.APPRENTICESHIP_SKILLS_EVALUATION,
                            ]
                          }
                          value={values.deadlinesRespect}
                          onChange={(newValue) => setFieldValue("deadlinesRespect", newValue)}
                        />
                        <InputErrorMessage message={errors.deadlinesRespect} touched={touched.deadlinesRespect} />
                      </div>
                      <div className={`${styles.label_plus_input_container} input-like-rw`}>
                        <label htmlFor="companyTutorComment">{t("app.m2EvalFinal.Commentaires")}</label>
                        <textarea
                          style={
                            errors.companyTutorComment &&
                            touched.companyTutorComment && {
                              border: "2px solid #EA001E",
                            }
                          }
                          id="companyTutorComment"
                          rows="5"
                          maxLength={32768}
                          value={values.companyTutorComment}
                          onChange={(e) => setFieldValue("companyTutorComment", e.target.value.trimStart())}
                        ></textarea>
                        <InputErrorMessage message={errors.companyTutorComment} touched={touched.companyTutorComment} />
                      </div>
                      <div className={styles.checkbox_container}>
                        <label htmlFor="signature" className={styles.label_text}>
                          {t("app.m2EvalFinal.SignatureMaitre")}
                        </label>
                        <input
                          id="signature"
                          type="checkbox"
                          checked={values.signature}
                          onChange={(e) => setFieldValue("signature", e.target.checked)}
                        />
                        <InputErrorMessage message={errors.signature} touched={touched.signature} />
                      </div>
                    </div>
                    <SubmitFormBtn text={t("app.formulairesLivretPartnerBilan.BoutonValider")} />
                  </form>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    </>
  );
};

export default EndTermEvalSecondYear;
