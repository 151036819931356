import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../../../components/Header/Header";
import AcademicExperiences from "./subForms/AcademicExperiences/AcademicExperiences";
import AcademicProjects from "./subForms/AcademicProjects";
import AssociativeExperiences from "./subForms/AssociativeExperiences";
import ProExperiences from "./subForms/ProfessionalExperiences";
import { handleNexStep, handlePreviousStep } from "../../../../../app/slices/createYourCV/createYourCV.slice";
import PreviousFormBtn from "../../../../../components/PreviousFormBtn/PreviousFormBtn";
import NextStepButton from "../../../../../components/nextStepBtn/nextStepBtn";
import ResumeCardCv from "../../components/ResumeCardCv/ResumeCardCv";

import bgConsulOffreImg from "../../assets/bg-consul-offre.png";

import style from "../../CreateYourCV.module.scss";

const Experiences = () => {
  const dispatch = useDispatch();

  const [pending, setPending] = useState(false);

  const createPendingAndSubmit = () => {
    setPending(true);
    setTimeout(() => setPending(false), 100);
    setTimeout(() => dispatch(handleNexStep(), 200));
  };
  return (
    <>
      {pending && (
        <Header
          h1="Envoi des données en cours..."
          textElement={<p>La soumission de vos données est en cours, merci de patienter.</p>}
          bgImg={bgConsulOffreImg}
        />
      )}
      {!pending && (
        <>
          <Header h1="CREEZ VOTRE CV" textElement={<p>{t("app.depotCv.Etape3")}</p>} />
          <section className={style.main_container}>
            <div className={style.wrapper}>
              <div className={style.leftCol}>
                <div className={style.title_container}>
                  <h2> {t("app.depotCv.Experiences")}</h2>
                  <div>(Etape 3/5)</div>
                </div>
                <AcademicExperiences />
                <AcademicProjects />
                <AssociativeExperiences />
                <ProExperiences />
                <div className={style.form_container}>
                  <div
                    className={style.your_need_form}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <NextStepButton
                      text={t("app.depotCv.ValiderEtape")}
                      handleNextStep={() => createPendingAndSubmit()}
                    />
                    <PreviousFormBtn
                      text={t("app.depotCv.EtapePrecedente")}
                      handlePreviousStep={() => {
                        dispatch(handlePreviousStep());
                      }}
                    />
                  </div>
                </div>
              </div>
              <ResumeCardCv />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Experiences;
