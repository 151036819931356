import { t } from "i18next";

import Header from "components/Header/Header";

import { useSelector } from "react-redux";
import { getLearningBookletsIdData } from "app/slices/learningBooklets/learningBooklets.slice";
import BackButton from "../../components/BackButton/BackButton";

import bgContractImg from "../../../assets/bg-consul-offre.png";

import styles from "../../forms/IntermediateEvaluationM1/IntermediateEvaluationM1.module.scss";

const IntermediateEvaluationM1Valid = () => {
  const idData = useSelector(getLearningBookletsIdData);

  return (
    <div>
      <Header h1={t("app.formulairesLivretConfirmation.SuivezAlternant")} bgImg={bgContractImg} />
      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.container}>
            <form className={styles.the_form}>
              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.Competences")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="insertion" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Insertion")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.InsertionDesc")}</p>
                  <p>{idData?.insertion?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="teamSpirit" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Equipe")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.EquipeDesc")}</p>
                  <p>{idData?.teamSpirit?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="autonomy" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Autonomie")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.AutonomieDesc")}</p>
                  <p>{idData?.autonomy?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="initiative" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Initiative")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.InitiativeDesc")}</p>
                  <p>{idData?.initiative?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="standardAndRegulationRespect" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.RespectNormes")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.RespectNormesDesc")}</p>
                  <p>{idData?.standardAndRegulationRespect?.label}</p>
                </div>
              </div>
              <div className={styles.line} />

              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.Competences")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="planificationOrganisation" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Planification")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.PlanificationDesc")}</p>
                  <p>{idData?.planificationOrganisation?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="autoControl" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Controle")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ControleDesc")}</p>
                  <p>{idData?.autoControl?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="reporting" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Reporting")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ReportingDesc")}</p>
                  <p>{idData?.reporting?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="companyUnderstanding" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Comprehension")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ComprehensionDesc")}</p>
                  <p>{idData?.companyUnderstanding?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="communicationSkills" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.QualiteComm")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.QualiteCommDesc")}</p>
                  <p>{idData?.communicationSkills?.label}</p>
                </div>
              </div>
              <div className={styles.line} />
              <h3 className={styles.title}>{t("app.formulairesLivretConfirmation.CompetencesTech")}</h3>
              <div className={styles.box}>
                <div className={styles.inputs_container}>
                  <label htmlFor="knowledgeAcquisition" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Connaissances")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.ConnaissancesDesc")}</p>
                  <p>{idData?.knowledgeAcquisition?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="personalOrganizationWork" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.OrgaPerso")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.OrgaPersoDesc")}</p>
                  <p>{idData?.personalOrganizationWork?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="toolsAndMethodsMastery" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Outils")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.OutilsDesc")}</p>
                  <p>{idData?.toolsAndMethodsMastery?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="deadlinesRespect" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Delais")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.DelaisDesc")}</p>
                  <p>{idData?.deadlinesRespect?.label}</p>
                </div>
                <div className={styles.inputs_container}>
                  <label htmlFor="methodMastery" className={styles.label_text}>
                    {t("app.formulairesLivretConfirmation.Methodes")}
                  </label>
                  <p>{t("app.formulairesLivretConfirmation.MethodesDesc")}</p>
                  <p>{idData?.methodMastery?.label}</p>
                </div>
              </div>
              <div className={styles.submitButton}>
                <BackButton text={t("app.formulairesLivretPartnerBilan.BoutonRetour")} />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default IntermediateEvaluationM1Valid;
