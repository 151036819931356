import { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { getYourOfferCardProps } from "app/slices/followOffers/followOffers.slice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import * as Yup from "yup";
import { Listbox, DropdownList } from "react-widgets/cjs";
import {
  downloadFileCompanyThunk,
  getUploadFilePending,
  resetFileData,
  selectFileData,
  setFileData,
} from "app/slices/uploadFile/uploadFile.slice";
import DocumentsToDownload from "pages/ETU/ModifyYourCV/components/DocumentsToDownload/DocumentsToDownload";
import UploadDeleteOrganism from "components/UploadDeleteOrganism/UploadDeleteOrganism";
import Header from "components/Header/Header";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import { patchCompanyOfferThunk } from "../../../../app/slices/offerDetails/offerDetails.slice";
import SubmitFormBtn from "../../../../components/SubmitFormBtn/SubmitFormBtn";
import { createBooleanReferential, createObjAucun } from "../../../../utils/localReferentials";

import bgFollowOffersImg from "../assets/bg-follow-offre.png";

import styles from "./OfferDetails.module.scss";

/* eslint-disable */
const OfferDetails = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const referentialData = useSelector(getReferentialsData);
  const offerCardProps = useSelector(getYourOfferCardProps);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [generalEnable, setGeneralEnable] = useState(true);
  const [frequencyRemoteWorkIsEnable, setFrequencyRemoteWorkIsEnable] = useState(true);
  const [otherLaguageIsEnable, setOtherLaguageIsEnable] = useState(true);

  const [salaryPartIsEnable, setSalaryPartIsEnable] = useState(true);
  const [salaryAmountIsEnable, setSalaryAmountIsEnable] = useState(true);
  const [areaPostalcodeIsEnable, setAreaPostalcodeIsEnable] = useState(true);
  const [cityIsEnable, setCityIsEnable] = useState(true);

  const [otherCurenciIsEnable, setOtherCurencyIsEnable] = useState(true);
  const [urlToApplyIsEnable, setUrlToApplyIsEnable] = useState(true);
  const [mailToApplyIsEnable, setMailToApplyIsEnable] = useState(true);
  const [gratificationEnable, setGratificationEnabeled] = useState(true);
  const [iWouldLikeToTalkIsEnable, setIWouldLikeToTalkIsEnable] = useState(true);
  const [howtoApplyIsEnable, setHowtoApplyIsEnable] = useState(true);

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();
  const [handleFrChRegion, setHandleFrChRegion] = useState([]);

  const fileData = useSelector(selectFileData);
  const [supportingDocument, setSupportingDocument] = useState(null);
  const filePending = useSelector(getUploadFilePending);
  const [href, setHref] = useState(null);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);
  const [fileType, setFileType] = useState(null);

  useLayoutEffect(() => {
    if (offerCardProps?.status?.key === "Pourvue") {
      setGratificationEnabeled(false);
    }
    if (!offerCardProps) {
      navigate("/mes-offres");
    } else {
      setSupportingDocument(offerCardProps?.supportingDocument);
    }
  }, [offerCardProps]);

  useLayoutEffect(() => {
    if (offerCardProps) {
      console.log("useLayoutEffect");

      handleStatusIsEnable(offerCardProps?.status);

      handleRqth(offerCardProps?.position?.openToDisabledPeople);
      handleRemoteWork(offerCardProps?.localisation?.remoteWork);
      handleNeedCheckBox(offerCardProps?.availability?.needFrequency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("offerCardProps", offerCardProps);

  useLayoutEffect(() => {
    if (offerCardProps?.supportingDocument) {
      dispatch(setFileData(offerCardProps.supportingDocument));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    return () => {
      // axiosCancelToken.cancel();
      dispatch(resetFileData());
      console.log("OfferDetails unmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Yup validation schema
  const offerDetailSchema = Yup.object().shape({
    typeOfContract: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    targetLevelOfStudy: Yup.array().min(1, t("app.errorMessages.SelectionnerOption")),
    companyLineOfBusiness: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    department: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    immediateCheckbox: Yup.boolean(),
    recurrentCheckbox: Yup.boolean(),
    countryOfThePosition: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    startDate: Yup.date().typeError("Format de date non valide.").required(),
    region: Yup.object().when("countryOfThePosition", {
      is: (countryOfThePosition) => countryOfThePosition.key === "FR" || countryOfThePosition.key === "CH",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    flexibleDateCheckbox: Yup.boolean(),
    postalCode: Yup.string()
      .typeError("Remplissez ce champ.")
      .when("countryOfThePosition", {
        is: (countryOfThePosition) => countryOfThePosition.key === "FR" || countryOfThePosition.key === "CH",
        then: (schema) =>
          schema
            .min(5, t("app.errorMessages.NombreItemRequis"))
            .max(5, t("app.errorMessages.NombreItemRequis"))
            .required(t("app.errorMessages.RemplirChamp")),
      }),

    remoteWork: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    //DISPLAY ISSUES
    remoteWorkFrequency: Yup.object().when("remoteWork", {
      is: (remoteWork) => remoteWork.key === "Oui",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),

    city: Yup.string().when(["countryOfThePosition"], {
      is: (countryOfThePosition) => countryOfThePosition.key !== "FR" && countryOfThePosition.key !== "CH",
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),

    positionTitle: Yup.string().required(t("app.errorMessages.RemplirChamp")),

    offerOpenToPerson: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    jobDescritpion: Yup.string().required(t("app.errorMessages.RemplirChamp")),

    requiredLanguages: Yup.array().min(1, "Une option doit être sélectionnée."),
    otherLanguage: Yup.string().when("requiredLanguages", {
      is: (requiredLanguages) => requiredLanguages.some((obj) => obj.key === "Autre"),
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),

    currency: Yup.object().when(["paidIntership", "typeOfContract"], {
      is: (paidIntership, typeOfContract) =>
        paidIntership.key === "Oui" || typeOfContract.some((obj) => obj.key !== "Stage"),
      then: (schema) =>
        schema.test("if-is-currency", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),

    specifyIfOtherCurrency: Yup.string().when(["currency"], {
      is: (currency) => currency.key === "Autre",
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),

    paidIntership: Yup.object().when(["typeOfContract"], {
      is: (typeOfContract) => typeOfContract.some((obj) => obj.key === "Stage"),
      then: (schema) =>
        schema.test("if-is-stage", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),

    gratification: Yup.string().when(["paidIntership", "typeOfContract"], {
      is: (paidIntership, typeOfContract) =>
        paidIntership.key === "Oui" || typeOfContract.some((obj) => obj.key !== "Stage"),
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),

    iWouldLikeToTalk: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    howToApply: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    urlOfYourWebsite: Yup.string().when("howToApply", {
      is: (howToApply) => howToApply.key === "web",
      then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    }),

    emailAdressToApply: Yup.string().when("howToApply", {
      is: (howToApply) => howToApply.key === "email",
      then: (schema) => schema.email(t("app.errorMessages.MailFormat")).required(t("app.errorMessages.RemplirChamp")),
    }),
  });

  const convertDateToSend = (dateStr) => {
    console.log("convertDateToSend");

    var date = new Date(dateStr),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const convertSalary = (value) => {
    console.log("convertSalary");
    if (parseFloat(value) !== NaN) return parseFloat(value);
    else return parseFloat(0);
  };

  const onSubmit = (values, actions) => {
    const valuesCopy = structuredClone(values);
    // Logic to setup needFrequency in dataToSend
    const needFrequencyArray = [valuesCopy.immediateCheckbox, valuesCopy.recurrentCheckbox];

    const newNeedFrequencyArray = [];

    needFrequencyArray.forEach((value, index) => {
      if (value && index === 0) newNeedFrequencyArray.push("IMMEDIATE");
      if (value && index === 1) newNeedFrequencyArray.push("RECURRENT");
    });

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "Proposée",
      contractTypes: valuesCopy.typeOfContract.map((obj) => obj.key),
      position: {
        sector: valuesCopy.companyLineOfBusiness.key,
        subSector: valuesCopy.department.key,
        title: valuesCopy.positionTitle,
        description: valuesCopy.jobDescritpion,
        openToDisabledPeople: valuesCopy.offerOpenToPerson,
      },
      localisation: {
        country: valuesCopy.countryOfThePosition.key,
        city: valuesCopy.city,
        remoteWork: valuesCopy.remoteWork.key,
        remoteWorkFrequency: valuesCopy.remoteWorkFrequency.key,
        region: valuesCopy.region.key,
        zipCode: valuesCopy.postalCode,
      },
      requirements: {
        educationLevels: valuesCopy.targetLevelOfStudy.map((obj) => obj.key),
        requiredLanguages: {
          known: valuesCopy.requiredLanguages.map((obj) => obj.key),
          others: valuesCopy.otherLanguage ? [valuesCopy.otherLanguage] : [],
        },
      },
      availability: {
        needFrequency: newNeedFrequencyArray,
        startDate: convertDateToSend(valuesCopy.startDate),
        flexibleStartDate: valuesCopy.flexibleDateCheckbox,
      },
      gratification: {
        paid: valuesCopy.paidIntership.key,
        currency: valuesCopy.currency.key,
        otherCurrency: valuesCopy.specifyIfOtherCurrency,
        gratification: convertSalary(valuesCopy.gratification),
      },
      contact: {
        applicationChannel: valuesCopy.howToApply.key,
        wantToSpeakToAdministration: valuesCopy.iWouldLikeToTalk,
        applicationEmail: valuesCopy.emailAdressToApply,
        companyWebsiteURL: valuesCopy.urlOfYourWebsite,
      },
    };

    // if (!props.pending) {
    (async () => {
      try {
        await dispatch(
          patchCompanyOfferThunk({
            dataToSend,
            id: offerCardProps?.id,
            axiosCancelToken,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ModificationReussie")}
            secondMessage={t("app.toastMessages.OffreModifieeSucces")}
          />,
        );
        console.log("OfferDetail patch success !", dataToSend);
      } catch (error) {
        console.log("OfferDetail patch fail !", error.message);
      }
    })();
    // }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: offerCardProps && {
      // YOUR NEED PART
      typeOfContract: offerCardProps?.contractTypes ? offerCardProps?.contractTypes : [],
      targetLevelOfStudy: offerCardProps?.requirements?.educationLevels
        ? offerCardProps?.requirements?.educationLevels
        : [],
      companyLineOfBusiness: offerCardProps?.position?.sector ? offerCardProps?.position?.sector : { ...objAucun },
      department: offerCardProps?.position?.subSector ? offerCardProps?.position?.subSector : { ...objAucun },

      // DATE & PLACE PART
      immediateCheckbox: false,
      recurrentCheckbox: false,
      startDate: offerCardProps?.availability?.startDate ? new Date(offerCardProps?.availability?.startDate) : null,
      countryOfThePosition: offerCardProps?.localisation?.country
        ? offerCardProps?.localisation?.country
        : { ...objAucun },
      flexibleDateCheckbox: offerCardProps?.availability?.flexibleStartDate
        ? offerCardProps?.availability?.flexibleStartDate
        : false,
      region: offerCardProps?.localisation?.region ? offerCardProps?.localisation?.region : { ...objAucun },
      remoteWork: { ...objAucun },
      remoteWorkFrequency: offerCardProps?.localisation?.remoteWorkFrequency
        ? offerCardProps?.localisation?.remoteWorkFrequency
        : { ...objAucun },
      postalCode: offerCardProps?.localisation?.zipCode ? offerCardProps?.localisation?.zipCode : "",
      city: offerCardProps?.localisation?.city ? offerCardProps?.localisation?.city : "",

      // POSITION TO FILLED PART
      positionTitle: offerCardProps?.position?.title ? offerCardProps?.position?.title : "",
      jobDescritpion: offerCardProps?.position?.description ? offerCardProps?.position?.description : "",
      offerOpenToPerson: offerCardProps?.position?.offerOpenToPerson ? { key: "", label: "" } : { ...objAucun },
      requiredLanguages: offerCardProps?.requirements?.requiredLanguages?.known
        ? offerCardProps?.requirements?.requiredLanguages?.known
        : [],

      otherLanguage: offerCardProps?.requirements?.requiredLanguages?.others
        ? offerCardProps?.requirements?.requiredLanguages?.others
        : "", // MODIFY TO MAP WHEN MULTIPLE OTHER LANG WILL BE AVIALIABLE

      paidIntership: { ...objAucun },
      currency: offerCardProps?.gratification?.currency ? offerCardProps?.gratification?.currency : { ...objAucun },
      specifyIfOtherCurrency: offerCardProps?.gratification?.otherCurrency
        ? offerCardProps?.gratification?.otherCurrency
        : "",
      gratification: offerCardProps?.gratification?.gratification ? offerCardProps?.gratification?.gratification : "",
      howToApply: offerCardProps?.contact?.applicationChannel
        ? offerCardProps?.contact?.applicationChannel
        : { ...objAucun },
      urlOfYourWebsite: offerCardProps?.contact?.companyWebsiteURL ? offerCardProps?.contact?.companyWebsiteURL : "",
      emailAdressToApply: offerCardProps?.contact?.applicationEmail ? offerCardProps?.contact?.applicationEmail : "",
      iWouldLikeToTalk: { ...objAucun },
    },
    validationSchema: offerDetailSchema,
    onSubmit,
  });

  console.log("FORMIK ERROR", errors);

  // Handle the closing of an offer
  const closeTheOffer = () => {
    (async () => {
      try {
        console.log("CLOTURE 1");
        await dispatch(
          patchCompanyOfferThunk({
            dataToSend: { status: "Pourvue" },
            id: offerCardProps?.id,
            axiosCancelToken,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ClotureOffre")}
            secondMessage={t("app.toastMessages.ClotureSucces")}
          />,
        );
        console.log("OfferDetail put success ! CLOSE OFFER");
        setGratificationEnabeled(false);
        setGeneralEnable(true);
        setFrequencyRemoteWorkIsEnable(true);
        setCityIsEnable(true);
        setSalaryPartIsEnable(true);
        setIWouldLikeToTalkIsEnable(true);
        setSalaryAmountIsEnable(true);
        setHowtoApplyIsEnable(true);
        setOtherCurencyIsEnable(true);
        setOtherLaguageIsEnable(true);
        setUrlToApplyIsEnable(true);
        setMailToApplyIsEnable(true);
        setAreaPostalcodeIsEnable(true);
      } catch (error) {
        toastError(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ClotureOffre")}
            secondMessage={t("app.toastMessages.ClotureFail")}
          />,
        );
        console.log("OfferDetail post fail ! CLOSE OFFER", error.message);
      }
    })();
    console.log("CLOTURE FINAL");
  };

  // Enable the fields depending on the status / Fromat value before display
  const handleStatusIsEnable = (value) => {
    if (value !== undefined) {
      switch (value.key) {
        case "Pourvue":
          setGeneralEnable(true);
          break;
        default:
          setGeneralEnable(false);
          handleAreaPostalcodeEnable(offerCardProps?.localisation?.country, false, true);
          otherLaguageEnable(offerCardProps?.requirements?.requiredLanguages, false, true);
          otherCurrencyEnable(offerCardProps?.gratification?.currency, false);
          handleSalaryPartEnable(offerCardProps?.contractTypes, offerCardProps?.gratification, false);
          handleDefaultWouldLikeToTalk(offerCardProps?.contact?.wantToSpeakToAdministration, false);
          handleHowToApplyEnable(offerCardProps?.contact?.applicationChannel, false);
          handlefrequencyRemoteWorkEnable(offerCardProps?.localisation?.remoteWork, false);
          break;
      }
    }
  };

  const handleSalaryPartEnable = (value, gratification, mountEnabler) => {
    let tmpIndicator = false;
    let onlyIntership = true;

    for (let i = 0; i < value?.length; i++) {
      if (value[i]?.key === "Stage") {
        tmpIndicator = true;
      } else onlyIntership = false;
    }

    if (tmpIndicator === true) {
      setSalaryPartIsEnable(false);
      if (gratification?.paid?.key === "Oui" || gratification?.paid === true) {
        setFieldValue("paidIntership", { key: "Oui", label: "Oui" });
        setFieldValue("gratification", gratification?.gratification);
        setSalaryAmountIsEnable(false);
      } else if (gratification?.paid === false || gratification?.paid?.key === "Non") {
        if (onlyIntership) {
          setSalaryPartIsEnable(false);
          setFieldValue("paidIntership", { key: "Non", label: "Non" });
          setFieldValue("gratification", "");
          setSalaryAmountIsEnable(true);
        } else {
          setFieldValue("paidIntership", { key: "Non", label: "Non" });
          setSalaryPartIsEnable(false);
          setSalaryAmountIsEnable(false);
        }
      }
    } else {
      setFieldValue("paidIntership", { key: "-Aucun-", label: "-Aucun-" });
      setFieldValue("gratification", gratification?.gratification);
      setSalaryPartIsEnable(true);
      setSalaryAmountIsEnable(false);
    }

    if (mountEnabler !== false) {
      setSalaryAmountIsEnable(true);
      setSalaryPartIsEnable(true);
    }
  };

  const handleNeedCheckBox = (value) => {
    let immediateBox = false;
    let recurentBox = false;

    for (let i = 0; i < value?.length; i++) {
      if (value[i] === "IMMEDIATE") {
        setFieldValue("immediateCheckbox", true);
        // immediateBox = true;
      } else if (value[i] === "RECURRENT") {
        setFieldValue("recurrentCheckbox", true);
        // recurentBox = true;
      }
    }
    return [immediateBox, recurentBox];
  };

  const handleAreaPostalcodeEnable = (value, mountEnabler, firstCall) => {
    if (value?.key === "FR" || value?.key === "CH" || value?.key === "-Aucun-") {
      setFieldValue("city", "");
      setAreaPostalcodeIsEnable(false);
      setCityIsEnable(true);
      switch (value?.key) {
        case "FR":
          setHandleFrChRegion(offerCardProps?.referencialData?.REGION_FR);
          if (!firstCall) {
            setFieldValue("region", { ...objAucun });
            setFieldValue("postalCode", "");
          }
          break;
        case "CH":
          setHandleFrChRegion(offerCardProps?.referencialData?.REGION_CH);
          if (!firstCall) {
            setFieldValue("region", { ...objAucun });
            setFieldValue("postalCode", "");
          }
          break;
        default:
          break;
      }
    } else {
      setFieldValue("postalCode", "");
      setFieldValue("region", { key: "-Aucun-", label: "-Aucun-" });
      setAreaPostalcodeIsEnable(true);
      setCityIsEnable(false);
    }
    if (mountEnabler !== false) {
      setAreaPostalcodeIsEnable(true);
      setCityIsEnable(true);
    }
  };

  const handleRemoteWork = (value) => {
    if (value === true) {
      setFieldValue("remoteWork", { key: "Oui", label: "Oui" });
    } else {
      setFieldValue("remoteWork", { key: "Non", label: "Non" });
    }
  };

  const handlefrequencyRemoteWorkEnable = (value, mountEnabler) => {
    if (value?.key === "Oui" || (value === true && !mountEnabler)) {
      setFrequencyRemoteWorkIsEnable(false);
    } else {
      setFrequencyRemoteWorkIsEnable(true);
      setFieldValue("remoteWorkFrequency", {
        key: "-Aucun-",
        label: "-Aucun-",
      });
    }
  };

  const handleRqth = (value) => {
    if (value === true) setFieldValue("offerOpenToPerson", { key: "Oui", label: "Oui" });
    else setFieldValue("offerOpenToPerson", { key: "Non", label: "Non" });
  };

  const otherLaguageEnable = (value, mountEnabler, firstCall) => {
    let findOther = false;
    let otherLang = [];

    let langArray = [];

    if (firstCall) langArray = value?.known;
    else langArray = value;

    for (let i = 0; i < langArray?.length; i++) {
      if (langArray[i]?.key === "Autre") {
        if (firstCall) otherLang = value?.others[0]; // MODIFY TO A PUSH WHEN WE WILL BE ABLE TO DISPLAY MORE THANT 1 OTHER LANG
        findOther = true;
      }
    }
    if (findOther === true) {
      if (firstCall) setFieldValue("otherLanguage", otherLang);
      setOtherLaguageIsEnable(false);
    } else {
      setFieldValue("otherLanguage", "");
      setOtherLaguageIsEnable(true);
    }
    if (mountEnabler !== false) setOtherLaguageIsEnable(true);
  };

  const otherCurrencyEnable = (value, mountEnabler) => {
    if (value?.key === "Autre" && !mountEnabler) setOtherCurencyIsEnable(false);
    else setOtherCurencyIsEnable(true);

    if (value?.key !== "Autre") setFieldValue("specifyIfOtherCurrency", "");
  };

  const paidIntershipEnalbe = (value) => {
    let tmpIndicator = false;
    let onlyIntership = true;

    for (let i = 0; i < values.typeOfContract.length; i++) {
      if (values.typeOfContract[i].key === "Stage") tmpIndicator = true;
      else onlyIntership = false;
    }
    if (value.key === "Oui") {
      setSalaryAmountIsEnable(false);
    } else if (value.key === "Non" && onlyIntership) {
      setSalaryAmountIsEnable(true);
      setFieldValue("gratification", "");
    }
  };

  const handleDefaultWouldLikeToTalk = (value, mountEnabler) => {
    if (value === false) setFieldValue("iWouldLikeToTalk", { key: "Non", label: "Non" });
    else setFieldValue("iWouldLikeToTalk", { key: "Oui", label: "Oui" });

    if (mountEnabler === false) {
      setIWouldLikeToTalkIsEnable(false);
    }
  };

  const handleHowToApplyEnable = (value, mountEnabler) => {
    if (value?.key === "Via cette plateforme") {
      setFieldValue("urlOfYourWebsite", "");
      setFieldValue("emailAdressToApply", "");

      setUrlToApplyIsEnable(true);
      setMailToApplyIsEnable(true);
    } else {
      switch (value?.key) {
        case "Via une adresse email":
          setFieldValue("urlOfYourWebsite", "");
          if (!mountEnabler) {
            setUrlToApplyIsEnable(true);
            setMailToApplyIsEnable(false);
          }
          break;
        case "Via votre site internet":
          setFieldValue("emailAdressToApply", "");
          if (!mountEnabler) {
            setUrlToApplyIsEnable(false);
            setMailToApplyIsEnable(true);
          }
          break;

        default:
          setUrlToApplyIsEnable(true);
          setMailToApplyIsEnable(true);
          break;
      }
    }

    if (!mountEnabler) {
      setHowtoApplyIsEnable(false);
    }
  };

  const handleType = (type) => {
    switch (type) {
      case "ai" || "eps":
        return "application/postscript";
      case "txt":
        return "text/plain";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "html":
        return "text/html";
      case "csv":
        return "text/csv";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "xls":
        return "application/vnd.ms-excel";
      case "ods":
        return "application/vnd.oasis.opendocument.spreadsheet";
      case "exe":
        return "application/octet-stream";
      case "mp4":
        return "video/mp4";
      case "pgs":
        return "text/plain";
      case "psd":
        return "image/vnd.adobe.photoshop";
      case "rtf":
        return "application/rtf";
      case "xml":
        return "application/xml";
      case "xmd":
        return "application/octet-stream";
      case "zip":
        return "application/zip";
      default:
        return "application/zip";
    }
  };

  useEffect(() => {
    if (offerCardProps?.supportingDocument?.downloadUrl !== undefined) {
      (async () => {
        try {
          setFileType(handleType(offerCardProps?.supportingDocument?.extension));
          await dispatch(
            downloadFileCompanyThunk({
              id: offerCardProps?.id,
              axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: fileType }));
              setHref(href);
              window.URL.revokeObjectURL(url);
            });
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={supportingDocument?.name}
            />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
  }, [offerCardProps]);

  const handleUpload = () => {
    if (!filePending) {
      (async () => {
        try {
          await dispatch(
            downloadFileCompanyThunk({
              id: offerCardProps?.id,
              axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: "application/zip" }));
              setHref(href);
            });
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersTelecharges")}
              secondMessage={`${t("app.toastMessages.FichiersTelechargesMessage")} 1`}
            />,
          );
          setSpanErrorMessage(false);
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={supportingDocument.name}
            />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Header
        h1={t("app.suivezOffres.SuivezOffre")}
        textElement={<p>{t("app.suivezOffres.Intro1")}</p>}
        bgImg={bgFollowOffersImg}
      />

      <section className={styles.main_container}>
        {offerCardProps === null && <div>Chargement en cours...</div>}
        {offerCardProps !== null && referentialData && (
          <>
            <div className={styles.title_container}>
              <span className={styles.main_title}>{t("app.visualisationOffre.InformationOffre")}</span>
              <p className={styles.offer_number_title}>{offerCardProps?.name}</p>
            </div>

            <div className={styles.export_close_offer_container}>
              <div className={styles.cloture_btn} onClick={closeTheOffer}>
                <span className={styles.closure_btn_content}>{t("app.visualisationOffre.CloturezOffre")}</span>
              </div>
            </div>

            <div className={styles.forms_main_container}>
              <form className={styles.date_and_place_form} onSubmit={handleSubmit}>
                {/** YOUR NEDD START */}
                <div>
                  <span className={styles.forms_section_title}>{t("app.visualisationOffre.VotreBesoin")}</span>
                  <div className={styles.inputs_container}>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="typeOfContract">{t("app.visualisationOffre.TypeContrat")}</label>
                      <Listbox
                        style={
                          errors.typeOfContract &&
                          touched.typeOfContract && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="typeOfContract"
                        multiple
                        dataKey="key"
                        textField="label"
                        data={[...referentialData?.CONTRACT_TYPE]}
                        value={values.typeOfContract}
                        disabled={generalEnable}
                        onChange={(newValue) => {
                          setFieldValue("typeOfContract", newValue);
                          handleSalaryPartEnable(newValue, values, generalEnable);
                          setFieldValue("gratification", "");
                        }}
                      />
                      <InputErrorMessage message={errors.typeOfContract} touched={touched.typeOfContract} />
                    </div>
                    <div className={styles.label_plus_input_container}>
                      <label htmlFor="targetLevelOfStudy">{t("app.visualisationOffre.NiveauEtudes")}</label>
                      <Listbox
                        style={
                          errors.targetLevelOfStudy &&
                          touched.targetLevelOfStudy && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        }
                        id="targetLevelOfStudy"
                        multiple
                        dataKey="key"
                        textField="label"
                        className={styles.targetLevelOfStudy}
                        disabled={generalEnable}
                        data={[...referentialData?.STUDENT_EDUCATION_LEVEL]}
                        value={values.targetLevelOfStudy}
                        onChange={(newValue) => setFieldValue("targetLevelOfStudy", newValue)}
                      />
                      <InputErrorMessage message={errors.targetLevelOfStudy} touched={touched.targetLevelOfStudy} />
                    </div>
                    <div>
                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="companyLineOfBusiness">{t("app.visualisationOffre.SecteurActivite")}</label>
                        <DropdownList
                          style={
                            errors.companyLineOfBusiness &&
                            touched.companyLineOfBusiness && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="companyLineOfBusiness"
                          dataKey="key"
                          textField="label"
                          disabled={generalEnable}
                          data={[...referentialData?.COMPANY_JOB_OFFER_SECTOR]}
                          value={values.companyLineOfBusiness}
                          onChange={(newValue) => setFieldValue("companyLineOfBusiness", newValue)}
                        />
                        <InputErrorMessage
                          message={errors.companyLineOfBusiness}
                          touched={touched.companyLineOfBusiness}
                        />
                      </div>

                      <div className={styles.label_plus_input_container}>
                        <label htmlFor="department">{t("app.visualisationOffre.Metier")}</label>
                        <DropdownList
                          style={
                            errors.department &&
                            touched.department && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="department"
                          dataKey="key"
                          textField="label"
                          data={[...referentialData?.COMPANY_JOB_OFFER_SUBSECTOR]}
                          disabled={generalEnable}
                          value={values.department}
                          onChange={(newValue) => setFieldValue("department", newValue)}
                        />
                        <InputErrorMessage message={errors.department} touched={touched.department} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.section_separator}></div>
                </div>
                {/** YOUR NEDD END */}

                {/** DATE & PLACE START */}
                <div>
                  <span className={styles.forms_section_title}>{t("app.visualisationOffre.DateLieu")}</span>
                  <div className={styles.date_and_place_container}>
                    <div className={styles.sub_card_one}>
                      <div className={styles.sub_card_two}>
                        <div className={styles.label_plus_input_container}>
                          <span className={styles.label}>{t("app.visualisationOffre.Recurrence")}</span>
                          <div className={styles.checkbox_row}>
                            <div className={styles.checkbox_container}>
                              <input
                                id="immediateCheckbox"
                                type="checkbox"
                                disabled={generalEnable}
                                checked={values.immediateCheckbox}
                                onChange={(e) => setFieldValue("immediateCheckbox", e.target.checked)}
                              />
                              <div>{t("app.visualisationOffre.Immediat")}</div>
                            </div>
                            <div className={styles.checkbox_container}>
                              <input
                                id="recurrentCheckbox"
                                type="checkbox"
                                disabled={generalEnable}
                                checked={values.recurrentCheckbox}
                                onChange={(e) => setFieldValue("recurrentCheckbox", e.target.checked)}
                              />
                              <div>{t("app.visualisationOffre.Recurrent")}</div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.label_plus_input_container}>
                          <span className={styles.label}>{t("app.visualisationOffre.DateDebut")}</span>
                          {/* <DatePicker
                            style={
                              errors.startDate &&
                              touched.startDate && {
                                border: "2px solid #EA001E",
                                borderRadius: "5px",
                              }
                            }
                            disabled={generalEnable}
                            id="startDate"
                            value={values.startDate}
                            onChange={(value) => setFieldValue("startDate", value)}
                          /> */}
                          <DatePickerCustom
                            error={errors.startDate}
                            touched={touched.startDate}
                            disabled={generalEnable}
                            id="startDate"
                            value={values.startDate}
                            onChange={(value) => setFieldValue("startDate", value)}
                          />
                          <InputErrorMessage message={errors.startDate} touched={touched.startDate} />
                        </div>
                      </div>

                      <div className={styles.sub_card_two}>
                        <div className={styles.label_plus_input_container}>
                          <label htmlFor="countryOfThePosition" className={styles.label}>
                            {t("app.visualisationOffre.LieuPoste")}
                          </label>
                          <DropdownList
                            style={
                              errors.countryOfThePosition &&
                              touched.countryOfThePosition && {
                                border: "2px solid #EA001E",
                                borderRadius: "5px",
                              }
                            }
                            id="countryOfThePosition"
                            dataKey="key"
                            textField="label"
                            disabled={generalEnable}
                            data={[...referentialData?.COUNTRY]}
                            value={values.countryOfThePosition}
                            onChange={(newValue) => {
                              setFieldValue("countryOfThePosition", newValue);
                              handleAreaPostalcodeEnable(newValue, generalEnable, false);
                            }}
                          />
                          <InputErrorMessage
                            message={errors.countryOfThePosition}
                            touched={touched.countryOfThePosition}
                          />
                        </div>
                        <div className={styles.label_plus_input_container}>
                          <label htmlFor="region" className={styles.label}>
                            {t("app.visualisationOffre.Region")}
                          </label>
                          <DropdownList
                            style={
                              errors.region &&
                              touched.region && {
                                border: "2px solid #EA001E",
                                borderRadius: "5px",
                              }
                            }
                            id="region"
                            dataKey="key"
                            textField="label"
                            disabled={areaPostalcodeIsEnable}
                            data={handleFrChRegion}
                            value={values.region}
                            onChange={(newValue) => setFieldValue("region", newValue)}
                          />
                          <InputErrorMessage message={errors.region} touched={touched.region} />
                        </div>
                      </div>
                    </div>

                    <div className={styles.sub_card_one}>
                      <div className={styles.sub_card_two}>
                        <div className={styles.label_plus_input_container}>
                          <span className={styles.label}>{t("app.visualisationOffre.DatesFlexibles")}</span>
                          <div className={`${styles.checkbox_row} ${styles.jcontentStart}`}>
                            <div className={styles.checkbox_container}>
                              <input
                                id="flexibleDateCheckbox"
                                type="checkbox"
                                checked={values.flexibleDateCheckbox}
                                disabled={generalEnable}
                                onChange={(e) => setFieldValue("flexibleDateCheckbox", e.target.checked)}
                              />
                              <div>{t("app.visualisationOffre.Oui")}</div>
                            </div>
                            <div className={styles.checkbox_container}>
                              <input
                                id="flexibleDateCheckbox"
                                type="checkbox"
                                checked={!values.flexibleDateCheckbox}
                                disabled={generalEnable}
                                onChange={(e) => setFieldValue("flexibleDateCheckbox", !e.target.checked)}
                              />
                              <div>{t("app.visualisationOffre.Non")}</div>
                            </div>
                          </div>
                        </div>

                        <div className={styles.telework_container}>
                          <div className={styles.label_plus_input_telework_container}>
                            <label htmlFor="remoteWork" className={styles.label}>
                              {t("app.visualisationOffre.Teletravail")}
                            </label>
                            <DropdownList
                              style={
                                errors.remoteWork &&
                                touched.remoteWork && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="remoteWork"
                              dataKey="key"
                              textField="label"
                              data={YesNo}
                              disabled={generalEnable}
                              value={values.remoteWork}
                              onChange={(newValue) => {
                                setFieldValue("remoteWork", {
                                  key: "-Aucun-",
                                  label: "- Aucun -",
                                });
                                setFieldValue("remoteWork", newValue);
                                handlefrequencyRemoteWorkEnable(newValue, generalEnable);
                              }}
                            />
                            <InputErrorMessage message={errors.remoteWork} touched={touched.remoteWork} />
                          </div>
                          <div className={styles.label_plus_input_telework_container}>
                            <label htmlFor="remoteWorkFrequency" className={styles.label}>
                              {t("app.visualisationOffre.FrequenceDuTeletravail")}
                            </label>
                            <DropdownList
                              style={
                                errors.remoteWorkFrequency &&
                                touched.remoteWorkFrequency && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="remoteWorkFrequency"
                              dataKey="key"
                              textField="label"
                              data={[...referentialData?.REMOTE_WORK_FREQUENCY]}
                              disabled={frequencyRemoteWorkIsEnable}
                              value={values.remoteWorkFrequency}
                              onChange={(newValue) => setFieldValue("remoteWorkFrequency", newValue)}
                            />
                            <InputErrorMessage
                              message={errors.remoteWorkFrequency}
                              touched={touched.remoteWorkFrequency}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.sub_card_two}>
                        <div className={`${styles.label_plus_input_container} input-like-rw`}>
                          <label htmlFor="postalCode" className={styles.label}>
                            {t("app.visualisationOffre.CodePostal")}
                          </label>
                          <input
                            style={
                              errors.postalCode &&
                              touched.postalCode && {
                                border: "2px solid #EA001E",
                              }
                            }
                            id="postalCode"
                            className={styles.codePostal}
                            type="number"
                            min="0"
                            disabled={areaPostalcodeIsEnable}
                            value={values.postalCode}
                            onChange={(e) => setFieldValue("postalCode", e.target.value.trim())}
                          />
                          <InputErrorMessage message={errors.postalCode} touched={touched.postalCode} />
                        </div>

                        <div className={`${styles.label_plus_input_container} input-like-rw`}>
                          <label htmlFor="city" className={styles.label}>
                            {t("app.visualisationOffre.Ville")}
                          </label>
                          <input
                            style={
                              errors.city &&
                              touched.city && {
                                border: "2px solid #EA001E",
                              }
                            }
                            id="city"
                            maxLength={255}
                            type="text"
                            disabled={cityIsEnable}
                            value={values.city}
                            onChange={(e) => setFieldValue("city", e.target.value.trim())}
                          />
                          <InputErrorMessage message={errors.city} touched={touched.city} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.section_separator}></div>
                </div>
                {/** DATE & PLACE END */}

                {/** POSITION TO FILLED START */}
                <div>
                  <span className={styles.forms_section_title}>{t("app.visualisationOffre.PostePourvoir")}</span>
                  <div className={styles.position_to_fufilled_container}>
                    <div className={styles.top_container}>
                      <div className={styles.top_left_section}>
                        <div className={`${styles.label_plus_input_container} input-like-rw`}>
                          <div className={styles.title_txt_section}>
                            <label htmlFor="positionTitle" className={styles.label}>
                              {t("app.visualisationOffre.IntitulePoste")}
                            </label>
                            <input
                              style={
                                errors.positionTitle &&
                                touched.positionTitle && {
                                  border: "2px solid #EA001E",
                                }
                              }
                              id="positionTitle"
                              maxLength={255}
                              type="text"
                              disabled={generalEnable}
                              value={values.positionTitle}
                              onChange={(e) => setFieldValue("positionTitle", e.target.value)}
                            />
                            <InputErrorMessage message={errors.positionTitle} touched={touched.positionTitle} />
                          </div>
                        </div>
                        <div className={styles.label_plus_input_container}>
                          <div className={styles.title_txt_section}>
                            <label htmlFor="offerOpenToPerson" className={styles.label}>
                              {t("app.visualisationOffre.OffreOuverteRQTH")}
                              <p className={styles.rqth_info}>{t("app.visualisationOffre.OuvertRQTHHelpText")}</p>
                            </label>
                            <div className={styles.info_bubble}>i</div>
                            <DropdownList
                              style={
                                errors.offerOpenToPerson &&
                                touched.offerOpenToPerson && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="offerOpenToPerson"
                              dataKey="key"
                              textField="label"
                              data={YesNo}
                              disabled={generalEnable}
                              value={values.offerOpenToPerson}
                              onChange={(newValue) => setFieldValue("offerOpenToPerson", newValue)}
                            />
                            <InputErrorMessage message={errors.offerOpenToPerson} touched={touched.offerOpenToPerson} />
                          </div>
                        </div>
                      </div>

                      <div className={styles.position_description_container}>
                        <div className={`${styles.label_plus_input_container} input-like-rw`}>
                          <div className={styles.title_txt_section}>
                            <label htmlFor="jobDescritpion" className={styles.label}>
                              {t("app.visualisationOffre.DescriptionPoste")}
                            </label>
                            <textarea
                              style={
                                errors.jobDescritpion &&
                                touched.jobDescritpion && {
                                  border: "2px solid #EA001E",
                                }
                              }
                              id="jobDescritpion"
                              maxLength={32768}
                              rows="5"
                              type="text"
                              className={styles.description_input}
                              disabled={generalEnable}
                              value={values.jobDescritpion}
                              onChange={(e) => setFieldValue("jobDescritpion", e.target.value)}
                            />
                            <InputErrorMessage message={errors.jobDescritpion} touched={touched.jobDescritpion} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.middle_container}>
                      <div className={styles.label_plus_input_language_container}>
                        <label htmlFor="requiredLanguages" className={styles.label}>
                          {t("app.visualisationOffre.LanguesRequises")}
                        </label>
                        <Listbox
                          style={
                            errors.requiredLanguages &&
                            touched.requiredLanguages && {
                              border: "2px solid #EA001E",
                              borderRadius: "5px",
                            }
                          }
                          id="requiredLanguages"
                          multiple
                          dataKey="key"
                          textField="label"
                          data={[...referentialData?.LANGUAGE]}
                          disabled={generalEnable}
                          value={values.requiredLanguages}
                          onChange={(newValue) => {
                            console.log("requiredLanguages", newValue);
                            setFieldValue("requiredLanguages", newValue);
                            otherLaguageEnable(newValue, generalEnable, false);
                          }}
                        />
                        <InputErrorMessage message={errors.requiredLanguages} touched={touched.requiredLanguages} />
                      </div>
                      <div className={styles.middle_right_container}>
                        <div className={styles.section}>
                          <div className={`${styles.label_plus_input_container} input-like-rw`}>
                            <div className={styles.title_txt_section}>
                              <label htmlFor="otherLanguage" className={styles.label}>
                                {t("app.visualisationOffre.PreciserAutreLangue")}
                              </label>
                              <input
                                style={
                                  errors.otherLanguage &&
                                  touched.otherLanguage && {
                                    border: "2px solid #EA001E",
                                  }
                                }
                                id="otherLanguage"
                                type="text"
                                disabled={otherLaguageIsEnable}
                                value={values.otherLanguage}
                                onChange={(e) => setFieldValue("otherLanguage", e.target.value.trim())}
                              />
                              <InputErrorMessage message={errors.otherLanguage} touched={touched.otherLanguage} />
                            </div>
                          </div>
                          <div className={styles.label_plus_input_container}>
                            <label htmlFor="currency" className={styles.label}>
                              {t("app.visualisationOffre.Devise")}
                            </label>
                            <DropdownList
                              style={
                                errors.currency &&
                                touched.currency && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="currency"
                              dataKey="key"
                              textField="label"
                              data={[...referentialData?.CURRENCY]}
                              disabled={generalEnable}
                              value={values.currency}
                              onChange={(newValue) => {
                                setFieldValue("specifyIfOtherCurrency", "");
                                setFieldValue("currency", newValue);
                                otherCurrencyEnable(newValue, generalEnable);
                              }}
                            />
                            <InputErrorMessage message={errors.currency} touched={touched.currency} />
                          </div>
                          <div className={`${styles.label_plus_input_container} input-like-rw`}>
                            <label htmlFor="specifyIfOtherCurrency" className={styles.label}>
                              {t("app.visualisationOffre.PrecisezAutreDevise")}
                            </label>
                            <input
                              style={
                                errors.specifyIfOtherCurrency &&
                                touched.specifyIfOtherCurrency && {
                                  border: "2px solid #EA001E",
                                }
                              }
                              id="specifyIfOtherCurrency"
                              type="text"
                              maxLength={255}
                              disabled={otherCurenciIsEnable}
                              value={values.specifyIfOtherCurrency}
                              onChange={(e) => setFieldValue("specifyIfOtherCurrency", e.target.value.trim())}
                            />
                            <InputErrorMessage
                              message={errors.specifyIfOtherCurrency}
                              touched={touched.specifyIfOtherCurrency}
                            />
                          </div>
                        </div>
                        <div className={styles.section}>
                          <div className={styles.label_plus_input_container}>
                            <label htmlFor="paidIntership" className={styles.label}>
                              {t("app.visualisationOffre.StageRemunere")}
                            </label>
                            <DropdownList
                              id="paidIntership"
                              dataKey="key"
                              textField="label"
                              data={[...YesNo]}
                              disabled={salaryPartIsEnable}
                              value={values.paidIntership}
                              onChange={(newValue) => {
                                setFieldValue("paidIntership", newValue);
                                paidIntershipEnalbe(newValue);
                                setFieldValue("gratification", "");
                              }}
                            />
                            <InputErrorMessage message={errors.paidIntership} touched={touched.paidIntership} />
                          </div>

                          <div className={`${styles.label_plus_input_container} input-like-rw`}>
                            <div className={styles.title_txt_section}>
                              <label htmlFor="gratification" className={styles.label}>
                                {t("app.visualisationOffre.Salaire")}
                              </label>
                              <input
                                style={
                                  errors.gratification &&
                                  touched.gratification && {
                                    border: "2px solid #EA001E",
                                    borderRadius: "5px",
                                  }
                                }
                                id="gratification"
                                type="number"
                                // disabled={salaryAmountIsEnable}
                                disabled={
                                  (values.paidIntership.key === "Oui" ||
                                    values.typeOfContract.some((obj) => obj.key !== "Stage")) &&
                                  gratificationEnable === true
                                    ? false
                                    : true
                                }
                                value={values.gratification}
                                onChange={(e) => {
                                  setFieldValue("gratification", e.target.value.trim());
                                  //values.gratification = e.target.value;
                                }}
                              />
                              <InputErrorMessage message={errors.gratification} touched={touched.gratification} />
                            </div>
                          </div>

                          <div className={styles.label_plus_input_container}>
                            <label htmlFor="iWouldLikeToTalk" className={styles.label}>
                              {t("app.visualisationOffre.EchangerServiceRelation")}
                            </label>
                            <DropdownList
                              style={
                                errors.iWouldLikeToTalk &&
                                touched.iWouldLikeToTalk && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="iWouldLikeToTalk"
                              dataKey="key"
                              textField="label"
                              data={YesNo}
                              disabled={iWouldLikeToTalkIsEnable}
                              value={values.iWouldLikeToTalk}
                              onChange={(newValue) => setFieldValue("iWouldLikeToTalk", newValue)}
                            />
                            <InputErrorMessage message={errors.iWouldLikeToTalk} touched={touched.iWouldLikeToTalk} />
                          </div>
                        </div>

                        <div className={styles.section}>
                          <div className={styles.label_plus_input_container}>
                            <label htmlFor="howToApply" className={styles.label}>
                              {t("app.visualisationOffre.ModaliteCandidature")}
                            </label>
                            <DropdownList
                              style={
                                errors.howToApply &&
                                touched.howToApply && {
                                  border: "2px solid #EA001E",
                                  borderRadius: "5px",
                                }
                              }
                              id="howToApply"
                              dataKey="key"
                              textField="label"
                              data={[...referentialData?.COMPANY_JOB_OFFER_APPLICATION_CHANNEL]}
                              disabled={howtoApplyIsEnable}
                              value={values.howToApply}
                              onChange={(newValue) => {
                                setFieldValue("howToApply", newValue);
                                handleHowToApplyEnable(newValue, generalEnable);
                              }}
                            />
                            <InputErrorMessage message={errors.howToApply} touched={touched.howToApply} />
                          </div>
                          <div className={`${styles.label_plus_input_container} input-like-rw`}>
                            <label htmlFor="urlOfYourWebsite" className={styles.label}>
                              {t("app.visualisationOffre.URLVotreSite")}
                            </label>
                            <input
                              style={
                                errors.urlOfYourWebsite &&
                                touched.urlOfYourWebsite && {
                                  border: "2px solid #EA001E",
                                }
                              }
                              id="urlOfYourWebsite"
                              type="text"
                              disabled={urlToApplyIsEnable}
                              value={values.urlOfYourWebsite}
                              onChange={(e) => setFieldValue("urlOfYourWebsite", e.target.value)}
                            />
                            <InputErrorMessage message={errors.urlOfYourWebsite} touched={touched.urlOfYourWebsite} />
                          </div>
                          <div className={`${styles.label_plus_input_container} input-like-rw`}>
                            <label htmlFor="emailAdressToApply" className={styles.label}>
                              {t("app.visualisationOffre.AdresseEmailPostuler")}
                            </label>
                            <input
                              style={
                                errors.emailAdressToApply &&
                                touched.emailAdressToApply && {
                                  border: "2px solid #EA001E",
                                }
                              }
                              id="emailAdressToApply"
                              type="email"
                              disabled={mailToApplyIsEnable}
                              value={values.emailAdressToApply}
                              onChange={(e) => setFieldValue("emailAdressToApply", e.target.value)}
                            />
                            <InputErrorMessage
                              message={errors.emailAdressToApply}
                              touched={touched.emailAdressToApply}
                            />
                          </div>
                        </div>

                        <UploadDeleteOrganism
                          id={offerCardProps?.id}
                          middleware="company"
                          endpoint={`/v1/companies/job-offers/${offerCardProps?.id}/supporting-documents`}
                          labelText={t("app.declarezUnRecrutement.DeposezUnDocument")}
                          uploadMessages={{
                            success: {
                              firstMessage: t("app.toastMessages.FichiersAjoutes"),
                              secondMessage: `${t("app.toastMessages.FichiersAjoutesMessage")}`,
                            },
                            error: {
                              firstMessage: t("app.toastMessages.genericError"),
                            },
                          }}
                          deleteMessages={{
                            success: {
                              firstMessage: t("app.toastMessages.genericDeleteSuccess"),
                            },
                            error: {
                              firstMessage: t("app.toastMessages.genericDeleteError"),
                            },
                          }}
                        />
                        {fileData && (
                          <div className={styles.saveDocument_master_container}>
                            <label className={styles.label_padding} htmlFor="chargementDeDocument">
                              {t("app.visualisationCv.DocumentToDownload")}
                            </label>
                            <DocumentsToDownload
                              fileType={supportingDocument?.extension}
                              href={href}
                              onClick={() => handleUpload()}
                              download={supportingDocument?.title}
                              fileTitle={supportingDocument?.title}
                              spanErrorMessage={spanErrorMessage}
                              supportingDocument={supportingDocument?.downloadUrl}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.section_separator} />
                </div>
                {/** POSITION TO FILLED END */}
                <div className={styles.submit_btn_to_right}>
                  <SubmitFormBtn disabled={generalEnable} text={t("app.visualisationOffre.ValidezLesModifications")} />
                </div>
              </form>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default OfferDetails;
