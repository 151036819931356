import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header/Header";
import DivLine from "components/DivLine/DivLine";
import { getOfferById, getOfferByIdPending, getOfferByIdThunk } from "app/slices/offre/checkAllOffers.slice";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { isEmpty } from "utils/isEmpty";

import { nanoid } from "@reduxjs/toolkit";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";

import {
  downloadFileCompanyThunk,
  getUploadFilePending,
  resetFileData,
  selectFileData,
  setFileData,
} from "app/slices/uploadFile/uploadFile.slice";
import axios from "axios";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import DocumentsToDownload from "../ModifyYourCV/components/DocumentsToDownload/DocumentsToDownload";

import styles from "./OffreById.module.scss";

/* eslint-disable */
const ListDetail = ({ label, value, note }) => {
  return (
    !isEmpty(value) &&
    !(value === "false") && (
      <div className={styles.offreListDetails__option}>
        <h6>{label}</h6>
        <em>{note}</em>
        <p>{value.replace(true, `${t("app.referentials.Oui")}`)}</p>
      </div>
    )
  );
};
const CheckBoxBloc = () => {
  return <input type="checkbox" name="checkbox" checked disabled />;
};

export default function OffreById() {
  const navigate = useNavigate();
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const handleClick = () => {
    navigate("/consultez-les-offres", { replace: true });
  };

  const dispatch = useDispatch();
  const offerById = useSelector(getOfferById);
  const pending = useSelector(getOfferByIdPending);
  const filePending = useSelector(getUploadFilePending);
  const fileData = useSelector(selectFileData);
  const { id } = useParams();
  const [href, setHref] = useState(null);
  const [fileType, setFileType] = useState(null);

  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getOfferByIdThunk(id)).unwrap();
        } catch (error) {
          console.log("useEffect fail !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const configtDetails = [
    {
      key: "sector",
      label: `${t("app.allOffers.sector")}`,
      value: `${offerById?.position?.sector?.label}`,
    },
    {
      key: "subSector",
      label: `${t("app.detailsOffer.sectorActivity")}`,
      value: `${offerById?.position?.subSector?.label}`,
    },
    {
      key: "applicationChannel",
      label: `${t("app.detailsOffer.candidatesApply")}`,
      value: `${offerById?.contact?.applicationChannel?.label}`,
    },
    {
      key: "companyWebsiteURL",
      label: `${t("app.detailsOffer.url")}`,
      value: `${offerById?.contact?.companyWebsiteURL}`,
    },
    {
      key: "applicationEmail",
      label: `${t("app.detailsOffer.mailToApply")}`,
      value: `${offerById?.contact?.applicationEmail}`,
    },
    {
      key: "startDate",
      label: `${t("app.allOffers.startDate")}`,
      value: `${offerById?.availability?.startDate}`,
    },
    {
      key: "contractDuration",
      label: `${t("app.allOffers.estimatedDuration")}`,
      value: `${offerById?.availability?.contractDuration?.label}`,
    },
    {
      key: "openToDisabledPeople",
      label: `${t("app.detailsOffer.disabilityStatus")}`,
      note: "Offre ouverte aux profils bénéficiant d'une reconnaissance de la qualité de travailleur handicapé",
      value: `${offerById?.position?.openToDisabledPeople}`,
    },
    {
      key: "remoteWork",
      label: `${t("app.detailsOffer.remoteWork")}`,
      value: `${offerById?.localisation?.remoteWork}`,
    },
    {
      key: "remoteWorkFrequency",
      label: `${t("app.detailsOffer.frequencyRemoteWork")}`,
      value: `${offerById?.localisation?.remoteWorkFrequency?.label}`,
    },
    {
      key: "description",
      label: `${t("app.detailsOffer.jobDescription")}`,
      value: `${offerById?.position?.description}`,
    },
  ];

  const complementConfig = [
    {
      key: "creationDate",
      label: `${t("app.detailsOffer.creationDate")}`,
      value: `${offerById?.creationDate}`, //TODO change for the creationDate Value
    },
    {
      key: "country",
      label: `${t("app.detailsOffer.jobLocation")}`,
      value: `${offerById?.localisation?.country?.label}`,
    },
    {
      key: "region",
      label: `${t("app.detailsOffer.region")}`,
      value: `${offerById?.localisation?.region?.label}`,
    },
    {
      key: "city",
      label: `${t("app.detailsOffer.city")}`,
      value: `${offerById?.localisation?.city}`,
    },
    {
      key: "zipCode",
      label: `${t("app.detailsOffer.zipCode")}`,
      value: `${offerById?.localisation?.zipCode}`,
    },
    {
      key: "currency",
      label: `${t("app.detailsOffer.currency")}`,
      value: `${offerById?.gratification?.currency?.label}`,
    },
    {
      key: "otherCurrency",
      label: `${t("app.detailsOffer.otherCurrency")}`,
      value: `${offerById?.gratification?.otherCurrency}`,
    },
    {
      key: "gratification",
      label: `${t("app.detailsOffer.gratification")}`,
      value: `${offerById?.gratification?.gratification}`,
    },
    {
      key: "paidIntership",
      label: `${t("app.detailsOffer.paidInternship")}`,
      value: `${offerById?.gratification?.paidIntership}`,
    },

    {
      key: "unpaidInternship",
      label: `${t("app.detailsOffer.unpaidInternship")}`,
      value: `${offerById?.gratification?.unpaidInternship}`,
    },
  ];

  const contractType = offerById?.contractTypes;
  const languages = offerById?.requirements?.requiredLanguages?.known;
  const educationLevel = offerById?.requirements?.educationLevels;
  const otherLanguages = offerById?.requirements?.requiredLanguages?.others;
  const availability = offerById?.availability?.needFrequency;
  const immediate = offerById?.availability?.needFrequency[0] === "IMMEDIATE";
  const recurrent = offerById?.availability?.needFrequency[1] === "RECURRENT";

  const handleType = (type) => {
    switch (type) {
      case "ai" || "eps":
        return "application/postscript";
      case "txt":
        return "text/plain";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "html":
        return "text/html";
      case "csv":
        return "text/csv";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "xls":
        return "application/vnd.ms-excel";
      case "ods":
        return "application/vnd.oasis.opendocument.spreadsheet";
      case "exe":
        return "application/octet-stream";
      case "mp4":
        return "video/mp4";
      case "pgs":
        return "text/plain";
      case "psd":
        return "image/vnd.adobe.photoshop";
      case "rtf":
        return "application/rtf";
      case "xml":
        return "application/xml";
      case "xmd":
        return "application/octet-stream";
      case "zip":
        return "application/zip";
      default:
        return "application/zip";
    }
  };

  useEffect(() => {
    if (offerById?.supportingDocument?.downloadUrl !== undefined) {
      (async () => {
        try {
          setFileType(handleType(offerById?.supportingDocument?.extension));
          await dispatch(
            downloadFileCompanyThunk({
              id: offerById?.id,
              axiosCancelToken: axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: fileType }));
              setHref(href);
              window.URL.revokeObjectURL(url);
            });
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={offerById?.supportingDocument?.name}
            />,
          );
          console.log("upload fail TestUploadFile !", error.message);
        }
      })();
    }
  }, [offerById]);

  useLayoutEffect(() => {
    if (offerById?.supportingDocument) {
      dispatch(setFileData(offerById?.supportingDocument));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerById]);

  useLayoutEffect(() => {
    return () => {
      // axiosCancelToken.cancel();
      dispatch(resetFileData());
      console.log("ModifyYourCV unmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = () => {
    if (!filePending) {
      (async () => {
        try {
          await dispatch(
            downloadFileCompanyThunk({
              id: offerById?.id,
              axiosCancelToken: axiosCancelToken,
            }),
          )
            .unwrap()
            .then((blob) => {
              const url = [];
              url.push(blob);
              const href = URL.createObjectURL(new Blob(url, { type: "application/zip" }));
              setHref(href);
            });
          toastSuccess(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.FichiersTelecharges")}
              secondMessage={`${t("app.toastMessages.FichiersTelechargesMessage")} 1`}
            />,
          );
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.ImpossibleLoad")}
              secondMessage={offerById?.supportingDocument?.name}
            />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Header h1={t("app.allOffers.headerTitle")} textElement={<p>{t("app.allOffers.headerDescription")}</p>} />

      <div className={styles.offerWrapper}>
        <div className={styles.divline_h2_container}>
          <DivLine />
          <h2>{t("app.detailsOffer.title")}</h2>

          <h2 className={styles.refOffer}>{offerById?.name}</h2>
        </div>

        <div className={styles.actionWrapper}>
          <button type="button" className={styles.lightBtn} onClick={handleClick}>
            {t("app.detailsOffer.btnSeeOtherOffer")}
          </button>
        </div>
        <div className={styles.container__Offer}>
          <h1 className={styles.container__Offer__titleOffre}>{offerById?.company?.name}</h1>

          <h1 className={styles.container__Offer__titleOffre}>{offerById?.position?.title}</h1>

          <div className={styles.offreList}>
            <h4>{t("app.detailsOffer.post")}</h4>
            <div className={styles.offreListDetails}>
              <div className={styles.offreListDetails__option}>
                <h6>{t("app.allOffers.levelEducation")}</h6>

                {educationLevel &&
                  educationLevel?.map((education) => (
                    <p className={styles.inline} key={nanoid()}>
                      {education?.label}
                    </p>
                  ))}
              </div>

              {configtDetails.map((elem) => (
                <ListDetail key={nanoid()} label={elem.label} value={elem.value} note={elem.note} />
              ))}
              {!isEmpty(otherLanguages) && (
                <div className={styles.offreListDetails__option}>
                  <h6>{t("app.detailsOffer.otherLanguage")}</h6>

                  {otherLanguages &&
                    otherLanguages?.map((other) => (
                      <p className={styles.inline} key={nanoid()}>
                        {other}
                      </p>
                    ))}
                </div>
              )}
              {!isEmpty(contractType) && (
                <div className={styles.offreListDetails__option}>
                  <h6>{t("app.allOffers.contractType")}</h6>

                  {contractType &&
                    contractType?.map((contrat) => (
                      <p className={styles.inline} key={nanoid()}>
                        {contrat?.label}
                      </p>
                    ))}
                </div>
              )}
              {!isEmpty(languages) && (
                <div className={styles.offreListDetails__option}>
                  <h6>{t("app.detailsOffer.languageRequired")}</h6>

                  {languages &&
                    languages?.map((item) => (
                      <p className={styles.inline} key={nanoid()}>
                        {item?.label}
                      </p>
                    ))}
                </div>
              )}
              {!isEmpty(availability) && (
                <div className={styles.offreListDetails__option}>
                  {availability.length > 0 && (
                    <div>
                      <h6>{t("app.detailsOffer.flexibleStartDate")}</h6>

                      <CheckBoxBloc />
                    </div>
                  )}
                </div>
              )}

              {immediate && (
                <div className={styles.offreListDetails__option}>
                  <h6>{t("app.detailsOffer.immediat")}</h6>
                  <CheckBoxBloc />
                </div>
              )}

              {recurrent && (
                <div className={styles.offreListDetails__option}>
                  <h6>{t("app.detailsOffer.recurrent")}</h6>
                  <CheckBoxBloc />
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className={styles.offreList}>
            <h4>{t("app.detailsOffer.moreInfo")}</h4>
            <div className={styles.offreListDetails}>
              {complementConfig.map((elem) => (
                <ListDetail key={nanoid()} label={elem.label} value={elem.value} />
              ))}
            </div>
          </div>
        </div>
        {fileData && offerById && (
          <div className={styles.saveDocument_master_container}>
            <label className={styles.label_padding} htmlFor="chargementDeDocument">
              {t("app.visualisationCv.DocumentToDownload")}
            </label>
            <DocumentsToDownload
              fileType={offerById?.supportingDocument?.extension}
              href={href}
              onClick={() => handleUpload()}
              download={offerById?.supportingDocument?.title}
              fileTitle={offerById?.supportingDocument?.title}
              spanErrorMessage={false}
              supportingDocument={offerById?.supportingDocument?.downloadUrl}
            />
          </div>
        )}
        <div className={styles.toApplay}>
          {offerById?.contact?.applicationChannel?.key !== "Via cette plateforme" && (
            <SubmitFormBtn
              onClick={() => {
                if (offerById?.contact?.applicationChannel?.key === "Via une adresse email")
                  window.location.href = `mailto:${offerById?.contact?.applicationEmail}`;
                else if (offerById?.contact?.applicationChannel?.key === "Via votre site internet")
                  window.location.href = offerById?.contact?.companyWebsiteURL;
              }}
              text={t("app.detailsOffer.btnSubmit")}
              type="button"
            />
          )}
        </div>
      </div>
    </>
  );
}
