import { t } from "i18next";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getCheckYourCVErrorMessage,
  getCheckYourCVPending,
  getCheckYourCVUserCVSCards,
  getUserCVSCardsListThunk,
} from "app/slices/checkYourCV/checkYourCV.slice";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import { getDeviceWidth } from "../../../../app/slices/deviceWidth/deviceWidth.slice";

import styles from "./HomepageHeader.module.scss";

const HomepageHeader = ({ bgImg, h1, textElement }) => {
  const dispatch = useDispatch();

  const deviceWidth = useSelector(getDeviceWidth);
  const userCVSList = useSelector(getCheckYourCVUserCVSCards);
  const userToken = useSelector(getUserToken);
  const pending = useSelector(getCheckYourCVPending);
  const errorMessage = useSelector(getCheckYourCVErrorMessage);
  const actualBg = deviceWidth > 1350 ? bgImg : "none";

  const headerStyle = {
    background: `no-repeat top right url(${actualBg})`,
  };

  useEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getUserCVSCardsListThunk()).unwrap();
          console.log("useEffect success !", userCVSList);
        } catch (error) {
          console.log("useEffect fail !", error.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <header className={styles.header_container} style={headerStyle}>
      <div className={styles.header_content}>
        <h1>{h1}</h1>
        {textElement}
      </div>
      <div className={styles.white_squares_container}>
        <div className={styles.white_square_card}>
          <div className={styles.cv_number_card}>
            {pending && <div>...</div>}
            {userCVSList.length <= 0 && !pending && !errorMessage && <div>0</div>}
            {userCVSList.length > 0 && !pending && !errorMessage && userCVSList?.length}
            {errorMessage && <div style={{ fontSize: "1rem" }}>Network error</div>}
          </div>
          <div className={styles.cv_text_card}>{t("app.homePageStudent.NombreCV")}</div>
          <div className={styles.cv_link_card}>{t("app.homePageStudent.EnSavoirPlus")}</div>
        </div>
        <div className={styles.white_square_card}>
          <div className={styles.cv_number_card}>0</div>
          <div className={styles.cv_text_card}>{t("app.homePageStudent.Candidatures")}</div>
          <div className={styles.cv_link_card}>{t("app.homePageStudent.EnSavoirPlus")}</div>
        </div>
      </div>
    </header>
  );
};

export default HomepageHeader;
