import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  company: null,
  employee: null,
  employee2: null,
  pending: false,
  errorMessage: null,
  id: null,
  data: null,
};

// THUNKS SECTION

export const getEmployeesThunk = createAsyncThunk(
  "checkDetailsCompany/getEmployeesThunk",
  async ({ email, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v1/companies/employees?email=${email}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const getEmployeesThunk2 = createAsyncThunk(
  "checkDetailsCompany/getEmployeesThunk2",
  async ({ email, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v1/companies/employees?email=${email}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postEmployeesThunk = createAsyncThunk(
  "checkDetailsCompany/postEmployeesThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().post(`/v1/companies/${id}/employees`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

// CREATESLICE SECTION

const checkDetailsCompanySlice = createSlice({
  name: "checkDetailsCompany",
  initialState,
  extraReducers: {
    [getEmployeesThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getEmployeesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.employee = action.payload;
    },
    [getEmployeesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getEmployeesThunk2.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getEmployeesThunk2.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.employee2 = action.payload;
    },
    [getEmployeesThunk2.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [postEmployeesThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [postEmployeesThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
    },
    [postEmployeesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getEmployeeDetails = (state) => state?.checkDetailsCompany?.employee;
export const getEmployeeDetails2 = (state) => state?.checkDetailsCompany?.employee2;
export const getCompanyEmployees = (state) => state?.checkDetailsCompany?.company;

export const getCompanyDetailsIsPending = (state) => state?.checkDetailsCompany?.pending;
export const getCompanyDetailsErrorMessage = (state) => state?.checkDetailsCompany?.errorMessage;

export const getcreateYourEmployesId = (state) => state.checkDetailsCompany.id;
export const getcreateYourEmployesData = (state) => state.checkDetailsCompany.data;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkDetailsCompanySlice.reducer;
