import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import fr from "./fr/index";
import en from "./en/index";

const languageDetector = new LanguageDetector();
languageDetector.init({
  order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["path", "navigator"] },
    supportedLngs: ["fr", "en"],
    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
    fallbackLng: fr,
    lng: localStorage.getItem("i18nextLng"),
  });

export default i18n;
