import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { DropdownList } from "react-widgets/cjs";

import { toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import { useSelector, useDispatch } from "react-redux";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { getLanguageSkills, patchLanguageSkillsThunk } from "../../../../app/slices/modifyYourCV/languageSkills.slice";

import InputErrorMessage from "../../../../components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";

import closeIcon from "../assets/close-svg.svg";

import styles from "../modalWrapper.module.scss";

/* eslint-disable */
const ModifyModalLanguageSkills = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const referentialData = useSelector(getReferentialsData);
  const languageSkillsById = useSelector(getLanguageSkills);

  const objAucun = { key: "-Aucun-", label: "- Aucun -" };

  const [language, setLanguage] = useState("");

  const handleProps = (value) => {
    if (value !== undefined) {
      setFieldValue("lang", value.lang);
      setFieldValue("other", value.other);
      setFieldValue("level", value.level);
      setFieldValue("certification", value.certification);
    }
    if (value.other) {
      setLanguage("Autre");
    }
  };

  useEffect(() => {
    handleProps(props.object);
    if (props.object.other) {
      setLanguage("Autre");
    }
  }, [props.object]);

  const onSubmit = (values) => {
    let dataToSend = {};
    if (values.certification) {
      dataToSend = {
        lang: values.lang.key,
        other: language === "Autre" || values.other === "Autre" ? values.other : "",
        level: values.level.key,
        certification: values.certification,
      };
    } else {
      dataToSend = {
        lang: values.lang.key,
        other: language === "Autre" || values.other === "Autre" ? values.other : "",
        level: values.level.key,
      };
    }
    (async () => {
      try {
        await dispatch(
          patchLanguageSkillsThunk({
            dataToSend,
            id: id,
            ID: props.id,
          }),
        ).unwrap();
        toastSuccess(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.ConfirmationModifLanguageTitle")}
            secondMessage={t("app.toastMessages.ConfirmationModifLanguageMessage")}
          />,
        );
        props.onClose();
      } catch (error) {
        console.log(error);
        props.onClose();
      }
    })();
  };

  const ModifyModalLanguageSkillsSchema = Yup.object().shape({
    lang: Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-"),
    other:
      language === "Other" || language === "Autre" ? Yup.string().required(t("app.errorMessages.RemplirChamp")) : null,
    level: Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-"),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      lang: props.object?.lang ? { key: props.object?.level?.key } : { key: "-Aucun-" },
      other: props.object?.other || languageSkillsById?.other ? props.object?.other : "",
      level: props.object?.level ? { key: props.object?.level?.key } : { key: "-Aucun-" },
      certification: props.object?.certification ? props.object?.certification : "",
    },
    validationSchema: ModifyModalLanguageSkillsSchema,
    onSubmit,
  });

  if (props.showModify && languageSkillsById)
    return (
      <>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={props.onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.visualisationCv.ModificationMaitriseLangue")}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.modal_contentBox}>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="language">
                    {t("app.visualisationCv.ChoixLangue")}
                  </label>
                  <DropdownList
                    style={
                      errors.lang &&
                      touched.lang && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="language"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.STUDENT_RESUME_LANGUAGE]}
                    value={values.lang}
                    onChange={(newValue) => {
                      setLanguage(newValue.key);
                      setFieldValue("lang", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.lang} touched={touched.lang} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="other">
                    {t("app.visualisationCv.PrecisezSiAutre")}
                  </label>
                  <input
                    style={
                      errors.other &&
                      touched.other && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    disabled={
                      values.lang === "Autre" || language === "Autre" ? false : true
                      // ? false
                      // : language === "Autre"
                      // ? false
                      // : true
                    }
                    value={values.lang === "Autre" ? values.other : language === "Autre" ? values.other : ""}
                    maxLength={80}
                    type="text"
                    name="other"
                    id="other"
                    onChange={(e) => setFieldValue("other", e.target.value.trimStart())}
                  />
                  <InputErrorMessage message={errors.other} touched={touched.other} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="level">
                    {t("app.visualisationCv.NiveauLangue")}
                  </label>
                  <DropdownList
                    style={
                      errors.level &&
                      touched.level && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    className={styles.dropDownList}
                    id="level"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialData.STUDENT_LANG_LEVEL]}
                    value={values.level}
                    onChange={(newValue) => {
                      setFieldValue("level", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.level} touched={touched.level} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label className={styles.label_modify_modal} htmlFor="certification">
                    {t("app.visualisationCv.CertificationsLinguistiques")}
                  </label>
                  <input
                    value={values.certification}
                    maxLength={255}
                    type="text"
                    name="certification"
                    id="certification"
                    onChange={(e) => setFieldValue("certification", e.target.value.trimStart())}
                  />
                </div>
              </div>
              <div className={styles.button_modify_modal_container}>
                <SubmitBtn text={t("app.visualisationCv.ValidezModification")} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  if (!props.showModify) return null;
};

export default ModifyModalLanguageSkills;
