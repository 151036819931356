import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosCommon } from "app/axiosInstances/axiosInstances";
import { LOCAL_USERTOKEN } from "utils/globalConstantsAndURL";

// Initial State declaration
const initialState = {
  userToken: LOCAL_USERTOKEN || "",
  getMeData: null,
  getMeDataV2: null,
  pending: false,
  errorMessage: "",
  acquireTokenRedirectInProgress: false,
};

// THUNKS SECTION
export const getMeDataThunk = createAsyncThunk("userConnexion/getMeDataThunk", async () => {
  const response = await axiosCommon().get("/v1/me");
  return response.data;
});

export const getMeDataV2Thunk = createAsyncThunk("userConnexion/getMeDataV2Thunk", async () => {
  const response = await axiosCommon().get("/v2/me");
  return response.data;
});

// CREATESLICE SECTION
const userConnexionSlice = createSlice({
  name: "userConnexion",
  initialState,
  reducers: {
    handleUserToken: (state, action) => {
      console.log("action.payload in handleUserToken", action.payload);
      state.userToken = action.payload.userToken;
    },
    setAcquireTokenRedirectInProgress: (state, action) => {
      state.acquireTokenRedirectInProgress = action.payload;
    },
  },
  extraReducers: {
    [getMeDataThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getMeDataThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.getMeData = action.payload;
    },
    [getMeDataThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error.response);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getMeDataV2Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getMeDataV2Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.getMeDataV2 = action.payload;
    },
    [getMeDataV2Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error.response);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getUserToken = (state) => state.userConnexion.userToken;

export const getMeData = (state) => state.userConnexion.getMeData;

export const getMeDataV2 = (state) => state.userConnexion.getMeDataV2;

export const getUserConnexionErrorMessage = (state) => state.userConnexion.errorMessage;

export const selectAcquireTokenRedirectInProgress = (state) => state.userConnexion.acquireTokenRedirectInProgress;

// ACTIONS CREATOR EXPORTS SECTION
export const { handleUserToken, setAcquireTokenRedirectInProgress } = userConnexionSlice.actions;

// REDUCER DEFAULT EXPORT SECTION
export default userConnexionSlice.reducer;
