// fonction qui permet de formater une date

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}
