import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { DropdownList } from "react-widgets";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { formatDate } from "utils/formatDate";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { createObjAucun } from "utils/localReferentials";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import styles from "./Form8.module.scss";

/* eslint-disable */
const Form8 = (props) => {
  const [displayYear1Row, setDisplayYear1Row] = useState(false);
  const [displayYear2Row, setDisplayYear2Row] = useState(false);
  const [displayYear3Row, setDisplayYear3Row] = useState(false);
  const [year1InputsDisabled, setYear1InputsDisabled] = useState(true);
  const [year2InputsDisabled, setYear2InputsDisabled] = useState(true);
  const [year3InputsDisabled, setYear3InputsDisabled] = useState(true);
  const [year1EndDisabled, setYear1EndDisabled] = useState(true);
  const [year2EndDisabled, setYear2EndDisabled] = useState(true);
  const [year3EndDisabled, setYear3EndDisabled] = useState(true);
  const [ageToDisplay, setAgeToDisplay] = useState(null);
  const [firstCheck, setFirstCheck] = useState(false);

  const referentialsData = useSelector(getReferentialsData);

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);

  const dispatch = useDispatch();

  // const recruitmentPropsGeneral = useSelector(getAllUserRecruitments);
  const contractData = useSelector(getYourRecruitmentPropsData);

  const objAucun = createObjAucun();

  useEffect(() => {
    console.log("Form8 mounted");
    // console.log("PROPS", recruitmentPropsGeneral);
    console.log("CONTRACT DATA", contractData);
    console.log(errors);
    console.log("VALUES FORM8", values);
  });

  const calculateAge = (birthdate, referenceDate) => {
    const birth = new Date(birthdate);
    const ref = new Date(referenceDate);

    let age = ref.getFullYear() - birth.getFullYear();
    const monthDifference = ref.getMonth() - birth.getMonth();
    const dayDifference = ref.getDate() - birth.getDate();

    // Adjusts the age if the reference date is before the current year's birthday
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const handleAutofillDates = (_startDate, _endDate, yearBefore, yearAfter, yearEnd) => {
    const birthDate = contractData.student.birthdate;
    const birthDateYear = parseInt(birthDate?.split("-")[0]);
    const birthDateMonth = parseInt(birthDate?.split("-")[1]);
    const birthDateDay = parseInt(birthDate?.split("-")[2]);

    const startDate = _startDate;
    const startDateYear = parseInt(_startDate?.split("-")[0]);
    const startDateMonth = parseInt(_startDate?.split("-")[1]);
    const startDateDay = parseInt(_startDate?.split("-")[2]);

    const endDate = _endDate;
    const endDateYear = parseInt(_endDate?.split("-")[0]);
    const endDateMonth = parseInt(_endDate?.split("-")[1]);
    const endDateDay = parseInt(_endDate?.split("-")[2]);

    // Reset l'affichage
    if (yearEnd === "Year1End") {
      console.log("NEEEEEEEWWWW 1 TEST");
      setDisplayYear1Row(false);
      setFieldValue("Year1Before", null);
      setFieldValue("Year1After", null);
    } else if (yearEnd === "Year2End") {
      console.log("NEEEEEEEWWWW 2 TEST");
      setDisplayYear2Row(false);
      setFieldValue("Year2Before", null);
      setFieldValue("Year2After", null);
    } else if (yearEnd === "Year3End") {
      console.log("NEEEEEEEWWWW 3 TEST");
      setDisplayYear3Row(false);
      setFieldValue("Year3Before", null);
      setFieldValue("Year3After", null);
    }

    // Logic for 20 to 21 years
    if (calculateAge(birthDate, startDate) < 21 && calculateAge(birthDate, endDate) >= 21) {
      setAgeToDisplay(21);
      console.log("IF IN 21 CASE");
      if (
        startDateYear - birthDateYear === 21 ||
        (endDateYear - startDateYear === 1 && endDateYear - birthDateYear === 21)
      ) {
        console.log("startDate anniversary");

        // if (startDateMonth === birthDateMonth && startDateDay >= birthDateDay && endDateYear === startDateYear) {
        //   console.log("La personne a déjà eu 21 ans !");
        //   return;
        // } else if (startDateMonth > birthDateMonth && endDateYear === startDateYear) {
        //   console.log("La personne a déjà eu 21 ans !");
        //   return;
        // } else if (startDateMonth > birthDateMonth && endDateYear > startDateYear && yearEnd === "Year1End") {
        //   console.log("La personne a déjà eu 21 ans !");
        //   return;
        // } else if (displayYear2Row && yearEnd === "Year3End") {
        //   console.log("La personne a déjà eu 21 ans !");
        //   return;
        // }

        if (endDateMonth >= birthDateMonth && endDateDay >= birthDateDay) {
          console.log("YEEEEAR", yearEnd);
          if (yearEnd === "Year1End") {
            console.log("YEAR11111");
            setDisplayYear1Row(true);
          } else if (yearEnd === "Year2End") {
            setDisplayYear2Row(true);
            setDisplayYear3Row(false);
            setFieldValue("Year3Before", null);
            setFieldValue("Year3After", null);
          } else if (yearEnd === "Year3End") setDisplayYear3Row(true);

          const birthDateArray = birthDate.split("-");
          console.log("BIRRTTTHHHHH : ", birthDateArray);
          const dateBeforeYear = startDate.split("-");

          if (endDateYear - startDateYear === 1) {
            console.log("endDateYear - startDateYear === 1");
            let yearPlusOne = parseInt(dateBeforeYear[0]) + 1;
            dateBeforeYear[0] = yearPlusOne.toString();
          }

          birthDateArray[0] = dateBeforeYear[0];
          const birthDateObj = new Date(birthDateArray.join("-"));
          console.log("BIRRTTTHHHHH : ", birthDateObj);
          setFieldValue(yearBefore, new Date(birthDateObj.setDate(birthDateObj.getDate() - 1)));
          //Evolve first day and next month - start code
          const yearAfterArray = birthDateObj.toISOString().split("T")[0].split("-");
          console.log("yearAfterArray", yearAfterArray);
          if (parseInt(yearAfterArray[1]) < 12) {
            yearAfterArray[1] = JSON.stringify(parseInt(yearAfterArray[1]) + 1);
            yearAfterArray[2] = "01";
          } else {
            yearAfterArray[1] = "01";
            yearAfterArray[2] = "01";
            yearAfterArray[0] = JSON.stringify(parseInt(yearAfterArray[0]) + 1);
          }
          //Evolve first day and next month - end code
          setFieldValue(yearAfter, new Date(yearAfterArray.join("-")));
          setFieldValue(yearEnd, new Date(endDate));

          // Set bonus inputs
          if (yearEnd === "Year1End") {
            const year1ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1_CHGT_AGE");
            if (year1ObjBonus) {
              year1ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage1Bonus", year1ObjBonus?.grossSalaryPercent);
              year1ObjBonus?.salaryType && setFieldValue("salaryType1Bonus", year1ObjBonus?.salaryType);
              year1ObjBonus?.grossSalary && setFieldValue("salaryGross1Bonus", year1ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year2End") {
            const year2ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2_CHGT_AGE");
            if (year2ObjBonus) {
              year2ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage2Bonus", year2ObjBonus?.grossSalaryPercent);
              year2ObjBonus?.salaryType && setFieldValue("salaryType2Bonus", year2ObjBonus?.salaryType);
              year2ObjBonus?.grossSalary && setFieldValue("salaryGross2Bonus", year2ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year3End") {
            const year3ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR3_CHGT_AGE");
            if (year3ObjBonus) {
              year3ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage3Bonus", year3ObjBonus?.grossSalaryPercent);
              year3ObjBonus?.salaryType && setFieldValue("salaryType3Bonus", year3ObjBonus?.salaryType);
              year3ObjBonus?.grossSalary && setFieldValue("salaryGross3Bonus", year3ObjBonus?.grossSalary);
            }
          }
        } else if (endDateYear - startDateYear === 1) {
          console.log("YEEEEAR", yearEnd);
          if (yearEnd === "Year1End") {
            console.log("YEAR11111");
            setDisplayYear1Row(true);
          } else if (yearEnd === "Year2End") {
            setDisplayYear2Row(true);
            setDisplayYear3Row(false);
            setFieldValue("Year3Before", null);
            setFieldValue("Year3After", null);
          } else if (yearEnd === "Year3End") setDisplayYear3Row(true);

          const birthDateArray = birthDate.split("-");
          console.log("BIRRTTTHHHHH : ", birthDateArray);
          const dateBeforeYear = startDate.split("-");

          birthDateArray[0] = dateBeforeYear[0];
          const birthDateObj = new Date(birthDateArray.join("-"));
          console.log("BIRRTTTHHHHH : ", birthDateObj);
          setFieldValue(yearBefore, new Date(birthDateObj.setDate(birthDateObj.getDate() - 1)));
          //Evolve first day and next month - start code
          const yearAfterArray = birthDateObj.toISOString().split("T")[0].split("-");
          console.log("yearAfterArray", yearAfterArray);
          if (parseInt(yearAfterArray[1]) < 12) {
            yearAfterArray[1] = JSON.stringify(parseInt(yearAfterArray[1]) + 1);
            yearAfterArray[2] = "01";
          } else {
            yearAfterArray[1] = "01";
            yearAfterArray[2] = "01";
            yearAfterArray[0] = JSON.stringify(parseInt(yearAfterArray[0]) + 1);
          }
          //Evolve first day and next month - end code
          setFieldValue(yearAfter, new Date(yearAfterArray.join("-")));
          setFieldValue(yearEnd, new Date(endDate));

          // Set bonus inputs
          if (yearEnd === "Year1End") {
            const year1ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1_CHGT_AGE");
            if (year1ObjBonus) {
              year1ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage1Bonus", year1ObjBonus?.grossSalaryPercent);
              year1ObjBonus?.salaryType && setFieldValue("salaryType1Bonus", year1ObjBonus?.salaryType);
              year1ObjBonus?.grossSalary && setFieldValue("salaryGross1Bonus", year1ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year2End") {
            const year2ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2_CHGT_AGE");
            if (year2ObjBonus) {
              year2ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage2Bonus", year2ObjBonus?.grossSalaryPercent);
              year2ObjBonus?.salaryType && setFieldValue("salaryType2Bonus", year2ObjBonus?.salaryType);
              year2ObjBonus?.grossSalary && setFieldValue("salaryGross2Bonus", year2ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year3End") {
            const year3ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR3_CHGT_AGE");
            if (year3ObjBonus) {
              year3ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage3Bonus", year3ObjBonus?.grossSalaryPercent);
              year3ObjBonus?.salaryType && setFieldValue("salaryType3Bonus", year3ObjBonus?.salaryType);
              year3ObjBonus?.grossSalary && setFieldValue("salaryGross3Bonus", year3ObjBonus?.grossSalary);
            }
          }
        }
      }
    }

    // Logic for 25 to 26 years
    if (calculateAge(birthDate, startDate) < 26 && calculateAge(birthDate, endDate) >= 26) {
      console.log("IF IN 26 CASE");
      setAgeToDisplay(26);
      if (
        startDateYear - birthDateYear === 26 ||
        (endDateYear - startDateYear === 1 && endDateYear - birthDateYear === 26)
      ) {
        console.log("startDate anniversary");

        // if (startDateMonth === birthDateMonth && startDateDay >= birthDateDay && endDateYear === startDateYear) {
        //   console.log("La personne a déjà eu 26 ans !");
        //   return;
        // } else if (startDateMonth > birthDateMonth && endDateYear === startDateYear) {
        //   console.log("La personne a déjà eu 26 ans !");
        //   return;
        // } else if (startDateMonth > birthDateMonth && endDateYear > startDateYear && yearEnd === "Year1End") {
        //   console.log("La personne a déjà eu 26 ans !");
        //   return;
        // } else if (displayYear2Row && yearEnd === "Year3End") {
        //   console.log("La personne a déjà eu 26 ans !");
        //   return;
        // }

        if (endDateMonth >= birthDateMonth && endDateDay >= birthDateDay) {
          console.log("YEEEEAR", yearEnd);
          if (yearEnd === "Year1End") {
            console.log("YEAR11111");
            setDisplayYear1Row(true);
          } else if (yearEnd === "Year2End") {
            setDisplayYear2Row(true);
            setDisplayYear3Row(false);
            setFieldValue("Year3Before", null);
            setFieldValue("Year3After", null);
          } else if (yearEnd === "Year3End") setDisplayYear3Row(true);

          const birthDateArray = birthDate.split("-");
          console.log("BIRRTTTHHHHH : ", birthDateArray);
          const dateBeforeYear = startDate.split("-");

          if (endDateYear - startDateYear === 1) {
            console.log("endDateYear - startDateYear === 1");
            let yearPlusOne = parseInt(dateBeforeYear[0]) + 1;
            dateBeforeYear[0] = yearPlusOne.toString();
          }

          birthDateArray[0] = dateBeforeYear[0];
          const birthDateObj = new Date(birthDateArray.join("-"));
          console.log("BIRRTTTHHHHH : ", birthDateObj);
          setFieldValue(yearBefore, new Date(birthDateObj.setDate(birthDateObj.getDate() - 1)));
          //Evolve first day and next month - start code
          const yearAfterArray = birthDateObj.toISOString().split("T")[0].split("-");
          console.log("yearAfterArray", yearAfterArray);
          if (parseInt(yearAfterArray[1]) < 12) {
            yearAfterArray[1] = JSON.stringify(parseInt(yearAfterArray[1]) + 1);
            yearAfterArray[2] = "01";
          } else {
            yearAfterArray[1] = "01";
            yearAfterArray[2] = "01";
            yearAfterArray[0] = JSON.stringify(parseInt(yearAfterArray[0]) + 1);
          }
          //Evolve first day and next month - end code
          setFieldValue(yearAfter, new Date(yearAfterArray.join("-")));
          setFieldValue(yearEnd, new Date(endDate));

          // Set bonus inputs
          if (yearEnd === "Year1End") {
            const year1ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1_CHGT_AGE");
            if (year1ObjBonus) {
              year1ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage1Bonus", year1ObjBonus?.grossSalaryPercent);
              year1ObjBonus?.salaryType && setFieldValue("salaryType1Bonus", year1ObjBonus?.salaryType);
              year1ObjBonus?.grossSalary && setFieldValue("salaryGross1Bonus", year1ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year2End") {
            const year2ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2_CHGT_AGE");
            if (year2ObjBonus) {
              year2ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage2Bonus", year2ObjBonus?.grossSalaryPercent);
              year2ObjBonus?.salaryType && setFieldValue("salaryType2Bonus", year2ObjBonus?.salaryType);
              year2ObjBonus?.grossSalary && setFieldValue("salaryGross2Bonus", year2ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year3End") {
            const year3ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR3_CHGT_AGE");
            if (year3ObjBonus) {
              year3ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage3Bonus", year3ObjBonus?.grossSalaryPercent);
              year3ObjBonus?.salaryType && setFieldValue("salaryType3Bonus", year3ObjBonus?.salaryType);
              year3ObjBonus?.grossSalary && setFieldValue("salaryGross3Bonus", year3ObjBonus?.grossSalary);
            }
          }
        } else if (endDateYear - startDateYear === 1) {
          console.log("YEEEEAR", yearEnd);
          if (yearEnd === "Year1End") {
            console.log("YEAR11111");
            setDisplayYear1Row(true);
          } else if (yearEnd === "Year2End") {
            setDisplayYear2Row(true);
            setDisplayYear3Row(false);
            setFieldValue("Year3Before", null);
            setFieldValue("Year3After", null);
          } else if (yearEnd === "Year3End") setDisplayYear3Row(true);

          const birthDateArray = birthDate.split("-");
          console.log("BIRRTTTHHHHH : ", birthDateArray);
          const dateBeforeYear = startDate.split("-");

          birthDateArray[0] = dateBeforeYear[0];
          const birthDateObj = new Date(birthDateArray.join("-"));
          console.log("BIRRTTTHHHHH : ", birthDateObj);
          setFieldValue(yearBefore, new Date(birthDateObj.setDate(birthDateObj.getDate() - 1)));
          //Evolve first day and next month - start code
          const yearAfterArray = birthDateObj.toISOString().split("T")[0].split("-");
          console.log("yearAfterArray", yearAfterArray);
          if (parseInt(yearAfterArray[1]) < 12) {
            yearAfterArray[1] = JSON.stringify(parseInt(yearAfterArray[1]) + 1);
            yearAfterArray[2] = "01";
          } else {
            yearAfterArray[1] = "01";
            yearAfterArray[2] = "01";
            yearAfterArray[0] = JSON.stringify(parseInt(yearAfterArray[0]) + 1);
          }
          //Evolve first day and next month - end code
          setFieldValue(yearAfter, new Date(yearAfterArray.join("-")));
          setFieldValue(yearEnd, new Date(endDate));

          // Set bonus inputs
          if (yearEnd === "Year1End") {
            const year1ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1_CHGT_AGE");
            if (year1ObjBonus) {
              year1ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage1Bonus", year1ObjBonus?.grossSalaryPercent);
              year1ObjBonus?.salaryType && setFieldValue("salaryType1Bonus", year1ObjBonus?.salaryType);
              year1ObjBonus?.grossSalary && setFieldValue("salaryGross1Bonus", year1ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year2End") {
            const year2ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2_CHGT_AGE");
            if (year2ObjBonus) {
              year2ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage2Bonus", year2ObjBonus?.grossSalaryPercent);
              year2ObjBonus?.salaryType && setFieldValue("salaryType2Bonus", year2ObjBonus?.salaryType);
              year2ObjBonus?.grossSalary && setFieldValue("salaryGross2Bonus", year2ObjBonus?.grossSalary);
            }
          } else if (yearEnd === "Year3End") {
            const year3ObjBonus = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR3_CHGT_AGE");
            if (year3ObjBonus) {
              year3ObjBonus?.grossSalaryPercent &&
                setFieldValue("salaryPercentage3Bonus", year3ObjBonus?.grossSalaryPercent);
              year3ObjBonus?.salaryType && setFieldValue("salaryType3Bonus", year3ObjBonus?.salaryType);
              year3ObjBonus?.grossSalary && setFieldValue("salaryGross3Bonus", year3ObjBonus?.grossSalary);
            }
          }
        }
      }
    }
  };

  const resetAndDisabledAll = () => {
    setFieldValue("Year1Start", null);
    setFieldValue("Year1Before", null);
    setFieldValue("Year1After", null);
    setFieldValue("Year1End", null);
    setFieldValue("salaryPercentage1", "");
    setFieldValue("salaryType1", { ...objAucun });
    setFieldValue("salaryGross1", "");
    setFieldValue("salaryPercentage1Bonus", "");
    setFieldValue("salaryType1Bonus", { ...objAucun });
    setFieldValue("salaryGross1Bonus", "");

    setFieldValue("Year2Start", null);
    setFieldValue("Year2Before", null);
    setFieldValue("Year2After", null);
    setFieldValue("Year2End", null);
    setFieldValue("salaryPercentage2", "");
    setFieldValue("salaryType2", { ...objAucun });
    setFieldValue("salaryGross2", "");
    setFieldValue("salaryPercentage2Bonus", "");
    setFieldValue("salaryType2Bonus", { ...objAucun });
    setFieldValue("salaryGross2Bonus", "");

    setFieldValue("Year3Start", null);
    setFieldValue("Year3Before", null);
    setFieldValue("Year3After", null);
    setFieldValue("Year3End", null);
    setFieldValue("salaryPercentage3", "");
    setFieldValue("salaryType3", { ...objAucun });
    setFieldValue("salaryGross3", "");
    setFieldValue("salaryPercentage3Bonus", "");
    setFieldValue("salaryType3Bonus", { ...objAucun });
    setFieldValue("salaryGross3Bonus", "");

    setYear1InputsDisabled(true);
    setYear2InputsDisabled(true);
    setYear3InputsDisabled(true);

    setYear1EndDisabled(true);
    setYear2EndDisabled(true);
    setYear3EndDisabled(true);

    setDisplayYear1Row(false);
    setDisplayYear2Row(false);
    setDisplayYear3Row(false);
  };

  useEffect(() => {
    if (
      contractData?.contract?.startDate &&
      contractData?.contract?.endDate &&
      (contractData?.contract?.apprenticeshipType?.key === "Contrat_dapprentissage" ||
        contractData?.contract?.apprenticeshipType?.key === "Convention de formation professionnelle continue")
    ) {
      const startDate = contractData.contract.startDate;
      const endDate = contractData.contract.endDate;

      resetAndDisabledAll();
      setFieldValue("Year1Start", new Date(contractData?.contract?.startDate));

      // Logic for display more rows if more than 365 days
      if ((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) <= 365) {
        console.log(
          "Nombre de jours <= 365",
          (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24),
        );
        setYear1InputsDisabled(false);
        setFieldValue("Year1End", new Date(endDate));
        const year1Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1");
        if (year1Obj) {
          console.log("IF YEAR 1 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year1Obj?.endDate) {
            handleAutofillDates(startDate, endDate, "Year1Before", "Year1After", "Year1End");
          }

          year1Obj?.endDate && setFieldValue("Year1End", new Date(endDate));
          year1Obj?.grossSalaryPercent && setFieldValue("salaryPercentage1", year1Obj?.grossSalaryPercent);
          year1Obj?.salaryType && setFieldValue("salaryType1", year1Obj?.salaryType);
          year1Obj?.grossSalary && setFieldValue("salaryGross1", year1Obj?.grossSalary);
        }
        handleAutofillDates(startDate, endDate, "Year1Before", "Year1After", "Year1End");
      } else if (
        (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) > 365 &&
        (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) <= 730
      ) {
        console.log(
          "Nombre de jours > 365 && <= 730",
          (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24),
        );
        setYear1InputsDisabled(false);
        setYear2InputsDisabled(false);
        setYear1EndDisabled(false);
        setFieldValue("Year2End", new Date(endDate));
        const year1Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1");
        if (year1Obj) {
          console.log("IF YEAR 1 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year1Obj?.endDate) {
            handleAutofillDates(startDate, year1Obj.endDate, "Year1Before", "Year1After", "Year1End");
          }

          year1Obj?.endDate && setFieldValue("Year1End", new Date(year1Obj?.endDate));
          year1Obj?.grossSalaryPercent && setFieldValue("salaryPercentage1", year1Obj?.grossSalaryPercent);
          year1Obj?.salaryType && setFieldValue("salaryType1", year1Obj?.salaryType);
          year1Obj?.grossSalary && setFieldValue("salaryGross1", year1Obj?.grossSalary);
        }
        const year2Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2");
        if (year2Obj) {
          console.log("IF YEAR 2 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year2Obj?.startDate && year2Obj?.endDate) {
            handleAutofillDates(year2Obj.startDate, endDate, "Year2Before", "Year2After", "Year2End");
          }

          year2Obj?.startDate && setFieldValue("Year2Start", new Date(year2Obj?.startDate));
          year2Obj?.endDate && setFieldValue("Year2End", new Date(endDate));
          year2Obj?.grossSalaryPercent && setFieldValue("salaryPercentage2", year2Obj?.grossSalaryPercent);
          year2Obj?.salaryType && setFieldValue("salaryType2", year2Obj?.salaryType);
          year2Obj?.grossSalary && setFieldValue("salaryGross2", year2Obj?.grossSalary);
        }
      } else if (
        (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) > 365 &&
        (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) > 730
      ) {
        console.log(
          "Nombre de jours > 730",
          (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24),
        );
        setYear1InputsDisabled(false);
        setYear2InputsDisabled(false);
        setYear3InputsDisabled(false);
        setYear1EndDisabled(false);
        setYear2EndDisabled(false);
        setFieldValue("Year3End", new Date(endDate));
        const year1Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR1");
        if (year1Obj) {
          console.log("IF YEAR 1 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year1Obj?.endDate) {
            handleAutofillDates(startDate, year1Obj.endDate, "Year1Before", "Year1After", "Year1End");
          }

          year1Obj?.endDate && setFieldValue("Year1End", new Date(year1Obj?.endDate));
          year1Obj?.grossSalaryPercent && setFieldValue("salaryPercentage1", year1Obj?.grossSalaryPercent);
          year1Obj?.salaryType && setFieldValue("salaryType1", year1Obj?.salaryType);
          year1Obj?.grossSalary && setFieldValue("salaryGross1", year1Obj?.grossSalary);
        }
        const year2Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR2");
        if (year2Obj) {
          console.log("IF YEAR 2 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year2Obj?.startDate && year2Obj?.endDate) {
            handleAutofillDates(year2Obj.startDate, year2Obj.endDate, "Year2Before", "Year2After", "Year2End");
          }

          year2Obj?.startDate && setFieldValue("Year2Start", new Date(year2Obj?.startDate));
          year2Obj?.endDate && setFieldValue("Year2End", new Date(year2Obj?.endDate));
          year2Obj?.grossSalaryPercent && setFieldValue("salaryPercentage2", year2Obj?.grossSalaryPercent);
          year2Obj?.salaryType && setFieldValue("salaryType2", year2Obj?.salaryType);
          year2Obj?.grossSalary && setFieldValue("salaryGross2", year2Obj?.grossSalary);
        }
        const year3Obj = contractData?.contract?.remunerations?.find((obj) => obj.code === "YEAR3");
        if (year3Obj) {
          console.log("IF YEAR 3 SET DATA");
          // Initialization check and display if 20 to 21 or 25 to 26
          if (year3Obj?.startDate && year3Obj?.endDate) {
            handleAutofillDates(year3Obj.startDate, endDate, "Year3Before", "Year3After", "Year3End");
          }

          year3Obj?.startDate && setFieldValue("Year3Start", new Date(year3Obj?.startDate));
          year3Obj?.endDate && setFieldValue("Year3End", new Date(endDate));
          year3Obj?.grossSalaryPercent && setFieldValue("salaryPercentage3", year3Obj?.grossSalaryPercent);
          year3Obj?.salaryType && setFieldValue("salaryType3", year3Obj?.salaryType);
          year3Obj?.grossSalary && setFieldValue("salaryGross3", year3Obj?.grossSalary);
        }
      }
    } else {
      resetAndDisabledAll();
    }
  }, [contractData]);

  // Start : Reset Year3 Bonus inputs if displayYear2Row is true
  useEffect(() => {
    if (!firstCheck) {
      setFirstCheck(true);
    }

    if (firstCheck) {
      if (displayYear2Row) {
        setDisplayYear3Row(false);
        setFieldValue("Year3Before", null);
        setFieldValue("Year3After", null);
      }
    }
  }, [firstCheck]);
  // End : Reset Year3 Bonus inputs if displayYear2Row is true

  // Start : Code to manage the display of the badge in red or green.
  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  const handleMountPingStatus = () => {
    setFormikErrorJustMount(false);
    if (contractData?.contract?.apprenticeshipType?.key === "Contrat de professionnalisation") {
      props.setAreaFufiled(true);
    } else {
      console.log("errors json", errors);
      // if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
      // else props.setAreaFufiled(false);
      props.setAreaFufiled(false);
    }
  };

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };
  // End : Code to manage the display of the badge in red or green.

  // Yup validation schema
  const Form8Schema = Yup.object().shape({
    Year1Start:
      !year1InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year1InputsDisabled,
          then: (schema) => schema.required(),
        }),
    Year1Before:
      !year1InputsDisabled &&
      displayYear1Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear1Row,
          then: (schema) => schema.required(),
        }),
    Year1After:
      !year1InputsDisabled &&
      displayYear1Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear1Row,
          then: (schema) => schema.required(),
        }),
    Year1End:
      !year1InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year1InputsDisabled,
          then: (schema) => schema.required(),
        }),
    salaryPercentage1: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year1InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType1: Yup.object().when([], {
      is: () => !year1InputsDisabled,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    salaryGross1: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year1InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryPercentage1Bonus: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => displayYear1Row,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType1Bonus: Yup.object().when([], {
      is: () => displayYear1Row,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    // salaryGross1Bonus: Yup.string()
    // 	.typeError(t("app.errorMessages.RemplirChamp"))
    // 	.when([], {
    // 		is: () => displayYear1Row,
    // 		then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    // 	}),
    Year2Start:
      !year2InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year2InputsDisabled,
          then: (schema) => schema.required(),
        }),
    Year2Before:
      !year2InputsDisabled &&
      displayYear2Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear2Row,
          then: (schema) => schema.required(),
        }),
    Year2After:
      !year2InputsDisabled &&
      displayYear2Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear2Row,
          then: (schema) => schema.required(),
        }),
    Year2End:
      !year2InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year2InputsDisabled,
          then: (schema) => schema.required(),
        }),
    salaryPercentage2: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year2InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType2: Yup.object().when([], {
      is: () => !year2InputsDisabled,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    salaryGross2: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year2InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryPercentage2Bonus: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => displayYear2Row,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType2Bonus: Yup.object().when([], {
      is: () => displayYear2Row,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    // salaryGross2Bonus: Yup.string()
    // 	.typeError(t("app.errorMessages.RemplirChamp"))
    // 	.when([], {
    // 		is: () => displayYear2Row,
    // 		then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    // 	}),
    Year3Start:
      !year3InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year3InputsDisabled,
          then: (schema) => schema.required(),
        }),
    Year3Before:
      !year3InputsDisabled &&
      displayYear3Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear3Row,
          then: (schema) => schema.required(),
        }),
    Year3After:
      !year3InputsDisabled &&
      displayYear3Row &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => displayYear3Row,
          then: (schema) => schema.required(),
        }),
    Year3End:
      !year3InputsDisabled &&
      Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when([], {
          is: () => !year3InputsDisabled,
          then: (schema) => schema.required(),
        }),
    salaryPercentage3: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year3InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType3: Yup.object().when([], {
      is: () => !year3InputsDisabled,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    salaryGross3: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => !year3InputsDisabled,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryPercentage3Bonus: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when([], {
        is: () => displayYear3Row,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    salaryType3Bonus: Yup.object().when([], {
      is: () => displayYear3Row,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    // salaryGross3Bonus: Yup.string()
    // 	.typeError(t("app.errorMessages.RemplirChamp"))
    // 	.when([], {
    // 		is: () => displayYear3Row,
    // 		then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
    // 	}),
  });

  const onSubmit = (values) => {
    if (formikErrorJustMount) {
      if (contractData?.contract?.apprenticeshipType?.key) {
        props.setAreaFufiled(true);
      }
      setFormikErrorJustMount(false);
      return;
    }

    if (!formikErrorJustMount) {
      console.log("CONTRACT VALUE TO SEND 1");

      const valuesCopy = structuredClone(values);

      // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      console.log("VALUES COPY FORM8", valuesCopy);

      const dataToSend = {
        status: "En cours",
        contract: {
          remunerations: [],
        },
      };

      if (!year1InputsDisabled)
        dataToSend.contract.remunerations.push({
          code: "YEAR1",
          startDate: valuesCopy.Year1Start ? formatDate(valuesCopy.Year1Start) : null,
          endDate: valuesCopy.Year1End ? formatDate(valuesCopy.Year1End) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage1),
          salaryType: valuesCopy?.salaryType1?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross1),
        });
      if (displayYear1Row)
        dataToSend.contract.remunerations.push({
          code: "YEAR1_CHGT_AGE",
          startDate: valuesCopy.Year1Before ? formatDate(valuesCopy.Year1Before) : null,
          endDate: valuesCopy.Year1After ? formatDate(valuesCopy.Year1After) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage1Bonus),
          salaryType: valuesCopy?.salaryType1Bonus?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross1Bonus),
        });
      if (!year2InputsDisabled)
        dataToSend.contract.remunerations.push({
          code: "YEAR2",
          startDate: valuesCopy.Year2Start ? formatDate(valuesCopy.Year2Start) : null,
          endDate: valuesCopy.Year2End ? formatDate(valuesCopy.Year2End) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage2),
          salaryType: valuesCopy?.salaryType2?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross2),
        });
      if (displayYear2Row)
        dataToSend.contract.remunerations.push({
          code: "YEAR2_CHGT_AGE",
          startDate: valuesCopy.Year2Before ? formatDate(valuesCopy.Year2Before) : null,
          endDate: valuesCopy.Year2After ? formatDate(valuesCopy.Year2After) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage2Bonus),
          salaryType: valuesCopy?.salaryType2Bonus?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross2Bonus),
        });
      if (!year3InputsDisabled)
        dataToSend.contract.remunerations.push({
          code: "YEAR3",
          startDate: valuesCopy.Year3Start ? formatDate(valuesCopy.Year3Start) : null,
          endDate: valuesCopy.Year3End ? formatDate(valuesCopy.Year3End) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage3),
          salaryType: valuesCopy?.salaryType3?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross3),
        });
      if (displayYear3Row)
        dataToSend.contract.remunerations.push({
          code: "YEAR3_CHGT_AGE",
          startDate: valuesCopy.Year3Before ? formatDate(valuesCopy.Year3Before) : null,
          endDate: valuesCopy.Year3After ? formatDate(valuesCopy.Year3After) : null,
          grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage3Bonus),
          salaryType: valuesCopy?.salaryType3Bonus?.key,
          grossSalary: parseFloat(valuesCopy.salaryGross3Bonus),
        });

      console.log("DATA TO SEND FORM8", dataToSend);

      props.submitSection(dataToSend, 8);
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      Year1Start: null,
      Year1Before: null,
      Year1After: null,
      Year1End: null,
      salaryPercentage1: "",
      salaryType1: { ...objAucun },
      salaryGross1: "",
      salaryPercentage1Bonus: "",
      salaryType1Bonus: { ...objAucun },
      salaryGross1Bonus: "",
      Year2Start: null,
      Year2Before: null,
      Year2After: null,
      Year2End: null,
      salaryPercentage2: "",
      salaryType2: { ...objAucun },
      salaryGross2: "",
      salaryPercentage2Bonus: "",
      salaryType2Bonus: { ...objAucun },
      salaryGross2Bonus: "",
      Year3Start: null,
      Year3Before: null,
      Year3After: null,
      Year3End: null,
      salaryPercentage3: "",
      salaryType3: { ...objAucun },
      salaryGross3: "",
      salaryPercentage3Bonus: "",
      salaryType3Bonus: { ...objAucun },
      salaryGross3Bonus: "",
    },
    validationSchema: Form8Schema,
    onSubmit,
  });

  useEffect(() => {
    if (formikErrorJustMount) {
      setTimeout(() => {
        handleSubmit();
      }, 0);
    }

    if (JSON.stringify(errors) !== "{}" && formikErrorJustMount) {
      setFormikErrorJustMount(false);
    }
  }, [errors]);

  if (props.formOpen && referentialsData) {
    return (
      <>
        <div className={styles.condition}>{t("app.suivezVosContratsPartner.NotRequiredSection")}</div>
        <p
          style={{
            fontFamily: "Soleil-Leger",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "130%",
            paddingBottom: "4px",
            color: "#190038",
          }}
        >
          {t("app.suivezVosContratsPartner.DateDeNaissanceDeLalternant")}
        </p>
        <p style={{ paddingBottom: "14px", fontSize: "14px" }}>{formatDate(contractData?.student?.birthdate)}</p>
        <p style={{ paddingBottom: "14px", fontSize: "12px" }}>
          {t("app.suivezVosContratsPartner.Text1PageDaide")}
          <a
            style={{ textDecoration: "underline" }}
            href="/statics/Nemo_Infographie_Remuneration_Omnes_Education_V1.pdf"
            target="_blank"
          >
            {t("app.suivezVosContratsPartner.HyperLinkPageDaide")}
          </a>
        </p>
        <p
          style={{
            fontFamily: "Soleil-Leger",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "130%",
            paddingBottom: "4px",
            color: "#190038",
          }}
        >
          {t("app.suivezVosContratsPartner.PrevisionRemuneration")}
        </p>
        <p style={{ fontSize: "12px" }}>
          {t("app.suivezVosContratsPartner.Text1Smic")}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.insee.fr/fr/statistiques/1375188"
            target="_blank"
            rel="noreferrer"
          >
            {t("app.suivezVosContratsPartner.HyperLinkSmic")}
          </a>
        </p>
        <p style={{ paddingBottom: "14px", fontSize: "12px" }}>{t("app.suivezVosContratsPartner.Text2Smic")}</p>
        <div className={styles.condition}>{t("app.suivezVosContratsPartner.changeTrancheAge")}</div>
        <form onSubmit={handleSubmit}>
          <div className={styles.row_inputs_container}>
            <div className={styles.label_plus_input_container}>
              <label htmlFor="Year1Start">{t("app.suivezVosContratsPartner.Annee1Debut")}</label>
              <div className={styles.input_container}>
                {/* <DatePicker
									style={
										!formikErrorJustMount
											? errors.Year1Start &&
											  touched.Year1Start && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
											  }
											: null
									}
									disabled={true}
									value={values.Year1Start}
									onChange={(value) => setFieldValue("Year1Start", value)}
								/> */}
                <DatePickerCustom
                  error={errors.Year1Start}
                  touched={touched.Year1Start}
                  formikErrorJustMount={formikErrorJustMount}
                  disabled={true}
                  value={values.Year1Start}
                  onChange={(value) => setFieldValue("Year1Start", value)}
                />
              </div>
              {!formikErrorJustMount && <InputErrorMessage message={errors.Year1Start} touched={touched.Year1Start} />}
            </div>
            {!displayYear1Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year1End">{t("app.suivezVosContratsPartner.Annee1Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year1End &&
												  touched.Year1End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year1InputsDisabled || year1EndDisabled ? true : false
										}
										value={values.Year1End}
										onChange={(value) => {
											setFieldValue("Year1End", value);
											if (values.Year1Start) {
												handleAutofillDates(
													values.Year1Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year1Before",
													"Year1After",
													"Year1End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year1End}
                    touched={touched.Year1End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year1InputsDisabled || year1EndDisabled ? true : false}
                    value={values.Year1End}
                    onChange={(value) => {
                      setFieldValue("Year1End", value);
                      if (values.Year1Start) {
                        handleAutofillDates(
                          values.Year1Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year1Before",
                          "Year1After",
                          "Year1End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year1End} touched={touched.Year1End} />}
              </div>
            )}
            {displayYear1Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year1Before">
                  {t("app.suivezVosContratsPartner.Annee1Avant")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year1Before &&
												  touched.Year1Before && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year1InputsDisabled}
										value={values.Year1Before}
										onChange={(value) => setFieldValue("Year1Before", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year1Before}
                    touched={touched.Year1Before}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year1InputsDisabled}
                    value={values.Year1Before}
                    onChange={(value) => setFieldValue("Year1Before", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year1Before} touched={touched.Year1Before} />
                )}
              </div>
            )}
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryPercentage1">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
              <input
                // className={styles.testInput}
                style={
                  !formikErrorJustMount
                    ? errors.salaryPercentage1 &&
                      touched.salaryPercentage1 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year1InputsDisabled}
                id="salaryPercentage1"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryPercentage1}
                onChange={(e) => setFieldValue("salaryPercentage1", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryPercentage1} touched={touched.salaryPercentage1} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container}`}>
              <label htmlFor="salaryType1">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
              <div className={styles.input_container}>
                <DropdownList
                  style={
                    !formikErrorJustMount
                      ? errors.salaryType1 &&
                        touched.salaryType1 && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  disabled={year1InputsDisabled}
                  id="salaryType1"
                  dataKey="key"
                  textField="label"
                  data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                  value={values.salaryType1}
                  onChange={(newValue) => {
                    setFieldValue("salaryType1", newValue);
                  }}
                />
              </div>
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryType1} touched={touched.salaryType1} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryGross1">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.salaryGross1 &&
                      touched.salaryGross1 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year1InputsDisabled}
                id="salaryGross1"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryGross1}
                onChange={(e) => setFieldValue("salaryGross1", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryGross1} touched={touched.salaryGross1} />
              )}
            </div>
          </div>
          {displayYear1Row && (
            <div className={styles.row_inputs_container}>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year1After">
                  {t("app.suivezVosContratsPartner.Annee1Apres")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year1After &&
												  touched.Year1After && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year1InputsDisabled}
										value={values.Year1After}
										onChange={(value) => setFieldValue("Year1After", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year1After}
                    touched={touched.Year1After}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year1InputsDisabled}
                    value={values.Year1After}
                    onChange={(value) => setFieldValue("Year1After", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year1After} touched={touched.Year1After} />
                )}
              </div>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year1End">{t("app.suivezVosContratsPartner.Annee1Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year1End &&
												  touched.Year1End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year1InputsDisabled || year1EndDisabled ? true : false
										}
										value={values.Year1End}
										onChange={(value) => {
											setFieldValue("Year1End", value);
											if (values.Year1Start) {
												handleAutofillDates(
													values.Year1Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year1Before",
													"Year1After",
													"Year1End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year1End}
                    touched={touched.Year1End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year1InputsDisabled || year1EndDisabled ? true : false}
                    value={values.Year1End}
                    onChange={(value) => {
                      setFieldValue("Year1End", value);
                      if (values.Year1Start) {
                        handleAutofillDates(
                          values.Year1Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year1Before",
                          "Year1After",
                          "Year1End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year1End} touched={touched.Year1End} />}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryPercentage1Bonus">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
                <input
                  // className={styles.testInput}
                  style={
                    !formikErrorJustMount
                      ? errors.salaryPercentage1Bonus &&
                        touched.salaryPercentage1Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year1InputsDisabled}
                  id="salaryPercentage1Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryPercentage1Bonus}
                  onChange={(e) => setFieldValue("salaryPercentage1Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryPercentage1Bonus} touched={touched.salaryPercentage1Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container}`}>
                <label htmlFor="salaryType1Bonus">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
                <div className={styles.input_container}>
                  <DropdownList
                    style={
                      !formikErrorJustMount
                        ? errors.salaryType1Bonus &&
                          touched.salaryType1Bonus && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    disabled={year1InputsDisabled}
                    id="salaryType1Bonus"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                    value={values.salaryType1Bonus}
                    onChange={(newValue) => {
                      setFieldValue("salaryType1Bonus", newValue);
                    }}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryType1Bonus} touched={touched.salaryType1Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryGross1Bonus">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
                <input
                  style={
                    !formikErrorJustMount
                      ? errors.salaryGross1Bonus &&
                        touched.salaryGross1Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year1InputsDisabled}
                  id="salaryGross1Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryGross1Bonus}
                  onChange={(e) => setFieldValue("salaryGross1Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryGross1Bonus} touched={touched.salaryGross1Bonus} />
                )}
              </div>
            </div>
          )}
          {/* Second Row */}
          <div className={styles.row_inputs_container}>
            <div className={styles.label_plus_input_container}>
              <label htmlFor="Year2Start">{t("app.suivezVosContratsPartner.Annee2Debut")}</label>
              <div className={styles.input_container}>
                {/* <DatePicker
									style={
										!formikErrorJustMount
											? errors.Year2Start &&
											  touched.Year2Start && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
											  }
											: null
									}
									disabled={year2InputsDisabled}
									value={values.Year2Start}
									onChange={(value) => {
										setFieldValue("Year2Start", value);
										if (values.Year2End) {
											handleAutofillDates(
												value.toISOString().split("T")[0],
												values.Year2End.toISOString().split("T")[0],
												"Year2Before",
												"Year2After",
												"Year2End"
											);
										}
									}}
								/> */}
                <DatePickerCustom
                  error={errors.Year2Start}
                  touched={touched.Year2Start}
                  formikErrorJustMount={formikErrorJustMount}
                  disabled={year2InputsDisabled}
                  value={values.Year2Start}
                  onChange={(value) => {
                    setFieldValue("Year2Start", value);
                    if (values.Year2End) {
                      handleAutofillDates(
                        value.toISOString().split("T")[0],
                        values.Year2End.toISOString().split("T")[0],
                        "Year2Before",
                        "Year2After",
                        "Year2End",
                      );
                    }
                  }}
                />
              </div>
              {!formikErrorJustMount && <InputErrorMessage message={errors.Year2Start} touched={touched.Year2Start} />}
            </div>
            {!displayYear2Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year2End">{t("app.suivezVosContratsPartner.Annee2Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year2End &&
												  touched.Year2End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year2InputsDisabled || year2EndDisabled ? true : false
										}
										value={values.Year2End}
										onChange={(value) => {
											setFieldValue("Year2End", value);
											if (values.Year2Start) {
												handleAutofillDates(
													values.Year2Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year2Before",
													"Year2After",
													"Year2End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year2End}
                    touched={touched.Year2End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year2InputsDisabled || year2EndDisabled ? true : false}
                    value={values.Year2End}
                    onChange={(value) => {
                      setFieldValue("Year2End", value);
                      if (values.Year2Start) {
                        handleAutofillDates(
                          values.Year2Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year2Before",
                          "Year2After",
                          "Year2End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year2End} touched={touched.Year2End} />}
              </div>
            )}
            {displayYear2Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year2Before">
                  {t("app.suivezVosContratsPartner.Annee2Avant")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year2Before &&
												  touched.Year2Before && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year2InputsDisabled}
										value={values.Year2Before}
										onChange={(value) => setFieldValue("Year2Before", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year2Before}
                    touched={touched.Year2Before}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year2InputsDisabled}
                    value={values.Year2Before}
                    onChange={(value) => setFieldValue("Year2Before", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year2Before} touched={touched.Year2Before} />
                )}
              </div>
            )}
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryPercentage2">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
              <input
                // className={styles.testInput}
                style={
                  !formikErrorJustMount
                    ? errors.salaryPercentage2 &&
                      touched.salaryPercentage2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year2InputsDisabled}
                id="salaryPercentage2"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryPercentage2}
                onChange={(e) => setFieldValue("salaryPercentage2", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryPercentage2} touched={touched.salaryPercentage2} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container}`}>
              <label htmlFor="salaryType2">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
              <div className={styles.input_container}>
                <DropdownList
                  style={
                    !formikErrorJustMount
                      ? errors.salaryType2 &&
                        touched.salaryType2 && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  disabled={year2InputsDisabled}
                  id="salaryType2"
                  dataKey="key"
                  textField="label"
                  data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                  value={values.salaryType2}
                  onChange={(newValue) => {
                    setFieldValue("salaryType2", newValue);
                  }}
                />
              </div>
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryType2} touched={touched.salaryType2} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryGross2">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.salaryGross2 &&
                      touched.salaryGross2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year2InputsDisabled}
                id="salaryGross2"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryGross2}
                onChange={(e) => setFieldValue("salaryGross2", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryGross2} touched={touched.salaryGross2} />
              )}
            </div>
          </div>
          {displayYear2Row && (
            <div className={styles.row_inputs_container}>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year2After">
                  {t("app.suivezVosContratsPartner.Annee2Apres")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year2After &&
												  touched.Year2After && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year2InputsDisabled}
										value={values.Year2After}
										onChange={(value) => setFieldValue("Year2After", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year2After}
                    touched={touched.Year2After}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year2InputsDisabled}
                    value={values.Year2After}
                    onChange={(value) => setFieldValue("Year2After", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year2After} touched={touched.Year2After} />
                )}
              </div>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year2End">{t("app.suivezVosContratsPartner.Annee2Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year2End &&
												  touched.Year2End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year2InputsDisabled || year2EndDisabled ? true : false
										}
										value={values.Year2End}
										onChange={(value) => {
											setFieldValue("Year2End", value);
											if (values.Year2Start) {
												handleAutofillDates(
													values.Year2Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year2Before",
													"Year2After",
													"Year2End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year2End}
                    touched={touched.Year2End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year2InputsDisabled || year2EndDisabled ? true : false}
                    value={values.Year2End}
                    onChange={(value) => {
                      setFieldValue("Year2End", value);
                      if (values.Year2Start) {
                        handleAutofillDates(
                          values.Year2Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year2Before",
                          "Year2After",
                          "Year2End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year2End} touched={touched.Year2End} />}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryPercentage2Bonus">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
                <input
                  // className={styles.testInput}
                  style={
                    !formikErrorJustMount
                      ? errors.salaryPercentage2Bonus &&
                        touched.salaryPercentage2Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year2InputsDisabled}
                  id="salaryPercentage2Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryPercentage2Bonus}
                  onChange={(e) => setFieldValue("salaryPercentage2Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryPercentage2Bonus} touched={touched.salaryPercentage2Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container}`}>
                <label htmlFor="salaryType2Bonus">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
                <div className={styles.input_container}>
                  <DropdownList
                    style={
                      !formikErrorJustMount
                        ? errors.salaryType2Bonus &&
                          touched.salaryType2Bonus && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    disabled={year2InputsDisabled}
                    id="salaryType2Bonus"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                    value={values.salaryType2Bonus}
                    onChange={(newValue) => {
                      setFieldValue("salaryType2Bonus", newValue);
                    }}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryType2Bonus} touched={touched.salaryType2Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryGross2Bonus">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
                <input
                  style={
                    !formikErrorJustMount
                      ? errors.salaryGross2Bonus &&
                        touched.salaryGross2Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year2InputsDisabled}
                  id="salaryGross2Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryGross2Bonus}
                  onChange={(e) => setFieldValue("salaryGross2Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryGross2Bonus} touched={touched.salaryGross2Bonus} />
                )}
              </div>
            </div>
          )}
          {/* Third Row */}
          <div className={styles.row_inputs_container}>
            <div className={styles.label_plus_input_container}>
              <label htmlFor="Year3Start">{t("app.suivezVosContratsPartner.Annee3Debut")}</label>
              <div className={styles.input_container}>
                {/* <DatePicker
									style={
										!formikErrorJustMount
											? errors.Year3Start &&
											  touched.Year3Start && {
													border: "2px solid #EA001E",
													borderRadius: "5px",
											  }
											: null
									}
									disabled={year3InputsDisabled}
									value={values.Year3Start}
									onChange={(value) => {
										setFieldValue("Year3Start", value);
										if (values.Year3End) {
											handleAutofillDates(
												value.toISOString().split("T")[0],
												values.Year3End.toISOString().split("T")[0],
												"Year3Before",
												"Year3After",
												"Year3End"
											);
										}
									}}
								/> */}
                <DatePickerCustom
                  error={errors.Year3Start}
                  touched={touched.Year3Start}
                  formikErrorJustMount={formikErrorJustMount}
                  disabled={year3InputsDisabled}
                  value={values.Year3Start}
                  onChange={(value) => {
                    setFieldValue("Year3Start", value);
                    if (values.Year3End) {
                      handleAutofillDates(
                        value.toISOString().split("T")[0],
                        values.Year3End.toISOString().split("T")[0],
                        "Year3Before",
                        "Year3After",
                        "Year3End",
                      );
                    }
                  }}
                />
              </div>
              {!formikErrorJustMount && <InputErrorMessage message={errors.Year3Start} touched={touched.Year3Start} />}
            </div>
            {!displayYear3Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year3End">{t("app.suivezVosContratsPartner.Annee3Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year3End &&
												  touched.Year3End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year3InputsDisabled || year3EndDisabled ? true : false
										}
										value={values.Year3End}
										onChange={(value) => {
											setFieldValue("Year3End", value);
											if (values.Year3Start) {
												handleAutofillDates(
													values.Year3Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year3Before",
													"Year3After",
													"Year3End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year3End}
                    touched={touched.Year3End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year3InputsDisabled || year3EndDisabled ? true : false}
                    value={values.Year3End}
                    onChange={(value) => {
                      setFieldValue("Year3End", value);
                      if (values.Year3Start) {
                        handleAutofillDates(
                          values.Year3Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year3Before",
                          "Year3After",
                          "Year3End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year3End} touched={touched.Year3End} />}
              </div>
            )}
            {displayYear3Row && (
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year3Before">
                  {t("app.suivezVosContratsPartner.Annee3Avant")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year3Before &&
												  touched.Year3Before && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year3InputsDisabled}
										value={values.Year3Before}
										onChange={(value) => setFieldValue("Year3Before", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year3Before}
                    touched={touched.Year3Before}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year3InputsDisabled}
                    value={values.Year3Before}
                    onChange={(value) => setFieldValue("Year3Before", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year3Before} touched={touched.Year3Before} />
                )}
              </div>
            )}
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryPercentage3">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
              <input
                // className={styles.testInput}
                style={
                  !formikErrorJustMount
                    ? errors.salaryPercentage3 &&
                      touched.salaryPercentage3 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year3InputsDisabled}
                id="salaryPercentage3"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryPercentage3}
                onChange={(e) => setFieldValue("salaryPercentage3", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryPercentage3} touched={touched.salaryPercentage3} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container}`}>
              <label htmlFor="salaryType3">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
              <div className={styles.input_container}>
                <DropdownList
                  style={
                    !formikErrorJustMount
                      ? errors.salaryType3 &&
                        touched.salaryType3 && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      : null
                  }
                  disabled={year3InputsDisabled}
                  id="salaryType3"
                  dataKey="key"
                  textField="label"
                  data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                  value={values.salaryType3}
                  onChange={(newValue) => {
                    setFieldValue("salaryType3", newValue);
                  }}
                />
              </div>
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryType3} touched={touched.salaryType3} />
              )}
            </div>
            <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
              <label htmlFor="salaryGross3">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
              <input
                style={
                  !formikErrorJustMount
                    ? errors.salaryGross3 &&
                      touched.salaryGross3 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                disabled={year3InputsDisabled}
                id="salaryGross3"
                type="number"
                step="0.01"
                min="0"
                value={values.salaryGross3}
                onChange={(e) => setFieldValue("salaryGross3", e.target.value)}
              />
              {!formikErrorJustMount && (
                <InputErrorMessage message={errors.salaryGross3} touched={touched.salaryGross3} />
              )}
            </div>
          </div>
          {displayYear3Row && (
            <div className={styles.row_inputs_container}>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year3After">
                  {t("app.suivezVosContratsPartner.Annee3Apres")} {ageToDisplay} {t("app.suivezVosContratsPartner.Ans")}
                </label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year3After &&
												  touched.Year3After && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={year3InputsDisabled}
										value={values.Year3After}
										onChange={(value) => setFieldValue("Year3After", value)}
									/> */}
                  <DatePickerCustom
                    error={errors.Year3After}
                    touched={touched.Year3After}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year3InputsDisabled}
                    value={values.Year3After}
                    onChange={(value) => setFieldValue("Year3After", value)}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.Year3After} touched={touched.Year3After} />
                )}
              </div>
              <div className={styles.label_plus_input_container}>
                <label htmlFor="Year3End">{t("app.suivezVosContratsPartner.Annee3Fin")}</label>
                <div className={styles.input_container}>
                  {/* <DatePicker
										style={
											!formikErrorJustMount
												? errors.Year3End &&
												  touched.Year3End && {
														border: "2px solid #EA001E",
														borderRadius: "5px",
												  }
												: null
										}
										disabled={
											year3InputsDisabled || year3EndDisabled ? true : false
										}
										value={values.Year3End}
										onChange={(value) => {
											setFieldValue("Year3End", value);
											if (values.Year3Start) {
												handleAutofillDates(
													values.Year3Start.toISOString().split("T")[0],
													value.toISOString().split("T")[0],
													"Year3Before",
													"Year3After",
													"Year3End"
												);
											}
										}}
									/> */}
                  <DatePickerCustom
                    error={errors.Year3End}
                    touched={touched.Year3End}
                    formikErrorJustMount={formikErrorJustMount}
                    disabled={year3InputsDisabled || year3EndDisabled ? true : false}
                    value={values.Year3End}
                    onChange={(value) => {
                      setFieldValue("Year3End", value);
                      if (values.Year3Start) {
                        handleAutofillDates(
                          values.Year3Start.toISOString().split("T")[0],
                          value.toISOString().split("T")[0],
                          "Year3Before",
                          "Year3After",
                          "Year3End",
                        );
                      }
                    }}
                  />
                </div>
                {!formikErrorJustMount && <InputErrorMessage message={errors.Year3End} touched={touched.Year3End} />}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryPercentage3Bonus">{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</label>
                <input
                  // className={styles.testInput}
                  style={
                    !formikErrorJustMount
                      ? errors.salaryPercentage3Bonus &&
                        touched.salaryPercentage3Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year3InputsDisabled}
                  id="salaryPercentage3Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryPercentage3Bonus}
                  onChange={(e) => setFieldValue("salaryPercentage3Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryPercentage3Bonus} touched={touched.salaryPercentage3Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container}`}>
                <label htmlFor="salaryType3Bonus">{t("app.suivezVosContratsPartner.TypeDeSalaire")}</label>
                <div className={styles.input_container}>
                  <DropdownList
                    style={
                      !formikErrorJustMount
                        ? errors.salaryType3Bonus &&
                          touched.salaryType3Bonus && {
                            border: "2px solid #EA001E",
                            borderRadius: "5px",
                          }
                        : null
                    }
                    disabled={year3InputsDisabled}
                    id="salaryType3Bonus"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
                    value={values.salaryType3Bonus}
                    onChange={(newValue) => {
                      setFieldValue("salaryType3Bonus", newValue);
                    }}
                  />
                </div>
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryType3Bonus} touched={touched.salaryType3Bonus} />
                )}
              </div>
              <div className={`${styles.label_plus_input_container} ${styles.testInput}`}>
                <label htmlFor="salaryGross3Bonus">{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</label>
                <input
                  style={
                    !formikErrorJustMount
                      ? errors.salaryGross3Bonus &&
                        touched.salaryGross3Bonus && {
                          border: "2px solid #EA001E",
                        }
                      : null
                  }
                  disabled={year3InputsDisabled}
                  id="salaryGross3Bonus"
                  type="number"
                  step="0.01"
                  min="0"
                  value={values.salaryGross3Bonus}
                  onChange={(e) => setFieldValue("salaryGross3Bonus", e.target.value)}
                />
                {!formikErrorJustMount && (
                  <InputErrorMessage message={errors.salaryGross3Bonus} touched={touched.salaryGross3Bonus} />
                )}
              </div>
            </div>
          )}
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn
              onClick={handlePingStatus}
              //disabled={generalEnable}
              text={t("app.visualisationOffre.ValidezLesModifications")}
            />
          </div>
        </form>
      </>
    );
  }
};

export default Form8;
