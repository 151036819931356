import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { getYourRecruitmentPropsData } from "app/slices/companyRecruitment/getCompanyRecruitment.slice";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { DropdownList } from "react-widgets";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import { getEmployeesThunk, getEmployeeDetails } from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";

import { createBooleanReferential, createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form2.module.scss";

/* eslint-disable */
const dropDownData = {
  civility: [
    { key: "-Aucun-", label: "-Aucun-" },
    { key: "Madame", label: "Madame" },
    { key: "Monsieur", label: "Monsieur" },
  ],
};

const Form2 = (props) => {
  const dispatch = useDispatch();
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const FORM = "FORM_2";
  const [clicked, seIsClicked] = useState(false);
  const [mailWasFound, setMailWasFound] = useState(false);
  const [generalDisable, setGeneralDisable] = useState(false);
  const [justMounted, setJustMounted] = useState(false);

  const employeeDetailsForm2 = useSelector(getEmployeeDetails);

  const [formStep, setFormStep] = useState("");

  const [dataWasChange, setDataWasChange] = useState(false);

  const recruitmentPropsGeneral = useSelector(getYourRecruitmentPropsData);
  //const recruitmentPropsGeneral = recruitmentPropsGeneral[0];

  const [formikErrorJustMount, setFormikErrorJustMount] = useState(true);

  const timedOutRef = useRef(null);

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  useEffect(() => {
    if (props.submitAll) {
      handlePingStatus();
      handleSubmit();
      if (props.lastStep) props.setSubmitAll(false);
    }
  }, [props.submitAll]);

  useEffect(() => {
    if (employeeDetailsForm2) {
      if (employeeDetailsForm2.length > 0) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetailsForm2]);

  useEffect(() => {
    if (values) handleEnableOnMount(recruitmentPropsGeneral?.company?.contacts?.signatory);
  }, []);

  const handlePingStatus = () => {
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  const handleMountPingStatus = () => {
    setFormikErrorJustMount(false);
    if (JSON.stringify(errors) === "{}") props.setAreaFufiled(true);
    else props.setAreaFufiled(false);
  };

  // Yup validation schema
  const Form1Schema = Yup.object().shape({
    signatoryEmailForm2: Yup.string()
      .email(`${t("app.errorMessages.EmailInvalid")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    // fixPhone: Yup.string()
    // 	.matches(phoneRegExp, t("app.errorMessages.PhoneFormat"))
    // 	.required(t("app.errorMessages.PhoneFormat")),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    fixPhone: Yup.string().test(
      "if-is-notFrench",
      t("app.errorMessages.PhoneFormat"),
      (value) => value !== undefined && isValidPhoneNumber(value) !== false,
    ),
    lastNameForm2: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    firstNameForm2: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    functionForm2: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`).max(40, `${t("app.errorMessages.Max40")}`),
    civilityForm2: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    habilitationForm2: Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
  });

  const onSubmit = (values, actions) => {
    console.log("SUBMIT ALL SUBMIT 2", errors);
    if (!formikErrorJustMount) {
      const valuesCopy = structuredClone(values);
      console.log("SUBMIT ALL SUBMIT 2 INSIDE", errors);

      // These functionForm2s change the value of certain keys depending on the swagger, before dataToSend initialization
      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      const dataToSend = {
        status: "En cours",
        company: {
          contacts: {
            signatory: {
              email: valuesCopy.signatoryEmailForm2,
              civility: valuesCopy.civilityForm2?.key,
              name: valuesCopy.lastNameForm2,
              firstname: valuesCopy.firstNameForm2,
              phones: {
                office: valuesCopy.fixPhone,
                cellular: valuesCopy.phone,
              },
              function: valuesCopy.functionForm2,
              empowered: valuesCopy.habilitationForm2,
            },
          },
        },
      };

      props.submitSection(dataToSend, 2);
    } else handleMountPingStatus();
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      signatoryEmailForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.email
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.email
        : "",
      lastNameForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.name
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.name
        : "",
      firstNameForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.firstname
        : "",
      fixPhone: recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.office
        : "",
      phone: recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.phones?.cellular
        : "",
      functionForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.function
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.function
        : "",
      civilityForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.civility
        : { ...objAucun },
      habilitationForm2: recruitmentPropsGeneral?.company?.contacts?.signatory?.empowered
        ? recruitmentPropsGeneral?.company?.contacts?.signatory?.empowered
        : false,
    },
    validationSchema: Form1Schema,
    onSubmit,
  });

  useEffect(() => {
    if (formikErrorJustMount) onSubmit();
  }, [errors]);

  useEffect(() => {
    if (values.signatoryEmailForm2.length >= 1) setGeneralDisable(false);
    else setGeneralDisable(true);
    if (!errors.signatoryEmailForm2 && values.signatoryEmailForm2.length > 1) {
      if (timedOutRef.current) clearTimeout(timedOutRef.current);
      timedOutRef.current = setTimeout(() => {
        (async () => {
          try {
            await dispatch(
              getEmployeesThunk({
                email: values.signatoryEmailForm2,
                axiosCancelToken: axiosCancelToken,
              }),
            ).unwrap();
            console.log("get success Employees !");
          } catch (error) {
            console.log("get fail Employees !", error.message);
          }
        })();
      }, "500");
    } else {
      setFieldValue("civilityForm2", { ...objAucun });
      setFieldValue("lastNameForm2", "");
      setFieldValue("firstNameForm2", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("functionForm2", "");
      setFieldValue("habilitationForm2", false);
      return;
    }
  }, [errors.signatoryEmailForm2, values.signatoryEmailForm2]);

  const displayUserData = () => {
    if (formStep === FORM) {
      setFieldValue("lastNameForm2", employeeDetailsForm2[0]?.name ? employeeDetailsForm2[0]?.name : "");
      setFieldValue("firstNameForm2", employeeDetailsForm2[0]?.firstname ? employeeDetailsForm2[0]?.firstname : "");
      setFieldValue(
        "civilityForm2",
        employeeDetailsForm2[0]?.civility ? employeeDetailsForm2[0]?.civility : { ...objAucun },
      );
      setFieldValue("fixPhone", employeeDetailsForm2[0]?.phones?.office ? employeeDetailsForm2[0]?.phones?.office : "");
      setFieldValue(
        "phone",
        employeeDetailsForm2[0]?.phones?.cellular ? employeeDetailsForm2[0]?.phones?.cellular : "",
      );
      setFieldValue("functionForm2", employeeDetailsForm2[0]?.function ? employeeDetailsForm2[0]?.function : "");
      setFieldValue(
        "habilitationForm2",
        employeeDetailsForm2[0]?.empowered ? employeeDetailsForm2[0]?.empowered : false,
      );

      // if (employeeDetailsForm2[0]?.companyId === myInfo?.companyId)
      // 	setGeneralDisable(false);
      // else setGeneralDisable(true);

      setMailWasFound(true);
      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    //console.log("EMPLOYEE DATA FALSE");
    if (formStep === FORM) {
      setFieldValue("civilityForm2", { ...objAucun });
      setFieldValue("lastNameForm2", "");
      setFieldValue("firstNameForm2", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("functionForm2", "");
      setFieldValue("habilitationForm2", false);
      setGeneralDisable(false);
      setMailWasFound(false);
      setFormStep("");
    }
  };

  const handleEnableOnMount = (signContact) => {
    setJustMounted(true);
    if (values.signatoryEmailForm2 >= 1) setGeneralDisable(false);
    else setGeneralDisable(true);
  };

  const validPhone = touched.phone && errors.phone;
  const validFixPhone = errors.fixPhone && touched.fixPhone;

  useEffect(() => {
    console.log("Clicked", clicked);
    if (formikErrorJustMount) {
      handleSubmit();
    }
    console.log("Formik errors", errors);
    console.log("isOpen", props.formOpen);
  }, [errors]);
  if (props.formOpen) {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <p
            style={{
              fontFamily: "Soleil-Leger",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "130%",
              paddingBottom: "24px",
              color: "#190038",
            }}
          >
            {t("app.suivezVosContratsPartner.CoordonneesTitle")}
          </p>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="signatoryEmailForm2">{t("app.suivezVosContratsPartner.EmailDuContact")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.signatoryEmailForm2 &&
                      touched.signatoryEmailForm2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="signatoryEmailForm2"
                type="email"
                value={values.signatoryEmailForm2}
                onChange={(e) => {
                  setFormStep(FORM);
                  setFieldValue("signatoryEmailForm2", e.target.value);
                }}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.signatoryEmailForm2} touched={touched.signatoryEmailForm2} />
              )}
              <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="fixPhone">{t("app.suivezVosContratsPartner.Telephone")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount && clicked
                    ? errors.fixPhone &&
                      touched.fixPhone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="fixPhone"
                id="fixPhone"
                country={"FR"}
                disabled={generalDisable}
                value={values.fixPhone}
                onChange={(e) => {
                  setFieldValue("fixPhone", e);
                }}
                className={!formikErrorJustMount ? `${validFixPhone && `${styles.errorInput}`}` : null}
              />
              {validFixPhone && !formikErrorJustMount && clicked && (
                <span className={styles.error}>{errors.fixPhone}</span>
              )}
            </div>

            <div className={`${styles.formGroup} input-like-rw`}>
              <label htmlFor="phone">{t("app.suivezVosContratsPartner.Portable")}</label>
              <PhoneInput
                style={
                  !formikErrorJustMount
                    ? errors.phone &&
                      touched.phone && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                type="text"
                name="phone"
                id="phone"
                country={"FR"}
                disabled={generalDisable}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e);
                }}
                className={!formikErrorJustMount && clicked ? `${validPhone && `${styles.errorInput}`}` : null}
              />
              {/* <input
								style={
									!formikErrorJustMount
										? errors.phone &&
										  touched.phone && {
												border: "2px solid #EA001E",
										  }
										: null
								}
								id="phone"
								name="phone"
								type="text"
								disabled={generalDisable}
								value={values.phone}
								onChange={(e) => setFieldValue("phone", e.target.value)}
								className={
									!formikErrorJustMount
										? `${validPhone && `${styles.errorInput}`}`
										: null
								}
							/> */}
              {validPhone && !formikErrorJustMount && clicked && <span className={styles.error}>{errors.phone}</span>}
              <p>{t("app.suivezVosContratsPartner.CheckboxSignatureElectronique")}</p>
            </div>
          </div>
          <p
            style={{
              fontFamily: "Soleil-Leger",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "130%",
              paddingBottom: "24px",
              color: "#190038",
            }}
          >
            {t("app.suivezVosContratsPartner.IdentiteTitle")}
          </p>
          <div className={styles.wrapper}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="lastNameForm2">{t("app.suivezVosContratsPartner.Nom")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.lastNameForm2 &&
                      touched.lastNameForm2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="lastNameForm2"
                type="text"
                disabled={generalDisable}
                value={values.lastNameForm2}
                onChange={(e) => setFieldValue("lastNameForm2", e.target.value)}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.lastNameForm2} touched={touched.lastNameForm2} />
              )}
            </div>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="firstNameForm2">{t("app.suivezVosContratsPartner.Prenom")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.firstNameForm2 &&
                      touched.firstNameForm2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="firstNameForm2"
                type="text"
                disabled={generalDisable}
                value={values.firstNameForm2}
                onChange={(e) => setFieldValue("firstNameForm2", e.target.value)}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.firstNameForm2} touched={touched.firstNameForm2} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="civilityForm2">{t("app.suivezVosContratsPartner.Civilite")}</label>
              <DropdownList
                style={
                  !formikErrorJustMount && clicked
                    ? errors.civilityForm2 &&
                      touched.civilityForm2 && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    : null
                }
                id="civilityForm2"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...props?.referencialData?.CIVILITY]}
                disabled={generalDisable}
                value={values.civilityForm2}
                onChange={(newValue) => setFieldValue("civilityForm2", newValue)}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.civilityForm2} touched={touched.civilityForm2} />
              )}
            </div>
          </div>
          <div className={styles.wrapper} style={{ width: "221%" }}>
            <div className={` ${styles.formGroup} input-like-rw`}>
              <label htmlFor="functionForm2">{t("app.suivezVosContratsPartner.Fonction")}</label>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? errors.functionForm2 &&
                      touched.functionForm2 && {
                        border: "2px solid #EA001E",
                      }
                    : null
                }
                id="functionForm2"
                type="text"
                disabled={generalDisable}
                value={values.functionForm2}
                onChange={(e) => setFieldValue("functionForm2", e.target.value)}
              />
              {!formikErrorJustMount && clicked && (
                <InputErrorMessage message={errors.functionForm2} touched={touched.functionForm2} />
              )}
            </div>
          </div>
          <div className={` ${styles.checkbox_container} input-like-rw`}>
            <label htmlFor="habilitationForm2">{t("app.suivezVosContratsPartner.Habilitation")}</label>
            <div>
              <input
                style={
                  !formikErrorJustMount && clicked
                    ? touched.habilitationForm2 && values.habilitationForm2 === false
                      ? {
                          outline: "2px solid #EA001E",
                          borderRadius: "50%",
                          outlineOffset: "-1.9px",
                        }
                      : null
                    : null
                }
                id="habilitationForm2"
                type="checkbox"
                disabled={false}
                checked={values.habilitationForm2}
                onChange={(e) => setFieldValue("habilitationForm2", e.target.checked)}
              />
              <p>{t("app.suivezVosContratsPartner.PersonneHabilitee")}</p>
            </div>
            {!formikErrorJustMount && clicked && (
              <InputErrorMessage message={errors.habilitationForm2} touched={touched.habilitationForm2} />
            )}
          </div>

          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn
              onClick={() => {
                handlePingStatus();
                seIsClicked(true);
              }}
              //disabled={generalEnable}
              text={t("app.visualisationOffre.ValidezLesModifications")}
            />
          </div>
        </form>
      </div>
    );
  }
};

export default Form2;
