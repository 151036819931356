import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm8Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm8/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm8Slice = createSlice({
  name: "checkYourRecruitmentsForm8",
  initialState,
  reducers: {
    handleRecruitmentPropsForm8Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm8Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm8Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm8Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm8Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm8Data, handleRecruitmentSectionsForm8Data } =
  checkYourRecruitmentsForm8Slice.actions;

export const getYourRecruitmentPropsForm8Data = (state) => state.checkYourRecruitmentsForm8.recruitmentPropsForm8Data;
export const getAllUserRecruitmentsForm8 = (state) => state.checkYourRecruitmentsForm8.recruitmentList;
export const getUserRecruitmentsPendingForm8 = (state) => state.checkYourRecruitmentsForm8.pending;
export const getUserRecruitmentsErrorMessageForm8 = (state) => state.checkYourRecruitmentsForm8.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm8Slice.reducer;
