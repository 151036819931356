import Accordion from "components/accordion/accordion";
import { t } from "i18next";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { getLanguage } from "app/slices/language/language.slice";

import styles from "./Faq.module.scss";

const Faq = () => {
  const languageSelector = useSelector(getLanguage);
  const allQuestions = [
    {
      title: `${t("app.faqEntreprise.Question1")}`,
      content: `${t("app.faqEntreprise.Answer1")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question2")}`,
      content: `${t("app.faqEntreprise.Answer2")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question3")}`,
      content: `${t("app.faqEntreprise.Answer3")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question4")}`,
      content: `${t("app.faqEntreprise.Answer4")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question5")}`,
      content: `${t("app.faqEntreprise.Answer5")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question6")}`,
      content: `${t("app.faqEntreprise.Answer6")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question7")}`,
      content: `${t("app.faqEntreprise.Answer7")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question8")}`,
      content: `${t("app.faqEntreprise.Answer8")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question9")}`,
      content: `${t("app.faqEntreprise.Answer9")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question10")}`,
      content: `${t("app.faqEntreprise.Answer10")}`,
    },
    {
      title: `${t("app.faqEntreprise.Question11")}`,
      content: `${t("app.faqEntreprise.Answer11")}`,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("app.faqEntreprise.intro1")}</h1>
        <p>
          {t("app.faqEntreprise.Description1")}
          <br />
          {t("app.faqEntreprise.Description2")}
          <br />
          {t("app.faqEntreprise.Description3")}
          {languageSelector === "fr" ? (
            <a href="/statics/CGU_Nemo_OmnesEducation.pdf" target="_blank">
              CGU
            </a>
          ) : (
            <a href="/statics/CGU_Nemo_OmnesEducation_ENG.pdf" target="_blank">
              CGU
            </a>
          )}
        </p>
      </div>
      <div className={styles.content}>
        {allQuestions?.map((item) => (
          <Accordion title={item.title} content={item.content} key={nanoid()} />
        ))}
      </div>
    </div>
  );
};
export default Faq;
