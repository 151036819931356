import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosStudent } from "app/axiosInstances/axiosInstances";

const getCVsKey = "allCvs";
const cvByIdKey = "CvById";

const initialState = {
  [getCVsKey]: {
    listCVs: [],
    numberOfCv: 0,
    currentPagination: 1,
    maxPagination: undefined,
    offset: 0,
    endIndex: 20,
    pending: false,
    errorMessage: null,
  },
  [cvByIdKey]: {
    id: null,
    cv: null,
    pending: false,
    errorMessage: null,
  },
};

// THUNKS SECTION

export const getCVThunk = createAsyncThunk(
  "checkAllCV/getCVThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v1/students/resumes?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const { offset } = getState().checkAllCV[getCVsKey];
    const { endIndex } = getState().checkAllCV[getCVsKey];

    console.log("off", offset, endIndex);

    const response = await axiosStudent().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CV", response);

    const numberOfCv = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfCv);

    return {
      data: response.data,
      numberOfCv,
    };
  },
);

export const nextCVPaginationThunk = createAsyncThunk(
  "checkAllCV/nextCVPaginationThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v1/students/resumes?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const offset = getState().checkAllCV[getCVsKey].offset + 20;
    const endIndex = getState().checkAllCV[getCVsKey].endIndex + 20;

    console.log("off", offset, endIndex);

    const response = await axiosStudent().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CV", response);

    const numberOfCv = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfCv);

    return {
      data: response.data,
      numberOfCv,
    };
  },
);

export const previousCVPaginationThunk = createAsyncThunk(
  "checkAllCV/previousCVPaginationThunk",
  async ({ query, axiosCancelToken }, { getState }) => {
    const url = `/v1/students/resumes?${query}`;
    const encoded = encodeURI(url).replace("+", "%2B");

    const offset = getState().checkAllCV[getCVsKey].offset - 20;
    const endIndex = getState().checkAllCV[getCVsKey].endIndex - 20;

    console.log("off", offset, endIndex);

    const response = await axiosStudent().get(decodeURI(encoded), {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CV", response);

    const numberOfCv = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfCv", numberOfCv);

    return {
      data: response.data,
      numberOfCv,
    };
  },
);

export const getCVByIdThunk = createAsyncThunk("checkAllCV/getCVByIdThunk", async (id) => {
  const response = await axiosStudent().get(`/v1/students/resumes/${id}?embedded=true`);
  return response.data;
});

// CREATESLICE SECTION

const checkAllCVSlice = createSlice({
  name: "checkAllCV",
  initialState,
  reducers: {
    resetGetCVsKeyState: (state) => {
      state[getCVsKey].listCVs = [];
      state[getCVsKey].numberOfCv = 0;
      state[getCVsKey].currentPagination = 1;
      state[getCVsKey].maxPagination = undefined;
      state[getCVsKey].offset = 0;
      state[getCVsKey].endIndex = 20;
      state[getCVsKey].pending = false;
      state[getCVsKey].errorMessage = null;
    },
  },
  extraReducers: {
    [getCVByIdThunk.pending]: (state) => {
      state[cvByIdKey].errorMessage = null;
      state[cvByIdKey].pending = true;
    },
    [getCVByIdThunk.fulfilled]: (state, action) => {
      state[cvByIdKey].pending = false;
      state[cvByIdKey].cv = action.payload;
    },
    [getCVByIdThunk.rejected]: (state, action) => {
      state[cvByIdKey].pending = false;
      state[cvByIdKey].errorMessage = action.error.message;
    },
    [getCVThunk.pending]: (state) => {
      state[getCVsKey].errorMessage = null;
      state[getCVsKey].pending = true;
    },
    [getCVThunk.fulfilled]: (state, action) => {
      state[getCVsKey].listCVs = action.payload.data;
      state[getCVsKey].numberOfCv = action.payload.numberOfCv;
      state[getCVsKey].currentPagination = 1;
      state[getCVsKey].maxPagination =
        action.payload.numberOfCv % 20 === 0
          ? action.payload.numberOfCv / 20
          : Math.floor(action.payload.numberOfCv / 20) + 1;
      state[getCVsKey].pending = false;
    },
    [getCVThunk.rejected]: (state, action) => {
      state[getCVsKey].pending = false;
      state[getCVsKey].errorMessage = action.error.message;
    },
    [nextCVPaginationThunk.pending]: (state) => {
      state[getCVsKey].errorMessage = null;
      state[getCVsKey].pending = true;
    },
    [nextCVPaginationThunk.fulfilled]: (state, action) => {
      state[getCVsKey].listCVs = action.payload.data;
      state[getCVsKey].numberOfCv = action.payload.numberOfCv;
      state[getCVsKey].currentPagination += 1;
      state[getCVsKey].maxPagination =
        action.payload.numberOfCv % 20 === 0
          ? action.payload.numberOfCv / 20
          : Math.floor(action.payload.numberOfCv / 20) + 1;
      state[getCVsKey].offset += 20;
      state[getCVsKey].endIndex += 20;
      state[getCVsKey].pending = false;
    },
    [nextCVPaginationThunk.rejected]: (state, action) => {
      state[getCVsKey].pending = false;
      state[getCVsKey].errorMessage = action.error.message;
    },
    [previousCVPaginationThunk.pending]: (state) => {
      state[getCVsKey].errorMessage = null;
      state[getCVsKey].pending = true;
    },
    [previousCVPaginationThunk.fulfilled]: (state, action) => {
      state[getCVsKey].listCVs = action.payload.data;
      state[getCVsKey].numberOfCv = action.payload.numberOfCv;
      state[getCVsKey].currentPagination -= 1;
      state[getCVsKey].maxPagination =
        action.payload.numberOfCv % 20 === 0
          ? action.payload.numberOfCv / 20
          : Math.floor(action.payload.numberOfCv / 20) + 1;
      state[getCVsKey].offset -= 20;
      state[getCVsKey].endIndex -= 20;
      state[getCVsKey].pending = false;
    },
    [previousCVPaginationThunk.rejected]: (state, action) => {
      state[getCVsKey].pending = false;
      state[getCVsKey].errorMessage = action.error.message;
    },
  },
});

export const checkgetCVsKey = "checkAllCV";
export const getCVList = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.listCVs;
export const getCVListPending = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.pending;
export const getCVListErrorMessage = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.errorMessage;
export const getNumberOfCvs = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.numberOfCv;
export const selectCurrentPaginationOfCVS = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.currentPagination;
export const selectMaxPaginationOfCVS = (state) => state?.[checkgetCVsKey]?.[getCVsKey]?.maxPagination;
export const getCVById = (state) => state?.[checkgetCVsKey]?.[cvByIdKey]?.cv;
export const getCVByIdPending = (state) => state?.[checkgetCVsKey]?.[cvByIdKey]?.pending;
export const getCVByIdErrorMessage = (state) => state?.[checkgetCVsKey]?.[cvByIdKey]?.errorMessage;

export const { resetGetCVsKeyState } = checkAllCVSlice.actions;

// Reducers section
export default checkAllCVSlice.reducer;
