import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  recruitmentList: [],
  recruitmentPropsData: null,
  sections: {},
  pending: false,
  errorMessage: null,
};

// THUNKS SECTION

export const patchRecruitmentsForm10Thunk = createAsyncThunk(
  "checkYourRecruitmentsForm10/patchRecruitmentsThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v2/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const checkYourRecruitmentsForm10Slice = createSlice({
  name: "checkYourRecruitmentsForm10",
  initialState,
  reducers: {
    handleRecruitmentPropsForm10Data: (state, action) => {
      state.recruitmentPropsData = action.payload;
    },
    handleRecruitmentSectionsForm10Data: (state, action) => {
      state.sections[action.payload.sectionNumber] = action.payload.sectionData;
    },
  },
  extraReducers: {
    [patchRecruitmentsForm10Thunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [patchRecruitmentsForm10Thunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.recruitmentPropsData = action.payload;
    },
    [patchRecruitmentsForm10Thunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const { handleRecruitmentPropsForm10Data, handleRecruitmentSectionsForm10Data } =
  checkYourRecruitmentsForm10Slice.actions;

export const getYourRecruitmentPropsForm10Data = (state) => (
  state.checkYourRecruitmentsForm10.recruitmentPropsForm10Data
);
export const getAllUserRecruitmentsForm10 = (state) => state.checkYourRecruitmentsForm10.recruitmentList;
export const getUserRecruitmentsPendingForm10 = (state) => state.checkYourRecruitmentsForm10.pending;
export const getUserRecruitmentsErrorMessageForm10 = (state) => state.checkYourRecruitmentsForm10.errorMessage;

// ACTIONS CREATOR EXPORTS SECTION

// REDUCER DEFAULT EXPORT SECTION

export default checkYourRecruitmentsForm10Slice.reducer;
